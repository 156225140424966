import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  useNavigate
} from 'react-router-dom'
import {
  getSportsListingStart,
  getTeamListingStart, updateTeamImageStart
} from '../../../store/redux-slices/superAdminSportsBook'

import { debounce } from 'lodash'
import { getTenantSportsListingStart } from '../../../store/redux-slices/tenantSportsBook'

const initialState = {
  teamName: '',
  masterSportId: '173'

}
const useTeamListing = (isTenant) => {
  const { teamListing, loading, updateLoading } = useSelector(
    state =>
      state.superAdminSportsBook
  )
  const { sportsListing } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )
  const [filterData, setFilterData] = useState(initialState)
  const inputRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [modalData, setModalData] = useState({})
  const handleImageModalOpen = (data, type) => {
    setShowModal(true)
    setModalData({ ...data, entityType: type })
  }
  const handleUpdateImage = (formValues, formActions) => {
    const isURL = typeof formValues?.file === 'string'
    const formData = { ...formValues, entityType: modalData?.entityType, entityName: modalData?.teamName }
    if (isURL) {
      delete formData.file
    }
    dispatch(updateTeamImageStart({
      paramsData: formData,
      isTenant,
      onSuccess: () => {
        setShowModal(false)
        fetchData()
        formActions.resetForm()
        setModalData({})
      }
    }))
  }
  const fetchData = () => {
    dispatch(getTeamListingStart({
      paramsData: {
        ...filterData,
        limit,
        pageNo: page,
        isTenant
      }
    }))
  }
  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant
        }
      }))
  }, [])

  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])

  const totalPages = Math.ceil(teamListing?.count / limit)

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  function handleDebounceFn (event) {
    setFilterData(prev => ({
      ...prev,
      teamName: event.target.value
    }))
  }
  const handleClose = () => setShowModal(false)

  return {
    inputRef,
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    teamListing,
    loading,
    debounceFn,
    totalPages,
    showModal,
    handleClose,
    fetchData,
    handleImageModalOpen,
    handleUpdateImage,
    updateLoading,
    setModalData,
    sportsListing,
    modalData

  }
}

export default useTeamListing
