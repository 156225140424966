import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import useManagersList from '../hooks/useManagersList'
import PaginationComponent from '../../../../components/Pagination'
import ClientFilter from '../../../../components/ClientFilter'

const ManagersList = () => {
  const {
    tableHeaders,
    loading,
    TOManagersData,
    limit,
    setLimit,
    page,
    setPage,
    totalPages,
    selectedClient,
    setSelectedClient
  } = useManagersList()

  return (
    <>
      <Row>
        <Col>
          <h3>Managers</h3>
        </Col>
        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              hasAllOptions={false}
            />
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              h.label !== 'Action' && <th key={idx}>{h.label}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(TOManagersData) &&
              TOManagersData?.rows?.map(
                (
                  {
                    email,
                    firstName,
                    lastName,
                    phone,
                    isActive,
                    group
                  },
                  index
                ) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{email}</td>
                      <td>
                        {firstName + ' ' + lastName}
                      </td>
                      <td>{phone || 'N/A'}</td>
                      <td>Managers</td>

                      <td>{group || '-'}</td>

                      <td>
                        {isActive
                          ? (
                            <span className='text-success'>Active</span>
                            )
                          : (
                            <span className='text-danger'>In Active</span>
                            )}
                      </td>
                    </tr>
                  )
                }
              )}

          {TOManagersData?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={7}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
        </tbody>
      </Table>
      {TOManagersData?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={TOManagersData?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
    </>
  )
}

export default ManagersList
