import React from 'react'
import {
  Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Button
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStoreSlash, faBan, faEdit, faCheckSquare, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useCheckPermission from '../../../utils/checkPermission'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useCountriesListing from './useCountriesListing'
import ConfirmationModal, { UpdateKycMethodModal } from '../../../components/ConfirmationModal'
import { kycMethodsList } from './constants'

const Countries = () => {
  const {
    page,
    limit,
    showKycUpdateModal,
    countryData,
    name,
    totalPages,
    countries,
    loading,
    handleKycUpdateMethod,
    setCountryData,
    setShowKycUpdateModal,
    setLimit,
    setName,
    setPage,
    navigate,
    handleStatusShow,
    status,
    statusShow,
    myName,
    handleYes,
    setStatusShow,
    // active,
    // setActive,
    KYCMethod,
    setKYCMethod,
    languages
  } = useCountriesListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col>
          <h3>Countries</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center w-100'>
            <Row>
              <Col className='d-flex'>
                <Form.Label column='sm' style={{ marginRight: '15px', minWidth: '34%' }}>
                  Kyc Method
                </Form.Label>

                <Form.Select
                  onChange={(e) => { setKYCMethod(e.target.value) }}
                  size='sm'
                  style={{ maxWidth: '230px' }}
                  value={KYCMethod}
                >
                  <option value=''>All</option>
                  <option value='0'>System KYC</option>
                  <option value='1'>Sumsub</option>
                </Form.Select>
              </Col>

              <Col className='d-flex'>
                <Form.Label column='sm' style={{ marginRight: '15px' }}>
                  Search
                </Form.Label>

                <Form.Control
                  type='search'
                  placeholder='Search Country Name'
                  size='sm'
                  style={{ maxWidth: '3230px' }}
                  value={name}
                  onChange={(event) =>
                    setName(event.target.value.replace(/[^\w\s]/gi, ''))}
                />
                {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              size='sm'
              style={{ maxWidth: '230px' }}
              value={active}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>Id</th>
            <th>Country Code</th>
            <th>Country Name</th>
            <th>Language</th>
            <th>Kyc Method</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {countries && !loading &&
                      countries?.rows?.map(
                        ({ countryId, code, name: countryName, kycMethod, status, language }) => (
                          <tr key={countryId}>
                            <td>{countryId}</td>
                            <td>{code}</td>
                            <td>{countryName}</td>
                            <td>{language?.languageName}</td>
                            <td>{kycMethodsList[kycMethod] || 'NA'}</td>
                            <td className={status ? 'text-success' : 'text-danger'}>{status ? 'Active' : 'In-Active'}</td>

                            <td>
                              {!isHidden({ module: { key: 'RestrictedCountry', value: 'U' } })
                                ? (
                                  <ButtonGroup>
                                    <Trigger message='Edit'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='warning'
                                        onClick={() => {
                                          setCountryData((prev) => ({ ...prev, countryName, countryId, kycMethod: kycMethod || 0, languageId: language?.languageId }))
                                          setShowKycUpdateModal(true)
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Trigger>

                                    <Trigger message='View Blocked Games'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='warning'
                                        onClick={() => navigate(`/super-admin/countries/restricted-games/${countryId}`)}
                                      >
                                        <FontAwesomeIcon icon={faBan} />
                                      </Button>
                                    </Trigger>

                                    <Trigger message='View Blocked Providers'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='success'
                                        onClick={() => navigate(`/super-admin/countries/restricted-providers/${countryId}`)}
                                      >
                                        <FontAwesomeIcon icon={faStoreSlash} />
                                      </Button>
                                    </Trigger>
                                    {!status
                                      ? (
                                        <Trigger message='Set Status Active'>
                                          <Button
                                            className='m-1'
                                            size='sm'
                                            variant='success'
                                            onClick={() =>
                                              handleStatusShow(
                                                countryId,
                                                status,
                                                countryName
                                              )}
                                            hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                          >
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </Button>
                                        </Trigger>
                                        )
                                      : (
                                        <Trigger message='Set Status In-Active'>
                                          <Button
                                            className='m-1'
                                            size='sm'
                                            variant='danger'
                                            onClick={() =>
                                              handleStatusShow(
                                                countryId,
                                                status,
                                                countryName
                                              )}
                                            hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                          >
                                            <FontAwesomeIcon icon={faWindowClose} />
                                          </Button>
                                        </Trigger>
                                        )}
                                  </ButtonGroup>)
                                : '-'}
                            </td>
                          </tr>
                        )
                      )}
        </tbody>

        {countries?.count === 0 && !loading &&
                    (
                      <tr>
                        <td
                          colSpan={5}
                          className='text-danger text-center'
                        >
                          No data found
                        </td>
                      </tr>
                    )}
      </Table>

      {countries?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={countries?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalCount={countries?.count}
                />
              )}

      {languages?.count > 0 &&
        <UpdateKycMethodModal
          show={showKycUpdateModal}
          setShow={setShowKycUpdateModal}
          countryData={countryData}
          setCountryData={setCountryData}
          handleKycUpdateMethod={handleKycUpdateMethod}
          languages={languages}
        />}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={myName}
      />
    </>
  )
}

export default Countries
