import React, { useState } from 'react'
import { Pagination, Form, Button } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../utils/storageUtils'
const LENGTH = {
  1: 100,
  2: 100,
  3: 100,
  4: 100,
  5: 110,
  6: 135,
  7: 160
}
const PaginationComponent = ({ page, totalPages, setPage, limit, setLimit, totalCount }) => {
  const { t } = useTranslation()
  const OPTIONS_ARR = [15, 20, 50, 100]
  const items = []

  if (totalPages > 5) {
    const showFirstPages = page < 3
    const showLastPages = page > totalPages - 2

    if (!showFirstPages) {
      items.push(
        <Pagination.Item key={1} active={page === 1} onClick={() => setPage(1)}>
          {t('1')}
        </Pagination.Item>
      )

      items.push(<Pagination.Ellipsis key='ellipsis1' />)
    } else {
      for (let i = 1; i <= 3; i++) {
        items.push(
          <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
            {t(`${i}`)}
          </Pagination.Item>
        )
      }
    }

    if (showLastPages) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
            {t(`${i}`)}
          </Pagination.Item>
        )
      }
    }

    if (!showFirstPages && !showLastPages) {
      items.push(
        <Pagination.Item key={page - 1} active={page === page - 1} onClick={() => setPage(page - 1)}>
          {t(`${page - 1}`)}
        </Pagination.Item>
      )

      items.push(
        <Pagination.Item key={page} active onClick={() => setPage(page)}>
          {t(`${page}`)}
        </Pagination.Item>
      )

      items.push(
        <Pagination.Item key={page + 1} active={page === page + 1} onClick={() => setPage(page + 1)}>
          {t(`${page + 1}`)}
        </Pagination.Item>
      )
    }

    if (!showLastPages) {
      items.push(<Pagination.Ellipsis key='ellipsis2' />)

      items.push(
        <Pagination.Item key={totalPages} active={page === totalPages} onClick={() => setPage(totalPages)}>
          {t(`${totalPages}`)}
        </Pagination.Item>
      )
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {t(`${i}`)}
        </Pagination.Item>
      )
    }
  }
  const [pageNumber, setPageNumber] = useState(page)
  const handleSearchPage = () => {
    setPage(pageNumber)
  }
  const inputWidth = String(totalPages)?.length < 8 ? LENGTH[String(totalPages)?.length] : 160
  return (
    <div className='mt-3 d-flex flex-wrap pagination align-items-center gap-2'>
      <div className='flex-flex-shrink-0'>
        <div style={{ alignItems: 'center', width: '100%', textAlign: 'left' }}>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
            {t('perPage')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '80px' }}
            value={limit}
            onChange={(event) => {
              setLimit(event.target.value)
              setPage(1)
            }}
          >
            {OPTIONS_ARR.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className='flex-grow-1 flex justify-content-center '>
        <Pagination className='mb-0 d-flex align-items-center gap-1 justify-content-center'>
          <Pagination.Prev
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          />
          {items}
          <Pagination.Next
            disabled={page >= totalPages}
            onClick={() => setPage(page + 1)}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSearchPage()
            }} className='d-flex align-items-center gap-3'
          >
            <Form.Label style={{ marginBottom: '0', marginRight: '5px', flexShrink: 0, fontSize: 15 }}>
              {t('goToPage')}
            </Form.Label>
            <div className='d-flex gap-1 align-items-center'>
              <div style={{
                width: inputWidth,
                height: '40px'
              }}
              >
                <Form.Control
                  type='text'
                  size='sm'
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '6px',
                    textAlign: 'center',
                    paddding: '6px 8px'
                  }}
                  value={pageNumber <= 0 ? '' : pageNumber}
                  disabled={page > totalPages}
                  onChange={(e) => {
                    setPageNumber(Number(e.target.value) <= totalPages ? Number(e.target.value) : page)
                  }}
                />
              </div>
              <span className='flex-shrink-0' style={{ fontSize: 15, letterSpacing: '1px', marginLeft: 5 }}> / {totalPages}</span>

            </div>
            <Button
              variant='dark'
              type='submit'
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              {t('confirm')}
            </Button>
          </form>

        </Pagination>

      </div>
      {totalCount &&
        <div className='flex-flex-shrink-0'>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('totalCount')}
            </Form.Label>
            <Form.Control
              type='text'
              size='sm'
              style={{ width: '5rem', maxHeight: '15px', borderRadius: '30px', textAlign: 'center', marginRight: '1rem' }}
              value={formatNumber(totalCount)}
              disabled
            />
          </div>
        </div>}
    </div>

  )
}

export default PaginationComponent
