import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { SuperAdminRoutes } from '../../../routes'

// Super-Admin routes
import Tenants from '../../Super-Admin/Tenants'
import TenantOwner from '../../Super-Admin/TenantOwner'
import Themes from '../../Super-Admin/Themes'
import Admins from '../../Super-Admin/Admins'
import AdminDetails from '../../Super-Admin/AdminDetails'
import TenantDetails from '../../Super-Admin/TenantDetails'
import Currencies from '../../Super-Admin/Currencies'
import CreateCurrencies from '../../Super-Admin/Currencies/components/CreateCurrencies'
import EditCurrency from '../../Super-Admin/Currencies/components/editCurrency'
import CreateTenantAdmin from '../../Super-Admin/TenantOwner/components/CreateTenantAdmin'
import Dashboard from '../../Super-Admin/Dashboard'
import EditTenant from '../../Super-Admin/EditTenant'
import EditTheme from '../../Super-Admin/Themes/EditTheme'
import CreateTheme from '../../Super-Admin/Themes/CreateTheme'
import Cms from '../../Super-Admin/CMS'
import Users from '../../Super-Admin/Users'
import UserDetails from '../../Super-Admin/UserDetails'
import CreateCredential from '../../Super-Admin/TenantDetails/components/CreateCredential'
import CMSDetail from '../../Super-Admin/CMS/components/CmsDetail'
import CasinoProviders from '../../Super-Admin/CasinoProviders'
import CasinoGames from '../../Super-Admin/CasinoGames'
import Countries from '../../Super-Admin/Countries'
import CreateSuperAdminUser from '../../Super-Admin/Admins/components/CreateSuperAdminUser'
import EditSuperAdminUser from '../../Super-Admin/Admins/components/EditSuperAdminUser'
import EditTenantAdmin from '../../Super-Admin/TenantOwner/components/EditTenantAdmin'
import TransactionsBanking from '../../Super-Admin/TransactionsBanking'
import Wallet from '../../../components/Sidebar/components/Wallet'
import Aggregators from '../../Super-Admin/Aggregators'
import CasinoCategory from '../../Super-Admin/CasinoCategory'
import CasinoSubCategory from '../../Super-Admin/CasinoSubCategory'
import RestrictedGames from '../../Super-Admin/RestrictedGames'
import RestrictedProviders from '../../Super-Admin/RestrictedProviders'
import RestrictProviderCountries from '../../Super-Admin/RestrictProviderCountries'
import RestrictGamesCountries from '../../Super-Admin/RestrictGamesCountries'
import CasinoTransactions from '../../Super-Admin/CasinoTransactions'
import CreateCMSNew from '../../Super-Admin/CMS/components/CreateCMSNew'
import EditCms from '../../Super-Admin/CMS/components/EditCms'
import FormFields from '../../Super-Admin/FormFields'
import CreateBonus from '../../Super-Admin/Bonus/components/CreateBonus'
import BonusManagement from '../../Super-Admin/Bonus'
import EditBonus from '../../Super-Admin/Bonus/components/EditBonus'
import KYCLabels from '../../Super-Admin/KYCLabels'
import CloneBonus from '../../Super-Admin/Bonus/components/CloneBonus'
import BonusDetail from '../../Super-Admin/Bonus/BonusDetail'
import WageringTemplate from '../../Super-Admin/WageringTemplate'
import WageringTeplateDetails from '../../Super-Admin/WageringTemplate/components/WageringTeplateDetails'
import EditWageringTemplate from '../../Super-Admin/WageringTemplate/components/EditWageringTemplate'
import CreateWageringTemplate from '../../Super-Admin/WageringTemplate/components/CreateWageringTemplate'
import ProfilePage from '../../../components/ProfilePage'
import LoyaltyPoints from '../../../components/LoyaltyPoints'
import EmailTemplate from '../../Super-Admin/EmailTemplate'
import EditEmailTemplate from '../../Super-Admin/EmailTemplate/editEmailTemplate'
import OwnerDetails from '../../Super-Admin/TenantOwner/components/OwnerDetails'
import Languages from '../../Super-Admin/Languages'
import ImageGallery from '../../Super-Admin/ImageGallery'
import BannerManagement from '../../Super-Admin/BannerManagement'
import LanguageManagement from '../../Super-Admin/Languages/components/LanguageManagement'
import LanguageWise from '../../../components/LanguageMgmt/components/LanguageWise'
import KeyWise from '../../../components/LanguageMgmt/components/KeyWise'
import AddLangauge from '../../../components/LanguageMgmt/components/AddLanguage'
import CreateTenant from '../../Super-Admin/CreateTenant'
import CustomPayment from '../../../pages/Super-Admin/CustomPayment'
import EditProvider from '../../Super-Admin/CustomPayment/components/EditProvider'
import ReorderBonus from '../../../components/ReorderBonus'
import ReviewManagement from '../../Super-Admin/ReviewManagement'
import ManagersList from '../../Super-Admin/TenantOwner/components/ManagersList'
import UserWallet from '../../Super-Admin/UserWallet'
import Referrals from '../../Super-Admin/Referrals'
import AssociateReferrals from '../../Super-Admin/Referrals/component/AssociateReferrals'
import CasinoReporting from '../../Super-Admin/CasinoReporting'
import UserReporting from '../../Super-Admin/UserReporting'
import UserHistory from '../../Super-Admin/UserHistory'
import Notification from '../../Super-Admin/Notification'
import BonusTransaction from '../../Super-Admin/BonusTransaction'
import BonusUsers from '../../Super-Admin/BonusUsers'
import UniqueTickets from '../../Super-Admin/UniqueTickets'
import BonusGames from '../../Super-Admin/BonusGames'
import Sports from '../../Super-Admin/Sports'
import CreateUser from '../../../common/CreateUser/CreateUser'
import SeniorWallet from '../../Super-Admin/SeniorWallet'
import CreateVoucher from '../../Super-Admin/Voucher/CreateVoucher/CreateVoucher'
import VoucherManagement from '../../Super-Admin/Voucher'
import VoucherUsers from '../../Super-Admin/Voucher/VoucherUsers'
import VoucherGames from '../../Super-Admin/Voucher/VoucherGames'
import LevelSeasons from '../../Super-Admin/LevelSeasons/LevelSeasons'
import LevelTemplates from '../../Super-Admin/LevelTemplates/LevelTemplates'
import ViewTemplate from '../../Super-Admin/LevelTemplates/ViewTemplate'
import ViewSeason from '../../Super-Admin/LevelSeasons/ViewSeason'
import VoucherTransactions from '../../../components/VoucherTransactions'
import Affiliates from '../../../components/Affiliates'
import CreateAffiliate from '../../../components/Affiliates/CreateAffiliate/CreateAffiliate'
import Agents from '../../../components/Agent'
import CreateAgent from '../../../components/Agent/CreateAgent/CreateAgent'
import NotFound from '../../NotFound'
import RollingCompetetion from '../../Super-Admin/RollingCompetetion/RollingCompetetion'
import RollingDetails from '../../Super-Admin/RollingCompetetion/RollingDetails'
import RollingContestReport from '../../Super-Admin/RollingContestReport'
import BetslipDetails from '../../../components/SportsReporting/BetslipDetails'
import SportsReporting from '../../../components/SportsReporting'
import SportsTransactions from '../../../components/SportsTransactions'
import ReorderLeagues from '../../../components/SportsBook/ReorderLeagues'
import ReorderSports from '../../../components/SportsBook/ReorderSports'
import SecurityAlerts from '../../Super-Admin/SecurityAlerts'
import Events from '../../Super-Admin/Events/Events'
import Bonus from '../../../common/NewBonus/Bonus'
import BonusCreation from '../../../common/NewBonus/BonusCreation'
import ViewBonusDetail from '../../../common/NewBonus/ViewBonusDetail'
import HotCombo from '../../../components/SportsBook/HotCombo'
import CreateHotCombo from '../../../components/SportsBook/CreateHotCombo'
import UserRankHistory from '../../Super-Admin/UserRankHistory'
import ViewNewGames from '../../Super-Admin/ViewNewGames'
import ViewAndEditHotCombo from '../../../components/SportsBook/ViewAndEditHotCombo'
import CreateNotification from '../../../components/CreateNotification'
import DepositAddonConfig from '../../Super-Admin/DepositAddOn'
import SportsbookBonus from '../../../common/SportsbookBonus'
import SportsBookBonusCreation from '../../../common/SportsbookBonus/SportsBookBonusCreation'
import SportsBookViewBonusDetail from '../../../common/SportsbookBonus/SportsBookViewBonusDetail'
import AddonsReporting from '../../Super-Admin/AddonsReporting'
import CasinoLogReporting from '../../Super-Admin/CasinoLogs'
import ErrorLogReporting from '../../Super-Admin/ErrorLogs'
import { PerodicBonus } from '../../Super-Admin//PerodicBonus/PerodicBonus'
import { RackBackBonus } from '../../Super-Admin/PerodicBonus/RackBackBonus'
import UserMigrationReport from '../../Super-Admin/UserMigrationReporting'
import RollTransactionReport from '../../Super-Admin/RollTransactions'
import { SportsPerodicBonus } from '../../Super-Admin/SportsPerodicBonus/SportsPerodicBonus'
import SportsPromotionCashConfig from '../../Super-Admin/SportsPromotionCashConfig'
import SportsbookProviders from '../../Super-Admin/SportsbookProviders'
import BetBySportsLogReporting from '../../Super-Admin/BetBySportsLogReporting'

const SuperAdminPages = () => (
  <>
    <Route
      path={SuperAdminRoutes.bonusListing}
      element={
        <PrivateRoute>
          <Bonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.perodicPaybackBonus}
      element={
        <PrivateRoute>
          <PerodicBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.sportsPeriodicPaybackBonus}
      element={
        <PrivateRoute>
          <SportsPerodicBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.sportsbookProviders}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <SportsbookProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.reckBackBonus}
      element={
        <PrivateRoute>
          <RackBackBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusCreation}
      element={
        <PrivateRoute>
          <BonusCreation />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusDetail}
      element={
        <PrivateRoute>
          <ViewBonusDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusEdit}
      element={
        <PrivateRoute>
          <BonusCreation isEdit />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsBonusListing}
      element={
        <PrivateRoute>
          <SportsbookBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SportsBonusDetail}
      element={
        <PrivateRoute>
          <SportsBookViewBonusDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SportsBonusEdit}
      element={
        <PrivateRoute>
          <SportsBookBonusCreation isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SportsBonusCreation}
      element={
        <PrivateRoute>
          <SportsBookBonusCreation />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SportsCloneBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Wallet}
      element={
        <PrivateRoute>
          <Wallet />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantOwner}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantOwner />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Tenants}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <Tenants />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTenant}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ReorderLeagues}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <ReorderLeagues />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.ReorderSports}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <ReorderSports />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantDetails}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Admins}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Admins />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusUsers}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <BonusUsers />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.voucherUsers}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherUsers />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.giftcardUsers}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherUsers isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusGames}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <BonusGames />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.voucherGames}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherGames />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createNewUser}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateUser />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Voucher}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.GiftCard}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherManagement isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.uniqueCodeBonus}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherManagement isUniqueCodeBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Events}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Events />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Affiliates}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Affiliates />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.DepositAddOnConfig}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <DepositAddonConfig />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.sportsPromotionCashConfig}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <SportsPromotionCashConfig />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Agents}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Agents />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createVoucher}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createGiftCard}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createUniqueCodeBonus}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isUniqueCodeBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createAffiliate}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createAgent}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditVoucher}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditUniqueCodeBonus}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isEdit isUniqueCodeBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditGiftcard}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isEdit isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditAffiliate}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAffiliate isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditAgent}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAgent isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.VoucherDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isView />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.uniqueCodeBonusDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isView isUniqueCodeBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.GiftcardDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateVoucher isView isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.AffiliateDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAffiliate isView />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.AgentDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateAgent isView />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.RollingCompetetion}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RollingCompetetion />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.RollingContestReport}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RollingContestReport />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.depositAddonReporting}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <AddonsReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.RollingDetails}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RollingDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.lotteryTickets}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <UniqueTickets />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AdminDetails}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <AdminDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.seasonList}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <LevelSeasons />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.templateList}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <LevelTemplates />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.templateDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <ViewTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.seasonDetail}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <ViewSeason />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Themes}
      element={
        <PrivateRoute module={{ Themes: 'R' }}>
          <Themes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Currencies}
      element={
        <PrivateRoute module={{ Currencies: 'R' }}>
          <Currencies />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Languages}
      element={
        <PrivateRoute module={{ MultiLanguage: 'R' }}>
          <Languages />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTheme}
      element={
        <PrivateRoute module={{ Themes: 'C' }}>
          <CreateTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CMSDetail}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <CMSDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTheme}
      element={
        <PrivateRoute module={{ Themes: 'U' }}>
          <EditTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateCurrencies}
      element={
        <PrivateRoute module={{ Currencies: 'C' }}>
          <CreateCurrencies />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenantAdmin />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditNotification}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateNotification key='edit' isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CasinoGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CMS}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateCMS}
      element={
        <PrivateRoute module={{ CMS: 'C' }}>
          <CreateCMSNew />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Users}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users isUserV2={false} />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UsersV2}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users isUserV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UsersWithKyc}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users kycStatus='APPROVED' />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UsersWithRejectedKyc}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users kycStatus='REJECTED' />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserWithNonKyc}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users kycStatus='PENDING' />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsReporting}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SportsReporting />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsTransactions}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SportsTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsReportingBetBy}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SportsReporting isBetBy />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsTransactionsBetBy}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SportsTransactions isBetBy />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SportsTransactionsV2}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SportsTransactions isV2User />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BetslipDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <BetslipDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserDeposit}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='Deposit' isTransaction />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserWithdrawal}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='Withdraw' isWithdrawal isTransaction />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SubpaymentDeposit}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='SubpaymentDeposit' isSubPayment />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SubpaymentWithdraw}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='SubpaymentWithdraw' isSubPayment isWithdrawal />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserDepositHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='DepositHistory' isHistory />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.VaultDepositHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='VaultDepositHistory' isHistory isVault />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.VaultWithdrawHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='VaultWithdrawHistory' isHistory isWithdrawal isVault />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserWithdrawalHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='WithdrawalHistory' isHistory isWithdrawal />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2UserDeposit}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='DepositTransactionV2' isTransaction isV2 isDeposit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2UserWithdrawal}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='Withdraw' isWithdrawal isTransaction isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2SubpaymentDeposit}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='SubpaymentDeposit' isSubPayment isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2SubpaymentWithdraw}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='SubpaymentWithdraw' isSubPayment isWithdrawal isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2UserDepositHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='DepositHistoryV2' isHistory isV2 isDeposit />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2UserWithdrawalHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='WithdrawalHistory' isHistory isWithdrawal isV2 />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.V2VaultDepositHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='VaultDepositHistory' isHistory isVault isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.V2VaultWithdrawHistory}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserWallet key='VaultWithdrawHistory' isHistory isWithdrawal isVault isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SeniorWallet}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <SeniorWallet />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTenant}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCurrency}
      element={
        <PrivateRoute module={{ Currencies: 'U' }}>
          <EditCurrency />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Dashboard}
      element={
        <PrivateRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Credential}
      element={
        <PrivateRoute module={{ TenantCredentials: 'C' }}>
          <CreateCredential />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoProviders}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Countries}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'R' }}>
          <Countries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'C' }}>
          <CreateSuperAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'U' }}>
          <EditSuperAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenantAdmin />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.TransactionsBanking}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Aggregators}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <Aggregators />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoSubCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedGames}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedProviders}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedProviderCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }}>
          <RestrictProviderCountries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedGameCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }}>
          <RestrictGamesCountries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ReviewManagement}
      element={
        <PrivateRoute module={{ Reviews: 'U' }}>
          <ReviewManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <CasinoTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCMS}
      element={
        <PrivateRoute module={{ CMS: 'U' }}>
          <EditCms />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.FormFields}
      element={
        <PrivateRoute module={{ RegistrationField: 'R' }}>
          <FormFields />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CreateBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Bonus}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditBonus}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CloneBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.BonusDetail}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.KYCLabels}
      element={
        <PrivateRoute module={{ KycLabel: 'R' }}>
          <KYCLabels />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.WageringTemplate}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }}>
          <WageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.WageringEdit}
      element={
        <PrivateRoute module={{ WageringTemplate: 'U' }}>
          <EditWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.WageringDetails}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }}>
          <WageringTeplateDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateWagering}
      element={
        <PrivateRoute module={{ WageringTemplate: 'C' }}>
          <CreateWageringTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }}>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Profile}
      element={
        <PrivateRoute>
          <ProfilePage isTenant={false} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Loyalty}
      element={
        <PrivateRoute module={{ LoyaltyManagement: 'R' }}>
          <LoyaltyPoints />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantOwnerDetail}
      element={
        <PrivateRoute>
          <OwnerDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ImageGallery}
      element={
        <PrivateRoute module={{ ImageGallery: 'R' }}>
          <ImageGallery />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannerManagement}
      element={
        <PrivateRoute>
          <BannerManagement module={{ CasinoManagement: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.LanguageManagement}
      element={
        <PrivateRoute module={{ MultiLanguage: 'R' }}>
          <LanguageManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSupportLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <AddLangauge />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.PaymentAggregators}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <CustomPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ViewCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BonusReorder}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <ReorderBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TOManagers}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <ManagersList />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ErrLanguageManagement}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <LanguageManagement errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditErrSupportLanguage}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <LanguageWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditErrSupportLanguageKeys}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <KeyWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddErrLanguage}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <AddLangauge errorCodes />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Referrals}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <Referrals />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.AssociateReferral}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <AssociateReferrals />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CasinoReporting}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CasinoReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CasinoReportingV2}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CasinoReporting isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserReporting}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <UserReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserHistory}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <UserHistory />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserRankHistory}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <UserRankHistory />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.securityAlerts}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <SecurityAlerts />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.createNotification}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CreateNotification key='create' />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.bonusTransactions}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <BonusTransaction />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.sportsBonusTransactions}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <BonusTransaction isSportsBook />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.ViewNewGames}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <ViewNewGames />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.voucherTransactions}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherTransactions />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.giftCardTransactions}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <VoucherTransactions isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Notification}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <Notification />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Sports}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Sports />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.SportsCountries}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Countries />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Matches}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Matches />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.HotCombo}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <HotCombo />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CreateHotCombo}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <CreateHotCombo key='CreateHotCombo' />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.ViewHotCombo}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <ViewAndEditHotCombo key='viewHotCombo' />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Markets}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Markets />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Leagues}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Leagues />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Teams}
      element={
        <PrivateRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Teams />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.casinoLogs}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <CasinoLogReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.betBySportsLogs}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <BetBySportsLogReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.walletLogs}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <ErrorLogReporting isWalletLogs />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.errorLogs}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <ErrorLogReporting />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserMigrationReport}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <UserMigrationReport />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.UserRollTransactions}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RollTransactionReport />
        </PrivateRoute>
      }
    />

    <Route
      path='*'
      element={<NotFound />}
    />
  </>
)

export default SuperAdminPages
