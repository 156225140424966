import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { AffiliateRoutes } from '../../../routes'
import Agents from '../../../components/Agent'
import CreateUser from '../../../common/CreateUser/CreateUser'
import CreateAgent from '../../../components/Agent/CreateAgent/CreateAgent'
import Users from '../../Tenant/Users'
import UserDetails from '../../Tenant/UserDetails'
import CasinoReporting from '../../Tenant/CasinoReporting'
import TenantUserReporting from '../../Tenant/UserReporting'
import TenantUserHistory from '../../Tenant/UserHistory'
import BonusTransaction from '../../Tenant/BonusTransaction'
import NotFound from '../../NotFound'
import ProfilePage from '../../../components/ProfilePage'
import Dashboard from '../../Tenant/Dashboard'
import Bonus from '../../../common/NewBonus/Bonus'
import ViewBonusDetail from '../../../common/NewBonus/ViewBonusDetail'
import SportsbookBonus from '../../../common/SportsbookBonus'
import SportsBookViewBonusDetail from '../../../common/SportsbookBonus/SportsBookViewBonusDetail'
const AffiliatePages = () => (
  <>
    <Route
      path={AffiliateRoutes.agentsList}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <Agents isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.createAgent}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <CreateAgent isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.AgentDetail}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <CreateAgent isAffiliate isView />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.EditAgent}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <CreateAgent isAffiliate isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.Users}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <Users isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.UserDetails}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <UserDetails isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.CreateUser}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <CreateUser isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.CasinoReporting}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <CasinoReporting isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.UserReporting}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <TenantUserReporting isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.UserHistory}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <TenantUserHistory isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.bonusTransactions}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <BonusTransaction isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.sportsBonusTransactions}
      element={
        <PrivateRoute isAffiliate module={{ Users: 'R' }}>
          <BonusTransaction isAffiliate isSportsBook />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.Profile}
      element={
        <PrivateRoute isAffiliate>
          <ProfilePage isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.Dashboard}
      element={
        <PrivateRoute isAffiliate>
          <Dashboard isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.bonusListing}
      element={
        <PrivateRoute isAffiliate>
          <Bonus isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.sportsBookBonusListing}
      element={
        <PrivateRoute isAffiliate>
          <SportsbookBonus isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.bonusDetail}
      element={
        <PrivateRoute isAffiliate>
          <ViewBonusDetail isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.sportsBookBonusDetail}
      element={
        <PrivateRoute isAffiliate>
          <SportsBookViewBonusDetail isAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path='*'
      element={<NotFound />}
    />

  </>
)

export default AffiliatePages
