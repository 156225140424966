
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createBonusStart, getBonusStart, getSelectedSeniorBonusStart, updateBonusStart } from '../../../store/redux-slices/bonus'
import { useEffect } from 'react'
import { getAllClientsStart } from '../../../store/redux-slices/tenants'
import { useNavigate, useParams } from 'react-router-dom'
import { returnFormBonusData } from '../../../utils/helpers'
import { getTenantCurrenciesStart } from '../../../store/redux-slices/currencies'

const useCreateBonus = ({ isEdit }) => {
  const { bonusId } = useParams()
  const dispatch = useDispatch()
  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const { tenantCurrencies } = useSelector(state => state.currencies)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { bonusDropDown } = useSelector(state => state.bonus)
  const adminData = clientsFilterData?.rows
  const getTenantCurrencies = (tenantId) => {
    dispatch(getTenantCurrenciesStart({ tenantId }))
  }
  const handleCreateBonus = (values, formActions) => {
    const formData = returnFormBonusData(values)

    if (isEdit) {
      delete formData.bonusType
      dispatch(
        updateBonusStart({
          data: { ...formData, bonusId: bonusDetail?.bonusId, type: 'CASINO' },
          navigate
        })
      )
    } else {
      dispatch(createBonusStart({ formData: { ...formData, type: 'CASINO' }, navigate }))
    }
  }
  const getSeniorBonusStart = (tenantId, bonusId) => {
    dispatch(getSelectedSeniorBonusStart({ type: 'CASINO', tenantId }))
  }
  useEffect(() => {
    !isEdit && dispatch(getAllClientsStart({
      search: '',
      limit: '',
      orderBy: 'email',
      sort: 'desc'
    }))
  }, [])
  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ formData: { bonusId } }))
    }
  }, [bonusId])

  return {
    loading,
    bonusDetail,
    t,
    handleCreateBonus,
    adminData,
    bonusDropDown,
    getSeniorBonusStart,
    navigate,
    getTenantCurrencies,
    tenantCurrencies
  }
}

export default useCreateBonus
