import {
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import GeneralForm from './general'
import useCreateBonus from '../hooks/useCreateBonus'
import { bonusSchema } from './schema'
import { tabLabels } from './constants'
import Preloader from '../../../../../components/Preloader'

const CreateBonus = () => {
  const {
    curr,
    selectedTab,
    setSelectedTab,
    state,
    setState,
    preview,
    handleImagePreview,
    navigate,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    wageringTemplateList,
    setGameIds,
    selectedClient,
    appliedBonusOptions,
    languages,
    data,
    setData,
    setCountryData,
    selectedGames,
    setSelectedGames,
    selectedPlayers,
    setSelectedPlayers,
    bonusDropDown,
    selectedLimit,
    setSelectedLimit,
    t
  } = useCreateBonus()

  return (
    <div className='p-3'>
      <Row>
        {loading && <Preloader />}

        <Col>
          <h3>{t('createBonus')}</h3>
        </Col>
      </Row>

      <Formik
        initialValues={{
          bonusType: '',
          validFrom: state?.[0].startDate,
          validTo: state?.[0].endDate,
          currency: {
            KRW:
               {
                 minBonusThreshold: 5,
                 maxBonusThreshold: 50,
                 maxWinAmount: 1000,
                 minDeposit: 2,
                 zeroOutThreshold: 0.2
               }
          },
          limitType: '',
          daysToClear: '',
          reoccuring_deposite_rate: '',
          withdrawl_rate: '',
          dailyLimit: '',
          weeklyLimit: '',
          monthlyLimit: '',
          minutes_for_bonus_given: '',
          compensation_target: '',
          maxBonusLimit: 1,
          wageringRequirementType: 'bonus',
          depositBonusPercent: 1,
          tenantIds: [],
          validOnDays: [],
          isActive: true,
          isSticky: false,
          wageringTemplateId: 1,
          adminId: ''
        }}
        validationSchema={
          bonusSchema(curr, { bonusDetail: null, t })[
            tabLabels.findIndex((val) => val === selectedTab)
          ]
        }
        onSubmit={(formValues) => handelCreateBonus(formValues, 'create')}
      >
        {({
          touched,
          errors,
          values,
          setSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldError,
          setFieldTouched
        }) => {
          return (
            <BForm>
              <div className='mt-1'>
                <GeneralForm
                  t={t}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                  setFieldTouched={setFieldTouched}
                  setFieldError={setFieldError}
                  state={state}
                  setState={setState}
                  setSelectedTab={setSelectedTab}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setSubmitting={setSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  preview={preview}
                  handleImagePreview={handleImagePreview}
                  navigate={navigate}
                  clientsFilterData={clientsFilterData}
                  setSelectedClient={setSelectedClient}
                  portalOptions={portalOptions}
                  getBalanceBonusesOptions={getBalanceBonusesOptions}
                  balanceBonusOptions={balanceBonusOptions}
                  selectedClient={selectedClient}
                  setGameIds={setGameIds}
                  wageringTemplateList={wageringTemplateList}
                  setEnableSubmit={setEnableSubmit}
                  appliedBonusOptions={appliedBonusOptions}
                  data={data}
                  setData={setData}
                  languages={languages}
                  setCountryData={setCountryData}
                  selectedGames={selectedGames}
                  setSelectedGames={setSelectedGames}
                  selectedPlayers={selectedPlayers}
                  setSelectedPlayers={setSelectedPlayers}
                  bonusDropDown={bonusDropDown}
                />
              </div>
            </BForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateBonus
