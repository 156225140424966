import React from 'react'
import BonusTransactions from '../UserDetails/components/BonusTransactions'

const BonusTransaction = ({ isSportsBook = false }) => {
  return (
    <div>
      <BonusTransactions isReporting isSportsBook={isSportsBook} />
    </div>
  )
}

export default BonusTransaction
