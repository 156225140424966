import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  getAllTenantCMSStart,
  updateCmsStatusStart
} from '../../../../store/redux-slices/tenantsFetchData'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { useTranslation } from 'react-i18next'

const useCmsListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [showModal, setShowModal] = useState(false)
  const [statusState, setStatusState] = useState(false)
  const [selectedCmsId, setSelectedCmsId] = useState()
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const isInitialRender = useDidMountEffect()
  const [name, setName] = useState()

  const { tenantCMSData, loading } = useSelector((state) => state.tenantsFetch)
  const totalPages = Math.ceil(tenantCMSData?.count / limit)

  const handleShow = (id, active, myName) => {
    setSelectedCmsId(id)
    setStatusState(!active)
    setShowModal(true)
    setName(myName)
  }

  const updateCmsStatus = () => {
    dispatch(
      updateCmsStatusStart({
        limit,
        pageNo: page,
        search,
        cmsPageId: selectedCmsId,
        status: statusState,
        isActive: active
      })
    )
    setShowModal(false)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllTenantCMSStart({ limit, pageNo: page, search, isActive: active }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    !isInitialRender && dispatch(getAllTenantCMSStart({ limit, pageNo: page, search, isActive: active }))
  }, [page])

  useEffect(() => {
    dispatch(getAllTenantCMSStart({ limit, pageNo: page, search, isActive: active }))
  }, [limit, active])

  return {
    navigate,
    page,
    limit,
    showModal,
    statusState,
    selectedCmsId,
    search,
    setPage,
    setLimit,
    setShowModal,
    setStatusState,
    setSelectedCmsId,
    setSearch,
    tenantCMSData,
    totalPages,
    handleShow,
    updateCmsStatus,
    active,
    setActive,
    name,
    loading,
    t
  }
}

export default useCmsListing
