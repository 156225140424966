import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
// import { serialize } from 'object-to-formdata'
import { createVoucher, getVoucherDetails, getVoucherGames, getVoucherReports, getVoucherUsers, getVouchers, updateVoucher, updateVoucherStatus } from '../../utils/apiCalls'
import {
  createVoucherStart,
  createVoucherSuccess,
  createVoucherFailure,
  getVoucherStart,
  getVoucherSuccess,
  getVoucherFailure,
  updateVoucherStart,
  updateVoucherSuccess,
  updateVoucherFailure,
  getVoucherDetailStart,
  getVoucherDetailSuccess,
  getVoucherDetailFailure,
  getVoucherUserStart,
  getVoucherUserSuccess,
  getVoucherUserFailure,
  getVoucherGameStart,
  getVoucherGameSuccess,
  getVoucherGameFailure,
  updateVoucherStatusStart,
  updateVoucherStatusSuccess,
  updateVoucherStatusFailure,
  getVoucherReportStart,
  getVoucherReportSuccess,
  getVoucherReportFailure
} from '../redux-slices/voucher'
import { t } from 'i18next'

export default function * voucherWatcher () {
  yield takeLatest(createVoucherStart.type, createVoucherWorker)
  yield takeLatest(getVoucherStart.type, getVoucherWorker)
  yield takeLatest(updateVoucherStart.type, updateVoucherWorker)
  yield takeLatest(getVoucherDetailStart.type, getVoucherDetailWorker)
  yield takeLatest(getVoucherUserStart.type, getVoucherUserWorker)
  yield takeLatest(getVoucherGameStart.type, getVoucherGameWorker)
  yield takeLatest(updateVoucherStatusStart.type, updateVoucherStatusWorker)
  yield takeLatest(getVoucherReportStart.type, getVoucherReportWorker)
}

function * createVoucherWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    // data = serialize(data)
    const rData = yield createVoucher(data)
    yield toast(rData?.data?.data?.message, 'success')
    navigate(-1)
    yield put(createVoucherSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createVoucherFailure())
  }
}

function * getVoucherWorker (action) {
  try {
    const { data } = yield getVouchers(action.payload)
    yield put(getVoucherSuccess(data?.data?.voucherDetails))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getVoucherFailure(e.response.data.message))
  }
}

function * getVoucherReportWorker (action) {
  try {
    const { data } = yield getVoucherReports(action.payload)
    yield put(getVoucherReportSuccess(data?.data?.voucherReport))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getVoucherReportFailure(e.response.data.message))
  }
}

function * getVoucherUserWorker (action) {
  try {
    const { data } = yield getVoucherUsers(action.payload)
    yield put(getVoucherUserSuccess(data?.data?.userDetails))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getVoucherUserFailure(e.response.data.message))
  }
}

function * getVoucherGameWorker (action) {
  try {
    const { data } = yield getVoucherGames(action.payload)
    yield put(getVoucherGameSuccess(data?.data?.gameDetails))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getVoucherGameFailure(e.response.data.message))
  }
}

function * updateVoucherWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    const rData = yield updateVoucher(data)
    yield toast(rData?.data?.data?.message, 'success')
    navigate(-1)
    yield put(updateVoucherSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'erorr')
    yield updateVoucherFailure()
  }
}

function * updateVoucherStatusWorker (action) {
  try {
    const { data: formData, handleClose, isUniqueCodeBonus } = action && action.payload
    const res = yield updateVoucherStatus(formData, isUniqueCodeBonus)
    if (res.status === 200) {
      yield updateVoucherStatusSuccess()
      yield toast(t('updateSuccess'), 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'erorr')
    yield updateVoucherStatusFailure()
  }
}

function * getVoucherDetailWorker (action) {
  try {
    const { data } = yield getVoucherDetails(action.payload)
    yield put(getVoucherDetailSuccess(data?.data?.voucherDetails))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getVoucherDetailFailure(e.response.data.message))
  }
}
