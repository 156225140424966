import React from 'react'
import useLevelSeason, { levelTableHeaders } from './hooks/useLevelSeason'
import { Button, Form, Table } from 'react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose, faEdit, faEye, faRedoAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import CreateAndEditSeason from './components/CreateAndEditSeason'
import Preloader from '../../../components/Preloader'
import { format, milliseconds } from 'date-fns'
import PaginationComponent from '../../../components/Pagination'
import DeleteModal from '../../../common/Modals/DeleteModal'
import ChangeStatusModal from '../../../common/Modals/ChangeStatusModal'
import moment from 'moment'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { returnLangName } from '../../../utils/storageUtils'
import useIsGamma from '../../../common/hook/useIsGamma'
const LevelSeasons = () => {
  const {
    t,
    selectedClient,
    setSelectedClient,
    loading = false,
    navigate,
    isCreateSeasonModalOpen,
    setIsCreateSeasonModalOpen,
    isActive,
    setIsActive,
    setRefetch,
    seasonList,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    seasonData,
    setSeasonData,
    handleDeleteSeason,
    setSeasonDeleteData,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setStatusData,
    isStatusModalOpen,
    setIsStatusModalOpen,
    handleUpdateStatus,
    debounceFn,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setSearch,
    searchRef,
    currentDate
  } = useLevelSeason()
  const {
    isGamma
  } = useIsGamma()
  return (
    <>
      {loading && <Preloader />}

      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
          <h3>{t('listOfSeasons')}</h3>
          {
          !isGamma && (
            <Button
              variant='outline-success' onClick={() => {
                setSeasonData(null)
                setIsCreateSeasonModalOpen(true)
              }}
            >
              {t('addSeason')}
            </Button>
          )
         }
        </div>
        <div className='mb-2 d-flex align-items-center flex-wrap gap-2'>
          <div className='d-flex align-self-center align-items-center mt-2'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>
          <div className='d-flex gap-2  align-items-center mt-2 '>
            <Form.Label
              column='sm'
              style={{
                marginRight: '15px',
                fontSize: '13px'
              }}
            >
              {t('status')}
            </Form.Label>
            <Form.Select
              onChange={(e) => { setIsActive(e.target.value) }}
              value={isActive}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              <option value='1'>{t('active')}</option>
              <option value='0'>{t('inActive')}</option>
            </Form.Select>
          </div>
          <div className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              ref={searchRef}
              type='search'
              placeholder={t('searchByTemplateAndSeason')}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPageNo(1)
              }}
            />
          </div>

          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={startDate}
              onChange={(val) => {
                setStartDate(val)
                setPageNo(1)
              }}
            />
          </div>
          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={endDate}
              onChange={(val) => {
                setEndDate(val)
                setPageNo(1)
              }}
            />
          </div>
          <div className='d-flex mt-2'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='align-self-center'
                size='sm'
                onClick={() => {
                  setSearch('')
                  setSelectedClient('')
                  setPageNo(1)
                  setStartDate('')
                  setEndDate('')
                  searchRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </div>

        </div>
        <div className='list'>
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {levelTableHeaders.map((h, idx) => (
                  <th key={idx}>
                    {t(h)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>

              {seasonList?.seasons?.count > 0 && !loading
                ? (
                    seasonList?.seasons?.rows?.map((season, index) => {
                      return (
                        <tr key={index}>
                          <td> {(index + 1) + (limit * (pageNo - 1))}</td>
                          <td>{returnLangName(season?.seasonName)}</td>
                          <td>{season?.template?.templateName}</td>
                          <td>
                            <img style={{ width: 60, height: 60, objectFit: 'contain' }} src={season?.seasonUrl} alt='' />
                          </td>
                          <td>{format(new Date(season?.startDate), 'MM-dd-yyyy HH:mm')}</td>
                          <td>{format(new Date(season?.endDate), 'MM-dd-yyyy HH:mm')}</td>
                          <td>{
                            season?.isExpired ? t('yes') : t('no')
                            }
                          </td>
                          <td className={season?.status === 1 ? 'table-success' : 'table-danger'}>{season?.status === 0 ? t('inActive') : t('active')}</td>
                          <td>
                            <Trigger message={t('viewDetails')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() => {
                                  navigate(
                                    `/super-admin/season-list/${season.seasonId}/${season.tenantId}`
                                  )
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Trigger>
                            <Trigger message={t('editSeason')}>
                              <Button
                                className='m-1'
                                disabled={season?.isExpired}
                                size='sm'
                                variant='info'
                                s onClick={() => {
                                  setSeasonData(season)
                                  setIsCreateSeasonModalOpen(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>
                            <Trigger message={t('deleteSeason')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='danger'
                                onClick={() => {
                                  setSeasonDeleteData(
                                    {
                                      seasonId: season.seasonId,
                                      tenantId: season.tenantId
                                    }

                                  )
                                  setIsDeleteModalOpen(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Trigger>
                            <Trigger message={season?.status === 0 ? t('makeActive') : t('makeInActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                disabled={season?.isExpired || moment(season?.startDate).isAfter(currentDate, milliseconds)}
                                variant={season?.status === 1 ? 'danger' : 'success'}
                                onClick={() => {
                                  setStatusData({
                                    status: season?.status === 0 ? 1 : 0,
                                    tenantId: season?.tenantId,
                                    seasonId: season?.seasonId

                                  })
                                  setIsStatusModalOpen(true)
                                }}
                              >
                                <FontAwesomeIcon icon={season?.status === 1 ? faClose : faCheck} />
                              </Button>
                            </Trigger>
                          </td>
                        </tr>
                      )
                    })
                  )
                : (
                  <tr>
                    <td colSpan={8} className='text-danger text-center'>
                      {t('noDataFound')}
                    </td>
                  </tr>
                  )}

            </tbody>
          </Table>
          {seasonList?.seasons?.count > 0 && !loading && (
            <PaginationComponent
              page={seasonList?.seasons?.count < pageNo ? setPageNo(1) : pageNo}
              totalPages={Math.ceil(seasonList?.seasons?.count / limit)}
              setPage={setPageNo}
              limit={limit}
              setLimit={setLimit}
              totalCount={seasonList?.seasons?.count}
            />
          )}
        </div>
      </div>
      {isCreateSeasonModalOpen
        ? <CreateAndEditSeason
            type={seasonData ? 'editSeason' : 'addSeason'}
            seasonData={seasonData}
            setRefetch={setRefetch}
            show={isCreateSeasonModalOpen}
            handleClose={() => setIsCreateSeasonModalOpen(false)}
          />
        : null}
      <DeleteModal
        show={isDeleteModalOpen}
        handleClose={() => {
          setIsDeleteModalOpen(false)
          setSeasonDeleteData(null)
        }}
        handleDeleteYes={() => {
          handleDeleteSeason()
        }}
      />
      <ChangeStatusModal
        show={isStatusModalOpen}
        handleClose={() => {
          setIsStatusModalOpen(false)
          setStatusData(null)
        }}
        handleUpdateYes={() => {
          handleUpdateStatus()
        }}
      />
    </>
  )
}

export default LevelSeasons
