import React, { useState, useEffect } from 'react'
import {
  Button,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PaginationComponent from '../../../../components/Pagination'
import { useParams } from 'react-router-dom'
import { formatNumber } from '../../../../utils/storageUtils'
import { getV2WithdrawalTransactionDataStart, updatetV2WithdrawalTransactionStart } from '../../../../store/redux-slices/superAdminSettings'
import Preloader from '../../../../components/Preloader'
const tableHeaders = ['sNo', 'transactionId', 'remainingAmount', 'withdrawalAmount', 'payout', 'action']
const WithdrawalTableData = ({ userData }) => {
  const { userId } = useParams()
  const [type, setType] = useState('')
  const { t } = useTranslation()
  const {
    withdrawalTransaction: data,
    loading,
    updateLoading
  } = useSelector((state) => state.superAdminSettings)
  const [selectedData, setSelectedData] = useState([])
  const [tempSelectedPlayer, setTempSelectedPlayer] = useState({})
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const getData = () => {
    dispatch(getV2WithdrawalTransactionDataStart({ limit, page, providerType: type, userId: userId }))
  }
  const bulkAmount = selectedData?.reduce((acc, item) => acc + Number(item?.amount), 0)
  const payoutAmount = selectedData?.reduce((acc, item) => acc + Number(item?.payout), 0)
  useEffect(() => {
    if (type) { getData() }
  }, [page, limit, type])

  const isChecked = (id) => {
    return selectedData?.find(item => Number(item?.promotionCashMappingId) === Number(id))
  }
  const handleSingleSelectTransaction = (e, item) => {
    const tempVar = { ...tempSelectedPlayer }
    if (!isChecked(e.target.value)) {
      setSelectedData(prev => [...prev, item])
      tempVar[page] = [...tempVar[page] || [], item]
    } else {
      setSelectedData(selectedData.filter(item => Number(item?.promotionCashMappingId) !== Number(e.target.value)))
      tempVar[page] = (tempVar?.[page] || []).filter(item => Number(item?.promotionCashMappingId) !== Number(e.target.value))
    }
    setTempSelectedPlayer(tempVar)
  }
  const handleSelectAll = () => {
    const tempVar = { ...tempSelectedPlayer }
    if (tempVar[page]) {
      if (data?.rows?.length === tempVar[page]?.length) {
        tempVar[page] = []
      } else {
        tempVar[page] = data?.rows.map(item => item)
      }
    } else {
      tempVar[page] = data?.rows.map(item => item)
    }
    setTempSelectedPlayer(tempVar)
    setSelectedData(Object.values(tempVar)?.flat(Infinity))
  }
  const handleUpdate = () => {
    dispatch(updatetV2WithdrawalTransactionStart({
      formData: {
        remainingAmount: bulkAmount,
        payoutAmount: payoutAmount,
        userId: Number(userId),
        promotionMappingIds: selectedData?.map((item) => Number(item?.promotionCashMappingId)),
        providerType: type
      },
      onSuccess: () => {
        getData()
        setSelectedData([])
        setTempSelectedPlayer({})
      }
    }))
  }
  return (
    <div>
      {(loading || updateLoading) && <Preloader />}
      <div className='mb-2 d-flex align-items-center justify-content-between'>
        <div className='d-flex gap-2 align-items-center '>
          <div className='d-flex gap-2 align-items-center '>
            <Form.Label
              style={{
                fontSize: '13px',
                marginBottom: 0
              }}
            >
              {t('selectProviderType')}
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setType(e.target.value)
                setSelectedData([])
                setTempSelectedPlayer({})
              }}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                fontSize: '13px'
              }}
              value={type}
            >
              <option value=''>{t('selectProviderType')}</option>
              <option value='CASINO'>{t('casino')}</option>
              <option value='SPORTS'>{t('sports')}</option>
            </Form.Select>
          </div>
          {data?.count > 0 && (
            <div className='d-flex align-items-center gap-2'>
              <input
                onClick={handleSelectAll}
                onChange={handleSelectAll}
                style={{ width: 20, height: 20 }}
                checked={tempSelectedPlayer?.[page] ? tempSelectedPlayer?.[page]?.length === data?.rows.length : false}
                type='checkbox'
              />
              {t('selectAllOfThisPage')}
            </div>
          )}
        </div>
        {selectedData?.length > 0 && (
          <div className='d-flex align-items-center gap-3'>

            <div className='d-flex gap-1 align-items-center'>
              <span>{t('totalWithdrawableAmount')}:</span>
              <b>{formatNumber(bulkAmount + payoutAmount)}</b>
            </div>

            <Button
              onClick={() => {
                if (selectedData?.length <= 1) {
                  toast.info(t('selectMoreThanOne'))
                  return
                }
                handleUpdate()
              }} variant='outline-success'
            >
              {t('bulkWithdraw')}
            </Button>
          </div>
        )}
      </div>
      {!type
        ? (
          <p className='text-danger text-center py-4'>
            {t('pleaseSelectProviderType')}
          </p>
          )
        : (
          <>
            <Table bordered striped responsive hover size='sm' className='text-center'>
              <thead className='thead-dark'>
                <tr>
                  {tableHeaders.map((h, idx) => (
                    <th key={idx}>
                      {t(h)}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {!loading &&
                  data?.rows?.map(
                    (item, index) => {
                      return (
                        <tr key={item?.userId}>
                          <td>
                            <label className='me-2 d-inline-flex' htmlFor={item?.promotionCashMappingId}>
                              <input
                                onChange={(e) => handleSingleSelectTransaction(e, item)}
                                style={{ width: 20, height: 20 }}
                                checked={!!isChecked(item?.promotionCashMappingId)}
                                value={item?.promotionCashMappingId}
                                type='checkbox'
                                id={item?.promotionCashMappingId}
                              />
                            </label>
                            {(index + 1) + (limit * (page - 1))}
                          </td>
                          <td>{item?.promotionCashMappingId} </td>
                          <td>{formatNumber(item?.remainingAmount)} {userData?.currencyCode} </td>
                          <td>{formatNumber(item?.amount)} {userData?.currencyCode} </td>
                          <td>{formatNumber(item?.payout)} {userData?.currencyCode} </td>
                          <td>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              disabled={selectedData?.length > 1 || !isChecked(item?.promotionCashMappingId)}
                              onClick={handleUpdate}
                            >{t('withdraw')}
                            </Button>
                          </td>
                        </tr>
                      )
                    }
                  )}

                {data?.count === 0 &&
                  (
                    <tr>
                      <td colSpan={5} className='text-danger text-center'>
                        {t('noDataFound')}
                      </td>
                    </tr>
                  )}

              </tbody>
            </Table>
            {data?.count > 0 && !loading && (
              <PaginationComponent
                page={data?.count < page ? setPage(1) : page}
                totalPages={Math.ceil(data?.count / limit)}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={data?.count}
              />
            )}
          </>
          )}
    </div>
  )
}

export default WithdrawalTableData
