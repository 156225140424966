import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useRevenueDetails from './hooks/useRevenueDetails'
import Preloader from '../../../../components/Preloader'
import { formatNumber } from '../../../../utils/storageUtils'
const tabsButton = {
  senior: 'senior',
  affiliate: 'affiliate',
  agent: 'agent'
}
const RevenueListing = ({ t, data }) => {
  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center '>
        <thead className='thead-dark'>
          <tr>
            {['username', 'revenuePercentage', 'revenue'].map((h, idx) => (
              <th key={idx}>
                {t(h)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          {(
                 data?.length > 0
                   ? (
                       data?.map((item, index) => {
                         return (
                           <tr key={index}>
                             <td>{item?.username}</td>
                             <td>{item?.revenuePercentage ? <>{Number(item?.revenuePercentage).toFixed(2)}%</> : '0%'}</td>
                             <td>{item?.revenue ? formatNumber(item?.revenue) : '0'}</td>
                           </tr>
                         )
                       })
                     )
                   : (
                     <tr>
                       <td colSpan={3} className='text-danger text-center'>
                         {t('noDataFound')}
                       </td>
                     </tr>
                     )
              )}

        </tbody>
      </Table>
    </>
  )
}
const RevenueDetails = ({
  selectedClient,
  agent,
  affiliate
}) => {
  const [selectedTab, setSelectedTab] = useState(tabsButton.senior)
  const { t } = useTranslation()
  const {
    revenueSeniorData,
    revenueAffiliateAndAgentData,
    revenueLoading
  } = useRevenueDetails({
    selectedClient,
    agent,
    affiliate
  })
  const returnData = () => {
    if (selectedTab === tabsButton.affiliate) {
      return selectedClient ? revenueAffiliateAndAgentData?.affiliate : []
    } else if (selectedTab === tabsButton.agent) {
      return selectedClient ? revenueAffiliateAndAgentData?.agent : []
    } else {
      return revenueSeniorData
    }
  }
  const data = returnData()
  return (
    <>
      {revenueLoading && <Preloader />}
      <div className='p-3'>
        <h3 className='mb-2'>{t('revenueDetails')}</h3>
        <div className='d-flex gap-2 mb-3 '>
          {Object.values(tabsButton)?.map((item) => {
            return (
              <Button onClick={() => setSelectedTab(item)} key={item} variant={selectedTab === item ? 'success' : 'outline-success'}>
                {t(item)}
              </Button>
            )
          })}
        </div>
        <div className='d-flex flex-column gap-2'>
          <RevenueListing key={selectedTab} t={t} data={data} />
        </div>

      </div>
    </>
  )
}

export default RevenueDetails
