import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCasinoProvidersStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import moment from 'moment'
import { getUserInspectionStart } from '../../../../store/redux-slices/superAdminSettings'

const initialState = {
  categoryId: '',
  subCategoryId: '',
  providerId: ''
}

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useInspectionDetails = (userData) => {
  const dispatch = useDispatch()
  const [contentFilter, setContentFilter] = useState(initialState)
  const [state, setState] = useState(initialDate)
  const { loading, userInpectedData } = useSelector(state => state.superAdminSettings)
  const [show, setShow] = useState(false)
  const {
    gameCategory,
    gameSubCategory,
    casinoProvidersData
  } = useSelector((state) => state.superAdminCasino)
  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameSubCategoryStart({ limit: '', pageNo: '' }))
  }, [])
  useEffect(() => {
    dispatch(getUserInspectionStart({
      startDate: moment(state[0].startDate).toISOString() || '',
      endDate: moment(state[0].endDate).toISOString() || '',
      tenantId: '',
      userId: userData.userId,
      contentFilter
    }))
  }, [contentFilter, state])
  return {
    initialDate,
    contentFilter,
    setContentFilter,
    setState,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    initialState,
    state,
    userInpectedData,
    loading,
    show,
    setShow
  }
}

export default useInspectionDetails
