import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form as BForm, Spinner, Button, Row, Col } from 'react-bootstrap'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next'
import { createVoucherSchema } from './schema'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../../../store/redux-slices/tenants'
import { useNavigate, useParams } from 'react-router-dom'
import CasinoGames from '../../CasinoGames'
import moment from 'moment'
import { benefitTypes } from './constants'
import Users
  from '../../Users'
import { createVoucherStart, updateVoucherStart, getVoucherDetailStart } from '../../../../store/redux-slices/voucher'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'
import CurrencyInput from 'react-currency-input-field'
const CreateVoucher = ({ isEdit, isView, isGiftCard, isUniqueCodeBonus }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTenant = !window.location.href.match(/super/g)

  const { voucherId } = useParams()
  const [selectedGames, setSelectedGames] = useState([])
  const { voucherData } = useSelector((state) => state.voucher)
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { userCreateLoading } = useSelector(state => state.superAdminSettings)
  const TAB = {
    1: 'players',
    2: 'games'
  }
  const [activeTab, setActiveTab] = useState(TAB[1])
  const handleActiveTab = (type) => {
    setActiveTab(type)
  }
  useEffect(() => {
    if (!isTenant) {
      dispatch(getAllClientsStart({
        search: '',
        limit: '',
        orderBy: 'email',
        sort: 'desc'
      }))
    }
    (isEdit || isView) && dispatch(getVoucherDetailStart({ voucherId, isTenant, isUniqueCodeBonus }))
  }, [])

  useEffect(() => {
    if (isEdit || isView) {
      setSelectedGames(voucherData?.gameIds?.map((item) => ({ masterCasinoGameId: item, name: '' })))
      setSelectedPlayers(voucherData?.userIds?.map((item) => ({ userId: item, name: '' })))
    }
  }, [voucherData])

  const onSubmit = (formValues, { resetForm }) => {
    const gameIds = selectedGames?.map(item => item?.masterCasinoGameId) || []
    const userIds = selectedPlayers?.map(item => item?.userId) || []
    isEdit
      ? dispatch(updateVoucherStart({ data: { ...formValues, isGiftCard: isGiftCard, isUniqueCodeBonus: isUniqueCodeBonus, tenantId: Number(formValues.tenantId), userIds: userIds, gameIds: gameIds, voucherValidFrom: new Date(formValues.voucherValidFrom).toISOString(), voucherValidTo: new Date(formValues.voucherValidTo).toISOString(), voucherId: Number(voucherId) }, navigate: navigate }))
      : dispatch(createVoucherStart({ data: { ...formValues, isGiftCard: isGiftCard, isUniqueCodeBonus: isUniqueCodeBonus, tenantId: Number(formValues.tenantId), userIds: userIds, gameIds: gameIds, voucherValidFrom: new Date(formValues.voucherValidFrom).toISOString(), voucherValidTo: new Date(formValues.voucherValidTo).toISOString() }, navigate: navigate }))
  }
  const showStar = (fieldName) =>
    <span className='text-danger mt-1'>&nbsp;*&nbsp;</span>
  const navigate = useNavigate()

  return (
    <div className='p-3'>
      <div className='mb-3'>
        <h3>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className='cursor-pointer me-1'
            onClick={() => navigate(-1)}
          />

          {isUniqueCodeBonus ? isEdit ? t('editUniqueCodeBonus') : isView ? t('viewUniqueCodeBonus') : t('createUniqueCodeBonus') : !isGiftCard ? isEdit ? t('editVoucher') : isView ? t('voucherDetails') : t('createVoucher') : isEdit ? t('editGiftcard') : isView ? t('giftcardDetails') : t('createGiftCard')}
        </h3>
      </div>

      <Formik
        enableReinitialize={!!((isView || isEdit))}
        validateOnMount={false}
        initialValues={{
          tenantId: voucherData?.tenantId || '',
          voucher: voucherData?.voucher || '',
          isActive: voucherData?.isActive || false,
          targetBet: Number(voucherData?.targetBet) || '',
          voucherBenefits: Number(voucherData?.voucherBenefits) || '',
          benefitType: voucherData?.benefitType || '',
          voucherUseLimit: voucherData?.voucherUseLimit || '',
          currencyCode: voucherData?.currencyCode || '',
          voucherValidFrom: voucherData?.voucherValidFrom ? moment(voucherData?.voucherValidFrom).toDate() : moment().toDate(),
          voucherValidTo: voucherData?.voucherValidTo ? moment(voucherData?.voucherValidTo).toDate() : moment().add(1, 'hour').toDate(),
          rollingPeriod: voucherData?.rollingPeriod || '',
          rollingPercentage: voucherData?.rollingPercentage || 0,
          voucherType: isUniqueCodeBonus ? 'UniqueCode' : ''
        }}
        validationSchema={createVoucherSchema(isTenant, t)}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          validateField
        }) => {
          return (
            <Form className=' signup-form1 d-flex flex-column gap-3'>
              <Row className=''>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='tenantId'>
                        {t('selectSenior')}
                        {showStar()}
                      </label>
                      <BForm.Select
                        className='w-100 select-placeholder'
                        id='tenantId'
                        name='tenantId'
                        value={values.tenantId}
                        onChange={handleChange}
                        disabled={isView || isEdit}
                        onBlur={handleBlur}
                      >
                        <option value='' disabled>
                          {t('selectSenior')}
                        </option>
                        {clientsFilterData?.rows?.map((option, index) => (
                          <option
                            key={index} value={Number(option.adminUserId)}
                          >
                            {option.firstName + ' ' + option.lastName}
                          </option>
                        ))}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='tenantId'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='voucher'>
                        {t('name')}
                        {showStar()}
                      </label>
                      <input
                        name='voucher'
                        type='text'
                        className='form-control'
                        placeholder={t('voucherNamePlaceholder')}
                        id='voucher'
                        aria-describedby='voucher'
                        value={values.voucher}
                        onChange={handleChange}
                        disabled={isView}
                        onBlur={handleBlur}
                        maxLength={20}
                      />
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='voucher'
                          className='text-danger mt-1'
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='voucherUseLimit'>
                        {t('useLimit')}
                        {showStar()}
                      </label>
                      <input
                        name='voucherUseLimit'
                        type='number'
                        className='form-control'
                        placeholder={t('voucherUseLimitPlaceholder')}
                        id='voucherUseLimit'
                        aria-describedby='voucherUseLimit'
                        disabled={isView}
                        value={values.voucherUseLimit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                      />
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='voucherUseLimit'
                          className='text-danger mt-1'
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <label>
                    {t('benefitType')}<span className='text-danger'> *</span>
                  </label>
                  <BForm.Select
                    size='sm'
                    name='benefitType'
                    value={values?.benefitType}
                    disabled={isView}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ height: '47px' }}
                  >
                    <option value=''>{t('benefitTypePlaceholder')}</option>
                    {(isUniqueCodeBonus
                      ? benefitTypes?.filter((type) => type.value === 'cash')
                      : benefitTypes
                    )?.map((type) => (
                      <option
                        key={type.label}
                        value={type.value}
                      >
                        {t(type?.label).toUpperCase()}
                      </option>
                    )
                    )}
                  </BForm.Select>

                  <ErrorMessage component='div' name='benefitType' className='text-danger' />
                </Col>
                <Col sm={6} className='mb-2'>
                  <label>
                    {t('currency')}<span className='text-danger'> *</span>
                  </label>
                  <BForm.Select
                    size='sm'
                    name='currencyCode'
                    disabled={isView}
                    value={values?.currencyCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ height: '47px' }}
                  >
                    <option value=''>{t('selectCurrency')}</option>
                    {['KRW'].map((item, index) => (
                      <option key={index} value={item}>₩</option>
                    ))}
                  </BForm.Select>

                  <ErrorMessage component='div' name='currencyCode' className='text-danger' />
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='targetBet'>
                        {isUniqueCodeBonus ? t('depositTarget') : t('betTarget')}
                        {showStar()}
                      </label>
                      <CurrencyInput
                        intlConfig={{ locale: 'ko-KR' }}
                        value={values.targetBet}
                        placeholder={t('enterAmount')}
                        onValueChange={(value) => {
                          setFieldValue('targetBet', value !== undefined ? Number(value) : '')
                        }}
                        className='form-control'
                        size='sm'

                      />
                      {/* <input
                        name='targetBet'
                        type='number'
                        className='form-control'
                        placeholder={t('betTargetPlaceholder')}
                        id='targetBet'
                        aria-describedby='targetBet'
                        disabled={isView}
                        value={values.targetBet}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                      /> */}
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='targetBet'
                          className='text-danger mt-1'
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='voucherBenefits'>
                        {t('benefitAmount')}
                        {showStar()}
                      </label>
                      <CurrencyInput
                        intlConfig={{ locale: 'ko-KR' }}
                        value={values.voucherBenefits}
                        placeholder={t('voucherBenefitsPlaceholder')}
                        onValueChange={(value) => {
                          setFieldValue('voucherBenefits', value !== undefined ? Number(value) : '')
                        }}
                        className='form-control'
                        size='sm'

                      />
                      {/* <input
                        name='voucherBenefits'
                        type='number'
                        className='form-control'
                        placeholder={t('voucherBenefitsPlaceholder')}
                        id='voucherBenefits'
                        aria-describedby='voucherBenefits'
                        value={values.voucherBenefits}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isView}
                        maxLength={20}
                      /> */}
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='voucherBenefits'
                          className='text-danger mt-1'
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-1 input-label'>
                    <InputDatePicker
                      isRequired
                      disabled={isView || isEdit}
                      isForm
                      label={t('from')}
                      value={values.voucherValidFrom}
                      onChange={(val) => {
                        setFieldValue('voucherValidFrom', val)
                      }}
                    />
                    <ErrorMessage
                      component='div'
                      name='voucherValidFrom'
                      className='text-danger mt-1'
                    />
                  </div>
                </Col>

                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-1 input-label'>
                    <InputDatePicker
                      isRequired
                      disabled={isView || isEdit}
                      isForm
                      label={t('to')}
                      value={values.voucherValidTo}
                      minDate={values.voucherValidFrom}
                      onChange={(val) => {
                        setFieldValue('voucherValidTo', val)
                      }}
                    />
                    <ErrorMessage
                      component='div'
                      name='voucherValidTo'
                      className='text-danger mt-1'
                    />
                  </div>
                </Col>
                {
                  (isUniqueCodeBonus || values.benefitType === 'bonus') && (
                    <Col className='mb-2' sm={6}>
                      <label>
                        {t('rollingPercentage')}<span className='text-danger'> *</span>
                      </label>
                      <BForm.Control
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name='rollingPercentage'
                        disabled={isView || isEdit}
                        placeholder={t('rollingPercentagePlaceholder')}
                        maxLength={25}
                        value={values.rollingPercentage || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component='div'
                        name='rollingPercentage'
                        className='text-danger'
                      />
                    </Col>
                  )
                }
                <Col sm={3} className='mb-2 mt-4'>
                  <div className='form-group'>
                    <BForm.Check
                      type='switch'
                      id='isActiveSwitch'
                      label={t('active')}
                      disabled={isView}
                      name='isActive'
                      checked={values.isActive}
                      onChange={(e) => setFieldValue('isActive', e.target.checked)}
                    />
                  </div>
                </Col>
                {/* {!isEdit &&
                  <Col sm={3} className='mb-2 mt-4'>
                    <div className='form-group'>
                      <BForm.Check
                        type='switch'
                        id='isGiftCard'
                        label={t('giftCard')}
                        disabled={isView}
                        name='isGiftCard'
                        checked={values.isGiftCard}
                        onChange={(e) => setFieldValue('isGiftCard', e.target.checked)}
                      />
                    </div>
                  </Col>} */}
              </Row>

              {!isUniqueCodeBonus && (
                <>
                  {!isView && (
                    <div className='d-flex gap-2 align-items-center'>
                      {!(isEdit && voucherData && !voucherData?.isUserSpecific) && (
                        <Button
                          type='button'
                          variant={activeTab === TAB[1] ? 'warning' : 'outline-warning'}
                          className='w-100'
                          onClick={() => handleActiveTab(TAB[1])}
                        >
                          {t('players')}
                        </Button>
                      )}
                      {!(isEdit && voucherData && !voucherData?.isContentSpecific) && (!isGiftCard) && (
                        <Button
                          type='button'
                          variant={activeTab === TAB[2] ? 'warning' : 'outline-warning'}
                          className='w-100'
                          onClick={() => handleActiveTab(TAB[2])}
                        >
                          {t('games')}
                        </Button>
                      )}
                    </div>
                  )}
                  {activeTab === TAB[1] && !isView && !(isEdit && voucherData && !voucherData?.isUserSpecific) &&
                (
                  <Col sm={12} className='mb-3'>
                    {values.tenantId && (
                      <Users
                        adminId={values.tenantId}
                        isCreateBonus
                        selectedPlayers={selectedPlayers}
                        setSelectedPlayers={setSelectedPlayers}
                      />
                    )}
                  </Col>
                )}
                  {activeTab === TAB[2] && !isGiftCard &&
                (
                  <Col sm={12} className='mb-3'>
                    <CasinoGames
                      isCreateBonus
                      selectedGames={selectedGames}
                      setSelectedGames={setSelectedGames}
                    />
                  </Col>
                )}
                </>
              )}

              {!isView && (
                <div className='d-flex'>
                  <Button
                    variant='outline-success'
                    type='submit'
                    className='submit-btn justify-content-center'
                    style={{ minWidth: 200 }}
                  >
                    {t('submit')}
                    {userCreateLoading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              )}

            </Form>
          )
        }}
      </Formik>

    </div>
  )
}

export default CreateVoucher
