import React from 'react'
import { Row, Col, Table, Form as BForm } from '@themesberg/react-bootstrap'
import useBannerManagement from './useBannerManagement'
import UploadBanner from './UploadBanner'
// import { bannerType } from './constants'
import TableRow from './TableRow'

export default () => {
  const {
    show,
    setShow,
    upload,
    data,
    setData,
    banners,
    isHidden,
    validation,
    setValidation,
    loading,
    t,
    lang,
    setLang,
    languages,
    updateLoading
  } = useBannerManagement()

  return (
    <>
      <Row>
        <Col>
          <h3>{t('bannerManagement')}</h3>
        </Col>
        <Col>
          <div className='d-flex justify-content-end gap-2 align-items-center'>
            <div className=''>
              <BForm.Label>{t('selectLanguage')}</BForm.Label>
            </div>
            <div>
              <BForm.Select
                name='languageCode'
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value)
                }}
              >
                {languages && languages?.map(({
                  languageName,
                  code
                }, index) => {
                  return (
                    <option
                      key={index}
                      value={code.toLowerCase()}
                    >
                      {t(String(languageName).toLowerCase())}
                    </option>
                  )
                })}
              </BForm.Select>
            </div>

          </div>
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className='mt-3 text-center'>
        <thead className='thead-dark'>
          <tr>
            <th>{t('pages')}</th>
            <th>{t('bannerUrl')}</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>

          {!loading && banners &&
          Object.keys(banners.bannerUrl).map((banner, index) => {
            const ImgObj = banners?.bannerUrl
            const typeOf = typeof banners?.bannerUrl[banner][lang]
            return (
              <TableRow key={banner} lang={lang} ObjectOfData={banners?.bannerUrl[banner][lang]} typeOf={typeOf} banner={banner} banners={banners} isHidden={isHidden} t={t} setData={setData} setShow={setShow} ImgObj={ImgObj} />
            )
          })}
          {!loading && (!banners || Object.keys(banners)?.length < 1) &&
                      (
                        <tr>
                          <td
                            colSpan={3}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {show &&
        <UploadBanner
          loading={updateLoading}
          show={show}
          setShow={setShow}
          upload={upload}
          data={data}
          validation={validation}
          setValidation={setValidation}
        />}
    </>
  )
}
