import React from 'react'
import ErrorLogTable from '../../../components/ReportingTable/ErrorLogTable'
import useErrorLogs from './hooks/useErrorLogReporting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import { Button, Col, Form } from 'react-bootstrap'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import ClientFilter from '../../../components/ClientFilter'

const ErrorLogReporting = ({ isWalletLogs }) => {
  const {
    formData,
    setFormData,
    logCategories,
    errorLogData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalState,
    state,
    setState,
    handleCsvDownloadError,
    initialDate,
    inputRef,
    loading,
    t,
    setSelectedClient,
    selectedClient
  } = useErrorLogs(isWalletLogs)

  return (
    <div className='p-2'>
      <Col xs='auto'>
        <h3>{t(isWalletLogs ? 'walletLogs' : 'errorLogs')}</h3>
      </Col>
      <div className='d-flex flex-wrap p-2'>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('search')}
          </Form.Label>
          &nbsp;
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchName')}
            size='sm'
            className='w-90 mx-auto'
            style={{
              minWidth: '180px',
              maxWidth: '230px',
              width: 'auto'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </div>
        {isWalletLogs &&
          <div className='d-flex align-items-center p-2'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>}

        <div className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </div>
        <div className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </div>
        {!isWalletLogs && (
          <>
            <div className='d-flex align-items-center p-2'>
              <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                {t('category')}
              </Form.Label>

              <Form.Select
                size='sm'
                onChange={(e) => {
                  setFormData({ ...formData, category: e.target.value })
                  setPage(1)
                }}
                value={formData.category}
              >
                <option value=''>{t('selectCategory')}</option>
                {logCategories?.map((category) => (
                  <option key={category} value={category}>
                    {t(category)}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className='d-flex align-items-center p-2'>
              <Trigger message={t('downloadAsCsv')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  disabled={errorLogData?.count === 0}
                  onClick={() => {
                    handleCsvDownloadError()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
            </div>
          </>
        )}
        <div className='d-flex align-items-center p-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setFormData(initalState)
                setState(initialDate)
                setPage(1)
                inputRef.current.value = ''
                setSelectedClient('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </div>
      </div>

      <ErrorLogTable
        tableData={errorLogData}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        loading={loading}
        t={t}
        isWalletLogs={isWalletLogs}
      />
    </div>
  )
}
export default ErrorLogReporting
