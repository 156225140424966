import * as Yup from 'yup'

export const loyaltyPointsSchema = () => Yup.object().shape({
  updateLimit: Yup.array().of(
    Yup.object().shape({
      loyaltyPoint: Yup.number()
        .typeError('Only Numbers Allowed.')
        .min(0, 'Value Must be Greater Than Zero.')
        .required('Value Required.')
    })
  )
})

export const supportEmailSchema = () => Yup.object().shape({
  supportEmailAddress: Yup.string()
    .email('Invalid email')
    .max(200)
    .required('Email Required')
})
