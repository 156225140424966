import { Row, Col, Button } from '@themesberg/react-bootstrap'
import React from 'react'
import TACurrencies from './components/TACurrencies'
import useConfigurations from './useConfigurations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'

export default () => {
  const {
    loading,
    pointsObject,
    updateLoyaltyLimit,
    tenantDetails,
    enableEdit,
    setEnableEdit
  } = useConfigurations()

  return (
    <>
      <Row>
        <Col>
          <h3><FontAwesomeIcon icon={faGear} />&nbsp;Configurations</h3>
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          <Button
            variant='outline-warning'
            size='sm'
            onClick={() => {
              setEnableEdit(true)
            }}
          >Edit
          </Button>
        </Col>
      </Row>

      <TACurrencies
        pointsObject={pointsObject}
        updateLoyaltyLimit={updateLoyaltyLimit}
        tenantDetails={tenantDetails}
        enableEdit={enableEdit}
        loading={loading}
      />

    </>
  )
}
