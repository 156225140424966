import React from 'react'
import { Row, Col, Card, Tabs, Tab } from '@themesberg/react-bootstrap'
import useDashBoard from './hooks/useDashBoard'
import { getTextColor } from '../../../utils/dashboardEffects'
import LivePlayersTenant from './components/LivePlayersTenant'
import ProviderStatsTenant from './components/ProviderStatsTenant'
import WalletStats from './components/WalletStats'
import Trigger from '../../../components/OverlayTrigger'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import SportsDashBoardTransactions from '../../../components/SportsDashBoardTransactions'
import { formatNumber } from '../../../utils/storageUtils'

export default ({ isAffiliate, isAgent }) => {
  const {
    pieChartValuesSeries,
    pieChartValuesLabels,
    pieChartValuesWalletSeries,
    pieChartValuesWalletLabels,
    livePlayerData,
    providerDataStat,
    tableHeaders,
    selectedProvider,
    setSelectedProvider,
    selectedCategory,
    setSelectedCategory,
    casinoCategories,
    userLogginbyCountry,
    countryCodes,
    loggedInCounts,
    tableHeadersUserCountryCount,
    state,
    setState,
    initialDate,
    t,
    transactionType,
    setTransactionType,
    selectedTab,
    setSelectedTab,
    result,
    tableHeaderSeniorUser,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    dashboardSBTransactions,
    sbTransactionLoading,
    setSBTransactionDate,
    sbTransactionDate
  } = useDashBoard({ isAffiliate, isAgent })
  return (
    <>
      <Card className='p-3 mb-2'>
        <Row>
          <Col xs='auto'>
            <Col xs='auto' className='mb-3'>
              <h3>{t('dashboard')}</h3>
            </Col>
            <div className='d-flex align-items-center gap-2 mb-3'>
              <AffiliateAgentFilter
                agent={agent}
                setAgent={setAgent}
                affiliate={affiliate}
                setAffiliate={setAffiliate}
              />
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  onClick={() => {
                    setAffiliate('')
                    setAgent('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>

            </div>
          </Col>
        </Row>
        <Row className='gap-3'>
          <Col className='p-0'>
            <Card className='p-3' title={t('currentOnlinePlayer')} style={{ borderRadius: 8, minWidth: 150 }}>
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(livePlayerData?.[0]?.logged_in_count)}`}> {formatNumber(livePlayerData?.[0]?.logged_in_count) || 0}</p>
              <span className='fw-bold text-dark'>{t('onlinePlayer')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card className='p-3' title={t('totalPlayer')} style={{ borderRadius: 8, minWidth: 150 }}>
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(livePlayerData?.[0]?.totalUserCount)}`}>{formatNumber(livePlayerData?.[0]?.totalUserCount) || 0}</p>
              <span className='fw-bold text-dark'>{t('totalPlayer')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card className='p-3' title={t('totalNGR')} style={{ borderRadius: 8, minWidth: 150 }}>
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(providerDataStat?.totalGgrAmount)}`}>
                {providerDataStat?.totalGgrAmount !== undefined ? formatNumber(providerDataStat?.totalGgrAmount || 0) : 0}
              </p>
              <span className='fw-bold text-dark'>{t('totalGGR')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card className='p-3' title={t('totalNGR')} style={{ borderRadius: 8, minWidth: 150 }}>
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(providerDataStat?.totalNgrAmount)}`}>
                {providerDataStat?.totalNgrAmount !== undefined ? formatNumber(providerDataStat?.totalNgrAmount || 0) : 0}
              </p>
              <span className='fw-bold text-dark'>{t('totalNGR')}</span>
            </Card>
          </Col>
        </Row>
      </Card>

      <LivePlayersTenant
        pieChartValuesSeries={pieChartValuesSeries}
        pieChartValuesLabels={pieChartValuesLabels}
        countryCodes={countryCodes}
        loggedInCounts={loggedInCounts}
        userLogginbyCountry={userLogginbyCountry}
        tableHeadersUserCountryCount={tableHeadersUserCountryCount}
        t={t}
      />
      <Card className='p-2 mb-2'>
        <WalletStats
          pieChartValuesWalletSeries={pieChartValuesWalletSeries}
          pieChartValuesWalletLabels={pieChartValuesWalletLabels}
          userLogginbyCountry={userLogginbyCountry}
          tableHeadersUserCountryCount={tableHeadersUserCountryCount}
          t={t}
        />
      </Card>
      <Card className='p-2 mb-2'>
        <Tabs
          activeKey={selectedTab}
          onSelect={tab => {
            setSelectedTab(tab)
          }}
          mountOnEnter
          unmountOnExit
          className='nav-light'
        >
          <Tab eventKey='transaction' title={t('walletTransaction')}>
            <ProviderStatsTenant
              providerDataStat={providerDataStat}
              tableHeaders={tableHeaders}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              gameCategory={casinoCategories}
              state={state}
              setState={setState}
              initialDate={initialDate}
              t={t}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              isAffiliate={isAffiliate}
              isAgent={isAgent}
            />
          </Tab>

          <Tab eventKey='seniorTransactionStats' title={t('reportTransaction')}>
            <ProviderStatsTenant
              isUserSenior
              result={result}
              tableHeaderSeniorUser={tableHeaderSeniorUser}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              gameCategory={casinoCategories}
              state={state}
              setState={setState}
              initialDate={initialDate}
              t={t}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
            />
          </Tab>

        </Tabs>
        {/*  */}
      </Card>
      <Card className='p-3 mb-2'>
        {!isAffiliate && !isAgent && <SportsDashBoardTransactions
          dashboardSBTransactions={dashboardSBTransactions}
          sbTransactionLoading={sbTransactionLoading}
          date={sbTransactionDate}
          setDate={setSBTransactionDate}
          t={t}
                                     />}
      </Card>

    </>
  )
}
