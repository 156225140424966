import { Modal, Form, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createNoteValidationSchema } from './schema'

const NoteModal = ({
  addNoteModal,
  setAddNoteModal,
  individualUserData,
  handleSubmit
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          description: ''
        }}
        validationSchema={createNoteValidationSchema(t)}
        onSubmit={(formValues, { resetForm }) => {
          handleSubmit(formValues)
          resetForm({ formValues: '' })
          setAddNoteModal(false)
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        }) => {
          return (
            <Modal
              show={addNoteModal}
              onHide={() => setAddNoteModal(false)}
              backdrop='static'
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <h5>{t('addNoteFor')} {individualUserData?.username ?? 'bet'}</h5>
              </Modal.Header>
              <Modal.Body>
                <div className='form-group mt-3'>
                  <label
                    htmlFor='reason'
                  >
                    {t('addNote')}
                  </label>
                  <Form.Control
                    name='description'
                    type='text'
                    placeholder={t('enterNote')}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component='div'
                    name='description'
                    className='text-danger'
                  />
                </div>
              </Modal.Body>
              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => setAddNoteModal(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    {t('submit')}
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>

          )
        }}

      </Formik>
    </>
  )
}

export default NoteModal
