import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useViewNewGame from './useViewNewGame'
import { tableHeaders } from './constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { getItem } from '../../../utils/storageUtils'

const ViewNewGames = () => {
  const location = useLocation()
  const {
    limit,
    page,
    setLimit,
    setPage,
    totalPages,
    loading,
    setSearch,
    t,
    newGamesData,
    debounceFn,
    inputRef,
    getImageByGameCategory
  } = useViewNewGame({ providerId: location?.state?.data })
  const code = getItem('language')
  const navigate = useNavigate()
  return (
    <>
      <Row className='px-3 py-3'>
        <Col sm={8}>
          <h3><FontAwesomeIcon
            icon={faArrowCircleLeft} className='cursor-pointer'
            onClick={() => navigate(-1)}
              />&nbsp; {t('newGames')}
          </h3>
        </Col>
      </Row>

      <Row className='mt-2 mb-3 px-3'>
        <Col xs='auto'>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('search')}
              size='sm'
              style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
        </Col>
        <Col>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              className='mt-1'
              onClick={() => {
                setSearch('')
                inputRef.current.value = ''
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
              >
                {t(h.label)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && newGamesData &&
                      newGamesData?.rows?.map((
                        {
                          name,
                          masterCasinoGameId,
                          identifier,
                          thumbnailUrl,
                          masterGameSubCategoryId,
                          subCategoryName,
                          masterCasinoProviderId,
                          isActive,
                          operatorStatus,
                          devices,
                          returnToPlayer,
                          moreDetails,
                          featureGroup,
                          defaultRtp,
                          'MasterGameSubCategory.name': {
                            EN: subCategoryNameEN
                          } = {
                            EN: ''
                          },
                          'MasterGameCategory.name': {
                            EN: categoryName
                          } = { EN: '' },
                          translations
                        },
                        index
                      ) => {
                        return (

                          <tr key={masterCasinoGameId} className={!defaultRtp ? 'under-inspection' : ''}>
                            <td>{(page - 1) * limit + index + 1}</td>
                            <td>{masterCasinoGameId}</td>
                            <td>
                              <Trigger message={name}>
                                <span
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  className='d-inline-block '
                                >
                                  {translations?.[code] ?? name}
                                </span>
                              </Trigger>
                            </td>

                            <td>{returnToPlayer || 'N/A'}</td>

                            <td>
                              {
                            thumbnailUrl
                              ? <img
                                  src={thumbnailUrl} style={{
                                    height: '50px',
                                    width: '60px'
                                  }} onClick={() => window.open(thumbnailUrl)}
                                />
                              : <img
                                  src={getImageByGameCategory(categoryName) || ''}
                                  style={{
                                    height: '50px',
                                    width: '60px'
                                  }} onClick={() => window.open(thumbnailUrl)}
                                />

                           }
                            </td>
                            <td className={isActive ? 'table-success' : 'table-danger'}>
                              {isActive ? t('active') : t('inActive')}

                            </td>
                          </tr>
                        )
                      }
                      )}

          {newGamesData?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={8}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {
        newGamesData?.count !== 0 && !loading &&
                (
                  <PaginationComponent
                    page={newGamesData?.count < page ? setPage(1) : page}
                    totalPages={totalPages}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    totalCount={newGamesData?.count}
                  />
                )
      }
    </>
  )
}

export default ViewNewGames
