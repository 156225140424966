import React from 'react'
import BonusTransactions from '../UserDetails/components/BonusTransactions'

const BonusTransaction = ({ isAffiliate, isAgent, isSportsBook }) => {
  return (
    <div>
      <BonusTransactions isReporting isAffiliate={isAffiliate} isAgent={isAgent} isSportsBook={isSportsBook} />
    </div>
  )
}

export default BonusTransaction
