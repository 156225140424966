import React from 'react'
import { Button, Row, Col, Table, ButtonGroup, Form } from '@themesberg/react-bootstrap'
import useWageringTemplate from './hooks/useWageringTemplate'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    loading,
    wageringTemplates,
    navigate,
    setLimit,
    setPageNo,
    setSearch,
    search,
    totalPages,
    limit,
    pageNo
  } = useWageringTemplate()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col>
          <h3>Wagering Template List</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Label style={{ marginRight: '15px', marginTop: '5px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              placeholder='Search Templates'
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />
            <Button
              className='m-1'
              size='sm'
              variant='outline-success'
              hidden={isHidden({ module: { key: 'WageringTemplate', value: 'C' } })}
              onClick={() =>
                navigate('/tenant/wagering-template/create')}
            >
              Create
            </Button>

          </div>
        </Col>

      </Row>

      <Row>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {['Template ID',
                'Template Name',
                'Action'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && wageringTemplates && wageringTemplates?.rows.length > 0
              ? wageringTemplates?.rows.map(({ wageringTemplateId, name, parentType }, idx) => {
                  return (
                    <tr key={`wagering-template ${wageringTemplateId}`}>
                      <td>{wageringTemplateId}</td>
                      <td>{name}</td>

                      <td>
                        <ButtonGroup>
                          <Trigger message='View Details'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              hidden={isHidden({ module: { key: 'WageringTemplate', value: 'R' } })}
                              onClick={() =>
                                navigate(`/tenant/wagering-template/details/${wageringTemplateId}`)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>
                          <Trigger message='Edit'>
                            <Button
                              disabled={parentType === 'superadmin'}
                              className='m-1'
                              size='sm'
                              variant='warning'
                              hidden={isHidden({ module: { key: 'WageringTemplate', value: 'U' } })}
                              onClick={() =>
                                navigate(`/tenant/wagering-template/edit/${wageringTemplateId}`)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>
                          <Trigger message='Clone'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='danger'
                              hidden={isHidden({ module: { key: 'WageringTemplate', value: 'C' } })}
                              onClick={() =>
                                navigate('/tenant/wagering-template/create', { state: { isClone: true, wageringTemplateId } })}
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </Button>
                          </Trigger>
                        </ButtonGroup>
                      </td>

                    </tr>
                  )
                })
              : !loading && (
                <tr>
                  <td colSpan={3} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
                )}
          </tbody>
        </Table>
      </Row>
      {wageringTemplates && wageringTemplates?.count !== 0 && !loading && (
        <PaginationComponent
          page={wageringTemplates?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>

  )
}
