import { useDispatch, useSelector } from 'react-redux'
import { tenantCasinoActionStart } from '../../../store/redux-slices/tenantCasino'
import { addTopStart } from '../../../store/redux-slices/superAdminSportsBook'

const useEditSports = () => {
  const { addTopLoading } = useSelector((state) => state.superAdminSportsBook)
  const dispatch = useDispatch()

  const addTop = ({ data }) => {
    dispatch(addTopStart(data))
  }
  return {
    addTop,
    addTopLoading
  }
}

export default useEditSports
