import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateKycRequestBySuperAdminStart } from '../../../../store/redux-slices/fetchData'
// import { useDispatch, useSelector } from 'react-redux'

const useSetting = () => {
  const [showKycRequestAction, setShowKycRequestAction] = useState(false)
  const [userId, setUserId] = useState(null)
  const [KycRequestAction, setKycAction] = useState('')
  const [kycEditForm, setKycEditForm] = useState(false)
  const dispatch = useDispatch()
  const handleShowModal = (userId, kycStatus) => {
    setKycAction(kycStatus)
    setUserId(userId)
    setShowKycRequestAction(true)
  }

  const handleConfirmActionRequest = () => {
    dispatch(updateKycRequestBySuperAdminStart({
      kycStatus: KycRequestAction,
      userId
    }))
    setShowKycRequestAction(false)
  }
  const bankNameOptions = {
    EN: [
      { value: 'Chase Bank', label: 'Chase Bank' },
      { value: 'Wells Fargo', label: 'Wells Fargo' },
      { value: 'Bank of America', label: 'Bank of America' },
      { value: 'Citibank', label: 'Citibank' },
      { value: 'HSBC', label: 'HSBC' },
      { value: 'Barclays', label: 'Barclays' },
      { value: 'Santander', label: 'Santander' },
      { value: 'UBS', label: 'UBS' },
      { value: 'BNP Paribas', label: 'BNP Paribas' },
      { value: 'Deutsche Bank', label: 'Deutsche Bank' },
      { value: 'Industrial and Commercial Bank of China', label: 'Industrial and Commercial Bank of China' },
      { value: 'Kookmin Bank', label: 'Kookmin Bank' },
      { value: 'Nonghyup Bank', label: 'Nonghyup Bank' },
      { value: 'Woori Bank', label: 'Woori Bank' },
      { value: 'Saemaeul Bank', label: 'Saemaeul Bank' },
      { value: 'Shinhan Bank', label: 'Shinhan Bank' },
      { value: 'K Bank', label: 'K Bank' },
      { value: 'Kakao Bank', label: 'Kakao Bank' },
      { value: 'Hana Bank', label: 'Hana Bank' },
      { value: 'Post Office Bank', label: 'Post Office Bank' },
      { value: 'National Federation of Fisheries Cooperatives', label: 'National Federation of Fisheries Cooperatives' },
      { value: 'Standard Chartered Bank Korea', label: 'Standard Chartered Bank Korea' },
      { value: 'Kyongnam Bank', label: 'Kyongnam Bank' },
      { value: 'Kwangju Bank', label: 'Kwangju Bank' },
      { value: 'Shinhan Financial Investment', label: 'Shinhan Financial Investment' },
      { value: 'Industrial Bank of Korea', label: 'Industrial Bank of Korea' },
      { value: 'Nonghyup Members Cooperative', label: 'Nonghyup Members Cooperative' },
      { value: 'Daegu Bank', label: 'Daegu Bank' },
      { value: 'Bank of America', label: 'Bank of America' },
      { value: 'Busan Bank', label: 'Busan Bank' },
      { value: 'Korean Forestry Cooperatives Federation', label: 'Korean Forestry Cooperatives Federation' },
      { value: 'Industrial Bank of Korea', label: 'Industrial Bank of Korea' },
      { value: 'Mutual Savings Bank', label: 'Mutual Savings Bank' },
      { value: 'Export-Import Bank of Korea', label: 'Export-Import Bank of Korea' },
      { value: 'Korea Exchange Bank', label: 'Korea Exchange Bank' },
      { value: 'Jeonbuk Bank', label: 'Jeonbuk Bank' },
      { value: 'Jeju Bank', label: 'Jeju Bank' },
      { value: 'Citibank Korea', label: 'Citibank Korea' },
      { value: 'Hongkong and Shanghai Banking Corporation', label: 'Hongkong and Shanghai Banking Corporation' },
      { value: 'Other', label: 'Other' }
    ],
    KO: [
      { value: '체이스 은행', label: '체이스 은행' },
      { value: '웰스파고', label: '웰스파고' },
      { value: '뱅크 오브 아메리카', label: '뱅크 오브 아메리카' },
      { value: '시티은행', label: '시티은행' },
      { value: '홍콩상하이은행', label: '홍콩상하이은행' },
      { value: '바클레이스', label: '바클레이스' },
      { value: '산탄데르', label: '산탄데르' },
      { value: 'UBS', label: 'UBS' },
      { value: 'BNP 파리바', label: 'BNP 파리바' },
      { value: '독일은행', label: '독일은행' },
      { value: '중국공상은행', label: '중국공상은행' },
      { value: '국민은행', label: '국민은행' },
      { value: '농협은행', label: '농협은행' },
      { value: '우리은행', label: '우리은행' },
      { value: '새마을금고', label: '새마을금고' },
      { value: '신한은행', label: '신한은행' },
      { value: '케이뱅크', label: '케이뱅크' },
      { value: '카카오뱅크', label: '카카오뱅크' },
      { value: '하나은행', label: '하나은행' },
      { value: '우체국은행', label: '우체국은행' },
      { value: '신협중앙회', label: '신협중앙회' },
      { value: 'SC제일은행', label: 'SC제일은행' },
      { value: '경남은행', label: '경남은행' },
      { value: '광주은행', label: '광주은행' },
      { value: '신한금융투자', label: '신한금융투자' },
      { value: '기업은행', label: '기업은행' },
      { value: '수협입은행', label: '수협입은행' },
      { value: '농협회원조합', label: '농협회원조합' },
      { value: '대구은행', label: '대구은행' },
      { value: '뱅크오브아메리카', label: '뱅크오브아메리카' },
      { value: '부산은행', label: '부산은행' },
      { value: '산림조합중앙회', label: '산림조합중앙회' },
      { value: '산업은행', label: '산업은행' },
      { value: '상호신용금고', label: '상호신용금고' },
      { value: '수출입은행', label: '수출입은행' },
      { value: '외환은행', label: '외환은행' },
      { value: '전북은행', label: '전북은행' },
      { value: '제주은행', label: '제주은행' },
      { value: '한국씨티은행', label: '한국씨티은행' },
      { value: '홍콩상하이은행', label: '홍콩상하이은행' },
      { value: '기타', label: '기타' }
    ],
    JA: [
      { value: 'チェイス銀行', label: 'チェイス銀行' },
      { value: 'ウェルズ・ファーゴ', label: 'ウェルズ・ファーゴ' },
      { value: 'バンク・オブ・アメリカ', label: 'バンク・オブ・アメリカ' },
      { value: 'シティバンク', label: 'シティバンク' },
      { value: 'HSBC', label: 'HSBC' },
      { value: 'バークレイズ', label: 'バークレイズ' },
      { value: 'サンタンデール', label: 'サンタンデール' },
      { value: 'UBS', label: 'UBS' },
      { value: 'BNPパリバ', label: 'BNPパリバ' },
      { value: 'ドイツ銀行', label: 'ドイツ銀行' },
      { value: '中国工商銀行', label: '中国工商銀行' },
      { value: '国民銀行', label: '国民銀行' },
      { value: '農協銀行', label: '農協銀行' },
      { value: 'ウリ銀行', label: 'ウリ銀行' },
      { value: '새마을금고', label: '새마을금고' },
      { value: '新韓銀行', label: '新韓銀行' },
      { value: 'K銀行', label: 'K銀行' },
      { value: 'ココアバンク', label: 'ココアバンク' },
      { value: '銀行業務', label: '銀行業務' },
      { value: '郵便局銀行', label: '郵便局銀行' },
      { value: '全国漁業協同組合連合会', label: '全国漁業協同組合連合会' },
      { value: 'SC제일은행', label: 'SC제일은행' },
      { value: '경남은행', label: '경남은행' },
      { value: '광주은행', label: '광주은행' },
      { value: '신한금융투자', label: '신한금융투자' },
      { value: '기업은행', label: '기업은행' },
      { value: '수협입은행', label: '수협입은행' },
      { value: '농협회원조합', label: '농협회원조합' },
      { value: '대구은행', label: '대구은행' },
      { value: '뱅크오브아메리카', label: '뱅크오브아메리카' },
      { value: '부산은행', label: '부산은행' },
      { value: '산림조합중앙회', label: '산림조합중앙회' },
      { value: '산업은행', label: '산업은행' },
      { value: '상호신용금고', label: '상호신용금고' },
      { value: '수출입은행', label: '수출입은행' },
      { value: '외환은행', label: '외환은행' },
      { value: '전북은행', label: '전북은행' },
      { value: '제주은행', label: '제주은행' },
      { value: '한국씨티은행', label: '한국씨티은행' },
      { value: '홍콩상하이은행', label: '홍콩상하이은행' },
      { value: 'その他', label: 'その他' }
    ]
  }

  const sortedBankArr = (arr, lang) => {
    if (lang === 'EN') {
      return arr.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    } else {
      return arr
    }
  }
  const banksArr = {
    EN: sortedBankArr(bankNameOptions.EN, 'EN'),
    KO: sortedBankArr(bankNameOptions.KO, 'KO'),
    JA: sortedBankArr(bankNameOptions.JA, 'JA')
  }
  return {
    handleShowModal,
    setShowKycRequestAction,
    showKycRequestAction,
    handleConfirmActionRequest,
    KycRequestAction,
    kycEditForm,
    setKycEditForm,
    dispatch,
    banksArr
  }
}

export default useSetting
