import React from 'react'
import UniqueTicketsListing from './components/UniqueTicketsListing'
import useUniqueTickets from './useUniqueTickets'
import { Button, Form } from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'

const UniqueTickets = ({ isTenant }) => {
  const {
    userLotteryTickets,
    loading,
    date,
    setDate,
    limit,
    setLimit,
    pageNo,
    navigate,
    setPageNo,
    actionType,
    setActionType
  } = useUniqueTickets({ isTenant })
  return (
    <div className='p-2'>
      <h5> Bonus Tickets</h5>
      <div className='my-3 d-flex gap-2'>
        <div className='d-flex gap-2 '>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            Date Start
          </Form.Label>
          <Form.Control
            type='datetime-local'
            placeholder='Select start date and time'
            value={date.from || ''}
            onChange={(e) => {
              setDate({ ...date, from: e.target.value })
              setPageNo(1)
            }}
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
          />
        </div>
        <div className='d-flex'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            Date End
          </Form.Label>
          <Form.Control
            type='datetime-local'
            placeholder='Select end date and time'
            value={date.to || ''}
            onChange={(e) => {
              setDate({ ...date, to: e.target.value })
              setPageNo(1)
            }}
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
          />
        </div>
        <div className='d-flex ' xs='auto'>
          <Form.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            Action Type
          </Form.Label>
          <Form.Select
            name='isActive'
            size='sm'
            value={actionType}
            onChange={(e) => {
              setActionType(e.target.value)
              setPageNo(1)
            }}
            style={{ maxWidth: '230px' }}
          >
            <option value=''>
              All
            </option>
            <option value='WIN' key='WIN'>
              WIN
            </option>
            <option value='LOSE' key='LOSE'>
              LOSE
            </option>
          </Form.Select>
        </div>
        <div className='d-flex'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setDate({ from: '', to: '' })
                setPageNo(1)
                setActionType('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </div>
      </div>
      <UniqueTicketsListing
        ticketList={userLotteryTickets?.rows}
        count={userLotteryTickets?.count}
        limit={limit}
        setLimit={setLimit}
        page={pageNo}
        setPage={setPageNo}
        totalPages={Math.ceil(userLotteryTickets?.count / limit)}
        navigate={navigate}
        loading={loading}
      />
    </div>
  )
}

export default UniqueTickets
