import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setEmailCredsStart, updateProfileStart } from '../../store/redux-slices/login'
import { getAffiliateAllDetails, getSuperAdminDetails, getTenantOwnerDetails } from '../../utils/apiCalls'
import { useTranslation } from 'react-i18next'

const useProfilePage = ({ isTenant, isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('overview')
  const [editable, setEditable] = useState(false)
  const [editableCreds, setEditableCreds] = useState(false)
  const [details, setDetails] = useState()
  const { loading, resetPassLoading } = useSelector(state => state.login)
  const { adminDetails } = useSelector(state => state.admins)

  const updateData = (data) => {
    dispatch(updateProfileStart({
      data,
      isTenant
    }))
    setEditable(false)
  }

  const updateCredentials = ({ data }) => {
    dispatch(setEmailCredsStart({
      data: {
        sendgridKey: Buffer.from(data.sendgridKey).toString('base64'),
        sendgridEmail: Buffer.from(data.sendgridEmail).toString('base64')
      }
    }))
    setEditableCreds(false)
  }

  async function fetchData () {
    if (isAgent) {
      (await getAffiliateAllDetails({ isAgent, isAffiliate }).then((res) => {
        setDetails(res?.data?.data?.agentDetails)
      }))
    } else if (isAffiliate) {
      (await getAffiliateAllDetails({ isAgent, isAffiliate }).then((res) => {
        setDetails(res?.data?.data?.affiliateDetails)
      }))
    } else {
      isTenant
        ? (await getTenantOwnerDetails().then((res) => {
            setDetails(res?.data?.data?.adminDetails)
          }))
        : (await getSuperAdminDetails().then((res) => {
            setDetails(res?.data?.data?.adminDetails)
          }))
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedTab])

  useEffect(() => {
    const intervalId = setInterval(() => {
      return fetchData()
    }, 30000)
    return () => clearInterval(intervalId)
  }, [])

  return {
    details,
    selectedTab,
    setSelectedTab,
    navigate,
    editable,
    setEditable,
    updateData,
    loading,
    editableCreds,
    updateCredentials,
    adminDetails,
    setEditableCreds,
    resetPassLoading,
    fetchData,
    t
  }
}

export default useProfilePage
