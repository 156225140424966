import {
  Row,
  Col,
  Button,
  Form as BForm
} from '@themesberg/react-bootstrap'
import React from 'react'
import ClientFilter from '../../../components/ClientFilter'
// import PortalFilter from '../../../components/PortalFilter'
import { tableHeaders } from './bonusConstant'
import useBonus from './hooks/useBonus'
import { bonusType } from './components/CreateBonus/constants'
import BonusListing from '../../../components/BonusListing'
import useCheckPermission from '../../../utils/checkPermission'
import { SuperAdminRoutes } from '../../../routes'
import { GiveBonus, GiveFreeSpins } from '../../../components/BonusIssue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'

const BonusManagement = () => {
  const {
    selectedClient,
    setSelectedPortal,
    setSelectedClient,
    bonusList,
    navigate,
    loading,
    handleShow,
    show,
    setShow,
    handleYes,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    bonusTyp,
    setBonusTyp,
    isActive,
    setIsActive,
    name,
    showGiveModal,
    setShowGiveModal,
    showDeleteModal,
    setShowDeleteModal,
    showFreeSpinModal,
    setShowFreeSpinModal,
    t
  } = useBonus()
  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>{t('bonus')}</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
            <>
              <Button
                className='m-1'
                size='sm'
                variant='outline-success'
                hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                onClick={() => navigate(SuperAdminRoutes.CreateBonus)}
              >
                {t('create')}
              </Button>
            </>
          </div>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col className='d-flex mt-2' xs='auto'>
          <BForm.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            {t('bonusType')}
          </BForm.Label>
          <BForm.Select
            name='bonusType'
            size='sm'
            value={bonusTyp}
            onChange={(e) => {
              setBonusTyp(e.target.value)
              setPage(1)
            }}
            style={{ maxWidth: '230px' }}
          >
            <option value='' key=''>
              {t('all')}
            </option>
            {bonusType.map((item) => {
              return (
                <option key={`bonusType ${item.value}`} value={item.value}>
                  {t(item?.label)}
                </option>
              )
            })}
          </BForm.Select>
        </Col>
        <Col className='d-flex mt-2' xs='auto'>
          <BForm.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            {t('status')}
          </BForm.Label>
          <BForm.Select
            name='isActive'
            size='sm'
            value={isActive}
            onChange={(e) => {
              setIsActive(e.target.value)
              setPage(1)
            }}
            style={{ maxWidth: '230px' }}
          >
            <option value='' key=''>
              {t('all')}
            </option>
            <option value='true' key='active'>
              {t('active')}
            </option>
            <option value='false' key='in-active'>
              {t('inactive')}
            </option>
          </BForm.Select>
        </Col>
        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setIsActive('')
                setBonusTyp('')
                setSelectedClient('')
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <BonusListing
        bonusList={bonusList}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        tableHeaders={tableHeaders}
        show={show}
        setShow={setShow}
        handleYes={handleYes}
        active={active}
        handleShow={handleShow}
        navigate={navigate}
        name={name}
        loading={loading}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        t={t}
      />

      {showGiveModal &&
        <GiveBonus
          showModal={showGiveModal}
          setShowModal={setShowGiveModal}
        />}

      {showFreeSpinModal &&
        <GiveFreeSpins
          showModal={showFreeSpinModal}
          setShowModal={setShowFreeSpinModal}
        />}
    </div>
  )
}

export default BonusManagement
