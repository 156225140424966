import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getAllReferalStart } from '../../../../store/redux-slices/referrals'
import { useNavigate } from 'react-router-dom'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'

const UseReferrals = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { referralData, loading } = useSelector((state) => state.referral)
  const totalPages = Math.ceil(referralData?.count / limit)
  useEffect(() => {
    dispatch(getAllReferalStart({ search, limit, pageNo: page }))
  }, [search, limit, page])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }

  const handleCsvDownload = () =>
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/user/user-referral?search=${search || ''}&csvDownload=true&token=${getLoginToken()}`)

  return {
    search,
    setSearch,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    referralData,
    navigate,
    handleCsvDownload,
    inputRef,
    loading,
    t
  }
}

export default UseReferrals
