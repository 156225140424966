import React, { useEffect, useState } from 'react'

import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import { BONUSES, checkLabels, daysLabels } from './constants'
import Trigger from '../../../../../components/OverlayTrigger'
import CasinoGames from '../../../CasinoGames'
import Users from '../../../Users'
import { toast } from '../../../../../components/Toast'
import { getTime } from '../../../../../utils/dateFormatter'
const TAB = {
  1: 'players',
  2: 'games'
}

const GeneralForm = ({
  setSelectedLimit,
  selectedLimit,
  isEdit,
  isClone,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  state,
  setState,
  preview,
  handleImagePreview,
  bonusDetail,
  clone = false,
  navigate,
  clientsFilterData,
  setSelectedClient,
  portalOptions,
  getBalanceBonusesOptions,
  isTenant,
  setGameIds,
  wageringTemplateList,
  setEnableSubmit,
  appliedBonusOptions,
  data,
  setData,
  setCountryData,
  selectedGames,
  setSelectedGames,
  selectedPlayers,
  setSelectedPlayers,
  setFieldError,
  setFieldTouched,
  selectedClient,
  bonusDropDown,
  t
}) => {
  const [activeTab, setActiveTab] = useState(

    isEdit
      ? (bonusDetail?.user_id?.length > 0)
          ? TAB[1]
          : (bonusDetail?.gameIds?.length > 0) ? TAB[2] : null
      : TAB[1]

  )
  const handleActiveTab = (type) => {
    if (type === TAB[1]) {
      if (!values.adminId) {
        toast.error('Please choose senior first to select players')
      }
    }
    setActiveTab(type)
  }

  useEffect(() => {
    if (values.bonusType === 'balance' && values.tenantIds?.[0]) {
      if (values.tenantIds) {
        getBalanceBonusesOptions(values.tenantIds?.[0].value)
      }
    }
  }, [values.tenantIds, values.bonusType])

  const camelToText = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  useEffect(() => {
    if (bonusDetail?.gameIds && setGameIds) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  const isCompensation = values?.bonusType === BONUSES.compensation_bonus?.isActive
  const isRecurring = values?.bonusType === BONUSES.re_occurring_bonus?.isActive
  const compareEndTime = (endDate) => {
    const startDateTime = new Date(state[0]?.startDate)
    const endDateTime = new Date(endDate)
    if (startDateTime < endDateTime) {
      return true
    } else {
      return false
    }
  }

  const compareStartTime = (startDate) => {
    const startDateTime = new Date(startDate)
    const endDateTime = new Date(state[0]?.endDate)
    if (startDateTime < endDateTime) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Row className=' mt-3 mb-3'>
        {!isTenant &&
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='adminId'
              className={
        touched.adminId && errors.adminId ? 'text-danger' : ''
      }
            >
              {t('senior')}<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
        touched.adminId && errors.adminId ? 'border border-danger' : ''
      }
            >
              <BForm.Select
                disabled={isEdit}
                name='adminId'
                value={values.adminId}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  setSelectedClient(e.target.value)
                  setFieldValue('tenantIds', [])
                  setEnableSubmit(false)
                }}
              >
                <option value='' disabled key=''>
                  {t('select_owner')}
                </option>
                {clientsFilterData?.rows?.map((admin) => (
                  <option value={admin.adminUserId} key={admin.adminUserId}>
                    {admin?.firstName + ' ' + admin?.lastName}
                  </option>
                ))}
              </BForm.Select>
            </InputGroup>
          </Col>}
        {selectedClient || isEdit
          ? (
            <Col>
              <label>
                {t('bonus_type')} <span className='text-danger'>*</span>
              </label>

              <InputGroup
                className={
          touched.bonusType && errors.bonusType ? 'border border-danger' : ''
        }
              >
                <BForm.Select
                  name='bonusType'
                  value={values.bonusType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                >
                  <option value={isEdit ? bonusDetail?.bonusType : ''}>
                    {
            isEdit ? t(BONUSES[bonusDetail?.bonusType]?.label) : t('select_bonus_type')
          }
                  </option>
                  {selectedClient || isEdit
                    ? (
                        bonusDropDown?.find(item => item['AdminUser.adminUserId'] === parseInt(selectedClient))?.dropdown?.map(
                          (item, index) => (
                            <option
                              key={index}
                              value={item}
                            >{item}
                            </option>
                          )
                        )
                      )
                    : <></>}
                </BForm.Select>
              </InputGroup>
            </Col>
            )
          : <></>}

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='depositBonusPercent'
            className={
        touched.depositBonusPercent && errors.depositBonusPercent
          ? 'text-danger'
          : ''
      }
          >
            {values.bonusType === 'balance'
              ? t('min_balance_percentage')
              : t('bonus_percentage')}
            <span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
        touched.depositBonusPercent && errors.depositBonusPercent
          ? 'border border-danger'
          : ''
      }
          >
            <Trigger message='Value will consider in percentage'>
              <BForm.Control
                name='depositBonusPercent'
                disabled={isEdit && bonusDetail?.claimedCount}
                type='number'
                min={1}
                placeholder={
            values.bonusType === 'balance'
              ? t('enter_min_balance_percentage')
              : t('enter_bonus_percentage')
          }
                defaultValue={values.depositBonusPercent}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Trigger>
          </InputGroup>
          <ErrorMessage
            component='div'
            name='depositBonusPercent'
            className='text-danger'
          />
        </Col>

        {(values?.bonusType !== 'deposit' && values?.bonusType !== 'wagering') &&
          <Col sm={8} className='mb-3'>

            <div className='d-flex gap-4 w-full'>
              <div className='flex-grow-1'>
                <label className={touched.validFrom && errors.validFrom ? 'text-danger' : ''}>
                  {t('bonus_valid_from')}<span className='text-danger'> *</span>
                </label>
                <InputGroup
                  className={touched.validFrom && errors.validFrom
                    ? 'border border-danger'
                    : ''}
                >
                  <BForm.Control
                    min={new Date().toISOString().slice(0, -8)}
                    type='datetime-local'
                    placeholder='Select start date and time'
                    defaultValue={isEdit ? values.validFrom : state[0].startDate}
                    value={state[0].startDate}
                    onChange={(e) => {
                      setState([{ ...state[0], startDate: e.target.value }])
                      if (compareStartTime(e.target.value)) {
                        setState([{ ...state[0], startDate: e.target.value }])
                      } else {
                        toast(`End date time should not be more than ${getTime(state[0].endDate)}`, 'error')
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className='flex-grow-1'>
                <label className={touched.validTo && errors.validTo ? 'text-danger' : ''}>
                  {t('bonus_valid_to')}<span className='text-danger'> *</span>
                </label>
                <InputGroup
                  className={
              touched.validTo && errors.validTo
                ? 'border border-danger'
                : ''
            }
                > <BForm.Control
                  min={new Date(state[0].startDate).toISOString().slice(0, -8)}
                  type='datetime-local'
                  placeholder='Select end date and time'
                  defaultValue={isEdit ? values.validTo : state[0].endDate}
                  value={state[0].endDate}
                  onChange={(e) => {
                    setState([{ ...state[0], endDate: e.target.value }])
                    if (compareEndTime(e.target.value)) {
                      setState([{ ...state[0], endDate: e.target.value }])
                    } else {
                      toast(`End Date time should not be less than ${getTime(state[0]?.startDate)}`, 'error')
                    }
                  }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>}

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='maxBonusLimit'
            className={
        touched.maxBonusLimit && errors.maxBonusLimit ? 'text-danger' : ''
      }
          >
            {t('maximum_bonus_limit')}<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
        touched.maxBonusLimit && errors.maxBonusLimit
          ? 'border border-danger'
          : ''
      }
          >
            <BForm.Control
              name='maxBonusLimit'
              type='number'
              min={1}
              placeholder={t('enter_max_bonus_limit')}
              value={values.maxBonusLimit}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='maxBonusLimit'
            className='text-danger'
          />
        </Col>

        {(isRecurring ||
   (isEdit && BONUSES.re_occurring_bonus?.label.toUpperCase() === BONUSES[bonusDetail?.bonusType]?.label.toUpperCase())) &&
  (
    <>
      <Col sm={4} className='mb-3'>
        <label
          htmlFor='withdrawl_rate'
          className={
        touched.withdrawl_rate && errors.withdrawl_rate ? 'text-danger' : ''
      }
        >
          {t('withdrawal_rate')}<span className='text-danger'> *</span>
        </label>
        <InputGroup
          className={
        touched.withdrawl_rate && errors.withdrawl_rate
          ? 'border border-danger'
          : ''
      }
        >
          <Trigger message='Value will consider in percentage'>
            <BForm.Control
              name='withdrawl_rate'
              type='number'
              min={1}
              placeholder='Enter withdrawal rate'
              value={values.withdrawl_rate}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Trigger>

        </InputGroup>
        <ErrorMessage
          component='div'
          name='withdrawl_rate'
          className='text-danger'
        />
      </Col>
      <Col sm={4} className='mb-3'>
        <label
          htmlFor='reoccuring_deposite_rate'
          className={
        touched.reoccuring_deposite_rate && errors.reoccuring_deposite_rate ? 'text-danger' : ''
      }
        >
          {t('reoccurring_deposit_rate')}<span className='text-danger'> *</span>
        </label>
        <InputGroup
          className={
        touched.reoccuring_deposite_rate && errors.reoccuring_deposite_rate
          ? 'border border-danger'
          : ''
      }
        >
          <Trigger message='Value will consider in percentage'>
            <BForm.Control
              name='reoccuring_deposite_rate'
              type='number'
              min={1}
              placeholder='Enter reocurring deposit rate %'
              value={values.reoccuring_deposite_rate}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Trigger>
        </InputGroup>
        <ErrorMessage
          component='div'
          name='reoccuring_deposite_rate'
          className='text-danger'
        />
      </Col>
    </>
  )}

        {(isCompensation ||
  (isEdit && BONUSES.compensation_bonus?.label.toUpperCase() === BONUSES[bonusDetail?.bonusType]?.label.toUpperCase())) && (
    <>
      <Col sm={4} className='mb-3'>
        <div className='flex-grow-1'>
          <label
            htmlFor='limitType'
            className={
        touched.limitType && errors.limitType ? 'text-danger' : ''
      }
          >
            {t('bonus_limit')}<span className='text-danger'> *</span>
          </label>
          <InputGroup>
            <BForm.Select
              name='limitType'
              onChange={(e) => {
                setSelectedLimit(e?.target?.value)
              }}
              disabled={isEdit}
              onBlur={handleBlur}
              placeholder='asddsd'
            >
              <option key='' value={isEdit ? bonusDetail.field : ''}>{
                isEdit ? camelToText(bonusDetail.field) : t('select_bonus_limit')
              }
              </option>
              <option key='daily' value='daily'>{t('daily')}</option>
              <option key='weekly' value='weekly'>{t('weekly')}</option>
              <option key='monthly' value='monthly'>{t('monthly')}</option>
            </BForm.Select>
          </InputGroup>
        </div>
      </Col>
      {selectedLimit === 'daily' || (isEdit && bonusDetail.field === 'dailyLimit')
        ? (
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='dailyLimit'
              className={
        touched.dailyLimit && errors.dailyLimit ? 'text-danger' : ''
      }
            >
              {t('daily_limit')}<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
        touched.dailyLimit && errors.dailyLimit
          ? 'border border-danger'
          : ''
      }
            >
              <BForm.Control
                name='dailyLimit'
                type='number'
                min={1}
                placeholder='Enter daily limit'
                value={values.dailyLimit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='dailyLimit'
              className='text-danger'
            />
          </Col>
          )
        : selectedLimit === 'weekly' || (isEdit && bonusDetail.field === 'weeklyLimit')
          ? (
            <Col sm={4} className='mb-3'>
              <label
                htmlFor='weeklyLimit'
                className={
        touched.weeklyLimit && errors.weeklyLimit ? 'text-danger' : ''
      }
              >
                {t('weekly_limit')}<span className='text-danger'> *</span>
              </label>
              <InputGroup
                className={
        touched.weeklyLimit && errors.weeklyLimit
          ? 'border border-danger'
          : ''
      }
              >
                <BForm.Control
                  name='weeklyLimit'
                  type='number'
                  min={1}
                  placeholder='Enter weekly limit'
                  value={values.weeklyLimit}
                  onInput={handleChange}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </InputGroup>
              <ErrorMessage
                component='div'
                name='weeklyLimit'
                className='text-danger'
              />
            </Col>
            )

          : selectedLimit === 'monthly' || (isEdit && bonusDetail.field === 'monthlyLimit')
            ? (
              <Col sm={4} className='mb-3'>
                <label
                  htmlFor='monthlyLimit'
                  className={
        touched.monthlyLimit && errors.monthlyLimit ? 'text-danger' : ''
      }
                >
                  {t('monthly_limit')}<span className='text-danger'> *</span>
                </label>
                <InputGroup
                  className={
        touched.monthlyLimit && errors.monthlyLimit
          ? 'border border-danger'
          : ''
      }
                >
                  <BForm.Control
                    name='monthlyLimit'
                    type='number'
                    min={1}
                    placeholder='Enter monthly limit'
                    value={values.monthlyLimit}
                    onInput={handleChange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputGroup>
                <ErrorMessage
                  component='div'
                  name='monthlyLimit'
                  className='text-danger'
                />
              </Col>
              )
            : null}

      <Col sm={4} className='mb-3'>
        <label
          htmlFor='minutes_for_bonus_given'
          className={
        touched.minutes_for_bonus_given && errors.minutes_for_bonus_given ? 'text-danger' : ''
      }
        >
          {t('minutes_for_bonus_given')}<span className='text-danger'> *</span>
        </label>
        <InputGroup
          className={
        touched.minutes_for_bonus_given && errors.minutes_for_bonus_given
          ? 'border border-danger'
          : ''
      }
        >
          <BForm.Control
            name='minutes_for_bonus_given'
            type='number'
            min={1}
            // disabled={isEdit && bonusDetail?.minutes_for_bonus_given}
            placeholder='Enter minutes for bonus given'
            value={values.minutes_for_bonus_given}
            onInput={handleChange}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputGroup>
        <ErrorMessage
          component='div'
          name='minutes_for_bonus_given'
          className='text-danger'
        />
      </Col>
      <Col sm={4} className='mb-3'>
        <div className='flex-grow-1'>
          <label
            htmlFor='compensation_target'
            className={
        touched.compensation_target && errors.compensation_target ? 'text-danger' : ''
      }
          >
            {t('compensation_target')}<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
        touched.compensation_target && errors.compensation_target
          ? 'border border-danger'
          : ''
      }
          >
            <BForm.Control
              name='compensation_target'
              type='number'
              min={1}
              placeholder='Enter compensation target'
              value={values.compensation_target}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='compensation_target'
            className='text-danger'
          />
        </div>
      </Col>

    </>
        )}
      </Row>

      <Row className='mb-3'>
        {checkLabels(values.bonusType).map(({ label, value, message }) => {
          return (
            <Col
              key={`ffddChecked ${label}`}
              sm={3}
              className='d-flex justify-content-start '
            >

              <Col>
                <label htmlFor={value}>
                  {t(label.toLowerCase())}
                </label>
              </Col>

              <Col sm={4} className='mb-3'>
                <BForm.Check
                  name={value}
                  type='switch'
                  disabled={isEdit && bonusDetail?.claimedCount}
                  checked={values[value]}
                  onChange={(e) => {
                    handleChange(e)
                    if (label === 'Visible In Promotions') {
                      setFieldValue('validOnDays', [])
                    }
                  }}
                />
              </Col>
            </Col>
          )
        })}
      </Row>

      <Row className='mb-3'>
        <label
          htmlFor='days'
          className={
      'mb-2' && touched.validOnDays && errors.validOnDays
        ? 'text-danger d-flex'
        : 'd-flex'
    }
        >
          {t('validOnDays')}
          <span className='text-danger'> * </span>&nbsp;
          <BForm.Check
            name='select-all'
            type='checkbox'
            disabled={isEdit && bonusDetail?.claimedCount}
            checked={values?.validOnDays?.length === 7}
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue('validOnDays', [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday'
                ])
              } else {
                setFieldValue('validOnDays', [])
              }
            }}
          />
        </label>
        {daysLabels.map((days) => {
          const camelCaseDays = days.replace(/\s+/g, '').toLowerCase()
          return (
            <Col className='d-flex mb-3' key={camelCaseDays}>
              <Col className='d-flex'>
                <BForm.Check
                  name={camelCaseDays}
                  type='checkbox'
                  disabled={isEdit && bonusDetail?.claimedCount}
                  checked={
              values?.validOnDays && values?.validOnDays.includes(days)
            }
                  onChange={(e) => {
                    if (e.target.checked) {
                      values?.validOnDays?.length
                        ? setFieldValue('validOnDays', [
                            ...values.validOnDays,
                            e.target.name
                          ])
                        : setFieldValue('validOnDays', [e.target.name])
                    } else {
                      setFieldValue(
                        'validOnDays',
                        values.validOnDays.filter(
                          (day) => day !== e.target.name
                        )
                      )
                    }
                  }}
                />
                <label className='mx-2' htmlFor={camelCaseDays}>
                  {t(days)}
                </label>
              </Col>
            </Col>
          )
        })}
      </Row>

      <>
        <Row>

          <Col sm={12}>
            <div className='d-flex gap-2 align-items-center'>

              {isEdit && (
                <>
                  {bonusDetail?.user_id?.length > 0 &&
        (
          <Button
            variant={activeTab === TAB[1] ? 'warning' : 'outline-warning'}
            onClick={() => handleActiveTab(TAB[1])}
            className='w-100'
          >
            {t('players')}
          </Button>
        )}
                  {bonusDetail?.gameIds?.length > 0 && isCompensation &&
        (
          <Button
            variant={activeTab === TAB[2] ? 'warning' : 'outline-warning'}
            onClick={() => handleActiveTab(TAB[2])}
            className='w-100'
          >
            {t('games')}
          </Button>
        )}
                </>
              )}

              {!isEdit && (
                <>
                  <Button
                    variant={activeTab === TAB[1] ? 'warning' : 'outline-warning'}
                    onClick={() => handleActiveTab(TAB[1])}
                    className='w-100'
                  >
                    {t('players')}
                  </Button>
                  {isCompensation && (
                    <Button
                      variant={activeTab === TAB[2] ? 'warning' : 'outline-warning'}
                      onClick={() => handleActiveTab(TAB[2])}
                      className='w-100'
                    >
                      {t('games')}
                    </Button>
                  )}

                </>
              )}

            </div>

            {activeTab !== null && (
              <div className='py-2'>
                <b>{t('note')}:</b> {t('chooseNote', { tab: activeTab === TAB[2] ? t('games') : t('users') })}
              </div>
            )}
          </Col>

          {activeTab === TAB[1] &&
  (
    <Col sm={12} className='mb-3'>
      {values.adminId && (
        <Users
          adminId={values.adminId}
          isCreateBonus
          selectedPlayers={selectedPlayers}
          setSelectedPlayers={setSelectedPlayers}
        />
      )}
    </Col>
  )}
          {activeTab === TAB[2] &&
  (
    <Col sm={12} className='mb-3'>
      <CasinoGames
        isCreateBonus
        selectedGames={selectedGames}
        setSelectedGames={setSelectedGames}
      />
    </Col>
  )}
        </Row>

      </>
      <div className='mt-4 d-flex align-align-items-center gap-4'>
        <Button
          variant='outline-warning'
          onClick={() => navigate(-1)}
          className='ml-2'
        >
          {t('cancel')}
        </Button>
        <Button
          className='ml-2'
          variant='outline-success'
          onClick={() => {
            handleSubmit()
          }}

        >
          {t('submit')}
        </Button>
      </div>
    </>
  )
}
export default GeneralForm
