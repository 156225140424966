
import React, { useEffect, useRef } from 'react'
import { Button, Col, Form, Table } from '@themesberg/react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// import ClientFilter from '../../../components/ClientFilter'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import useAddonsReportingHook from './useAddonsReportingHook'
import { useLocation } from 'react-router-dom'
import { tableHeaders } from './constant'
import { formatNumber } from '../../../utils/storageUtils'
import moment from 'moment'
import PaginationComponent from '../../../components/Pagination'
import { debounce } from 'lodash'
import 'toolcool-range-slider'
// import 'toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js'
const AddonsReporting = () => {
  const { pathname } = useLocation()
  const isBonus = !pathname.includes('deposit')

  const {
    formData,
    setFormData,
    addonReportingData,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    state,
    setState,
    handleCsvDownloadUser,
    initialDate,
    inputRef,
    loading,
    t,
    selectedClient,
    setSelectedClient,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    totalPages
  } = useAddonsReportingHook(isBonus)
  const sliderRef = useRef()
  useEffect(() => {
    const slider = sliderRef.current
    const onChange = (e) => {
      const { value } = e.detail
      setFormData({
        amountLimit: value
      })
    }

    slider?.addEventListener('change', debounce(onChange, 1000))

    return () => {
      slider?.removeEventListener('change', debounce(onChange, 1000))
    }
  }, [])
  return (
    <div className='p-3'>
      <Col>
        <h3>{t('depositAddonReporting')}</h3>
      </Col>
      <div className='d-flex align-items-center flex-wrap'>
        <Col xs='auto' className='d-flex mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchName')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        {/* <Col xs='auto' className='d-flex mt-2'>
          <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </Col> */}
        <Col xs='auto' className='d-flex mt-2'>
          <AffiliateAgentFilter
            selectedClient={selectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
          />
        </Col>
        <Col xs='auto' className='d-flex flex-column m-2'>
          <div className='d-flex flex-column align-items-center'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '5px',
                fontSize: '13px'
              }}
            >
              {t('amount')}
            </Form.Label>
            <tc-range-slider
              step='100'
              max='10000000'
              value={formData?.amountLimit}
              ref={sliderRef}
              round={0}
              generate-labels='true'
            />
          </div>
          <div className='mt-2'>
            <span style={{ fontSize: 12 }}>
              {t('amountSelected')}: {formatNumber(formData?.amountLimit)}
            </span>
          </div>
        </Col>

        <Col xs='auto'>
          <Trigger message={t('downloadAsCsv')}>
            <Button
              variant='outline-success'
              className='m-1'
              size='sm'
              disabled={addonReportingData?.count === 0}
              onClick={() => {
                handleCsvDownloadUser()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              className='m-1'
              onClick={() => {
                inputRef.current.value = ''
                setFormData(initalWalletState)
                setState(initialDate)
                setSelectedClient('')
                setAgent('')
                setAffiliate('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </div>

      <div>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={h.label}>
                  {t(`${h.label}`)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && addonReportingData &&
            addonReportingData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {t(`${item?.adminName || 'NA'}`)}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {t(`${item?.affiliateName || '-'}`)}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {t(`${item?.agentName || '-'}`)}
                      </span>
                    </td>
                    <td>
                      {t(`${moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || '-'}`)}
                    </td>
                    <td>
                      {t(`${formatNumber(item?.amount)} ₩}`)}
                    </td>
                  </tr>
                )
              }
            )}
            {!loading && addonReportingData?.count === 0
              ? (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >
                    {t('NoDataFound')}
                  </td>
                </tr>
                )
              : <></>}
          </tbody>
        </Table>
        {!loading && addonReportingData?.count !== 0
          ? (
            <PaginationComponent
              page={addonReportingData?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalCount={addonReportingData?.count}
            />
            )
          : <></>}
      </div>
    </div>
  )
}

export default AddonsReporting
