import React from 'react'
import Preloader from '../Preloader'
import Overview from './Overview'
import Password from './Password'
import {
  Tabs,
  Tab,
  Row,
  Col
} from '@themesberg/react-bootstrap'
import useProfilePage from './useProfilePage'
import {
  affiliateAndAgentConstantsTA,
  profileConstants,
  profileConstantsTA
} from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import AccountTransaction from '../../pages/Tenant/UserDetails/components/AccountTransaction'
import { formatNumber } from '../../utils/storageUtils'

export default ({ isTenant, isAffiliate, isAgent }) => {
  const {
    details,
    selectedTab,
    setSelectedTab,
    editable,
    setEditable,
    updateData,
    loading,
    setEditableCreds,
    resetPassLoading,
    navigate,
    t
  } = useProfilePage({ isTenant, isAffiliate, isAgent })

  const isAffiliateAndAgent = isAffiliate || isAgent
  const constant = isAffiliateAndAgent ? affiliateAndAgentConstantsTA : isTenant ? profileConstantsTA : profileConstants
  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div className='p-3'>
            <Row>
              <Col className=' d-flex'>
                <h3>
                  <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                    className='cursor-pointer px-2'
                    onClick={() => navigate(-1)}
                  />
                  {t('profile')}
                </h3>
              </Col>
              {
                isTenant
                  ? (
                    <Col className='px-4'>
                      <h3 className='float-end'>
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className='cursor-pointer px-2'
                          onClick={() => navigate(-1)}
                        />
                        {t('amount')} : {formatNumber(details?.promotionCash) + ' ₩'}
                      </h3>
                    </Col>
                    )
                  : (<></>)
              }
            </Row>

            <Tabs
              activeKey={selectedTab}
              onSelect={tab => {
                setEditable(false)
                setEditableCreds(false)
                setSelectedTab(tab)
              }}
              mountOnEnter
              unmountOnExit
              className='nav-light'
            >
              <Tab eventKey='overview' title={t('overview')}>
                <div className='mt-3'>
                  <Overview
                    details={details}
                    isTenant={isTenant}
                    isAffiliate={isAffiliate}
                    isAgent={isAgent}
                    setEditable={setEditable}
                    editable={editable}
                    updateData={updateData}
                    constant={constant}
                    loading={loading}
                    t={t}
                    isAffiliateAndAgent={isAffiliateAndAgent}
                  />
                </div>
              </Tab>

              <Tab eventKey='password' title={t('resetPassword')}>
                <div className='mt-3'>
                  <Password isAffiliateAndAgent={isAffiliateAndAgent} isAffiliate={isAffiliate} isAgent={isAgent} isTenant={isTenant} loading={resetPassLoading} setSelectedTab={setSelectedTab} t={t} />
                </div>
              </Tab>

              {isTenant
                ? (
                  <Tab eventKey='deposit' title={t('deposit')}>
                    <div className='mt-5'>
                      <Row className='mt-3 d-flex flex-row-reverse '>
                        <AccountTransaction isDeposit buttonTitle={t('deposit')} isTenant tenantDetails={details} />
                      </Row>
                    </div>
                  </Tab>
                  )
                : <></>}

              {
                isTenant
                  ? (
                    <Tab eventKey='withdrawal' title={t('withdrawal')}>
                      <div className='mt-5'>
                        <Row className='mt-3 d-flex flex-row-reverse '>
                          <AccountTransaction buttonTitle={t('withdrawal')} isWithdraw isTenant tenantDetails={details} />
                        </Row>
                      </div>
                    </Tab>
                    )
                  : <></>
              }

            </Tabs>
          </div>
          )}
    </>
  )
}
