import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Accordion,
  Modal,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'

import Preloader from '../../../components/Preloader'
import useEmailTemplate from './hooks/useEmailTemplate'
import Trigger from '../../../components/OverlayTrigger'
import { useNavigate } from 'react-router-dom'
import { TenantRoutes } from '../../../routes'
import DOMPurify from 'dompurify'

export default () => {
  const navigate = useNavigate()
  const {
    emailTemplates,
    templateCount,
    loading,
    show,
    setShow,
    setModalData,
    templateData,
    makePrimaryEmailTemplatesStart,
    dispatch,
    isHidden,
    emailTemplateOrder,
    lang,
    setLang,
    languages,
    resetEmail,
    type
  } = useEmailTemplate()
  return (
    <>
      <Row>
        <Col>
          <h3>Email Templates</h3>
        </Col>
        <Col>
          <Col>
            <div className='d-flex justify-content-end align-items-center'>
              <Button
                variant='outline-success'
                className='f-right'
                size='sm'
                hidden={isHidden({ module: { key: 'EmailTemplate', value: 'C' } })}
                onClick={() => {
                  navigate(TenantRoutes.CreateEmailTemplate)
                  resetEmail()
                }}
              >
                Create
              </Button>
            </div>
          </Col>
        </Col>
      </Row>
      {templateCount === 0 && !loading && (
        <Row className='d- flex justify-between-center mt-3'>
          <h4>No Templates Found</h4>
        </Row>
      )}
      <Accordion defaultActiveKey='0'>
        {loading
          ? <Preloader />
          : emailTemplateOrder?.map(
            (template, index) => {
              return emailTemplates?.hasOwnProperty.call(template) && (
                <Accordion.Item
                  eventKey={String(index)}
                  key={`${template}-${index}`}
                >
                  <Accordion.Header>{template}</Accordion.Header>
                  <Accordion.Body>
                    <Table
                      bordered
                      striped
                      responsive
                      hover
                      size='sm'
                      className='text-center mt-4'
                    >
                      <thead className='thead-dark'>
                        <tr>
                          {['Id', 'Label', 'Primary', 'Action'].map((c) => (
                            <th key={c}>{c}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {emailTemplates?.[`${template}`].map(
                          ({
                            emailTemplateId, label, templateCode, isPrimary, type, isDefault
                          }) => {
                            return (
                              <tr key={emailTemplateId}>
                                <td>{emailTemplateId}</td>
                                <td>{label}</td>
                                <td>{isPrimary ? 'Yes' : 'No'}</td>
                                <td>
                                  <ButtonGroup>
                                    {!isDefault && (
                                      <Trigger message='Edit'>
                                        <Button
                                          className='m-1'
                                          size='sm'
                                          variant='warning'
                                          hidden={isHidden({ module: { key: 'EmailTemplate', value: 'U' } })}
                                          onClick={() => navigate(
                                              `/tenant/email-templates/edit/${emailTemplateId}`
                                          )}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                      </Trigger>
                                    )}

                                    <Trigger message='View Template'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='info'
                                        onClick={() => setModalData(template,
                                          templateCode
                                        )}
                                      >
                                        <FontAwesomeIcon icon={faEye} />
                                      </Button>
                                    </Trigger>
                                    <Trigger message='Make Template Primary'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='info'
                                        hidden={isHidden({ module: { key: 'EmailTemplate', value: 'U' } })}
                                        onClick={() => dispatch(
                                          makePrimaryEmailTemplatesStart({
                                            data: {
                                              emailTemplateId,
                                              type
                                            }
                                          })
                                        )}
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </Button>
                                    </Trigger>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              )
            }
          )}
      </Accordion>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName='modal-90w'
        size='lg'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header closeButton>
          <Col>
            <h4>{type}</h4>
          </Col>
          <Col className='m-2'>
            <Form.Select
              name='language'
              size='sm'
              className='w-auto float-end'
              value={lang}
              onChange={(e) => {
                setLang(e.target.value)
              }}
            >
              <option value='EN'>English</option>
              {languages?.length && languages?.map((code) => {
                return code !== 'EN' && templateData?.[code] !== undefined && (
                  <option key={code} value={code}>{code}</option>
                )
              })}
            </Form.Select>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(templateData?.[lang])
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
