import * as Yup from 'yup'

export const addTopSchema = (t, modalData) => {
  return Yup.object().shape({
    isTop: Yup.boolean().required(t('isTopRequired')),
    thumbnail: !modalData?.imageUrl && Yup.mixed().required(t('thumbnailRequired'))
  })
}

export const addHotComboSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(t('nameRequired')),
    match: Yup.string().required(t('matchRequired')),
    market: Yup.string().required(t('marketRequired'))
  })
}
