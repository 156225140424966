import { useSelector } from 'react-redux'
import { GAMMA_EMAILS } from '../../utils/constants'

const useIsGamma = (tenantData) => {
  const { adminDetails } = useSelector(state => state.admins)
  const isGamma = GAMMA_EMAILS.includes(adminDetails?.email)

  return {
    isGamma
  }
}

export default useIsGamma
