import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getAddonReportingDataStart } from '../../../store/redux-slices/admins'

const initalWalletState = {
  search: '',
  amountLimit: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useAddonsReportingHook = (isBonus) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { addonReportingData, addonReportingLoading } = useSelector((state) => state.admins)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const totalPages = Math.ceil(addonReportingData?.count / limit)
  const [state, setState] = useState(initialDate)
  const {
    gameCategory,
    gameSubCategory,
    casinoProvidersData
  } = useSelector((state) => state.superAdminCasino)

  useEffect(() => {
    dispatch(getAddonReportingDataStart({
      form: {
        ...formData,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || '',
        limit,
        pageNo: page,
        type: isBonus ? 'BONUS-ADDON' : 'DEPOSIT-ADDON'
        // adminId: selectedClient,
        // affiliateId: affiliate,
        // agentId: agent
      }
    }))
  }, [formData, limit, page, selectedClient, state, agent, affiliate])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  // useEffect(() => {
  //   dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '' }))
  //   dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '' }))
  //   dispatch(getSuperAdminGameSubCategoryStart({ limit: '', pageNo: '' }))
  // }, [])

  // const handleCsvDownloadCasino = () => {
  //   downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/casino-transaction?adminId=${selectedClient || ''}&actionType=${formData.actionType || ''}&search=${formData.search || ''}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&categoryId=${formData.categoryId}&subCategoryId=${formData.subCategoryId}&providerId=${formData.providerId}&csvDownload=true&token=${getLoginToken()}&affiliateId=${affiliate || ''}&agentId=${agent || ''}`)
  // }
  // const handleCsvDownloadUser = () => {
  //   downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/user-transaction?adminId=${selectedClient || ''}&actionType=${formData?.actionType || ''}&search=${formData?.search || ''}&affiliateId=${affiliate || ''}&agentId=${agent || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  // }
  return {
    formData,
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    addonReportingData,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    state,
    setState,
    // handleCsvDownloadCasino,
    // handleCsvDownloadUser,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    inputRef,
    initialDate,
    addonReportingLoading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useAddonsReportingHook
