import {
  faCheckSquare,
  faEdit,
  faEye,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import ConfirmationModal from '../../ConfirmationModal'
import Trigger from '../../OverlayTrigger'
import PaginationComponent from '../../Pagination'
import useCheckPermission from '../../../utils/checkPermission'
import moment from 'moment'
import { getItem } from '../../../utils/storageUtils'
import { ADMIN_ROLES } from '../../../utils/constants'
import useIsGamma from '../../../common/hook/useIsGamma'

export default ({
  affiliateData,
  limit,
  setLimit,
  page,
  setPage,
  totalPages,
  tableHeaders,
  show,
  setShow,
  handleYes,
  active,
  isTenant,
  handleShow,
  navigate,
  name,
  loading,
  t
}) => {
  const { isHidden } = useCheckPermission()
  const role = getItem('role')
  const isSuperAdminRole = role === ADMIN_ROLES.SUPERADMIN
  const {
    isGamma
  } = useIsGamma()
  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(isSuperAdminRole).map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>{t(h.label)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {affiliateData && !loading &&
            affiliateData?.rows?.map(
              ({
                affiliateId,
                trackingToken,
                username,
                joinDate,
                isActive,
                domain,
                parentUsername,
                parentRole,
                revenuePercentage
              }, index) => {
                return (
                  <tr key={`affiliate-list ${affiliateId}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>{username}</td>
                    <td>{moment(joinDate).format('MM-DD-YYYY HH:mm')}</td>
                    <td>{trackingToken}</td>
                    <td>{domain}</td>
                    {isSuperAdminRole && <td>{revenuePercentage}%</td>}
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inActive')}
                    </td>
                    <td className='text-left'>
                      <ButtonGroup>
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/affiliate/details/${affiliateId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      <ButtonGroup>
                        {
                        !isGamma &&
                        (
                          <Trigger message={t('edit')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                              onClick={() =>
                                navigate(`/${isTenant ? 'tenant' : 'super-admin'}/affiliate/edit/${affiliateId}`)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>
                        )
                       }
                      </ButtonGroup>

                      {
                        !isActive
                          ? (
                            <Trigger message={t('setStatusActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                onClick={() => handleShow(affiliateId, isActive)}
                              >
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </Button>
                            </Trigger>
                            )
                          : (
                            <Trigger message={t('setStatusInactive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='danger'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                onClick={() => handleShow(affiliateId, isActive)}
                              >
                                <FontAwesomeIcon icon={faWindowClose} />
                              </Button>
                            </Trigger>
                            )
                      }

                    </td>
                  </tr>
                )
              }
            )}

          {affiliateData?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {affiliateData?.count > 0 && !loading && (
        <PaginationComponent
          page={affiliateData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={affiliateData?.count}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}
    </>
  )
}
