
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  useDispatch
} from 'react-redux'
import {
  faArrowsAlt
} from '@fortawesome/free-solid-svg-icons'
import { reorderLeaguesStart } from '../../store/redux-slices/superAdminSportsBook'
import Preloader from '../../components/Preloader'

const ReorderComponent = ({ state, setState, loading, isTenant }) => {
  const dispatch = useDispatch()

  const reorder = (subCategories, startIndex, endIndex) => {
    const result = Array.from(subCategories)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const tempData = reorder(
      state,
      result.source.index,
      result.destination.index
    )

    setState(tempData)

    let startIndex = result.source.index
    let endIndex = result.destination.index

    if (endIndex < startIndex) {
      [startIndex, endIndex] = [endIndex, startIndex]
    }

    const formData = isTenant
      ? tempData
          ?.slice(startIndex, endIndex + 1)
          .map((item, index) => {
            return {
              tournamentId: item?.masterTournamentId,
              position: startIndex + index + 1,
              providerTournamentId: item?.providerTournamentId
            }
          })
      : tempData
        ?.slice(startIndex, endIndex + 1)
        .map((item, index) => {
          return {
            tournamentId: item?.masterTournamentId,
            position: startIndex + index + 1
          }
        })
    dispatch(reorderLeaguesStart({ tournaments: formData, isTenant: isTenant }))
  }

  return (
    !loading
      ? (
          state?.length > 0
            ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='list'>
                  {(provided) => (
                    <div className='d-flex flex-column gap-2' ref={provided.innerRef} {...provided.droppableProps}>
                      {state?.length > 0 &&
                      state?.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`id-${item.masterTournamentId}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className='d-flex p-3 rounded-2 border'>
                                <div>{item?.masterTournamentId}</div>
                                <div className='ms-3'>{item?.tournamentName}</div>
                                <div className='ms-auto'><FontAwesomeIcon icon={faArrowsAlt} /></div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              )
            : (
              <p className='text-center text-danger mt-3'> No Data Found </p>
              ))
      : <Preloader />
  )
}

export default ReorderComponent
