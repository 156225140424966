import React from 'react'
import { Button, ButtonGroup, Col, Form, Table, Spinner } from 'react-bootstrap'
import { disableAction, leagueListingTableHeader } from './constants'
import useLeague from './hooks/useLeague'
import PaginationComponent from '../Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faRedoAlt, faWindowClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../ConfirmationModal'
import Trigger from '../OverlayTrigger'
import { useTranslation } from 'react-i18next'
// import useSports from './hooks/useSports'
import EditSports from './EditSports'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'

const LeagueListing = ({
  title,
  isTenant
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    entityId,
    leaguesListing,
    totalPages,
    loading,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    debounceFn,
    // inputRef,
    sportsListing,
    setUpdateData,
    updateData,
    handleUpdateSportAction,
    handleShowModal,
    showModal,
    handleClose,
    modalData,
    type,
    sportsCountries,
    fetchData,
    addTopLoading
  } = useLeague(isTenant)
  const { t } = useTranslation()

  return (
    <div className='my-3'>
      <h3 className='d-flex align-items-center pe-2'>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer px-2'
          onClick={() => navigate(-1)}
        />

        {t(title.toLowerCase())}
        <Button
          className='ms-auto d-block'
          size='sm'
          variant='outline-success'
          onClick={() => navigate(isTenant ? TenantRoutes.ReorderLeagues : SuperAdminRoutes.ReorderLeagues)}
        >
          {t('reorder')}
        </Button>
      </h3>

      <div>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='d-flex align-items-center px-2'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              // ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchTournaments')}
              size='sm'
              style={{
                minWidth: '200px',
                maxWidth: '240px',
                width: 'fit-content'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('status')}
            </Form.Label>
            <Form.Select
              value={filterData?.status}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  status: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {disableAction?.map((actionRequest) =>
                (
                  <option
                    key={actionRequest.value}
                    value={actionRequest.label}
                  >
                    {t(actionRequest?.value)}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('sports')}
            </Form.Label>
            <Form.Select
              value={filterData?.sportId}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  sportId: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {sportsListing?.rows?.map(({
                providerSportId,
                sportName
              }) =>
                (
                  <option
                    key={providerSportId}
                    value={providerSportId}
                  >
                    {sportName}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('country')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '150px', width: 'auto' }}
              size='sm'
              value={filterData?.countryName}
              onChange={(e) => {
                setFilterData({ ...filterData, countryName: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {sportsCountries && sportsCountries?.map((country) => (
                <option
                  key={country?.countryName}
                  value={country?.countryName}
                >
                  {country?.countryName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('isTop')}
            </Form.Label>
            <Form.Check
              type='switch'
              id='isTopSwitch'
              checked={filterData.isTop === 'true'} // Assuming isTop is a boolean in filterData
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  isTop: e.target.checked ? 'true' : 'false'
                })
                setPage(1)
              }}
            />
          </div>
          <Col className='d-flex p-3 float-end'>
            <Trigger message='Reset Filters'>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setFilterData(initialState)
                  // inputRef.current.value = ''
                  setPage(1)
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {leagueListingTableHeader.map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {
              !loading && leaguesListing &&
              leaguesListing?.rows?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {(index + 1) + (limit * (page - 1))} </td>
                    <td>
                      {item?.tournamentName || '-'}
                    </td>
                    <td>
                      {item?.providerTournamentId || '-'}
                    </td>
                    <td>
                      {item?.sportName || '-'}
                    </td>
                    <td>
                      {item?.imageUrl && <img style={{ maxWidth: '30px', maxHeight: '40px' }} src={item?.imageUrl} alt='Thumbnail' />}
                    </td>
                    <td>
                      {item?.countryName || '-'}
                    </td>
                    <td className={item?.systemStatus ? 'table-success' : 'table-danger'}>
                      {item?.systemStatus ? t('enabled') : t('disabled') || ''}
                    </td>
                    <td>
                      {item?.isTop ? 'true' : 'false'}
                    </td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={!item?.systemStatus ? t('setEnable') : t('setDisable')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant={!item?.systemStatus ? 'success' : 'danger'}
                            onClick={() => {
                              setIsStatusModalOpen(true)
                              setIsDisable(item?.systemStatus)
                              setUpdateData({
                                entityType: 'tournament',
                                status: !item?.systemStatus,
                                entityId: Number(item?.masterTournamentId)
                              })
                            }}
                          >
                            <FontAwesomeIcon icon={!item?.systemStatus ? faArrowCircleLeft : faWindowClose} />
                          </Button>
                        </Trigger>
                        <Trigger message={t('edit')}>
                          <Button
                            className='m-1'
                            size='sm'
                            disabled={addTopLoading === item.masterTournamentId}
                            variant='warning'
                            onClick={() => {
                              handleShowModal('tournaments', item, item.masterTournamentId)
                            }}
                          >
                            {addTopLoading === item.masterTournamentId
                              ? <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              : <FontAwesomeIcon icon={faEdit} />}
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>

                  </tr>
                )
              }
              )
            }
            {leaguesListing?.count === 0
              ? (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
                )
              : <></>}
          </tbody>
        </Table>
        {!loading && leaguesListing?.count !== 0 &&
          (
            <PaginationComponent
              page={leaguesListing?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalCount={leaguesListing?.count}
            />
          )}
      </div>
      {isStatusModalOpen &&
        <ConfirmationModal
          setShow={setIsStatusModalOpen}
          show={isStatusModalOpen}
          handleYes={() => handleUpdateSportAction(updateData)}
          active={isDisable}
          isEnable
        />}
      <EditSports
        handleClose={handleClose}
        show={showModal}
        modalData={modalData}
        type={type}
        subCategories={1}
        limit={limit}
        pageNo={page}
        entityId={entityId}
        statusFilter={1}
        providerId={1}
        t={t}
        isLeague
        fetchData={fetchData}
      />
    </div>
  )
}
export default LeagueListing
// "masterTournamentId": "1",
// "tournamentName": "Ski Alpine 2023/24",
// "providerTournamentId": "1095527",
// "isDisabled": false,
// "sports.sportName": "Alpine Skiing",
// "countries.countryName": "Alpine"
