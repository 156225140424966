export const tableHeaders = [
  { label: 'sno', value: '' },
  { label: 'instigator', value: 'instigatorName' },
  { label: 'undergoer', value: 'underGoer' },
  { label: 'section', value: 'section' },
  { label: 'action', value: 'action' },
  { label: 'reason', value: 'reason' },
  { label: 'description', value: 'description' },
  { label: 'createdAt', value: 'createdAt' },
  { label: 'action', value: 'action' }
]
export const section = [
  { label: 'player', value: 'PLAYER' },
  { label: 'walletRequest', value: 'WALLET-REQUESTED' }
]
export const action = [
  { label: 'blacklist', value: 'BLACKLIST' },
  { label: 'whitelist', value: 'WHITELIST' },
  { label: 'redflag', value: 'RED-FLAG' },
  { label: 'greenflag', value: 'GREEN-FLAG' },
  { label: 'depositAccept', value: 'DEPOSIT-ACCEPT' },
  { label: 'depositReject', value: 'DEPOSIT-REJECT' },
  { label: 'withdrawAccept', value: 'WITHDRAW-ACCEPT' },
  { label: 'withdrawReject', value: 'WITHDRAW-REJECT' },
  { label: 'resetAccept', value: 'PASSWORD-RESET-ACCEPT' },
  { label: 'resetPending', value: 'PASSWORD-RESET-PENDING' },
  { label: 'resetReject', value: 'PASSWORD-RESET-REJECT' },
  { label: 'startSeason', value: 'START-SEASON' },
  { label: 'startEnd', value: 'START-END' },
  { label: 'playerRankIncrement', value: 'PLAYER-RANK-INCREMENT' },
  { label: 'bonusBenefitClaim', value: 'BONUS-BENEFIT-CLAIM' },
  { label: 'cashBenefitClaim', value: 'CASH-BENEFIT-CLAIM' },
  { label: 'withdrawlBenefitClaim', value: 'WITHDRAWL-BENEFIT-CLAIM' }
]
