import { createSlice } from '@reduxjs/toolkit'

const getAllTenantsSlice = createSlice({
  name: 'getAllTenants',
  initialState: {
    loading: false,
    error: null,
    success: false,
    allTenantList: null,
    tenantDetails: null,
    allCredentialsKey: [],
    clientsFilterData: null,
    portalsFilterData: null,
    tenantsList: null,
    ownerPermission: {},
    permissionLoading: false,
    TOManagersData: null,
    userTenantReportingData: [],
    casinoTenantReportingData: [],
    userHistoryTenant: [],
    tenantNotificationList: [],
    tenantReadRecieptList: [],
    updatePasswordMessage: '',
    tenantUpdatePasswordMessage: '',
    tenantSeasons: {},
    tenantSeasonDetails: {},
    rollingReportList: {},
    rollingListLoading: false
  },
  reducers: {
    getRollingReportingTAListStart: (state) => ({
      ...state,
      rollingListLoading: true
    }),
    getRollingReportingTAListSuccess: (state, { payload }) => ({
      ...state,
      rollingReportList: payload,
      rollingListLoading: false
    }),
    getRollingReportingTAListFailure: (state, { payload }) => ({
      ...state,
      rollingListLoading: false
    }),
    updateTenantUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserPasswordSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updatePasswordMessage: payload
    }),
    updateTenantUserPasswordFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    tenantUpdateUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    tenantUpdateUserPasswordSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantUpdatePasswordMessage: payload
    }),
    tenantUpdateUserPasswordFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantNotificationStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantNotificationStatusSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantNotificationStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    createTenantNotificationStart: (state) => ({
      ...state,
      loading: true
    }),
    createTenantNotificationSuccess: (state) => ({
      ...state,
      loading: false
    }),
    createTenantNotificationFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantSpecificNotificationStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantSpecificNotificationSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantNotificationList: payload
    }),
    getAllTenantSpecificNotificationFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantAllReadRecieptStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantAllReadRecieptSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantReadRecieptList: payload
    }),
    getTenantAllReadRecieptFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantAllUserHistoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantAllUserHistorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userHistoryTenant: payload
    }),
    getTenantAllUserHistoryFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getTenantUserReportingDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantUserReportingDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userTenantReportingData: payload
    }),
    getTenantUserReportingDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getTenantCasinoReportingDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantCasinoReportingDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoTenantReportingData: payload
    }),
    getTenantCasinoReportingDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllTenantsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getAllTenantsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allTenantList: payload
    }),
    getAllTenantsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createTenantSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    createTenantAdminStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createTenantAdminSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createTenantAdminFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    getTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getTenantSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantDetails: payload
    }),
    getTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false,
      tenantDetails: null
    }),
    editTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    editTenantSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    editTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    updateTenantStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateTenantStatusSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateTenantStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getAllCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allCredentialsKey: payload
    }),
    getAllCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantAdminStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminComplete: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    addDepositToOtherStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    addDepositToOtherCompleted: (state) => ({
      ...state,
      loading: false
    }),
    getAllClientsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllClientsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      clientsFilterData: payload
    }),
    getAllClientsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllPortalsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllPortalsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      portalsFilterData: payload
    }),
    getAllPortalsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantsListStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantsListSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantsList: payload
    }),
    getAllTenantsListFailure: (state) => ({
      ...state,
      loading: false
    }),
    getOwnerPermissionStart: (state) => ({
      ...state,
      permissionLoading: true
    }),
    getOwnerPermissionSuccess: (state, { payload }) => ({
      ...state,
      permissionLoading: false,
      ownerPermission: { ...payload, Transactions: ['R', 'U'], CMS: ['R', 'U', 'T'], Users: ['R', 'U', 'T', 'AB', 'SR', 'UP', 'RUI', 'SM'], Bonus: ['R', 'U', 'C', 'T', 'Issue'] }
    }),
    getOwnerPermissionFailure: (state) => ({
      ...state,
      permissionLoading: false
    }),
    getOwnerManagersStart: (state) => ({
      ...state,
      loading: true
    }),
    getOwnerManagersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      TOManagersData: payload
    }),
    getOwnerManagersFailure: (state) => ({
      ...state,
      loading: false
    }),

    getAllSeasonsStart: (state) => ({
      ...state,
      loading: true
    }),

    getAllSeasonsSuccess: (state, { payload }) => ({
      ...state,
      tenantSeasons: payload,
      loading: false
    }),

    getAllSeasonsFailure: (state) => ({
      ...state,
      loading: false
    }),

    getAllSeasonsDetailsStart: (state) => ({
      ...state,
      loading: true
    }),

    getAllSeasonsDetailsSuccess: (state, { payload }) => ({
      ...state,
      tenantSeasons: payload,
      loading: false
    }),

    getAllSeasonsDetailsFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default getAllTenantsSlice.reducer

export const {
  getRollingReportingTAListStart,
  getRollingReportingTAListFailure,
  getRollingReportingTAListSuccess,
  updateTenantNotificationStatusStart,
  updateTenantNotificationStatusSuccess,
  updateTenantNotificationStatusFailure,
  getAllTenantSpecificNotificationStart,
  getAllTenantSpecificNotificationSuccess,
  getAllTenantSpecificNotificationFailure,
  getTenantAllReadRecieptStart,
  getTenantAllReadRecieptSuccess,
  getTenantAllReadRecieptFailure,
  getAllTenantsStart,
  getAllTenantsSuccess,
  getAllTenantsFailure,
  createTenantStart,
  createTenantSuccess,
  createTenantFailure,
  createTenantAdminStart,
  createTenantAdminSuccess,
  createTenantAdminFailure,
  getTenantStart,
  getTenantSuccess,
  getTenantFailure,
  editTenantStart,
  editTenantSuccess,
  editTenantFailure,
  updateTenantStatusStart,
  updateTenantStatusSuccess,
  updateTenantStatusFailure,
  getAllCredentialsStart,
  getAllCredentialsSuccess,
  getAllCredentialsFailure,
  createCredentialsStart,
  createCredentialsSuccess,
  createCredentialsFailure,
  updateCredentialsStart,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  updateTenantAdminStart,
  updateTenantAdminComplete,
  addDepositToOtherStart,
  addDepositToOtherCompleted,
  getAllClientsStart,
  getAllClientsSuccess,
  getAllClientsFailure,
  getAllPortalsStart,
  getAllPortalsSuccess,
  getAllPortalsFailure,
  getAllTenantsListStart,
  getAllTenantsListSuccess,
  getAllTenantsListFailure,
  getOwnerPermissionStart,
  getOwnerPermissionSuccess,
  getOwnerPermissionFailure,
  getOwnerManagersStart,
  getOwnerManagersSuccess,
  getOwnerManagersFailure,
  getTenantCasinoReportingDataStart,
  getTenantCasinoReportingDataSuccess,
  getTenantCasinoReportingDataFailure,
  getTenantUserReportingDataStart,
  getTenantUserReportingDataSuccess,
  getTenantUserReportingDataFailure,
  getTenantAllUserHistoryStart,
  getTenantAllUserHistorySuccess,
  getTenantAllUserHistoryFailure,
  createTenantNotificationStart,
  createTenantNotificationSuccess,
  createTenantNotificationFailure,
  updateTenantUserPasswordStart,
  updateTenantUserPasswordSuccess,
  updateTenantUserPasswordFailure,
  tenantUpdateUserPasswordStart,
  tenantUpdateUserPasswordSuccess,
  tenantUpdateUserPasswordFailure,
  getAllSeasonsStart,
  getAllSeasonsSuccess,
  getAllSeasonsFailure,
  getAllSeasonsDetailsStart,
  getAllSeasonsDetailsSuccess,
  getAllSeasonsDetailsFailure

} = getAllTenantsSlice.actions
