import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
// import { serialize } from 'object-to-formdata'
import {
  createAffiliate, getAffiliateDetails, getAffiliates, updateAffiliate, updateAffiliateStatus,
  getAffiliateCountry,
  getBonusAffiliates
} from '../../utils/apiCalls'
import {
  createAffiliateStart,
  createAffiliateSuccess,
  createAffiliateFailure,
  getAffiliateStart,
  getAffiliateSuccess,
  getAffiliateFailure,
  getAffiliateDetailStart,
  getAffiliateDetailSuccess,
  getAffiliateDetailFailure,
  updateAffiliateStatusStart,
  updateAffiliateStatusSuccess,
  updateAffiliateStatusFailure,
  updateAffiliateStart,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  getAffiliateCountryStart,
  getAffiliateCountrySuccess,
  getAffiliateCountryFailure,
  getBonusDetailAffiliateStart,
  getBonusDetailAffiliateSuccess,
  getBonusDetailAffiliateFailure
} from '../redux-slices/affiliate'

export default function * affiliateWatcher () {
  yield takeLatest(getBonusDetailAffiliateStart.type, getBonusDetailAffiliateWorker)
  yield takeLatest(createAffiliateStart.type, createAffiliateWorker)
  yield takeLatest(getAffiliateStart.type, getAffiliateWorker)
  yield takeLatest(getAffiliateCountryStart.type, getAffiliateCountryWorker)
  yield takeLatest(getAffiliateDetailStart.type, getAffiliateDetailWorker)
  yield takeLatest(updateAffiliateStatusStart.type, updateAffiliateStatusWorker)
  yield takeLatest(updateAffiliateStart.type, updateAffiliateWorker)
}

function * getBonusDetailAffiliateWorker (action) {
  try {
    const { data } = yield getBonusAffiliates(action.payload)
    yield put(getBonusDetailAffiliateSuccess(data?.data?.affiliatesDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusDetailAffiliateFailure())
  }
}
function * createAffiliateWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    const encryptedPass = btoa(data.password)

    // data = serialize(data)
    yield createAffiliate({ ...data, password: encryptedPass })
    yield toast('Affiliate Created successfully', 'success')
    navigate(-1)
    yield put(createAffiliateSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createAffiliateFailure())
  }
}

function * updateAffiliateStatusWorker (action) {
  try {
    const {
      formData,
      isTenant,
      handlClose
    } = action && action.payload
    const res = yield updateAffiliateStatus({ data: formData, isTenant })
    if (res.status === 200) {
      yield toast('Affiliate Updated successfully', 'success')
      yield put(updateAffiliateStatusSuccess())
      handlClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAffiliateStatusFailure())
  }
}

function * updateAffiliateWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    // data = serialize(data)
    yield updateAffiliate(data)
    yield toast('Affiliate Updated successfully', 'success')
    navigate(-1)
    yield put(updateAffiliateSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAffiliateFailure())
  }
}

function * getAffiliateWorker (action) {
  try {
    // data = serialize(data)
    const { data } = yield getAffiliates(action.payload)
    yield put(getAffiliateSuccess(data?.data?.affiliateDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAffiliateFailure())
  }
}

function * getAffiliateCountryWorker (action) {
  try {
    const { data } = yield getAffiliateCountry(action && action.payload)
    yield put(getAffiliateCountrySuccess(data?.data?.countries?.rows))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAffiliateCountryFailure())
  }
}

function * getAffiliateDetailWorker (action) {
  try {
    const { data } = yield getAffiliateDetails(action.payload)
    yield put(getAffiliateDetailSuccess(data?.data?.affiliateDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAffiliateDetailFailure())
  }
}
