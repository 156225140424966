import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSeasonDetailStart } from '../../../../store/redux-slices/superAdminSettings'

const useViewSeason = () => {
  const dispatch = useDispatch()
  const { seasonId, tenantId } = useParams()
  const { seasonDetail, loading } = useSelector(state => state.superAdminSettings)
  useEffect(() => {
    dispatch(getSeasonDetailStart({ seasonId, tenantId }))
  }, [seasonId])
  return (
    {
      seasonDetail,
      loading
    }
  )
}

export default useViewSeason
