import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import moment from 'moment'
import { downloadFile } from '../../../utils/fileDownloader'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { getSportsBookTransactionStart } from '../../../store/redux-slices/superAdminTransactions'
import { getMatches } from '../../../utils/apiCalls'

const initalState = {
  betType: '',
  status: '',
  sortKey: 'transactionDate',
  sortBy: 'DESC'
}
const initialDate = [
  {
    from: '',
    to: '',
    key: 'selection'
  }
]
const useSportsTransactions = ({ isV2User, isBetBy, userData = {} }) => {
  const isTenant = !window.location.href.match(/super/g)
  const tenantId = getItem('tenant-id')
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { sportsBookTransactions, userTenantReportingData, loading } = useSelector((state) => state.superAdminTransactions)
  const [formData, setFormData] = useState({ ...initalState, limit, pageNo: page })
  const totalPages = Math.ceil(sportsBookTransactions?.count / limit)
  const totalPagesUser = Math.ceil(userTenantReportingData?.count / limit)
  const [selectedMatch, setSelectedMatch] = useState('')
  const [state, setState] = useState(initialDate)
  const {
    casinoProviders,
    casinoCategories,
    subCategories
  } = useSelector((state) => state.tenantCasino)
  const [selectedClient, setSelectedClient] = useState('')

  useEffect(() => {
    const payload = {
      ...formData,
      from: state[0].from ? moment(state[0].from)?.format('MM-DD-YYYY') : '',
      to: state[0].to ? moment(state[0].to)?.format('MM-DD-YYYY') : '',
      limit,
      pageNo: page,
      isTenant: isTenant,
      masterMatchId: selectedMatch?.masterMatchId || '',
      masterSportId: selectedMatch?.masterSportId || '',
      isV2User: userData?.isV2User ?? (isV2User || false),
      isBetBy
    }

    if (isTenant) {
      payload.tenantId = tenantId
    } else {
      payload.adminUserId = selectedClient
    }

    if (userData?.userId) {
      payload.userId = userData?.userId
    }

    dispatch(getSportsBookTransactionStart({ ...payload }))
  }, [formData, state, selectedClient, limit, page, selectedMatch, isV2User, isBetBy])

  const handleCsvDownloadCasino = () => {
    if (isBetBy) {
      downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/betby/transaction?isV2User=${isV2User}&limit=${limit || ''}&pageNo=${page || ''}&journalEntry=${formData.journalEntry || ''}&status=${formData.status || ''}&from=${formData.from || ''}&to=${formData.to || ''}&username=${formData.username || ''}&csvDownload=true&token=${getLoginToken()}&${isTenant ? `tenantId=${tenantId}` : `adminUserId=${selectedClient}`}${userData?.userId ? `&userId=${userData?.userId}` : ''}`)
    } else {
      downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/transaction?isV2User=${isV2User}&limit=${limit || ''}&pageNo=${page || ''}&journalEntry=${formData.journalEntry || ''}&status=${formData.status || ''}&from=${formData.from || ''}&to=${formData.to || ''}&username=${formData.username || ''}&masterMatchId=${selectedMatch?.masterMatchId || ''}&masterSportId=${selectedMatch?.masterSportId || ''}&csvDownload=true&token=${getLoginToken()}&${isTenant ? `tenantId=${tenantId}` : `adminUserId=${selectedClient}`}`)
    }
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, username: event.target.value }))
  }

  const debounceWithPromise = (func, wait) => {
    const debounced = debounce((resolve, reject, ...args) => {
      func(...args).then(resolve).catch(reject)
    }, wait)

    return (...args) => {
      return new Promise((resolve, reject) => {
        debounced(resolve, reject, ...args)
      })
    }
  }
  const loadOptions = async (inputValue) => {
    const matchRes = await getMatches({ paramsData: { isTenant, matchName: inputValue, limit: 15, pageNo: 1 } })
    return new Promise((resolve, reject) => {
      const newOption = matchRes?.data?.data?.[isTenant ? 'categoryMatches' : 'result']?.rows?.map((item) => {
        return ({
          ...item,
          label: `${item?.homeTeam} VS ${item?.awayTeam}`,
          value: item?.masterMatchId
        })
      })
      resolve(newOption)
    })
  }

  const customAsyncDebounce = useCallback(debounceWithPromise(loadOptions, 1000), [])

  const sortHandler = (type) => {
    if (formData?.sortKey === type) {
      setFormData({ ...formData, sortBy: formData?.sortBy === 'ASC' ? 'DESC' : 'ASC' })
    } else {
      setFormData({ ...formData, sortKey: type })
    }
  }

  return {
    formData,
    setFormData,
    totalPages,
    totalPagesUser,
    page,
    limit,
    setPage,
    sportsBookTransactions,
    userTenantReportingData,
    setLimit,
    initalState,
    state,
    debounceFn,
    setState,
    casinoCategories,
    subCategories,
    casinoProviders,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    selectedClient,
    setSelectedClient,
    t,
    loading,
    customAsyncDebounce,
    setSelectedMatch,
    selectedMatch,
    sortHandler
  }
}

export default useSportsTransactions
