import { createSlice } from '@reduxjs/toolkit'

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    tenantTransactions: {},
    tenantCasinoTransactions: {},
    tenantTransactionUsers: [],
    tenantWalletTransactions: [],
    tenantWalletTransactionsLoading: false,
    updateTenantTransactionAction: null,
    bonusTransactions: {},
    bonusTransactionsLoading: false,
    tenantSeniorWalletTransactions: []
  },
  reducers: {
    tenantWalletRequestStart: (state) => ({
      ...state,
      loading: true
    }),
    tenantWalletRequestSuccess: (state, { payload }) => ({
      ...state,
      payload,
      loading: false
    }),
    tenantWalletRequestFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSeniorTenantWalletListStart: (state) => ({
      ...state,
      tenantWalletTransactionsLoading: true,
      error: null
    }),
    getSeniorTenantWalletListSuccess: (state, { payload }) => ({
      ...state,
      tenantWalletTransactionsLoading: false,
      tenantSeniorWalletTransactions: payload
    }),
    getSeniorTenantWalletListStartFailure: (state) => ({
      ...state,
      tenantWalletTransactionsLoading: false
    }),
    getTenantBonusTransactionsStart: (state) => ({
      ...state,
      bonusTransactionsLoading: true,
      error: null
    }),
    getTenantBonusTransactionsSuccess: (state, { payload }) => ({
      ...state,
      bonusTransactionsLoading: false,
      bonusTransactions: payload
    }),
    getTenantBonusTransactionsFailure: (state) => ({
      ...state,
      bonusTransactionsLoading: false
    }),
    updateTenantTransactionActionStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantTransactionActionSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updateTenantTransactionAction: payload
    }),
    updateTenantTransactionActionFaliure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantWalletTransactionsStart: (state) => ({
      ...state,
      tenantWalletTransactionsLoading: true,
      error: null
    }),
    getAllTenantWalletTransactionsSuccess: (state, { payload }) => ({
      ...state,
      tenantWalletTransactionsLoading: false,
      tenantWalletTransactions: payload
    }),
    getAllTenantWalletTransactionsFailure: (state) => ({
      ...state,
      tenantWalletTransactionsLoading: false
    }),
    getAllTenantTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTenantTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantTransactions: payload
    }),
    getAllTenantTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantTransactionUsersStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getTenantTransactionUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantTransactionUsers: payload
    }),
    getTenantTransactionUsersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantCasinoTransactionsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantCasinoTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantCasinoTransactions: payload
    }),
    getAllTenantCasinoTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    depositTenantAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    depositTenantAmountSuccess: (state) => ({
      ...state,
      loading: false
    }),
    depositTenantAmountFailure: (state) => ({
      ...state,
      loading: false
    }),
    withdrawalTenantAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    withdrawalTenantAmountSuccess: (state) => ({
      ...state,
      loading: false
    }),
    withdrawalTenantAmountFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default transactionsSlice.reducer

export const {
  tenantWalletRequestStart,
  tenantWalletRequestSuccess,
  tenantWalletRequestFailure,
  getAllTenantTransactionsStart,
  getAllTenantTransactionsSuccess,
  getTenantTransactionUsersStart,
  getTenantTransactionUsersSuccess,
  getAllTenantTransactionsFailure,
  getTenantTransactionUsersFailure,
  getAllTenantCasinoTransactionsStart,
  getAllTenantCasinoTransactionsSuccess,
  getAllTenantCasinoTransactionsFailure,
  getAllTenantWalletTransactionsStart,
  getAllTenantWalletTransactionsSuccess,
  getAllTenantWalletTransactionsFailure,
  updateTenantTransactionActionStart,
  updateTenantTransactionActionSuccess,
  updateTenantTransactionActionFaliure,
  depositTenantAmountStart,
  depositTenantAmountSuccess,
  depositTenantAmountFailure,
  withdrawalTenantAmountStart,
  withdrawalTenantAmountSuccess,
  withdrawalTenantAmountFailure,
  getTenantBonusTransactionsStart,
  getTenantBonusTransactionsSuccess,
  getTenantBonusTransactionsFailure,
  getSeniorTenantWalletListStart,
  getSeniorTenantWalletListSuccess,
  getSeniorTenantWalletListFailure
} = transactionsSlice.actions
