export const eventHeader = [
  's,no',
  'name',
  'description',
  'thumbnail',
  'startDate',
  'endDate',
  'isActive',
  'action'
]
