import React from 'react'
import { Button, ButtonGroup, Col, Form, Table, Spinner } from 'react-bootstrap'
import { disableAction, sportsListingTableHeader } from './constants'
import useSports from './hooks/useSports'
import PaginationComponent from '../Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faCheckCircle, faRedoAlt, faWindowClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../OverlayTrigger'
import ConfirmationModal from '../ConfirmationModal'
import { useTranslation } from 'react-i18next'
import EditSports from './EditSports'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'

const SportListing = ({
  title,
  isTenant
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    type,
    navigate,
    filterData,
    setFilterData,
    entityId,
    modalData,
    initialState,
    sportsListing,
    loading,
    totalPages,
    debounceFn,
    inputRef,
    showModal,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    handleShowModal,
    handleUpdateSportAction,
    updateData,
    handleClose,
    setUpdateData,
    fetchData,
    addTopLoading
  } = useSports(isTenant)
  const { t } = useTranslation()

  return (
    <div>
      <h3>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer px-2'
          onClick={() => navigate(-1)}
        />
        {t(title.toLowerCase())}
        <Button
          className='ms-auto d-block'
          size='sm'
          variant='outline-success'
          onClick={() => navigate(isTenant ? TenantRoutes.ReorderSports : SuperAdminRoutes.ReorderSports)}
        >
          {t('reorder')}
        </Button>
      </h3>
      <div className='p-3'>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='d-flex align-items-center px-2'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('sports')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              defaultValue={inputRef?.current?.value}
              name='search'
              type='search'
              placeholder={t('searchSports')}
              size='sm'
              style={{
                minWidth: '200px',
                maxWidth: '240px',
                width: 'fit-content'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('sportsStatus')}
            </Form.Label>
            <Form.Select
              value={filterData?.status}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  status: e.target.value,
                  ...(e.target.value === 'false' && { isTop: 'false' })
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {disableAction?.map((actionRequest) =>
                (
                  <option
                    key={actionRequest.value}
                    value={actionRequest.label}
                  >
                    {t(actionRequest?.value)}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('isTop')}
            </Form.Label>
            <Form.Check
              type='switch'
              id='isTopSwitch'
              checked={filterData.isTop === 'true'} // Assuming isTop is a boolean in filterData
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  isTop: e.target.checked ? 'true' : 'false'
                })
              }}
            />
          </div>

          <Col className='d-flex p-3 float-end'>
            <Trigger message='Reset Filters'>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setFilterData(initialState)
                  inputRef.current.value = ''
                  setPage(1)
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {sportsListingTableHeader.map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && sportsListing &&
              sportsListing?.rows?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {(index + 1) + (limit * (page - 1))}</td>
                    <td>
                      {item?.sportName || '-'}
                    </td>
                    <td>
                      {item?.providerSportId || '-'}
                    </td>
                    <td className={item?.systemStatus ? 'table-success' : 'table-danger'}>
                      {item?.systemStatus ? t('enabled') : t('disabled') || ''}
                    </td>
                    <td>
                      {item?.imageUrl && <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={item?.imageUrl} alt='Thumbnail' />}
                    </td>
                    <td>
                      {item?.isTop ? 'true' : 'false'}
                    </td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={!item?.systemStatus ? t('setEnable') : t('setDisable')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant={!item?.systemStatus ? 'success' : 'danger'}
                            onClick={() => {
                              setIsStatusModalOpen(true)
                              setIsDisable(item?.systemStatus)
                              setUpdateData({
                                entityType: 'sport',
                                status: !item?.systemStatus,
                                entityId: Number(item?.masterSportId)
                              })
                            }}
                          >
                            <FontAwesomeIcon icon={!item?.systemStatus ? faCheckCircle : faWindowClose} />
                          </Button>
                        </Trigger>
                        <Trigger message={t('edit')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='warning'
                            disabled={addTopLoading === item.masterSportId}
                            onClick={() => {
                              handleShowModal('sports', item, item.masterSportId)
                            }}
                          >

                            {addTopLoading === item.masterSportId
                              ? <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              : <FontAwesomeIcon icon={faEdit} />}

                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
              )}
            {!loading && sportsListing?.count === 0
              ? (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >

                    No data found
                  </td>
                </tr>
                )
              : <></>}
          </tbody>
        </Table>
        {!loading && sportsListing.count !== 0 &&
          (
            <PaginationComponent
              page={sportsListing.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalCount={sportsListing.count}
            />
          )}
        {isStatusModalOpen &&
          <ConfirmationModal
            setShow={setIsStatusModalOpen}
            show={isStatusModalOpen}
            handleYes={() => handleUpdateSportAction(updateData)}
            active={isDisable}
            isEnable
          />}
      </div>
      <EditSports
        handleClose={handleClose}
        show={showModal}
        modalData={modalData}
        type={type}
        subCategories={1}
        limit={limit}
        pageNo={page}
        entityId={entityId}
        statusFilter={1}
        providerId={1}
        t={t}
        fetchData={fetchData}
      />

    </div>
  )
}
export default SportListing
