import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../../routes'
import { useTranslation } from 'react-i18next'

const SignAsSelect = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const AdminSignin = pathname.includes(SuperAdminRoutes.SuperAdminSignin)
  const TenantSignin = pathname.includes(TenantRoutes.TenantSignin)
  const AffiliateSignin = pathname.includes(AffiliateRoutes.AffiliateSignin)
  const AgentSignin = pathname.includes(AgentRoutes.AgentSignin)
  const activeRoute = () => {
    if (AdminSignin) {
      return SuperAdminRoutes.SuperAdminSignin
    } else if (TenantSignin) {
      return TenantRoutes.TenantSignin
    } else if (AffiliateSignin) {
      return AffiliateRoutes.AffiliateSignin
    } else if (AgentSignin) {
      return AgentRoutes.AgentSignin
    }
  }
  const [active, setActive] = useState(activeRoute())
  const routesArr = [
    {
      name: 'signInAsSuperAdmin',
      path: SuperAdminRoutes.SuperAdminSignin
    },
    {
      name: 'signInAsTenant',
      path: TenantRoutes.TenantSignin
    },
    {
      name: 'signInAsAffiliate',
      path: AffiliateRoutes.AffiliateSignin
    },
    {
      name: 'signInAsAgent',
      path: AgentRoutes.AgentSignin
    }

  ]
  return (
    <div>
      <div className='d-flex justify-content-center mb-1'>{t('SignInOption')}</div>

      <div className='mb-4'>
        <Form.Select
          value={active}
          onChange={(e) => {
            setActive(e.target.value)
            navigate(e.target.value)
          }}
        >
          {routesArr?.map((item, index) => {
            return (
              <option
                key={index}
                value={item?.path}
              >
                {t(item?.name)}
              </option>
            )
          })}
        </Form.Select>
      </div>

    </div>

  )
}

export default SignAsSelect
