import React from 'react'
import useLevelTemplates, { levelTableHeaders } from './hooks/useLevelTemplates'
import { Button, Form, Table } from 'react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import CreateAndEditTemplates from './components/CreateAndEditTemplates'
import Preloader from '../../../components/Preloader'
import PaginationComponent from '../../../components/Pagination'
import DeleteModal from '../../../common/Modals/DeleteModal'
import { returnLangName } from '../../../utils/storageUtils'
import useIsGamma from '../../../common/hook/useIsGamma'

const LevelTemplates = () => {
  const {
    isGamma
  } = useIsGamma()
  const {
    formattedRanks,
    t,
    selectedClient,
    setSelectedClient,
    loading,
    navigate,
    isCreateTemplateModalOpen,
    setIsCreateTemplateModalOpen,
    allTemplateList,
    setRefetch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    templateData,
    setTemplateData,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setDeleteTemplateData,
    handleDeleteTemplate,
    debounceFn
  } = useLevelTemplates()
  return (
    <>
      {loading && <Preloader />}
      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
          <h3>{t('listOfTemplates')}</h3>
          {
          !isGamma && (
            <Button variant='outline-success' onClick={() => setIsCreateTemplateModalOpen(true)}>
              {t('addTemplate')}
            </Button>
          )
          }
        </div>
        <div className='mb-2 d-flex align-items-center flex-wrap'>
          <div className='d-flex align-self-center mt-2'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>
          <div className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              placeholder={t('searchByTemplate')}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPageNo(1)
              }}
            />
          </div>
        </div>
        <div className='list'>
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {levelTableHeaders.map((h, idx) => (
                  <th key={idx}>
                    {t(h)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>

              {allTemplateList?.templates?.count > 0 && !loading
                ? allTemplateList?.templates?.rows?.map((template, index) => (
                  <tr key={template?.templateId}>
                    <td> {(index + 1) + (limit * (pageNo - 1))}</td>
                    <td>{returnLangName(template?.templateName)}</td>
                    <td>
                      <Trigger message={t('viewDetails')}>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() => {
                            navigate(
                              `/super-admin/template-list/${template?.templateId}`
                            )
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </Trigger>

                      <Trigger message={t('editTemplate')}>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() => {
                            setTemplateData(template)
                            setIsCreateTemplateModalOpen(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>
                      {!template?.defaultTemplate && (
                        <Trigger message={t('deleteTemplate')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() => {
                              setDeleteTemplateData({
                                templateId: template?.templateId,
                                tenantId: template?.tenantId
                              })
                              setIsDeleteModalOpen(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Trigger>
                      )}
                    </td>
                  </tr>
                  ))
                : (
                  <tr>
                    <td colSpan={3} className='text-danger text-center'>
                      {t('noDataFound')}
                    </td>
                  </tr>
                  )}

            </tbody>
          </Table>
          {allTemplateList?.templates?.count > 0 && !loading && (
            <PaginationComponent
              page={allTemplateList?.templates?.count < pageNo ? setPageNo(1) : pageNo}
              totalPages={Math.ceil(allTemplateList?.templates?.count / limit)}
              setPage={setPageNo}
              limit={limit}
              setLimit={setLimit}
              totalCount={allTemplateList?.templates?.count}
            />
          )}
        </div>
      </div>
      {isCreateTemplateModalOpen
        ? <CreateAndEditTemplates
            type={templateData ? 'editTemplate' : 'addTemplate'}
            templateData={templateData}
            setRefetch={setRefetch}
            ranks={formattedRanks}
            show={isCreateTemplateModalOpen}
            setIsCreateTemplateModalOpen={setIsCreateTemplateModalOpen}
            handleClose={() => {
              setIsCreateTemplateModalOpen(false)
              setTemplateData(null)
            }}
          />
        : null}
      <DeleteModal
        show={isDeleteModalOpen}
        handleClose={() => {
          setIsDeleteModalOpen(false)
          setDeleteTemplateData(null)
        }}
        handleDeleteYes={() => {
          handleDeleteTemplate()
        }}
      />

    </>
  )
}

export default LevelTemplates
