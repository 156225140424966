import React from 'react'
import useBonusUsers from './useBonusUsers'
import BonusUsersListing from './components/BonusUsersListing'
import { Col, Form, Row } from 'react-bootstrap'

const BonusUsers = ({ isTenant }) => {
  const {
    bonusUsers,
    loading,
    handleSearch,
    limit,
    setLimit,
    pageNo,
    navigate,
    setPageNo
  } = useBonusUsers({ isTenant })
  return (
    <div className='p-2'>
      <h5> Bonus users</h5>
      <Row className='my-2 gap-2'>
        <Col className='d-flex align-self-center '>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            Search
          </Form.Label>
          <Form.Control
            type='search'
            placeholder='Search Username'
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '15px',
              fontSize: '13px'
            }}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <BonusUsersListing
        isTenant={isTenant}
        userList={bonusUsers?.bonusSpecificUsers}
        count={bonusUsers?.count}
        limit={limit}
        setLimit={setLimit}
        page={pageNo}
        setPage={setPageNo}
        totalPages={Math.ceil(bonusUsers?.count / limit)}
        navigate={navigate}
        loading={loading}
      />
    </div>
  )
}

export default BonusUsers
