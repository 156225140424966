import { useDispatch, useSelector } from 'react-redux'
import {
  updateCategoryProivderBasedSystemRtpActionStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import { useState, useEffect } from 'react'

const useProviderCategoryDefaultRtpUpdater = ({ fetchData }) => {
  const dispatch = useDispatch()
  const [providerIds, setProviderIds] = useState([])
  const [categoryIds, setCategoryIds] = useState([])
  const [providerList, setProviderList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const {
    loading
  } = useSelector((state) => state.superAdminCasino)

  const { allProviders, gameCategory } = useSelector((state) => state.superAdminCasino)

  useEffect(() => {
    const casinoProviders = []
    const casinoCategories = []
    allProviders.rows.forEach(function (provider) {
      casinoProviders.push({ label: provider?.name, value: provider?.masterCasinoProviderId })
    })
    gameCategory.rows.forEach(function (category) {
      casinoCategories.push({ label: category?.name?.EN, value: category?.mastergamecategoryid })
    })
    setProviderList(casinoProviders)
    setCategoryList(casinoCategories)
  }, [])

  const updateProviderCategorySystemRtp = (data, handleClose) => {
    dispatch(
      updateCategoryProivderBasedSystemRtpActionStart({
        data: data,
        onSuccess: () => {
          handleClose()
          fetchData()
        }
      })
    )
  }

  return {
    allProviders,
    loading,
    providerIds,
    setProviderIds,
    categoryIds,
    setCategoryIds,
    providerList,
    updateProviderCategorySystemRtp,
    categoryList
  }
}

export default useProviderCategoryDefaultRtpUpdater
