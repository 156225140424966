import { Button, Row, Form as BForm, Col, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import useEditSports from './hooks/useEditSports'
import { addTopSchema } from './schema'

const EditSports = ({
  handleClose,
  show,
  modalData,
  type,
  subCategories,
  limit,
  pageNo,
  entityId,
  statusFilter,
  providerId,
  t,
  fetchData,
  isLeague
}) => {
  const isTenant = !window.location.href.match(/super/g)
  const {
    addTop
  } = useEditSports()
  const [thumbnailFile, setThumbnailFile] = useState(null)
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          {
            isTop: modalData?.sadminTopOnly ? false : modalData?.isTop,
            thumbnail: modalData?.imageUrl || thumbnailFile
          }
        }
        validationSchema={addTopSchema(t, modalData)}
        onSubmit={(formValues) => {
          const formData = new FormData()
          formData.append('status', formValues.isTop)
          if (thumbnailFile) {
            formData.append('file', thumbnailFile)
          }
          formData.append('entityId', entityId)
          formData.append('entityType', type)
          if (isLeague) {
            formData.append('position', modalData?.position || '')
          }
          if (isLeague && isTenant) {
            formData.append('sadminTopOnly', modalData?.sadminTopOnly)
          }
          addTop({
            data: {
              formData,
              isTenant: isTenant,
              fetchData,
              entityId
            }
          })
          handleClose()
          setThumbnailFile(null)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset
        }) => (
          <Form>
            <Modal
              show={show}
              onHide={() => {
                handleClose()
                setThumbnailFile(null)
              }}
              backdrop='static'
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <h4 className='d-flex align-items-center'>
                  {type === 'sports' ? t('editSport') : t('editLeague')}
                </h4>
              </Modal.Header>
              <Modal.Body>
                <Row className='mt-3'>
                  <Col className='d-grid'>
                    <BForm.Label>{t('thumbnail')}{<span className='text-danger'> *</span>} </BForm.Label>
                    <BForm.Text>
                      <input
                        id='file'
                        name='thumbnail'
                        onBlur={handleBlur}
                        type='file'
                        onChange={(event) => {
                          setThumbnailFile(event.currentTarget.files[0])
                        }}
                      />
                      {
                        thumbnailFile
                          ? (
                            <img
                              alt={t('Thumbnail Preview')}
                              width='50px'
                              src={URL.createObjectURL(thumbnailFile)}
                            />
                            )
                          : (
                              modalData?.imageUrl && (
                                <img alt='not found' style={{ maxWidth: '300px', maxHeight: '300px' }} src={typeof modalData?.imageUrl !== 'string' ? URL.createObjectURL(modalData?.imageUrl) : modalData?.imageUrl} />
                              )
                            )
                      }
                    </BForm.Text>
                    <ErrorMessage
                      component='div'
                      name='thumbnail'
                      className='text-danger'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <BForm.Label>
                      isTop<span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Switch
                      type='switch'
                      name='isTop'
                      value={values.isTop}
                      checked={values.isTop}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='isTop'
                      className='text-danger'
                    />
                  </Col>
                </Row>

              </Modal.Body>

              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      handleClose()
                      handleReset()
                      setThumbnailFile(null)
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    {t('submit')}
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditSports
