/* eslint-disable react/prop-types */
import React from 'react'
import ReactApexChart from 'react-apexcharts'

const PieChart = ({ series, labels, colors, heigth = 350, width = 350 }) => {
  const data = {
    series: series || [0],
    options: {
      chart: {
        type: 'pie'
      },
      labels: labels || [''],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: '',
        verticalAlign: 'middle'
      },
      colors: colors
    }
  }

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type='pie'
      width={heigth}
      height={width}
    />
  )
}

export default PieChart
