import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import moment from 'moment'
import {
  tableHeaders,
  actionSources,
  requestActions,
  transactionType
} from './constants'
import {
  ButtonGroup,
  Col,
  Form,
  Row
} from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import { WalletActionConfirmModal } from '../../../components/ConfirmationModal'
import PaginationComponent from '../../../components/Pagination'
import ClientFilter from '../../../components/ClientFilter'
import useSeniorWallet from './hooks/useSeniorWallet'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../utils/storageUtils'
const SeniorWallet = () => {
  const {
    state,
    setState,
    formData,
    setFormData,
    // allCurrencies,
    handleShowModal,
    setShowWalletRequestAction,
    showWalletRequestAction,
    seniorWalletTransaction,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    handleConfirmActionRequest,
    selectedClient,
    setSelectedClient,
    handleCsvDownload,
    inputRef,
    initialDate,
    loading,
    t,
    status
  } = useSeniorWallet()

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('wallet')}</h3>
          </Col>
        </Col>
      </Row>
      <div>
        <div className='d-flex flex-wrap align-items-center gap-2'>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('requestStatus')}
            </Form.Label>
            <Form.Select
              value={formData?.status}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  status: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>
              {requestActions?.map((requestAction) => (
                <option
                  key={requestAction.value}
                  value={requestAction.value}
                >
                  {t(requestAction.label)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div xs='auto' className='d-flex '>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          {/* <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('currency')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
              size='sm'
              value={formData?.currency}
              onChange={(e) => setFormData({ ...formData, currency: e.target.value })}
            >
              <option value=''>{t('all')}</option>
              {allCurrencies && allCurrencies?.map((currency) => (
                <option
                  key={currency?.code}
                  value={currency?.code}
                >
                  {currency?.name}
                </option>
              ))}
            </Form.Select>
          </div> */}
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('transactionType')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '150px', width: 'auto' }}
              size='sm'
              value={formData?.transactionType}
              onChange={(e) => {
                setFormData({ ...formData, transactionType: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {transactionType?.map((transactionRequest) => (
                <option
                  key={transactionRequest.value}
                  value={transactionRequest.value}
                >
                  {t(transactionRequest.label)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm'
              style={{ marginBottom: '0px', marginRight: '15px', minWidth: 'fit-content' }}
            >
              {t('transactionAction')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
              size='sm'
              value={formData?.actionType}
              onChange={(e) => {
                setFormData({ ...formData, actionType: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {actionSources?.map((action) => (
                <option
                  key={action.value}
                  value={action.value}
                >
                  {t(action.label)}
                </option>
              ))}
            </Form.Select>

          </div>
          <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchUsernameOrName')}
              size='sm'
              style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div className='d-flex align-items-center gap-2'>
            <div className='d-flex  '>
              <Trigger message={t('downloadAsCSV')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  disabled={seniorWalletTransaction?.seniorWalletRequests?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
            </div>
            <div className='d-flex  '>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  className='py-1'
                  size='sm'
                  onClick={() => {
                    setFormData(initalWalletState)
                    setState(initialDate)
                    setSelectedClient('')
                    inputRef.current.value = ''
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>

        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && seniorWalletTransaction &&
            seniorWalletTransaction?.seniorWalletRequests?.rows?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <span
                      style={{
                        width: '130px',
                        cursor: 'pointer'
                      }}
                      className='d-inline-block text-truncate'
                    >
                      {item?.domain || 'NA'}
                    </span>
                  </td>
                  <td>
                    {item?.name || 'NA'}
                  </td>
                  <td className='text-wrap'>
                    {item?.transactionId || 'NA'}
                  </td>
                  <td>
                    {item?.requestStatus || 'NA'}
                  </td>
                  <td>
                    {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || '-'}
                  </td>
                  <td>
                    {item?.amount || item?.currency
                      ? formatNumber(item?.amount) + ' ' + item?.currency
                      : '-'}
                  </td>
                  <td>
                    {item?.actionType || 'NA'}
                  </td>
                  <td>
                    {item?.transactionType || 'REQUESTED'}
                  </td>
                  <td>
                    {item.requestStatus === 'PENDING'
                      ? (
                        <ButtonGroup>
                          <Trigger message={t('acceptRequest')}>
                            <Button
                              className='m-1 fw-bolder'
                              size='sm'
                              disabled={item?.requestStatus !== 'PENDING'}
                              variant='success'
                              onClick={() => handleShowModal(
                                item?.transactionId,
                                item?.seniorId,
                                'ACCEPT',
                                item?.seniorWalletRequestId
                              )}
                            >
                              {t('accept')}
                            </Button>
                          </Trigger>
                          <Trigger message={t('rejectRequest')}>
                            <Button
                              className='m-1 fw-bolder'
                              size='sm'
                              variant='danger'
                              disabled={item?.requestStatus !== 'PENDING'}
                              onClick={() => handleShowModal(
                                item?.transactionId,
                                item?.seniorId,
                                'REJECT',
                                item?.seniorWalletRequestId
                              )}
                            >
                              {t('reject')}
                            </Button>
                          </Trigger>
                        </ButtonGroup>
                        )
                      : (
                          item.requestStatus === 'ACCEPT' || item?.transactionType === 'NON REQUEST'
                            ? <span className='fw-bolder text-success'>{t('accepted')}</span>
                            : <span className='fw-bolder text-danger'>{t('rejected')}</span>
                        )}
                  </td>
                </tr>
              )
            })}
          {!loading && seniorWalletTransaction?.seniorWalletRequests?.count === 0
            ? (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && seniorWalletTransaction?.seniorWalletRequests?.count !== 0 && (
        <PaginationComponent
          page={seniorWalletTransaction?.seniorWalletRequests?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={seniorWalletTransaction?.seniorWalletRequests?.count}
        />
      )}
      {showWalletRequestAction && (
        <WalletActionConfirmModal
          action={status}
          setShow={setShowWalletRequestAction}
          show={showWalletRequestAction}
          handleConfirmActionRequest={handleConfirmActionRequest}
        />
      )}
    </div>
  )
}

export default SeniorWallet
