import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllSeasonsDetailsStart } from '../../../../store/redux-slices/tenants'

const useViewSeason = () => {
  const dispatch = useDispatch()
  const { tenantSeasons: seasonDetail, loading } = useSelector(state => state.tenants)
  const { seasonId, tenantId } = useParams()

  useEffect(() => {
    dispatch(getAllSeasonsDetailsStart({ seasonId, tenantId }))
  }, [seasonId])
  return (
    {
      seasonDetail,
      loading
    }
  )
}

export default useViewSeason
