import { createSlice } from '@reduxjs/toolkit'

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: {
    loading: false,
    error: null,
    adminUsers: [],
    adminUserDetails: {},
    groups: [],
    userCurrentStatus: 0,
    userStatusLoading: false,
    noteMessage: '',
    kycUpdateMessage: '',
    userInspectionDetailsLoading: false,
    userInspectionDetails: {},
    ggrData: [],
    affiliateAndAgentsList: {},
    userForNotification: {}
  },
  reducers: {
    getUserForNotificationsStart: (state) => ({
      ...state,
      userLoading: true
    }),
    getUserForNotificationsSuccess: (state, { payload }) => ({
      ...state,
      userForNotification: payload,
      userLoading: false
    }),
    getUserForNotificationsFailure: (state) => ({
      ...state,
      userLoading: false
    }),
    getUserForNotificationsReset: (state) => ({
      ...state,
      userForNotification: {}
    }),
    getAgentFilterListStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateAndAgentsListStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateAndAgentsListSuccess: (state, { payload }) => ({
      ...state,
      affiliateAndAgentsList: payload,
      loading: false
    }),
    getAffiliateAndAgentsListFailure: (state) => ({
      ...state,
      loading: false
    }),
    getUserGGRStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserGGRSuccess: (state, { payload }) => ({
      ...state,
      ggrData: payload,
      loading: false
    }),
    getUserGGRFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateUserKycDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserKycDetailsSuccess: (state, { payload }) => ({
      ...state,
      kycUpdateMessage: payload,
      loading: false
    }),
    updateUserKycDetailsFailure: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminUserNoteStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminUserNoteSuccess: (state, { payload }) => ({
      ...state,
      noteMessage: payload,
      loading: false
    }),
    updateSuperAdminUserNoteFailure: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminBetNoteStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminBetNoteSuccess: (state, { payload }) => ({
      ...state,
      noteMessage: payload,
      loading: false
    }),
    updateSuperAdminBetNoteFailure: (state) => ({
      ...state,
      loading: true
    }),
    getAdminUsersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminUsers: payload
    }),
    getAdminUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),

    getAdminUserDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminUserDetails: payload
    }),
    getAdminUserDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createAdminUserStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createAdminUserSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createAdminUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    updateSuperAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    createSuperAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    createSuperAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperAdminStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllGroupsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllGroupsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      groups: payload
    }),
    getAllGroupsFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getUserStatusStart: (state) => ({
      ...state,
      userStatusLoading: true
    }),
    getUserStatusSuccess: (state, { payload }) => ({
      ...state,
      userStatusLoading: false,
      userCurrentStatus: payload
    }),
    getUserStatusFailure: (state, { payload }) => ({
      ...state,
      userStatusLoading: false
    }),
    getUserInspectionDetailsStart: (state) => ({
      ...state,
      userInspectionDetailsLoading: true
    }),
    getUserInspectionDetailsSuccess: (state, { payload }) => ({
      ...state,
      userInspectionDetailsLoading: false,
      userInspectionDetails: payload
    }),
    getUserInspectionDetailsFailure: (state, { payload }) => ({
      ...state,
      userInspectionDetailsLoading: false
    })
  }
})

export default adminUserSlice.reducer
export const {
  getUserForNotificationsStart,
  getUserForNotificationsSuccess,
  getUserForNotificationsFailure,
  getUserForNotificationsReset,
  getAgentFilterListStart,
  getAdminUsersStart,
  getAdminUsersSuccess,
  getAdminUsersFailure,
  getAdminUserDetailsStart,
  getAdminUserDetailsSuccess,
  getAdminUserDetailsFailure,
  createAdminUserStart,
  createAdminUserSuccess,
  createAdminUserFailure,
  updateSuperAdminUserStart,
  updateSuperAdminUserComplete,
  createSuperAdminUserStart,
  createSuperAdminUserComplete,
  updateSuperAdminStatusStart,
  updateSuperAdminStatusComplete,
  updateTenantAdminUserStart,
  updateTenantAdminUserComplete,
  getAllGroupsStart,
  getAllGroupsSuccess,
  getAllGroupsFailure,
  getUserStatusStart,
  getUserStatusSuccess,
  getUserStatusFailure,
  updateSuperAdminUserNoteStart,
  updateSuperAdminUserNoteSuccess,
  updateSuperAdminUserNoteFailure,
  updateSuperAdminBetNoteStart,
  updateSuperAdminBetNoteSuccess,
  updateSuperAdminBetNoteFailure,
  updateUserKycDetailsStart,
  updateUserKycDetailsSuccess,
  updateUserKycDetailsFailure,
  getUserInspectionDetailsStart,
  getUserInspectionDetailsSuccess,
  getUserInspectionDetailsFailure,
  getUserGGRStart,
  getUserGGRSuccess,
  getUserGGRFailure,
  getAffiliateAndAgentsListStart,
  getAffiliateAndAgentsListSuccess,
  getAffiliateAndAgentsListFailure
} = adminUserSlice.actions
