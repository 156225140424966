export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'subCategory', value: 'name' },
  { label: 'status', value: 'status' },
  { label: 'category', value: 'categoryname' },
  { label: 'actions', value: 'Actions' }
]

export const allowedKeysforOrder = [
  'tenantGameSubCategoryId',
  'name'
]
