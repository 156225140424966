import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { getVoucherGameStart } from '../../../../store/redux-slices/voucher'
import {
  getSuperAdminGameSubCategoryStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getAllSubCategoriesStart } from '../../../../store/redux-slices/tenantCasino'

const useVoucherGames = ({ isTenant }) => {
  const navigate = useNavigate()
  const inputRef = useRef()

  const { voucherGames, loading } = useSelector(state => state.voucher)
  const { gameSubCategory } = useSelector(
    (state) => state.superAdminCasino
  )
  const { subCategories } = useSelector((state) => state.tenantCasino)
  const subcategories = isTenant ? subCategories : gameSubCategory
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('')
  const { voucherId } = useParams()

  const handleSearch = debounce((e) => {
    setSearch(e.target.value)
    setPageNo(1)
  }, 1000)

  useEffect(() => {
    dispatch(getVoucherGameStart({ limit, pageNo, search, voucherId, isTenant, casinoCategoryId: selectedSubCategoryId, providerId: selectedProvider }))
  }, [limit, pageNo, search, selectedProvider, selectedSubCategoryId])

  useEffect(() => {
    if (!isTenant) {
      dispatch(
        getSuperAdminGameSubCategoryStart({
          limit: '',
          pageNo: '',
          gameCategoryId: '',
          search: '',
          isActive: '',
          tenantId: ''
        })
      )
    } else {
      dispatch(
        getAllSubCategoriesStart({
          limit: '',
          pageNo: '',
          categoryId: '',
          search: '',
          isActive: '',
          orderBy: '',
          sort: ''
        }))
    }
  }, [])
  return {
    voucherGames,
    loading,
    search,
    setSearch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    navigate,
    handleSearch,
    selectedProvider,
    setSelectedProvider,
    selectedSubCategoryId,
    setSelectedSubCategoryId,
    subcategories,
    inputRef
  }
}

export default useVoucherGames
