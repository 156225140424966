import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllTenantCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getUserTransactionDetailsStart } from '../../../../store/redux-slices/tenantUsers'
import { debounce } from 'lodash'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import moment from 'moment'

const initalWalletState = {
  actionSource: '',
  actionType: '',
  search: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useUserTransactionList = ({
  selectedTab
}) => {
  const inputRef = useRef()
  const { userId } = useParams()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { allTenantCurrencies } = useSelector(state => state.currencies)
  const { tenantCountries } = useSelector((state) => state.fetch)
  const { userTransactionDetails, loading: userTransactionLoading } = useSelector((state) => state.tenantUsers)
  const [state, setState] = useState(initialDate)
  const [formData, setFormData] = useState({
    ...initalWalletState,
    limit,
    pageNo: page,
    userId
  })

  useEffect(() => {
    if (selectedTab === 'transactions-banking') {
      dispatch(getUserTransactionDetailsStart({
        form: {
          ...formData,
          limit,
          pageNo: page,
          userId,
          from: moment(state[0].startDate).toISOString() || '',
          to: moment(state[0].endDate).toISOString() || ''
        }
      }))
    }
  }, [selectedTab, formData, limit, page, state])

  const totalPages = Math.ceil(userTransactionDetails?.count / limit)
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  useEffect(() => {
    dispatch(getAllTenantCurrenciesStart({
      limit: 500,
      pageNo: 1
    }))
    dispatch(getTenantCountriesStart())
  }, [])

  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/tenant/user-wallet-transactions?pageNo=${page || ''}&userId=${formData.userId || ''}&actionType=${formData.actionType || ''}&actionSource=${formData.actionSource || ''}&search=${formData.search || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    formData,
    setFormData,
    allTenantCurrencies,
    tenantCountries,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    initalWalletState,
    dispatch,
    getUserTransactionDetailsStart,
    userTransactionDetails,
    userTransactionLoading,
    debounceFn,
    inputRef,
    handleCsvDownload,
    state,
    setState,
    initialDate
  }
}

export default useUserTransactionList
