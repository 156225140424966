import { takeLatest, put, select } from 'redux-saga/effects'
import {
  getAllCasinoProviders,
  getAllCasinoGames,
  createCasinoProvider,
  createCasinoGame,
  updateCasinoGame,
  updateCasinoProvider,
  superAdminViewToggleStatus,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  getAllSAProviders,
  getFreeSpinGames,
  updateReorderGames,
  getCasinoThemes,
  getCasinoGameIds,
  getSpecificCasinoGame,
  updateAction,
  updateFlag,
  createCasinoProviderAddGames,
  getAllNewGames,
  bulkUpdateDefaultRtp,
  providerCategoryUpdateDefaultRtp
} from '../../utils/apiCalls'
import {
  viewNewGameStart,
  viewNewGameSuccess,
  viewNewGameFailure,
  updateFlagStart,
  updateFlagSuccess,
  updateFlagFaliure,
  updateActionStart,
  updateActionSuccess,
  updateActionFaliure,
  getSpecificCasinoGameStart,
  getSpecificCasinoGameSuccess,
  getSpecificCasinoGameFailure,
  getAllCasinoProvidersStart,
  getAllCasinoProvidersSuccess,
  getAllCasinoProvidersFailure,
  createCasinoProviderStart,
  createCasinoProviderComplete,
  updateCasinoProviderStart,
  updateCasinoProviderComplete,
  getAllCasinoGamesStart,
  getAllCasinoGamesSuccess,
  getAllCasinoGamesFailure,
  createCasinoGameStart,
  createCasinoGameComplete,
  updateCasinoGameStart,
  updateCasinoGameComplete,
  resetCasinoGameStart,
  resetCasinoGameSuccess,
  updateCasinoStatusStart,
  updateCasinoStatusComplete,
  getSuperAdminAggregatorsStart,
  getSuperAdminAggregatorsSuccess,
  getSuperAdminAggregatorsFailure,
  createSuperAdminAggregatorStart,
  createSuperAdminAggregatorComplete,
  updateSuperAdminAggregatorStatusStart,
  updateSuperAdminAggregatorStatusComplete,
  getSuperAdminGameCategoryStart,
  getSuperAdminGameCategorySuccess,
  getSuperAdminGameCategoryFailure,
  getSuperAdminGameSubCategoryStart,
  getSuperAdminGameSubCategorySuccess,
  getSuperAdminGameSubCategoryFailure,
  updateSACasinoGamesStatusStart,
  updateSACasinoGamesStatusComplete,
  getAllSAProvidersStart,
  getAllSAProvidersSuccess,
  getAllSAProvidersFailure,
  getFreeSpinGamesStart,
  getFreeSpinGamesSuccess,
  getFreeSpinGamesFailure,
  updateReorderGamesStart,
  updateReorderGamesSuccess,
  updateReorderGamesFailure,
  getCasinoThemesStart,
  getCasinoThemesSuccess,
  getCasinoThemesFailure,
  getUniqueGameIdsStart,
  getUniqueGameIdsSuccess,
  getUniqueGameIdsFailure,
  getSuperAdminTAGameSubCategorySuccess,
  getSuperAdminTAGameSubCategoryStart,
  createCasinoProviderAddGamesStart,
  createCasinoProviderAddGamesComplete,
  updateDefaultRtpActionStart,
  updateDefaultRtpActionSuccess,
  updateDefaultRtpActionFailure,
  updateCategoryProivderBasedSystemRtpActionStart,
  updateCategoryProivderBasedSystemRtpActionSuccess,
  updateCategoryProivderBasedSystemRtpActionFailure
} from '../redux-slices/superAdminCasinoManagement'
import { toast } from '../../components/Toast'

import { objectToFormData } from '../../utils/objectToFormdata'
import { getAllGamesStart } from '../redux-slices/tenantCasino'
import { getUserSuccess } from '../redux-slices/fetchData'
const getUserData = state => state.fetch

export default function * superAdminCasinoManagementWatcher () {
  yield takeLatest(getAllCasinoProvidersStart.type, getAllCasinoProvidersWorker)
  yield takeLatest(getAllCasinoGamesStart.type, getAllCasinoGamesWorker)
  yield takeLatest(createCasinoGameStart.type, createCasinoGameWorker)
  yield takeLatest(createCasinoProviderStart.type, createCasinoProviderWorker)
  yield takeLatest(createCasinoProviderAddGamesStart.type, createCasinoProviderAddGamesWorker)
  yield takeLatest(updateCasinoGameStart.type, updateCasinoGameWorker)
  yield takeLatest(updateCasinoProviderStart.type, updateCasinoProviderWorker)
  yield takeLatest(resetCasinoGameStart.type, resetCasinoGameWorker)
  yield takeLatest(updateCasinoStatusStart.type, updateCasinoStatusWorker)
  yield takeLatest(getSuperAdminAggregatorsStart.type, getSuperAdminAggregatorsWorker)
  yield takeLatest(createSuperAdminAggregatorStart.type, createSuperAdminAggregatorWorker)
  yield takeLatest(updateSuperAdminAggregatorStatusStart.type, updateSuperAdminAggregatorStatusWorker)
  yield takeLatest(getSuperAdminGameCategoryStart.type, getSuperAdminGameCategoryWorker)
  yield takeLatest(getSuperAdminGameSubCategoryStart.type, getSuperAdminGameSubCategoryWorker)
  yield takeLatest(updateSACasinoGamesStatusStart.type, updateSACasinoGamesStatusWorker)
  yield takeLatest(getAllSAProvidersStart.type, getAllSAProvidersWorker)
  yield takeLatest(getFreeSpinGamesStart.type, getFreeSpinGamesWorker)
  yield takeLatest(updateReorderGamesStart.type, updateReorderGamesWorker)
  yield takeLatest(getCasinoThemesStart.type, getCasinoThemesWorker)
  yield takeLatest(getSpecificCasinoGameStart.type, getSpecificCasinoGameWorker)
  yield takeLatest(getUniqueGameIdsStart.type, getUniqueGameIdsWorker)
  yield takeLatest(getSuperAdminTAGameSubCategoryStart.type, getSuperAdminGameSubCategoryWorker)
  yield takeLatest(updateActionStart.type, updateActionWorker)
  yield takeLatest(updateFlagStart.type, updateFlageWorker)
  yield takeLatest(viewNewGameStart.type, viewNewGameWorker)
  yield takeLatest(updateDefaultRtpActionStart.type, updateBulkDefaultRtpWorker)
  yield takeLatest(updateCategoryProivderBasedSystemRtpActionStart.type, updateProviderCategoryBasedSystemRtpWorker)
}

function * viewNewGameWorker (action) {
  try {
    const {
      limit,
      pageNo,
      search,
      masterCasinoProviderId
    } = action && action.payload
    const { data } = yield getAllNewGames({
      limit,
      pageNo,
      search,
      masterCasinoProviderId
    })

    yield put(viewNewGameSuccess(data?.data?.masterCasinoGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(viewNewGameFailure(e?.response?.data?.errors[0].description))
  }
}

function * getAllCasinoProvidersWorker (action) {
  try {
    const { limit, pageNo, search, isAffiliate } = action && action.payload
    const { data } = yield getAllCasinoProviders({
      limit,
      pageNo,
      search,
      isAffiliate
    })

    yield put(getAllCasinoProvidersSuccess(data?.data?.casinos))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAllCasinoProvidersFailure(e?.response?.data?.errors[0].description))
  }
}
function * updateFlageWorker (action) {
  try {
    const {
      userId,
      tenantId,
      redFlag,
      reason,
      description,
      securityFlag
    } = action && action.payload

    const { userData } = yield select(getUserData)

    const { data } = yield updateFlag({
      userId,
      tenantId,
      redFlag,
      reason,
      description,
      securityFlag
    })
    yield put(updateFlagSuccess(data))
    yield toast('Flag Change Successfully', 'success')
    yield put(getUserSuccess({
      ...userData,
      isFlaggedRed: redFlag,
      redFlagReason: reason,
      redFlagDescription: description
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateFlagFaliure(e?.response?.data?.errors[0].description))
  }
}
function * updateActionWorker (action) {
  try {
    const { data, onSuccess } = action && action.payload
    const { response } = yield updateAction(data)
    yield put(updateActionSuccess(response))
    yield onSuccess()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateActionFaliure(e?.response?.data?.errors[0].description))
  }
}
function * resetCasinoGameWorker () {
  yield put(resetCasinoGameSuccess())
}

function * getAllCasinoGamesWorker (action) {
  try {
    const {
      bonusId,
      limit,
      pageNo,
      casinoCategoryId,
      categoryId,
      search,
      isActive,
      tenantId,
      selectedProvider,
      freespins,
      countryCode = '',
      portalId = '',
      languageCode,
      defaultRtp

    } = action && action.payload

    const { data } = yield getAllCasinoGames({
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      categoryId,
      tenantId,
      selectedProvider,
      freespins: freespins || '',
      bonusId: bonusId || '',
      countryCode: countryCode || '',
      portalId,
      languageCode,
      defaultRtp
    })
    yield put(getAllCasinoGamesSuccess(data?.data?.getAllCasinoGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCasinoGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getSpecificCasinoGameWorker (action) {
  try {
    const { gameId } = action && action.payload

    const { data } = yield getSpecificCasinoGame({
      gameId
    })

    yield put(getSpecificCasinoGameSuccess(data?.data?.responseObject))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSpecificCasinoGameFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload

    yield createCasinoProvider(objectToFormData(data))
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))

    yield toast('Provider created', 'success')
    yield put(createCasinoProviderComplete())
  } catch (e) {
    yield put(createCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createCasinoProviderAddGamesWorker (action) {
  try {
    const { masterCasinoProviderId } = action && action.payload

    yield createCasinoProviderAddGames(masterCasinoProviderId)
    yield put(createCasinoProviderAddGamesComplete())

    yield toast('New Games Added Succesfully!', 'success')
  } catch (e) {
    yield put(createCasinoProviderAddGamesComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo, onSuccess } = action && action.payload
    yield createCasinoGame(objectToFormData(data))

    yield onSuccess()
    if (casinoProviderId) {
      yield put(getAllCasinoGamesStart({
        casinoProviderId,
        limit,
        pageNo
      }))
    }

    yield put(createCasinoGameComplete())
  } catch (e) {
    yield put(createCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload

    yield updateCasinoProvider(objectToFormData(data))

    yield toast('Provider updated', 'success')
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))

    yield put(updateCasinoProviderComplete())
  } catch (e) {
    yield put(updateCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo } = action && action.payload

    yield updateCasinoGame(objectToFormData(data))

    yield put(updateCasinoGameComplete())

    yield put(getAllCasinoGamesStart({
      casinoProviderId,
      limit,
      pageNo
    }))

    yield toast('Game updated', 'success')
  } catch (e) {
    yield put(updateCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoStatusWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload
    yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))

    yield put(updateCasinoStatusComplete())
  } catch (e) {
    yield put(updateCasinoStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSACasinoGamesStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider
    } = action && action.payload

    yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    yield put(getAllCasinoGamesStart({
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider
    }))

    yield put(updateSACasinoGamesStatusComplete())
  } catch (e) {
    yield put(updateSACasinoGamesStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminAggregatorsWorker (action) {
  try {
    const { limit, pageNo } = action && action.payload
    const { data } = yield getSuperAdminAggregators({
      limit,
      pageNo
    })

    yield put(getSuperAdminAggregatorsSuccess(data?.data?.aggregators))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminAggregatorsFailure(e?.response?.data?.errors[0].description))
  }
}

function * createSuperAdminAggregatorWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload
    yield createSuperAdminAggregator(data)

    yield toast('Aggregator Created', 'success')

    yield put(createSuperAdminAggregatorComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo }))
  } catch (e) {
    yield put(createSuperAdminAggregatorComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSuperAdminAggregatorStatusWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload

    yield superAdminViewToggleStatus(data)

    yield toast('Status Updated', 'success')

    yield put(updateSuperAdminAggregatorStatusComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo }))
  } catch (e) {
    yield put(updateSuperAdminAggregatorStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminGameCategoryWorker (action) {
  try {
    const { limit, pageNo, search, tenantId } = action && action.payload
    const { data } = yield getSuperAdminGameCategory({
      limit,
      pageNo,
      search,
      tenantId
    })

    yield put(getSuperAdminGameCategorySuccess(data?.data?.casinoCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getSuperAdminGameSubCategoryWorker (action) {
  try {
    const { limit, pageNo, search, gameCategoryId, isActive, tenantId, isSuperAdmin = false } = action && action.payload
    const { data } = yield getSuperAdminGameSubCategory({
      limit,
      pageNo,
      search,
      gameCategoryId,
      isActive,
      tenantId
    })

    !isSuperAdmin
      ? yield put(getSuperAdminGameSubCategorySuccess(data?.data?.casinoSubCategories))
      : yield put(getSuperAdminTAGameSubCategorySuccess(data?.data?.casinoSubCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameSubCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getAllSAProvidersWorker (action) {
  try {
    const { data } = yield getAllSAProviders(action?.payload)

    yield put(getAllSAProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSAProvidersFailure())
  }
}

function * getFreeSpinGamesWorker (action) {
  try {
    const { limit, pageNo, providerId, search, bonusId } = action && action.payload

    const { data } = yield getFreeSpinGames({
      limit, pageNo, providerId, search, bonusId, isTenant: false
    })

    yield put(getFreeSpinGamesSuccess(data?.data?.gameWithFreespin))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getFreeSpinGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateReorderGamesWorker (action) {
  try {
    const { data, casinoCategoryId } = action && action.payload
    const res = yield updateReorderGames({ data })

    yield put(updateReorderGamesSuccess())
    yield put(
      getAllGamesStart({
        limit: '',
        pageNo: '',
        casinoCategoryId,
        isActive: '',
        orderBy: '',
        sort: '',
        providerId: ''
      })
    )
    yield toast(res.data.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCasinoThemesWorker (action) {
  try {
    const { isTenant = false } = action && action.payload

    const { data } = yield getCasinoThemes({ isTenant })

    yield put(getCasinoThemesSuccess(data?.data?.ThemeList?.map(({ theme }) => theme)))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCasinoThemesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUniqueGameIdsWorker (action) {
  try {
    const { search, rating, provider, subCategory, themes, tenantId, countries, isTenant } = action && action.payload

    const { data } = yield getCasinoGameIds({ search, rating, provider, category: subCategory, themes, tenantId, country: countries, isTenant })

    yield put(getUniqueGameIdsSuccess(data?.data?.categoryGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUniqueGameIdsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateBulkDefaultRtpWorker (action) {
  try {
    const {
      data, onSuccess
    } = action && action.payload
    yield bulkUpdateDefaultRtp(data)
    yield toast('Default Rtp Updated', 'success')
    yield onSuccess()

    yield put(updateDefaultRtpActionSuccess())
  } catch (e) {
    yield put(updateDefaultRtpActionFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateProviderCategoryBasedSystemRtpWorker (action) {
  try {
    const {
      data, onSuccess
    } = action && action.payload
    yield providerCategoryUpdateDefaultRtp(data)
    yield toast('Default Rtp Updated', 'success')
    yield onSuccess()

    yield put(updateCategoryProivderBasedSystemRtpActionSuccess())
  } catch (e) {
    yield put(updateCategoryProivderBasedSystemRtpActionFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
