import { useDispatch, useSelector } from 'react-redux'
import {
  updateDefaultRtpActionStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import { useTranslation } from 'react-i18next'

const useUpdateBulkSystemRtp = ({ fetchData }) => {
  const { t } = useTranslation()
  const { languages } = useSelector(state => state.languages)
  const dispatch = useDispatch()
  const {
    loading
  } = useSelector((state) => state.superAdminCasino)

  const updateBulkDefaultRtp = (data, handleClose) => {
    dispatch(
      updateDefaultRtpActionStart({
        data: data,
        onSuccess: () => {
          handleClose()
          fetchData()
        }
      })
    )
  }

  return {
    loading,
    updateBulkDefaultRtp,
    t,
    languages
  }
}

export default useUpdateBulkSystemRtp
