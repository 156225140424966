import * as Yup from 'yup'

export const perodicBonusSchema = (t) => {
  return Yup.object().shape({
    maxBonusLimit: Yup.number()
      .when('customLimit', (customLimit, schema) =>
        customLimit ? schema.moreThan(customLimit, t('maxBonusLimitGreaterThan')) : schema
      )
      .required(t('fieldRequired')),
    bonusPercent: Yup
      .number()
      .positive()
      .min(0, t('minimumValue0'))
      .max(100, t('maximumValue100'))
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired')),
    rollingPercent: Yup
      .number()
      .positive()
      .min(0, t('minimumValue0'))
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
  })
}
