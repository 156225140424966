import axios from 'axios'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../routes'
import { getItem, getLoginToken, removeLoginToken } from './storageUtils'
import { ADMIN_ROLES } from './constants'

const axiosInstance = axios.create()

export const setupInterceptors = () => {
  const role = getItem('role')

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 403) {
        removeLoginToken()
        if (role === ADMIN_ROLES.ADMIN) {
          window.location.href = TenantRoutes.Dashboard
        } else if (role === ADMIN_ROLES.SUPERADMIN) {
          window.location.href = SuperAdminRoutes.Dashboard
        } else if (role === ADMIN_ROLES.AFFILIATE) {
          window.location.href = AffiliateRoutes.Dashboard
        } else if (role === ADMIN_ROLES.AGENT) {
          window.location.href = AgentRoutes.Dashboard
        }
      }

      return Promise.reject(error)
    }
  )
}

const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
}

const makeRequest = async (url, method, data = {}) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (getLoginToken()) {
    headers.Authorization = `Bearer ${getLoginToken()}`
  }

  const isTenant = url.includes('/tenant/')

  const tenantId = getItem('tenant-id')

  if (isTenant && tenantId) {
    headers.id = tenantId
  }

  if (data?.isTenant === true || data?.isTenant === false) {
    delete data.isTenant
  }

  const payload = {
    url,
    method,
    data,
    headers
  }

  if (method === METHODS.get) {
    payload.params = data
  }

  return axiosInstance({ ...payload })
}

const getRequest = (url, data) => makeRequest(url, METHODS.get, data)

const postRequest = (url, data) => makeRequest(url, METHODS.post, data)

const putRequest = (url, data) => makeRequest(url, METHODS.put, data)

const deleteRequest = (url, data) => makeRequest(url, METHODS.delete, data)

export { getRequest, postRequest, putRequest, deleteRequest }
