import { takeLatest, put } from 'redux-saga/effects'
import {
  getTenantTransactionUsersStart,
  getTenantTransactionUsersSuccess,
  getTenantTransactionUsersFailure,
  getAllTenantCasinoTransactionsStart,
  getAllTenantCasinoTransactionsSuccess,
  getAllTenantCasinoTransactionsFailure,
  getAllTenantTransactionsStart,
  getAllTenantTransactionsSuccess,
  getAllTenantTransactionsFailure,
  getAllTenantWalletTransactionsStart,
  getAllTenantWalletTransactionsSuccess,
  getAllTenantWalletTransactionsFailure,
  updateTenantTransactionActionStart,
  updateTenantTransactionActionSuccess,
  updateTenantTransactionActionFaliure,
  depositTenantAmountStart,
  depositTenantAmountSuccess,
  depositTenantAmountFailure,
  withdrawalTenantAmountStart,
  withdrawalTenantAmountSuccess,
  withdrawalTenantAmountFailure,
  getTenantBonusTransactionsStart,
  getTenantBonusTransactionsSuccess,
  getTenantBonusTransactionsFailure,
  getSeniorTenantWalletListStart,
  getSeniorTenantWalletListSuccess,
  getSeniorTenantWalletListFailure,
  tenantWalletRequestStart,
  tenantWalletRequestSuccess,
  tenantWalletRequestFailure
} from '../redux-slices/tenantTransactions'
import {
  getAllTenantCasinoTransactions,
  getAllTenantTransactions,
  getTenantTransactionUsers,
  getTenantWalletTransactions,
  updateUserTransaction,
  depositTenantAmount,
  withdrawalTenantAmount,
  getTenantBonusTransactions,
  getSeniorTenantWalletList,
  tenantWalletRequest
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { getTenantUserDetailsStart } from '../redux-slices/tenantUsers'
export default function * tenantTransactionsWatcher () {
  yield takeLatest(getAllTenantTransactionsStart.type, getAllTenantTransactionsWorker)
  yield takeLatest(getTenantTransactionUsersStart.type, getTenantTransactionUsersWorker)
  yield takeLatest(getAllTenantCasinoTransactionsStart.type, getAllTenantCasinoTransactionsWorker)
  yield takeLatest(getAllTenantWalletTransactionsStart.type, getAllTenantWalletTransactionsWorker)
  yield takeLatest(updateTenantTransactionActionStart.type, updateTenantTransactionActionWorker)
  yield takeLatest(depositTenantAmountStart.type, depositTenantAmountWorker)
  yield takeLatest(withdrawalTenantAmountStart.type, withdrawalTenantAmountWorker)
  yield takeLatest(getTenantBonusTransactionsStart.type, getTenantBonusTransactionsWorker)
  yield takeLatest(getSeniorTenantWalletListStart.type, getSeniorTenantWalletListWorker)
  yield takeLatest(tenantWalletRequestStart.type, tenantWalletRequestWorker)
}

function * tenantWalletRequestWorker (action) {
  const { formData } = action.payload
  try {
    const { data } = yield tenantWalletRequest(formData)
    yield put(tenantWalletRequestSuccess(data?.data))
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield put(tenantWalletRequestFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * getSeniorTenantWalletListWorker (action) {
  try {
    const {
      form
    } =
      action && action.payload

    const { data } = yield getSeniorTenantWalletList(
      form
    )

    yield put(getSeniorTenantWalletListSuccess(data?.data?.seniorWalletRequests))
  } catch (e) {
    yield put(getSeniorTenantWalletListFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * getTenantBonusTransactionsWorker (action) {
  const { data: formData, isReporting, isAffiliate, isAgent } = action.payload
  try {
    const { data } = yield getTenantBonusTransactions(formData, isReporting, isAffiliate, isAgent)
    yield put(getTenantBonusTransactionsSuccess(data?.data?.casinoTransactionDetails))
  } catch (e) {
    yield put(getTenantBonusTransactionsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTenantTransactionActionWorker (action) {
  try {
    const {
      actionType,
      actionSource,
      transactionId,
      handleClose,
      addOnConfigData,
      tenantConfiguration
    } = action && action.payload
    const { data } = yield updateUserTransaction({
      actionType,
      actionSource,
      transactionId,
      addOnConfigData,
      tenantConfiguration
    })
    yield put(updateTenantTransactionActionSuccess(data?.data?.message))
    actionType === 'ACCEPT'
      ? yield toast('Transaction request accepted', 'success')
      : yield toast('Transaction request rejected', 'success')
    yield handleClose()
  } catch (e) {
    yield put(updateTenantTransactionActionFaliure())
    yield toast(e?.response?.data?.errors[0].description, 'eror')
  }
}
function * getAllTenantTransactionsWorker (action) {
  try {
    const {
      countryCode, limit, pageNo, search, startDate, endDate, currencyId,
      transactionType, paymentProvider, isUserDetail, userId, status,
      transactionId, createdAtStartDate, createdAtEndDate, paymentMethod, check
    } =
      action && action.payload

    const { data } = yield getAllTenantTransactions({
      limit,
      pageNo,
      search,
      startDate,
      endDate,
      currencyId,
      transactionType,
      paymentProvider,
      isUserDetail,
      userId,
      status,
      countryCode,
      transactionId,
      createdAtStartDate,
      createdAtEndDate,
      paymentMethod,
      check
    })

    yield put(getAllTenantTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllTenantTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * getAllTenantWalletTransactionsWorker (action) {
  try {
    const {
      form
    } =
      action && action.payload
    const { data } = yield getTenantWalletTransactions(form)
    yield put(getAllTenantWalletTransactionsSuccess(data?.data))
  } catch (e) {
    yield put(getAllTenantWalletTransactionsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getTenantTransactionUsersWorker (action) {
  try {
    const { type, email } = action && action.payload

    const { data } = yield getTenantTransactionUsers({ type, email })

    yield put(getTenantTransactionUsersSuccess(data?.data?.transactionUsers))
  } catch (e) {
    yield put(getTenantTransactionUsersFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getAllTenantCasinoTransactionsWorker (action) {
  try {
    const {
      limit,
      pageNo,
      startDate,
      endDate,
      transactionType,
      userId,
      isAffiliate,
      isAgent,
      username,
      isUserV2,
      nonWithdrawableRolled,
      bonusRolled,
      promotionRolled,
      formData
    } =
      action && action.payload
    const { data } = yield getAllTenantCasinoTransactions(({
      limit,
      pageNo,
      startDate,
      endDate,
      transactionType,
      userId,
      isAffiliate,
      isAgent,
      username,
      isUserV2,
      nonWithdrawableRolled,
      bonusRolled,
      promotionRolled,
      ...formData
    }))

    yield put(getAllTenantCasinoTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllTenantCasinoTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * depositTenantAmountWorker (action) {
  const { data: formData, setFormData } = action.payload
  try {
    const { data } = yield depositTenantAmount(formData)
    yield put(depositTenantAmountSuccess(data?.data))
    yield toast(data?.data?.message, 'success')
    yield put(getTenantUserDetailsStart({ userId: formData.userId }))
    setFormData()
  } catch (e) {
    yield put(depositTenantAmountFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * withdrawalTenantAmountWorker (action) {
  const { data: formData, setFormData } = action.payload
  try {
    const { data } = yield withdrawalTenantAmount(formData)
    yield put(withdrawalTenantAmountSuccess(data?.data))
    yield toast(data?.data?.message, 'success')
    yield put(getTenantUserDetailsStart({ userId: formData.userId }))
    setFormData()
  } catch (e) {
    yield put(withdrawalTenantAmountFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
