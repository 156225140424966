import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBannersStart, uploadBannerStart } from '../../../store/redux-slices/tenantcredentials'
import useCheckPermission from '../../../utils/checkPermission'
import { useTranslation } from 'react-i18next'

const useBannerManagement = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [data, setData] = useState('')
  const { banners, loading, updateLoading } = useSelector(state => state.tenantcredentials)
  const { tenantDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [refetch, setRefetch] = useState(false)
  const [validation, setValidation] = useState({
    minRequiredWidth: '',
    minRequiredHeight: '',
    maxRequiredWidth: '',
    maxRequiredHeight: ''
  })
  const [lang, setLang] = useState('ko')
  const { languages } = useSelector(state => state.languages)

  useEffect(() => {
    tenantDetails?.tenantId && dispatch(getAllBannersStart())
  }, [tenantDetails?.tenantId, refetch])

  const upload = (item) => {
    const formData = new FormData()
    formData.append('tenantId', tenantDetails?.tenantId)
    formData.append('lang', lang)
    formData.append('bannerType', data?.type)
    if (data?.imageKey) {
      formData.append('imageKey', data?.imageKey)
    }
    if (item?.file) {
      formData.append('file', item?.file)
    }
    dispatch(uploadBannerStart({
      data: formData,
      setShow,
      setRefetch
    }))
  }

  return {
    show,
    setShow,
    upload,
    data,
    setData,
    banners,
    isHidden,
    validation,
    setValidation,
    loading,
    t,
    lang,
    setLang,
    languages,
    updateLoading
  }
}

export default useBannerManagement
