import {
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  createTenantNotificationStart,
  getAllTenantSpecificNotificationStart,
  getTenantAllReadRecieptStart,
  updateTenantNotificationStatusStart
} from '../../../../store/redux-slices/tenants'
import { useTranslation } from 'react-i18next'
const initialState = {
  referenceType: '',
  status: '',
  isRead: ''
}
const useNotification = () => {
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState(initialState)
  const [notificationId, setNotificatonId] = useState(null)
  const [status, setStatus] = useState(true)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isReadReceiptTable, setIsReadReceiptTable] = useState(false)
  const [showNotificationForm, setShowNotificationForm] = useState(false)
  const [showConfirmActiveNotification, setShowConfirmActiveNotificationModal] = useState(false)
  const {
    tenantNotificationList,
    tenantReadRecieptList,
    loading
  } = useSelector((state) => state.tenants)

  const totalPages = Math.ceil(tenantNotificationList?.count / limit)
  const totalPagesReadReciept = Math.ceil(tenantReadRecieptList?.count / limit)

  useEffect(() => {
    if (isReadReceiptTable) {
      dispatch(getTenantAllReadRecieptStart({
        notificationId: notificationId,
        limit,
        pageNo: page,
        ...filterData
      }))
    }
  }, [limit, page, isReadReceiptTable, filterData])
  useEffect(() => {
    dispatch(getAllTenantSpecificNotificationStart({
      ...filterData,
      limit,
      pageNo: page,
      affiliateId: affiliate || '',
      agentId: agent || ''
    }))
  }, [limit, page, isReadReceiptTable, filterData, affiliate, agent])

  const handleViewReadReciept = (notificationId) => {
    setNotificatonId(notificationId)
    dispatch(getTenantAllReadRecieptStart({
      notificationId: notificationId,
      limit,
      pageNo: page,
      ...filterData
    }))
    setIsReadReceiptTable((prev) => !prev.isReadReceiptTable)
  }
  const handleCreateNotification = (formData) => {
    dispatch(createTenantNotificationStart({
      formData: {
        ...formData,
        referenceType: Number(formData?.referenceType),
        limit,
        pageNo: page
      }
    }))
  }
  const handleActiveNotification = () => {
    dispatch(updateTenantNotificationStatusStart({
      formData:
      {
        notificationId,
        status: status,
        limit,
        pageNo: page
      },
      setShowConfirmActiveNotificationModal
    }))
  }
  return {
    totalPages,
    totalPagesReadReciept,
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    isReadReceiptTable,
    setIsReadReceiptTable,
    handleViewReadReciept,
    setShowNotificationForm,
    showNotificationForm,
    tenantNotificationList,
    tenantReadRecieptList,
    handleCreateNotification,
    notificationId,
    setNotificatonId,
    status,
    setStatus,
    showConfirmActiveNotification,
    setShowConfirmActiveNotificationModal,
    handleActiveNotification,
    filterData,
    setFilterData,
    initialState,
    t,
    loading,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useNotification
