import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEdit,
  faWindowClose,
  faCheckSquare,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useCmsListing from './hooks/useCmsListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    navigate,
    page,
    limit,
    showModal,
    setPage,
    setLimit,
    setShowModal,
    search,
    setSearch,
    tenantCMSData,
    totalPages,
    handleShow,
    updateCmsStatus,
    statusState,
    active,
    setActive,
    name,
    loading,
    t
  } = useCmsListing()
  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>{t('cms')}</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              className='my-auto'
              size='sm'
              style={{ minWidth: '200px', marginRight: '5px' }}
              value={search}
              placeholder={t('searchTitleSlug')}
              onChange={(e) => setSearch(e.target.value.replace(
                /[~`!$%^&*)()><?]+/g,
                ''
              ))}
            />

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              {t('status')}
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>{t('all')}</option>
              <option key='true' value>{t('active')}</option>
              <option key='false' value={false}>{t('inActive')}</option>
            </Form.Select>
          </div>
        </Col>
        <Col xs='auto' className='d-flex px-3 float-end'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='py-1'
              size='sm'
              onClick={() => {
                setSearch('')
                setActive('')
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-dark'>
          <tr>
            {['sno', 'title', 'slug', 'status', 'action'].map((c) => (
              <th key={c}>
                <b>{t(c)}</b>
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(tenantCMSData) &&
            tenantCMSData?.rows?.map(
              ({ cmsPageId, title, slug, isActive, global }, index) => {
                return (
                  <tr key={cmsPageId}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>

                    <td>
                      <Trigger message={title?.EN}>
                        <span
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          className='text-link d-inline-block text-truncate'
                          onClick={() =>
                            navigate(
                              `/tenant/CMS/details/${cmsPageId}`
                            )}
                        >
                          {title?.EN}
                        </span>
                      </Trigger>
                    </td>
                    <td>{slug}</td>

                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inActive')}
                    </td>

                    <td>
                      <ButtonGroup>
                        {!global &&
                          <Trigger message={t('edit')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='warning'
                              hidden={isHidden({ module: { key: 'CMS', value: 'U' } })}
                              onClick={() =>
                                navigate(`/tenant/CMS/edit/${cmsPageId}`)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>}

                        <Trigger message={t('viewDetails')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({ module: { key: 'CMS', value: 'R' } })}
                            onClick={() =>
                              navigate(`/tenant/CMS/details/${cmsPageId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>

                        {!global && (!isActive
                          ? (
                            <Trigger message={t('setStatusActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                onClick={() =>
                                  handleShow(cmsPageId, isActive, title?.EN)}
                              >
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </Button>
                            </Trigger>
                            )
                          : (
                            <Trigger message={t('setStatusInActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='danger'
                                hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                onClick={() =>
                                  handleShow(cmsPageId, isActive, title?.EN)}
                              >
                                <FontAwesomeIcon icon={faWindowClose} />
                              </Button>
                            </Trigger>
                            ))}
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )}

          {tenantCMSData?.count === 0 && !loading &&
            (
              <tr>
                <td
                  colSpan={5}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>

      {tenantCMSData?.count !== 0 && !loading &&
        (
          <PaginationComponent
            page={tenantCMSData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={tenantCMSData?.count}
          />
        )}

      {
        showModal && (
          <ConfirmationModal
            show={showModal}
            setShow={setShowModal}
            handleYes={updateCmsStatus}
            active={statusState}
            name={name}
          />
        )
      }
    </div>
  )
}
