export const tableHeaders = (isV2, isDeposit) => {
  return [
    { label: 's.no' },
    { label: 'userInfo' },
    { label: 'accountInfo' },
    ...(isV2 && isDeposit
      ? [
          { label: 'numberOfAcceptedRequests' },
          { label: 'addOnDepositSettings' },
          { label: 'uniqueBonusCoupons' }
        ]
      : []),
    { label: 'transaction' },
    { label: 'date' },
    { label: 'amount' },
    { label: 'senior' },
    { label: 'playerStats' },
    { label: 'requestStatus' }
  ]
}
export const requestActions = [
  { label: 'all', value: 'NOT_PENDING' },
  { label: 'approve', value: 'ACCEPT' },
  { label: 'reject', value: 'REJECT' },
  { label: 'cancel', value: 'CANCEL' }
]
export const actionSources = [
  { label: 'withdraw', value: 'WITHDRAW' },
  { label: 'deposit', value: 'DEPOSIT' },
  { label: 'vaulWithdraw', value: 'VAULT-WITHDRAW' },
  { label: 'vaultDeposit', value: 'VAULT-DEPOSIT' },
  { label: 'voucherDeposit', value: 'VOUCHER-DEPOSIT' },
  { label: 'giftcardDeposit', value: 'GIFT-CARD-DEPOSIT' }
]
export const transactionType = [
  { label: 'request', value: 'REQUEST' },
  { label: 'nonRequest', value: 'NON-REQUEST' },
  { label: 'vaultWithdraw', value: 'VAULT-WITHDRAW' },
  { label: 'vaultDeposit', value: 'VAULT-DEPOSIT' }

]
