import {
  Row,
  Col,
  Button,
  Form as BForm
} from '@themesberg/react-bootstrap'
import React from 'react'
import ClientFilter from '../../../components/ClientFilter'
import {
  tableHeaders,
  // benefitTypes,
  voucherStatus
} from './voucherConstant'
import { SuperAdminRoutes } from '../../../routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Trigger from '../../../components/OverlayTrigger'
import useVoucher from './hooks/useVoucher'
import VoucherListing from './VoucherListing'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import useIsGamma from '../../../common/hook/useIsGamma'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'

const VoucherManagement = ({ isGiftCard, isUniqueCodeBonus }) => {
  const isTenant = !window.location.href.match(/super/g)
  const {
    selectedClient,
    state,
    setState,
    setSelectedClient,
    voucherData,
    navigate,
    loading,
    handleShow,
    initialDate,
    show,
    setShow,
    handleYes,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    name,
    setSearch,
    setShowDeleteModal,
    t,
    inputRef,
    // benefitType,
    setBenefitType,
    status,
    setStatus,
    debounceFn
  } = useVoucher({ isTenant, isGiftCard, isUniqueCodeBonus })
  const {
    isGamma
  } = useIsGamma()

  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>{isUniqueCodeBonus ? t('uniqueCodeBonus') : isGiftCard ? t('giftCard') : t('voucher')}</h3>
        </Col>

        {!isTenant &&
          <Col xs='auto'>
            <div className='d-flex justify-content-end align-items-center'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
              />
              <>
                {
                !isGamma &&
                (
                  <Button
                    className='m-1'
                    size='sm'
                    variant='outline-success'
                    onClick={() => navigate(isUniqueCodeBonus ? SuperAdminRoutes.createUniqueCodeBonus : isGiftCard ? SuperAdminRoutes.createGiftCard : SuperAdminRoutes.createVoucher)}
                  >
                    {t('create')}
                  </Button>
                )
               }
              </>
            </div>
          </Col>}
      </Row>

      <Row className='mt-2'>
        <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
            {t('search')}
          </BForm.Label>&nbsp;
          <BForm.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('search')}
            size='sm'
            style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>

        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('from')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('to')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        {/* <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            {t('benefitType')}
          </BForm.Label>
          <BForm.Select
            name='benefitType'
            size='sm'
            value={benefitType}
            onChange={(e) => setBenefitType(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {benefitTypes.map((item) => {
              return (
                item.value !== 'cashfreespins' &&
                  <option key={item.value} value={item.value}>
                    {t(item?.label).toUpperCase()}
                  </option>
              )
            })}
          </BForm.Select>
        </Col> */}
        <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            {t('status')}
          </BForm.Label>
          <BForm.Select
            name='isActive'
            size='sm'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {voucherStatus?.map(({ label, value }, i) => {
              return (
                <option value={value} key={`status-filter ${i}`}>
                  {t(label)}
                </option>
              )
            })}
          </BForm.Select>
        </Col>

        <Col xs='auto' className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setState(initialDate)
                setStatus('')
                setBenefitType('')
                setSearch('')
                setSelectedClient('')
                inputRef.current.value = ''
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <VoucherListing
        voucherData={voucherData}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        tableHeaders={tableHeaders}
        show={show}
        setShow={setShow}
        handleYes={handleYes}
        active={active}
        handleShow={handleShow}
        navigate={navigate}
        name={name}
        loading={loading}
        isTenant={isTenant}
        setShowDeleteModal={setShowDeleteModal}
        t={t}
        isUniqueCodeBonus={isUniqueCodeBonus}
      />
    </div>
  )
}

export default VoucherManagement
