import React, { useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  InputGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next' // Importing useTranslation
import Preloader from '../../Preloader'
import Trigger from '../../OverlayTrigger'
import useCreateAffiliates from './useCreateAffiliate'
import moment from 'moment'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { createAffiliateStart, updateAffiliateStart } from '../../../store/redux-slices/affiliate'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import { affiliateSchema, editAffiliateSchema } from './schema'
import { ADMIN_ROLES } from '../../../utils/constants'
import { getItem } from '../../../utils/storageUtils'
import InputDatePicker from '../../FormElements/InputDatePicker/InputDatePicker'

const CreateAffiliate = ({ isView, isEdit }) => {
  const isTenant = !window.location.href.match(/super/g)
  const role = getItem('role')
  const isSuperAdminRole = role === ADMIN_ROLES.SUPERADMIN
  const {
    navigate,
    loading,
    type,
    setType,
    clientsFilterData,
    setAllowedLanguages,
    allowedLanguages,
    langOptions,
    country
  } = useCreateAffiliates(isTenant, isView, isEdit)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { affiliateId } = useParams()
  const { affiliateData } = useSelector((state) => state.affiliate)
  const actionLabel = isView ? t('view') : (isEdit ? t('edit') : t('create'))
  useEffect(() => {
    if (affiliateData?.allowedLanguages?.length > 0) setAllowedLanguages(affiliateData?.allowedLanguages)
  }, [affiliateData])

  return (
    <div>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div className='p-2 '>
            <Row>
              <Col className='d-flex ps-3'>
                <h3>
                  <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                    className='cursor-pointer me-1'
                    onClick={() => navigate(-1)}
                  />
                  {actionLabel} {t('affiliate')}
                </h3>
              </Col>
            </Row>

            <div className='ps-2'>
              <Formik
                enableReinitialize
                initialValues={{
                  username: affiliateData?.username || '',
                  password: affiliateData?.password || '',
                  email: affiliateData?.email || '',
                  adminUserId: affiliateData?.adminId || '',
                  doj: affiliateData?.joinDate ? moment(affiliateData?.joinDate).toDate() : '',
                  phoneCode: affiliateData?.phoneCode || '',
                  phone: affiliateData?.phone || '',
                  country: affiliateData?.country || '',
                  legalName: affiliateData?.legalName || '',
                  idOfAffiliate: affiliateData?.idOfAffiliate || '',
                  allowedLanguages: affiliateData?.allowedLanguages || '',
                  primaryLanguage: affiliateData?.primaryLanguage || '',
                  ...(isSuperAdminRole && { revenuePercentage: affiliateData?.revenuePercentage || 0 })
                }}
                validationSchema={isEdit ? editAffiliateSchema(t, isTenant) : affiliateSchema(t, isTenant)}
                onSubmit={(formValues) => {
                  formValues = {
                    ...formValues,
                    username: formValues.username.trim(),
                    legalName: formValues.legalName.trim()
                  }
                  isEdit
                    ? (
                        dispatch(updateAffiliateStart({ data: { ...formValues, affiliateId: Number(affiliateId), allowedLanguages: allowedLanguages, adminUserId: Number(formValues.adminUserId), doj: new Date(formValues.doj).toISOString(), isTenant: isTenant }, navigate: navigate }))
                      )
                    : (
                        dispatch(createAffiliateStart({ data: { ...formValues, allowedLanguages: allowedLanguages, adminUserId: Number(formValues.adminUserId), doj: new Date(formValues.doj).toISOString(), isTenant: isTenant }, navigate: navigate }))
                      )
                }}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  setFieldError,
                  setFieldTouched
                }) => {
                  return (
                    <Form className='mt-2'>
                      <Row>
                        {!isTenant && (
                          <Col sm={6} className='mb-2'>
                            <div className=' d-flex  flex-column input-label'>
                              <div className='form-group'>
                                <label htmlFor='adminId'>
                                  {t('selectSenior')}
                                </label>
                                <BForm.Select
                                  disabled={isView || isEdit}
                                  className='w-100 select-placeholder'
                                  id='adminUserId'
                                  name='adminUserId'
                                  value={values.adminUserId}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value='' disabled>
                                    {t('selectSenior')}
                                  </option>
                                  {clientsFilterData?.rows?.map((option) => (
                                    <option key={option.adminUserId} value={option.adminUserId}>
                                      {option.firstName + ' ' + option.lastName}
                                    </option>
                                  ))}
                                </BForm.Select>
                                <ErrorMessage
                                  component='div'
                                  name='adminUserId'
                                  className='text-danger mt-1'
                                />
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col sm={6} className='mb-2'>
                          <label>
                            {t('email')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            disabled={isView || isEdit}
                            type='text'
                            name='email'
                            placeholder={t('enterEmail')}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage
                            component='div'
                            name='email'
                            className='text-danger'
                          />
                        </Col>

                        <Col sm={6} className='mb-2'>
                          <label>
                            {t('fullName')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            type='text'
                            name='legalName'
                            maxLength={25}
                            placeholder={t('legalNamePlaceholder')}
                            value={values.legalName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isView || isEdit}
                          />

                          <ErrorMessage
                            component='div'
                            name='legalName'
                            className='text-danger'
                          />
                        </Col>

                        <Col sm={6} className='mb-2'>
                          <label>
                            {t('affiliateId')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            type='text'
                            name='idOfAffiliate'
                            maxLength={25}
                            placeholder={t('enterAffiliateId')}
                            value={values.idOfAffiliate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isView || isEdit}
                          />

                          <ErrorMessage
                            component='div'
                            name='idOfAffiliate'
                            className='text-danger'
                          />
                        </Col>

                        <Col className='mb-2' sm={6}>
                          <label>
                            {t('username')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            disabled={isView || isEdit}
                            type='text'
                            name='username'
                            placeholder={t('enterUsername')}
                            maxLength={25}
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage
                            component='div'
                            name='username'
                            className='text-danger'
                          />
                        </Col>

                        {!isView && !isEdit && (
                          <Col sm={6} className='mb-2'>
                            <label>
                              {t('password')}<span className='text-danger'> *</span>
                            </label>

                            <Trigger
                              message={t('mustBeAtLeast8Characters')}
                            >
                              <InputGroup>
                                <BForm.Control
                                  type={type}
                                  name='password'
                                  maxLength={16}
                                  placeholder={t('enterPassword')}
                                  value={values.password}
                                  autoComplete='new-password'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                                  <FontAwesomeIcon
                                    icon={type === 'password' ? faEyeSlash : faEye}
                                    onClick={() => {
                                      type === 'password' ? setType('text') : setType('password')
                                    }}
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                            </Trigger>

                            <ErrorMessage
                              component='div'
                              name='password'
                              className='text-danger'
                            />
                          </Col>
                        )}

                        <Col sm={6} className='mb-2'>
                          <div className=' d-flex  flex-column input-label'>

                            <div className='form-group '>
                              <label htmlFor='phone'>
                                {t('phoneLabel')}
                              </label>

                              <PhoneInput
                                maxLength={20}
                                name='phone'
                                type='text'
                                countryCodeEditable={false}
                                aria-describedby='emailHelp'
                                alwaysDefaultMask={false}
                                enableSearch
                                placeholder={t('phonePlaceholder')}
                                enableLongNumbers
                                country='kr'
                                value={values.phoneCode + values.phone}
                                onChange={(phone, { dialCode }) => {
                                  setFieldValue('phoneCode', `+${dialCode}`)
                                  setFieldValue('phone', phone.slice(dialCode.length))
                                }}
                                searchPlaceholder={t('searchPhonePlaceholder')}
                                inputProps={{
                                  maxLength: 20
                                }}
                                onBlur={() => setFieldTouched('phone', true)}
                                buttonStyle={{ backgroundColor: '#22214b' }}
                                inputStyle={{ width: '100%' }}
                                disabled={isView}
                              />

                              <ErrorMessage
                                component='div'
                                name='phone'
                                className='text-danger mt-1'
                              />
                            </div>

                          </div>
                        </Col>
                        <Col sm={6} className='mb-2'>
                          <div className='d-flex flex-column gap-1 input-label'>
                            <InputDatePicker
                              isForm
                              disabled={isView || isEdit}
                              label={t('dateOfJoining')}
                              value={values.doj}
                              onChange={(val) => {
                                setFieldValue('doj', val)
                              }}
                            />
                            <ErrorMessage
                              component='div'
                              name='doj'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                        {(country && Array.isArray(country)) &&
                          <Col sm={6} className='mb-2'>
                            <label>
                              {t('country')}
                            </label>
                            <BForm.Select
                              name='country'
                              disabled={isView || isEdit}
                              onChange={handleChange}
                              value={values.country}
                            >
                              <option value=''>{t('selectCountry')}</option>

                              {country?.map((item, idx) => {
                                return (
                                  <option
                                    key={`countryNames2_${idx}`}
                                    value={item.code}
                                  >
                                    {item.name}
                                  </option>
                                )
                              })}

                            </BForm.Select>
                            <ErrorMessage component='div' name='country' className='text-danger' />
                          </Col>}
                        <Col sm={6} className='mb-2'>
                          <BForm.Group className='mb-3'>
                            <label>
                              {t('allowedLanguages')}<span className='text-danger'> *</span>
                            </label>

                            <Select
                              isMulti
                              name='allowedLanguages'
                              value={allowedLanguages}
                              options={langOptions}
                              isDisabled={isView}
                              className='basic-multi-select'
                              classNamePrefix='select'
                              onBlur={() => {
                                if (allowedLanguages?.length < 1) {
                                  setFieldTouched('allowedLanguages', true)
                                  setFieldError('allowedLanguages', 'Required')
                                } else {
                                  setFieldTouched('allowedLanguages', false)
                                  setFieldError('allowedLanguages', null)
                                }
                              }}
                              onChange={(option, e) => {
                                if (e?.removedValue?.value === 'en') {
                                  setFieldValue('allowedLanguages', values.allowedLanguages)
                                  setAllowedLanguages(allowedLanguages)
                                } else {
                                  setAllowedLanguages(option)
                                  setFieldValue('allowedLanguages', option.map((o) => o.value))
                                  if (option?.length < 1) {
                                    setFieldError('allowedLanguages', 'Required')
                                  } else {
                                    setFieldTouched('allowedLanguages', false)
                                    setFieldError('allowedLanguages', null)
                                  }
                                }
                                setFieldValue('primaryLanguage', '')
                              }}
                            />

                            <ErrorMessage component='div' name='allowedLanguages' className='text-danger' />
                          </BForm.Group>
                        </Col>
                        <Col sm={6} className='mb-2'>
                          {allowedLanguages?.length !== 0 && (
                            <BForm.Group className='mb-3'>
                              <label
                                className={
                                touched.primaryLanguage && errors.primaryLanguage ? 'text-danger' : ''
                              }
                              >
                                {t('primaryLanguage')}<span className='text-danger'> *</span>
                              </label>

                              <BForm.Select
                                name='primaryLanguage'
                                onChange={handleChange}
                                placeholder={t('selectPrimaryLanguage')}
                                disabled={isView}
                                value={values.primaryLanguage}
                              >
                                <option value='' disabled>{t('selectPrimaryLanguage')}</option>
                                {allowedLanguages?.map((currency, index) => (
                                  <option key={index} value={currency?.value}>{currency?.label}</option>
                                ))}
                              </BForm.Select>

                              <ErrorMessage component='div' name='primaryLanguage' className='text-danger' />
                            </BForm.Group>
                          )}
                        </Col>
                        {isSuperAdminRole && (
                          <Col sm={6} className='mb-2'>
                            <label>
                              {t('revenuePercentage')}<span className='text-danger'> *</span>
                            </label>

                            <BForm.Control
                              type='number'
                              name='revenuePercentage'
                              max={100}
                              placeholder={t('enterValueInPercent')}
                              value={values.revenuePercentage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isView}
                            />

                            <ErrorMessage
                              component='div'
                              name='revenuePercentage'
                              className='text-danger'
                            />
                          </Col>
                        )}
                      </Row>

                      {!isView &&
                        <div className='mt-4 d-flex justify-content-between align-items-center'>
                          <Button
                            variant='outline-warning'
                            onClick={() => navigate(-1)}
                          >
                            {t('cancel')}
                          </Button>

                          <Button
                            variant='outline-success'
                            onClick={handleSubmit}
                            className='ml-2'
                          >
                            {t('submit')}
                            {loading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                          </Button>
                        </div>}
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          )}
    </div>
  )
}

export default CreateAffiliate
