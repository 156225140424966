import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
// import { serialize } from 'object-to-formdata'
import { getAllReferrals, getAllReferredUser } from '../../utils/apiCalls'
import {
  getAllReferalFailure,
  getAllReferalStart,
  getAllReferalSuccess,
  getAllReferredUserlStart,
  getAllReferredUserlSuccess,
  getAllReferredUserlFailure
} from '../redux-slices/referrals'

export default function * referralWatcher () {
  yield takeLatest(getAllReferalStart.type, getAllReferralsWorker)
  yield takeLatest(getAllReferredUserlStart.type, getAllReferredUsersWorker)
}

function * getAllReferredUsersWorker (action) {
  try {
    const { userId, search, limit, pageNo } = action && action.payload

    const { data } = yield getAllReferredUser({
      userId,
      search,
      limit,
      pageNo
    })
    yield put(getAllReferredUserlSuccess(data?.data?.users))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getAllReferredUserlFailure(e.response.data.message))
  }
}
function * getAllReferralsWorker (action) {
  try {
    const { search, limit, pageNo } = action && action.payload

    const { data } = yield getAllReferrals({
      search,
      limit,
      pageNo
    })
    yield put(getAllReferalSuccess(data?.data?.users))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getAllReferalFailure(e.response.data.message))
  }
}
