// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  // Button, ButtonGroup,
  Table
} from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../../components/Pagination'
import moment from 'moment'
const tableHeaders = [
  'S.No',
  'Total Tickets',
  'Status',
  'Date'
]

const UniqueTicketsListing = ({
  ticketList,
  limit,
  count,
  setLimit,
  page,
  setPage,
  totalPages,
  navigate,
  loading
}) => {
  return (
    <div>
      <>
        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center '
        >
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={`T-table_heading ${idx}`}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {count > 0 && !loading &&
            ticketList?.map(
              (item, index) => {
                return (
                  <tr key={`bonus-list ${index}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>{item?.totalTickets}</td>
                    <td>{item?.status}</td>
                    <td>{moment(item?.createdAt).format('MM-DD-YYYY, hh:mm a')}</td>

                  </tr>
                )
              }
            )}

            {count < 1 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {count > 0 && !loading && (
          <PaginationComponent
            page={count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}

      </>
    </div>
  )
}

export default UniqueTicketsListing
