import * as Yup from 'yup'

export const casinoSchema = (t) => Yup.object().shape({
  gameName: Yup.string().required(t('gameNameRequired')),
  operatorGameId: Yup.string().required(t('operatorGameIdRequired')).nullable(),
  casinoProviderId: Yup.string()
    .required(t('casinoProviderIdRequired'))
    .nullable(),
  thumbnail: Yup.mixed().required(t('fileRequired'))
})

export const editCasinoSchema = (t) => Yup.object().shape({
  gameName: Yup
    .string()
    .required(t('gameNameRequired')),
  rtpValue: Yup
    .number()
    .typeError(t('valueMustBeNumber'))
    .positive(t('valueMustBePositive'))
    .test(
      'is-decimal',
      t('valueMustBeTwoDigitAfterDecimal'),
      value => (value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString()))
    ),
  // .required('rtpRequired'),
  operatorGameId: Yup
    .string()
    .required(t('operatorGameIdRequired')).nullable(),
  providerName: Yup
    .string()
    .required(t('casinoProviderIdRequired')),
  thumbnail: Yup
    .mixed(),
  subCategoryName: Yup
    .string()
    .required(t('subCategoryRequired')),
  languageCode: Yup
    .string()
    .required(t('languageRequired')),
  systemRtp: Yup
    .number()
    .typeError(t('valueMustBeNumber'))
    .positive(t('valueMustBePositive'))
    .max(100, t('valueMustNotExceed100')) // Ensures the value is not more than 100
    .test(
      'is-decimal',
      t('valueMustBeTwoDigitAfterDecimal'),
      value => (value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString()))
    )
})

export const bulkEditDefaultRtpSchema = (t) => Yup.object().shape({
  systemRtp: Yup
    .number()
    .required(t('systemRtpRequired')) // Ensures the field is required
    .typeError(t('valueMustBeNumber'))
    .positive(t('valueMustBePositive'))
    .max(100, t('valueMustNotExceed100')) // Ensures the value is not more than 100
    .test(
      'is-decimal',
      t('valueMustBeTwoDigitAfterDecimal'),
      value => (value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString()))
    )
})
