import React, { useEffect } from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp, faRedoAlt } from '@fortawesome/free-solid-svg-icons'

import useAllUserListing from './../../Users/useAllUserListing'
import { kycLabels, playerCashierHeaders, playerRestrictTransactionType, playerTypes } from './../../Users/userConstant'
import { countryCode } from '../../../../utils/countryJson'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'
import DateRangePicker from '../../../../components/DateRangePicker'
import Trigger from '../../../../components/OverlayTrigger'
import PaginationComponent from '../../../../components/Pagination'
import IDModal from './IDModal'

const RestrictPlayers = ({ providerDetails, updateData, details }) => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    userData,
    totalPages,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    kycOptions,
    setKycOptions,
    setState,
    userId,
    setUserId,
    setSortBy,
    setOrderBy,
    over,
    setOver,
    selected,
    sortBy,
    regState,
    setRegState,
    countries,
    setSelectedCountry,
    selectedCountry,
    regPlayerDate,
    setRegPlayerDate,
    regPlayersType,
    setRegPlayersType,
    transactionType,
    setTransactionType,
    depositRestrictPlayers,
    setRestrictDeposit,
    withdrawRestrictPlayers,
    setRestrictWithdrawal,
    isInternal,
    setIsInternal,
    setShowIDModal,
    handleIDModal,
    showIDModal
  } = useAllUserListing()

  useEffect(() => {
    setRestrictDeposit(providerDetails?.settings?.depositRestrictPlayers || [])
    setRestrictWithdrawal(providerDetails?.settings?.withdrawRestrictPlayers || [])
  }, [providerDetails])

  return (
    <>
      <Row>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>

            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />

            {selectedClient && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            )}
          </div>
        </Col>
        <Col className='d-flex mt-2'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Search
          </Form.Label>

          <Form.Control
            type='search'
            placeholder='Search Username, Email'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={search}
            onChange={(event) =>
              setSearch(
                event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
              )}
          />
        </Col>

        <Col className='d-flex mt-2'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px', minWidth: '126px' }}>
            Search By UserId
          </Form.Label>

          <Form.Control
            type='number'
            placeholder='Search UserId'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={userId}
            onChange={(event) =>
              setUserId(event.target.value)}
          />
        </Col>

        <Col className='d-flex mt-2'>
          <Form.Label style={{ marginBottom: '0', marginTop: '5px', minWidth: '92px' }}>
            Kyc Status
          </Form.Label>
          <Form.Select
            onChange={(e) => setKycOptions(e.target.value)}
            value={kycOptions}
            style={{ maxWidth: '180px', marginRight: '10px', height: '36px' }}
            size='sm'
          >
            {kycLabels.map((item) => {
              return <option key={`kyc Labels ${item.value}`} value={item.value}>{item.label}</option>
            })}

          </Form.Select>
        </Col>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label style={{ marginBottom: '0', marginTop: '5px', minWidth: '100px' }}>
            Player Type
          </Form.Label>
          <Form.Select
            onChange={(e) => setRegPlayersType(e.target.value)}
            value={regPlayersType}
            style={{ maxWidth: '180px', marginRight: '10px', height: '36px' }}
            size='sm'
          >
            {playerTypes.map((item) => <option key={`players-type ${item.value}`} value={item.value}>{item.label}</option>)}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        {regPlayersType === 'newDepositors'
          ? (
            <Col className='d-flex mt-2 d-flex align-self-center'>
              <Form.Label className='mx-auto text-nowrap align-self-center' style={{ paddingRight: '10px', marginBottom: '0' }}>
                First Depositors
              </Form.Label>
              <DateRangePicker state={regPlayerDate} setState={setRegPlayerDate} player />
            </Col>)
          : (
            <Col className='d-flex mt-2'>
              <Form.Label className='mx-auto text-nowrap align-self-center' style={{ paddingRight: '10px', marginBottom: '0' }}>
                Registration Date
              </Form.Label>
              <DateRangePicker state={regState} setState={setRegState} player transaction bonus reg />
            </Col>)}

        {(countries && Array.isArray(countries)) &&
          <Col className='d-flex align-self-center mt-2'>
            <Form.Label style={{ marginBottom: '0', marginTop: '5px', minWidth: '70px' }}>
              Country
            </Form.Label>
            <Form.Select
              onChange={(e) => setSelectedCountry(countryCode.find(val => val.name === e.target.value)?.code)}
              value={countries?.find(({ code }) => code === selectedCountry)?.name || ''}
              style={{ maxWidth: '180px', marginRight: '10px', height: '36px' }}
              size='sm'
            >
              <option value=''>Select Country</option>
              {countries?.map((item, idx) => {
                return <option key={`country names 1${idx}`} value={item.name}>{item.name}</option>
              })}

            </Form.Select>
          </Col>}

        <Col className='d-flex align-self-center mt-2'>
          <Form.Label style={{ marginBottom: '0', marginTop: '5px', minWidth: '80px' }}>
            Restrict Type
          </Form.Label>
          <Form.Select
            onChange={(e) => setTransactionType(e.target.value)}
            value={transactionType}
            style={{ maxWidth: '180px', marginRight: '10px', height: '36px' }}
            size='sm'
          >
            {playerRestrictTransactionType.map((item) => <option key={`transaction-type ${item.value}`} value={item.value}>{item.label}</option>)}
          </Form.Select>
        </Col>

        <Col className='d-flex align-self-center'>
          <Form.Label style={{ paddingRight: '10px', marginBottom: '0' }}>
            Is Internal
          </Form.Label>
          <Form.Switch
            name='isInternal'
            checked={isInternal}
            onChange={() => setIsInternal(!isInternal)}
          />
        </Col>

        <Col className='d-flex mt-2'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setUserId('')
                setLimit(15)
                setPage(1)
                setOrderBy('userId')
                setSortBy('desc')
                setKycOptions('')
                setSelectedCountry('')
                setIsInternal(false)
                setState([
                  {
                    startDate: new Date('01 July 1923'),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setRegState([
                  {
                    startDate: new Date('01 July 1923'),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>

        <Col className='mt-2'>
          <Button
            variant='outline-success'
            className='align-self-center'
            size='sm'
            hidden={details}
            onClick={() => setShowIDModal(true)}
          >
            Add IDs
          </Button>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {playerCashierHeaders.map((h, idx) => (
              <th
                key={idx}
                onClick={() => (h.value === 'email' || h.value === 'userId' || h.value === 'username') && setOrderBy(h.value)}
                style={{
                  cursor: 'pointer'
                }}
              >
                {h.label}{' '}
                {selected(h) &&
                  (sortBy === 'asc'
                    ? (
                      <FontAwesomeIcon
                        style={over ? { color: 'red' } : {}}
                        icon={faArrowCircleUp}
                        onClick={() => setSortBy('desc')}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                      )
                    : (
                      <FontAwesomeIcon
                        style={over ? { color: 'red' } : {}}
                        icon={faArrowCircleDown}
                        onClick={() => setSortBy('asc')}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                      ))}
              </th>
            ))}
            {providerDetails?.supportsDeposit && <th>Restrict Deposit</th>}
            {providerDetails?.supportsWithdrawal && <th>Restrict Withdraw</th>}

          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(userData) &&
            userData?.rows?.map(({ userId, username, firstName, lastName }) => {
              return (
                <tr key={userId}>
                  <td>{userId}</td>
                  <td>{username}</td>
                  <td>{`${firstName} ${lastName}`}</td>
                  {providerDetails?.supportsDeposit &&
                    <td>
                      <input
                        onChange={(e) => {
                          if (e.target.checked) {
                            depositRestrictPlayers?.length
                              ? setRestrictDeposit([...depositRestrictPlayers, userId])
                              : setRestrictDeposit([userId])
                          } else {
                            setRestrictDeposit((old) => {
                              return old?.filter(c => parseInt(c) !== userId)
                            })
                          }
                        }}
                        checked={depositRestrictPlayers?.flatMap((item) => parseInt(item))?.includes(userId)}
                        disabled={details}
                        style={{ cursor: 'pointer' }} type='checkbox'
                      />
                    </td>}
                  {providerDetails?.supportsWithdrawal &&
                    <td>
                      <input
                        onChange={(e) => {
                          if (e.target.checked) {
                            withdrawRestrictPlayers?.length
                              ? setRestrictWithdrawal([...withdrawRestrictPlayers, userId])
                              : setRestrictWithdrawal([userId])
                          } else {
                            setRestrictWithdrawal((old) => {
                              return old?.filter(c => parseInt(c) !== userId)
                            })
                          }
                        }}
                        checked={withdrawRestrictPlayers?.flatMap((item) => parseInt(item))?.includes(userId)}
                        disabled={details}
                        style={{ cursor: 'pointer' }} type='checkbox'
                      />
                    </td>}

                </tr>
              )
            }
            )}

          {userData?.count === 0 && !loading && (
            <tr>
              <td colSpan={13} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!details &&
        <div className='d-flex align-items-end justify-content-end'>
          <Button
            variant='outline-success'
            onClick={async () => {
              await updateData({
                data: {
                  withdrawRestrictPlayers,
                  depositRestrictPlayers
                },
                country: true
              })
            }}
            className='mt-2'
          >
            Submit
          </Button>
        </div>}

      {userData?.count !== 0 && !loading && (
        <PaginationComponent
          page={userData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <IDModal
        show={showIDModal}
        setShow={setShowIDModal}
        handleYes={handleIDModal}
        supportsDeposit={providerDetails?.supportsDeposit}
        supportsWithdrawal={providerDetails?.supportsWithdrawal}
      />
    </>
  )
}

export default RestrictPlayers
