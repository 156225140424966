import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getTemplateDetailStart } from '../../../../store/redux-slices/superAdminSettings'
import { getItem } from '../../../../utils/storageUtils'

const useViewTemplate = () => {
  const dispatch = useDispatch()
  const { templateId } = useParams()
  const { templateDetail, loading } = useSelector(state => state.superAdminSettings)
  const currentLang = getItem('language')?.toLowerCase()
  useEffect(() => {
    dispatch(getTemplateDetailStart({ templateId }))
  }, [templateId])
  return (
    {
      templateDetail,
      loading,
      currentLang
    }
  )
}

export default useViewTemplate
