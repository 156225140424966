import { createSlice } from '@reduxjs/toolkit'

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loading: false,
    isLoggedIn: false,
    error: null,
    success: false,
    role: null,
    tenantDetails: null,
    adminRole: null,
    tenantAdminRole: null,
    wallets: null,
    twoStepsEnabled: false
  },
  reducers: {
    superAdminLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    superAdminLoginSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      error: null,
      success: true,
      role: payload
    }),
    superAdminLoginFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      error: payload,
      success: false
    }),
    tenantLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    tenantLoginSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      error: null,
      success: true,
      role: payload
    }),
    tenantLoginFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      error: payload,
      success: false
    }),
    affiliateLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    affiliateLoginSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      error: null,
      success: true,
      role: payload
    }),
    affiliateLoginFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      error: payload,
      success: false
    }),
    agentLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    agentLoginSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      error: null,
      success: true,
      role: payload
    }),
    agentLoginFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      error: payload,
      success: false
    }),
    fetchTenantDetailsStart: (state) => ({
      ...state,
      loading: true,
      tenantDetails: null
    }),
    fetchTenantDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantDetails: payload
    }),
    fetchTenantDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAdminRoleStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminRoleSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminRole: payload
    }),
    getAdminRoleFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getTenantRoleStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantRoleSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantAdminRole: payload
    }),
    getTenantRoleFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getTenantAdminRoleStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantAdminRoleSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantAdminRole: payload
    }),
    getTenantAdminRoleFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getTenantWalletStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantWalletSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      wallets: payload
    }),
    getTenantWalletFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getSuperAdminWalletStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminWalletSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      wallets: payload
    }),
    getSuperAdminWalletFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateProfileStart: (state) => ({
      ...state,
      loading: true
    }),
    updateProfileComplete: (state) => ({
      ...state,
      loading: false
    }),
    setEmailCredsStart: (state) => ({
      ...state,
      loading: true
    }),
    setEmailCredsComplete: (state) => ({
      ...state,
      loading: false
    }),
    sendMailStart: (state) => ({
      ...state,
      sendMailLoading: true
    }),
    sendMailComplete: (state) => ({
      ...state,
      sendMailLoading: false
    }),
    resetPasswordStart: (state) => ({
      ...state,
      resetPassLoading: true
    }),
    resetPasswordComplete: (state) => ({
      ...state,
      resetPassLoading: false
    }),
    resetProfilePasswordStart: (state) => ({
      ...state,
      resetPassLoading: true
    }),
    resetProfilePasswordComplete: (state) => ({
      ...state,
      resetPassLoading: false
    }),
    twoStepsEnableStart: (state) => ({
      ...state,
      loading: false,
      twoStepsEnabled: true
    }),
    twoStepsDisableStart: (state) => ({
      ...state,
      twoStepsEnabled: false
    })
  }
})

export default loginSlice.reducer

export const {
  superAdminLoginStart,
  superAdminLoginSuccess,
  superAdminLoginFailure,
  tenantLoginStart,
  tenantLoginSuccess,
  tenantLoginFailure,
  fetchTenantDetailsStart,
  fetchTenantDetailsSuccess,
  fetchTenantDetailsFailure,
  getAdminRoleStart,
  getAdminRoleSuccess,
  getAdminRoleFailure,
  getTenantRoleStart,
  getTenantRoleSuccess,
  getTenantRoleFailure,
  getTenantAdminRoleStart,
  getTenantAdminRoleSuccess,
  getTenantAdminRoleFailure,
  getTenantWalletStart,
  getTenantWalletSuccess,
  getTenantWalletFailure,
  getSuperAdminWalletStart,
  getSuperAdminWalletSuccess,
  getSuperAdminWalletFailure,
  updateProfileStart,
  updateProfileComplete,
  setEmailCredsStart,
  setEmailCredsComplete,
  sendMailStart,
  sendMailComplete,
  resetPasswordStart,
  resetPasswordComplete,
  resetProfilePasswordStart,
  resetProfilePasswordComplete,
  twoStepsEnableStart,
  twoStepsDisableStart,
  affiliateLoginStart,
  affiliateLoginSuccess,
  affiliateLoginFailure,
  agentLoginStart,
  agentLoginSuccess,
  agentLoginFailure
} = loginSlice.actions
