import {
  useCallback,
  useEffect,
  useState
  , useRef
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSportsCountriesListingStart, updateSportsBookActionSAStart, getSportsListingStart } from '../../../store/redux-slices/superAdminSportsBook'
import { debounce } from 'lodash'
import { getTenantSportsCountriesListingStart, updateSportsBookActionTAStart, getTenantSportsListingStart } from '../../../store/redux-slices/tenantSportsBook'

const initialState = {
  countryName: '',
  status: '',
  sportId: 173
}
const useSportsCountries = (isTenant) => {
  const inputRef = useRef()
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDisable, setIsDisable] = useState(null)
  const [updateData, setUpdateData] = useState()
  const fetchData = () => {
    !isTenant
      ? dispatch(getSportsCountriesListingStart({
          paramsData: {
            ...filterData,
            limit,
            pageNo: page,
            isTenant
          }
        }))
      : dispatch(getTenantSportsCountriesListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page,
          isTenant
        }
      }))
  }
  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant
        }
      }))
  }, [])

  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])

  const {
    sportsCountriesListing,
    loading,
    sportsListing
  } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )

  const totalPages = Math.ceil(sportsCountriesListing?.count / limit)

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFilterData(prev => ({ ...prev, countryName: event.target.value }))
  }

  const handleUpdateSportAction = (updateData) => {
    !isTenant
      ? dispatch(updateSportsBookActionSAStart({ updateData, setIsStatusModalOpen, page, fetchData }))
      : dispatch(updateSportsBookActionTAStart({ updateData: { ...updateData, isTenant }, page, fetchData }))
  }

  return {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    totalPages,
    sportsCountriesListing,
    loading,
    debounceFn,
    inputRef,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    handleUpdateSportAction,
    updateData,
    setUpdateData,
    sportsListing
  }
}

export default useSportsCountries
