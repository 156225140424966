import React from 'react'
import {
  Button,
  Card,
  Form as BForm,
  Row,
  Col,
  Badge,
  Spinner,
  InputGroup
} from '@themesberg/react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { createAdminUserSchema } from '../schema'
import Trigger from '../../../../components/OverlayTrigger'
import { TenantRoutes } from '../../../../routes'
import { toast } from '../../../../components/Toast'
import useCreateAdminUser from '../hooks/useCreateAdminUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import CreatableSelect from 'react-select/creatable'

const CreateTenantRoles = ({ userDetails }) => {
  const {
    navigate,
    loading,
    getPermissionLabel,
    updateTenantAdminUser,
    createTenantAdminUser,
    adminDetails,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup
  } = useCreateAdminUser({ group: userDetails?.group })

  return (
    <div>
      <Row>
        <Col className='d-flex'>
          <h3>{userDetails ? 'Edit' : 'Create'} Manager</h3>
          {userDetails && (
            <h3>
              {`: ${userDetails?.firstName} ${userDetails?.lastName}`}
            </h3>
          )}
        </Col>
      </Row>

      <Formik
        initialValues={{
          email: userDetails ? userDetails?.email : '',
          password: '',
          firstName: userDetails ? userDetails?.firstName : '',
          lastName: userDetails ? userDetails?.lastName : '',
          agentName: userDetails ? userDetails?.agentName : '',
          phone: userDetails ? userDetails?.phone : '',
          role: 'Manager',
          permission: userDetails
            ? userDetails?.userPermission?.permission
            : {},
          adminId: adminDetails?.adminUserId,
          group: userDetails
            ? userDetails?.group
            : ''
        }}
        validationSchema={createAdminUserSchema(userDetails)}
        onSubmit={(formValues) => {
          ([undefined, null].includes(formValues.permission) || Object.keys(formValues.permission).length < 1)
            ? toast('Please Select At Least One Permission', 'error')
            : (userDetails
                ? updateTenantAdminUser({
                    data: {
                      ...formValues,
                      adminUserId: userDetails?.adminUserId,
                      password: Buffer.from(formValues.password).toString(
                        'base64'
                      )
                    },
                    navigate
                  })
                : createTenantAdminUser({
                  data: {
                    ...formValues,
                    password: Buffer.from(formValues.password).toString(
                      'base64'
                    )
                  },
                  navigate
                }))
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>Email
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='email'
                  placeholder='Enter Email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={userDetails}
                />

                <ErrorMessage
                  component='div'
                  name='email'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>Phone Number</BForm.Label>

                <BForm.Control
                  type='text'
                  name='phone'
                  placeholder='Enter Phone'
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='phone'
                  className='text-danger'
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <BForm.Label>First Name
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='firstName'
                  placeholder='Enter First Name'
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='firstName'
                  className='text-danger'
                />
              </Col>
              <Col>
                <BForm.Label>Last Name
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='lastName'
                  placeholder='Enter Last Name'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='lastName'
                  className='text-danger'
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <BForm.Label>Username
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='agentName'
                  placeholder='Enter Username'
                  value={values.agentName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={userDetails}
                />

                <ErrorMessage
                  component='div'
                  name='agentName'
                  className='text-danger'
                />
              </Col>
              <Col>
                <BForm.Label>Group
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <CreatableSelect
                  isClearable
                  name='group'
                  onCreateOption={(option) => {
                    groupOptions?.length > 0
                      ? setGroupOptions([
                          ...groupOptions,
                          { label: option, value: option }
                        ])
                      : setGroupOptions([
                        { label: option, value: option }
                      ])
                    setSelectedGroup({ label: option, value: option })
                    setFieldValue('group', option)
                  }}
                  options={groupOptions}
                  value={selectedGroup}
                  classNamePrefix='select'
                  onChange={(option, e) => {
                    if (option === null) {
                      setSelectedGroup()
                      setFieldValue('group', '')
                    } else {
                      setSelectedGroup({ label: option?.label, value: option?.value })
                      setFieldValue('group', option?.value)
                    }
                  }}
                />

                <ErrorMessage
                  component='div'
                  name='group'
                  className='text-danger'
                />
              </Col>
            </Row>

            <Row className='mt-3'>

              {!userDetails &&
                <Col sm={12} md={6}>
                  <BForm.Label>Password
                    <span className='text-danger'> *</span>
                  </BForm.Label>

                  <Trigger message='Must be atleast 8 characters long with 1 uppercase and 1 lowercase letters, 1 special character and 1 digit'>
                    <InputGroup>
                      <BForm.Control
                        type={type}
                        name='password'
                        placeholder='Enter Password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                        <FontAwesomeIcon
                          icon={type === 'password' ? faEyeSlash : faEye}
                          onClick={() => {
                            type === 'password' ? setType('text') : setType('password')
                          }}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Trigger>

                  <ErrorMessage
                    component='div'
                    name='password'
                    className='text-danger'
                  />
                </Col>}
            </Row>

            <Card className='mt-3'>
              <Card.Header>
                <div className='d-flex'>
                  <span className='col-4 header-font-weight'>
                    Module
                  </span>
                  <span className='ms-3 header-font-weight'>
                    Permissions
                  </span>
                </div>
              </Card.Header>
              {adminDetails?.userPermission && (
                <Card.Body>
                  {Object.keys(
                    adminDetails.userPermission?.permission
                  ).map((key, index) => {
                    return (
                      key !== 'Admins' && (
                        <Row className='border-bottom mt-3' key={key}>
                          <Col>
                            <BForm.Label>{key}</BForm.Label>
                          </Col>
                          <Col className='d-flex '>
                            {adminDetails?.userPermission?.permission[
                              key
                            ].map((value, index) => {
                              return (
                                <label key={index}>
                                  {adminDetails?.userPermission?.permission[
                                    key
                                  ].includes('R')
                                    ? (
                                      <Field
                                        className='d-none'
                                        type='checkbox'
                                        name={`permission[${key}]`}
                                        value={value}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === 'R' ||
                                            values?.permission?.[key]?.includes(
                                              'R'
                                            )
                                          ) {
                                            if (
                                              e.target.value === 'R' &&
                                              !e.target.checked
                                            ) {
                                              delete values.permission[key]
                                              setFieldValue(
                                                'permission',
                                                values.permission
                                              )
                                            } else {
                                              handleChange(e)
                                            }
                                          } else {
                                            toast(
                                              'Please Select Read Permission Before Selecting Other For This Module',
                                              'error'
                                            )
                                          }
                                        }}
                                      />
                                      )
                                    : (
                                      <Field
                                        className='d-none'
                                        type='checkbox'
                                        name={`permission[${key}]`}
                                        value={value}
                                        onChange={handleChange}
                                      />
                                      )}

                                  <h3>
                                    <Badge
                                      className='p-2 mx-2 '
                                      type='button'
                                      bg={
                                        values?.permission?.[key]?.includes(
                                          value
                                        )
                                          ? 'success'
                                          : 'primary'
                                      }
                                    >
                                      {getPermissionLabel(value, key)}
                                    </Badge>
                                  </h3>
                                </label>
                              )
                            })}
                          </Col>
                        </Row>)
                    )
                  })}
                </Card.Body>
              )}
            </Card>

            <div className='mt-4 d-flex justify-content-between align-items-center'>
              <Button
                variant='outline-warning'
                onClick={() => navigate(TenantRoutes.Admins)}
              >
                Cancel
              </Button>
              <Button
                variant='outline-success'
                onClick={handleSubmit}
                className='ml-2'
              >
                Submit
                {loading && (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateTenantRoles
