import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getRollingReportingListStart } from '../../../store/redux-slices/superAdminSettings'
import { getRollingReportingTAListStart } from '../../../store/redux-slices/tenants'

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]

const useRollingReportingTable = ({ isTenant }) => {
  const { t } = useTranslation()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [state, setState] = useState(initialDate)
  const inputRef = useRef()
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const {
    rollingReportList,
    rollingListLoading
  } =
    useSelector(
      state =>
        isTenant
          ? state.tenants
          : state.superAdminSettings)
  const totalPages = Math.ceil(rollingReportList?.count / limit)
  useEffect(() => {
    !isTenant
      ? (
          dispatch(getRollingReportingListStart({
            limit,
            pageNo: page,
            search,
            from: moment(state[0].startDate).toISOString() || '',
            to: moment(state[0].endDate).toISOString() || '',
            tenantId: selectedClient || ''
          }))
        )
      : (
          dispatch(getRollingReportingTAListStart({
            limit,
            pageNo: page,
            search,
            from: moment(state[0].startDate).toISOString() || '',
            to: moment(state[0].endDate).toISOString() || '',
            isTenant
          }))
        )
  }, [limit, page, selectedClient, state, search])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  return {
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    selectedClient,
    setSelectedClient,
    inputRef,
    state,
    setState,
    initialDate,
    t,
    setSearch,
    search,
    rollingReportList,
    rollingListLoading,
    totalPages
  }
}
export default useRollingReportingTable
