import React from 'react'
import { useTranslation } from 'react-i18next' // Import the useTranslation hook
import useCasinoTransactionsList from '../hooks/useCasinoTransactionsList'
import { Button, Col, Row, Form, Form as BForm } from '@themesberg/react-bootstrap'
import {
  transactionType
} from '../../CasinoTransactions/constants'
import CasinoTransactionsList from '../../../../components/CasinoTransactionList'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadFile } from '../../../../utils/fileDownloader'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../../utils/storageUtils'

const CasinoTransactions = () => {
  const { t } = useTranslation()

  const {
    setSelectedCurrency,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedAction,
    state,
    setState,
    casinoTransactions,
    loading,
    setStatus,
    getCsvDownloadUrl,
    sortBy,
    setSortBy,
    over,
    setOver,
    bannedGames,
    showBannedGameAmount,
    setShowBannedGameAmount,
    userData,
    contentFilter,
    setContentFilter,
    gameCategory,
    gameSubCategory,
    initialState,
    casinoProvidersData,
    initialDate,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleSubmit,
    ggrData,
    nonWithdrawableRolled,
    setNonWithdrawableRolled,
    bonusRolled,
    setBonusRolled,
    promotionRolled,
    setPromotionRolledRolled
  } = useCasinoTransactionsList()
  return (
    <>
      <Row className='search-section mt-5'>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-1'>
            {t('category')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, categoryId: e.target.value })
              setPage(1)
            }}
            value={contentFilter.categoryId}
          >
            <option value=''>{t('selectCategory')}</option>
            {gameCategory?.rows?.map(
              ({
                name,
                mastergamecategoryid
              }) => (
                <option key={mastergamecategoryid} value={mastergamecategoryid}>
                  {name.EN}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, subCategoryId: e.target.value })
              setPage(1)
            }}
            value={contentFilter.subCategoryId}
          >
            <option value=''>{t('selectSubCategory')}</option>
            {gameSubCategory?.rows?.map(
              ({
                name,
                masterGameSubCategoryId
              }) => (
                <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                  {name.EN}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('provider')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, providerId: e.target.value })
              setPage(1)
            }}
            value={contentFilter.providerId}
          >
            <option value=''>{t('selectProvider')}</option>
            {casinoProvidersData?.rows?.map(
              ({
                name,
                masterCasinoProviderId
              }) => (
                <option key={masterCasinoProviderId} value={masterCasinoProviderId}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('actionType')}
          </Form.Label>

          <Form.Select
            size='sm'
            disabled={showBannedGameAmount}
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setShowBannedGameAmount(false)
              setSelectedAction(e.target.value)
              setPage(1)
            }}
            value={selectedAction}
          >
            {transactionType && transactionType?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {t(label)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={casinoTransactions?.count === 0}
              onClick={() => {
                downloadFile(getCsvDownloadUrl())
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSelectedAction('all')
                setSelectedCurrency('')
                setLimit(15)
                setPage(1)
                setState(initialDate)
                setStatus('all')
                setContentFilter(initialState)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        {userData?.isV2User &&
          <>
            <div className='d-flex flex-wrap mt-2' style={{ marginBottom: '0' }}>
              <div className='d-flex align-items-center me-2'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('nonWithdrawableWallet')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='nonWithdrawable'
                  checked={contentFilter.nonWithdrawable}
                  onChange={(e) => setContentFilter({ ...contentFilter, nonWithdrawable: e.target.checked })}
                />
              </div>
              <div className='d-flex align-items-center me-3'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('bonusWallet')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='bonus'
                  checked={contentFilter.bonus}
                  onChange={(e) => setContentFilter({ ...contentFilter, bonus: e.target.checked })}
                />
              </div>
              <div className='d-flex align-items-center me-3'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('promotionCashWallet')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='promotionCash'
                  checked={contentFilter.promotionCash}
                  onChange={(e) => setContentFilter({ ...contentFilter, promotionCash: e.target.checked })}
                />
              </div>
              <div className='d-flex align-items-center me-3'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('nonWithdrawableRolled')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='nonWithdrawableRolled'
                  checked={nonWithdrawableRolled}
                  onChange={(e) => setNonWithdrawableRolled(e.target.checked)}
                />
              </div>
              <div className='d-flex align-items-center me-3'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('bonusRolled')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='bonusRolled'
                  checked={bonusRolled}
                  onChange={(e) => setBonusRolled(e.target.checked)}
                />
              </div>
              <div className='d-flex align-items-center'>
                <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '10px' }}>
                  {t('promotionRolled')}
                </BForm.Label>
                <BForm.Check
                  type='switch'
                  id='promotionRolled'
                  checked={promotionRolled}
                  onChange={(e) => setPromotionRolledRolled(e.target.checked)}
                />
              </div>
            </div>
          </>}
        <Col>
          <div className='card  py-1 px-3 d-flex float-end'>
            <span className='justify-content-center fw-bold fs-3'>{t('GGR' + ': ')}{ggrData ? formatNumber(ggrData) : ''}</span>
          </div>
        </Col>
      </Row>
      <CasinoTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={casinoTransactions}
        isTenant={false}
        userDetail
        sortBy={sortBy}
        setSortBy={setSortBy}
        over={over}
        setOver={setOver}
        loading={loading}
        bannedGames={bannedGames}
        showBannedGameAmount={showBannedGameAmount}
        userData={userData}
        t={t}
        addNoteModal={addNoteModal}
        setAddNoteModal={setAddNoteModal}
        individualUserData={individualUserData}
        setIndividualUserData={setIndividualUserData}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default CasinoTransactions
