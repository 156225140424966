import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBonusGamesStart } from '../../../store/redux-slices/bonus'
import { debounce } from 'lodash'

const useBonusGames = ({ isTenant }) => {
  const navigate = useNavigate()
  const { bonusGames, loading } = useSelector(state => state.bonus)
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const { bonusId } = useParams()
  const handleSearch = debounce((e) => {
    setSearch(e.target.value)
    setPageNo(1)
  }, 1000)
  useEffect(() => {
    dispatch(getBonusGamesStart({ limit, pageNo, search, bonusId, isTenant }))
  }, [limit, pageNo, search, bonusId])

  return {
    bonusGames,
    loading,
    search,
    setSearch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    navigate,
    handleSearch
  }
}

export default useBonusGames
