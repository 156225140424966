import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  deleteCategoryStart,
  getAllCasinoCategoriesStart,
  tenantCasinoSourceActionStart
} from '../../../../store/redux-slices/tenantCasino'
import { useTranslation } from 'react-i18next'

const useCasinoCategoriesListing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [categoryId, setCategoryId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [tenantGameCategoryId, setTenantGameCategoryId] = useState('')
  const [name, setName] = useState()

  const { loading, casinoCategories } = useSelector((state) => state.tenantCasino)

  const totalPages = Math.ceil(casinoCategories?.count / limit)

  const handleShow = (id, active, myName) => {
    setCategoryId(id)
    setName(name)
    setActive(active)
    setShow(true)
  }

  const handleYes = () => {
    dispatch(
      tenantCasinoSourceActionStart({
        activeStatus: active,
        actionSource: 'category',
        sourceId: categoryId,
        limit,
        pageNo: page
      })
    )
    setShow(false)
  }

  const handleClose = () => setShowModal(false)

  const handleShowModal = (type) => {
    setType(type)
    setShowModal(true)
  }

  const handleDeleteYes = () => {
    dispatch(deleteCategoryStart({
      data: { tenantGameCategoryId },
      limit,
      pageNo: page
    }))
    setDeleteModalShow(false)
  }

  const handleDeleteModal = (id, myName) => {
    setTenantGameCategoryId(id)
    setDeleteModalShow(true)
    setName(myName)
  }

  useEffect(() => {
    dispatch(
      getAllCasinoCategoriesStart({
        limit,
        pageNo: page
      })
    )
  }, [limit, page])

  return {
    limit,
    page,
    loading,
    casinoCategories,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    handleShowModal,
    showModal,
    type,
    handleClose,
    selectedCategory,
    setSelectedCategory,
    active,
    navigate,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    name,
    t
  }
}

export default useCasinoCategoriesListing
