import React from 'react'
import CasinoReportingTable from '../../../components/ReportingTable/CasinoReportingTable'
import { Button, Col, Form, Row } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import useTenantCasinoReporting from './hooks/useTenantCasinoReporting'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
const TenantCasinoReporting = ({ isAffiliate, isAgent, isV2 = false }) => {
  const {
    formData,
    setFormData,
    state,
    setState,
    casinoTenantReportingData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    casinoCategories,
    subCategories,
    casinoProviders,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    t,
    agent,
    setAffiliate,
    affiliate,
    setAgent
  } = useTenantCasinoReporting({ isAffiliate, isAgent, isV2 })
  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{t('casinoTransaction')}</h3>
        </Col>
      </Row>
      <div className='d-flex flex-wrap align-items-center'>
        <AffiliateAgentFilter
          agent={agent}
          setAgent={setAgent}
          affiliate={affiliate}
          setAffiliate={setAffiliate}
        />
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('betResult')}
          </Form.Label>
          <Form.Select
            value={formData?.actionType}
            onChange={(e) => {
              setFormData({ ...formData, actionType: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['BET', 'WIN', 'LOST'].map((requestAction) =>
              (
                <option
                  key={requestAction}
                  value={requestAction}
                >
                  {t(requestAction)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('transactionType')}
          </Form.Label>
          <Form.Select
            value={formData?.transactionType}
            onChange={(e) => {
              setFormData({ ...formData, transactionType: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['CREDIT', 'DEBIT'].map((transactionRequest) =>
              (
                <option
                  key={transactionRequest}
                  value={transactionRequest}
                >
                  {t(transactionRequest)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('category')}
          </Form.Label>

          <Form.Select
            size='sm'
            onChange={(e) => {
              setFormData({ ...formData, categoryId: e.target.value })
              setPage(1)
            }}
            value={formData.categoryId}
          >
            <option value=''>{t('selectCategory')}</option>
            {casinoCategories?.rows?.map(
              ({
                categoryname,
                categoryid
              }) => (
                <option key={categoryid} value={categoryid}>
                  {categoryname}
                </option>
              )
            )}
          </Form.Select>

        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, subCategoryId: e.target.value })
              setPage(1)
            }}
            value={formData.subCategoryId}
          >
            <option value=''>{t('selectSubCategory')}</option>
            {subCategories?.rows?.map(
              ({
                name,
                masterGameSubCategoryId
              }) => (
                <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                  {name.EN}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('provider')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, providerId: e.target.value })
              setPage(1)
            }}
            value={formData.providerId}
          >
            <option value=''>{t('selectProvider')}</option>
            {casinoProviders?.rows?.map(
              ({
                name,
                masterCasinoProviderId
              }) => (
                <option key={masterCasinoProviderId} value={masterCasinoProviderId}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label style={{ marginLeft: '10px', marginBottom: '0', marginRight: '15px' }}>
            {t('search')}
          </Form.Label>&nbsp;
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('search')}
            size='sm'
            className='w-90 mx-auto'
            style={{ minWidth: '180px', maxWidth: '230px', width: 'auto' }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex p-3 float-end'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='py-1'
              size='sm'
              onClick={() => {
                setFormData(initalWalletState)
                setState(initialDate)
                setAffiliate('')
                setAgent('')
                inputRef.current.value = ''
                setAgent('')
                setAffiliate('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        <Col className='d-flex p-3 float-end'>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={casinoTenantReportingData?.count === 0}
              onClick={() => {
                handleCsvDownloadCasino()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>
      </div>

      <CasinoReportingTable
        tableData={casinoTenantReportingData}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        t={t}
      />
    </div>
  )
}
export default TenantCasinoReporting
