import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTenantDetailsStart } from '../../../../../store/redux-slices/login'

const useLanguages = () => {
  const dispatch = useDispatch()
  const { tenantDetails, languageLoading } = useSelector(state => state?.login)

  useEffect(() => {
    if (!tenantDetails?.tenantConfig?.allowedLanguages) {
      dispatch(fetchTenantDetailsStart())
    }
  }, [tenantDetails?.tenantConfig?.allowedLanguages])

  return {
    languageLoading,
    tenantDetails
  }
}

export default useLanguages
