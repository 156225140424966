import React from 'react'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../routes'
import { Navigate } from 'react-router-dom'
import { ADMIN_ROLES } from '../utils/constants'

const RouteHandle = ({ isTenant, isSuperAdmin, token, role }) => {
  if (!token && !window.location.pathname.includes('reset-password')) {
    if (isTenant) {
      return <Navigate replace to={TenantRoutes.TenantSignin} />
    } else {
      return <Navigate replace to={SuperAdminRoutes.SuperAdminSignin} />
    }
  } else {
    if (role === ADMIN_ROLES.ADMIN) {
      return <Navigate to={TenantRoutes.Dashboard} />
    } else if (role === ADMIN_ROLES.SUPERADMIN) {
      return <Navigate to={SuperAdminRoutes.Dashboard} />
    } else if (role === ADMIN_ROLES.AFFILIATE) {
      return <Navigate to={AffiliateRoutes.Dashboard} />
    } else if (role === ADMIN_ROLES.AGENT) {
      return <Navigate to={AgentRoutes.Dashboard} />
    }
  }
}

export default RouteHandle
