import moment from 'moment'

export const formatDate = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('-')
}

export const formatDateYMD = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const getTime = (dateTime) => {
  const formatedTime = moment(dateTime).format('hh:mm A')
  return formatedTime
}

export const getUtcDateYMD = (date, value) => {
  let d = new Date(date)
  if (value === 'kpiReport') {
    const parsedData = new Date()
    const parsedRequiredDate = new Date(date)
    parsedData.setDate(parsedRequiredDate.getDate())
    d = new Date(parsedData.toString())
  }
  const utcDate = new Intl.DateTimeFormat('en-GB', { timeZone: 'UTC' }).format(d)
  const [day, month, year] = utcDate.split('/')
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const formatMaltaDateYMD = (date) => {
  return [date.split('-')[0], date.split('-')[1], parseInt(date.split('-')[2]) <= 9 ? '0' + parseInt(date.split('-')[2]) : parseInt(date.split('-')[2])].join('-')
}

export const getDateDaysAgo = (days) => {
  const now = new Date()
  now.setDate(now.getDate() - days)
  return now
}

export const getDateTime = (dateTime) => {
  // const d = new Date(dateTime)
  // let month = '' + (d.getMonth() + 1)
  // let day = '' + d.getDate()
  // const year = d.getFullYear()
  // let hours = d.getHours()
  // let minutes = d.getMinutes()
  // const ampm = hours >= 12 ? 'PM' : 'AM'
  // hours = hours % 12
  // hours = hours || 12 // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0' + minutes : minutes
  // const time = hours + ':' + minutes + ' ' + ampm

  // if (month.length < 2) month = '0' + month
  // if (day.length < 2) day = '0' + day

  // const formatedDateTime = `${month}-${day}-${year} ${time}`
  const formatedDateTime = moment(dateTime).format('MM-DD-YYYY hh:mm A')

  return formatedDateTime
}

export const getDateTimeCET = (dateTime) => {
  return new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'CET'
  }).format(new Date(dateTime))
}
