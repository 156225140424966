/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { tableHeaders } from '../constant'
import PaginationComponent from '../../Pagination'

const NotificationForUser = ({
  userForNotification,
  selectedPlayer,
  setSelectedPlayer,
  limit,
  setLimit,
  page,
  setPage,
  loading,
  t,
  totalPages,
  getSingleNotification,
  isEdit,
  tempSelectedPlayer,
  setTempSelectedPlayer,
  search,
  setSearch,
  debounceFn,
  inputRef
}) => {
  const isChecked = (id) => {
    return selectedPlayer?.find(e => Number(e) === Number(id))
  }
  const handleSelectedPlayers = (e, item) => {
    const tempVar = { ...tempSelectedPlayer }
    if (!isChecked(e.target.value)) {
      setSelectedPlayer(prev => [...prev, item])
      tempVar[page] = [...tempVar[page] || [], item]
    } else {
      setSelectedPlayer(selectedPlayer.filter(t => Number(t) !== Number(e.target.value)))
      tempVar[page] = (tempVar?.[page] || []).filter(t => Number(t) !== Number(e.target.value))
    }
    setTempSelectedPlayer(tempVar)
  }
  const handleSelectAll = () => {
    const tempVar = { ...tempSelectedPlayer }
    if (tempVar[page]) {
      if (userForNotification?.rows?.length === tempVar[page]?.length) {
        tempVar[page] = []
      } else {
        tempVar[page] = userForNotification?.rows.map(item => item.userId)
      }
    } else {
      tempVar[page] = userForNotification?.rows.map(item => item.userId)
    }
    setTempSelectedPlayer(tempVar)
    setSelectedPlayer(Object.values(tempVar)?.flat(Infinity))
  }
  return (
    <div className='p-3'>
      {isEdit && (
        <div className='py-3 table-with-max-h'>
          <h6>{t('previouslySelectedUser')}</h6>
          <Table bordered striped responsive hover size='sm' className='text-center mt-2 '>
            <thead className='thead-dark'>
              <tr>
                {tableHeaders.map((h, idx) => (
                  <th key={idx}>
                    {t(h.label)}{' '}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getSingleNotification?.users?.length > 0
                ? (
                    getSingleNotification?.users?.map(
                      (item, index) => {
                        return (
                          <tr key={item?.userId} className={item?.isUnderInspection ? 'under-inspection' : ''}>
                            <td>
                              <div className='text-center d-flex align-items-center justify-content-center '>
                                <label className='me-2 d-inline-flex' htmlFor={item?.userId}>
                                  <input
                                    onChange={(e) =>
                                      handleSelectedPlayers(e, item.userId)}
                                    style={{ width: 20, height: 20 }}
                                    checked={!!isChecked(item?.userId)}
                                    value={item?.userId}
                                    type='checkbox'
                                    id={item?.userId}
                                  />
                                </label>
                                {index + 1}
                              </div>

                            </td>
                            <td>{item?.userName || 'NA'} </td>
                            <td>{item?.affiliate?.username || '-'} </td>
                            <td>{item?.agent?.username || '-'} </td>
                          </tr>
                        )
                      }
                    )
                  )
                : (
                  <tr>
                    <td colSpan={13} className='text-danger text-center'>{t('noPlayerSelected')}</td>
                  </tr>
                  )}
            </tbody>
          </Table>
        </div>
      )}

      <div className='d-flex align-items-center gap-2 justify-content-between'>

        <h6> {isEdit && t('selectOtheruser')}</h6>
        {!isEdit && (
          <div className='d-flex align-items-center gap-2'>
            <input
              onClick={handleSelectAll}
              onChange={handleSelectAll}
              style={{ width: 20, height: 20 }}
              checked={tempSelectedPlayer?.[page] ? tempSelectedPlayer?.[page]?.length === userForNotification?.rows.length : false}
              type='checkbox'
            />
            {t('selectAllOfThisPage')}
          </div>
        )}

      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-2'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={idx}>
                {t(h.label)}{' '}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userForNotification?.count > 0
            ? (
                userForNotification?.rows?.map(
                  (item, index) => {
                    const serialNumber = index + 1 + limit * (page - 1)
                    return (
                      <tr key={item?.userId} className={item?.isUnderInspection ? 'under-inspection' : ''}>
                        <td>
                          <div className='text-center d-flex align-items-center justify-content-center '>
                            <label className='me-2 d-inline-flex' htmlFor={item?.userId}>
                              <input
                                onChange={(e) =>
                                  handleSelectedPlayers(e, item.userId)}
                                style={{ width: 20, height: 20 }}
                                checked={!!isChecked(item?.userId)}
                                value={item?.userId}
                                type='checkbox'
                                id={item?.userId}
                              />
                            </label>
                            {serialNumber}
                          </div>

                        </td>
                        <td>{item?.username || 'NA'} </td>
                        <td>{item?.affiliate?.username || '-'} </td>
                        <td>{item?.agent?.username || '-'} </td>
                      </tr>
                    )
                  }
                )
              )
            : (
              <tr>
                <td colSpan={13} className='text-danger text-center'>{t('noPlayerSelected')}</td>
              </tr>
              )}
          {userForNotification?.count === 0 &&
            (
              <tr>
                <td colSpan={13} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
            )}

        </tbody>
      </Table>
      {userForNotification?.count > 0 && !loading && userForNotification?.count !== 0
        ? (
          <PaginationComponent
            page={userForNotification?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={userForNotification?.count}
          />
          )
        : null}
    </div>
  )
}
export default NotificationForUser
