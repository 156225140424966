import React from 'react'
import { Button, Col, Form } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../OverlayTrigger'
import useSportsReporting from './hooks/useSportsReporting'
import SportsReportingTable from '../ReportingTable/SportsReportingTable'
import ClientFilter from '../ClientFilter'
import InputDatePicker from '../FormElements/InputDatePicker/InputDatePicker'
const SportsReporting = ({ isBetBy }) => {
  const {
    formData,
    setFormData,
    state,
    setState,
    betTransactions,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    initalState,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    t,
    setSelectedClient,
    selectedClient,
    betCancelHandler,
    loading,
    debounceFn,
    handleYesBetSlip,
    handleNoBetSlip,
    isConfirmationOpenForBetCancel,
    betslipId
  } = useSportsReporting(isBetBy)
  const isTenant = !window.location.href.match(/super/g)

  return (
    <div className='p-3'>
      <Col xs='auto'>
        <h3>{isBetBy ? t('betTransactionsBetBy') : t('betTransactions')}</h3>
      </Col>
      <div className='d-flex flex-wrap align-items-center'>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          {!isTenant && <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
                        />}
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('betType')}
          </Form.Label>
          <Form.Select
            value={formData?.betType}
            onChange={(e) => {
              setFormData({ ...formData, betType: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['single', 'multiple'].map((type) =>
              (
                <option
                  key={type}
                  value={type}
                >
                  {t(type)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
            {t('userName')}
          </Form.Label>&nbsp;
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              minWidth: '200px',
              maxWidth: '240px',
              width: 'fit-content'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('betSettlementStatus')}
          </Form.Label>
          <Form.Select
            value={formData?.settlementStatus}
            onChange={(e) => {
              setFormData({ ...formData, settlementStatus: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['in_game', 'won', 'lost', 'refund', 'cashout', 'partial_won'].map((status) =>
              (
                <option
                  key={status}
                  value={status}
                >
                  {t(status)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('betSlipStatus')}
          </Form.Label>
          <Form.Select
            value={formData?.betslipStatus}
            onChange={(e) => {
              setFormData({ ...formData, betslipStatus: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['completed', 'pending'].map((status) =>
              (
                <option
                  key={status}
                  value={status}
                >
                  {t(status)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            showTimeSelect={false}
            value={state[0].from}
            onChange={(val) => {
              setState([{ ...state[0], from: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            showTimeSelect={false}
            value={state[0].to}
            onChange={(val) => {
              setState([{ ...state[0], to: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex p-3 float-end'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='py-1'
              size='sm'
              onClick={() => {
                setFormData(initalState)
                setState(initialDate)
                setSelectedClient('')
                if (inputRef.current) { // Add null check here
                  inputRef.current.value = ''
                }
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        <Col className='d-flex p-3 float-end'>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={betTransactions?.count === 0}
              onClick={() => {
                handleCsvDownloadCasino()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>
      </div>

      <SportsReportingTable
        betslipId={betslipId}
        isConfirmationOpenForBetCancel={isConfirmationOpenForBetCancel}
        handleYesBetSlip={handleYesBetSlip}
        handleNoBetSlip={handleNoBetSlip}
        tableData={betTransactions}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        t={t}
        loading={loading}
        betCancelHandler={betCancelHandler}
        isBetBy={!!isBetBy}
      />
    </div>
  )
}
export default SportsReporting
