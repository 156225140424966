import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useAffiliateAgent from './useAffiliateAgentFilter'
import { getItem } from '../../utils/storageUtils'
import { ADMIN_ROLES } from '../../utils/constants'
import { agentObject } from '../../utils/helpers'

const AffiliateAgentFilter = ({ selectedClient, agent, setAgent, affiliate, setAffiliate }) => {
  const isTenant = !window.location.href.match(/super/g)
  const { affiliateAndAgentsList, t } = useAffiliateAgent({ selectedClient, isTenant })
  const role = getItem('role')

  const agents = affiliate > 0 ? agentObject(affiliateAndAgentsList?.affiliates, affiliate) : affiliateAndAgentsList?.agents
  return (
    <>
      {(selectedClient || isTenant) && (
        <div className='d-flex align-self-center gap-2'>
          {affiliateAndAgentsList?.affiliates?.length > 0 && (
            <div className='d-flex align-self-center'>
              <Form.Label
                style={{
                  marginBottom: '0',
                  marginRight: '15px',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                {t('selectAffiliate')}
              </Form.Label>
              <Form.Select
                onChange={(e) => {
                  setAffiliate(e.target.value)
                  setAgent('')
                }}
                size='sm'
                style={{
                  width: '230px',
                  marginRight: '10px',
                  fontSize: '13px'
                }}
                value={affiliate}
              >
                <option value=''>{t('all')}</option>
                {affiliateAndAgentsList?.affiliates?.map((item, index) => (
                  <option key={index} value={item.affiliateId}>{item.username}</option>
                ))}
              </Form.Select>
            </div>
          )}
          {(affiliateAndAgentsList?.agents?.length > 0 || agents?.length > 0) && (
            <div className='d-flex align-self-center'>
              <Form.Label
                style={{
                  width: 'auto',
                  marginBottom: '0',
                  marginRight: '15px',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                {t('selectAgent')}
              </Form.Label>
              <Form.Select
                onChange={(e) => setAgent(e.target.value)}
                size='sm'
                style={{
                  width: '230px',
                  marginRight: '10px',
                  fontSize: '13px'
                }}
                value={agent}
              >
                <option value=''>{t('all')}</option>
                {agents?.map((item, index) => (
                  <option key={index} value={item.agentId}>{item.username}</option>
                ))}
              </Form.Select>
            </div>
          )}

        </div>
      )}
      {
        role === ADMIN_ROLES.AFFILIATE && affiliateAndAgentsList?.count > 0 && (
          <div className='d-flex align-self-center'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('selectAgent')}
            </Form.Label>
            <Form.Select
              onChange={(e) => setAgent(e.target.value)}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                fontSize: '13px'
              }}
              value={agent}
            >
              <option value=''>{t('all')}</option>
              {affiliateAndAgentsList?.rows?.map((item, index) => (
                <option key={index} value={item.agentId}>{item.username}</option>
              ))}
            </Form.Select>
          </div>

        )
      }
    </>
  )
}

export default AffiliateAgentFilter
