import * as Yup from 'yup'

export const createTenantUserSchema = (userFields, user, t) => {
  userFields &&
  Yup.object().shape({
    email:
      userFields.email &&
      Yup.string().email(t('invalidEmail')).max(50).required(t('emailRequired')),
    password:
      userFields.password && user
        ? Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            t('invalidPassword')
          )
        : Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            t('invalidPassword')
          )
          .max(50)
          .required(t('passwordRequired')),
    confirmPassword:
      userFields.confirmPassword && user
        ? Yup.string().when('password', {
            is: (value) => value && value.length > 0,
            then: Yup.string()
              .oneOf([Yup.ref('password'), null], t('passwordMatchError'))
              .required(t('confirmPasswordRequired')),
            otherwise: Yup.string()
          })
        : Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            t('invalidPassword')
          )
          .max(50)
          .oneOf([Yup.ref('password'), null], t('passwordMatchError'))
          .required(t('confirmPasswordRequired')),
    firstName:
      userFields.firstName &&
      Yup.string()
        .min(3, t('mustBeAtLeastCharacters', { label: t('firstName'), minNumber: 3 }))
        .matches(/^[a-zA-Z0-9]*$/, t('onlyAlphabetsAndNumbers'))
        .max(50, t('mustBeAtMostCharacters', { label: t('firstName'), maxNumber: 50 }))
        .required(t('firstNameRequired')),
    lastName:
      userFields.lastName === 2
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t('onlyAlphabetsAndNumbers'))
            .min(3, t('mustBeAtLeastCharacters', { label: t('lastName'), minNumber: 3 }))
            .max(50, t('mustBeAtMostCharacters', { label: t('lastName'), maxNumber: 50 }))
            .required(t('lastNameRequired'))
        : Yup.string()
          .matches(/^[a-zA-Z0-9]*$/, t('onlyAlphabetsAndNumbers'))
          .min(3, t('mustBeAtLeastCharacters', { label: t('lastName'), minNumber: 3 }))
          .max(50, t('mustBeAtMostCharacters', { label: t('firstName'), maxNumber: 50 }))
          .nullable(),
    phone: userFields.phone === 2
      ? Yup.string().required(t('phoneRequired')).nullable()
      : userFields.phone !== 0 &&
      Yup.string().nullable(),
    dateOfBirth:
      userFields.dateOfBirth === 2
        ? Yup.date()
            .max(new Date(), t('dateOfBirthMax'))
            .typeError(t('dateOfBirthInvalid'))
            .required(t('invalidDate'))
        : userFields.dateOfBirth !== 0 && Yup.date()
          .max(new Date(), t('dateOfBirthMax'))
          .typeError(t('dateOfBirthInvalid')).nullable(),
    gender: userFields.gender === 2
      ? Yup.string().required(t('genderRequired'))
      : userFields.gender !== 0 && Yup.string().nullable(),
    username:
      userFields.username === 2
        ? Yup.string().max(50)
            .matches(/^[a-zA-Z0-9_]*$/, t('onlyAlphabetsNumbersAndUnderscore'))
            .required(t('usernameRequired'))
        : userFields.username !== 0 && Yup.string().max(50)
          .matches(/^[a-zA-Z0-9_]*$/, t('onlyAlphabetsNumbersAndUnderscore'))
          .nullable(),
    address: userFields.address === 2
      ? Yup.string().max(100, t('addressMax'))
          .required(t('addressRequired')).nullable()
      : userFields.address !== 0 && Yup.string().max(100, t('addressMax')).nullable(),
    city: userFields.address === 2
      ? Yup.string().max(50, t('cityMax'))
          .matches(/^[a-zA-Z -]*$/, t('onlyAlphabetsSpaceAndDash'))
          .required(t('cityRequired')).nullable()
      : userFields.address !== 0 && Yup.string()
        .matches(/^[a-zA-Z -]*$/, t('onlyAlphabetsSpaceAndDash'))
        .max(50, t('cityMax')).nullable(),
    zipCode: userFields.address === 2
      ? Yup.string().max(6, t('zipCodeMax'))
          .matches(/^[0-9]*$/, t('onlyNumbersAllowed'))
          .required(t('zipCodeRequired')).nullable()
      : userFields.address !== 0 && Yup.string()
        .max(6, t('zipCodeMax'))
        .matches(/^[0-9]*$/, t('onlyNumbersAllowed'))
        .nullable(),
    currencyCode:
      userFields.currencyCode &&
      Yup.string().required(t('countryCodeRequired')),
    countryCode: userFields.countryCode === 2
      ? Yup.string().required(t('countryRequired'))
      : userFields.countryCode !== 0 && Yup.string().nullable(),
    preferredLanguage:
      userFields.preferredLanguage === 2
        ? Yup.string().required(t('preferredLanguageRequired'))
        : userFields.preferredLanguage !== 0 && Yup.string().nullable(),
    sms: userFields.sms === 2
      ? Yup.string().max(50).required(t('smsRequired'))
      : userFields.sms !== 0 && Yup.string().max(50).nullable()
  })
}

export const depositSchema = (t) => {
  Yup.object().shape({
    addAmount: Yup.number()
      .typeError(t('onlyNumbersAllowed'))
      .min(1, t('amountMin'))
      .required(t('amountRequired'))
  })
}
export const reasonSchema = (t) => {
  return Yup.object().shape({
    reason: Yup.string()
      .required(t('reasonRequired'))
  })
}
