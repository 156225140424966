import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { Modal, Form as BForm, Button, Spinner } from 'react-bootstrap'
import { updateLogoSchemaNew } from './schema'
import { teamDesktopSize, teamMobileSize } from '../../../utils/constants'

const UpdateTeamLogo = ({
  handleUpdateTeamLogo,
  show,
  handleClose,
  type,
  t,
  updateLoading,
  modalData
}) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          {
            file: modalData?.entityType ? modalData?.imageUrl?.[modalData?.entityType] : ''
          }
        }
        validationSchema={updateLogoSchemaNew(t, modalData?.entityType === 'desktop' ? teamDesktopSize : teamMobileSize)}
        onSubmit={handleUpdateTeamLogo}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset,
          setFieldError,
          errors
        }) => {
          return (
            <Form>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <h4 className='d-flex align-items-center'>
                    {t('update')}
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('thumbnail')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Text>
                          <input
                            id='file'
                            name='file'
                            type='file'
                            onChange={(event) => {
                              setFieldValue(
                                'file',
                                event.currentTarget.files[0]
                              )
                            }}
                          />

                        </BForm.Text>
                        {values?.file &&
                          (
                            <img
                              alt='file'
                              style={{ width: 150, maxHeight: 150, objectFit: 'contain' }}
                              src={
                                typeof values?.file !== 'string'
                                  ? URL.createObjectURL(values?.file)
                                  : values?.file
                              }
                            />
                          )}
                        <ErrorMessage
                          component='div'
                          name='file'
                          className='text-danger'
                        />
                      </div>
                    </div>
                  </div>

                </Modal.Body>

                <div className='mt-4 '>
                  <Modal.Footer className='d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => {
                        handleClose()
                        handleReset()
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                    >
                      {t('submit')}
                      {updateLoading && (
                        <Spinner className='ms-2' size='sm' animation='border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </Form>
          )
        }}
      </Formik>

    </>
  )
}

export default UpdateTeamLogo
