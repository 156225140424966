import React from 'react'
import CasinoLogTable from '../../../components/ReportingTable/CasinoLogTable'
import useCasinoLogs from './hooks/useCasinoLogs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import { Button, Col, Form } from 'react-bootstrap'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'

const CasinoLogReporting = () => {
  const {
    formData,
    setFormData,
    casinoLogData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalState,
    state,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    setState,
    handleCsvDownloadCasino,
    initialDate,
    inputRef,
    loading,
    t
  } = useCasinoLogs()
  return (
    <div className='p-2'>
      <Col xs='auto'>
        <h3>{t('casinoLogs')}</h3>
      </Col>
      <div className='d-flex flex-wrap p-2'>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('search')}
          </Form.Label>
          &nbsp;
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchName')}
            size='sm'
            className='w-90 mx-auto'
            style={{
              minWidth: '180px',
              maxWidth: '230px',
              width: 'auto'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('category')}
          </Form.Label>

          <Form.Select
            size='sm'
            onChange={(e) => {
              setFormData({ ...formData, categoryId: e.target.value })
              setPage(1)
            }}
            value={formData.categoryId}
          >
            <option value=''>{t('selectCategory')}</option>
            {gameCategory?.rows?.map(({ name, mastergamecategoryid }) => (
              <option key={mastergamecategoryid} value={mastergamecategoryid}>
                {t(name.EN)}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, subCategoryId: e.target.value })
              setPage(1)
            }}
            value={formData.subCategoryId}
          >
            <option value=''>{t('selectSubCategory')}</option>
            {gameSubCategory?.rows?.map(({ name, masterGameSubCategoryId }) => (
              <option
                key={masterGameSubCategoryId}
                value={masterGameSubCategoryId}
              >
                {t(name.EN)}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('provider')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, providerId: e.target.value })
              setPage(1)
            }}
            value={formData.providerId}
          >
            <option value=''>{t('selectProvider')}</option>
            {casinoProvidersData?.rows?.map(
              ({ name, masterCasinoProviderId }) => (
                <option
                  key={masterCasinoProviderId}
                  value={masterCasinoProviderId}
                >
                  {t(name)}
                </option>
              )
            )}
          </Form.Select>
        </div>

        <div className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </div>
        <div className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </div>

        <div className='d-flex align-items-center p-2'>
          <Trigger message={t('downloadAsCsv')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={casinoLogData?.count === 0}
              onClick={() => {
                handleCsvDownloadCasino()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </div>

        <div className='d-flex align-items-center p-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setFormData(initalState)
                setState(initialDate)
                setPage(1)
                inputRef.current.value = ''
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </div>
      </div>

      <CasinoLogTable
        tableData={casinoLogData}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        loading={loading}
        t={t}
      />
    </div>
  )
}
export default CasinoLogReporting
