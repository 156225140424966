import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUserFieldsStart,
  updateUserFieldsStart
} from '../../../store/redux-slices/tenantUsers'

const useFormFields = () => {
  const dispatch = useDispatch()
  const { userFields, loading } = useSelector((state) => state.tenantUsers)

  const [data, setData] = useState(null)

  const handleChange = (e, check) => {
    if (check === '') {
      setData({
        ...data,
        [e.target.name]: e.target.checked ? 1 : 0
      })
    }
    if (check === 1) {
      setData({
        ...data,
        [e.target.name]: 2
      })
    } else if (check === 0) {
      setData({
        ...data,
        [e.target.name]: 1
      })
    }
  }

  const updateFields = () => {
    dispatch(updateUserFieldsStart(data))
  }

  useEffect(() => {
    setData(userFields)
  }, [userFields])

  useEffect(() => {
    dispatch(getUserFieldsStart())
  }, [])

  return {
    userFields,
    loading,
    data,
    setData,
    handleChange,
    updateFields
  }
}

export default useFormFields
