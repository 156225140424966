import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getTenantUserDetailsStart,
  getUserFieldsStart
} from '../../../../store/redux-slices/tenantUsers'

const useEditTenantUser = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { user, loading } = useSelector((state) => state.tenantUsers)

  useEffect(() => {
    dispatch(getUserFieldsStart())
    dispatch(getTenantUserDetailsStart({ userId }))
  }, [userId])

  return {
    userId,
    user,
    loading
  }
}

export default useEditTenantUser
