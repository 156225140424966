import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useCategoryListing = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const [categoryId, setCategoryId] = useState('')

  const { loading, gameCategory, gameSubCategory } = useSelector((state) => state.superAdminCasino)
  const totalPages = Math.ceil(gameSubCategory?.count / limit)

  const getGameName = (id) => {
    return gameCategory && gameCategory?.rows?.find((obj) => obj.masterGameCategoryId === id)?.name?.EN
  }

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '', search: '', tenantId: '' }))
  }, [])

  useEffect(() => {
    dispatch(getSuperAdminGameSubCategoryStart({ limit, pageNo: page, search, isActive: active, gameCategoryId: categoryId, tenantId: '' }))
  }, [limit, page, active, categoryId])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getSuperAdminGameSubCategoryStart({ limit, pageNo: page, search, isActive: active, gameCategoryId: categoryId, tenantId: '' }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    totalPages,
    gameSubCategory,
    getGameName,
    search,
    setSearch,
    active,
    setActive,
    gameCategory,
    categoryId,
    setCategoryId
  }
}

export default useCategoryListing
