import { takeLatest, put } from 'redux-saga/effects'
import {
  getLanguages,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage,
  updateErrorCodes,
  setLanguageStatus
} from '../../utils/apiCalls'
import {
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  uploadLanguageCSVStart,
  uploadLanguageCSVComplete,
  updateLanguageSupportStart,
  updateLanguageSupportComplete,
  getCMSLanguagesStart,
  getCMSLanguagesSuccess,
  getCMSLanguagesFailure,
  languageStatusStart,
  languageStatusSuccess,
  languageStatusFaliure
} from '../redux-slices/languages'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'

export default function * languagesWatcher () {
  yield takeLatest(getLanguagesStart.type, getLanguagesWorker)
  yield takeLatest(getLanguageSupportKeysStart.type, getLanguageSupportKeysWorker)
  yield takeLatest(uploadLanguageCSVStart.type, uploadLanguageCSVWorker)
  yield takeLatest(updateLanguageSupportStart.type, updateLanguageSupportWorker)
  yield takeLatest(getCMSLanguagesStart.type, getCMSLanguagesWorker)
  yield takeLatest(languageStatusStart.type, setLanguageStatusWorker)
}
function * setLanguageStatusWorker (action) {
  try {
    const { languageId, isActive } = action && action.payload
    const { data } = yield setLanguageStatus({
      languageId,
      isActive
    })
    yield put(languageStatusSuccess(data?.data?.message))
    yield toast('Language Status Updated', 'success')
    yield put(getLanguagesStart({
      limit: '',
      pageNo: '',
      name: ''
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(languageStatusFaliure(e?.response?.data?.errors[0].description))
  }
}
function * getLanguagesWorker (action) {
  try {
    const { limit, pageNo, name, isTenant, isAffiliate, isAgent } = action && action.payload

    const { data } = yield getLanguages({ limit, pageNo, name, isTenant, isAffiliate, isAgent })
    yield put(getLanguagesSuccess(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')
    yield put(getLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}

function * getLanguageSupportKeysWorker (action) {
  try {
    const { tenantId, language, isTenant, errorCodes } = action && action.payload

    const { data } = yield getLanguageSupportKeys({
      tenantId,
      language,
      isTenant,
      errorCodes
    })

    yield put(getLanguageSupportKeysSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getLanguageSupportKeysFailure(e?.response?.data?.errors[0].description))
  }
}

function * uploadLanguageCSVWorker (action) {
  try {
    let { data, isTenant, tenantId, language, errorCodes } = action && action.payload
    data = serialize(data)

    yield uploadLanguageCSV({ data, isTenant })

    yield put(uploadLanguageCSVComplete())
    yield toast('File Uploaded Successfully', 'success')

    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language, errorCodes }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(uploadLanguageCSVComplete())
  }
}

function * updateLanguageSupportWorker (action) {
  try {
    const { data, isTenant, tenantId, navigate, errorCodes } = action && action.payload

    yield errorCodes ? updateErrorCodes(data?.type !== 'key' ? data : data?.data) : updateLanguageSupport({ data, isTenant })

    yield put(updateLanguageSupportComplete())
    data?.type === 'create'
      ? yield toast('Language Support Keys Created Successfully', 'success')
      : yield toast('Language Support Keys Updated Successfully', 'success')

    if (data?.type === 'key') {
      isTenant
        ? navigate(`/tenant/language-management/edit/${Object.keys(data?.data)?.[0]}`)
        : navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/edit/${errorCodes ? data?.data?.key : Object.keys(data?.data)?.[0]}`)
    } else if (data?.type === 'create') {
      isTenant
        ? navigate(TenantRoutes.LanguageManagement)
        : navigate(errorCodes ? SuperAdminRoutes.ErrLanguageManagement : SuperAdminRoutes.LanguageManagement)
    }
    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language: '', errorCodes }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateLanguageSupportComplete())
  }
}

function * getCMSLanguagesWorker (action) {
  try {
    const { adminId = '', tenantId = '' } = action && action.payload

    const { data } = yield getCMSLanguage({
      adminId: adminId === null ? '' : adminId,
      tenantId: tenantId === null ? '' : tenantId
    })

    yield put(getCMSLanguagesSuccess(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getCMSLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}
