import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import moment from 'moment'
import { tableHeaders } from './constant'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import ClientFilter from '../../../components/ClientFilter'
import useUserMigrationReport from './hooks/useUserMigrationReport'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'

const UserMigrationReport = () => {
  const {

    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalState,
    selectedClient,
    setSelectedClient,
    userMigrationReport,
    inputRef,
    state,
    setState,
    initialDate,
    handleCsvDownload,
    loading,
    t
  } = useUserMigrationReport()

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('migrationHistory')}</h3>
          </Col>
        </Col>
      </Row>
      <div>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px'
              }}
            >
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              autoComplete='off'
              name='search'
              type='search'
              placeholder={t('searchPlaceholder')}
              size='sm'
              style={{
                marginRight: '15px',
                width: '260px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div xs='auto' className='d-flex m-2'>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex m-2 '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>

          <div className='d-flex gap-2 align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
            {/* <AffiliateAgentFilter
              selectedClient={selectedClient}
              agent={agent}
              setAgent={setAgent}
              affiliate={affiliate}
              setAffiliate={setAffiliate}
            /> */}

            <div className='d-flex px-3 float-end'>
              <Trigger message={t('downloadCsv')}>
                <Button
                  variant='outline-success'
                  className='m-1'
                  size='sm'
                  disabled={userMigrationReport?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  className='m-1'
                  onClick={() => {
                    setState(initialDate)
                    setSelectedClient('')
                    setFormData(initalState)
                    inputRef.current.value = ''
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>

        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((heading, idx) => (
              <th key={heading.label}>
                {t(heading.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && userMigrationReport &&
            userMigrationReport?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index} className='row-height'>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {item?.User?.username || 'NA'}
                      </span>
                    </td>
                    <td>
                      {item?.amount} ₩
                    </td>
                    <td>
                      {item?.nonCashAmount} ₩
                    </td>
                    <td>
                      {item?.hotTimeBonus} ₩
                    </td>
                    <td>
                      {item?.compensationBonus} ₩
                    </td>
                    <td>
                      {item?.paybackBonus} ₩
                    </td>
                    <td>
                      {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || 'NA'}
                    </td>
                  </tr>
                )
              }
            )
          }
          {!loading && userMigrationReport.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {
        !loading && userMigrationReport.count !== 0 &&
        (
          <PaginationComponent
            page={userMigrationReport.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={userMigrationReport.count}
          />
        )
      }
    </div>
  )
}

export default UserMigrationReport
