import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { debounce } from 'lodash'
import { downloadFile } from '../../../utils/fileDownloader'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { cancelBetSlipStart, getSuperAdminSportsTransactionStart } from '../../../store/redux-slices/superAdminTransactions'
const initalState = {
  betType: '',
  settlementStatus: '',
  betslipStatus: '',
  username: ''
}
const initialDate = [
  {
    from: '',
    to: '',
    key: 'selection'
  }
]
const useSportsReporting = (isBetBy) => {
  const isTenant = !window.location.href.match(/super/g)
  const { t } = useTranslation()
  const inputRef = useRef()
  const tenantId = getItem('tenant-id')
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { betTransactions, userTenantReportingData, loading } = useSelector((state) => state.superAdminTransactions)
  const [formData, setFormData] = useState({ ...initalState, limit, pageNo: page })
  const totalPages = Math.ceil(betTransactions?.count / limit)
  const totalPagesUser = Math.ceil(userTenantReportingData?.count / limit)
  const [state, setState] = useState(initialDate)
  const [isConfirmationOpenForBetCancel, setIsConfirmationOpenForBetCancel] = useState(false)
  const [betslipId, setBetslipId] = useState(null)
  const {
    casinoProviders,
    casinoCategories,
    subCategories
  } = useSelector((state) => state.tenantCasino)
  const [selectedClient, setSelectedClient] = useState('')

  const getSportsBetRecords = () => {
    dispatch(getSuperAdminSportsTransactionStart({
      ...formData,
      from: state[0].from ? moment(state[0].from)?.format('MM-DD-YYYY') : '',
      to: state[0].to ? moment(state[0].to)?.format('MM-DD-YYYY') : '',
      limit,
      pageNo: page,
      isTenant: isTenant,
      adminUserId: selectedClient,
      isBetBy
    }))
  }

  useEffect(() => {
    getSportsBetRecords()
  }, [formData, state, selectedClient, limit, page, isBetBy])

  const handleCsvDownloadCasino = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/bet-history?limit=${limit || ''}&pageNo=${page || ''}&username=${formData.username || ''}&betslipStatus=${formData.betslipStatus || ''}&betType=${formData.betType || ''}&settlementStatus=${formData.settlementStatus || ''}&from=${formData.from || ''}&to=${formData.to || ''}&csvDownload=true&token=${getLoginToken()}&${isTenant ? `tenantId=${tenantId}` : `adminUserId=${selectedClient}`}`)
  }

  const betCancelHandler = (betslipId) => {
    setBetslipId(betslipId)
    setIsConfirmationOpenForBetCancel(true)
  }
  const handleYesBetSlip = () => {
    dispatch(cancelBetSlipStart({
      betslipId,
      isTenant,
      onSuccess: () => {
        getSportsBetRecords()
        setIsConfirmationOpenForBetCancel(false)
      }
    }))
  }
  const handleNoBetSlip = () => {
    setIsConfirmationOpenForBetCancel(false)
    setBetslipId(null)
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, username: event.target.value }))
  }

  return {
    formData,
    setFormData,
    totalPages,
    totalPagesUser,
    page,
    limit,
    setPage,
    betTransactions,
    userTenantReportingData,
    setLimit,
    initalState,
    state,
    setState,
    casinoCategories,
    subCategories,
    casinoProviders,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    selectedClient,
    setSelectedClient,
    t,
    loading,
    betCancelHandler,
    debounceFn,
    handleYesBetSlip,
    handleNoBetSlip,
    isConfirmationOpenForBetCancel,
    betslipId
  }
}

export default useSportsReporting
