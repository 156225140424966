import { Button, Col, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { SuperAdminRoutes } from '../../../../routes'
import { updateProviderSchema } from './schema'
import { providerCategory } from './constants'
import { Tab, Tabs } from 'react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import ReactQuill from 'react-quill'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'

const General = ({
  providerDetails,
  updateData,
  navigate,
  logoCheck,
  setLogoCheck,
  tenantLanguages,
  details,
  amtOptions
}) => {
  const [selectedTab, setSelectedTab] = useState((providerDetails?.description && typeof providerDetails?.description === 'object' && Object.keys(providerDetails?.description)?.[0]) || 'EN')
  const [description, setDescription] = useState(providerDetails?.description || {})

  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter(key => predicate(obj[key]))
      .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          depositImage: null,
          withdrawImage: null,
          amountKeys: { ...amtOptions },
          text: providerDetails?.settings?.description || '',
          name: providerDetails?.displayName || '',
          category: providerDetails?.category || 'OTHER',
          depositCount: providerDetails?.settings?.depositCount || 0,
          kycDepositCount: providerDetails?.settings?.kycDepositCount || 0,
          mdr: providerDetails?.settings?.mdr || 0,
          feeSuccessTx: providerDetails?.settings?.feeSuccessTx || 0,
          feeDeclinedTx: providerDetails?.settings?.feeDeclinedTx || 0,
          hideDepositCount: providerDetails?.settings?.hideDepositCount || 0,
          reportName: providerDetails?.reportingName || ''
        }}
        validationSchema={updateProviderSchema(
          providerDetails?.settings?.depositImageUrl,
          providerDetails?.settings?.withdrawImageUrl,
          providerDetails?.supportsDeposit,
          providerDetails?.supportsWithdrawal,
          logoCheck,
          amtOptions
        )}
        onSubmit={(formValues) => {
          updateData({
            data: {
              ...formValues,
              depositImage: formValues.depositImage || providerDetails?.settings?.depositImageUrl,
              withdrawImage: logoCheck === 'false' ? (formValues.withdrawImage || providerDetails?.settings?.withdrawImageUrl) : null,
              logoCheck,
              text: Object.filter(description, desc => desc?.replace(/<[^>]+>/g, '')?.length > 0)
            },
            country: false
          })
        }}
      >
        {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, setFieldError }) => (
          <Form className='align-items-center m-3'>

            <Row className='d-flex mt-5'>
              <Col>
                <BForm.Label>
                  Display Name
                </BForm.Label>
                <BForm.Control
                  type='text'
                  name='name'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Display Name'}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='name'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  Reporting Name <span className='text-danger'>* </span>
                </BForm.Label>
                <BForm.Control
                  type='text'
                  name='reportName'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Reporting Name'}
                  value={values.reportName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='reportName'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  Category
                </BForm.Label>
                <BForm.Select
                  type='select'
                  name='category'
                  disabled={details}
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {Object.keys(providerCategory).map(categoryKey =>
                    <option value={categoryKey} key={categoryKey}>{providerCategory[categoryKey]}</option>)}
                </BForm.Select>
              </Col>
            </Row>

            {values?.amountKeys && Object.keys(values?.amountKeys).length > 0 &&
              <Row className='d-flex mt-3'>
                <BForm.Label>
                  Allowed Amount Options <span className='text-danger'>* </span>
                </BForm.Label>
                {Object.keys(values?.amountKeys).map((key, index) => (
                  values.amountKeys?.[key] !== 'Other' &&
                    <Col key={index}>
                      <BForm.Control
                        type='number'
                        name={`amountKeys.${[key]}`}
                        disabled={details}
                        placeholder={details ? '' : 'Enter Amount Value'}
                        value={values.amountKeys?.[key]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name={`amountKeys.${[key]}`}
                        className='text-danger'
                      />
                    </Col>
                )
                )}
              </Row>}

            <Row className='d-flex mt-3'>
              <Col>
                <BForm.Label>
                  Fixed Fee Per Success TRX <span className='text-danger'>* </span>
                </BForm.Label>
                <BForm.Control
                  type='number'
                  name='feeSuccessTx'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Amount'}
                  value={values.feeSuccessTx}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='feeSuccessTx'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  Fixed Fee Per Declined TRX <span className='text-danger'>* </span>
                </BForm.Label>
                <BForm.Control
                  type='number'
                  name='feeDeclinedTx'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Amount'}
                  value={values.feeDeclinedTx}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='feeDeclinedTx'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  MDR % <span className='text-danger'>* </span>
                </BForm.Label>
                <BForm.Control
                  type='number'
                  name='mdr'
                  disabled={details}
                  placeholder={details ? '' : 'Enter MDR%'}
                  value={values.mdr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='mdr'
                  className='text-danger'
                />
              </Col>
            </Row>

            <Row className='d-flex mt-3'>
              {providerDetails?.supportsDeposit &&
                <Col xs={6}>
                  <BForm.Label>
                    Deposit Logo <span className='text-danger'>* </span>
                  </BForm.Label>
                  <BForm.Control
                    type='file'
                    disabled={details}
                    name='depositImage'
                    onChange={(e) => setFieldValue('depositImage', e.target.files[0])}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='depositImage'
                    className='text-danger'
                  />

                  {!errors?.depositImage && (values?.depositImage || providerDetails?.settings?.depositImageUrl) &&
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                          src={
                            values?.depositImage
                              ? URL.createObjectURL(values?.depositImage)
                              : providerDetails?.settings?.depositImageUrl
                          }
                        />
                      </Col>
                    </Row>}
                </Col>}
              {providerDetails?.supportsWithdrawal &&
                <Col xs={6}>
                  <div className='d-flex'>
                    <BForm.Label>
                      Withdraw Logo <span className='text-danger'>* </span>
                    </BForm.Label>
                    {providerDetails?.supportsDeposit &&
                      <>
                        (<p>Same as Deposit : &nbsp;</p>
                        <BForm.Check
                          value={logoCheck}
                          disabled={details}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue('withdrawImage', null)
                              setLogoCheck('true')
                              setFieldError('withdrawImage', null)
                            } else setLogoCheck('false')
                          }}
                        />)
                      </>}
                  </div>

                  <BForm.Control
                    type='file'
                    name='withdrawImage'
                    disabled={logoCheck === 'true' || details}
                    onChange={(e) => setFieldValue('withdrawImage', e.target.files[0])}
                    onBlur={handleBlur}
                  />

                  {logoCheck === 'false' &&
                    <ErrorMessage
                      component='div'
                      name='withdrawImage'
                      className='text-danger'
                    />}

                  {!errors?.withdrawImage && logoCheck === 'false' && (values?.withdrawImage || providerDetails?.settings?.withdrawImageUrl) &&
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                          src={
                            values?.withdrawImage
                              ? URL.createObjectURL(values?.withdrawImage)
                              : providerDetails?.settings?.withdrawImageUrl
                          }
                        />
                      </Col>
                    </Row>}
                </Col>}
              {providerDetails?.supportsDeposit && (
                <>
                  <Col xs='auto'>
                    <BForm.Label>
                      Deposit Count
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='depositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.depositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='depositCount'
                      className='text-danger'
                    />
                  </Col>

                  <Col xs='auto'>
                    <BForm.Label>
                      KYC Deposit Count
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='kycDepositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.kycDepositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='kycDepositCount'
                      className='text-danger'
                    />
                  </Col>

                  <Col xs='auto'>
                    <BForm.Label>
                      Deposit Count to Hide Method
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='hideDepositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count to Hide Method'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.hideDepositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='hideDepositCount'
                      className='text-danger'
                    />
                  </Col>
                </>
              )}
            </Row>

            {/* Description */}

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light mt-3'
            >
              {(details ? description?.EN : true) &&
                <Tab
                  eventKey='EN'
                  title={
                    <Trigger message='English'>
                      <span>EN</span>
                    </Trigger>
                  }
                  mountOnEnter
                  tabClassName={selectedTab !== 'EN' ? (description?.EN !== undefined && description?.EN !== '') ? 'email' : '' : 'email-active'}
                >
                  <div className='mt-2'>
                    <label>
                      Description
                    </label>
                    <ReactQuill
                      name='description'
                      placeholder={details ? '' : 'Enter Description'}
                      preserveWhitespace
                      readOnly={details}
                      value={description?.EN || ''}
                      onChange={(e) => {
                        setDescription({
                          ...description,
                          EN: e
                        })
                      }}
                    />
                  </div>
                </Tab>}

              {tenantLanguages?.map(lang => (
                lang !== 'EN' && (details ? description?.[lang] : true) &&
                  <Tab
                    eventKey={lang}
                    key={lang}
                    title={
                      <Trigger message={languageCode[lang]}>
                        <span>{lang}</span>
                      </Trigger>
                  }
                    mountOnEnter
                    tabClassName={selectedTab !== lang ? (description?.[lang] !== undefined && description?.[lang] !== '') ? 'email' : '' : 'email-active'}
                  >
                    <div className='mt-2'>
                      <label>
                        Description
                      </label>
                      <ReactQuill
                        name='description'
                        placeholder={details ? '' : 'Enter Description'}
                        readOnly={details}
                        value={description?.[lang] || ''}
                        preserveWhitespace
                        onChange={(e) => {
                          setDescription({
                            ...description,
                            [lang]: e
                          })
                        }}
                      />
                    </div>
                  </Tab>
              ))}
            </Tabs>

            <div
              className='mt-4 d-flex justify-content-between align-items-center'
            >
              <Button
                variant='outline-warning'
                onClick={() => navigate(SuperAdminRoutes.PaymentAggregators)}
              >
                Cancel
              </Button>

              <div>
                <Button
                  variant='outline-success'
                  onClick={() => {
                    handleSubmit()
                  }}
                  hidden={details}
                  className='ml-2'
                >
                  Submit
                </Button>
              </div>
            </div>

          </Form>
        )}
      </Formik>
    </>
  )
}

export default General
