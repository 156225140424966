import { Button, Table } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import moment from 'moment'
import {
  tableHeaders,
  requestActions
} from './constants'
import {
  ButtonGroup,
  Col,
  Form,
  Row
} from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import UseTenantUserWallet from './hooks/useTenantUserWallet'
import { WalletActionConfirmModal } from '../../../components/ConfirmationModal'
import PaginationComponent from '../../../components/Pagination'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../utils/storageUtils'
import { depositAddonConfigSelectedType, depositAddonType } from '../../Super-Admin/DepositAddOn/depositAddonConstant'
const AddOnConfig = ({ t, addOnSelect, setAddOnSelect, item, isHistory }) => {
  return (
    <div className='d-flex flex-column gap-1' style={{ minWidth: 240 }}>

      {
        isHistory
          ? (
            <>
              <div className='d-flex align-items-center gap-1'>
                <span className='flex-title'>
                  {t('addOnConfigType')}:
                </span>
                <b>  {item?.addOnConfigType || '-'}</b>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <span className='flex-title'>
                  {item?.relativeAmount ? t('addOnPercentage') : t('addOnRelativeAmount')}:
                </span>
                <b>  {item?.relativeAmount || item?.depositPercentage || 0}</b>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <span className='flex-title'>
                  {t('rollingPercentage')}:
                </span>
                <b>  {item?.rollingPercentage || 0}</b>
              </div>
            </>
            )

          : (
            <>
              <div>
                <Form.Select
                  value={addOnSelect.type}
                  onChange={(e) => {
                    setAddOnSelect(prev => (
                      {
                        ...prev,
                        type: e.target.value

                      }
                    ))
                  }}
                  style={{
                    maxWidth: '230px'
                  }}
                  size='sm'
                >
                  <option
                    value=''
                  >
                    {t('selectAddOnConfigType')}
                  </option>
                  {depositAddonType?.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {t(option)}
                    </option>
                  ))}
                </Form.Select>
              </div>
              {addOnSelect.type && (
                <div className='pt-1'>
                  <Form.Select
                    value={addOnSelect.name}
                    onChange={(e) => {
                      setAddOnSelect(prev => (
                        {
                          ...prev,
                          name: e.target.value

                        }
                      ))
                    }}
                    style={{
                      maxWidth: '230px'
                    }}
                    size='sm'
                  >
                    <option
                      value=''
                    >
                      {t('selectAddOnConfigValueType')}
                    </option>
                    {depositAddonConfigSelectedType?.map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {t(option)}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              )}
              {addOnSelect.name && addOnSelect.type && (
                <>
                  <div className='d-flex align-items-center gap-1'>
                    <span className='flex-title'>
                      {t(addOnSelect.name)}:
                    </span>
                    <b> {item?.TenantConfiguration[addOnSelect?.type][addOnSelect?.name]}</b>
                  </div>
                  <div className='d-flex align-items-center gap-1'>
                    <span className='flex-title'>
                      {t('rollingPercentage')}:
                    </span>
                    <b>  {item?.TenantConfiguration[addOnSelect?.type].rollingPercentage || 0}</b>
                  </div>

                </>
              )}

            </>
            )
      }

    </div>
  )
}
const SingleRow = ({
  index,
  page,
  limit,
  t,
  item,
  handleShowModal,
  isV2,
  isHistory
}) => {
  const [addOnSelect, setAddOnSelect] = useState({
    type: '',
    name: ''
  })
  const renderStatus = (status) => {
    if (status === 'ACCEPT') {
      return <span className='fw-bolder text-success'>{t('accepted')}</span>
    } else if (status === 'CANCEL') {
      return <span className='fw-bolder text-danger'>{t('canceled')}</span>
    } else {
      return <span className='fw-bolder text-danger'>{t('rejected')}</span>
    }
  }
  return (
    <tr>
      <td>{(page - 1) * limit + index + 1}</td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('userName')}:
            </span>
            <b> {item?.username || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('fullName')}:
            </span>
            <b>  {item?.name || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('affiliate')}:
            </span>
            <b> {item?.Affiliate?.username || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('agent')}:
            </span>
            <b> {item?.Agent?.username || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('ip')}:
            </span>
            <b>  {(item?.ipAddress?.split('::ffff:')?.[1] ?? item.ipAddress) || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('phone')}:
            </span>
            <b> {item?.User?.phoneCode}{item?.User?.phone}</b>
          </div>

        </div>
      </td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('bankName')}:
            </span>
            <b> {item?.User?.bankName || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('accountNumber')}:
            </span>
            <b>  {item?.User?.accountNumber || 'NA'}</b>
          </div>
        </div>
      </td>
      {isV2 && (
        <td>
          {item?.numberOfAcceptedRequests}
        </td>
      )}
      {isV2 && (
        <td>
          <AddOnConfig
            t={t}
            addOnSelect={addOnSelect}
            setAddOnSelect={setAddOnSelect}
            item={item}
            isHistory={isHistory}
          />
        </td>
      )}
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex text-left gap-1'>
            <span className='flex-title'>
              {t('transactionId')}:
            </span>
            <b className='text-wrap' title={item?.transactionId}> {item?.transactionId}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('transactionType')}:
            </span>
            <b>   {item?.transactionType || 'REQUESTED'}</b>
          </div>
        </div>
      </td>
      <td>
        {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || '-'}
      </td>
      <td>
        <b>
          {formatNumber(item?.amount)} {item?.currency}
        </b>
      </td>
      <td>
        {
          item.status === 'PENDING' && item?.actionType !== 'COMPENSATION-BONUS-DEPOSIT'
            ? (
              <ButtonGroup>
                <Trigger message={t('acceptRequest')}>
                  <Button
                    className='m-1 fw-bolder'
                    size='sm'
                    variant='success'
                    disabled={isV2 && !(addOnSelect?.name && addOnSelect.type)}
                    onClick={() => handleShowModal('accept',
                      item?.actionType,
                      'ACCEPT',
                      item?.transactionId,
                      item?.TenantConfiguration,
                      addOnSelect

                    )}
                  >
                    {t('accept')}
                  </Button>
                </Trigger>
                <Trigger message={t('rejectRequest')}>
                  <Button
                    className='m-1 fw-bolder'
                    size='sm'
                    variant='danger'
                    disabled={item.transactionType === 'NON-REQUEST' || item?.status !== 'PENDING'}
                    onClick={() => handleShowModal('reject',
                      item.actionType,
                      'REJECT',
                      item?.transactionId,
                      item?.TenantConfiguration,
                      addOnSelect

                    )}
                  >
                    {t('reject')}
                  </Button>
                </Trigger>
              </ButtonGroup>
              )
            : (
                renderStatus(item?.status)
              )
        }
      </td>
    </tr>
  )
}

const TenantUserWallet = ({ isWithdrawal, isHistory, isVault, isV2 }) => {
  const {
    handleShowModal,
    showWalletRequestAction,
    setShowWalletRequestAction,
    walletRequestAction,
    handleConfirmActionRequest,
    formData,
    setFormData,
    tenantWalletTransactions,
    tenantWalletTransactionsLoading,
    totalPages,
    setPage,
    limit,
    page,
    setLimit,
    debounceFn,
    initalWalletState,
    inputRef,
    handleCsvDownload,
    state,
    setState,
    initialDate,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = UseTenantUserWallet({ isWithdrawal, isHistory, isVault, isV2 })

  const dashboardData = tenantWalletTransactions?.detailsCounts?.[0]
  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{isVault ? isWithdrawal ? t('vaultWithdrawalHistory') : t('vaultDepositHistory') : isWithdrawal ? isHistory ? t('withdrawalHistory') : t('withdrawalRequest') : isHistory ? t('depositHistory') : t('depositRequest')}</h3>
          </Col>
        </Col>
      </Row>
      <div className='filter-wrapper'>
        <div className='d-flex flex-wrap align-items-center gap-2'>
          <div>
            <AffiliateAgentFilter
              agent={agent}
              setAgent={setAgent}
              affiliate={affiliate}
              setAffiliate={setAffiliate}
            />

          </div>
          {isHistory && !isVault && (
            <div className='d-flex align-items-center'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
                {t('transactionStatus')}
              </Form.Label>
              <Form.Select
                value={formData?.actionType}
                onChange={(e) => {
                  setFormData({ ...formData, actionType: e.target.value })
                  setPage(1)
                }}
                style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
                size='sm'
              >
                {requestActions?.map((requestAction) =>
                  (
                    <option
                      key={requestAction.value}
                      value={requestAction.value}
                    >
                      {t(requestAction?.label)}
                    </option>
                  )
                )}
              </Form.Select>
            </div>
          )}

          <div xs='auto' className='d-flex'>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('search')}
              size='sm'
              className='w-90 mx-auto'
              style={{ minWidth: '180px', maxWidth: '230px', width: 'auto' }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>

          <div className='d-flex gap-2 align-items-center'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setState(initialDate)
                  setFormData(initalWalletState)
                  setAffiliate('')
                  setAgent('')
                  inputRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
            <Trigger message={t('downloadAsCSV')}>
              <Button
                variant='outline-success'
                size='sm'
                disabled={tenantWalletTransactions?.walletTranactionDetails?.count === 0}
                onClick={() => {
                  handleCsvDownload()
                }}
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </Trigger>
          </div>

        </div>
      </div>
      <div className='table-dashboard'>
        <div className='item-wrapper'>
          <span className='item-heading'>
            {t('now')}
          </span>
        </div>
        {!isHistory && (
          <>
            <div className='item-wrapper'>
              <span className='item-heading'>
                {isWithdrawal ? t('totalWithdrawalRequest') : t('totalDepositRequest')}
              </span>
              <b className='item-content'>{formatNumber(dashboardData?.PENDING || 0)} {t('case')}</b>
            </div>
          </>
        )}
        {isHistory && (
          <>
            <div className='item-wrapper'>
              <span className='item-heading'>
                {t('confirm')}
              </span>
              <b className='item-content'>{formatNumber(dashboardData?.ACCEPTED || 0)} {t('case')}</b>
            </div>
            {!isVault && (
              <>
                <div className='item-wrapper'>
                  <span className='item-heading'>
                    {t('reject')}
                  </span>
                  <b className='item-content'>{formatNumber(dashboardData?.REJECTED || 0)} {t('case')}</b>
                </div>
                <div className='item-wrapper'>
                  <span className='item-heading'>
                    {t('cancel')}
                  </span>
                  <b className='item-content'>{formatNumber(dashboardData?.CANCELED || 0)} {t('case')}</b>
                </div>
              </>
            )}
          </>
        )}
        <div className='item-wrapper'>
          <span className='item-heading'>
            {isWithdrawal ? t('totalWithdrawalAmount') : t('totalDepositAmount')}
          </span>
          <b className='item-content'>{formatNumber(isVault ? isWithdrawal ? dashboardData?.totalVaultWithdrawalAmount : dashboardData?.totalVaultDepositAmount : isWithdrawal ? dashboardData?.totalWithdrawalAmount : dashboardData?.totalDepositAmount || 0)} ₩</b>
        </div>

      </div>
      <div className='custom-table  mt-4'>
        <Table responsive className='text-center '>
          <thead className=''>
            <tr>
              {tableHeaders(isV2).map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={tenantWalletTransactionsLoading ? 'cover-spin' : ''}>
            {
            !tenantWalletTransactionsLoading && tenantWalletTransactions &&
            tenantWalletTransactions?.walletTranactionDetails?.rows?.map(
              (item, index) => {
                return (
                  <SingleRow
                    key={index}
                    index={index}
                    page={page}
                    limit={limit}
                    t={t}
                    item={item}
                    handleShowModal={handleShowModal}
                    isV2={isV2}
                    isHistory={isHistory}
                  />

                )
              }
            )
          }
            {tenantWalletTransactions?.walletTranactionDetails?.count === 0 && !tenantWalletTransactionsLoading &&
                      (
                        <tr>
                          <td
                            colSpan={8}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
          </tbody>
        </Table>
      </div>
      {!tenantWalletTransactionsLoading && tenantWalletTransactions?.walletTranactionDetails?.count !== 0 &&
            (
              <PaginationComponent
                page={tenantWalletTransactions?.walletTranactionDetails?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tenantWalletTransactions?.walletTranactionDetails?.count}
              />
            )}

      {showWalletRequestAction && (
        <WalletActionConfirmModal
          action={walletRequestAction}
          setShow={setShowWalletRequestAction}
          show={showWalletRequestAction}
          handleConfirmActionRequest={handleConfirmActionRequest}
        />
      )}
    </div>
  )
}

export default TenantUserWallet
