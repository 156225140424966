import { Button, ButtonGroup, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import useLanguages from './hooks/useLanguages'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useCheckPermission from '../../../utils/checkPermission'
import ConfirmationModal from '../../../components/ConfirmationModal'

const Languages = () => {
  const {
    loading,
    languages,
    limit,
    pageNo,
    name,
    setName,
    handleLanguageStatus,
    isLanguageStatusOpen,
    setIsLangugeStatusOpen,
    langugageData,
    setLanguageData,
    t
  } = useLanguages()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col>
          <h3>{t('languages')}</h3>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              placeholder={t('searchLanguage')}
              size='sm'
              style={{ maxWidth: '230px' }}
              value={name}
              onChange={(event) =>
                setName(event.target.value.replace(/[^\w\s]/gi, ''))}
            />
          </div>
        </Col>
      </Row>

      <Table
        bordered striped
        responsive hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            <th>{t('sno')}</th>
            <th>{t('languageCode')}</th>
            <th>{t('languageName')}</th>
            <th>{t('status')}</th>
            <th>{t('action')}</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && languages?.map((item, index) => (
            <tr key={index}>
              <td>{(index + 1) + (limit * (pageNo - 1))}</td>

              <td>
                {item?.code}
              </td>
              <td>
                {item?.languageName}
              </td>
              <td className={item?.isActive ? 'table-success' : 'table-danger'}>
                {item?.isActive ? t('active') : t('inactive')}
              </td>
              {
                !(item?.code === 'EN')
                  ? (
                    <td>
                      <ButtonGroup>
                        <Trigger message={!item?.isActive ? t('setStatusActive') : t('setStatusInactive')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant={!item?.isActive ? 'success' : 'danger'}
                            onClick={() => {
                              setIsLangugeStatusOpen(true)
                              setLanguageData({
                                languageId: item.languageId,
                                isActive: !item.isActive
                              })
                            }}
                            hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                          >
                            <FontAwesomeIcon icon={!item?.isActive ? faCheckSquare : faWindowClose} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                    )
                  : <></>
              }
            </tr>
          ))}
        </tbody>

        {languages?.length === 0 && !loading &&
          <tr>
            <td
              colSpan={4}
              className='text-danger text-center'
            >
              {t('noDataFound')}
            </td>
          </tr>}
      </Table>
      {isLanguageStatusOpen &&
        <ConfirmationModal
          setShow={setIsLangugeStatusOpen}
          show={isLanguageStatusOpen}
          handleYes={handleLanguageStatus}
          active={!langugageData?.isActive}
          name={name}
        />}
    </>
  )
}

export default Languages
