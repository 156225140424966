import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner

} from '@themesberg/react-bootstrap'
import React from 'react'
import './depositAddonConfig.scss'
import useSportsPromotionCashConfig from './hooks/useSportsPromotionCashConfig'
import { promotionCashSchema } from './hooks/schema'
import Preloader from '../../../components/Preloader'
import ClientFilter from '../../../components/ClientFilter'

const SportsPromotionCashConfig = () => {
  const {
    selectedClient,
    setSelectedClient,
    t,
    loading,
    promotionCashAddEditLoading,
    promotionCashAddEditHandler,
    tenantDetails
  } = useSportsPromotionCashConfig()

  return (
    <div className='p-3'>
      <Row className='mb-3'>
        <Col>
          <h3>{t('sportsPromotionCashConfig')}</h3>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>
        </Col>
      </Row>

      {selectedClient
        ? loading
            ? <Preloader />
            : (
              <Formik
                enableReinitialize
                initialValues={{
                  rollingPercentage: tenantDetails?.tenantConfig?.promotionCashConfig?.rollingPercentage || ''
                }}
                validationSchema={promotionCashSchema(t)}
                onSubmit={(formValues, { resetForm }) => {
                  const newFormsValue = {
                    ...formValues,
                    tenantId: Number(selectedClient),
                    addOnType: 'promotionCashConfig'
                  }
                  promotionCashAddEditHandler(newFormsValue, resetForm)
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue
                }) => {
                  return (
                    <Form className='mt-2'>
                      <Row>
                        <Col className='mb-2' sm={6}>
                          <label>
                            {t('sportsRollingPercentage')}<span className='text-danger'> *</span>
                          </label>
                          <BForm.Control
                            name='rollingPercentage'
                            type='number'
                            min={1}
                            placeholder={t('enterValue')}
                            value={values.rollingPercentage}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            component='div'
                            name='rollingPercentage'
                            className='text-danger'
                          />
                        </Col>

                      </Row>

                      <div className='mt-4 d-flex justify-content-end align-items-center'>
                        <Button
                          disabled={promotionCashAddEditLoading}
                          variant='outline-success'
                          onClick={handleSubmit}
                          className='ml-2'
                        >
                          {t('update')}
                          {promotionCashAddEditLoading && (
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                        </Button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
              )
        : (
          <div className='depositType-select-tenant'>
            <p>{t('pleaseSelectTenantForPromotionCashConfig')}</p>
          </div>
          )}
    </div>
  )
}

export default SportsPromotionCashConfig
