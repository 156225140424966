import React from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Table
} from '@themesberg/react-bootstrap'
import useCredentialsListing from './hooks/useCredentialsListing'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLock } from '@fortawesome/free-solid-svg-icons'

const TenantCredentials = () => {
  const {
    data,
    loading,
    navigate
  } = useCredentialsListing()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3><FontAwesomeIcon icon={faLock} />&nbsp;Credentials</h3>
        </Col>
      </Row>

      <Table bordered responsive hover className='mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>ID</th>
            <th>Key</th>
            <th>Value</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && data &&
                  data.map(({ credentialId, key, value, description }) => {
                    return (
                      <tr key={credentialId}>
                        <td>{credentialId}</td>
                        <td>{key}</td>
                        <td>{key === 'SUPPORT_EMAIL_ADDRESS'
                          ? value
                          : '***********************'}
                        </td>
                        <td>
                          <Trigger message={description || 'NA'}>
                            <span
                              style={{
                                width: '100px',
                                cursor: 'pointer'
                              }}
                              className='d-inline-block text-truncate'
                            >
                              {description || 'NA'}
                            </span>
                          </Trigger>
                        </td>

                        <td className='text-center'>
                          {key === 'SUPPORT_EMAIL_ADDRESS'
                            ? (
                              <ButtonGroup>
                                <Trigger message='Edit'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='warning'
                                    onClick={() =>
                                      navigate(
                                    `/tenant/update-credentials/${key}`
                                      )}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Trigger>
                              </ButtonGroup>)
                            : '-'}
                        </td>
                      </tr>
                    )
                  })}
        </tbody>
      </Table>

      {(data?.length < 1) && !loading && (
      // If no data found
        <Form.Label className='text-danger d-flex justify-content-center'>
          No data found.
        </Form.Label>
      )}
    </>
  )
}

export default TenantCredentials
