import React, {
// useState
} from 'react'
import {
  // Tabs,
  // Tab,
  Row,
  Col
} from '@themesberg/react-bootstrap'
import Tenants from '../CreateTenant/components/Tenant'
import useEditTenant from './hooks/useEditTenant'
import Preloader from '../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const UpdateTenant = () => {
  const {
    setSelectedTab,
    tenantDetails,
    createTenantData,
    setCreateTenantData,
    loading,
    adminUserDetails,
    navigate,
    setMyData
  } = useEditTenant()
  const { t } = useTranslation()
  return (
    <div className='px-2'>
      <Row className='px-2'>
        <Col className='d-flex'>
          <h3>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className='px-1 pt-2 cursor-pointer'
              onClick={() => navigate(-1)}
            />
            &nbsp; {t('tenant')} : &nbsp;{tenantDetails && tenantDetails?.name}
          </h3>
        </Col>
        <Col className='d-flex justify-content-end mt-2'>
          <h3>{t('senior')} : {adminUserDetails?.firstName}{' '}{adminUserDetails?.lastName}</h3>
        </Col>
      </Row>
      {
         loading
           ? <Preloader />
           : (
             <div className='my-2 mx-2 pb-2'>
               <span className='fs-6 fw-bold ps-1'>{t('editDetails')}</span>
               <Tenants
                 createTenantData={createTenantData}
                 setCreateTenantData={setCreateTenantData}
                 setSelectedTab={setSelectedTab}
                 setFieldValidation={null}
                 setMyData={setMyData}
               />
             </div>
             )
}
    </div>
  )
}

export default UpdateTenant
