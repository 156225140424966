import React from 'react'
import { Button, Col, Form, Table } from '@themesberg/react-bootstrap'
import { tableHeaders } from './constants'
import UseReferrals from './hooks/useReferrals'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import moment from 'moment'

const Referrals = () => {
  const {
    navigate,
    totalPages,
    setSearch,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    referralData,
    handleCsvDownload,
    inputRef,
    loading,
    t
  } = UseReferrals()

  return (
    <div className='p-3'>
      <Col xs='auto'>
        <h3>{t('playerReferral')}</h3>
      </Col>
      <div className='d-flex gap-2 justify-content-between flex-wrap align-items-center'>
        <div className='d-flex gap-2 align-items-center'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px'
              }}
            >
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchName')}
              size='sm'
              className='w-90 mx-auto'
              style={{
                minWidth: '180px',
                maxWidth: '230px',
                width: 'auto'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <Col className='d-flex p-3 float-end'>
            <Trigger message={t('downloadAsCSV')}>
              <Button
                variant='outline-success'
                size='sm'
                disabled={referralData?.count === 0}
                onClick={() => {
                  handleCsvDownload()
                }}
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </Trigger>
          </Col>
          <Col className='d-flex p-3 float-end'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setSearch(' ')
                  inputRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && referralData &&
            referralData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {item?.userId}
                      </span>
                    </td>
                    <td>
                      {item?.username}
                    </td>
                    <td>
                      {item?.uniqueId || t('na')}
                    </td>
                    <td>
                      {item?.referralLink || t('na')}
                    </td>
                    <td>
                      {moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss')}
                    </td>
                    <td>
                      {item?.totalReferCount}
                    </td>
                    <td>
                      <Button
                        className='m-1'
                        size='sm'
                        onClick={() =>
                          navigate(
                            `/super-admin/associate-user-referral/${item?.userId}`, { state: { data: item } }
                          )}
                      >
                        {t('viewReferredUser')}
                      </Button>
                    </td>
                  </tr>
                )
              }
            )
          }
          {!loading && referralData?.count === 0 &&
            (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      {!loading && referralData?.count !== 0 &&
        (
          <PaginationComponent
            page={referralData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={referralData?.count}
          />
        )}
    </div>
  )
}

export default Referrals
