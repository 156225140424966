import { Modal, Form, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { withdrawalSchema } from './schema'
import { useTranslation } from 'react-i18next'
const WithdrawlLimitModal = ({
  show,
  withdrawlLimitData,
  setWithdrawlLimitModal,
  user
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          currency: user?.withdrawlLimitCurrency || '',
          amount: user?.withdrawlLimit || ''
        }}
        validationSchema={withdrawalSchema(t)}
        onSubmit={(formValues, { resetForm }) => {
          withdrawlLimitData(formValues)
          resetForm({ formValues: '' })
          setWithdrawlLimitModal(false)
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Modal
            show={show}
            onHide={() => setWithdrawlLimitModal(false)}
            backdrop='static'
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <h5>Set {user?.legalName || 'Player'} Withdrawl Limit</h5>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className='d-flex mb-3 w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                      Currency
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column flex-grow-1 gap-2'>
                    <Form.Select
                      className='w-100'
                      size='sm'
                      name='currency'
                      style={{ width: '200px' }}
                      value={values?.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value=''>Select a Currency</option>
                      <option key={1} value='KRW'>
                        ₩
                      </option>
                    </Form.Select>
                    <ErrorMessage
                      component='div'
                      name='currency'
                      className='text-danger'
                    />
                  </div>

                </div>
                <div className='d-flex w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label
                      column='sm'
                      className='mx-auto text-nowrap px-2'
                    >
                      Amount
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column flex-grow-1 gap-2'>
                    <Form.Control
                      className='w-100'
                      type='number'
                      name='amount'
                      placeholder='Enter amount'
                      size='sm'
                      style={{ width: '200px' }}
                      value={values?.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component='div'
                      name='amount'
                      className='text-danger'
                    />

                  </div>

                </div>

              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer
                className='d-flex justify-content-between align-items-center'
              >
                <Button
                  variant='outline-warning'
                  onClick={() => setWithdrawlLimitModal(false)}
                >
                  Cancel
                </Button>
                <div className='mt-4'>
                  <div className='d-flex justify-content-center'>
                    <Button
                      className='depositButton'
                      variant='outline-info'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Set Limit
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default WithdrawlLimitModal
