import React, { forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datePicker.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Form } from 'react-bootstrap'

const InputDatePicker = ({
  isLabelSm,
  isRequired,
  isFilter,
  isForm,
  value: date,
  label,
  onChange,
  minDate,
  disabled,
  maxDate,
  isFullWidth,
  className,
  showTimeSelect = true,
  ...props
}) => {
  const { t } = useTranslation()
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button disabled={disabled} type='button' className={`date-input-wrapper ${isFilter ? 'form-select-sm' : 'form-select full-width'}`} onClick={onClick} ref={ref}>
      {date ? value : showTimeSelect ? 'mm/dd/yyyy hh:mm a' : 'mm/dd/yyyy'}
      <img src='/icon/chevron-down.svg' alt='arrow' />
    </button>
  ))

  const now = moment().toDate()
  return (
    <div className={`${isFilter && 'date-row'} ${isForm && 'date-column'} ${className} gap-1`}>
      {label && (
        <Form.Label
          className='flex-shrink-0'
          style={{ fontSize: isFilter || isLabelSm ? 13 : 16, marginBottom: 0 }}
        >
          {label} {isRequired && <span className='text-danger'> *</span>}
        </Form.Label>
      )}
      <ReactDatePicker
        timeFormat='HH:mm a'
        timeIntervals={1}
        minDate={isFilter ? null : minDate || now}
        maxDate={isFilter ? null : maxDate || null}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy HH:mm a' : 'MM/dd/yyyy'}
        timeCaption='Hour'
        selected={date}
        onChange={onChange}
        customInput={<CustomInput />}
        showTimeSelect={showTimeSelect}
        placeholderText={t('selectDate')}
        popperPlacement='top-start'
        popperProps={{}}
        popperModifiers={[
          {
            name: 'myModifier',
            fn (state) {
              return state
            }
          }
        ]}
        {...props}
      />
    </div>
  )
}

export default InputDatePicker
