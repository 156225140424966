export const sportsListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'sportName', value: 'sportsName' },
  { label: 'providerSportId', value: 'providerSportId' },
  { label: 'status', value: 'disabledStatus' },
  { label: 'thumbnail', value: 'thumbnail' },
  { label: 'isTop', value: 'disabledStatus' },
  { label: 'action', value: 'actionButton' }
]

export const sportsCountriesListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'countryName', value: 'countryName' },
  { label: 'sportName', value: 'sportName' },
  { label: 'providerCountryId', value: 'providerCountryId' },
  { label: 'status', value: 'disabledStatus' },
  { label: 'action', value: 'button' }
]

export const matchListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'sportName', value: 'sportname' },
  { label: 'tournamentName', value: 'tournamentname' },
  { label: 'countryName', value: 'countryname' },
  { label: 'matchTime', value: 'matchtime' },
  { label: 'homeTeam', value: 'hometeam' },
  { label: 'awayTeam', value: 'awayteam' },
  // { label: 'liveStatus', value: 'islive' },
  { label: 'status', value: 'disabledStatus' },
  { label: 'action', value: 'button' }
]

export const marketListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'marketName', value: 'marketName' },
  { label: 'providerMarketId', value: 'providerMarketId' },
  { label: 'status', value: 'disabledStatus' },
  { label: 'sportName', value: 'sportname' },
  { label: 'action', value: 'button' }
]

export const leagueListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'tournamentName', value: 'tournamentName' },
  { label: 'providerMarketId', value: 'providerMarketId' },
  { label: 'sportName', value: 'sportname' },
  { label: 'thumbnail', value: 'thumbnail' },
  { label: 'countryName', value: 'countries.name' },
  { label: 'status', value: 'isActive' },
  { label: 'isTop', value: 'isActive' },
  { label: 'action', value: 'button' }
]
export const teamListingTableHeader = [
  { label: 'sno', value: '' },
  { label: 'teamName', value: 'teamName' },
  { label: 'providerSportId', value: 'providerSportId' },
  { label: 'thumbnail', value: 'thumbnail' }
]
export const disableAction = [
  { label: 'true', value: 'Enable' },
  { label: 'false', value: 'Disable' }
]

export const liveAction = [
  { label: 'true', value: 'live' },
  { label: 'false', value: 'notLive' }
]

export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'comboName', value: 'username' },
  { label: 'startDate', value: 'startDate' },
  { label: 'action', value: 'button' }
]

export const outcomeHeaders = [
  { label: 'sNo', value: '' },
  { label: 'homeTeam', value: 'id' },
  { label: 'awayTeam', value: 'username' },
  { label: 'outcomeName', value: 'referralcode' },
  { label: 'odds', value: 'referrallink' }
]
