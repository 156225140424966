import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getBetBySportsLogsDataStart } from '../../../../store/redux-slices/admins'
import moment from 'moment'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'

const initalState = {
  search: '',
  logType: 'sports'
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useBetBySportsLogReporting = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { betBySportsLogData, loading } = useSelector((state) => state.admins)
  const [formData, setFormData] = useState({ ...initalState, limit, pageNo: page })
  const totalPages = Math.ceil(betBySportsLogData?.count / limit)
  const [state, setState] = useState(initialDate)

  useEffect(() => {
    dispatch(getBetBySportsLogsDataStart({
      form: {
        ...formData,
        from: moment(state[0].startDate).toISOString(),
        to: moment(state[0].endDate).toISOString(),
        limit,
        pageNo: page
      }
    }))
  }, [formData, limit, page, state])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }
  const handleCsvDownloadCasino = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/log-management?logType=${formData.logType || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }

  return {
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    betBySportsLogData,
    setLimit,
    debounceFn,
    initalState,
    state,
    setState,
    handleCsvDownloadCasino,
    inputRef,
    initialDate,
    loading,
    t
  }
}

export default useBetBySportsLogReporting
