import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRevenueAffiliateAndAgentDataStart, getRevenueSeniorDataStart } from '../../../../../store/redux-slices/dashboard'

const useRevenueDetails = ({
  selectedClient,
  agent,
  affiliate
}) => {
  const {
    revenueSeniorData,
    revenueAffiliateAndAgentData,
    revenueLoading
  } = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRevenueSeniorDataStart({ tenantId: selectedClient }))
  }, [selectedClient])
  useEffect(() => {
    if (selectedClient) {
      dispatch(getRevenueAffiliateAndAgentDataStart({ tenantId: selectedClient, agentId: agent, affiliateId: affiliate }))
    }
  }, [selectedClient, agent,
    affiliate])
  return {
    revenueSeniorData,
    revenueAffiliateAndAgentData,
    revenueLoading
  }
}

export default useRevenueDetails
