import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getAddonConfigDropDownStart, getAllWalletTransactionsStart, updateUserTransactionActionStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'
import { downloadFile } from '../../../../utils/fileDownloader'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const UseUserWallet = ({ isWithdrawal, isHistory, isVault, isSubPayment, isV2, isDeposit }) => {
  const initalWalletState = {
    currency: '',
    country: '',
    actionSource: isVault ? isWithdrawal ? 'VAULT-WITHDRAW' : 'VAULT-DEPOSIT' : isWithdrawal ? 'WITHDRAW' : 'DEPOSIT',
    search: '',
    actionType: isSubPayment ? 'NOT_PENDING' : isHistory ? 'NOT_PENDING' : 'PENDING',
    senior: '',
    transactionType: isVault ? isWithdrawal ? 'VAULT-WITHDRAW' : 'VAULT-DEPOSIT' : isSubPayment ? 'NON-REQUEST' : 'REQUEST',
    isRequestTimeout: false
  }
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const { countries } = useSelector((state) => state.fetch)
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { userWalletTransactions, loading, dropLoading, addOnConfigDropDown } = useSelector((state) => state.superAdminTransactions)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const [actionSource, setActionSource] = useState(null)
  const [actionType, setActionType] = useState(null)
  const [transactionId, setTransactionId] = useState(null)
  const [tenantId, setTenantId] = useState(null)
  const [showWalletRequestAction, setShowWalletRequestAction] = useState(false)
  const [walletRequestAction, setAction] = useState('')
  const totalPages = Math.ceil(userWalletTransactions?.walletTranactionDetails?.count / limit)
  const [state, setState] = useState(initialDate)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [addOnConfigData, setAddOnConfigData] = useState(null)
  const [tenantConfiguration, setTenantConfiguration] = useState(null)

  const depositAddonConfigSelectedType = []
  const depositAddonType = []

  const handleShowModal = (action, actionSource, actionType, transactionId, tenantId, TenantConfiguration, addOnSelect) => {
    setActionSource(actionSource)
    setActionType(actionType)
    setAction(action)
    setShowWalletRequestAction(true)
    setTransactionId(transactionId)
    setTenantId(tenantId)
    if (isV2 && action === 'accept') {
      setAddOnConfigData(addOnSelect)
      setTenantConfiguration(TenantConfiguration)
    }
  }

  const handleConfirmActionRequest = () => {
    dispatch(updateUserTransactionActionStart({
      actionType,
      actionSource,
      transactionId,
      tenantId,
      addOnConfigData,
      tenantConfiguration,
      handleClose: () => {
        setShowWalletRequestAction(false)
        setFormData(initalWalletState)
      }
    }))
  }

  useEffect(() => {
    dispatch(getAllWalletTransactionsStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        tenantId: selectedClient,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || '',
        agentId: agent,
        affiliateId: affiliate,
        isV2: isDeposit || isSubPayment
      }
    }))
  }, [formData, limit, page, selectedClient, state, agent, affiliate, isV2])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  const handleGetAddonConfig = (data) => {
    dispatch(getAddonConfigDropDownStart({
      tenantId: data?.tenantId,
      adminId: data?.adminId,
      affiliateId: data?.affiliateId,
      agentId: data?.agentId,
      createdAt: data?.createdAt,
      amount: data?.amount,
      userId: data?.userId
    }))
  }

  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/tenant/wallet-transactions?currency=${formData.currency || ''}&country=${formData.country || ''}&actionSource=${formData.actionSource || ''}&search=${formData.search || ''}&actionType=${formData.actionType || ''}&transactionId=${transactionId || ''}&pageNo=${page || ''}&tenantId=${selectedClient || ''}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}&agentId=${agent || ''}&affiliateId=${affiliate || ''}`)
  }
  return {
    state,
    setState,
    formData,
    setFormData,
    allCurrencies,
    handleShowModal,
    setShowWalletRequestAction,
    showWalletRequestAction,
    countries,
    walletRequestAction,
    userWalletTransactions,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    handleConfirmActionRequest,
    selectedClient,
    setSelectedClient,
    handleCsvDownload,
    inputRef,
    initialDate,
    loading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    handleGetAddonConfig,
    addOnConfigDropDown,
    depositAddonConfigSelectedType,
    depositAddonType,
    dropLoading
  }
}

export default UseUserWallet
