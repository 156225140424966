export const EVENT_LISTENERS = {
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  ERROR: 'error',
  BONUS_NOTIFICATION: 'BONUS_NOTIFICATION',
  INCORRECT_PASSWORD_NOTIFICATION: 'INCORRECT_PASSWORD_NOTIFICATION',
  BONUS_NOTIFICATION_AFFILIATE: 'BONUS_NOTIFICATION_AFFILIATE',
  WALLET_REQUEST: 'WALLET_REQUEST'
}

export const SOCKET_ROOM_NAME = {
  BONUS_NOTIFICATION: 'bonus_notification',
  INCORRECT_PASSWORD_NOTIFICATION: 'incorrect-password-namespace',
  BONUS_NOTIFICATION_AFFILIATE: 'bonus_notification_affiliate',
  WALLET_REQUEST: 'wallet-request'
}
