import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
const initalWalletState = {
  currency: '',
  country: '',
  actionSource: '',
  search: '',
  actionType: ''
}
const useTransactionBList = ({ isUserDetail, allTransaction = false }) => {
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { countries } = useSelector(state => state.fetch)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  // const totalPages = Math.ceil(transactions?.count / limit)

  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
  }, [])

  return {
    formData,
    setFormData,
    allCurrencies,
    countries,
    page,
    limit,
    setPage,
    setLimit,
    initalWalletState
    // debounceFn,
  }
}

export default useTransactionBList
