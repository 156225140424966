import React from 'react'
import useAgentList from './hooks/useAgentList'
import { Button, Form, Table } from 'react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import UpdateLimitModal from './updateLimitModal'
import { faClose, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Trigger from '../../../components/OverlayTrigger'
import { toast } from 'react-toastify'
import RemoveModal from '../../Modals/RemoveModal'
import { entityType } from './hooks/useAdminTabContent'
import BonusDetailModal from './BonusDetailModal'

const AgentsList = ({ isAffiliate, isTenant }) => {
  const {
    bonusAgent,
    bonusAgentLoading,
    t,
    limit,
    setLimit,
    page,
    setPage,
    debounceFn,
    searchRef,
    isChecked,
    handleCheck,
    bonusDetail,
    isLimitUpdateModalOpen,
    setLimitIsUpdateModalOpen,
    setSelectedData,
    handleUpdateLimit,
    selectedData,
    updateLoading,
    isRemoveModalOpen,
    setIsRemoveModalOpen,
    setRemovedData,
    handleYesRemove,
    entityBonusDetails,
    isEntityDetailModalOpen,
    setIsEntityDetailModalOpen,
    getEntityBonusDetails,
    handleResetEntityDetails
  } = useAgentList({ isAffiliate, isTenant })
  return (
    <>
      <div>
        <div className='mb-2 d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center '>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>
            <Form.Control
              ref={searchRef}
              type='search'
              placeholder={t('search')}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <Button
            onClick={() => {
              if (selectedData?.length <= 1) {
                toast.info(t('selectMoreThanOne'))
                return
              }
              setLimitIsUpdateModalOpen(true)
              handleResetEntityDetails()
            }} variant='outline-success'
          >
            {t('bulkUpdate')}
          </Button>
        </div>

        <div>
          <Table
            bordered
            striped
            responsive
            hover
            size='sm'
            className='text-center'
          >
            <thead className='thead-dark'>
              <tr>
                {['sr.No', 'username', 'name', 'bonusConfigured', 'action']?.map((h, idx) => (
                  <th key={`T-table_heading ${idx}`}>{t(h)}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {bonusAgent && !bonusAgentLoading &&
            bonusAgent?.rows?.map(
              (item, index) => {
                return (
                  <tr key={`bonus-list ${index}`}>
                    <td>
                      <div className='d-flex gap-1 align-items-center justify-content-center'>
                        <label className='me-2 d-inline-flex' htmlFor={item?.agentId}>
                          <input
                            onChange={(e) => handleCheck(e, item?.agentId)}
                            style={{ width: 20, height: 20 }}
                            checked={!!isChecked(item?.agentId)}
                            value={item?.agentId}
                            type='checkbox'
                            id={item?.agentId}
                          />
                        </label>
                        {(index + 1) + (limit * (page - 1))}
                      </div>
                    </td>
                    <td>{item?.username}</td>
                    <td>{item?.legalName}</td>
                    <td className={item?.hasBonus ? 'table-success' : 'table-danger'}>{item?.hasBonus ? t('true') : t('false')}</td>
                    <td>
                      <Trigger message={t('edit')}>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() => {
                            setLimitIsUpdateModalOpen(true)
                            setSelectedData([item?.agentId])
                            item?.hasBonus && getEntityBonusDetails({
                              entityId: item?.agentId,
                              entityType: isAffiliate ? entityType.AFFILIATE_AGENT : 'agent',
                              bonusId: bonusDetail?.bonusId
                            })
                          }}

                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>
                      {item?.hasBonus && (
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() => {
                              getEntityBonusDetails({
                                entityId: item?.agentId,
                                entityType: isAffiliate ? entityType.AFFILIATE_AGENT : 'agent',
                                bonusId: bonusDetail?.bonusId
                              })
                              setIsEntityDetailModalOpen(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      )}
                      {item?.hasBonus && (
                        <Trigger message={t('remove')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() => {
                              setIsRemoveModalOpen(true)
                              setRemovedData({
                                entityId: item?.agentId,
                                entityType: isAffiliate ? entityType.AFFILIATE_AGENT : 'agent',
                                bonusId: bonusDetail?.bonusId

                              })
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </Trigger>
                      )}
                    </td>

                  </tr>
                )
              }
            )}

              {bonusAgent?.count === 0 && !bonusAgentLoading && (
                <tr>
                  <td colSpan={8} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {bonusAgent?.count > 0 && !bonusAgentLoading && (
          <PaginationComponent
            page={bonusAgent?.count < page ? setPage(1) : page}
            totalPages={Math.ceil(bonusAgent?.count / limit)}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={bonusAgent?.count}
          />
        )}

      </div>
      <UpdateLimitModal
        handleClose={() => {
          setLimitIsUpdateModalOpen(false)
          setPage(1)
          handleResetEntityDetails()
        }}
        handleUpdateYes={handleUpdateLimit}
        show={isLimitUpdateModalOpen}
        bonusDetail={entityBonusDetails || bonusDetail}
        loading={updateLoading}
      />
      <BonusDetailModal
        show={isEntityDetailModalOpen}
        handleClose={() => {
          setIsEntityDetailModalOpen(false)
          handleResetEntityDetails()
        }}
      />
      <RemoveModal
        show={isRemoveModalOpen}
        loading={updateLoading}
        handleClose={() => {
          setIsRemoveModalOpen(false)
        }}
        handleUpdateYes={handleYesRemove}
      />
    </>
  )
}

export default AgentsList
