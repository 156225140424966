import * as Yup from 'yup'
import { t } from 'i18next'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
const imageDimensionCheck = Yup.addMethod(Yup.mixed, 'imageDimensionCheck', function (message, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight) {
  return this.test('image-width-height-check', message, async function (value) {
    const { path, createError } = this

    if (!value) {
      return
    }

    const imgDimensions = await imageWidthAndHeight(value)

    if (imgDimensions.width < minRequiredWidth || imgDimensions.width > maxRequiredWidth) {
      return createError({
        path,
        message: `${t('imageWidthBetween')}` + ' ' + `${minRequiredWidth}px - ${maxRequiredWidth}px!`
      })
    }

    if (imgDimensions.height < minRequiredHeight || imgDimensions.height > maxRequiredHeight) {
      return createError({
        path,
        message: `${t('imageHeightBetween')}` + ' ' + `${minRequiredHeight}px - ${maxRequiredHeight}px!`
      })
    }

    return true
  })
})
export const creatRollingCompetetionSchema = (t) => {
  return Yup.object().shape({
    tenantId: Yup
      .number()
      .required(t('ownerIdRequired')),
    eventName: Yup
      .string()
      .matches(/^[\p{L} ]+$/u, t('legalName'))
      .matches(/^\p{L}+( \p{L}+)*$/u, t('legalNameOneSpace'))
      .required(t('nameRequired')),
    eventDescriptions: Yup
      .string().max(250, t('max50CharactersRequired'))
      .required(t('required')),
    validFrom: Yup
      .string()
      .required(t('required')),
    validTo: Yup
      .string()
      .required(t('required')).test('is-greater', t('endDateMustBeAfterStartDate'),
        function (value) {
          const { startDate } = this.parent
          return moment(value).isAfter(moment(startDate))
        }),
    file: Yup.mixed()
      .required(t('fileRequired'))
      .test('fileFormat', t('imageFormatRequired'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(value.type)
      })
      .test('fileSize', t('sizeTooLarge'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return value.size <= 10485760 // 10MB limit
      })
      .imageDimensionCheck('Banner Required', 1100, 300, 1300, 400)

  })
}

const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null }
  if (typeof provideFile === 'string') {
    return {
      width: 1200,
      height: 350
    }
  }

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.readAsDataURL(provideFile)
    reader.onload = function () {
      const img = new Image()
      img.src = reader.result

      img.onload = function () {
        imgDimensions.width = img.width
        imgDimensions.height = img.height

        resolve(imgDimensions)
      }
    }
  })
}
