import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteSeasonStart, updateSeasonStatusStart } from '../../../../store/redux-slices/superAdminSettings'
import { getAllSeasonsStart } from '../../../../store/redux-slices/tenants'
import { debounce } from 'lodash'

export const levelTableHeaders = ['sNo', 'season', 'template', 'banner', 'startDate', 'endDate', 'status', 'action']
const useLevelSeason = () => {
  const dispatch = useDispatch()
  const { tenantSeasons: seasonList, loading } = useSelector(state => state.tenants)
  const { tenantDetails } = useSelector(state => state.login)
  const searchRef = useRef()
  const dateStartRef = useRef()
  const dateEndRef = useRef()
  const [isCreateSeasonModalOpen, setIsCreateSeasonModalOpen] = useState(false)
  const [seasonData, setSeasonData] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [isActive, setIsActive] = useState('')
  const [refetch, setRefetch] = useState(false)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [seasonId, setSeasonId] = useState(null)
  const [statusData, setStatusData] = useState(null)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [search, setSearch] = useState('')
  const getSeasonList = () => {
    dispatch(getAllSeasonsStart({ tenantId: tenantDetails?.tenantId, limit, pageNo, searchParam: search, isActive: isActive, startDate: startDate ? new Date(startDate).toISOString() : '', endDate: endDate ? new Date(endDate).toISOString() : '' }))
  }
  const handleDeleteSeason = () => {
    dispatch(deleteSeasonStart({
      formData: { seasonId },
      handleClose: () => {
        setRefetch(prev => !prev)
        setIsDeleteModalOpen(false)
      }
    }))
  }
  const handleUpdateStatus = () => {
    dispatch(updateSeasonStatusStart({
      formData: statusData,
      handleClose: () => {
        setRefetch(prev => !prev)
        setIsStatusModalOpen(false)
      }
    }))
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  useEffect(() => {
    getSeasonList()
  }, [refetch, selectedClient, isActive, limit, pageNo, search, startDate, endDate])
  return {
    seasonList,
    loading,
    t,
    selectedClient,
    setSelectedClient,
    navigate,
    isCreateSeasonModalOpen,
    setIsCreateSeasonModalOpen,
    isActive,
    setIsActive,
    setRefetch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    seasonData,
    setSeasonData,
    handleDeleteSeason,
    setSeasonId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setStatusData,
    isStatusModalOpen,
    setIsStatusModalOpen,
    handleUpdateStatus,
    debounceFn,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setSearch,
    searchRef,
    dateStartRef,
    dateEndRef
  }
}

export default useLevelSeason
