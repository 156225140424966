export const tableHeaders = [
  { label: 'S.No', value: '' },
  { label: 'User Name', value: 'usename' },
  { label: 'Transaction Id', value: 't' },
  { label: 'Name', value: 'name' },
  { label: 'Request Status', value: 'status' },
  { label: 'IP', value: 'ip' },
  { label: 'Country', value: 'country' },
  { label: 'Date', value: 'date' },
  { label: 'Amount', value: 'amount' },
  { label: 'Currency', value: 'currency' },
  { label: 'Transaction Type', value: 'actionSource' }
]

export const allTransactionTableHeaders = [
  { label: 'ID', value: 'transactionBankingId' },
  { label: 'Transaction ID', value: 'paymentTransactionId' },
  { label: 'Transaction Type', value: 'transactionType' },
  { label: 'Amount', value: 'amount' },
  { label: 'Status', value: 'status' },
  { label: 'Amount Type', value: 'amountType' },
  { label: 'Before Money', value: 'beforeBalance' },
  { label: 'After Money', value: 'afterBalance' },
  { label: 'Updated At', value: 'updatedAt' }
]

export const transactionType = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Add Money', value: 'addMoney' },
  { label: 'Remove Money', value: 'removeMoney' },
  { label: 'Bonus', value: 'bonus' },
  { label: 'Bonus To Cash', value: 'bonusToCash' },
  { label: 'Bonus Forfiet', value: 'bonusForfeit' },
  { label: 'Bonus Expired', value: 'bonusExpired' },
  { label: 'Bonus Zeroed Out', value: 'bonusZeroedOut' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Internal Bonus', value: 'bonusInternal' },
  { label: 'Internal Bonus To Cash', value: 'bonusToCashInternal' },
  { label: 'Internal Bonus Forfiet', value: 'bonusForfeitInternal' },
  { label: 'Internal Bonus Expired', value: 'bonusExpiredInternal' },
  { label: 'Internal Bonus Zeroed Out', value: 'bonusZeroedOutInternal' }
]

export const allTransactionType = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Add Money', value: 'addMoney' },
  { label: 'Remove Money', value: 'removeMoney' },
  { label: 'Bonus', value: 'bonus' },
  { label: 'Bonus To Cash', value: 'bonusToCash' },
  { label: 'Bonus Forfiet', value: 'bonusForfeit' },
  { label: 'Bonus Expired', value: 'bonusExpired' },
  { label: 'Bonus Zeroed Out', value: 'bonusZeroedOut' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Reversed', value: 'reversal' },
  { label: 'Refunded', value: 'refund' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'RollBack', value: 'rollback' },
  { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  { label: 'Free Spin', value: 'freespins' }
]

export const statusType = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 0 },
  { label: 'Complete', value: 1 },
  { label: 'Cancelled', value: 2 },
  { label: 'Failed', value: 3 },
  { label: 'Requested', value: 7 },
  { label: 'Refunded', value: 8 },
  { label: 'Chargeback', value: 9 }
]

export const walletType = ['Cash', 'Bonus']

const transactionStatusPending = [
  // { label: 'Chargeback', value: 9 },
  { label: 'Cancel', value: 2 },
  { label: 'Complete', value: 1 }
]

const transactionStatusCompleted = [
  { label: 'Chargeback', value: 9 },
  { label: 'Cancel', value: 2 },
  { label: 'Refund', value: 8 }
]

const transactionStatusCancle = [
  { label: 'Cancel', value: 2 }
]

const transactionStatusCancelled = [
  { label: 'Complete', value: 1 }
]

const transactionStatusChargeBack = [
  { label: 'Complete', value: 1 },
  { label: 'Cancel', value: 2 },
  { label: 'Refund', value: 8 }
]

const transactionStatusRefund = [
  { label: 'Complete', value: 1 },
  { label: 'Cancel', value: 2 },
  { label: 'Chargeback', value: 9 }
]

export const transStatus = {
  0: transactionStatusPending,
  1: { deposit: transactionStatusCompleted, withdraw: transactionStatusCancle },
  2: transactionStatusCancelled,
  3: transactionStatusCancelled,
  7: { withdraw: transactionStatusPending },
  8: { deposit: transactionStatusRefund },
  9: { deposit: transactionStatusChargeBack }
}
export const requestActions = [
  { label: 'Approve', value: 'ACCEPT' },
  { label: 'Reject', value: 'REJECT' },
  { label: 'Pending', value: 'PENDING' }
]
export const actionSources = [
  { label: 'WITHDRAW', value: 'WITHDRAW' },
  { label: 'DEPOSIT', value: 'DEPOSIT' }
]
