export const tableHeaders = (isV2) => [
  { label: 's.no' },
  { label: 'userInfo' },
  { label: 'accountInfo' },
  ...(isV2
    ? [
        { label: 'numberOfAcceptedRequests' },
        { label: 'addOnDepositSettings' }
      ]
    : []),
  { label: 'transaction' },
  { label: 'date' },
  { label: 'amount' },
  { label: 'requestStatus' }
]
export const requestActions = [
  { label: 'all', value: 'NOT_PENDING' },
  { label: 'approve', value: 'ACCEPT' },
  { label: 'reject', value: 'REJECT' },
  { label: 'cancel', value: 'CANCEL' }
]
export const actionSources = [
  { label: 'withdraw', value: 'WITHDRAW' },
  { label: 'deposit', value: 'DEPOSIT' },
  { label: 'vaultWithdraw', value: 'VAULT-WITHDRAW' },
  { label: 'vaultDeposit', value: 'VAULT-DEPOSIT' },
  { label: 'voucherDeposit', value: 'VOUCHER-DEPOSIT' },
  { label: 'giftcardDeposit', value: 'GIFT-CARD-DEPOSIT' }
]
export const transactionType = [
  { label: 'request', value: 'REQUEST' },
  { label: 'nonRequest', value: 'NON-REQUEST' },
  // { label: 'reoccurringDepositBonus', value: 'REOCCURRING_DEPOSIT_BONUS' },
  // { label: 'hotTimeBonus', value: 'HOT_TIME_BONUS' },
  // { label: 'compensationDepositBonus', value: 'COMPENSATION_DEPOSIT_BONUS' },
  { label: 'vaultWithdraw', value: 'VAULT-WITHDRAW' },
  { label: 'vaultDeposit', value: 'VAULT-DEPOSIT' }
  // { label: 'firstDepositBonus', value: 'FIRST-DEPOSIT-BONUS-DEPOSIT' },

]
