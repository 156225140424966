import React from 'react'
import { useTranslation } from 'react-i18next'
import useViewTemplate from './hooks/useViewTemplate'
import Preloader from '../../../components/Preloader'
import { formatNumber } from '../../../utils/storageUtils'
import { Button, Table } from 'react-bootstrap'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
const ViewLevelTemplate = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { templateDetail, loading } = useViewTemplate()
  return (
    <>
      {loading && <Preloader />}
      <div className='p-3'>
        <Button variant='warning' size='sm' role='button' className='d-flex mb-3 gap-3 align-items-center' onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faLeftLong} /> {t('goBack')}
        </Button>
        <h3>{t('detailsOfTemplate')}</h3>
        <div className='mt-3 d-flex flex-column gap-3'>
          <div className='d-flex gap-2'>
            <b style={{ minWidth: 100 }}>{t('name')}:</b>
            <span>{templateDetail?.template?.templateName}</span>
          </div>
          <div>
            <b style={{ minWidth: 100 }}>{t('ranks')}:</b>
            <Table bordered striped responsive hover size='sm' className='text-center mt-2'>
              <thead className='thead-dark'>
                <tr>
                  {['srNo', 'rank', 'thresholdAmount', 'benefit', 'benefitsType'].map((h, idx) => (
                    <th key={idx}>
                      {t(h)}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {templateDetail?.rankDetails?.map((rank, index) => {
                  return (
                    <tr key={index}>
                      <td className='py-3'>{index + 1}</td>
                      <td className='py-3'>{rank?.rank?.rank}</td>
                      <td className='py-3'>{formatNumber(rank?.thresholdAmount)}</td>
                      <td className='py-3'>{rank?.benefit
                        ? (
                          <>
                            {formatNumber(rank?.benefit)}
                            {rank?.benefitType === 'BONUS' ? '%' : ` ${templateDetail?.template?.currencyCode}`}

                          </>
                          )
                        : '-'}
                      </td>
                      <td className='py-3'>{rank?.benefitType ? t(rank?.benefitType) : '-'}</td>
                    </tr>
                  )
                })}

              </tbody>
            </Table>

          </div>

        </div>
      </div>
    </>
  )
}

export default ViewLevelTemplate
