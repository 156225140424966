import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getLanguagesStart } from '../../../../store/redux-slices/languages'
import { editTenantStart } from '../../../../store/redux-slices/tenants'
import { getAllCasinoProvidersStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { useTranslation } from 'react-i18next'

const useTenant = ({ createTenantData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const { allCurrencies, loading } = useSelector((state) => state.currencies)
  const { allTenantList, tenantDetails } = useSelector((state) => state.tenants)
  const [currencies, setCurrencies] = useState([{
    label: '₩',
    value: 'KRW'
  }])
  const [allowedLanguages, setAllowedLanguages] = useState([
    {
      label: 'English',
      value: 'EN'
    }
  ])
  const [allowedProviders, setAllowedProviders] = useState()
  const { languages } = useSelector(state => state.languages)
  const { casinoProvidersData } = useSelector((state) => state.superAdminCasino)

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '', search: '' }))
  }, [])

  useEffect(() => {
    !languages && dispatch(getLanguagesStart({ name: '', limit: '', pageNo: '' }))
  }, [])

  const currData =
  tenantId &&
  createTenantData?.allowedCurrencies?.map((currency) => {
    return (
      allCurrencies && allCurrencies?.find((c) => c.code === currency)
    )
  })

  const language =
  tenantId && createTenantData?.allowedLanguages.map((lang) => {
    return languages && languages?.find((l) => l.code.toUpperCase() === lang)
  })

  const providers =
    tenantId && createTenantData?.providerIds?.map((p) => {
      return casinoProvidersData && casinoProvidersData?.rows?.find((provider) => provider?.masterCasinoProviderId === p)
    })

  useEffect(() => {
    if (allCurrencies &&
      tenantId) {
      resetCurrencies()
    }
  }, [tenantId, allCurrencies, createTenantData?.allowedCurrencies])

  const resetCurrencies = () => {
    const tenantCurrencies = []
    currData.forEach(function (element) {
      tenantCurrencies.push({ label: element?.name, value: element?.code })
    })
    setCurrencies(tenantCurrencies)
  }

  useEffect(() => {
    if (languages && tenantId) {
      resetLanguages()
    }
  }, [tenantId, languages, createTenantData?.allowedLanguages])

  const resetLanguages = () => {
    const langOpt = []
    language.forEach(function (lang) {
      langOpt.push({ label: lang?.languageName, value: lang?.code.toUpperCase() })
    })
    setAllowedLanguages(langOpt)
  }
  useEffect(() => {
    if (providers && tenantId) {
      resetProviders()
    }
  }, [tenantId, createTenantData?.tenantConfig?.gameProviders])

  const resetProviders = () => {
    const casinoProviders = []
    const gameProviders = tenantDetails?.tenantConfig?.gameProviders ?? []

    gameProviders.forEach(function (provider) {
      casinoProviders.push({ label: provider?.name, value: provider?.masterCasinoProviderId })
    })

    setAllowedProviders(casinoProviders)
  }

  const currOptions = []
  allCurrencies &&
    allCurrencies.forEach(function (element) {
      currOptions.push({ label: element.name, value: element.code })
    })

  const langOptions = []
  languages &&
  languages.forEach(function (language) {
    langOptions.push({ label: language.languageName, value: language.code.toUpperCase() })
  })
  const providerOptions = []
  casinoProvidersData &&
    casinoProvidersData.rows.forEach(function (provider) {
      providerOptions.push({ label: provider.name, value: provider.masterCasinoProviderId })
    })

  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
  }, [])

  if (!createTenantData.primaryCurrency) {
    createTenantData.primaryCurrency = createTenantData?.allowedCurrencies[0]
  }

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => {
    dispatch(
      editTenantStart({
        createTenantData: {
          ...createTenantData,
          applyToBonus: !!createTenantData?.depositMethods?.filter(item => item?.applyToBonus)?.length
        },
        navigate,
        val,
        tenantId
      })
    )
  }

  return {
    tenantId,
    currencies,
    setCurrencies,
    currOptions,
    allCurrencies,
    navigate,
    loading,
    tenantDetails,
    languages,
    setAllowedLanguages,
    allowedLanguages,
    langOptions,
    allowedProviders,
    providerOptions,
    setAllowedProviders,
    editTenant,
    resetCurrencies,
    resetLanguages,
    resetProviders,
    allTenantList,
    t
  }
}

export default useTenant
