import {
// faCheck,
// faCopy,
// faEdit,
// faTimes
} from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  // Tabs,
  // Tab,
  Row,
  Col
  // Button,
  // ButtonGroup,
  // Badge,
  // Form
} from '@themesberg/react-bootstrap'
import React from 'react'
// import Trigger from '../../../../components/OverlayTrigger'
import Preloader from '../../../../components/Preloader'
// import { convertAmountOptions } from '../components/CreateBonus/constants'
import useBonusDetail from '../components/hooks/useBonusDetail'
import GeneralDetails from '../../../Super-Admin/Bonus/BonusDetail/generalDetail'
// import WageringTemplateDetails from '../../../../components/WageringTemplateDetails'
// import Games from '../../../Super-Admin/Bonus/components/CreateBonus/Games'
// import useCheckPermission from '../../../../utils/checkPermission'
// import LoyaltyPoint from '../../../../components/LoyaltyPoints/LoyaltyPoint'
// import LanguageDetails from '../../../Super-Admin/Bonus/BonusDetail/LanguageDetails'
// import BonusCountry from '../../../Super-Admin/Bonus/components/CreateBonus/BonusCountry'

const BonusDetail = () => {
  const {
    // selectedTab,
    // setSelectedTab,
    bonusDetail,
    loading,
    // bonusId,
    // navigate,
    // paymentProviders,
    // wageringTemplateDetail,
    // pageNo,
    // setPageNo,
    // limit,
    // setLimit,
    // totalPages,
    // selectedProvider,
    // setSelectedProvider,
    // search,
    // setSearch,
    // gameIds,
    // setGameIds,
    // casinoGamesData,
    // gamesLimit,
    // gamesPageNo,
    // setGamesLimit,
    // setGamesPageNo,
    // wagerSearch,
    // setWagerSearch,
    selectedLang
    // setSelectedLang,
    // languages,
    // countryData,
    // setCountryData
  } = useBonusDetail()
  // const { isHidden } = useCheckPermission()

  return (
    <>
      {loading && !bonusDetail
        ? (
          <Preloader />
          )
        : (
          <>
            <Row>
              <Col className='d-flex'>
                <Col>
                  {' '}
                  <h3>Bonus Details</h3>
                </Col>
              </Col>
            </Row>
            <div className='mt-2'>
              <GeneralDetails bonusDetail={bonusDetail} isTenant selectedLang={selectedLang} />
            </div>
          </>
          )}
    </>
  )
}
export default BonusDetail
