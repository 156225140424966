import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useAllProviderList from './useAllProviderList'

const ProvidersFilter = ({
  setSelectedProvider, selectedProvider, isTenantRoute = false, t, isAffiliate,
  isAgent
}) => {
  const { allProviders } = useAllProviderList({
    isTenantRoute,
    isAffiliate,
    isAgent
  })

  return (
    <>
      <Form.Label
        style={{
          width: 'auto',
          marginBottom: '0',
          marginRight: '15px',
          marginTop: '5px',
          fontSize: '13px'
        }}
      >
        {t('provider')}
      </Form.Label>

      <Form.Select
        onChange={(e) => {
          setSelectedProvider(e.target.value)
        }}
        value={selectedProvider}
        style={{
          maxWidth: '180px',
          marginRight: '10px',
          height: 'auto',
          fontSize: '13px'
        }}
        size='sm'
      >
        <option value=''>
          {t('all')}
        </option>

        {allProviders?.count === 0 && (
          <option value='' disabled>
            {t('noProvidersAvailable')}
          </option>
        )}

        {allProviders?.rows?.map(
          ({ masterCasinoProviderId, name }) => (
            <option key={masterCasinoProviderId} value={masterCasinoProviderId}>
              {name}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}

export default ProvidersFilter
