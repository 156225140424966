import { Button, Form as BForm, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import useCreateTemplate from './useCreateTemplate'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { creatTemplateSchema } from '../schema'
import { getCurrentLang, returnLangName } from '../../../../utils/storageUtils'
const CreateAndEditTemplates = ({
  setRefetch,
  templateData,
  ranks,
  handleClose,
  show,
  setIsCreateTemplateModalOpen,
  type = 'addTemplate'
}) => {
  const {
    clientsFilterData,
    t,
    languages,
    handleCreateTemplate,
    createLoading,
    handleUpdateTemplate,
    getTenantCurrencies,
    tenantCurrencies
  } = useCreateTemplate({ templateData, setIsCreateTemplateModalOpen })
  const { templateDetail } = useSelector(state => state.superAdminSettings)

  const formattedRanksForEdit = (templateDetail?.rankDetails || []).map((rank) => {
    return {
      templateConfigId: rank?.templateConfigId,
      isChecked: true,
      rankId: rank?.rankId,
      name: rank?.rank?.rank || '',
      amount: rank?.thresholdAmount,
      benefit: rank?.benefit || '',
      benefitType: rank?.benefitType || 'BONUS',
      rollingPercentage: rank?.rollingPercentage || ''
    }
  })

  const formattedRanksToMake = (ranks || []).map((rank) => {
    return {
      isChecked: false,
      rankId: rank?.rankId,
      name: rank?.name || '',
      amount: rank?.ranksInfo?.thresholdAmount || 0,
      benefit: '',
      benefitType: 'BONUS',
      rollingPercentage: ''
    }
  })

  const formattedRanksForEditIds = new Set(formattedRanksForEdit.map(rank => rank.rankId))
  const filteredFormattedRanksToMake = formattedRanksToMake.filter(rank => !formattedRanksForEditIds.has(rank.rankId))
  const combinedRanks = [...formattedRanksForEdit, ...filteredFormattedRanksToMake].sort((a, b) => a.rankId - b.rankId)
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          {
            templateName: templateData ? returnLangName(templateData?.templateName) : '',
            lang: getCurrentLang(),
            currencyCode: templateData ? templateData?.currencyCode : '',
            tenantId: templateData ? templateData.tenantId : '',
            ranks: templateData ? combinedRanks : ranks || []
          }
        }
        validationSchema={creatTemplateSchema(t, (type === 'editTemplate' && templateData.tenantId === null))}
        onSubmit={(formValues, formActions) => {
          if (templateData) {
            handleUpdateTemplate(formValues, handleClose, setRefetch)
          } else {
            handleCreateTemplate(formValues, handleClose, setRefetch)
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset
        }) => {
          return (
            <Form>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <h4 className='d-flex align-items-center'>
                    {t(type)}
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  <div className='d-flex flex-column gap-2'>
                    {
                    templateData?.tenantId !== null
                      ? (
                        <div>
                          <BForm.Label>{t('owner')}
                            <span className='text-danger'> *</span>
                          </BForm.Label>

                          <div className='d-flex flex-column gap-1'>
                            <BForm.Select
                              type='select'
                              name='tenantId'
                              value={values?.tenantId}
                              disabled={!!templateData}
                              onChange={(e) => {
                                handleChange(e)
                                getTenantCurrencies(e.target.value)
                              }}
                              onBlur={handleBlur}
                            >
                              <option key='' value=''>{t('selectOwner')}</option>
                              {clientsFilterData?.rows?.map(
                                ({ adminUserId, firstName, lastName, Tenants }) => (
                                  <option key={adminUserId} value={Number(Tenants[0]?.tenantId)}>
                                    {`${firstName} ${lastName}`}
                                  </option>
                                )
                              )}
                            </BForm.Select>

                            <ErrorMessage
                              component='div'
                              name='tenantId'
                              className='text-danger'
                            />
                          </div>
                        </div>
                        )
                      : (<></>)
                   }
                    <div>
                      <BForm.Label>{t('selectLanguage')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          name='lang'
                          value={values.lang}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {languages && languages?.filter(item => item.isActive).map(({
                            languageName,
                            code
                          }, index) => {
                            return (
                              <option
                                key={index}
                                value={code.toLowerCase()}
                              >
                                {t(String(languageName).toLowerCase())}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='lang'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>{t('selectCurrency')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          name='currencyCode'
                          value={values.currencyCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!!templateData}
                        >
                          <option value=''>{t('selectCurrency')}</option>
                          {tenantCurrencies?.currencies?.map((item, index) => {
                            return (
                              <option
                                key={index}
                                value={item}
                              >
                                {t(item)}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='currencyCode'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('nameOfTemplate')}<span className='text-danger'> *</span>
                      </BForm.Label>

                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          type='text'
                          name='templateName'
                          placeholder={t('enterName')}
                          value={values.templateName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          name='templateName'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('ranks')}
                      </BForm.Label>
                      <FieldArray name='ranks'>
                        {() => (
                          <div className='d-flex flex-column gap-1'>
                            {values?.ranks?.map((rank, index) => {
                              return (
                                <div key={index} className='d-flex flex-column gap-2  mb-1'>
                                  <div className='d-flex align-items-center gap-2'>
                                    <BForm.Check
                                      type='checkbox'
                                      name={`ranks.${index}.isChecked`}
                                      checked={rank.isChecked}
                                      onChange={(e) => {
                                        setFieldValue(`ranks.${index}.isChecked`, Boolean(e.target.checked))
                                      }}
                                    />
                                    <span className='flex-shrink-0'>{rank?.name}</span>
                                  </div>
                                  {rank.isChecked && (
                                    <div className='d-flex flex-column gap-2'>
                                      <div className='d-flex flex-column  '>
                                        <BForm.Label style={{ width: 150 }}>
                                          {t('thresholdAmount')}
                                        </BForm.Label>
                                        <div className='d-flex flex-column gap-1 flex-grow-1'>
                                          <BForm.Control
                                            className='w-100'
                                            name={`ranks.${index}.amount`}
                                            value={rank.amount}
                                            onChange={(e) => {
                                              setFieldValue(`ranks.${index}.amount`, e.target.value === '' ? '' : Number(e.target.value))
                                              setFieldValue(`ranks.${index}.rankId`, rank?.rankId)
                                            }}
                                            type='number'
                                            placeholder={t('enterAmount')}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            className='text-danger'
                                            name={`ranks.${index}.amount`}
                                          />
                                          <div className='text-danger'>{typeof errors.ranks === 'string' && t('amountShouldBeInAccordingToRank')}</div>
                                        </div>
                                      </div>
                                      <div className='d-flex flex-column '>
                                        <div className='d-flex flex-column gap-1 flex-grow-1'>
                                          <BForm.Select
                                            className='w-100'
                                            type='select'
                                            name={`ranks.${index}.benefitType`}
                                            value={rank.benefitType}
                                            onChange={(e) => {
                                              setFieldValue(`ranks.${index}.benefitType`, e.target.value)
                                            }}
                                            onBlur={handleBlur}
                                          >
                                            <option disabled key='' value=''>{t('selectBenefitType')}</option>
                                            {['BONUS', 'CASH', 'WITHDRAWAL-LIMIT']?.map((item) => (
                                              <option key={item} value={item}>
                                                {t(item)}
                                              </option>
                                            ))}
                                          </BForm.Select>

                                          <ErrorMessage
                                            component='div'
                                            name={`ranks.${index}.benefitType`}
                                            className='text-danger'
                                          />
                                        </div>
                                      </div>
                                      <div className='d-flex gap-2 align-items-center'>
                                        {rank.benefitType === 'BONUS' &&
                                          <div className='d-flex flex-column gap-1 flex-grow-1'>
                                            <BForm.Label style={{ width: 150 }}>
                                              {t('rollingPercentage')}
                                            </BForm.Label>
                                            <BForm.Control
                                              className='w-100'
                                              name={`ranks.${index}.rollingPercentage`}
                                              value={rank.rollingPercentage}
                                              onChange={(e) => {
                                                setFieldValue(`ranks.${index}.rollingPercentage`, e.target.value === '' ? '' : Number(e.target.value))
                                              }}
                                              type='number'
                                              placeholder={t('rollingPercent')}
                                            />
                                            <div>
                                              <ErrorMessage
                                                component='div'
                                                className='text-danger'
                                                name={`ranks.${index}.rollingPercentage`}
                                              />
                                            </div>

                                          </div>}

                                        <div className='d-flex flex-column gap-1 flex-grow-1'>
                                          <BForm.Label style={{ width: 150 }}>
                                            {t('benefitAmount')}
                                          </BForm.Label>
                                          <BForm.Control
                                            className='w-100'
                                            name={`ranks.${index}.benefit`}
                                            value={rank.benefit}
                                            onChange={(e) => {
                                              setFieldValue(`ranks.${index}.benefit`, e.target.value === '' ? '' : Number(e.target.value))
                                            }}
                                            type='number'
                                            placeholder={t('enterBenefitValue')}
                                          />
                                          <div>
                                            <ErrorMessage
                                              component='div'
                                              className='text-danger'
                                              name={`ranks.${index}.benefit`}
                                            />
                                          </div>

                                        </div>
                                      </div>
                                      <div>
                                        <div style={{ fontSize: 12 }}>{t('benefitNote')}</div>
                                      </div>

                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </FieldArray>

                    </div>

                  </div>

                </Modal.Body>

                <div className='mt-4 '>
                  <Modal.Footer className='d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => {
                        handleClose()
                        handleReset()
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                    >
                      {t('submit')} {createLoading && (
                        <Spinner className='ms-2' size='sm' animation='border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateAndEditTemplates
