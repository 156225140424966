
export const tableHeaders = (isSuperAdminRole) => {
  if (isSuperAdminRole) {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'username' },
      { label: 'joinDate', value: 'regDate' },
      { label: 'agentToken', value: 'country' },
      { label: 'parent', value: 'kycStatus' },
      { label: 'parentUsername', value: 'kycStatus' },
      { label: 'domain', value: 'kycStatus' },
      { label: 'revenuePercentage', value: 'revenuePercentage' },
      { label: 'status', value: 'status' },
      { label: 'action', value: 'action' }
    ]
  } else {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'username' },
      { label: 'joinDate', value: 'regDate' },
      { label: 'agentToken', value: 'country' },
      { label: 'parent', value: 'kycStatus' },
      { label: 'parentUsername', value: 'kycStatus' },
      { label: 'domain', value: 'kycStatus' },
      { label: 'status', value: 'status' },
      { label: 'action', value: 'action' }
    ]
  }
}

export const parentRoles = (path) => {
  return [
    { label: 'all', value: '' },
    { label: 'superadmin', value: 'superadmin' },
    { label: 'admin', value: 'admin' },
    !path ? { label: 'affiliate', value: 'affiliate' } : null
  ].filter(Boolean)
}
export const agentStatus = [
  { label: 'all', value: '' },
  { label: 'active', value: true },
  { label: 'inactive', value: false }
]
