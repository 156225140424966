import { Col, Row, Form as BForm, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { toast } from '../Toast'
import HtmlTextEditor from '../../common/HtmlTextEditor/HtmlTextEditor'
import { useTranslation } from 'react-i18next'

export default ({
  setTemp,
  values,
  cmsKeys,
  setFieldValue,
  handleChange,
  handleBlur,
  selectedTab,
  navigate,
  isHidden,
  create = false,
  handleSubmit,
  deleteCms,
  details = false,
  isTenant = false,
  initValues = false
}) => {
  const { t } = useTranslation()
  const [template, setTemplate] = useState('')
  const [titleErr, setTitleErr] = useState('')
  const [err, setErr] = useState('')
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (cmsKeys?.dynamicKeys && Object.keys(cmsKeys?.dynamicKeys)?.length) {
      let tempDataAll = {}
      let tempData = {}
      const dynamicKeys = cmsKeys?.dynamicKeys
      dynamicKeys.forEach((item) => {
        tempDataAll = { ...tempDataAll, [item.key]: item.description }
        if (item.required) {
          tempData = { ...tempData, [item.key]: item.description }
        }
      })
    }
  }, [cmsKeys?.dynamicKeys])

  useEffect(() => {
    setTemp(template)
    if (template) {
      setErr('')
    }
  }, [template])

  useEffect(() => {
    setFieldValue && setFieldValue('language', selectedTab)
    setLabel(label || values?.title?.[selectedTab] || values?.title?.EN)
  }, [selectedTab])

  useEffect(() => {
    setFieldValue('title', label)
  }, [label])

  return (
    <>
      <Row className='mt-3'>
        <Col>
          <Col xs={2}>
            <BForm.Label>
              {t('title')} <span className='text-danger'>* </span>
            </BForm.Label>
          </Col>

          <Col xs={5}>
            <BForm.Control
              type='text'
              name='title'
              disabled={details}
              placeholder={t('enterTitle')}
              value={label}
              onChange={(e) => {
                setLabel(e.target.value)
                handleChange(e)
                setTitleErr('')
              }}
              onBlur={handleBlur}
            />

            {titleErr
              ? (
                <Row>
                  <span className='text-danger'>{titleErr}</span>
                </Row>)
              : (
                <ErrorMessage
                  component='div'
                  name='title'
                  className='text-danger'
                />)}

          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex mb-2 align-items-center'>
            <BForm.Label>
              {t('content')} <span className='text-danger'>*</span>
            </BForm.Label>
            <Col />
          </div>

          <HtmlTextEditor
            value={values?.content?.[selectedTab] ? values?.content?.[selectedTab] : values?.content?.EN || ''}
            setValue={setTemplate}
          />
          <ErrorMessage
            component='div'
            name='content'
            className='text-danger'
          />
        </Col>
      </Row>
      {err &&
        <Row>
          <span className='text-danger'>{err}</span>
        </Row>}
      <Row>
        <Col className='d-flex justify-content-between'>

          <Button
            variant='outline-warning'
            className='m-2'
            onClick={() => isTenant ? navigate(TenantRoutes.CMS) : navigate(SuperAdminRoutes.CMS)}
          >
            {t('cancel')}
          </Button>

          <div>

            <Button
              variant='outline-success'
              hidden={details || isHidden({ module: { key: 'CMS', value: 'U' } }) || (create && values?.content?.[selectedTab] !== undefined)}
              onClick={() => {
                if (template === '') {
                  setErr(t('contentIsRequired'))
                  toast(t('contentRequired'), 'error')
                } else {
                  setFieldValue('content', template)
                  setFieldValue('title', label)
                  handleSubmit()
                  if (initValues) {
                    (!initValues?.slug || !initValues?.title) && window.scroll(0, 0)
                    if (!initValues?.title) {
                      setTitleErr(t('titleIsRequired'))
                    } else {
                      setTitleErr('')
                    }
                  }
                  setErr('')
                }
              }}
              className='m-2'
            >
              {t('submit')}
            </Button>

            <Button
              variant='outline-danger'
              className='m-2'
              hidden={details || (selectedTab === 'EN' || values?.content?.[selectedTab] === undefined)}
              onClick={() => {
                deleteCms({ language: selectedTab, cmsPageId: values?.cmsPageId })
              }}
            >
              {t('delete')}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}
