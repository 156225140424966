import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as locales from './locales/index'
import { getItem } from '../utils/storageUtils'

i18n.use(initReactI18next).init({
  resources: {
    ...Object.entries(locales).reduce(
      (acc, [key, value]) => (
        {
          ...acc,
          [key]: {
            translation: value
          }
        }),
      {}
    )
  },
  lng: getItem('language') || 'en',
  fallbackLng: getItem('language') || 'en'
})

export default i18n
