import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faEye,
  faRedoAlt,
  faWallet
} from '@fortawesome/free-solid-svg-icons'
import { SuperAdminRoutes } from '../../../routes'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal, { NonCashWalletRequest } from '../../../components/ConfirmationModal'
import Trigger from '../../../components/OverlayTrigger'
import useTenantOwner from './hooks/useTenantOwner'
import useCheckPermission from '../../../utils/checkPermission'
import useIsGamma from '../../../common/hook/useIsGamma'
import { formatNumber } from '../../../utils/storageUtils'
export const GAMMA_EMAILS = ['demo@yopmail.com', 'leo02@yopmail.com', 'demoaffiliate@yopmail.com', 'demoagent@yopmail.com']

export default () => {
  const {
    totalPages,
    limit,
    page,
    clientsFilterData,
    navigate,
    setLimit,
    setPage,
    setSearch,
    search,
    handleShow,
    show,
    setShow,
    handleYes,
    active,
    loading,
    name,
    status,
    setStatus,
    t,
    walletModal,
    setWalletAction,
    setTenantData,
    tenantData
  } = useTenantOwner()
  const { isHidden } = useCheckPermission()

  const {
    isGamma
  } = useIsGamma()
  const tableHeaders = ['sNo', 'name', 'email', 'phone', 'withdrawalLimit', 'promotionCash', 'status', 'action']

  return (
    <div className='px-3'>
      <Row className='p-3'>
        <Col xs='auto' className='p-2'>
          <h3>{t('tenantSenior')}</h3>
        </Col>
        <Col
          className='d-flex
          justify-content-end
          align-items-center'
        >
          {
          !isGamma &&
            <Button
              variant='outline-success'
              size='sm'
              className='px-4'
              onClick={() => navigate(SuperAdminRoutes.CreateTenantAdmin)}
              hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
            >
              {t('create')}
            </Button>
         }
        </Col>
      </Row>
      <Row>
        <Col className='d-flex'>
          <span
            style={{
              width: 'auto',
              cursor: 'pointer',
              marginRight: '10px'
            }}
            className='d-inline-block mt-1 mx-3'
          >
            {t('status')}
          </span>

          <Form.Select
            onChange={(e) => {
              setStatus(e.target.value)
              setPage(1)
            }}
            value={status}
            size='sm'
            style={{
              maxWidth: '230px',
              width: 'auto',
              marginRight: '10px'
            }}
          >
            <option value=''>{t('all')}</option>
            <option value='true'>{t('active')}</option>
            <option value='false'>{t('inActive')}</option>
          </Form.Select>
          <span
            style={{
              width: 'auto',
              cursor: 'pointer',
              marginRight: '10px'
            }}
            className='d-inline-block mt-1 mx-3'
          >
            {t('search')}
          </span>
          <Form.Control
            type='search'
            placeholder={t('searchByNameOrEmail')}
            value={search}
            size='sm'
            style={{ maxWidth: '230px', marginRight: '10px', padding: '5px' }}
            onChange={(e) => {
              setSearch(e.target.value.replace(/[^\w\s@.+.-]/gi, ''))
              setPage(1)
            }}
          />
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setStatus('')
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{t(header)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(clientsFilterData) &&
              clientsFilterData?.rows?.map(
                ({
                  adminUserId,
                  firstName,
                  lastName,
                  email,
                  Tenants,
                  phone,
                  isActive,
                  adminRoleId,
                  promotionCash,
                  promotionCashCurrency
                }, index) => {
                  return (
                    <tr key={adminUserId}>
                      <td>{(index + 1) + (limit * (page - 1))}</td>
                      <td>
                        {firstName + ' ' + lastName}
                      </td>

                      <td>
                        <Trigger message={email}>
                          <span
                            style={{
                              width: '200px',
                              cursor: 'pointer'
                            }}
                            className='text-link d-inline-block text-truncate'
                            onClick={() =>
                              navigate(
                                  `/super-admin/tenants/owners/details/${adminUserId}`
                              )}
                          >
                            {email}
                          </span>
                        </Trigger>
                      </td>

                      <td>{phone || 'NA'}</td>
                      <td>{
                        Tenants?.length
                          ? formatNumber(Tenants[0]?.tenantConfig?.withdrawlLimit) + ' ₩'
                          : '-'
                      }
                      </td>
                      <td>
                        {
                          promotionCash ? formatNumber(promotionCash) + ' ₩' : '-'
                        }
                      </td>
                      <td className={isActive ? 'table-success' : 'table-danger'}>
                        {isActive ? t('active') : t('inActive') || 'NA'}
                      </td>
                      <td>
                        <ButtonGroup>
                          {
                            !isGamma &&
                            (
                              <Trigger message={t('edit')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='warning'
                                  onClick={() =>
                                    navigate(
                                  `/super-admin/tenants/owners/edit/${adminUserId}`
                                    )}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </Trigger>
                            )
                          }

                          <Trigger message={t('viewTenants')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() =>
                                navigate(`/super-admin/tenants/${adminUserId}`)}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>
                          {!isActive
                            ? (
                              <Trigger message={t('setStatusActive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>
                              )
                            : (
                              <Trigger message={t('setStatusInactive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                              )}
                          {
                          Tenants?.length !== 0 || isActive
                            ? (
                              <Trigger message={t('nonCashRequest')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='info'
                                  onClick={() => {
                                    setWalletAction(prev => !prev)
                                    setTenantData({
                                      seniorId: adminUserId,
                                      tenantId: Tenants[0]?.tenantId,
                                      promotionCash: promotionCash,
                                      promotionCashCurrency: promotionCashCurrency
                                    })
                                  }}
                                >
                                  <FontAwesomeIcon icon={faWallet} />
                                </Button>
                              </Trigger>
                              )
                            : (<></>)
                         }
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
              )}

          {clientsFilterData?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {clientsFilterData?.count !== 0 && !loading && (
        <PaginationComponent
          page={clientsFilterData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={clientsFilterData?.count}
        />
      )}
      {
        walletModal
          ? <NonCashWalletRequest
              setShow={setWalletAction}
              show={walletModal}
              tenantData={tenantData}
            />
          : <></>
      }
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}
    </div>
  )
}
