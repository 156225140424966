import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getCmsDetailsStart } from '../../../../store/redux-slices/tenantsFetchData'

const useCmsDetails = () => {
  const { loading, cmsDetails } = useSelector((state) => state.tenantsFetch)
  const { cmsId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCmsDetailsStart({ cmsId }))
  }, [])

  return {
    loading,
    cmsDetails,
    cmsId,
    navigate
  }
}

export default useCmsDetails
