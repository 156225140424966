import AsyncSelect from 'react-select/async'
import './CustomAsyncSelect.scss'
const CustomAsyncSelect = (props) => {
  return (
    <AsyncSelect
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: props?.height || '33px',
          minHeight: 'unset',
          fontSize: '14px',
          borderRadius: '10px',
          border: '0.0625rem solid #d3d3d3'
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          top: '-3px',
          position: 'relative'
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          top: '-3px',
          position: 'relative'
        })
      }}
      className='custom-async-select'
      {...props}

    />
  )
}

export default CustomAsyncSelect
