import {
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getSportsListingStart, getLeaguesListingStart, getMarketOutcomeStart, addHotComboStart, getMatchMarketStart, getLeaguesListingSuccess, getMatchListingSuccess, getMatchMarketSuccess, getMarketOutcomeSuccess, getSportsCountriesListingStart, updateHotComboStart, getSportsCountriesListingSuccess } from '../../../store/redux-slices/superAdminSportsBook'
import { getTenantCountriesStart } from '../../../store/redux-slices/fetchData'
import { toast } from '../../../components/Toast'
import { debounce } from 'lodash'
import { getMatches } from '../../../utils/apiCalls'

const useCreateHotCombo = () => {
  const [selectedMatch, setSelectedMatch] = useState('')
  const [defaultOptions, setDefaultOptions] = useState([])
  const location = useLocation()
  const [selectedMarkets, setSelectedMarkets] = useState([])
  const [comboName, setComboName] = useState(location?.state?.comboName)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sport, setSport] = useState('')
  const [country, setCountry] = useState('')
  const [tournament, setTournament] = useState('')
  const [matchId, setMatchId] = useState('')
  const [marketId, setMarketId] = useState('')
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const { sportsListing, loading, leaguesListing, matchListing, sportsCountriesListing, matchMarkets, marketOutcomes, addComboLoading, updateLoading } = useSelector(state => state.superAdminSportsBook)
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const [selectedOutcomes, setSelectedOutcomes] = useState({})
  const [startDate, setStartDate] = useState('')
  const isTenant = !window.location.href.match(/super/g)
  const [countryList, setCountryList] = useState(null)
  const resetForm = () => {
    setSelectedOutcomes([])
    setMarketId('')
    setMatchId('')
    setSelectedMarkets([])
    setSport('')
    setTournament('')
    setCountry('')
    dispatch(getLeaguesListingSuccess([]))
    dispatch(getMatchListingSuccess([]))
    dispatch(getMatchMarketSuccess([]))
    dispatch(getMarketOutcomeSuccess([]))
    setDefaultOptions([])
  }
  const resetForCountry = () => {
    dispatch(getLeaguesListingSuccess([]))
    dispatch(getMatchListingSuccess([]))
    dispatch(getMatchMarketSuccess([]))
    dispatch(getMarketOutcomeSuccess([]))
    setSelectedMatch('')
    setDefaultOptions([])
  }
  const resetForSports = () => {
    dispatch(getLeaguesListingSuccess([]))
    dispatch(getMatchListingSuccess([]))
    dispatch(getMatchMarketSuccess([]))
    dispatch(getMarketOutcomeSuccess([]))
    dispatch(getSportsCountriesListingSuccess({}))
    setSelectedMatch('')
    setDefaultOptions([])
  }
  const resetForLeagues = () => {
    dispatch(getMatchListingSuccess([]))
    dispatch(getMatchMarketSuccess([]))
    dispatch(getMarketOutcomeSuccess([]))
    setSelectedMatch('')
    setDefaultOptions([])
  }
  const resetForMatch = () => {
    dispatch(getMatchMarketSuccess([]))
    dispatch(getMarketOutcomeSuccess([]))
  }
  const resetForMarket = () => {
    dispatch(getMarketOutcomeSuccess([]))
  }
  const debounceWithPromise = (func, wait) => {
    const debounced = debounce((resolve, reject, ...args) => {
      func(...args).then(resolve).catch(reject)
    }, wait)

    return (...args) => {
      return new Promise((resolve, reject) => {
        debounced(resolve, reject, ...args)
      })
    }
  }

  const loadOptions = async (inputValue) => {
    const matchRes = await getMatches({
      paramsData: {
        isTenant,
        matchName: inputValue,
        status: '',
        countryName: country,
        sportId: sport.providerSportId,
        limit: 1000,
        pageNo: 1,
        tournamentId: tournament
      }
    })

    return new Promise((resolve, reject) => {
      const newOption = matchRes?.data?.data?.[isTenant ? 'categoryMatches' : 'result']?.rows?.map((item) => {
        return ({
          ...item,
          label: `${item?.homeTeam} VS ${item?.awayTeam}`,
          value: item?.masterMatchId
        })
      })
      setDefaultOptions(newOption)
      resolve(newOption)
    })
  }

  const customAsyncDebounce = useCallback(debounceWithPromise(loadOptions, 1000), [country,
    sport,
    tournament])

  const addCombo = (formData, formActions) => {
    const outcomeIds = []

    for (const [, value] of Object.entries(selectedOutcomes)) {
      outcomeIds.push(value)
    }
    if (outcomeIds.length > 1 && outcomeIds.length < 5) {
      dispatch(addHotComboStart({
        paramsData: { masterOutcomeIds: outcomeIds, comboName: formData.name, startDate: startDate },
        onSuccess: () => {
          formActions.resetForm()
          resetForm()
          navigate(-1)
        },
        isTenant
      }))
    } else {
      toast(t('outcomeError'), 'error')
    }
  }
  const handleUpdateHotCombo = ({ hotComboId, comboName }) => {
    if (comboName === '') {
      toast(t('nameRequired'), 'error')
      return
    }
    const outcomeIds = []

    for (const [, value] of Object.entries(selectedOutcomes)) {
      outcomeIds.push(value)
    }
    if (outcomeIds.length > 1 && outcomeIds.length < 5) {
      dispatch(updateHotComboStart({
        formData: { masterOutcomeIds: outcomeIds, comboName, startDate: startDate, hotComboId },
        onSuccess: () => {
          toast(t('updateSuccess', 'success'))
          navigate(-1)
        },
        isTenant
      }))
    } else {
      toast(t('outcomeError'), 'error')
    }
  }
  useEffect(() => {
    if (isTenant) {
      setCountryList(tenantCountries?.rows)
    } else {
      setCountryList(countries)
    }
  }, [isTenant, countries, tenantCountries])

  // sports listing
  useEffect(() => {
    dispatch(getSportsListingStart({
      paramsData: {
        limit: 1000,
        pageNo: 1
      },
      isTenant
    }))
  }, [])
  // countries
  useEffect(() => {
    if (!isTenant) {
      dispatch(getSportsCountriesListingStart({
        paramsData: {
          countryName: '',
          status: '',
          sportId: sport.masterSportId,
          limit: 1000,
          pageNo: '',
          isTenant
        }
      }))
      // dispatch(getCountriesStart({
      //   limit: '',
      //   name: '',
      //   pageNo: '',
      //   isActive: '',
      //   kycMethod: '',
      //   sportId: sport.masterSportId
      // }))
    } else {
      dispatch(getTenantCountriesStart())
    }
  }, [sport])

  // Leagues
  useEffect(() => {
    if (sport.providerSportId &&
      country) {
      dispatch(getLeaguesListingStart({
        paramsData: {
          tournamentName: '',
          status: '',
          sportId: sport.providerSportId,
          countryName: country,
          limit: 1000,
          pageNo: 1
        },
        isTenant
      }))
    }
  }, [country])
  // matches
  useEffect(() => {
    if (country &&
      sport.providerSportId &&
      tournament) {
      customAsyncDebounce()
    }
  }, [tournament])
  // markets
  useEffect(() => {
    if (matchId) {
      dispatch(getMatchMarketStart({
        paramsData: {
          matchId: matchId
        },
        isTenant
      }))
    }
  }, [matchId])
  useEffect(() => {
    if (matchId && marketId) {
      dispatch(getMarketOutcomeStart({ paramsData: { matchId: matchId, marketId: marketId }, isTenant }))
    }
  }, [marketId, matchId])

  return {
    defaultOptions,
    customAsyncDebounce,
    comboName,
    setComboName,
    setCountry,
    setSport,
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    loading,
    t,
    sportsListing,
    countryList,
    leaguesListing,
    matchListing,
    matchMarkets,
    marketOutcomes,
    selectedOutcomes,
    setSelectedOutcomes,
    addCombo,
    setStartDate,
    setTournament,
    setMatchId,
    setMarketId,
    resetForm,
    addComboLoading,
    selectedMarkets,
    setSelectedMarkets,
    marketId,
    resetForCountry,
    resetForSports,
    resetForLeagues,
    resetForMatch,
    resetForMarket,
    handleUpdateHotCombo,
    updateLoading,
    sportsCountriesListing,
    selectedMatch,
    setSelectedMatch
  }
}

export default useCreateHotCombo
