import * as Yup from 'yup'
import { ADMIN_ROLES } from '../../../utils/constants'
import { getItem } from '../../../utils/storageUtils'
const role = getItem('role')
const isSuperAdminRole = role === ADMIN_ROLES.SUPERADMIN
export const affiliateSchema = (t, isTenant) => {
  return Yup.object().shape({
    username: getItem('languages') === 'EN'
      ? Yup.string()
          .max(50, t('max50Characters'))
          .min(2, t('min2Characters'))
          .matches(/^[a-zA-Z]/, t('userNameFirstCharacterLetter'))
          .matches(/^[a-z][^A-Z \s]*$/, t('userNameAllowed'))
          .matches(/^[^ ]+$/, t('onlySpaceNotAllowed'))
          .required(t('userNameRequired'))
      : Yup.string()
        .max(50, t('max50Characters'))
        .min(2, t('min2Characters'))
        .required(t('userNameRequired')),

    password: Yup.string()
      .max(16, t('max16Characters'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9\s])[^\s.,]{4,16}$/, t('passwordValidationMessage'))
      .required(t('passwordRequired')),

    adminUserId: Yup.string()
      .when([], {
        is: () => !isTenant,
        then: Yup.string().required(t('seniorRequired')),
        otherwise: Yup.string().notRequired()
      }),

    email: Yup.string()
      .email(t('invalidEmail'))
      .required(t('emailRequired')),

    doj: Yup.date()
      .required(t('dateRequired'))
      .typeError(t('invalidDateFormat')),

    phoneCode: Yup.string()
      .required(t('phoneCodeRequired')),

    phone: Yup.string()
      .min(5, t('min5Numbers'))
      .max(16, t('max16Numbers'))
      .matches(/^\d{5,20}$/, t('enterValidPhone'))
      .required(t('phoneRequired')),

    country: Yup.string()
      .required(t('countryRequired')),

    legalName: getItem('langauge') === 'EN'
      ? Yup
          .string()
          .min(1, t('minCharacters'))
          .matches(/^[\p{L} ]+$/u, t('legalName'))
          .matches(/^\p{L}+( \p{L}+)*$/u, t('legalNameOneSpace'))
          .max(50, t('max50Characters')).required(t('legalNameRequired'))
          .required(t('legalNameRequired'))
      : Yup
        .string()
        .min(1, t('minCharacters'))
        .max(50, t('max50Characters')).required(t('legalNameRequired'))
        .required(t('legalNameRequired')),

    idOfAffiliate: Yup.string()
      .required(t('idAffiliateRequired')),

    allowedLanguages: Yup
      .array()
      .min(1, t('required', { label: t('allowedLanguages') })),

    primaryLanguage: Yup.string()
      .required(t('primaryLanguageRequired')),
    revenuePercentage: isSuperAdminRole
      ? Yup.number()
          .transform((value) => Number.isNaN(value) ? null : value)
          .required(t('fieldRequired'))
          .min(0, `${t('valueMustBePositive')}`)
          .max(100, `${t('maximumValue100')}`)
      : Yup.number().notRequired()
  })
}

export const editAffiliateSchema = (t, isTenant) => {
  return Yup.object().shape({
    phoneCode: Yup.string()
      .required(t('phoneCodeRequired')),

    phone: Yup.string()
      .min(5, t('min5Numbers'))
      .max(16, t('max16Numbers'))
      .matches(/^\d{5,20}$/, t('enterValidPhone'))
      .required(t('phoneRequired')),

    allowedLanguages: Yup
      .array()
      .min(1, t('required', { label: t('allowedLanguages') })),

    primaryLanguage: Yup.string()
      .required(t('primaryLanguageRequired')),
    revenuePercentage: isSuperAdminRole
      ? Yup.number()
          .transform((value) => Number.isNaN(value) ? null : value)
          .required(t('fieldRequired'))
          .min(0, `${t('valueMustBePositive')}`)
          .max(100, `${t('maximumValue100')}`)
      : Yup.number().notRequired()
  })
}
