import React from 'react'
import SportListing from '../../../components/SportsBook/SportListing'
import SportsCountriesListing from '../../../components/SportsBook/SportsCountriesListing'
import MatchListing from '../../../components/SportsBook/MatchListing'
import MarketListing from '../../../components/SportsBook/MarketListing'
import LeagueListing from '../../../components/SportsBook/LeagueListing'

const Sports = (
  listingProp
) => {
  const renderComponent = (name) => {
    switch (name) {
      case 'Sports':
        return (
          <SportListing
            title={name}
            isTenant
          />
        )

      case 'Countries':
        return (
          <SportsCountriesListing
            title={name}
            isTenant
          />
        )

      case 'Matches':
        return (
          <MatchListing
            title={name}
            isTenant
          />
        )

      case 'Markets':
        return (
          <MarketListing
            title={name}
            isTenant
          />
        )

      case 'Leagues':
        return (
          <LeagueListing
            title={name}
            isTenant
          />
        )

      default:
    }
  }
  return (
    <div>
      {
      renderComponent(Object.keys(listingProp)[0])
      }
    </div>
  )
}

export default Sports
