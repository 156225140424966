import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBonusDetailAffiliateStart } from '../../../../store/redux-slices/affiliate'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { getEntityBonusDetailsStart, removeAffiliateAndAgentStart, resetEntityBonusDetails, updateAffiliateAndAgentBonusLimitStart } from '../../../../store/redux-slices/bonus'
import { BonusVal } from '../../constant'

const useAffiliateList = ({ isAffiliate, isTenant }) => {
  const { entityBonusDetails, entityLoading } = useSelector(state => state?.bonus)
  const [selectedData, setSelectedData] = useState([])
  const { bonusDetail, updateLoading } = useSelector((state) => state.bonus)
  const [isLimitUpdateModalOpen, setLimitIsUpdateModalOpen] = useState(false)
  const [isEntityDetailModalOpen, setIsEntityDetailModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [removedData, setRemovedData] = useState(null)
  const searchRef = useRef()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { bonusAffiliate, bonusAffiliateLoading } = useSelector((state) => state.affiliate)
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const getAffiliates = () => {
    dispatch(getBonusDetailAffiliateStart({ formData: { size: limit, pageNo: page, search: search || '', bonusId: bonusDetail?.bonusId } }))
  }
  const handleUpdateLimit = (values, formActions) => {
    const formData = { ...values }
    if (formData?.bonusType === BonusVal.winningBonus) {
      delete formData.bonusPercent
    } else {
      delete formData.addOnOddBonus
    }
    const allowedEntity = selectedData?.map((item) => {
      return {
        entityId: item,
        ...formData
      }
    })
    const tempData = {
      bonusId: bonusDetail?.bonusId,
      entityType: 'affiliate',
      allowedEntity
    }
    dispatch(updateAffiliateAndAgentBonusLimitStart({
      formData: tempData,
      handleClose: () => {
        setLimitIsUpdateModalOpen(false)
        setSelectedData([])
        getAffiliates()
      }
    }))
  }
  const getEntityBonusDetails = (formData) => {
    dispatch(resetEntityBonusDetails())
    dispatch(getEntityBonusDetailsStart({ formData, isAffiliate, isTenant, bonusType: bonusDetail?.bonusType }))
  }
  useEffect(() => {
    if (bonusDetail?.bonusId) {
      getAffiliates()
    }
  }, [limit, page, search, bonusDetail])

  const isChecked = (id) => {
    return selectedData?.find(affiliateId => Number(affiliateId) === Number(id))
  }
  const handleCheck = (e, affiliateId) => {
    if (!isChecked(e.target.value)) {
      setSelectedData(prev => [...prev, affiliateId])
    } else {
      setSelectedData(selectedData.filter(t => Number(t) !== Number(e.target.value)))
    }
  }
  const handleYesRemove = () => {
    dispatch(removeAffiliateAndAgentStart({
      formData: removedData,
      isAffiliate,
      isTenant,
      handleClose: () => {
        setIsRemoveModalOpen(false)
        getAffiliates()
        setRemovedData(null)
      }
    }))
  }
  const handleResetEntityDetails = () => {
    dispatch(resetEntityBonusDetails())
  }
  useEffect(() => {
    handleResetEntityDetails()
  }, [])
  return {
    handleResetEntityDetails,
    entityBonusDetails,
    entityLoading,
    isEntityDetailModalOpen,
    setIsEntityDetailModalOpen,
    getEntityBonusDetails,
    isRemoveModalOpen,
    setIsRemoveModalOpen,
    setRemovedData,
    handleYesRemove,
    t,
    bonusAffiliate,
    bonusAffiliateLoading,
    limit,
    setLimit,
    page,
    setPage,
    debounceFn,
    searchRef,
    isChecked,
    handleCheck,
    bonusDetail,
    isLimitUpdateModalOpen,
    setLimitIsUpdateModalOpen,
    handleUpdateLimit,
    setSelectedData,
    selectedData,
    updateLoading
  }
}

export default useAffiliateList
