import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCasinoGameStart, getAllCasinoProvidersStart, updateActionStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import { toast } from '../../../../components/Toast'
import { useTranslation } from 'react-i18next'

const useCreateCasinoGame = ({ fetchData }) => {
  const { t } = useTranslation()
  const { languages } = useSelector(state => state.languages)
  const dispatch = useDispatch()
  const {
    casinoProvidersData,
    loading,
    specificGameData
  } = useSelector((state) => state.superAdminCasino)
  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({
      limit: '',
      pageNo: ''
    }))
  }, [])

  const updateCasinoGame = (data, limit, pageNo, handleClose) => {
    dispatch(
      updateActionStart({
        data,
        onSuccess: () => {
          toast(t('updateSuccess'), 'success')
          data.handleClose()
          fetchData()
        }
      })

    )
  }

  const createCasinoGame = ({
    data,
    casinoProviderId,
    limit,
    pageNo,
    handleClose
  }) =>
    dispatch(
      createCasinoGameStart({
        data,
        casinoProviderId,
        limit,
        pageNo,
        onSuccess: () => {
          toast(t('createSuccess'), 'success')
          handleClose()
          fetchData()
        }
      })
    )

  return {
    casinoProvidersData,
    loading,
    updateCasinoGame,
    createCasinoGame,
    specificGameData,
    t,
    languages
  }
}

export default useCreateCasinoGame
