import React from 'react'
import useSportsBookBonus from './hooks/useSportsBookBonus'
import ClientFilter from '../../components/ClientFilter'
import Trigger from '../../components/OverlayTrigger'
import { Button, Form as BForm, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faEdit, faEye, faRedoAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../components/Pagination'
import { BONUSES, tableHeaders } from './constant'
import { SuperAdminRoutes } from '../../routes'
import moment from 'moment'
import ChangeStatusModal from '../Modals/ChangeStatusModal'
import Preloader from '../../components/Preloader'
import InputDatePicker from '../../components/FormElements/InputDatePicker/InputDatePicker'
import useIsGamma from '../hook/useIsGamma'
const SportsbookBonus = ({ isTenant, isAffiliate }) => {
  const {
    t,
    updateLoading,
    selectedClient,
    setSelectedClient,
    bonusList,
    navigate,
    loading,
    limit,
    setLimit,
    setPage,
    page,
    bonusType,
    setBonusType,
    isActive,
    setIsActive,
    handleShow,
    bonusValidFrom,
    setBonusValidFrom,
    bonusValidTo,
    setBonusValidTo,
    show,
    setShow,
    handleYes
  } = useSportsBookBonus({ isTenant, isAffiliate })
  const {
    isGamma
  } = useIsGamma()
  return (
    <>
      {loading && <Preloader />}

      <div className='p-3'>
        <div className='mb-3 d-flex align-items-center gap-3 justify-content-between'>
          <h3>{t('sportsbookBonus')}</h3>
          {!isTenant && !isAffiliate && !isGamma && (
            <Button
              size='sm'
              variant='outline-success'
              onClick={() => navigate(SuperAdminRoutes.SportsBonusCreation)}
            >
              {t('create')}
            </Button>
          )}
        </div>
        <div className='d-flex align-items-center gap-3 flex-wrap'>
          {!isTenant && !isAffiliate && (
            <div className='d-flex gap-2 align-items-center'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
              />
            </div>
          )}
          <div className='d-flex gap-2 align-items-center '>
            <BForm.Label className='flex-shrink-0'>
              {t('bonusType')}
            </BForm.Label>
            <BForm.Select
              name='bonusType'
              size='sm'
              value={bonusType}
              onChange={(e) => {
                setBonusType(e.target.value)
                setPage(1)
              }}
            >
              <option value='' key=''>
                {t('all')}
              </option>
              {BONUSES.map((item) => {
                return (
                  <option key={`bonusType ${item.value}`} value={item.value}>
                    {t(item?.label)}
                  </option>
                )
              })}
            </BForm.Select>
          </div>
          <div className='d-flex gap-2 align-items-center '>
            <BForm.Label className='flex-shrink-0'>
              {t('status')}
            </BForm.Label>
            <BForm.Select
              name='isActive'
              size='sm'
              value={isActive}
              onChange={(e) => {
                setIsActive(e.target.value)
                setPage(1)
              }}
              style={{ maxWidth: '230px' }}
            >
              <option value='' key=''>
                {t('all')}
              </option>
              <option value='true' key='active'>
                {t('active')}
              </option>
              <option value='false' key='in-active'>
                {t('inactive')}
              </option>
            </BForm.Select>
          </div>
          <div className='d-flex gap-2 align-items-center '>
            <InputDatePicker
              isFilter
              label={t('fromDate')}
              onChange={(val) => {
                setBonusValidFrom(val)
              }}
              value={bonusValidFrom}
            />
          </div>
          <div className='d-flex gap-2 align-items-center '>
            <InputDatePicker
              isFilter
              label={t('toDate')}
              onChange={(val) => {
                setBonusValidTo(val)
              }}
              value={bonusValidTo}
            />
          </div>
          <div className='d-flex '>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='align-self-center'
                size='sm'
                onClick={() => {
                  setIsActive('')
                  setBonusType('')
                  setSelectedClient('')
                  setLimit(15)
                  setPage(1)
                  setBonusValidFrom('')
                  setBonusValidTo('')
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </div>
        </div>
        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center mt-4'
        >
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => {
                if ((h.label === 'ownerName' || h.label === 'senior') && isAffiliate) {
                  return null
                } else {
                  return (
                    <th key={`T-table_heading ${idx}`}>{t(h.label)}</th>
                  )
                }
              })}
            </tr>
          </thead>

          <tbody>
            {bonusList && !loading &&
            bonusList?.rows?.map(
              (item, index) => {
                const isExpired = moment(new Date().toISOString()).isAfter(item?.bonusValidTo, 'minute')
                return (
                  <tr key={`bonus-list ${index}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    {!isAffiliate && <td>{item?.parentUsername}</td>}
                    {!isAffiliate && <td>{item?.tenant?.name || '-'}</td>}

                    <td>{item?.bonusType}</td>
                    <td>{moment(item?.bonusValidTo).format('MM-DD-YYYY HH:mm')}</td>
                    <td>{isExpired ? t('yes') : t('no')}</td>
                    <td className={item?.status ? 'table-success' : 'table-danger'}>
                      {item?.status ? t('active') : t('inActive')}
                    </td>
                    <td className='text-left'>
                      <div className='d-flex justify-content-center'>
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() =>
                              navigate(`/${isAffiliate ? 'affiliate' : isTenant ? 'tenant' : 'super-admin'}/sports/bonus-detail/${item?.bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                        {!isTenant && !isAffiliate && !isGamma && (
                          <>
                            <Trigger message={t('edit')}>
                              <Button
                                disabled={isExpired}
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() =>
                                  navigate(`/super-admin/sports/bonus-edit/${item?.bonusId}`)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>
                            <Trigger message={item?.status ? t('setStatusInactive') : t('setStatusActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant={item?.status ? 'success' : 'danger'}
                                onClick={() => handleShow(item)}
                              >
                                <FontAwesomeIcon icon={item?.status ? faCheckSquare : faWindowClose} />
                              </Button>
                            </Trigger>
                          </>
                        )}
                      </div>

                    </td>

                  </tr>
                )
              }
            )}

            {bonusList?.count === 0 && !loading && (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {bonusList?.count > 0 && !loading && (
          <PaginationComponent
            page={bonusList?.count < page ? setPage(1) : page}
            totalPages={Math.ceil(bonusList?.count / limit)}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={bonusList?.count}
          />
        )}
        <ChangeStatusModal
          show={show}
          handleClose={() => {
            setShow(false)
          }}
          handleUpdateYes={() => {
            handleYes()
          }}
          loading={updateLoading}
        />
      </div>
    </>
  )
}

export default SportsbookBonus
