import { Button, Row, Form as BForm, Col, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import useEditCasinoGames from '../hooks/useEditCasinoGames'
import { editGamesSchema } from '../schema'
import { useSelector } from 'react-redux'
import { getItem } from '../../../../utils/storageUtils'

const EditGames = ({
  handleClose,
  show,
  gameData,
  type,
  subCategories,
  limit,
  pageNo,
  casinoCategoryId,
  statusFilter,
  providerId,
  t
}) => {
  const {
    editGames
  } = useEditCasinoGames()
  const { languages } = useSelector(state => state.languages)
  const getTranslatedGameName = (translations, languageCode) => {
    return translations?.[languageCode] || ''
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          {
            name: gameData?.translations?.[getItem('language')] || gameData?.name || '',
            description: gameData?.description || '',
            thumbnail: null,
            isActive: gameData?.isActive || false,
            tenantGameSubCategory: gameData?.TenantGameSubCategory || '',
            language: gameData?.translation || 'en'
          }
        }
        validationSchema={editGamesSchema(t)}
        onSubmit={(formValues) => {
          editGames({
            data: {
              ...formValues,
              gameId: gameData?.identifier,
              categoryGameId: gameData?.masterCasinoGameId,
              tenantGameSubCategoryId: parseInt(formValues.tenantGameSubCategoryId)
            },
            limit,
            pageNo,
            casinoCategoryId,
            isActive: statusFilter,
            providerId
          })
          handleClose()
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset
        }) => (
          <Form>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop='static'
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <h4 className='d-flex align-items-center'>
                  {t(type)} {t('game')}
                </h4>
              </Modal.Header>
              <Modal.Body>
                <Row className='mt-3'>
                  <Col className='d-grid'>
                    <BForm.Label>{t('selectLanguage')}</BForm.Label>
                    <BForm.Select
                      name='language'
                      value={values.language}
                      onChange={(e) => {
                        setFieldValue('name', getTranslatedGameName(gameData?.translations, e.target.value))
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                    >
                      <option value=''>{t('selectLanguage')}</option>
                      {languages && languages?.map(({
                        languageName,
                        code
                      }, index) => {
                        return (
                          <option
                            key={index}
                            value={code}
                          >
                            {languageName}
                          </option>
                        )
                      })}
                    </BForm.Select>

                    <ErrorMessage
                      component='div'
                      name='language'
                      className='text-danger'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <BForm.Label>
                      {t('name')}<span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='text'
                      name='name'
                      placeholder={t('enterName')}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='name'
                      className='text-danger'
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <BForm.Label>
                      {t('description')}<span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      as='textarea'
                      name='description'
                      placeholder={t('enterDescription')}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='description'
                      className='text-danger'
                    />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <BForm.Label>
                      {t('gameSubCategory')}<span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      name='description'
                      placeholder={t('enterDescription')}
                      disabled
                      value={values.tenantGameSubCategory}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <BForm.Select
                      name='tenantGameSubCategoryId'
                      value={values.tenantGameSubCategoryId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {subCategories &&
                      subCategories?.rows?.map(({ name, tenantGameSubCategoryId }, index) => {
                        return (
                          <option key={tenantGameSubCategoryId} value={tenantGameSubCategoryId}>
                            {name?.EN}
                          </option>
                        )
                      })}
                    </BForm.Select> */}
                    <ErrorMessage
                      component='div'
                      name='tenantGameSubCategoryId'
                      className='text-danger'
                    />
                  </Col>
                </Row>

              </Modal.Body>

              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      handleClose()
                      handleReset()
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    {t('submit')}
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditGames
