import { BonusVal } from '../common/NewBonus/constant'

export const safeStringify = (object) => {
  return JSON.stringify(object)?.replace(/</g, '\\u003c')
}
export const agentObject = (sampleArray, affiliateId) => {
  const response = sampleArray?.find((affiliate) => Number(affiliate.affiliateId) === Number(affiliateId))
  return response?.Agents ?? []
}
export const isEqual = (newObj, prevObj) => {
  for (const key in newObj) {
    if (newObj[key] !== prevObj[key]) return false
  }
  return true
}

export const capitalizeMyText = (name, delemiter) => {
  return (name && name?.length > 0 && name.split(delemiter).map(ele =>
    ele.substr(0, 1).toUpperCase().concat(ele.substr(1))
  ).join(' ')) || name
}
export const returnFormBonusData = (values, isUpdateLimit = false) => {
  let formData
  const { bonusType } = values
  const commonData = {
    type: values?.type,
    bonusType: values?.bonusType,
    ...(!isUpdateLimit && {
      bonusValidFrom: new Date(values?.bonusValidFrom).toISOString(),
      bonusValidTo: new Date(values?.bonusValidTo).toISOString(),
      adminId: values?.adminId,
      tenantId: values?.tenantId,
      currencyCode: values?.currencyCode
    }),
    rollingPercent: values?.rollingPercent,
    maxBonusLimit: values?.maxBonusLimit,
    ...(values?.bonusPercent && { bonusPercent: values?.bonusPercent })

  }

  if (bonusType === BonusVal.compensationBonus) {
    formData = {
      ...commonData,
      limitType: values?.limitType,
      customLimit: values?.customLimit,
      minsForBonusGiven: values?.minsForBonusGiven,
      compensationTarget: values?.compensationTarget
    }
  } else if (bonusType === BonusVal.reOccuringDepositBonus) {
    formData = {
      ...commonData,
      reoccuringDepositRate: values?.reoccuringDepositRate,
      withdrawalRate: values?.withdrawalRate
    }
  } else if (bonusType === BonusVal.paybackBonus) {
    formData = {
      ...commonData,
      slotCatBonusPercent: values?.slotCatBonusPercent,
      liveCatBonusPercent: values?.liveCatBonusPercent
    }
  } else {
    formData = commonData
  }
  return formData
}

export const getEntityArray = (user, affiliateAndAgentsList, entityType) => {
  let entityArray = []
  if (user?.affiliateId && user?.agentId) {
    if (entityType === 'Affiliate') {
      entityArray = affiliateAndAgentsList?.affiliates?.map(item => ({
        affiliateId: item.affiliateId,
        username: item.username
      }))
    } else {
      entityArray = affiliateAndAgentsList?.affiliates?.find(aff => aff.affiliateId === user?.affiliateId)
      entityArray = entityArray.Agents
    }
  } else if (user?.affiliateId && !user?.agentId) {
    if (entityType === 'Affiliate') {
      entityArray = affiliateAndAgentsList?.affiliates?.map(item => ({
        affiliateId: item.affiliateId,
        username: item.username
      }))
    } else {
      if (entityType === 'Affiliate') {
        entityArray = affiliateAndAgentsList?.affiliates?.map(item => ({
          affiliateId: item.affiliateId,
          username: item.username
        }))
      } else {
        const requiredAgentsArray = affiliateAndAgentsList.affiliates?.find((index) => index.affiliateId === user.affiliateId)
        entityArray = requiredAgentsArray?.Agents
      }
    }
  } else {
    if (entityType === 'Affiliate') {
      entityArray = affiliateAndAgentsList?.affiliates?.map(item => ({
        affiliateId: item.affiliateId,
        username: item.username
      }))
    } else {
      entityArray = affiliateAndAgentsList.agents
    }
  }
  return entityArray
}
export const transformArray = (array) =>
  array?.map(({ agentId, affiliateId, username }) => ({
    value: agentId !== undefined ? agentId : affiliateId,
    label: username
  }))

export const objectToFormData = (obj) => {
  const formData = new FormData()

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      formData.append(key, obj[key])
    }
  }
  return formData
}

export const isNotProduction =
  origin.includes('staging') ||
  origin.includes('dev') ||
  origin.includes('local')
