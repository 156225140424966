import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner

} from '@themesberg/react-bootstrap'
import React from 'react'
import './depositAddonConfig.scss'
import useDepositAddonConfig from './hooks/useDepositAddonConfig'
import { depositAddonType } from './depositAddonConstant'
import { depositAddonSchema } from './hooks/schema'
import Preloader from '../../../components/Preloader'
import ClientFilter from '../../../components/ClientFilter'
import { Accordion } from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import moment from 'moment'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
const DepositAddonConfig = () => {
  const {
    selectedClient,
    setSelectedClient,
    t,
    loading,
    depositAddEditLoading,
    depositAddonAddEditHandler,
    tenantDetails,
    loaderForItem,
    setLoaderForItem
  } = useDepositAddonConfig()

  return (
    <div className='p-3'>
      <Row className='mb-3'>
        <Col>
          <h3>{t('depositAddOnConfig')}</h3>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>
        </Col>
      </Row>

      {selectedClient
        ? (
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            {loading
              ? <Preloader />
              : depositAddonType?.map((item, i) => {
                const tenantDepositConfigData = tenantDetails?.tenantConfig?.[item]
                const tenantHotimeBonus = tenantDetails?.tenantHotimeBonus
                const isEdit = !!tenantDepositConfigData || false
                const isBonusEdit = tenantHotimeBonus ? 1 : 0

                return (
                  <Accordion.Item
                    key={`deposit-type-add-on-listing-${i}`}
                    eventKey={String(i)}
                  >
                    <Accordion.Header>{t(item)}</Accordion.Header>
                    <Accordion.Body>
                      <div className='ps-2'>
                        <Formik
                          enableReinitialize={false}
                          initialValues={{
                            addOnPercentage: tenantDepositConfigData?.addOnPercentage || '',
                            addOnRelativeAmount: tenantDepositConfigData?.addOnRelativeAmount || '',
                            rollingPercentage: tenantDepositConfigData?.rollingPercentage || '',
                            bonusValidFrom: isBonusEdit ? moment(tenantHotimeBonus?.bonusValidFrom).toDate() : moment().toDate(),
                            bonusValidTo: isBonusEdit ? moment(tenantHotimeBonus?.bonusValidTo).toDate() : moment().add(1, 'hour').toDate(),
                            maxBonusLimit: tenantHotimeBonus?.maxBonusLimit || '',
                            rollingPercent: tenantHotimeBonus?.rollingPercent || '',
                            bonusPercent: tenantHotimeBonus?.bonusPercent || 0,
                            bonusId: tenantHotimeBonus?.bonusId || '',
                            adminId: tenantDetails?.adminUserId || '',
                            currencyCode: tenantDetails?.primaryCurrency,
                            status: true,
                            bonusType: 'HOT-TIME-BONUS',
                            type: (item === 'hotTimeBonus') ? 'bonus' : ''
                          }}
                          validationSchema={depositAddonSchema(t)}
                          onSubmit={(formValues, { resetForm }) => {
                            const newFormsValue = {
                              ...formValues,
                              tenantId: Number(selectedClient),
                              addOnType: item
                            }
                            setLoaderForItem(i)
                            depositAddonAddEditHandler(newFormsValue, isEdit, isBonusEdit, resetForm)
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue
                          }) => {
                            return (
                              <Form className='mt-2'>
                                <Row>
                                  {item !== 'hotTimeBonus'
                                    ? (
                                      <>
                                        <Col sm={6} className='mb-2'>
                                          <label>
                                            {t('addOnPercentage')}<span className='text-danger'> *</span>
                                          </label>
                                          <BForm.Control
                                            type='number'
                                            onWheel={(e) => e.target.blur()}
                                            name='addOnPercentage'
                                            maxLength={25}
                                            placeholder={t('addOnPercentagePlaceholder')}
                                            value={values.addOnPercentage || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            name='addOnPercentage'
                                            className='text-danger'
                                          />
                                        </Col>

                                        <Col sm={6} className='mb-2'>
                                          <label>
                                            {t('addOnRelativeAmount')}<span className='text-danger'> *</span>
                                          </label>
                                          <BForm.Control
                                            type='number'
                                            onWheel={(e) => e.target.blur()}
                                            name='addOnRelativeAmount'
                                            maxLength={25}
                                            placeholder={t('addOnRelativeAmountPlaceholder')}
                                            value={values.addOnRelativeAmount || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            name='addOnRelativeAmount'
                                            className='text-danger'
                                          />
                                        </Col>

                                        <Col className='mb-2' sm={6}>
                                          <label>
                                            {t('rollingPercentage')}<span className='text-danger'> *</span>
                                          </label>
                                          <BForm.Control
                                            type='number'
                                            onWheel={(e) => e.target.blur()}
                                            name='rollingPercentage'
                                            placeholder={t('rollingPercentagePlaceholder')}
                                            maxLength={25}
                                            value={values.rollingPercentage || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            name='rollingPercentage'
                                            className='text-danger'
                                          />
                                        </Col>
                                      </>
                                      )
                                    : (
                                      <>
                                      </>
                                      )}

                                  {item === 'hotTimeBonus'
                                    ? (
                                      <>
                                        <Col className='mb-2' sm={6}>
                                          <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                                            {t('bonus_percentage')}<span className='text-danger'> *</span>
                                          </BForm.Label>
                                          <Trigger message='Value will consider in percentage'>
                                            <BForm.Control
                                              name='bonusPercent'
                                              type='number'
                                              min={1}
                                              placeholder={t('enterValueInPercent')}
                                              value={values.bonusPercent}
                                              onInput={handleChange}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                            />
                                          </Trigger>
                                          <ErrorMessage
                                            component='div'
                                            name='bonusPercent'
                                            className='text-danger mt-1'
                                          />
                                        </Col>
                                        <Col className='mb-2' sm={6}>
                                          <label>
                                            {t('maxBonusLimit')}<span className='text-danger'> *</span>
                                          </label>
                                          <BForm.Control
                                            name='maxBonusLimit'
                                            type='number'
                                            min={1}
                                            placeholder={t('enterValue')}
                                            value={values.maxBonusLimit}
                                            onInput={handleChange}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            name='maxBonusLimit'
                                            className='text-danger'
                                          />
                                        </Col>
                                        <Col className='mb-2' sm={6}>
                                          <label>
                                            {t('rollingPercentage')}<span className='text-danger'> *</span>
                                          </label>
                                          <BForm.Control
                                            name='rollingPercent'
                                            type='number'
                                            min={1}
                                            placeholder={t('enterValue')}
                                            value={values.rollingPercent}
                                            onInput={handleChange}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                          <ErrorMessage
                                            component='div'
                                            name='rollingPercentage'
                                            className='text-danger'
                                          />
                                        </Col>
                                        <Col className='mb-2' sm={6}>
                                          <div className='d-flex flex-column gap-1'>
                                            <InputDatePicker
                                              isForm
                                              label={t('bonus_valid_from')}
                                              onChange={(val) => {
                                                setFieldValue('bonusValidFrom', val)
                                              }}
                                              value={values.bonusValidFrom}
                                            />
                                            <ErrorMessage
                                              component='div'
                                              name='bonusValidFrom'
                                              className='text-danger mt-1'
                                            />
                                          </div>

                                        </Col>
                                        <Col className='mb-2' sm={6}>
                                          <div className='d-flex flex-column gap-1'>
                                            <InputDatePicker
                                              isForm
                                              label={t('bonus_valid_to')}
                                              onChange={(val) => {
                                                setFieldValue('bonusValidTo', val)
                                              }}
                                              value={values.bonusValidTo}
                                            />
                                            <ErrorMessage
                                              component='div'
                                              name='bonusValidTo'
                                              className='text-danger mt-1'
                                            />
                                          </div>

                                        </Col>
                                      </>
                                      )
                                    : (
                                      <></>
                                      )}
                                </Row>

                                <div className='mt-4 d-flex justify-content-end align-items-center'>
                                  <Button
                                    disabled={depositAddEditLoading}
                                    variant='outline-success'
                                    onClick={handleSubmit}
                                    className='ml-2'
                                  >
                                    {item === 'hotTimeBonus' ? (isBonusEdit ? t('update') : t('submit')) : (isEdit ? t('update') : t('submit'))}
                                    {depositAddEditLoading && (loaderForItem === i) && (
                                      <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                      />
                                    )}
                                  </Button>
                                </div>
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
          </Accordion>
          )
        : (
          <div className='depositType-select-tenant'>
            <p>{t('pleaseSelectTenant')}</p>
          </div>
          )}
    </div>
  )
}

export default DepositAddonConfig
