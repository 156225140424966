import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { depositAddonAddEditStart } from '../../../../store/redux-slices/admins'
import { createBonusStart, updateBonusStart } from '../../../../store/redux-slices/bonus'

import { getTenantStart } from '../../../../store/redux-slices/tenants'

const useDepositAddonConfig = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [loaderForItem, setLoaderForItem] = useState(0)
  const { tenantDetails, loading } = useSelector((state) => state.tenants)
  const { depositAddEditLoading } = useSelector((state) => state.admins)

  const depositAddonAddEditHandler = (formValues, isEdit, isBonusEdit, resetValues) => {
    formValues.type === 'bonus'
      ? isBonusEdit
          ? dispatch(updateBonusStart({ data: { ...formValues, type: 'CASINO' } }))
          : dispatch(createBonusStart({ formData: { ...formValues, type: 'CASINO' }, navigate }))
      : dispatch(depositAddonAddEditStart({ data: formValues, isEdit, resetValues }))
  }

  useEffect(() => {
    if (selectedClient) {
      dispatch(getTenantStart({ tenantId: selectedClient }))
    }
  }, [selectedClient])

  return {
    navigate,
    selectedClient,
    setSelectedClient,
    t,
    depositAddonAddEditHandler,
    tenantDetails,
    depositAddEditLoading,
    loading,
    loaderForItem,
    setLoaderForItem
  }
}

export default useDepositAddonConfig
