export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'userName', value: 'usename' },
  { label: 'transactionId', value: 't' },
  { label: 'name', value: 'name' },
  { label: 'requestStatus', value: 'status' },
  { label: 'ipAddress', value: 'ip' },
  { label: 'createdAt', value: 'date' },
  { label: 'amount', value: 'amount' },
  { label: 'transactionType', value: 'actionSource' }
]
export const requestActions = [
  { label: 'approve', value: 'ACCEPT' },
  { label: 'reject', value: 'REJECT' },
  { label: 'pending', value: 'PENDING' }
]
export const actionSources = [
  { label: 'withdraw', value: 'WITHDRAW' },
  { label: 'deposit', value: 'DEPOSIT' }
]
