export const notificationTable = (isTenant) => {
  return isTenant
    ? [
        { label: 's.no', value: '' },
        { label: 'origin', value: 'senderType' },
        { label: 'category', value: 'referenceType' },
        { label: 'message', value: 'notificationMessage' },
        { label: 'status', value: 'isActive' },
        { label: 'created', value: 'createdAt' },
        { label: 'updated', value: 'updatedAt' },
        { label: 'taskOptions', value: 'button' }
      ]
    : [
        { label: 's.no', value: '' },
        { label: 'origin', value: 'senderType' },
        { label: 'senior', value: 'senior' },
        { label: 'category', value: 'referenceType' },
        { label: 'message', value: 'notificationMessage' },
        { label: 'status', value: 'isActive' },
        { label: 'created', value: 'createdAt' },
        { label: 'updated', value: 'updatedAt' },
        { label: 'taskOptions', value: 'button' }
      ]
}

export const readRecieptTable = [
  { label: 's.no', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'seenBy', value: 'isNotificationRead' },
  { label: 'date', value: 'updatedAt' }
]
export const notificationCategoryOptions = [
  { label: 'emergency', value: 0 },
  { label: 'maintenance', value: 1 },
  { label: 'bonus', value: 2 },
  { label: 'voucher', value: 5 },
  // { label: 'season', value: 4 },
  { label: 'deposit', value: 6 },
  { label: 'withdrawal', value: 7 }
]
export const statusFilter = [
  { label: 'sent', value: true },
  { label: 'unsent', value: false }
]
export const isReadFilter = [
  { label: 'seen', value: true },
  { label: 'unseen', value: false }
]
