import { Button, Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const BonusDetailModal = ({
  show,
  handleClose
}) => {
  const { t } = useTranslation()
  const { entityBonusDetails, entityLoading } = useSelector(state => state?.bonus)
  return (
    <Modal show={show} onHide={handleClose} centered>

      <Modal.Body>
        {entityLoading
          ? 'Loading'
          : (
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('bonusType')}</strong>
                <span>{t(entityBonusDetails?.bonusType)}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('maximum_bonus_limit')}</strong>
                <span>{entityBonusDetails?.maxBonusLimit} ₩</span>
              </div>
              {!entityBonusDetails?.isPayback && (
                <div className='d-flex align-items-center gap-2'>
                  <strong style={{ minWidth: 220 }}>{t('bonus_percentage')}</strong>
                  <span>{entityBonusDetails?.bonusPercent}%</span>
                </div>
              )}
              {
          entityBonusDetails?.isCompensation && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('limitType')}</strong>
                <span>{entityBonusDetails?.limitType}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('customLimit')}</strong>
                <span>{entityBonusDetails?.customLimit} ₩</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('minutes_for_bonus_given')}</strong>
                <span>{entityBonusDetails?.minsForBonusGiven}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('compensation_target')}</strong>
                <span>{entityBonusDetails?.compensationTarget} ₩</span>
              </div>
            </>
          )

        }
              {
          entityBonusDetails?.isRecurring && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('reoccurring_deposit_rate')}</strong>
                <span>{entityBonusDetails?.reoccuringDepositRate}%</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('withdrawal_rate')}</strong>
                <span>{entityBonusDetails?.withdrawalRate}%</span>
              </div>

            </>
          )

        }
              {
                entityBonusDetails?.isPayback && (
                  <>
                    <div className='d-flex align-items-center gap-2'>
                      <strong style={{ minWidth: 220 }}>{t('slotCatBonusPercent')}</strong>
                      <span>{entityBonusDetails?.slotCatBonusPercent}%</span>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                      <strong style={{ minWidth: 220 }}>{t('liveCatBonusPercent')}</strong>
                      <span>{entityBonusDetails?.liveCatBonusPercent}%</span>
                    </div>

                  </>
                )
        }

            </div>
            )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-primary' onClick={handleClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BonusDetailModal
