import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllBonusStart } from '../../../store/redux-slices/admins'
import moment from 'moment'
import { updateSABonusStatusStart } from '../../../store/redux-slices/bonus'

const useBonus = ({ isTenant, isAffiliate }) => {
  const { bonusList, loading } = useSelector((state) => state.admins)
  const { deleteLoading, updateLoading } = useSelector((state) => state.bonus)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isActive, setIsActive] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [bonusValidFrom, setBonusValidFrom] = useState('')
  const [bonusValidTo, setBonusValidTo] = useState('')
  const [bonusType, setBonusType] = useState('')
  const [show, setShow] = useState(false)
  const [activeData, setActiveData] = useState('')

  const getBonus = () => {
    dispatch(getAllBonusStart({
      formData: {
        type: 'CASINO',
        adminId: selectedClient,
        size: limit,
        pageNo: page,
        status: isActive,
        bonusType: bonusType || '',
        bonusValidFrom: bonusValidFrom ? moment(bonusValidFrom).toISOString() : '',
        bonusValidTo: bonusValidTo ? moment(bonusValidTo).toISOString() : ''
      },
      isTenant,
      isAffiliate
    }))
  }
  const handleShow = (item) => {
    setShow(true)
    setActiveData({
      bonusId: item?.bonusId,
      bonusType: item?.bonusType,
      tenantId: item?.tenantId,
      adminId: item?.adminId,
      status: !item?.status
    })
  }

  const handleYes = () => {
    dispatch(
      updateSABonusStatusStart({
        data: activeData,
        handleClose: () => {
          setShow(false)
          getBonus()
        }
      })
    )
  }

  useEffect(() => {
    getBonus()
  }, [page, selectedClient, bonusType, isActive, limit, bonusValidFrom, bonusValidTo])
  return {
    handleYes,
    bonusValidFrom,
    setBonusValidFrom,
    bonusValidTo,
    setBonusValidTo,
    t,
    selectedClient,
    setSelectedClient,
    bonusList,
    navigate,
    loading,
    limit,
    setLimit,
    setPage,
    page,
    bonusType,
    setBonusType,
    isActive,
    setIsActive,
    activeData,
    show,
    handleShow,
    setShow,
    deleteLoading,
    updateLoading
  }
}

export default useBonus
