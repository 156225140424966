import React, { useState, useEffect } from 'react'
import ReorderComponent from '../../common/ReorderComponent'
import useReorderLeagues from './hooks/useReorderLeagues'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

const ReorderLeagues = () => {
  const { topTournaments, loading, navigate, isTenant } = useReorderLeagues()
  const { t } = useTranslation()
  const [state, setState] = useState([])

  useEffect(() => {
    setState(topTournaments?.rows)
  }, [topTournaments])

  return (
    <>
      <div className='py-2'>
        <h3>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className='cursor-pointer px-1'
            onClick={() => navigate(-1)}
          />

          {t('reorderLeagues')}

        </h3>
        <p className='ps-1' style={{ fontSize: '15px' }}>{t('reorderNote')}</p>

      </div>
      <ReorderComponent state={state} setState={setState} loading={loading} isTenant={isTenant} />
    </>
  )
}

export default ReorderLeagues
