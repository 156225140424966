import React from 'react'
import { betslipDetails } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import { outcomeTypeFormatter } from '../../utils/outcomeFormatter'

const BetslipListing = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t
}) => {
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center'>
        <thead className='thead-dark'>
          <tr>
            {betslipDetails.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && tableData &&
       tableData?.rows?.map(
         (item, index) => {
           const team1 = item?.masterMatch?.teams?.find((item) => item?.type === 'home')
           const team2 = item?.masterMatch?.teams?.find((item) => item?.type === 'away')
           const selection = outcomeTypeFormatter({
             outcome: { providerOutcomeId: item?.providerOutcomeId },
             homeTeamName: team1?.team?.teamName?.en,
             awayTeamName: team2.team?.teamName?.en,
             providerMarketId: item?.providerMarketId,
             feedProvider: 1,
             language: 'en',
             subTypeId: ''
           })
           return (
             <tr key={index}>
               <td>{(page - 1) * limit + index + 1}</td>
               <td>
                 {
                   item?.betslipId || t('na')
                 }
               </td>
               <td>
                 {
                   item?.masterMatch.teams[0].team.teamName.en + ' ' + 'Vs' + ' ' + item?.masterMatch.teams[1].team.teamName.en || t('na')
                 }
               </td>
               <td>
                 {item?.masterMarket.marketName.en || t('na')}
               </td>
               <td>
                 {selection || t('na')}
               </td>
               <td>
                 {item?.price || t('na')}
               </td>
               <td>
                 {item?.settlementStatus || t('na')}
               </td>

             </tr>
           )
         }
       )
          }
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {/* {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )} */}
    </div>
  )
}
export default BetslipListing
