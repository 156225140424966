import React, { useEffect, useState } from 'react'
import { Row, Col } from '@themesberg/react-bootstrap'

const LogoUpload = ({ setFieldValue, createTenantData, setCreateTenantData, t }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  useEffect(() => {
    setSelectedImage(null)
  }, [createTenantData])
  const [imgError, setImgError] = useState(false)
  const onFileChange = (file) => {
    setImgError(false)
    setSelectedImage(file)
    setFieldValue('logo', file)
    setCreateTenantData && setCreateTenantData({ ...createTenantData, logo: file })
  }

  return (
    <Row>
      <Col xs>
        <div className='d-grid'>
          <input
            name='logo'
            className='mb-2'
            type='file'
            onChange={(event) => {
              onFileChange(event.target.files[0])
            }}
          />

          {imgError && (
            <span className='text-danger'>
              {t('fileSizeShouldBeLessThan1MB')}
            </span>
          )}

          {selectedImage
            ? (
              <img
                alt='not found'
                style={{ maxWidth: '300px', maxHeight: '100px' }}
                src={URL.createObjectURL(selectedImage)}
              />
              )
            : (
                createTenantData.logo && (
                  <img
                    alt='not found'
                    style={{ maxWidth: '300px', maxHeight: '100px' }}
                    src={typeof createTenantData.logo !== 'string'
                      ? URL.createObjectURL(createTenantData.logo)
                      : createTenantData.logo}
                  />
                )
              )}
        </div>
      </Col>
    </Row>
  )
}

export default LogoUpload
