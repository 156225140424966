import * as Yup from 'yup'
import { BonusVal } from './constant'
import moment from 'moment'
export const createBonusSchema = (t) => {
  return Yup.object().shape({
    bonusType: Yup.string().required(t('fieldRequired')),
    bonusValidFrom: Yup.date()
      .required(t('fieldRequired'))
      .transform((currentValue, originalValue) => moment(originalValue, 'YYYY-MM-DDTHH:mm').toDate())
      .typeError(t('mustbeValidDate')),
    bonusValidTo: Yup.date()
      .required(t('fieldRequired'))
      .min(Yup.ref('bonusValidFrom'), t('validToLater'))
      .transform((currentValue, originalValue) => moment(originalValue, 'YYYY-MM-DDTHH:mm').toDate())
      .typeError(t('mustbeValidDate')),
    currencyCode: Yup.string().required(t('fieldRequired')),
    limitType: Yup.string().when('bonusType', {
      is: BonusVal.compensationBonus,
      then: Yup.string().required(t('fieldRequired'))
    }),
    slotCatBonusPercent: Yup.string().when('bonusType', {
      is: BonusVal.paybackBonus,
      then: Yup.string().required(t('fieldRequired'))
    }),
    liveCatBonusPercent: Yup.string().when('bonusType', {
      is: BonusVal.paybackBonus,
      then: Yup.string().required(t('fieldRequired'))
    }),
    customLimit: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.compensationBonus,
        then: Yup.number()
          .max(99999999, t('customLimitLessThan8digit'))
          .required(t('fieldRequired'))
      }),
    reoccuringDepositRate: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.reOccuringDepositBonus,
        then: Yup
          .number()
          .positive()
          .min(0, t('minimumValue0'))
          .max(100, t('maximumValue100'))
          .required(t('fieldRequired'))
      }),
    withdrawalRate: Yup.number().transform((value) => Number.isNaN(value) ? null : value).when('bonusType', {
      is: BonusVal.reOccuringDepositBonus,
      then: Yup
        .number()
        .positive()
        .min(0, t('minimumValue0'))
        .max(100, t('maximumValue100'))
        .required(t('fieldRequired'))
    }),
    compensationTarget: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.compensationBonus,
        then: Yup.number().required(t('fieldRequired'))
      }),
    maxBonusLimit: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .max(99999999, t('customLimitLessThan8digit'))
      .required(t('fieldRequired'))
      .default(1)
      .when('customLimit', (customLimit, schema) =>
        customLimit ? schema.moreThan(customLimit, t('maxBonusLimitGreaterThan')) : schema
      ),
    bonusPercent: Yup.number().when('bonusType', {
      is: (val) => val !== BonusVal.paybackBonus,
      then: Yup
        .number()
        .positive()
        .max(99, t('maximumValue99'))
        .transform((value) => Number.isNaN(value) ? null : value)
        .required(t('fieldRequired')).default(1)
    }),
    adminId: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired')),
    tenantId: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired')),
    rollingPercent: Yup.number()
      .positive()
      .max(999, t('minPercentageShouldBeLesserThan1000'))
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
  })
}
export const updateLimitSchema = (t, data) => {
  return Yup.object().shape({
    bonusType: Yup.string().required(t('fieldRequired')),
    limitType: Yup.string().when('bonusType', {
      is: BonusVal.compensationBonus,
      then: Yup.string().required(t('fieldRequired'))
    }),
    customLimit: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.compensationBonus,
        then: Yup.number().positive()
          .min(0, t('minimumValue0'))
          .max(99999999, t('customLimitLessThan8digit'))
          .required(t('fieldRequired'))
          .max(data?.customLimit || 0, `${t('maxValueIs')} ${data?.customLimit}`)
      }),
    reoccuringDepositRate: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.reOccuringDepositBonus,
        then: Yup.number().positive().min(0, t('minimumValue0'))
          .required(t('fieldRequired'))
          .max(data?.reoccuringDepositRate || 0, `${t('maxValueIs')} ${data?.reoccuringDepositRate}`)
      }),
    withdrawalRate: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.reOccuringDepositBonus,
        then: Yup.number()
          .positive().min(0, t('minimumValue0'))
          .required(t('fieldRequired'))
          .max(data?.withdrawalRate || 0, `${t('maxValueIs')} ${data?.withdrawalRate}`)
      }),
    minsForBonusGiven: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.compensationBonus,
        then: Yup.number()
          .required(t('fieldRequired'))
          .max(data?.minsForBonusGiven || 0, `${t('maxValueIs')} ${data?.minsForBonusGiven}`)
      }),
    compensationTarget: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.compensationBonus,
        then: Yup.number()
          .required(t('fieldRequired'))
          .max(data?.compensationTarget || 0, `${t('maxValueIs')} ${data?.compensationTarget}`)
      }),
    maxBonusLimit: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .default(1)
      .max(99999999, t('customLimitLessThan8digit'))
      .max(data?.maxBonusLimit || 0, `${t('maxValueIs')} ${data?.maxBonusLimit}`)
      .when('customLimit', (customLimit, schema) =>
        customLimit ? schema.moreThan(customLimit, t('maxBonusLimitGreaterThan')) : schema
      ),
    slotCatBonusPercent: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.paybackBonus,
        then: Yup.number().positive().min(0, t('minimumValue0'))
          .required(t('fieldRequired'))
          .max(data?.slotCatBonusPercent || 0, `${t('maxValueIs')} ${data?.slotCatBonusPercent}`)
      }),
    liveCatBonusPercent: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: BonusVal.paybackBonus,
        then: Yup.number().positive().min(0, t('minimumValue0'))
          .required(t('fieldRequired'))
          .max(data?.liveCatBonusPercent || 0, `${t('maxValueIs')} ${data?.liveCatBonusPercent}`)
      }),

    bonusPercent: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: (val) => val !== BonusVal.paybackBonus,
        then: Yup.number()
          .transform((value) => Number.isNaN(value) ? null : value)
          .max(99, t('maximumValue99'))
          .required(t('fieldRequired'))
          .default(1)
          .positive().min(0, t('minimumValue0'))
          .max(data?.bonusPercent || 0, `${t('maxValueIs')} ${data?.bonusPercent}`)
      })
  })
}
