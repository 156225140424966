// eslint-disable
import React, { useEffect, useState } from 'react'
import {
  Col, Table
} from '@themesberg/react-bootstrap'
import { useLocation } from 'react-router-dom'
import useHotCombo from './hooks/useHotCombo'
import useCreateHotCombo from './hooks/useCreateHotCombo'
import { outcomeHeaders } from './constants'
import { outcomeTypeFormatter } from '../../utils/outcomeFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from '../Toast'
import CreateHotCombo from './CreateHotCombo'
import { Button, Form, Spinner } from 'react-bootstrap'

const ViewAndEditHotCombo = () => {
  const location = useLocation()
  const viewComboData = location?.state?.comboDetails
  const hotComboId = location?.state?.hotComboId
  const startDate = location?.state?.startDate
  const isEdit = !!location?.search?.includes('isEdit')

  const { t } = useHotCombo()
  const {
    navigate, loading, marketOutcomes, selectedOutcomes,
    setSelectedOutcomes,
    setStartDate,
    handleUpdateHotCombo,
    updateLoading,
    setComboName,
    comboName,
    setSelectedMarkets,
    selectedMarkets
  } = useCreateHotCombo()
  const [formatedOutcomes, setFormattedOutcomes] = useState([])
  const [editmarketId, setEditMarketId] = useState('')
  const [listOfMarkets, setListOfMarkets] = useState([])
  const [addMore, setAddMore] = useState(false)
  useEffect(() => {
    const tempOutcomes = {}
    let tempFormatedOutcomes = []
    viewComboData?.forEach((item) => {
      const team1 = item?.teams?.find((item) => item?.type === 'home')
      const team2 = item?.teams?.find((item) => item?.type === 'away')
      item?.matchMarket?.forEach((otData) => {
        tempOutcomes[otData?.matchMarketId] = otData?.outcome?.[0]?.masterOutcomeId
        const outcomeWithMarket = otData?.outcome?.map((outcome) => ({ ...outcome, ...otData?.MasterMarkets?.[0], team1, team2, matchMarketId: otData?.matchMarketId }))
        tempFormatedOutcomes = [...tempFormatedOutcomes, ...outcomeWithMarket]
      })
    })
    setSelectedOutcomes(tempOutcomes)
    setListOfMarkets(tempFormatedOutcomes)
    setFormattedOutcomes(tempFormatedOutcomes)
    setStartDate(startDate)
  }, [viewComboData])

  const isChecked = (id, marketId) => {
    return (Number(selectedOutcomes?.[marketId]) === Number(id))
  }
  const handleSelectedOutcomes = (e, item, marketId, wholeMarketDetail) => {
    if (!isChecked(e.target.value, marketId)) {
      setSelectedOutcomes(prev => {
        if (Object.entries(prev)?.length < 4) {
          setStartDate((prevDate) => {
            const currentTime = new Date(marketOutcomes?.matchDate)?.getTime()
            const prevTime = new Date(prevDate || new Date()?.getDate() + 30)?.getTime()
            if (prevTime > currentTime) {
              return marketOutcomes?.matchDate
            } else {
              return prevDate || marketOutcomes?.matchDate
            }
          })
          if (isEdit) {
            const tempList = [...listOfMarkets]?.filter(item => item?.matchMarketId !== marketId)
            setListOfMarkets([...tempList, wholeMarketDetail])
          } else {
            setListOfMarkets(prev => [...prev, wholeMarketDetail])
          }
          return { ...prev, [marketId]: item }
        } else {
          toast(t('outcomeError'), 'error')
          return { ...prev }
        }
      })

      setSelectedMarkets(editmarketId)
    } else {
      const prevOutcomes = { ...selectedOutcomes }
      delete prevOutcomes[marketId]
      setSelectedOutcomes(prevOutcomes)
      setListOfMarkets(prev => prev?.filter(item => item?.matchMarketId !== marketId))
      setSelectedMarkets(selectedMarkets?.filter((item) => item !== editmarketId))
    }
  }

  return (
    <div className='p-3'>
      <Col className='mb-4'>
        <h3> <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer me-2'
          onClick={() => navigate(-1)}
             />
          {isEdit ? t('editHotCombo') : t('hotComboDetails')}

        </h3>
      </Col>
      {isEdit && (
        <div className=''>
          <Col sm={6} className='mb-2'>
            <div className='d-flex flex-column gap-2'>
              <div>
                <Form.Label>
                  {t('nameOfHotcombo')}<span className='text-danger'> *</span>
                </Form.Label>
                <div className='d-flex flex-column gap-1'>
                  <Form.Control
                    type='text'
                    name='name'
                    placeholder={t('enterName')}
                    value={comboName}
                    onChange={(e) => setComboName(e.target.value)}
                  />
                </div>
              </div>

            </div>
          </Col>
        </div>
      )}

      <Table bordered striped responsive hover size='sm' className='text-center '>
        <thead className='thead-dark'>
          <tr>
            {outcomeHeaders.map((h, idx) => (
              <th key={idx}>
                {t(h.label)}{' '}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {(!loading && formatedOutcomes?.length > 0) &&
            formatedOutcomes?.map(
              (item, index) => {
                const selection = outcomeTypeFormatter({
                  outcome: item,
                  homeTeamName: item?.team1?.team.teamName,
                  awayTeamName: item?.team2?.team.teamName,
                  providerMarketId: item?.providerMarketId,
                  feedProvider: 1,
                  language: 'en',
                  subTypeId: ''
                })
                return (
                  <tr key={item?.masterOutcomeId}>
                    <td className='py-2'>

                      <div className='text-center d-flex align-items-center justify-content-center '>

                        {(isEdit) && (
                          <label className='me-2 d-inline-flex' htmlFor={item?.masterOutcomeId}>
                            <input
                              onChange={(e) =>
                                handleSelectedOutcomes(e, item?.masterOutcomeId, item?.matchMarketId, item)}
                              style={{ width: 20, height: 20 }}
                              checked={!!isChecked(item?.masterOutcomeId, item?.matchMarketId)}
                              value={item?.masterOutcomeId}
                              type='checkbox'
                              id={item?.masterOutcomeId}
                            />
                          </label>
                        )}
                        {(index + 1)}
                      </div>

                    </td>
                    <td className='py-2'>{item?.team1?.team.teamName} </td>
                    <td className='py-2'>{item?.team2?.team.teamName}</td>
                    <td className='py-2'>{selection} </td>
                    <td className='py-2'>{item?.odd} </td>

                  </tr>
                )
              }
            )}

        </tbody>

      </Table>

      {(!loading && formatedOutcomes?.length === 0) && (
        <div className='text-center text-danger w-100'>
          {t('noDataFound')}
        </div>
      )}
      {listOfMarkets?.length <= 4 && isEdit && (
        <div className='my-2'>
          <Button
            type='submit'
            size='sm'
            variant='outline-success'
            onClick={() => setAddMore(prev => !prev)}
            disabled={updateLoading}
            style={{ minWidth: 100 }}
          >
            {t('clickToAddMoreEvents')}
          </Button>
        </div>
      )}

      {addMore && isEdit &&
        <>
          <CreateHotCombo setEditMarketId={setEditMarketId} editSelectedMarkets={selectedMarkets} isEdit handleEditSelectedOutcomes={handleSelectedOutcomes} isEditSelectedCheck={isChecked} />
          <>
            <h6 className='py-3 mb-0 pt-4'>{t('comboEventsList')}</h6>
            <Table bordered striped responsive hover size='sm' className='text-center '>
              <tbody>
                {(listOfMarkets?.length > 0) &&
                listOfMarkets?.map(
                  (item, index) => {
                    const selection = outcomeTypeFormatter({
                      outcome: item,
                      homeTeamName: item?.team1?.team.teamName,
                      awayTeamName: item?.team2?.team.teamName,
                      providerMarketId: item?.providerMarketId,
                      feedProvider: 1,
                      language: 'en',
                      subTypeId: ''
                    })
                    return (
                      <tr key={item?.masterOutcomeId}>
                        <td className='py-2'>

                          <div className='text-center d-flex align-items-center justify-content-center '>
                            {(index + 1)}
                          </div>

                        </td>
                        <td className='py-2'>{item?.team1?.team.teamName} </td>
                        <td className='py-2'>{item?.team2?.team.teamName}</td>
                        <td className='py-2'>{selection} </td>
                        <td className='py-2'>{item?.odd} </td>

                      </tr>
                    )
                  }
                )}

              </tbody>

            </Table>
          </>
        </>}
      {isEdit && (
        <>
          <Button
            type='submit'
            variant='outline-success'
            onClick={() => handleUpdateHotCombo({ hotComboId, comboName })}
            className='ml-2 mt-2'
            disabled={updateLoading}
            style={{ minWidth: 100 }}
          >
            {updateLoading
              ? (
                <Spinner className='ms-2' size='sm' animation='border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
                )
              : t('update')}
          </Button>
        </>
      )}
    </div>
  )
}

export default ViewAndEditHotCombo
