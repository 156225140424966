import React from 'react'
import useCasinoReporting from '../CasinoReporting/hooks/useCasinoReporting'
import { Button, Col, Form } from '@themesberg/react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import UserReportingTable from '../../../components/ReportingTable/UserReportingTable'
import ClientFilter from '../../../components/ClientFilter'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import { DEPOSIT_TYPE_ARR } from '../../../utils/constants'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'

const UserReporting = () => {
  const {
    formData,
    setFormData,
    UserReportingData,
    totalPagesUser,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    state,
    setState,
    handleCsvDownloadUser,
    initialDate,
    inputRef,
    loading,
    t,
    selectedClient,
    setSelectedClient,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = useCasinoReporting()
  return (
    <div className='p-3'>
      <Col>
        <h3>{t('userReporting')}</h3>
      </Col>
      <div className='d-flex align-items-center flex-wrap'>
        <Col xs='auto' className='d-flex mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchName')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <Form.Label
            style={{
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('transactionType')}
          </Form.Label>
          <Form.Select
            value={formData?.actionType}
            onChange={(e) => {
              setFormData({ ...formData, actionType: e.target.value })
              setPage(1)
            }}
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {DEPOSIT_TYPE_ARR.map((transactionRequest) =>
              (
                <option
                  key={transactionRequest.value}
                  value={transactionRequest.value}
                >
                  {t(transactionRequest.value)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <AffiliateAgentFilter
            selectedClient={selectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
          />
        </Col>
        <Col xs='auto'>
          <Trigger message={t('downloadAsCsv')}>
            <Button
              variant='outline-success'
              className='m-1'
              size='sm'
              disabled={UserReportingData?.count === 0}
              onClick={() => {
                handleCsvDownloadUser()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              className='m-1'
              onClick={() => {
                inputRef.current.value = ''
                setFormData(initalWalletState)
                setState(initialDate)
                setSelectedClient('')
                setAgent('')
                setAffiliate('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </div>

      <UserReportingTable
        tableData={UserReportingData}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPagesUser}
        loading={loading}
        t={t}
      />
    </div>
  )
}

export default UserReporting
