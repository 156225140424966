import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getSuperAdminCasinoTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import { getAllCasinoProvidersStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import moment from 'moment'
import { getUserGGRStart, updateSuperAdminBetNoteStart } from '../../../../store/redux-slices/adminUser'

const initialState = {
  categoryId: '',
  subCategoryId: '',
  providerId: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useCasinoTransactionsList = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [contentFilter, setContentFilter] = useState(initialState)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [showBannedGameAmount, setShowBannedGameAmount] = useState(false)
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [individualUserData, setIndividualUserData] = useState(null)
  const [nonWithdrawableRolled, setNonWithdrawableRolled] = useState(false)
  const [bonusRolled, setBonusRolled] = useState(false)
  const [promotionRolled, setPromotionRolledRolled] = useState(false)

  const [over, setOver] = useState({
    bonusAmount: false,
    cashAmount: false,
    Id: false
  })
  const { allCurrencies } = useSelector((state) => state.currencies)
  const {
    gameCategory,
    gameSubCategory,
    casinoProvidersData
  } = useSelector((state) => state.superAdminCasino)

  const { loading, casinoTransactions: casinoBetTransaction, bannedGames } = useSelector((state) => state.superAdminTransactions)

  const { userData } = useSelector((state) => state.fetch)
  const { ggrData } = useSelector((state) => state.adminUser)

  const casinoTransactions = (typeof bannedGames?.totalWageringAmount === 'number' && !loading && showBannedGameAmount)
    ? bannedGames?.casinoDetail
    : casinoBetTransaction

  const totalPages = Math.ceil(casinoTransactions?.count / limit)

  const [state, setState] = useState(initialDate)
  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameSubCategoryStart({ limit: '', pageNo: '' }))
  }, [])

  useEffect(() => {
    dispatch(getSuperAdminCasinoTransactionsStart({
      limit,
      pageNo: page,
      email: '',
      startDate: moment(state[0].startDate).toISOString() || '',
      endDate: moment(state[0].endDate).toISOString() || '',
      currencyCode: selectedCurrency,
      transactionType: selectedAction,
      tenantId: '',
      adminId: '',
      status,
      userId,
      contentFilter,
      isUserV2: userData?.isV2User,
      nonWithdrawableRolled,
      bonusRolled,
      promotionRolled
    }))
    dispatch(getUserGGRStart({
      startDate: moment(state[0].startDate).toISOString() || '',
      endDate: moment(state[0].endDate).toISOString() || '',
      tenantId: '',
      userId,
      contentFilter
    }))
  }, [limit, page, selectedCurrency, promotionRolled, nonWithdrawableRolled, bonusRolled, selectedAction, state, status, userId, contentFilter])

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/superadmin/casino/transactions?csvDownload=true&startDate=${moment(state[0].startDate).toISOString() || ''}&endDate=${moment(state[0].endDate).toISOString() || ''}&currencyCode=${selectedCurrency}&transactionType=${selectedAction}&tenantId=${showBannedGameAmount ? userData?.tenantId : ''}&status=${status}&email=&adminId=&token=${getLoginToken()}&userId=${userId}&categoryId=${contentFilter?.categoryId || ''}&subCategoryId=${contentFilter?.subCategoryId || ''}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&providerId=${contentFilter?.providerId || ''}&nonWithdrawable=${contentFilter.nonWithdrawable || ''}&bonus=${contentFilter.bonus || ''}&promotionCash=${contentFilter.promotionCash || ''}&isV2User=${userData?.isV2User || ''}`

  const handleSubmit = (data) => {
    dispatch(updateSuperAdminBetNoteStart({
      transactionId: individualUserData.casinoTransactionId,
      note: data.description,
      userId
    }))
  }
  return {
    allCurrencies,
    setSelectedCurrency,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    state,
    setState,
    casinoTransactions,
    loading,
    status,
    setStatus,
    getCsvDownloadUrl,
    over,
    setOver,
    bannedGames,
    setShowBannedGameAmount,
    showBannedGameAmount,
    userData,
    contentFilter,
    setContentFilter,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    initialState,
    initialDate,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleSubmit,
    nonWithdrawableRolled,
    setNonWithdrawableRolled,
    bonusRolled,
    setBonusRolled,
    promotionRolled,
    setPromotionRolledRolled,
    ggrData
  }
}

export default useCasinoTransactionsList
