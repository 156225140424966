import * as Yup from 'yup'

export const withdrawalSchema = (t) => {
  Yup.object().shape({
    amount: Yup
      .string()
      .matches(/^(1|[1-9][0-9]{0,9})$/, t('mustBeRealAmount'))
      .required(t('amountRequired')),
    currency: Yup
      .string()
      .required(t('currencyRequired'))
  })
}
