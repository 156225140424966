import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getCasinoReportingDataStart, getUserReportingDataStart } from '../../../../store/redux-slices/admins'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import { getAllCasinoProvidersStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { useTranslation } from 'react-i18next'

const initalWalletState = {
  search: '',
  actionType: '',
  transactionType: '',
  categoryId: '',
  subCategoryId: '',
  providerId: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useCasinoReporting = (isV2) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { casinoReportingData, UserReportingData, loading } = useSelector((state) => state.admins)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const totalPages = Math.ceil(casinoReportingData?.count / limit)
  const totalPagesUser = Math.ceil(UserReportingData?.count / limit)
  const [state, setState] = useState(initialDate)
  const [nonWithdrawableRolled, setNonWithdrawableRolled] = useState(false)
  const [bonusRolled, setBonusRolled] = useState(false)
  const [promotionRolled, setPromotionRolledRolled] = useState(false)
  const {
    gameCategory,
    gameSubCategory,
    casinoProvidersData
  } = useSelector((state) => state.superAdminCasino)
  const { pathname } = useLocation()

  useEffect(() => {
    pathname.split('/')[2] === 'casino-reporting' || pathname.split('/')[2] === 'v2-casino-reporting'
      ? (dispatch(getCasinoReportingDataStart({
          form: {
            ...formData,
            from: moment(state[0].startDate).toISOString(),
            to: moment(state[0].endDate).toISOString(),
            limit,
            pageNo: page,
            adminId: selectedClient,
            affiliateId: affiliate,
            agentId: agent,
            isV2: isV2,
            nonWithdrawableRolled,
            bonusRolled,
            promotionRolled
          }
        })))
      : (
          dispatch(getUserReportingDataStart({
            form: {
              ...formData,
              from: moment(state[0].startDate).toISOString(),
              to: moment(state[0].endDate).toISOString(),
              limit,
              pageNo: page,
              adminId: selectedClient,
              affiliateId: affiliate,
              agentId: agent
            }
          })))
  }, [formData, limit, page, selectedClient, state, agent, affiliate, isV2, nonWithdrawableRolled, bonusRolled, promotionRolled])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '' }))
    dispatch(getSuperAdminGameSubCategoryStart({ limit: '', pageNo: '' }))
  }, [])

  const handleCsvDownloadCasino = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/casino-transaction?adminId=${selectedClient || ''}&actionType=${formData.actionType || ''}&search=${formData.search || ''}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&categoryId=${formData.categoryId}&subCategoryId=${formData.subCategoryId}&providerId=${formData.providerId}&csvDownload=true&token=${getLoginToken()}&affiliateId=${affiliate || ''}&agentId=${agent || ''}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&nonWithdrawable=${formData.nonWithdrawable || ''}&bonus=${formData.bonus || ''}&promotionCash=${formData.promotionCash || ''}&isV2User=${isV2 || ''}`)
  }
  const handleCsvDownloadUser = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/user-transaction?adminId=${selectedClient || ''}&actionType=${formData?.actionType || ''}&search=${formData?.search || ''}&affiliateId=${affiliate || ''}&agentId=${agent || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    formData,
    setFormData,
    totalPages,
    totalPagesUser,
    page,
    limit,
    setPage,
    casinoReportingData,
    UserReportingData,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    state,
    setState,
    handleCsvDownloadCasino,
    handleCsvDownloadUser,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    inputRef,
    initialDate,
    loading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    nonWithdrawableRolled,
    setNonWithdrawableRolled,
    bonusRolled,
    setBonusRolled,
    promotionRolled,
    setPromotionRolledRolled
  }
}

export default useCasinoReporting
