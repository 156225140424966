import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { Button, Col, Row, Form as BForm, Spinner } from 'react-bootstrap'
import { BonusVal } from '../constant'
import { updateLimitSchema } from '../schema'
import { useSelector } from 'react-redux'

const UpdateLimitForm = ({ bonusDetail, loading, t, handleUpdateYes, handleClose }) => {
  const { bonusDetail: mainBonusDetail } = useSelector((state) => state.bonus)
  return (
    <Formik
      enableReinitialize
      validateOnMount={false}
      initialValues={{
        bonusType: bonusDetail?.bonusType || '',
        addOnOddBonus: bonusDetail?.addOnOddBonus || '',
        maxBonusLimit: bonusDetail?.maxBonusLimit || '',
        bonusPercent: bonusDetail?.bonusPercent || ''
      }}
      validationSchema={updateLimitSchema(t, mainBonusDetail)}
      onSubmit={(values, formActions) => {
        handleUpdateYes(values, formActions)
      }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        validateField,
        setValues
      }) => {
        const isAddOnOddBonus = bonusDetail?.bonusType === BonusVal.winningBonus
        return (
          <Form>
            <Row>
              {isAddOnOddBonus
                ? (
                  <Col sm={12} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('addOnBonus')}
                      </BForm.Label>
                      <BForm.Control
                        name='addOnOddBonus'
                        type='number'
                        disabled
                        value={values.addOnOddBonus}
                      />
                    </div>

                  </Col>
                  )
                : (
                  <Col sm={12} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('bonus_percentage')}
                      </BForm.Label>
                      <BForm.Control
                        name='bonusPercent'
                        type='number'
                        min={1}
                        placeholder={t('enterValueInPercent')}
                        value={values.bonusPercent}
                        onInput={handleChange}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component='div'
                        name='bonusPercent'
                        className='text-danger mt-1'
                      />
                    </div>

                  </Col>
                  )}
              <Col sm={12} className='mb-2'>
                <div className='d-flex flex-column gap-1'>
                  <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                    {t('maximum_bonus_limit')}
                  </BForm.Label>
                  <BForm.Control
                    name='maxBonusLimit'
                    type='number'
                    min={1}
                    placeholder={t('enterValue')}
                    value={values.maxBonusLimit}
                    onInput={handleChange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    component='div'
                    name='maxBonusLimit'
                    className='text-danger mt-1'
                  />
                </div>

              </Col>

              <Col sm={12}>
                <div className='mt-4 d-flex align-align-items-center gap-4'>
                  {handleClose && (
                    <Button
                      variant='outline-warning'
                      className='ml-2'
                      onClick={handleClose}
                    >
                      {t('cancel')}
                    </Button>
                  )}
                  <Button
                    type='submit'
                    className='ml-2'
                    variant='outline-success'
                  >
                    {t('submit')} {loading && <Spinner size='sm' className='ms-1' />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateLimitForm
