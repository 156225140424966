import {
  faCheckSquare,
  faEdit,
  faEye,
  faGamepad,
  faUsers,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import ConfirmationModal from '../ConfirmationModal'
import Trigger from '../OverlayTrigger'
import PaginationComponent from '../Pagination'
import useCheckPermission from '../../utils/checkPermission'
import useDeleteBonus from './useDeleteBonus'
import DeleteModal from './DeleteModal'
import moment from 'moment'

export default ({
  bonusList,
  limit,
  setLimit,
  page,
  setPage,
  totalPages,
  tableHeaders,
  show,
  setShow,
  handleYes,
  active,
  isTenant = false,
  handleShow,
  navigate,
  name,
  loading,
  showDeleteModal,
  setShowDeleteModal,
  t
}) => {
  const { isHidden } = useCheckPermission()
  const {
    handleDeleteYes,
    handleClose,
    deleteName,
    showText
  } = useDeleteBonus(isTenant, setShowDeleteModal)
  const isExpired = (validTo) => {
    return moment(validTo).isBefore(moment())
  }
  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>{t(h.label)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {bonusList && !loading &&
            bonusList?.rows?.map(
              ({
                bonusId,
                promotionTitle,
                tenant,
                tenantId,
                isActive,
                bonusType,
                adminUser,
                validFrom,
                validTo,
                isSticky
              }, index) => {
                return (
                  <tr key={`bonus-list ${bonusId}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>
                      {adminUser?.firstName}{' '}
                      {adminUser?.lastName}
                    </td>
                    <td>{tenant?.name}</td>
                    <td>{bonusType || ''}</td>
                    <td>{moment(validTo).format('MM-DD-YYYY HH:mm')}</td>
                    <td>
                      {isExpired(validTo) ? t('yes') : t('no')}
                    </td>
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inActive')}
                    </td>
                    <td className='text-left'>
                      <ButtonGroup>
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            disabled={isExpired(validTo)}
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus/details/${bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      {!isTenant && (
                        <ButtonGroup>
                          <Trigger message={t('edit')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              disabled={bonusType === 'unique_number_bonus' || isExpired(validTo)}
                              hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                              onClick={() =>
                                navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus/edit/${bonusId}`)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>
                        </ButtonGroup>
                      )}
                      {!isTenant
                        ? !isActive
                            ? (
                              <Trigger message={t('setStatusActive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  disabled={isExpired(validTo)}
                                  hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                  onClick={() => handleShow(bonusId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>
                              )
                            : (
                              <Trigger message={t('setStatusInactive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  disabled={isExpired(validTo)}
                                  hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                  onClick={() => handleShow(bonusId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                              )
                        : null}
                      <ButtonGroup>
                        <Trigger message={t('viewUser')}>
                          <Button
                            className='m-1'
                            size='sm'
                            disabled={isExpired(validTo)}
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus-users/${bonusId}/${tenantId}`)}
                          >
                            <FontAwesomeIcon icon={faUsers} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      <ButtonGroup>
                        <Trigger message={t('viewGames')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            disabled={bonusType === 'hot_time_bonus' || isExpired(validTo)}
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus-games/${bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faGamepad} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )}

          {bonusList?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {bonusList?.count > 0 && !loading && (
        <PaginationComponent
          page={bonusList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={bonusList?.count}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}

      {showDeleteModal &&
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          handleDeleteYes={handleDeleteYes}
          name={deleteName}
          handleClose={handleClose}
          showText={showText}
          loading={loading}
        />}
    </>
  )
}
