import * as Yup from 'yup'

export const updateProviderSchema = (
  depositImage,
  withdrawImage,
  supportsDeposit,
  supportsWithdrawal,
  logoCheck,
  keys) => Yup.object().shape({
  reportName: Yup.string().required('Reporting Name Required'),
  depositImage: supportsDeposit && (!depositImage
    ? Yup.mixed().required('A file is required').test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()
    : Yup.mixed().test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()),
  withdrawImage: (supportsWithdrawal && logoCheck === 'false') && (!withdrawImage
    ? Yup.mixed().required('A file is required').test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()
    : Yup.mixed().test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()),
  depositCount: Yup.number().min(0, 'Deposit Count be Greater than or equal to 0.').required('Deposit Count Required'),
  kycDepositCount: Yup.number().min(0, 'KYC Deposit Count be Greater than or equal to 0.').required('KYC Deposit Count Required'),
  amountKeys: amtKeysValidate({ keys }),
  mdr: Yup.number().min(0, 'MDR % must be Greater than or equal to 0.').required('MDR % Required'),
  feeSuccessTx: Yup.number().min(0, 'Amount must be Greater than or equal to 0.').required('Amount Required'),
  feeDeclinedTx: Yup.number().min(0, 'Amount must be Greater than or equal to 0.').required('Amount Required'),
  hideDepositCount: Yup.number().test('isSmall',
    'Count Must Be Greater Than Deposit Count',
    (value, context) => {
      if (value !== 0 && value <= context.parent.depositCount) {
        return false
      } else {
        return true
      }
    }).min(0, 'Deposit Count to hide method be Greater than or equal to 0.').required('Count Required')
})

const amtKeysValidate = ({ keys }) => {
  const validationObject = {}
  for (const file in keys) {
    if (keys[file] !== 'Other') {
      validationObject[file] = Yup.number().typeError('Only Numbers Allowed').min(0, 'Minimum Amount 0(Zero) is Required').required('Value Required.')
    }
  }
  return Yup.object(validationObject)
}
