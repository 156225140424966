export const BonusVal = {
  hotTimeBonus: 'HOT-TIME-BONUS',
  compensationBonus: 'COMPENSATION-BONUS',
  reOccuringDepositBonus: 'RE-OCCURING-DEPOSIT-BONUS',
  firstDepositBonus: 'FIRST-DEPOSIT-BONUS',
  uniqueTicketBonus: 'UNIQUE-TICKET-BONUS',
  paybackBonus: 'PAYBACK-BONUS',
  rackbackBonus: 'RACKBACK-BONUS'
}
export const BONUSES = Object.keys(BonusVal).map((key) => ({
  label: key, value: BonusVal[key]
}))
export const LIMITTYPE = [
  { label: 'DAILY', value: 'DAILY' },
  { label: 'WEEKLY', value: 'WEEKLY' },
  { label: 'MONTHLY', value: 'MONTHLY' }

]

export const tableHeaders = [
  { label: 'id', value: 'id' },
  { label: 'ownerName', value: 'ownerName' },
  { label: 'bonusType', value: 'bonusType' },
  { label: 'validTill', value: 'validTo' },
  { label: 'isExpired', value: 'isExpired' },
  { label: 'status', value: 'isActive' },
  { label: 'action', value: 'action' }
]
