import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  faArrowsAlt
  , faArrowCircleLeft
} from '@fortawesome/free-solid-svg-icons'
import { getSportsListingReorderStart, reorderSportsStart } from '../../store/redux-slices/superAdminSportsBook'
import Preloader from '../../components/Preloader'
import { useTranslation } from 'react-i18next'

const ReorderSports = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = !window.location.href.match(/super/g)
  const { loading, reorderSportsList } = useSelector(state => state.superAdminSportsBook)

  useEffect(() => {
    dispatch(getSportsListingReorderStart({ isTenant: isTenant }))
  }, [])

  const { t } = useTranslation()
  const [state, setState] = useState([])

  useEffect(() => {
    setState(reorderSportsList?.rows)
  }, [reorderSportsList])

  const reorder = (subCategories, startIndex, endIndex) => {
    const result = Array.from(subCategories)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const tempData = reorder(
      state,
      result.source.index,
      result.destination.index
    )

    setState(tempData)

    let startIndex = result.source.index
    let endIndex = result.destination.index

    if (endIndex < startIndex) {
      [startIndex, endIndex] = [endIndex, startIndex]
    }

    const formData = isTenant
      ? tempData
          ?.slice(startIndex, endIndex + 1)
          .map((item, index) => {
            return {
              masterSportId: item?.masterSportId,
              ordering: startIndex + index + 1,
              providerSportId: item?.providerSportId
            }
          })
      : tempData
        ?.slice(startIndex, endIndex + 1)
        .map((item, index) => {
          return {
            masterSportId: item?.masterSportId,
            ordering: startIndex + index + 1,
            providerSportId: item?.providerSportId
          }
        })
    dispatch(reorderSportsStart({ data: { sports: formData }, isTenant: isTenant }))
  }

  return (
    <>
      <div className='py-2'>
        <h3>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className='cursor-pointer px-1'
            onClick={() => navigate(-1)}
          />

          {t('reorderSports')}

        </h3>
        <p className='ps-1' style={{ fontSize: '15px' }}>{t('reorderNote')}</p>

      </div>
      {
      (
        !loading
          ? (
              state?.length > 0
                ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list'>
                      {(provided) => (
                        <div className='d-flex flex-column gap-2' ref={provided.innerRef} {...provided.droppableProps}>
                          {state?.length > 0 &&
                          state?.map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={`id-${item.masterSportId}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className='d-flex p-3 rounded-2 border'>
                                    <div>{item?.masterSportId}</div>
                                    <div className='ms-3'>{item?.sportName}</div>
                                    <div className='ms-auto'><FontAwesomeIcon icon={faArrowsAlt} /></div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  )
                : (
                  <p className='text-center text-danger mt-3'> No Data Found </p>
                  ))
          : <Preloader />
      )
     }
    </>
  )
}

export default ReorderSports
