import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getVoucherStart, updateVoucherStatusStart } from '../../../../store/redux-slices/voucher'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import { debounce } from 'lodash'
import moment from 'moment'

const useVoucher = ({ isTenant, isGiftCard, isUniqueCodeBonus }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const initialDate = [
    {
      startDate: '',
      endDate: '',
      key: 'selection'
    }
  ]
  const { t } = useTranslation()
  const { voucherData, loading } = useSelector((state) => state.voucher)
  const [benefitType, setBenefitType] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('')
  const [voucherId, setVoucherId] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [name, setName] = useState()
  const [status, setStatus] = useState('')
  const totalPages = Math.ceil(voucherData?.count / limit)
  const inputRef = useRef()
  const [state, setState] = useState(initialDate)
  const [search, setSearch] = useState('')
  const [refetch, setRefetch] = useState(false)
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  const handleShow = (id, active, myName) => {
    setVoucherId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }
  const handleYes = () => {
    dispatch(
      updateVoucherStatusStart({
        data: {
          isActive: active,
          voucherId
        },
        isUniqueCodeBonus,
        handleClose: () => {
          setRefetch(prev => !prev)
        }
      })
    )
    setShow(false)
  }
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  useEffect(() => {
    dispatch(getVoucherStart({
      tenantId: selectedClient,
      limit,
      pageNo: page,
      search: search,
      startDate: moment(state[0].startDate).toISOString() || '',
      endDate: moment(state[0].endDate).toISOString() || '',
      benefitType: benefitType,
      isActive: status,
      isGiftCard: !!isGiftCard,
      ...(isUniqueCodeBonus && { isUniqueCodeBonus: isUniqueCodeBonus }),
      isTenant
    }))
  }, [page, selectedClient, search, benefitType, state, limit, status, isGiftCard, isUniqueCodeBonus, refetch])

  const handleCsvDownload = () =>
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/voucher?limit=${limit}&pageNo=${page}&search=${search || ''}&tenantId=${selectedClient || ''}&startDate=${moment(state[0].startDate).toISOString() || ''}&endDate=${moment(state[0].endDate).toISOString() || ''}&benefitType=${benefitType || ''}&isActive=${status || ''}&csvDownload=true&token=${getLoginToken()}&isGiftCard=${isGiftCard}&isUniqueCodeBonus=${isUniqueCodeBonus}`)

  return {
    navigate,
    loading,
    voucherData,
    handleDebounceFn,
    debounceFn,
    inputRef,
    selectedClient,
    setSelectedClient,
    handleShow,
    handleYes,
    show,
    setShow,
    status,
    setStatus,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    state,
    setState,
    search,
    setSearch,
    initialDate,
    name,
    benefitType,
    setBenefitType,
    handleCsvDownload,
    t,
    useRef
  }
}

export default useVoucher
