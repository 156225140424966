import io from 'socket.io-client'
import { EVENT_LISTENERS } from './socketConstant'

const SOCKET_ADMIN_URL = process.env.REACT_APP_SOCKET_ADMIN_URL
const SOCKET_USER_URL = process.env.REACT_APP_SOCKET_URL

class Socket {
  initializeSocket = async (token, roomName, isAdminUrl = true) => {
    try {
      this.socket = io(`${isAdminUrl ? SOCKET_ADMIN_URL : SOCKET_USER_URL}/${roomName}`, {
        auth: {
          token: token
        },
        path: '/socket.io',
        transports: ['websocket', 'polling'],
        reconnectionAttempts: 10 // Number of times to retry login
      })

      this.socket.on(EVENT_LISTENERS.CONNECT, (data) => {
        console.log('=== socket connected ===')
      })

      this.socket.on(EVENT_LISTENERS.DISCONNECT, (data) => {
        console.log('=== socket disconnected ===')
      })

      this.socket.on(EVENT_LISTENERS.ERROR, (data) => {
        console.log('=== socket error ===', data)
      })
    } catch (error) {
      console.log('=== scoket is not inialized ===', error)
    }
  }

  emit = (event, data) => {
    if (this.socket) {
      this.socket.emit(event, data)
    }
  }

  on = (event, cb) => {
    if (this.socket) {
      this.socket.on(event, cb)
    }
  }

  off = (listenerName) => {
    if (this.socket) {
      this.socket.off(listenerName)
    }
  }
}

const bonusSocketService = new Socket()
const incorrectPasswordSocketService = new Socket()
const affiliateBonusSocketService = new Socket()
const walletRequestService = new Socket()

export {
  bonusSocketService,
  incorrectPasswordSocketService,
  affiliateBonusSocketService,
  walletRequestService

}
