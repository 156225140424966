import React from 'react'
import UpdateLimitForm from './UpdateLimitForm'
import { Card, Col, Row } from 'react-bootstrap'

const AdminPlayersTab = ({ bonusDetail, t, updateLoading, handleUpdateLimit }) => {
  return (
    <div>
      <Row>
        <Col sm={6}>
          <Card>
            <Card.Body>
              <UpdateLimitForm
                bonusDetail={bonusDetail}
                t={t}
                handleUpdateYes={handleUpdateLimit}
                handleClose={null}
                loading={updateLoading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AdminPlayersTab
