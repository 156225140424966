import * as Yup from 'yup'
export const tenantSchema = (t) => {
  return Yup.object().shape({
    domainName: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('domainName'), minNumber: 3 }))
      .max(200, t('mustBeLesserThanCharacters', { label: t('domainName'), maxNumber: 200 }))
      .required(t('required', { label: t('domainName') }))
      .matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, t('onlyAlphabetsAndSingleSpace')),

    allowedLanguages: Yup
      .array()
      .min(1, t('required', { label: t('allowedLanguages') })),

    providerIds: Yup
      .array()
      .min(1, t('required', { label: t('providers') })),

    domain: Yup.string()
      .min(1, t('minCharacters', { label: t('domain'), minNumber: 1 }))
      .max(200, t('maxCharacters', { label: t('domain'), maxNumber: 200 }))
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
        t('enterCorrectURL')
      )
      .required(t('required', { label: t('domain') })),

    allowedCurrencies: Yup.array().min(1, t('required', { label: t('allowedCurrencies') })),

    logo: Yup
      .mixed()
      .required(t('required', { label: t('logo') }))
      .test('fileSize',
        t('fileSizeLessThan1MB'), (value) => !value || (value && value.size <= 1024 * 1024))
      .test('fileFormat', t('unsupportedFormat'),
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))),

    primaryCurrency: Yup
      .string()
      .required(t('required', { label: t('primaryCurrency') })),

    withdrawalLimit: Yup
      .string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        t('enterValidAmount')
      )
      .required(t('required', { label: t('withdrawalLimit') }))
      .test('greaterThanZero', t('mustBeGreaterThanZero'), value => value ? value > 0 : ''),

    currency: Yup
      .string()
      .required(t('required', { label: t('withdrawalCurrency') })),

    ipLimit: Yup
      .number()
      .typeError(t('numberTypeIpLimit'))
      .min(1, t('minIpLimit', { minNumber: 1 }))
      .max(10, t('maxIpLimit', { maxNumber: 10 }))
      .required(t('required', { label: t('ipLimit') })),
    withdrawlLimitTime: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .min(1, `${t('requiredAndGreaterThan0')}`)
      .required(t('required', { label: t('withdrawalLimitTime') })),

    withdrawlLimitTimeUnit: Yup
      .string()
      .required(t('required', { label: t('withdrawalLimitTimeUnit') })),
    rollingPercent: Yup
      .number()
      .min(1, t('percentGreaterThanEqual', { percent: 1 }))
      .typeError(t('bonusRollingPercentNumber'))
      .required(t('required', { label: t('bonusRollingPercentage') }))
      .nullable(),
    revenuePercentage: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .min(0, `${t('valueMustBePositive')}`)
      .max(100, `${t('maximumValue100')}`)
  })
}

export const editTenantSchema = (logo, t) => {
  return Yup.object().shape({
    domainName: Yup.string()
      .max(200, t('mustBeLesserThanCharacters', { label: t('domainName'), maxNumber: 200 }))
      .required(t('required', { label: t('domainName') }))
      .matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, t('onlyAlphabetsAndSingleSpace')),

    domain: Yup.string()
      .max(200, t('maxCharacters', { label: t('domain'), maxNumber: 200 }))
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
        t('enterCorrectURL')
      )
      .required(t('required', { label: t('domain') })),

    allowedLanguages: Yup
      .array()
      .min(1, t('required', { label: t('allowedLanguages') })),

    allowedCurrencies: Yup
      .array()
      .min(1, t('required', { label: t('allowedCurrencies') })),

    primaryCurrency: Yup
      .string()
      .required(t('required', { label: t('primaryCurrency') })),

    providerIds: Yup.array().min(1, t('required', { label: t('providers') })),

    withdrawalLimit: Yup
      .string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        t('enterValidAmount')
      )
      .test('greaterThanZero', t('mustBeGreaterThanZero'), value => value ? value > 0 : ' ')
      .nullable()
      .required(t('required', { label: t('withdrawalLimit') })),

    currency: Yup
      .string()
      .nullable()
      .required(t('required', { label: t('withdrawalCurrency') })),

    logo: typeof logo !== 'string' && Yup.mixed()
      .test('fileSize',
        t('fileSizeLessThan1MB'),
        (value) => !value || (value && value.size <= 1024 * 1024))
      .test('fileFormat', t('unsupportedFormat'),
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']
          .includes(value.type))),

    rollingPercent: Yup
      .number()
      .min(1, t('percentGreaterThanEqual', { percent: 1 }))
      .typeError(t('bonusRollingPercentNumber'))
      .required(t('required', { label: t('bonusRollingPercentage') }))
      .nullable(),

    ipLimit: Yup
      .number()
      .typeError(t('numberTypeIpLimit'))
      .min(1, t('minIpLimit', { minNumber: 1 }))
      .max(10, t('maxIpLimit', { maxNumber: 10 }))
      .required(t('required', { label: t('ipLimit') })),

    incorrectPasswordLimit: Yup
      .number()
      .typeError(t('numberTypeIpLimit'))
      .min(1, t('minValue', { minNumber: 1 }))
      .max(10, `${t('maxValueIs')} 10`)
      .required(t('required', { label: t('incorrectPasswordLimit') })),

    withdrawlLimitTime: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .min(1, `${t('requiredAndGreaterThan0')}`)
      .required(t('required', { label: t('withdrawalLimitTime') })),

    withdrawlLimitTimeUnit: Yup
      .string()
      .required(t('required', { label: t('withdrawalLimitTimeUnit') })),
    revenuePercentage: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .min(0, `${t('valueMustBePositive')}`)
      .max(100, `${t('maximumValue100')}`)
  })
}
