import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSAProvidersStart } from '../../store/redux-slices/superAdminCasinoManagement'
import { getAllTAProvidersStart } from '../../store/redux-slices/tenantCasino'

const useAllProviderList = ({
  isTenantRoute,
  isAffiliate,
  isAgent
}) => {
  const dispatch = useDispatch()
  const isTenant = !window.location.href.match(/super/g)
  const { allProviders } = useSelector((state) => isTenant ? state.tenantCasino : state.superAdminCasino)

  useEffect(() => {
    if (!allProviders) {
      if (isTenant) {
        dispatch(getAllTAProvidersStart({
          isAffiliate,
          isAgent
        }))
      } else {
        dispatch(getAllSAProvidersStart({
          isAffiliate,
          isAgent
        }))
      }
    }
  }, [])

  return {
    allProviders
  }
}

export default useAllProviderList
