import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getVoucherReportStart } from '../../../store/redux-slices/voucher'
import { downloadFile } from '../../../utils/fileDownloader'
import { getLoginToken } from '../../../utils/storageUtils'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useVoucherTransactions = (isTenant, isGiftCard) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [bonusType, setBonusType] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [search, setSearch] = useState('')
  const { voucherTransactions, loading } = useSelector(state => state.voucher)

  const totalPages = Math.ceil(voucherTransactions?.count / limit)
  const handleDebouncSearch = debounce((e) => {
    setSearch(e.target.value)
    setPage(1)
  }, 1000)
  const [state, setState] = useState(initialDate)

  useEffect(() => {
    dispatch(getVoucherReportStart({
      limit,
      pageNo: page,
      startDate: moment(state[0].startDate).toISOString() || '',
      endDate: moment(state[0].endDate).toISOString() || '',
      search,
      isTenant,
      tenantId: selectedClient,
      actionType: isGiftCard ? 'GIFT-CARD-DEPOSIT' : 'VOUCHER-DEPOSIT'

    }))
  }, [limit, page, search, state, selectedClient, isGiftCard])
  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/voucher/voucher-report?limit=${limit}&pageNo=${page}&search=${search}&tenantId=${selectedClient}&startDate=${moment(state[0].startDate).toISOString() || ''}&endDate=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&actionType=${isGiftCard ? 'GIFT-CARD-DEPOSIT' : 'VOUCHER-DEPOSIT'}&token=${getLoginToken()}`)
  }
  return {
    handleCsvDownload,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    initialDate,
    setBonusType,
    bonusType,
    voucherTransactions,
    loading,
    selectedClient,
    setSelectedClient,
    inputRef,
    handleDebouncSearch,
    setSearch,
    t
  }
}

export default useVoucherTransactions
