import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTenantStart } from '../../../../store/redux-slices/tenants'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'
import { getLanguagesStart } from '../../../../store/redux-slices/languages'
import { getAllCasinoProvidersStart } from '../../../../store/redux-slices/superAdminCasinoManagement'

const useEditTenant = () => {
  const [selectedTab, setSelectedTab] = useState('tenant')
  const [depositMethods, setDepositMethods] = useState([])
  const { tenantDetails, loading } = useSelector((state) => state.tenants)
  const { allCredentialsKey } = useSelector((state) => state.tenants)
  const { tenantId, adminId } = useParams()
  const dispatch = useDispatch()
  const { adminUserDetails } = useSelector((state) => state.adminUser)
  const navigate = useNavigate()

  const [createTenantData, setCreateTenantData] = useState({
    tenantId,
    name: '',
    domain: '',
    primaryCurrency: '',
    allowedCurrencies: [],
    themeAttr: null,
    logo: '',
    code: 'jap',
    languageName: 'English',
    themeName: '',
    themeMode: '',
    themeId: '',
    primaryColor: null,
    secondaryColor: null,
    value: null,
    description: null,
    allowedLanguages: [],
    depositMethods: [],
    withdrawMethods: [],
    providerIds: []
  })

  const setMyData = () => {
    if (tenantDetails?.tenantConfig?.depositProviders) {
      const depMethod = []
      tenantDetails.tenantConfig.depositProviders.map(({ paymentProviderId }) => depMethod.push(paymentProviderId))
      setDepositMethods(depMethod)
    }

    if (tenantDetails?.name && allCredentialsKey) {
      const allKeys = allCredentialsKey.map((key) => key.name)
      const data = Object.fromEntries(allKeys.map((k) => [k, '']))
      const keys = tenantDetails.credentials.map((cred) => cred.key)
      let value = tenantDetails.credentials.map((cred) => cred.value)
      let description = tenantDetails.credentials.map(
        (cred) => cred.description
      )
      value = Object.fromEntries(keys.map((k, index) => [k, value[index]]))
      description = Object.fromEntries(
        keys.map((k, index) => [k, description[index]])
      )
      value = { ...data, ...value }
      description = { ...data, ...description }
      setCreateTenantData({
        ...createTenantData,
        name: tenantDetails?.name,
        domain: tenantDetails?.domain,
        primaryCurrency: tenantDetails?.primaryCurrency,
        allowedCurrencies: tenantDetails?.tenantConfig?.allowedCurrencies,
        allowedLanguages: tenantDetails?.tenantConfig?.allowedLanguages,
        logo: tenantDetails?.tenantConfig?.logoUrl,
        value,
        description,
        depositMethods: tenantDetails?.tenantConfig?.depositProviders,
        withdrawMethods: tenantDetails?.tenantConfig?.withdrawProviders,
        providerIds: tenantDetails?.tenantConfig?.gameProviders
      })
    }
  }

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId: adminId }))
  }, [adminId])

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '' }))
  }, [])

  useEffect(() => {
    const keys = allCredentialsKey.map((key) => key.name)
    const data = Object.fromEntries(keys.map((k) => [k, '']))
    setCreateTenantData({
      ...createTenantData,
      value: { ...data, ...createTenantData.value },
      description: { ...data, ...createTenantData.description }
    })
  }, [allCredentialsKey])

  useEffect(() => {
    dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])

  useEffect(() => {
    dispatch(getTenantStart({ tenantId }))
    setMyData() // temp- undersatnd the reason why it was commmented before
  }, [tenantId, tenantDetails && tenantDetails?.name])

  useEffect(() => {
    if (tenantDetails?.tenantId === tenantId) {
      setMyData()
    }
  })

  return {
    selectedTab,
    setSelectedTab,
    tenantDetails,
    allCredentialsKey,
    tenantId,
    createTenantData,
    setCreateTenantData,
    loading,
    adminUserDetails,
    depositMethods,
    navigate,
    setMyData
  }
}

export default useEditTenant
