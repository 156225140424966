import { createSlice } from '@reduxjs/toolkit'

const transactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    transactions: {},
    casinoTransactions: {},
    updateStatus: {},
    allTransactions: {},
    bannedGames: {},
    userWalletTransactions: [],
    updateTransactionAction: null,
    bonusTransactions: {},
    bonusTransactionsLoading: false,
    NonRequestmessage: '',
    seniorWalletTransaction: [],
    updateSeniorTransactionAction: '',
    betTransactions: {},
    sportsBookTransactions: {},
    betTransactionsLoading: false,
    cancelBetSlipLoading: {},
    dropLoading: false
  },
  reducers: {
    updateSeniorTransactionActionStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSeniorTransactionActionSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updateSeniorTransactionAction: payload
    }),
    updateSeniorTransactionActionFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSeniorWalletListStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSeniorWalletListSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      seniorWalletTransaction: payload
    }),
    getSeniorWalletListFailure: (state) => ({
      ...state,
      loading: false
    }),
    nonCashWalletSuperAdminStart: (state) => ({
      ...state,
      loading: true
    }),
    nonCashWalletSuperAdminSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      NonRequestmessage: payload
    }),
    nonCashWalletSuperAdminFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateUserWalletLimitStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserWalletLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updateTransactionAction: payload
    }),
    updateUserWalletLimitFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateUserTransactionActionStart: (state) => {
      return {
        ...state,
        loading: true
      }
    },
    updateUserTransactionActionSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        updateTransactionAction: payload
      }
    },
    updateUserTransactionActionFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllWalletTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllWalletTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userWalletTransactions: payload
    }),
    getAllWalletTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllSuperAdminTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllSuperAdminTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactions: payload
    }),
    getAllSuperAdminTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminAllTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminAllTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allTransactions: payload
    }),
    getSuperAdminAllTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminBonusTransactionsStart: (state) => ({
      ...state,
      bonusTransactionsLoading: true,
      error: null
    }),
    getSuperAdminBonusTransactionsSuccess: (state, { payload }) => ({
      ...state,
      bonusTransactionsLoading: false,
      bonusTransactions: payload
    }),
    getSuperAdminBonusTransactionsFailure: (state) => ({
      ...state,
      bonusTransactionsLoading: false
    }),
    getSuperAdminCasinoTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminCasinoTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoTransactions: payload
    }),
    getSuperAdminCasinoTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperadminTransactionStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateSuperadminTransactionStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updateStatus: payload
    }),
    updateSuperadminTransactionStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBannedGamesDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getBannedGamesDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedGames: payload
    }),
    getBannedGamesDetailsFailure: (state) => ({
      ...state,
      loading: false
    }),
    depositSuperAdminAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    depositSuperAdminAmountSuccess: (state) => ({
      ...state,
      loading: false
    }),
    depositSuperAdminAmountFailure: (state) => ({
      ...state,
      loading: false
    }),
    withdrawalSuperAdminAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    withdrawalSuperAdminAmountSuccess: (state) => ({
      ...state,
      loading: false
    }),
    withdrawalSuperAdminAmountFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminSportsTransactionStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminSportsTransactionSuccess: (state, action) => ({
      ...state,
      betTransactions: action.payload,
      loading: false
    }),
    getSuperAdminSportsTransactionFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSportsBookTransactionStart: (state) => ({
      ...state,
      loading: true
    }),
    getSportsBookTransactionSuccess: (state, action) => ({
      ...state,
      sportsBookTransactions: action.payload,
      loading: false
    }),
    getSportsBookTransactionFailure: (state) => ({
      ...state,
      loading: false
    }),
    cancelBetSlipStart: (state, action) => ({
      ...state,
      cancelBetSlipLoading: action?.payload
    }),
    cancelBetSlipSuccess: (state, action) => ({
      ...state,
      cancelBetSlipLoading: {}
    }),
    cancelBetSlipFailure: (state) => ({
      ...state,
      cancelBetSlipLoading: {}
    }),
    getAddonConfigDropDownStart: (state) => ({
      ...state,
      dropLoading: true
    }),
    getAddonConfigDropDownSuccess: (state, action) => ({
      ...state,
      addOnConfigDropDown: action.payload,
      dropLoading: false
    }),
    getAddonConfigDropDownFailure: (state) => ({
      ...state,
      dropLoading: false
    })
  }
})

export default transactionSlice.reducer

export const {
  getSeniorWalletListStart,
  getSeniorWalletListSuccess,
  getSeniorWalletListFailure,
  nonCashWalletSuperAdminStart,
  nonCashWalletSuperAdminSuccess,
  nonCashWalletSuperAdminFailure,
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  updateSuperadminTransactionStatusStart,
  updateSuperadminTransactionStatusSuccess,
  updateSuperadminTransactionStatusFailure,
  getSuperAdminAllTransactionsStart,
  getSuperAdminAllTransactionsSuccess,
  getSuperAdminAllTransactionsFailure,
  getBannedGamesDetailsStart,
  getBannedGamesDetailsSuccess,
  getBannedGamesDetailsFailure,
  getAllWalletTransactionsStart,
  getAllWalletTransactionsSuccess,
  getAllWalletTransactionsFailure,
  updateUserTransactionActionStart,
  updateUserTransactionActionSuccess,
  updateUserTransactionActionFailure,
  depositSuperAdminAmountStart,
  depositSuperAdminAmountSuccess,
  depositSuperAdminAmountFailure,
  withdrawalSuperAdminAmountStart,
  withdrawalSuperAdminAmountSuccess,
  withdrawalSuperAdminAmountFailure,
  updateUserWalletLimitStart,
  updateUserWalletLimitSuccess,
  updateUserWalletLimitFailure,
  getSuperAdminBonusTransactionsStart,
  getSuperAdminBonusTransactionsSuccess,
  getSuperAdminBonusTransactionsFailure,
  updateSeniorTransactionActionStart,
  updateSeniorTransactionActionSuccess,
  updateSeniorTransactionActionFailure,
  getSuperAdminSportsTransactionStart,
  getSuperAdminSportsTransactionSuccess,
  getSuperAdminSportsTransactionFailure,
  getSportsBookTransactionFailure,
  getSportsBookTransactionStart,
  getSportsBookTransactionSuccess,
  cancelBetSlipFailure,
  cancelBetSlipSuccess,
  cancelBetSlipStart,
  getAddonConfigDropDownStart,
  getAddonConfigDropDownSuccess,
  getAddonConfigDropDownFailure
} = transactionSlice.actions
