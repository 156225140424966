import { ODDS_DATA, STATIC_OUTCOME, COMPETITOR1, COMPETITOR2 } from './constants'
import marketVariant from './marketVariant.json'

export function compareInString (args1, args2) {
  return `${args1}` === `${args2}`
}
export function replaceCompetitor (str, homeTeamName, awayTeamName, language) {
  if (!str) return ''
  else str = `${str}`
  return str
    ?.replaceAll(COMPETITOR1, homeTeamName || '1')
    ?.replaceAll(COMPETITOR2, awayTeamName || '2')
    ?.replaceAll('&amp;', '')
    ?.replaceAll('amp;', '')
}
export const outcomeTypeFormatter = ({
  outcome,
  homeTeamName,
  awayTeamName,
  providerMarketId,
  feedProvider,
  language,
  subTypeId
}) => {
  let type, variantId, specifier, total
  const { providerOutcomeId } = outcome

  if (providerOutcomeId?.includes('||')) {
    type = providerOutcomeId.split('||')[1]
    variantId = providerOutcomeId.split('||')[0]

    if (type?.includes('|')) {
      total = type.split('|')[1]
      specifier = type.split('|')[0]
      if (total?.includes('=')) {
        total = total.split('=')[1]
      }
      if (specifier?.includes('=')) {
        specifier = specifier.split('=')[1]
      }
    } else if (type?.includes('=')) {
      specifier = type.split('=')[1]
    }
  } else {
    variantId = providerOutcomeId
  }
  const outcomeData = ODDS_DATA[type?.toLowerCase()]
  // console.log(`${STATIC_OUTCOME?.[variantId]?.name_en}`)
  let oddType = outcomeData ? type : `${STATIC_OUTCOME?.[variantId]?.name_en}`
  // console.log(specifier)
  if (specifier) {
    // Regex to identify and replace placeholders such as {total}, {+hcp}, etc.
    oddType = oddType.replace(/\{[^}]+\}/g, (match) => {
      // Remove curly braces to compare keys
      const key = match.replace(/[\{\}]/g, '')
      if (key === '+hcp' || key === '-hcp') {
        return `(${specifier})`
      } else
      if (key === 'total') {
        return `(${total || specifier})`
      } else if (key === 'competitor1') {
        return `${homeTeamName}`
      } else if (key === 'competitor2') {
        return awayTeamName
      }
      return match // Return original if no specific handling is needed
    })
  }

  if (oddType.includes('{$competitor1}')) {
    // Replace {$competitor1} with homeTeamName
    oddType = oddType.replace('{$competitor1}', homeTeamName)
  }
  if (oddType.includes('{$competitor2}')) {
    // Replace {$competitor1} with homeTeamName
    oddType = oddType.replace('{$competitor2}', awayTeamName)
  }
  if (oddType.includes('X')) {
    // Replace {$competitor1} with homeTeamName
    oddType = oddType.replace('X', 'Draw')
  }
  // if (specifier) {
  //   oddType = oddType.replace('{total}', specifier);
  // }

  if (!outcome || !oddType) return ''
  if (
    homeTeamName &&
    (compareInString(oddType, '1') || compareInString(oddType, COMPETITOR1))
  ) {
    const specialBetValue = outcome?.specialBetValue
      ? outcome?.specialBetValue
      : ''

    return specialBetValue
      ? `${homeTeamName} (${specialBetValue})`
      : `${homeTeamName}`
  }

  if (
    awayTeamName &&
    (compareInString(oddType, '2') || compareInString(oddType, COMPETITOR2))
  ) {
    const specialBetValue = outcome?.specialBetValue
      ? outcome?.specialBetValue
      : ''

    return specialBetValue
      ? `${awayTeamName} (${specialBetValue})`
      : `${awayTeamName}`
  }

  let outcomeType = ''
  const formattedOutComeType = outcome && replaceCompetitor(oddType, homeTeamName, awayTeamName)

  if (marketVariant[specifier] && marketVariant[specifier]?.outcomes?.[`${specifier}:${variantId}`]) {
    outcomeType = marketVariant[specifier]?.outcomes?.[`${specifier}:${variantId}`]
    if (outcomeType.includes('{$competitor1}')) {
      // Replace {$competitor1} with homeTeamName
      outcomeType = outcomeType.replace('{$competitor1}', homeTeamName)
    }
    if (outcomeType.includes('{$competitor2}')) {
      // Replace {$competitor1} with homeTeamName
      outcomeType = outcomeType.replace('{$competitor2}', awayTeamName)
    }
    if (outcomeType.includes('X')) {
      // Replace {$competitor1} with homeTeamName
      outcomeType = outcomeType.replace('X', 'Draw')
    }
  } else {
    outcomeType = formattedOutComeType
  }

  return outcomeType.replace('((', '(').replace('))', ')')
}

export function getName (obj, language) {
  if (!obj) return ''

  if (!(obj instanceof Array)) {
    if (obj?.[language]) return obj?.[language]
    else return obj?.en || obj
  }

  let filteredobj =
    obj && obj?.length && obj?.filter((item) => item.language === language)
  if (!filteredobj || !filteredobj?.length) {
    filteredobj = obj && obj?.filter((item) => item.language === 'BET')
  }
  if (!filteredobj || !filteredobj?.length) {
    filteredobj =
      obj && obj?.length && obj?.filter((item) => item.language === 'en')
  }
  return filteredobj[0]?.name
}

export function getMarketName (marketData, teams, language) {
  const market = {
    ...marketData?.MasterMarkets?.[0],
    outcomes: marketData?.outcome
  }

  if (!market?.marketName) return ''
  //   const regex12 = /\b1\b|\b2\b/g;

  const team1 = teams?.find((item) => item?.type === 'home')
  const team2 = teams?.find((item) => item?.type === 'away')

  const homeTeam = team1?.team?.teamName?.[language] || team1?.team?.teamName?.en || team1?.team?.teamName
  const awayTeam = team2?.team?.teamName?.[language] || team2?.team?.teamName?.en || team2?.team?.teamName

  let marketName = replaceCompetitor(market?.marketName, homeTeam, awayTeam)

  const regex = /\{([^}]+)\}/
  const match = marketName?.match(regex)
  const matchedContent = match?.length > 1 && match?.[1]

  const outComeSpecifier = market?.outcomes?.[0]?.specifiers
  let specifier = ''
  let multipleValue = []

  if (outComeSpecifier?.includes('|')) {
    multipleValue = outComeSpecifier.split('|')
    specifier = multipleValue[0]
    if (specifier?.includes('=')) {
      specifier = specifier.split('=')[1]
    }
  } else if (outComeSpecifier?.includes('=')) {
    multipleValue = outComeSpecifier.split('|')
    specifier = multipleValue[0]
  }

  if (multipleValue.length > 0) {
    multipleValue.forEach((specifierValue) => {
      if (specifierValue.includes('=')) {
        const key = specifierValue.split('=')[0]
        const value = specifierValue.split('=')[1]
        if (marketName.includes(`{!${key}}`)) {
          marketName = marketName?.replace(`{!${key}}`, value)
        } else if (marketName.includes(`{${key}}`)) {
          marketName = marketName?.replace(`{${key}}`, value)
        }
      }
    })
  }

  if ([1, '1'].includes(specifier)) specifier = '1st'
  if ([2, '2'].includes(specifier)) specifier = '2nd'

  if (specifier.includes('=')) {
    specifier = specifier.split('=')[1]
  }
  marketName = marketName?.replace(`{${matchedContent}}`, specifier)

  return marketName
}
