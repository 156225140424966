import React from 'react'
import { userReportingTable } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import { formatNumber } from '../../utils/storageUtils'

const UserReportingTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t // Assuming t is the translation function
}) => {
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {userReportingTable.map((h, idx) => (
              <th key={h.label}>
                {t(`${h.label}`)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && tableData &&
            tableData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {t(`${item?.username || 'NA'}`)}
                      </span>
                    </td>
                    <td>
                      {t(`${formatNumber(item?.amount)} ₩`)}
                    </td>
                    <td>
                      {t(`${item?.actionType || 'NA'}`)}
                    </td>
                    <td>
                      {t(`${moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || 'NA'}`)}
                    </td>
                  </tr>
                )
              }
            )}
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('NoDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0
        ? (
          <PaginationComponent
            page={tableData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={tableData?.count}
          />
          )
        : <></>}
    </div>
  )
}
export default UserReportingTable
