import { Col, Form as BForm, Row } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React from 'react'
import { keysNotToBeShown } from '../hooks/constant'

const UserEndPages = ({
  myKeys,
  selectedTab,
  values,
  handleBlur,
  handleChange,
  languageTabs,
  setIsError,
  isError,
  errorCodes = false
}) => {
  return (
    <Row className='mt-3 mb-3'>
      {myKeys && Object.keys(myKeys)?.map(key => {
        return (
          languageTabs?.includes(key) && !keysNotToBeShown.includes(key) && (
            <Col sm={6} key={key} className='mb-3'>
              <label
                htmlFor={key}
              >
                {key}{selectedTab === 'EN' && !errorCodes && <span className='text-danger'> *</span>}
              </label>
              <BForm.Control
                name={`languageKeys[${key}]`}
                as='textarea'
                placeholder='Enter Value'
                value={values?.languageKeys?.[key]}
                onBlur={handleBlur}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setIsError && setIsError({
                      ...isError,
                      [key]: true
                    })
                  } else {
                    setIsError && setIsError(Object.fromEntries(Object.entries(isError).filter(k => k[0] !== key)))
                  }
                  handleChange(e)
                }}
              />
              <ErrorMessage
                component='div'
                name={`languageKeys[${key}]`}
                className='text-danger'
              />
            </Col>
          )
        )
      })}
    </Row>
  )
}

export default UserEndPages
