import { Button, Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BonusVal } from '../constant'

const BonusDetailModal = ({
  show,
  handleClose
}) => {
  const { t } = useTranslation()
  const { entityBonusDetails, entityLoading } = useSelector(state => state?.bonus)
  const isAddOnOddBonus = entityBonusDetails?.bonusType === BonusVal.winningBonus
  return (
    <Modal show={show} onHide={handleClose} centered>

      <Modal.Body>
        {entityLoading
          ? 'Loading'
          : (
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('bonusType')}</strong>
                <span>{t(entityBonusDetails?.bonusType)}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('maximum_bonus_limit')}</strong>
                <span>{entityBonusDetails?.maxBonusLimit} ₩</span>
              </div>
              {isAddOnOddBonus
                ? (
                  <div className='d-flex align-items-center gap-2'>
                    <strong style={{ minWidth: 220 }}>{t('addOnBonus')}</strong>
                    <span>{entityBonusDetails?.addOnOddBonus}</span>
                  </div>)
                : (
                  <div className='d-flex align-items-center gap-2'>
                    <strong style={{ minWidth: 220 }}>{t('bonus_percentage')}</strong>
                    <span>{entityBonusDetails?.bonusPercent}%</span>
                  </div>
                  )}

            </div>
            )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-primary' onClick={handleClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BonusDetailModal
