import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { returnFormBonusData } from '../../../../utils/helpers'
import { getAdminAffiliateAndAgentsListingStart, getEntityBonusDetailsStart, removeAffiliateAndAgentStart, resetEntityBonusDetails, updateAdminBonusLimitStart } from '../../../../store/redux-slices/bonus'
import { TABS_ADMIN_KEYS } from '../BonusAdminTabContent'
export const entityType = {
  AFFILIATE: 'affiliate',
  AGENT: 'agent',
  AFFILIATE_AGENT: 'affiliate-agent',
  SENIOR_PLAYER: 'senior-player',
  AFFILIATE_PLAYER: 'affiliate-player'
}
const useAdminTabContent = ({ selectedTab }) => {
  const { entityBonusDetails, entityLoading } = useSelector(state => state?.bonus)
  const [isEntityDetailModalOpen, setIsEntityDetailModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [removedData, setRemovedData] = useState(null)
  const [selectedData, setSelectedData] = useState([])
  const { bonusDetail } = useSelector((state) => state.bonus)
  const [isLimitUpdateModalOpen, setLimitIsUpdateModalOpen] = useState(false)
  const searchRef = useRef()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fetchEntity, setFetchEntity] = useState(false)
  const { adminAgentsAndAffiliatesListing, loading, updateLoading } = useSelector((state) => state.bonus)
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const getEntityType = () => {
    if (selectedTab === TABS_ADMIN_KEYS.affiliate) {
      return entityType.AFFILIATE
    } else if (selectedTab === TABS_ADMIN_KEYS.agent) {
      return entityType.AGENT
    } else if (selectedTab === TABS_ADMIN_KEYS.affiliateAgent) {
      return entityType.AFFILIATE_AGENT
    } else if (selectedTab === TABS_ADMIN_KEYS.seniorPlayer) {
      return entityType.SENIOR_PLAYER
    }
  }
  const entitySelectedType = getEntityType()
  const getAdminAffiliateAndAgentsListing = () => {
    dispatch(getAdminAffiliateAndAgentsListingStart({ formData: { size: limit, pageNo: page, entityType: entitySelectedType, search: search || '', adminId: bonusDetail?.adminId, bonusId: bonusDetail?.bonusId } }))
  }
  const handleYesRemove = () => {
    dispatch(removeAffiliateAndAgentStart({
      formData: removedData,
      handleClose: () => {
        setIsRemoveModalOpen(false)
        getAdminAffiliateAndAgentsListing()
        setRemovedData(null)
      }
    }))
  }

  const handleUpdateLimit = (values, formActions) => {
    const formData = returnFormBonusData(values, true)
    const allowedEntity = selectedData?.map((item) => {
      return {
        entityId: item,
        ...formData
      }
    })
    const formEntityType = getEntityType()

    const tempData = {
      bonusId: bonusDetail?.bonusId,
      adminId: bonusDetail?.adminId,
      entityType: formEntityType,
      allowedEntity
    }
    if (selectedTab === TABS_ADMIN_KEYS.seniorPlayer) {
      dispatch(updateAdminBonusLimitStart({
        formData: {
          entityType: formEntityType,
          bonusId: bonusDetail?.bonusId,
          playerLimitObject: formData,
          adminId: bonusDetail?.adminId
        },
        handleClose: () => {
          setFetchEntity(prev => !prev)
        },
        isPlayer: true
      }))
    } else {
      dispatch(updateAdminBonusLimitStart({
        formData: tempData,
        handleClose: () => {
          setLimitIsUpdateModalOpen(false)
          setSelectedData([])
          getAdminAffiliateAndAgentsListing()
        }
      }))
    }
  }

  useEffect(() => {
    if (bonusDetail?.bonusId) { if (selectedTab === TABS_ADMIN_KEYS.affiliate) { getAdminAffiliateAndAgentsListing() } else if (selectedTab === TABS_ADMIN_KEYS.agent) { getAdminAffiliateAndAgentsListing() } else if (selectedTab === TABS_ADMIN_KEYS.affiliateAgent) { getAdminAffiliateAndAgentsListing() } }
  }, [limit, page, search, bonusDetail, selectedTab])

  const isChecked = (id) => {
    return selectedData?.find(item => Number(item) === Number(id))
  }
  const getEntityBonusDetails = (formData) => {
    dispatch(resetEntityBonusDetails())
    dispatch(getEntityBonusDetailsStart({ formData: { ...formData, entityType: entitySelectedType }, bonusType: bonusDetail?.bonusType }))
  }
  useEffect(() => {
    if (bonusDetail?.bonusId && selectedTab === TABS_ADMIN_KEYS.seniorPlayer) {
      getEntityBonusDetails({
        entityId: '',
        bonusId: bonusDetail?.bonusId
      })
    }
  }, [bonusDetail, fetchEntity])
  const handleCheck = (e, id) => {
    if (!isChecked(e.target.value)) {
      setSelectedData(prev => [...prev, id])
    } else {
      setSelectedData(selectedData.filter(t => Number(t) !== Number(e.target.value)))
    }
  }
  const handleResetEntityDetails = () => {
    dispatch(resetEntityBonusDetails())
  }
  useEffect(() => {
    handleResetEntityDetails()
  }, [])
  return {
    entityBonusDetails,
    entityLoading,
    isEntityDetailModalOpen,
    setIsEntityDetailModalOpen,
    getEntityBonusDetails,
    entitySelectedType,
    isRemoveModalOpen,
    setIsRemoveModalOpen,
    setRemovedData,
    handleYesRemove,
    t,
    adminAgentsAndAffiliatesListing,
    loading,
    limit,
    setLimit,
    page,
    setPage,
    debounceFn,
    searchRef,
    isChecked,
    handleCheck,
    bonusDetail,
    isLimitUpdateModalOpen,
    setLimitIsUpdateModalOpen,
    handleUpdateLimit,
    setSelectedData,
    selectedData,
    updateLoading,
    handleResetEntityDetails
  }
}

export default useAdminTabContent
