import { createSlice } from '@reduxjs/toolkit'

const tenantSportsBookSlice = createSlice({
  name: 'tenantSportsBook',
  initialState: {
    loading: false,
    sportsListing: [],
    sportsCountriesListing: [],
    leaguesListing: [],
    matchListing: [],
    marketListing: []
  },
  reducers: {
    getTenantSportsListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantSportsListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportsListing: payload
    }),
    getTenantSportsListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantSportsCountriesListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantSportsCountriesListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportsCountriesListing: payload
    }),
    getTenantSportsCountriesListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantMatchListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantMatchListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      matchListing: payload
    }),
    getTenantMatchListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantMarketListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantMarketsListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      marketListing: payload
    }),
    getTenantMarketsListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantLeaguesListingStart: (state) => ({
      ...state,
      loading: true,
      leaguesListing: {}
    }),
    getTenantLeaguesListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      leaguesListing: payload
    }),
    getTenantLeaguesListingFailure: (state) => ({
      ...state,
      loading: false,
      leaguesListing: []
    }),
    updateSportsBookActionTAStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSportsBookActionTASuccess: (state, payload) => ({
      ...state,
      loading: false
    }),
    updateSportsBookActionTAFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default tenantSportsBookSlice.reducer

export const {
  getTenantSportsListingStart,
  getTenantSportsListingSuccess,
  getTenantSportsListingFailure,
  getTenantSportsCountriesListingStart,
  getTenantSportsCountriesListingSuccess,
  getTenantSportsCountriesListingFailure,
  getTenantMatchListingStart,
  getTenantMatchListingSuccess,
  getTenantMatchListingFailure,
  getTenantMarketListingStart,
  getTenantMarketsListingSuccess,
  getTenantMarketsListingFailure,
  getTenantLeaguesListingStart,
  getTenantLeaguesListingSuccess,
  getTenantLeaguesListingFailure,
  updateSportsBookActionTAStart,
  updateSportsBookActionTASuccess,
  updateSportsBookActionTAFailure
} = tenantSportsBookSlice.actions
