import {
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  useNavigate
} from 'react-router-dom'
import {
  getLeaguesListingStart, getSportsListingStart, sportsCountriesStart, updateSportsBookActionSAStart
} from '../../../store/redux-slices/superAdminSportsBook'
import {
  getTenantLeaguesListingStart, getTenantSportsListingStart, updateSportsBookActionTAStart
} from '../../../store/redux-slices/tenantSportsBook'
import { debounce } from 'lodash'

const initialState = {
  tournamentName: '',
  status: '',
  sportId: '1',
  isTop: 'true',
  countryName: ''
}
const useLeague = (isTenant) => {
  const { sportsCountries, addTopLoading } = useSelector((state) => state.superAdminSportsBook)
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modalData, setModalData] = useState()
  const [entityId, setEntityId] = useState()
  const [type, setType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDisable, setIsDisable] = useState(null)
  const [updateData, setUpdateData] = useState()

  const fetchData = () => {
    !isTenant
      ? dispatch(getLeaguesListingStart({
          paramsData: {
            ...filterData,
            limit,
            pageNo: page,
            isTenant
          }
        }))
      : dispatch(getTenantLeaguesListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page,
          isTenant
        }
      }))
  }

  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])

  useEffect(() => {
    dispatch(sportsCountriesStart({
      limit: 1000,
      pageNo: '',
      isTenant
    }))
  }, [])

  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant
        }
      }))
  }, [])

  const { leaguesListing, sportsListing, loading } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )
  const totalPages = Math.ceil(leaguesListing?.count / limit)

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  function handleDebounceFn (event) {
    setFilterData(prev => ({
      ...prev,
      tournamentName: event.target.value
    }))
  }
  const handleClose = () => setShowModal(false)

  const handleShowModal = (type, data, id) => {
    setModalData(data)
    setEntityId(id)
    setType(type)
    setShowModal(true)
  }
  const handleUpdateSportAction = (updateData) => {
    !isTenant
      ? dispatch(updateSportsBookActionSAStart({ updateData, setIsStatusModalOpen, fetchData }))
      : dispatch(updateSportsBookActionTAStart({ updateData: { ...updateData, isTenant }, setIsStatusModalOpen, fetchData }))
  }
  return {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    leaguesListing,
    loading,
    isStatusModalOpen,
    sportsCountries,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    debounceFn,
    sportsListing,
    totalPages,
    handleUpdateSportAction,
    updateData,
    setUpdateData,
    handleShowModal,
    showModal,
    handleClose,
    modalData,
    type,
    entityId,
    fetchData,
    addTopLoading
  }
}

export default useLeague
