import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  createTenantUserStart,
  getUserFieldsStart,
  updateTenantUserStart
} from '../../../../store/redux-slices/tenantUsers'
import { getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'

const useCreateTenantUser = ({ user }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [completePhone, setCompletePhone] = useState('')
  const { userFields, loading } = useSelector((state) => state.tenantUsers)
  const { tenantDetails } = useSelector((state) => state.login)
  const { tenantCountries } = useSelector((state) => state.fetch)
  const arr =
    userFields &&
    Object.keys(userFields)
      .map((keyName, i) => userFields[keyName] !== false && keyName)
      .filter((ele) => ele !== false && ele)

  const initialState =
    arr &&
    arr.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = ''
      if (currentValue === 'address') {
        accumulator.zipCode = ''
        accumulator.city = ''
      }
      return accumulator
    }, {})

  if (user && initialState) {
    for (const k in initialState) initialState[k] = user[k]
    initialState.userId = user.userId
  }

  useState(() => {
    if (initialState?.phone && user) {
      setCompletePhone('' + user?.phoneCode?.substring(1) + user.phone)
    }
  }, [])

  useEffect(() => {
    if (!user) dispatch(getUserFieldsStart())
    dispatch(getTenantCountriesStart())
  }, [])

  const updateTenantUser = ({ initialState }) => {
    for (const k in initialState) {
      if (initialState[k] === '' || initialState[k] === null) {
        delete initialState[k]
      }
    }
    dispatch(updateTenantUserStart({ initialState, navigate }))
  }

  const createTenantUser = ({ initialState }) => {
    for (const k in initialState) {
      if (initialState[k] === '' || initialState[k] === null) {
        delete initialState[k]
      }
    }
    dispatch(createTenantUserStart({ initialState, navigate }))
  }

  const showStar = (fieldName) =>
    fieldName === 2 &&
      <span className='text-danger'> *</span>

  return {
    navigate,
    userFields,
    loading,
    tenantDetails,
    initialState,
    updateTenantUser,
    createTenantUser,
    showStar,
    tenantCountries,
    completePhone
  }
}

export default useCreateTenantUser
