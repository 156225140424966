import React from 'react'
import { Button, ButtonGroup, Col, Form, Table } from 'react-bootstrap'
import { teamListingTableHeader } from './constants'
import PaginationComponent from '../Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faRedoAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../OverlayTrigger'
import { useTranslation } from 'react-i18next'

import useTeamListing from './hooks/useTeamListing'
import UpdateTeamLogo from './components/UpdateTeamLogo'
import { teamDesktopSize, teamMobileSize } from '../../utils/constants'

const TeamListing = ({
  title,
  isTenant
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    setFilterData,
    initialState,
    teamListing,
    loading,
    debounceFn,
    totalPages,
    showModal,
    handleClose,
    handleImageModalOpen,
    handleUpdateImage,
    updateLoading,
    filterData,
    sportsListing,
    modalData,
    inputRef
  } = useTeamListing(isTenant)
  const { t } = useTranslation()
  const updateImageButton = (item, type) => {
    return (
      <ButtonGroup>
        <Trigger message={t('edit')}>
          <Button
            className='m-1'
            size='sm'
            variant='warning'
            onClick={() => handleImageModalOpen(item, type)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Trigger>

      </ButtonGroup>
    )
  }

  return (

    <>
      <div className='my-3'>
        <h3 className='d-flex align-items-center pe-2'>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className='cursor-pointer px-2'
            onClick={() => navigate(-1)}
          />

          {t(title.toLowerCase())}
        </h3>

        <div>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='d-flex align-items-center px-2'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                {t('search')}
              </Form.Label>&nbsp;
              <Form.Control
                ref={inputRef}
                name='search'
                type='search'
                placeholder={t('searchTeamByName')}
                size='sm'
                style={{
                  minWidth: '200px',
                  maxWidth: '240px',
                  width: 'fit-content'
                }}
                onChange={(e) => {
                  debounceFn(e)
                  setPage(1)
                }}
              />
            </div>
            <div className='d-flex align-items-center'>
              <Form.Label
                column='sm' style={{
                  marginBottom: '0',
                  marginRight: '15px',
                  minWidth: 'fit-content'
                }}
              >
                {t('sports')}
              </Form.Label>
              <Form.Select
                value={filterData?.masterSportId}
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    masterSportId: e.target.value
                  })
                  setPage(1)
                }}
                style={{
                  marginBottom: '0',
                  marginRight: '15px',
                  maxWidth: '230px',
                  width: 'auto'
                }}
                size='sm'
              >
                <option value=''>{t('all')}</option>

                {sportsListing?.rows?.map(({
                  masterSportId,
                  sportName
                }) =>
                  (
                    <option
                      key={masterSportId}
                      value={masterSportId}
                    >
                      {sportName}
                    </option>
                  )
                )}
              </Form.Select>
            </div>
            <Col className='d-flex p-3 float-end'>
              <Trigger message='Reset Filters'>
                <Button
                  variant='outline-success'
                  className='py-1'
                  size='sm'
                  onClick={() => {
                    setFilterData(initialState)
                    inputRef.current.value = ''
                    setPage(1)
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </Col>
          </div>
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {teamListingTableHeader.map((h, idx) => (
                  <th key={h.label}>
                    {t(h.label)} &nbsp;
                  </th>
                ))}
              </tr>
            </thead>
            <tbody id={loading ? 'cover-spin' : ''}>
              {
              !loading && teamListing &&
              teamListing?.rows?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {(index + 1) + (limit * (page - 1))} </td>
                    <td>
                      {item?.teamName || '-'}
                    </td>
                    <td>
                      {item?.masterSportId || '-'}
                    </td>
                    <td>
                      <div className='d-flex flex-column align-items-center justify-content-center'>

                        <div className='d-flex gap-1 align-items-center'>
                          <div style={{ minWidth: 168 }} className='text-left'>{t('dimensions')}: {teamDesktopSize?.minRequiredWidth}-{teamDesktopSize?.maxRequiredWidth}px , &nbsp;</div>
                          <div style={{ minWidth: 100 }} className='text-left'>{t('desktopImage')}</div> -
                          <div style={{ minWidth: 80 }}>
                            {item?.imageUrl?.desktop ? <img style={{ maxWidth: '80px', maxHeight: '80px' }} src={item?.imageUrl?.desktop} alt='desktopUrl' /> : '-'}
                          </div>
                          {updateImageButton(item, 'desktop')}
                        </div>

                        <div className='d-flex gap-1 align-items-center mt-2'>
                          <div style={{ minWidth: 168 }} className='text-left'>{t('dimensions')}: {teamMobileSize?.minRequiredWidth}-{teamMobileSize?.maxRequiredWidth}px , &nbsp;</div>
                          <div style={{ minWidth: 100 }} className='text-left'>{t('mobileImage')}</div> -
                          <div style={{ minWidth: 80 }}>
                            {item?.imageUrl?.mobile ? <img style={{ maxWidth: '80px', maxHeight: '80px' }} src={item?.imageUrl?.mobile} alt='mobileUrl' /> : '-'}
                          </div>
                          {updateImageButton(item, 'mobile')}
                        </div>

                      </div>

                    </td>

                  </tr>
                )
              }
              )
            }
              {teamListing?.count === 0 && !loading
                ? (
                  <tr>
                    <td
                      colSpan={8}
                      className='text-danger text-center'
                    >
                      No data found
                    </td>
                  </tr>
                  )
                : <></>}
            </tbody>
          </Table>
          {!loading && teamListing?.count !== 0 &&
          (
            <PaginationComponent
              page={teamListing?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalCount={teamListing?.count}
            />
          )}
        </div>
      </div>
      <UpdateTeamLogo modalData={modalData} handleClose={handleClose} handleUpdateTeamLogo={handleUpdateImage} show={showModal} t={t} updateLoading={updateLoading} />

    </>
  )
}
export default TeamListing
