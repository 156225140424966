import React from 'react'
import { Row, Col, Card } from '@themesberg/react-bootstrap'
import './livePlayer.css'
import PieChart from '../../../../../components/charts/PieCharts'
import BarGraph from '../../../../../components/charts/BarGraph'
import { countryFilter } from '../../../../../utils/countryFilter'
import Trigger from '../../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarChart, faPieChart } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'react-bootstrap'

export default ({
  pieChartValuesSeries,
  pieChartValuesLabels,
  loggedInCounts,
  countryCodes,
  userLogginbyCountry,
  tableHeadersUserCountryCount,
  t
}) => {
  return (
    <Row className='g-3 mb-3 d-flex gap-2 flex-wrap'>
      <Col className='flex-shrink-0'>
        <Card className='d-flex p-2 h-100 mb-3 graph-container'>
          <Row>
            <Col xs='auto'>
              <h3><FontAwesomeIcon icon={faPieChart} />&nbsp;{t('playerStats')}</h3>
            </Col>
          </Row>
          <Row className='flex-shrink-0 d-xl-flex align-items-center'>
            <PieChart
              series={pieChartValuesSeries}
              labels={pieChartValuesLabels}
              colors={['#008000', '#FF0000', '#CCCC00', '#000000', '#0000FF', '#808080']}
              height={450}
              width={450}
            />
          </Row>
        </Card>
      </Col>
      <Col className='flex-shrink-0' style={{ minWidth: 400 }}>
        <Card className='p-2 h-100 mb-3 graph-container'>
          <Row>
            <Col xs='auto'>
              <h3><FontAwesomeIcon icon={faBarChart} />&nbsp;{t('onlineUsersDemograph')}</h3>
            </Col>
          </Row>
          <Row className='d-flex d-block d-xl-flex align-items-center'>
            <BarGraph
              seriesData={loggedInCounts}
              categories={countryCodes}
            />
          </Row>
        </Card>
      </Col>
      <Col>
        <Card className='d-flex p-2 h-100 mb-3 graph-container'>
          <Table bordered striped responsive hover size='sm' className='text-center'>
            <thead className='thead-dark'>
              <tr>
                {tableHeadersUserCountryCount.slice(0, 3).map((h, idx) => (
                  <th key={idx}>
                    {t(h.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ overflowY: 'auto' }}> {/* Added style to prevent bottom scroll bar */}
              {userLogginbyCountry && userLogginbyCountry.map((item, index) => {
                const { countryImage, countryName } = countryFilter(item?.country_code)
                return (
                  <tr key={index}>
                    <td>
                      {!countryName
                        ? t('other')
                        : (
                          <Trigger message={countryName}>
                            <img width='25px' src={countryImage} alt={countryImage} />
                          </Trigger>
                          )}
                    </td>
                    <td>{item?.totalUserCount || '-'}</td>
                    <td>{item?.loggedInCount || '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  )
}
