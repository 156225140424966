import * as Yup from 'yup'

export const bothValidation = (t) => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .max(16, t('max16Characters'))
      .notRequired(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('matchPassword'))
      .when('newPassword', {
        is: (value) => value && value.length > 0,
        then: Yup.string().required(t('confirmPasswordRequired'))
      }),
    withdrawalNewPassword: Yup.string()
      .max(16, t('max16Characters'))
      .notRequired(),
    confirmWithdrawalPassword: Yup.string()
      .oneOf([Yup.ref('withdrawalNewPassword'), null], t('matchPassword'))
      .when('withdrawalNewPassword', {
        is: (value) => value && value.length > 0,
        then: Yup.string().required(t('confirmPasswordRequired'))
      })
  })
}

export const createNotificationValidation = (isTenant, t) => {
  return Yup.object().shape({
    notificationMessage: Yup.string()
      .required(t('notificationRequired'))
      .max(200),
    referenceType: Yup.number()
      .nullable()
      .required(t('notificationCategoryRequired')),
    referenceId: !isTenant
      ? Yup.array().min(1, t('tenantRequired'))
      : Yup.array().notRequired()
  })
}
export const updateEntityValidation = (isTenant, t) => {
  return Yup.object().shape({
    entityId: Yup.string()
      .required(t('fieldRequired'))
  })
}
