import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  InputGroup
} from '@themesberg/react-bootstrap'
import { createTenantAdminSchema } from '../../Tenants/schemas'
import { SuperAdminRoutes } from '../../../../routes'
import useCreateTenantAdmin from '../hooks/useCreateTenantAdmin'
import Preloader from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../../components/OverlayTrigger'
import { useTranslation } from 'react-i18next' // Importing useTranslation
import { getItem } from '../../../../utils/storageUtils'

const CreateTenantAdmin = ({ tenantData }) => {
  const {
    createTenantAdmin,
    navigate,
    loading,
    updateTenantAdmin,
    type,
    setType,
    ownerPermission,
    banksArr
  } = useCreateTenantAdmin(tenantData)

  const { t } = useTranslation() // Initializing useTranslation

  const actionLabel = tenantData ? t('edit') : t('create')

  return (
    <div>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div className='p-2 '>
            <Row>
              <Col className='d-flex ps-3'>
                <h3>
                  <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                    className='cursor-pointer me-1'
                    onClick={() => navigate(-1)}
                  />
                  {actionLabel} {t('tenantSenior')}
                </h3>
              </Col>
            </Row>

            <div className='ps-2'>
              <Formik
                enableReinitialize
                initialValues={{
                  email: tenantData ? tenantData.email : '',
                  password: '',
                  firstName: tenantData ? tenantData.firstName : '',
                  lastName: tenantData ? tenantData.lastName : '',
                  agentName: tenantData ? tenantData.agentName : '',
                  phone: tenantData ? tenantData.phone : '',
                  adminAccountNumber: tenantData ? tenantData.adminAccountNumber : '',
                  adminBankName: tenantData ? tenantData.adminBankName : '',
                  currency: tenantData?.Tenants?.[0]?.tenantConfig?.withdrawlLimitCurrency || '',
                  permission: tenantData
                    ? tenantData?.userPermission?.permission
                    : {
                        ...ownerPermission,
                        transactions: ['R', 'U'],
                        cms: ['R'],
                        users: ['R', 'U', 'T', 'AB'],
                        bonus: ['R', 'U', 'C', 'T']
                      }
                }}
                validationSchema={createTenantAdminSchema(tenantData, t)}
                onSubmit={(formValues) => {
                  const createTenantAdminData = {
                    ...formValues,
                    firstName: formValues.firstName.trim(),
                    lastName: formValues.lastName.trim(),
                    ...(tenantData ? {} : { password: formValues.password }),
                    agentName: formValues.agentName.trim()
                  }
                  tenantData
                    ? updateTenantAdmin({
                        createTenantAdminData,
                        adminUserId: tenantData.adminUserId
                      })
                    : createTenantAdmin(createTenantAdminData)
                }}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue
                }) => (
                  <Form className='mt-2'>
                    <Row>
                      <Col>
                        <label>
                          {t('email')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          disabled={tenantData?.email}
                          type='text'
                          name='email'
                          placeholder={t('enterEmail')}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='email'
                          className='text-danger'
                        />
                      </Col>

                      <Col>
                        <label>
                          {t('phoneNumber')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          type='text'
                          name='phone'
                          maxLength={13}
                          placeholder={t('xxxXxxxxxxxx')}
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='phone'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <label>
                          {t('firstName')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          type='text'
                          name='firstName'
                          maxLength={25}
                          placeholder={t('enterFirstName')}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='firstName'
                          className='text-danger'
                        />
                      </Col>

                      <Col>
                        <label>
                          {t('lastName')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          type='text'
                          name='lastName'
                          maxLength={25}
                          placeholder={t('enterLastName')}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='lastName'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col xs={12} md={6}>
                        <label>
                          {t('username')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          disabled={tenantData?.agentName}
                          type='text'
                          name='agentName'
                          placeholder={t('enterUsername')}
                          maxLength={25}
                          value={values.agentName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='agentName'
                          className='text-danger'
                        />
                      </Col>

                      {!tenantData && (
                        <Col>
                          <label>
                            {t('password')}<span className='text-danger'> *</span>
                          </label>

                          <Trigger
                            message={t('mustBeAtLeast8Characters')}
                          >
                            <InputGroup>
                              <BForm.Control
                                type={type}
                                name='password'
                                maxLength={16}
                                placeholder={t('enterPassword')}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                                <FontAwesomeIcon
                                  icon={type === 'password' ? faEyeSlash : faEye}
                                  onClick={() => {
                                    type === 'password' ? setType('text') : setType('password')
                                  }}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </Trigger>

                          <ErrorMessage
                            component='div'
                            name='password'
                            className='text-danger'
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className='mt-3'>
                      <Col>
                        <label>
                          {t('bankName')}<span className='text-danger'> *</span>
                        </label>
                        <BForm.Select
                          type='select'
                          name='adminBankName'
                          value={values.adminBankName}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>
                            {t('selectBank')}
                          </option>
                          {banksArr?.[getItem('language')].map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='adminBankName'
                          className='text-danger'
                        />
                      </Col>
                      <Col>
                        <label>
                          {t('accountNumber')}<span className='text-danger'> *</span>
                        </label>

                        <BForm.Control
                          type='text'
                          name='adminAccountNumber'
                          maxLength={13}
                          placeholder={t('enterAccountNumber')}
                          value={values.adminAccountNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='adminAccountNumber'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <div className='mt-4 d-flex justify-content-between align-items-center'>
                      <Button
                        variant='outline-warning'
                        onClick={() => navigate(SuperAdminRoutes.TenantOwner)}
                      >
                        {t('cancel')}
                      </Button>

                      <Button
                        variant='outline-success'
                        onClick={handleSubmit}
                        className='ml-2'
                      >
                        {t('submit')}
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          )}
    </div>
  )
}

export default CreateTenantAdmin
