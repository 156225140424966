import * as Yup from 'yup'
import { BONUSES } from './constants'
export const bonusSchema = (curr, { bonusDetail, t }) => {
  const bonusTypeSchema = Yup.object().shape({
    adminId: Yup
      .string()
      .required(t('selectOneSenior')),
    bonusType: Yup
      .string()
      .required(t('bonusTypeRequired')),

    limitType: Yup
      .string(),
    validFrom: Yup
      .date()
      .required(t('validFromDateRequired')),
    validTo: Yup
      .date()
      .required(t('validToDateRequired')),

    maxBonusLimit: Yup
      .number()
      .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
      .typeError(t('mustBeNumber'))
      .required(t('maxBonusLimitRequired'))
      .nullable(),

    dailyLimit: Yup.number().when('bonusType', {
      is: BONUSES.compensation_bonus.value,
      then: Yup
        .number()
        .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
        .typeError(t('mustBeNumber'))
    }).nullable(),

    weeklyLimit: Yup
      .number()
      .when('bonusType', {
        is: BONUSES.compensation_bonus.value,
        then: Yup
          .number()
          .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
          .typeError(t('mustBeNumber'))
      })
      .nullable(),

    monthlyLimit: Yup
      .number()
      .when('bonusType', {
        is: BONUSES.compensation_bonus.value,
        then: Yup
          .number()
          .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
          .typeError(t('mustBeNumber'))
      }).nullable(),

    minutes_for_bonus_given: Yup
      .number()
      .when('bonusType', {
        is: BONUSES.compensation_bonus.value,
        then: Yup
          .number()
          .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
          .typeError(t('mustBeNumber'))
          .required(t('minutesForBonusGivenRequired'))
      }).nullable(),

    compensation_target: Yup
      .number()
      .when('bonusType', {
        is: BONUSES.compensation_bonus.value,
        then: Yup
          .number()
          .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
          .typeError(t('mustBeNumber'))
          .required(t('compensationTargetRequired'))
      }),

    validOnDays: Yup
      .array()
      .when(['visibleInPromotions', 'bonusType'], {
        is: (visibleInPromotions, bonusType) => visibleInPromotions && bonusType !== 'promotion',
        then: Yup
          .array()
          .min(1, t('selectAtLeastOneDay'))
          .nullable()
      }).nullable(),

    wageringRequirementType: Yup
      .string()
      .when('bonusType', {
        is: bonusType => bonusType !== 'balance',
        then: Yup
          .string()
          .required(t('wageringTypeRequired'))
          .nullable()
      }),

    depositBonusPercent: Yup
      .number()
      .min(1, t('mustBeGreaterThanOrEqualTo', { value: 1 }))
      .typeError(t('bonusPercentMustBeNumber'))
      .required(t('bonusPercentageRequired'))
      .nullable()
  })

  return [bonusTypeSchema]
}
