import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMarketListingStart, getSportsListingStart, updateSportsBookActionSAStart } from '../../../store/redux-slices/superAdminSportsBook'
import { debounce } from 'lodash'
import { getTenantMarketListingStart, getTenantSportsListingStart, updateSportsBookActionTAStart } from '../../../store/redux-slices/tenantSportsBook'

const initialState = {
  marketName: '',
  status: '',
  sportId: '1'
}
const useMarket = (isTenant) => {
  const inputRef = useRef()
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [updateData, setUpdateData] = useState()
  const [isDisable, setIsDisable] = useState(null)
  const fetchData = () => {
    !isTenant
      ? dispatch(getMarketListingStart({
          paramsData: {
            ...filterData,
            limit,
            pageNo: page,
            isTenant
          }
        }))
      : dispatch(getTenantMarketListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page,
          isTenant
        }
      }))
  }
  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])
  const { marketListing, sportsListing, loading } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )
  const totalPages = Math.ceil(marketListing?.count / limit)

  const handleDebounceFn = (event) => {
    setFilterData(prev => ({ ...prev, marketName: event.target.value }))
  }
  const debouncefn = useCallback(debounce(handleDebounceFn, 1000), [])
  const handleUpdateSportAction = (updateData) => {
    !isTenant
      ? dispatch(updateSportsBookActionSAStart({ updateData, setIsStatusModalOpen, page, fetchData }))
      : dispatch(updateSportsBookActionTAStart({ updateData: { ...updateData, isTenant }, setIsStatusModalOpen, page, fetchData }))
  }

  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant
        }
      }))
  }, [])

  return {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    marketListing,
    totalPages,
    loading,
    debouncefn,
    inputRef,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    handleUpdateSportAction,
    updateData,
    setUpdateData,
    sportsListing
  }
}

export default useMarket
