import {
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import GeneralForm from '../CreateBonus/general'
import useCreateBonus from '../hooks/useCreateBonus'
import { updateBonusStart } from '../../../../../store/redux-slices/bonus'
import useEditBonus from '../hooks/useEditBonus'
import Preloader from '../../../../../components/Preloader'
import { tabLabels } from '../CreateBonus/constants'
import { bonusSchema } from '../CreateBonus/schema'
import moment from 'moment'

const EditBonus = () => {
  const {
    selectedTab,
    setSelectedTab,
    state,
    dispatch,
    bonusDetail,
    bonusId,
    portalOptions,
    loading,
    navigate,
    setState,
    setCountryData
  } = useEditBonus()

  const {
    handleImagePreview,
    preview,
    setEnableSubmit,
    wageringTemplateList,
    clientsFilterData,
    setSelectedClient,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    appliedBonusOptions,
    languages,
    data,
    setData,
    set,
    selectedGames,
    setSelectedGames,
    selectedPlayers,
    setSelectedPlayers,
    selectedLimit,
    setSelectedLimit,
    t
  } = useCreateBonus(bonusDetail)

  return (
    <>
      <Row>
        <Col>
          <h3>{t('editBonus')}: {t(bonusDetail?.bonusType)}</h3>
        </Col>
      </Row>
      {portalOptions.length && !loading && set
        ? (
          <Formik
            enableReinitialize
            initialValues={{
              bonusType: bonusDetail?.bonusType || '',
              validFrom: moment(bonusDetail?.validFrom).format('YYYY-MM-DDTHH:mm'),
              validTo: moment(bonusDetail?.validTo).format('YYYY-MM-DDTHH:mm'),
              wageringRequirementType: bonusDetail?.wageringRequirementType || 'bonus',
              userId: bonusDetail?.user_id || [],
              depositBonusPercent: bonusDetail?.depositBonusPercent,
              dailyLimit: bonusDetail?.daily_limit || '',
              weeklyLimit: bonusDetail?.weekly_limit || '',
              monthlyLimit: bonusDetail?.monthly_limit || '',
              minutesForBonusGiven: bonusDetail?.minutes_for_bonus_given || '',
              compensationTarget: bonusDetail?.compensation_target || '',
              tenantIds: portalOptions?.filter(
                (tenant) => tenant.value === bonusDetail?.tenantId
              ),
              adminId: bonusDetail?.adminId,
              validOnDays: bonusDetail?.validOnDays,
              reoccuringDepositeRate: bonusDetail?.reoccuring_deposite_rate,
              withdrawalRate: bonusDetail?.withdrawl_rate,
              gameIds: bonusDetail?.gameIds || [],
              isActive: bonusDetail?.isActive || false,
              maxBonusLimit: bonusDetail?.maxBonusLimit || 1,
              isSticky: bonusDetail?.isSticky || false,
              wageringTemplateId: bonusDetail?.wageringTemplateId || 1
            }}
            validationSchema={
            bonusSchema(bonusDetail?.currency,
              { bonusDetail, t })[tabLabels.findIndex((val) => val === selectedTab)]
          }
            onSubmit={(formValues) => {
              Object.keys(formValues).forEach((key) => {
                if (formValues[key] === null || formValues[key] === '' || formValues[key] === undefined) {
                  delete formValues[key]
                }
              })
              delete formValues.validFrom
              delete formValues.validTo
              const gameIds = selectedGames?.map(item => item?.masterCasinoGameId) || []
              const userId = selectedPlayers?.map(item => item?.userId) || []
              const tempObject = {
                ...formValues,
                gameIds,
                userId,
                bonusId: Number(bonusId),
                validFrom: moment(state[0].startDate).toISOString(),
                validTo: moment(state[0].endDate).toISOString(),
                tenantIds: formValues.tenantIds.map((id) => id.value)

              }
              dispatch(
                updateBonusStart({
                  data: tempObject,
                  navigate
                })
              )
            }}
          >
            {({
              touched,
              errors,
              values,
              setSubmitting,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => {
              return (
                <BForm>
                  <div className='mt-1'>
                    <GeneralForm
                      t={t}
                      selectedLimit={selectedLimit}
                      setSelectedLimit={setSelectedLimit}
                      state={state}
                      setState={setState}
                      isEdit
                      setSelectedTab={setSelectedTab}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setSubmitting={setSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      bonusDetail={bonusDetail}
                      handleImagePreview={handleImagePreview}
                      preview={preview}
                      navigate={navigate}
                      clientsFilterData={clientsFilterData}
                      setSelectedClient={setSelectedClient}
                      portalOptions={portalOptions}
                      getBalanceBonusesOptions={getBalanceBonusesOptions}
                      balanceBonusOptions={balanceBonusOptions}
                      wageringTemplateList={wageringTemplateList}
                      setEnableSubmit={setEnableSubmit}
                      appliedBonusOptions={appliedBonusOptions}
                      data={data}
                      setData={setData}
                      languages={languages}
                      setCountryData={setCountryData}
                      selectedGames={selectedGames}
                      setSelectedGames={setSelectedGames}
                      selectedPlayers={selectedPlayers}
                      setSelectedPlayers={setSelectedPlayers}
                    />
                  </div>
                </BForm>
              )
            }}
          </Formik>
          )
        : (
          <Preloader />
          )}
    </>
  )
}

export default EditBonus
