import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../../components/Pagination'
import Preloader from '../../../../components/Preloader'
import ProviderFilter from '../../../../components/ProviderFilter'
import useWageringTepltateDetails from '../hooks/useWageringTepltateDetails'
import './wageringStyle.css'

const WageringTepltateDetails = () => {
  const {
    loading,
    wageringTemplateDetail,
    selectedProvider,
    setSelectedProvider,
    totalPages,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    search,
    setSearch
  } = useWageringTepltateDetails()

  return (
    <>
      <Row>
        <Col>
          <h3>Wagering Template: {wageringTemplateDetail?.name} </h3>

        </Col>
        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              isTenantRoute
            />
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Label style={{ marginRight: '15px', marginBottom: '0px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{ maxWidth: '230px' }}
              placeholder='Search Game Name'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4 scrollable'>
          <thead className='thead-dark'>
            <tr>
              {[
                'Name',
                'RTP',
                'Default',
                'Custom Value'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {loading
              ? <Preloader />
              : wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail.gameDetail?.rows.length > 0
                ? wageringTemplateDetail.gameDetail?.rows.map(({ masterCasinoGameId, name, returnToPlayer, wageringContribution }, idx) => {
                    return (
                      <tr key={`wagering-template ${masterCasinoGameId}`}>
                        <td>{name}</td>

                        <td>{returnToPlayer} %</td>
                        <td>{wageringContribution} %</td>
                        <td>{wageringTemplateDetail?.gameContribution?.[masterCasinoGameId] || 100} %</td>

                      </tr>
                    )
                  })
                : (
                  <tr>
                    <td colSpan={7} className='text-danger text-center'>
                      No data found
                    </td>
                  </tr>
                  )}
          </tbody>
        </Table>
      </Row>
      {!loading && wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail?.gameDetail?.count !== 0 && (
        <PaginationComponent
          page={wageringTemplateDetail?.gameDetail?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />)}
    </>
  )
}

export default WageringTepltateDetails
