import { faLeftLong, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Form as BForm, Spinner, Button, Row, Col } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../utils/storageUtils'
import { format } from 'date-fns'
import { userSigninSchema, userSigninSchemaEN } from './schema'
import { banksArr, currenciesArr, date, month, nationalitiesArr, year } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../store/redux-slices/tenants'
import { userCreateStart } from '../../store/redux-slices/superAdminSettings'
import { useNavigate } from 'react-router-dom'

const CreateUser = ({ isTenant, isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { userCreateLoading } = useSelector(state => state.superAdminSettings)
  const { adminDetails } = useSelector(state => state.admins)
  useEffect(() => {
    if (!isTenant && !isAffiliate && !isAgent) {
      dispatch(getAllClientsStart({
        search: '',
        limit: '',
        orderBy: 'email',
        sort: 'desc'
      }))
    }
  }, [])
  const clientData = [{
    adminUserId: adminDetails?.adminId,
    firstName: adminDetails?.AdminUser?.firstName,
    lastName: adminDetails?.AdminUser?.lastName
  }]
  const adminData = isAffiliate || isAgent ? clientData : clientsFilterData?.rows
  const formik = useRef()
  const lang = getItem('language').toUpperCase()
  const [dob, setDob] = useState({
    day: '',
    month: '',
    year: ''
  })
  function isLeapYear (year) {
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)
  }
  const handlReset = () => {
    setDob({
      day: '',
      month: '',
      year: ''
    })
    formik.current.setFieldTouched('dateOfBirth',
      false
    )
    const inputRef = document.querySelector('.react-tel-input input')
    if (inputRef) {
      inputRef.value = ''
    }
  }
  useEffect(() => {
    let date
    if (dob.day && dob.month && dob.year) {
      const day = Number(dob.day)
      const month = Number(dob.month)
      const year = Number(dob.year)
      if (month === 2) {
        if (isLeapYear(year)) {
          if (day > 29) {
            date = null
          } else {
            date = format((new Date(`${year}/${month}/${day}`)), 'yyyy/MM/dd')
          }
        } else {
          if (day > 28) {
            date = null
          } else {
            date = format((new Date(`${year}/${month}/${day}`)), 'yyyy/MM/dd')
          }
        }
      } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        if (day > 30) {
          date = null
        } else {
          date = format((new Date(`${year}/${month}/${day}`)), 'yyyy/MM/dd')
        }
      } else {
        date = format((new Date(`${year}/${month}/${day}`)), 'yyyy/MM/dd')
      }
    } else {
      date = null
    }
    formik.current.setFieldValue('dateOfBirth',
      date
    )
  }, [dob])
  const [toggleViewPassword, setToggleViewPassword] = useState({
    password: false,
    confirmPassword: false
  })
  const onSubmit = (formValues, { resetForm }) => {
    formValues = {
      ...formValues,
      username: formValues.username.trim(),
      legalName: formValues.legalName.trim(),
      firstName: formValues.firstName.trim(),
      isUserV2: true
    }
    if (!isTenant) {
      const tenantId = isAffiliate || isAgent ? adminDetails?.tenantId : clientsFilterData?.rows?.find(row => row.adminUserId === Number(formValues.adminId))?.Tenants[0]?.tenantId
      dispatch(userCreateStart({
        formData: {
          ...formValues,
          tenantId: tenantId,
          adminId: Number(formValues.adminId),
          isTenant: false
        },
        resetForm: () => {
          resetForm()
          handlReset()
        },
        navigate,
        isAffiliate,
        isAgent
      }))
    } else {
      dispatch(userCreateStart({
        formData: formValues,
        resetForm: () => {
          resetForm()
          handlReset()
        },
        isTenant: true,
        navigate
      }))
    }
  }

  const showStar = (fieldName) =>
    <span className='text-danger mt-1'>&nbsp;*&nbsp;</span>
  const navigate = useNavigate()
  return (
    <div className='p-3'>
      <div className='mb-3'>
        <div role='button' className='d-flex mb-2 gap-3 align-items-center' onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faLeftLong} /> {t('goBack')}
        </div>
        <h3 className='mb-0'><FontAwesomeIcon icon={faUser} />&nbsp; {t('addNewPlayer')}</h3>
      </div>

      <Formik
        validateOnMount={false}
        innerRef={formik}
        initialValues={{
          username: '',
          password: '',
          confirmPassword: '',
          // userId: '',
          legalName: '',
          firstName: '',
          nationality: '',
          phone: '',
          phoneCode: '',
          preferredCurrency: '',
          bankName: '',
          accountNumber: '',
          withdrawalPassword: '',
          confirmPasswordWithdrawal: '',
          dateOfBirth: '',
          adminId: ''

        }}
        validationSchema={['EN'].includes(getItem('language')?.toUpperCase())
          ? userSigninSchemaEN(isTenant, t)
          : userSigninSchema(isTenant, t)}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          validateField
        }) => {
          return (
            <Form className=' signup-form1 d-flex flex-column gap-3'>
              <Row className=''>
                {!isTenant && (
                  <Col sm={6} className='mb-2'>
                    <div className=' d-flex  flex-column input-label'>

                      <div className='form-group'>
                        <label htmlFor='adminId'>
                          {t('selectSenior')}
                          {showStar()}
                        </label>
                        <BForm.Select
                          className='w-100 select-placeholder'
                          id='adminId'
                          name='adminId'
                          value={values.adminId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value='' disabled>
                            {t('selectSenior')}
                          </option>
                          {adminData?.map((option) => (
                            <option key={option.adminUserId} value={option.adminUserId}>
                              {option.firstName + ' ' + option.lastName}
                            </option>
                          ))}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='adminId'
                          className='text-danger mt-1'
                        />
                      </div>

                    </div>
                  </Col>
                )}
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='username'>
                        {t('usernameLabel')}
                        {showStar()}
                      </label>
                      <BForm.Control
                        name='username'
                        type='text'
                        className='form-control'
                        id='userName'
                        placeholder={t('userNamePlaceholder')}
                        aria-describedby='emailHelp'
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={50}
                      />
                      <ErrorMessage
                        component='div'
                        name='username'
                        key='username'
                        className='text-danger mt-1'
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group'>
                      <label htmlFor='firstName'>
                        {t('nickNameLabel')}
                        {showStar()}
                      </label>
                      <BForm.Control
                        name='firstName'
                        type='text'
                        className='form-control'
                        id='firstName'
                        placeholder={t('nickNamePlaceholder')}
                        aria-describedby='emailHelp'
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={50}
                      />
                      <ErrorMessage
                        component='div'
                        name='firstName'
                        key='firstName'
                        className='text-danger mt-1'
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>
                    <div className='form-group icon_include position-relative password-info'>
                      <label htmlFor='exampleInputPassword1'>
                        {t('passwordLabel')}
                        {showStar()}
                      </label>
                      &nbsp;
                      <input
                        name='password'
                        type={
                          toggleViewPassword.password
                            ? 'text'
                            : 'password'
                        }
                        className='form-control'
                        id='exampleInputPassword1'
                        value={values.password}
                        placeholder={t('passwordPlaceholder')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <div
                        style={{

                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='password'
                          key='password'
                          className='text-danger mt-1'
                        />
                      </div>
                      <span
                        className='pwd-icon'
                        onClick={() =>
                          setToggleViewPassword({
                            ...toggleViewPassword,
                            password: !toggleViewPassword.password
                          })}
                      >

                        {toggleViewPassword.password
                          ? <img alt='icon' src='/icon/Visible-eye.svg' style={{ width: '15px' }} />
                          : <img alt='icon' src='/icon/Close-eye.svg' style={{ width: '15px' }} />}

                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group icon_include position-relative'>
                      <label htmlFor='exampleInputPassword2'>
                        {t('confirmPasswordLabel')}
                        {showStar()}
                      </label>
                      <input
                        name='confirmPassword'
                        type={
                          toggleViewPassword.confirmPassword
                            ? 'text'
                            : 'password'
                        }
                        className='form-control'
                        id='exampleInputPassword2'
                        value={values.confirmPassword}
                        placeholder={t('confirmPasswordPlaceholder')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <ErrorMessage
                        component='div'
                        name='confirmPassword'
                        key='confirmPassword'
                        className='text-danger mt-1'
                      />

                      <span
                        className='pwd-icon'
                        // style={{ right: '25px' }}
                        onClick={() =>
                          setToggleViewPassword({
                            ...toggleViewPassword,
                            confirmPassword:
                              !toggleViewPassword.confirmPassword
                          })}
                      >
                        {toggleViewPassword.confirmPassword
                          ? <img alt='icon' src='/icon/Visible-eye.svg' style={{ width: '15px' }} />
                          : <img alt='icon' src='/icon/Close-eye.svg' style={{ width: '15px' }} />}
                      </span>
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='legalName'>
                        {t('legalNameLabel')}
                        {showStar()}
                      </label>
                      <input
                        name='legalName'
                        type='text'
                        className='form-control'
                        id='legalName'
                        placeholder={t('legalNamePlaceholder')}
                        aria-describedby='emailHelp'
                        value={values.legalName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={50}
                      />
                      <ErrorMessage
                        component='div'
                        name='legalName'
                        key='legalName'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='nationality'>
                        {t('nationalityLabel')}
                        {showStar()}
                      </label>
                      <BForm.Select
                        className='w-100 select-placeholder'
                        id='nationality'
                        name='nationality'
                        value={values.nationality}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('nationality', true)}
                      >
                        <option value='' disabled>
                          {t('selectNationality')}
                        </option>
                        {nationalitiesArr[lang]?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='nationality'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group '>
                      <label htmlFor='phone'>
                        {t('phoneLabel')}
                        {showStar()}
                      </label>

                      <PhoneInput
                        maxLength={20}
                        name='phone'
                        type='text'
                        countryCodeEditable={false}
                        aria-describedby='emailHelp'
                        alwaysDefaultMask={false}
                        enableSearch
                        placeholder={t('phonePlaceholder')}
                        enableLongNumbers
                        country='kr'
                        onChange={(phone, code) => {
                          code = String(code?.dialCode)
                          const newPhone = phone.substring(code.length)
                          const newCode = '+' + code
                          setFieldValue('phone', newPhone)
                          setFieldValue('phoneCode', newCode)
                        }}
                        searchPlaceholder={t('searchPhonePlaceholder')}
                        inputProps={{
                          maxLength: 20
                        }}
                        onBlur={() => setFieldTouched('phone', true)}
                        buttonStyle={{ backgroundColor: '#22214b' }}
                        inputStyle={{ width: '100%' }}

                      />
                      <ErrorMessage
                        component='div'
                        name='phone'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='preferredCurrency'>
                        {t('preferredCurrencyLabel')}
                        {showStar()}
                      </label>
                      <BForm.Select
                        className='w-100 select-placeholder'
                        id='preferredCurrency'
                        name='preferredCurrency'
                        value={values.preferredCurrency}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('preferredCurrency', true)}
                      >
                        <option value='' disabled>
                          {t('selectPreferredCurrency')}
                        </option>
                        {currenciesArr[lang]?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='preferredCurrency'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='bankName'>
                        {t('bankNameLabel')}
                        {showStar()}
                      </label>
                      <BForm.Select
                        className='w-100 select-placeholder'
                        id='bankName'
                        name='bankName'
                        value={values.bankName}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('bankName', true)}
                      >
                        <option value='' disabled>
                          {t('selectBankName')}
                        </option>
                        {banksArr[lang].map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='bankName'
                        className='text-danger mt-1'
                      />
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <label htmlFor='exampleAccountNumber'>
                        {t('accountNumberLabel')}
                        {showStar()}
                      </label>
                      <input
                        name='accountNumber'
                        type='text'
                        className='form-control'
                        placeholder={t('accountNumberPlaceholder')}
                        id='exampleAccountNumber'
                        aria-describedby='accountNumberHelp'
                        value={values.accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                      />
                      <div
                        style={{

                          position: 'relative'
                        }}
                      >
                        <ErrorMessage
                          component='div'
                          name='accountNumber'
                          key='accountNumber'
                          className='text-danger mt-1'
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group icon_include position-relative password-info'>
                      <label htmlFor='exampleInputwithdrawalPassword'>
                        {t('withdrawalPasswordLabel')}
                        {showStar()}
                      </label>
                      <input
                        name='withdrawalPassword'
                        type={
                          toggleViewPassword.withdrawalPassword
                            ? 'text'
                            : 'password'
                        }
                        className='form-control'
                        id='exampleInputwithdrawalPassword'
                        value={values.withdrawalPassword}
                        placeholder={t('passwordWithdrawalPlaceholder')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <ErrorMessage
                        component='div'
                        name='withdrawalPassword'
                        key='withdrawalPassword'
                        className='text-danger mt-1'
                      />
                      <span
                        className='pwd-icon'
                        onClick={() =>
                          setToggleViewPassword({
                            ...toggleViewPassword,
                            withdrawalPassword: !toggleViewPassword.withdrawalPassword
                          })}
                      >

                        {toggleViewPassword.withdrawalPassword
                          ? <img alt='icon' src='/icon/Visible-eye.svg' style={{ width: '15px' }} />
                          : <img alt='icon' src='/icon/Close-eye.svg' style={{ width: '15px' }} />}

                      </span>
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group icon_include position-relative'>
                      <label htmlFor='exampleInputReWithdrawalPassword'>
                        {t('reEnterwithdrawalPasswordLabel')}{' '}
                        {showStar()}
                      </label>
                      <input
                        name='confirmPasswordWithdrawal'
                        type={
                          toggleViewPassword.confirmPasswordWithdrawal
                            ? 'text'
                            : 'password'
                        }
                        className='form-control'
                        id='exampleInputReWithdrawalPassword'
                        value={values.confirmPasswordWithdrawal}
                        placeholder={t('confirmPasswordWithdrawalPlaceholder')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <ErrorMessage
                        component='div'
                        name='confirmPasswordWithdrawal'
                        key='confirmPasswordWithdrawal'
                        className='text-danger mt-1'
                      />

                      <span
                        className='pwd-icon'
                        onClick={() =>
                          setToggleViewPassword({
                            ...toggleViewPassword,
                            confirmPasswordWithdrawal:
                              !toggleViewPassword.confirmPasswordWithdrawal
                          })}
                      >
                        {toggleViewPassword.confirmPasswordWithdrawal
                          ? <img alt='icon' src='/icon/Visible-eye.svg' style={{ width: '15px' }} />
                          : <img alt='icon' src='/icon/Close-eye.svg' style={{ width: '15px' }} />}
                      </span>
                    </div>

                  </div>
                </Col>
              </Row>

              <div className=' d-flex  flex-column input-label'>
                <label for='exampleModalLabel'>
                  {t('dateOfBirthLabel')} <sup>*</sup>
                </label>
                <div className='d-flex gap-2 '>

                  <div className='w-100'>
                    <BForm.Select
                      className='w-100 select-placeholder'
                      value={dob.month}
                      onChange={(e) => {
                        setDob({ ...dob, month: e.target.value })
                      }}
                    >
                      <option value='' disabled>{t('selectMonth')}</option>
                      {month[lang].map(month =>
                        <option
                          value={month.value}
                          key={month.value}
                        >
                          {month.label}
                        </option>
                      )}
                    </BForm.Select>
                  </div>
                  <div className='w-100'>
                    <BForm.Select
                      className='w-100 select-placeholder'
                      value={dob.day}
                      onChange={(e) => {
                        setDob({ ...dob, day: e.target.value })
                      }}
                    >
                      <option value='' disabled>{t('selectDay')}</option>
                      {date.map(date =>
                        <option
                          value={date}
                          key={date}
                          className={date === parseInt(new Date(values.dateOfBirth)?.getDate()) ? 'active-date' : ''}
                        >
                          {date}
                        </option>
                      )}
                    </BForm.Select>
                  </div>
                  <div className='w-100'>
                    <BForm.Select
                      className='w-100 select-placeholder'
                      value={dob.year}
                      onChange={(e) => {
                        setDob({ ...dob, year: e.target.value })
                      }}
                    >
                      <option value='' disabled>{t('selectYear')}</option>
                      {year.map(year =>
                        <option
                          value={year}
                          key={year}
                        >
                          {year}
                        </option>
                      )}
                    </BForm.Select>
                  </div>

                </div>
                <ErrorMessage
                  component='div'
                  name='dateOfBirth'
                  className='text-danger mt-1'
                />
              </div>
              <div className='d-flex '>
                <Button
                  variant='outline-success'
                  type='submit'
                  className='submit-btn justify-content-center '
                  style={{ minWidth: 200 }}
                >
                  {t('create')}
                  {userCreateLoading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </div>

            </Form>
          )
        }}
      </Formik>

    </div>
  )
}

export default CreateUser
