import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllClientsStart } from '../../../store/redux-slices/tenants'
import { getTenantCountriesStart, getCountriesStart } from '../../../store/redux-slices/fetchData'
import { getAffiliateDetailStart } from '../../../store/redux-slices/affiliate'

const useCreateAffiliates = (isTenant, isView, isEdit) => {
  const { t } = useTranslation()
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const { affiliateId } = useParams()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const [allowedLanguages, setAllowedLanguages] = useState([
    {
      label: 'English',
      value: 'EN'
    }
  ])
  const langOptions = []
  const { languages } = useSelector(state => state.languages)

  languages &&
    languages.forEach(function (language) {
      langOptions.push({ label: language.languageName, value: language.code.toUpperCase() })
    })

  const { adminUserId } = useParams()
  const [type, setType] = useState('password')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [country, setCountry] = useState(null)

  useEffect(() => {
    if (isTenant) {
      setCountry(tenantCountries?.rows)
    } else {
      setCountry(countries)
    }
  }, [isTenant, countries, tenantCountries])
  useEffect(() => {
    if (!isTenant) {
      dispatch(getAllClientsStart({
        search: '',
        limit: '',
        orderBy: 'email',
        sort: 'desc'
      }))
      dispatch(getCountriesStart({
        limit: '',
        name: '',
        pageNo: '',
        isActive: '',
        kycMethod: ''
      }))
    } else dispatch(getTenantCountriesStart())
    if (isView || isEdit) dispatch(getAffiliateDetailStart({ affiliateId: affiliateId, isTenant: isTenant }))
  }, [])

  return {
    navigate,
    adminUserId,
    type,
    setType,
    clientsFilterData,
    allowedLanguages,
    setAllowedLanguages,
    langOptions,
    country,
    t
  }
}

export default useCreateAffiliates
