import { Button, Modal, Row, Col, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { uploadBannerSchema } from './schema'
import { returnValidation } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

const UploadBanner = ({
  show,
  setShow,
  upload,
  data,
  validation,
  setValidation,
  loading
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Update')} {t('banner')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              file: null
            }}
            validationSchema={uploadBannerSchema(
              {
                ...returnValidation(data?.type)
              })}
            onSubmit={(formValues) => {
              upload(formValues)
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => (
              <Form>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <Col>
                      <BForm.Label>{t('banner')}
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                    </Col>

                    <Col xs={9}>
                      <BForm.Control
                        type='file'
                        name='file'
                        onChange={(event) => {
                          setFieldValue('file', event.target.files[0])
                        }}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name='file'
                        className='text-danger'
                      />
                    </Col>
                  </Col>
                </Row>

                {!errors?.file &&
                  <Row className='text-center'>
                    <Col>
                      <img
                        alt='banner-file'
                        className='mt-2'
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                        src={
                            values?.file
                              ? URL.createObjectURL(values?.file)
                              : data?.imageUrl
                          }
                      />
                    </Col>
                  </Row>}

                <div className='mt-4 d-flex justify-content-between align-items-center'>
                  <Button
                    type='button'
                    variant='outline-warning'
                    onClick={() => setShow(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    type='submit'
                    disabled={!values?.file}
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    {t('submit')} {loading && <Spinner className='ms-1' size='sm' />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UploadBanner
