import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { superAdminLoginStart } from '../../../store/redux-slices/login'
import { getLoginToken, getItem } from '../../../utils/storageUtils'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../../../routes'
import { useTranslation } from 'react-i18next'

const useSuperAdminSignin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, twoStepsEnabled } = useSelector((state) => state.login)
  const [showPassword, setShowPassword] = useState(false)
  const [loginData, setLoginData] = useState({ user: '', password: '' })

  useEffect(() => {
    if (getLoginToken() && getItem('role') === 'Admin') {
      navigate(TenantRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Affiliate') {
      navigate(AffiliateRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Agent') {
      navigate(AgentRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Super Admin') {
      navigate(SuperAdminRoutes.Dashboard)
    }
  }, [])

  const handleSignIn = (data) => {
    setLoginData({ ...data })
    dispatch(superAdminLoginStart({ ...data, navigate }))
  }

  return {
    loading,
    showPassword,
    setShowPassword,
    navigate,
    handleSignIn,
    twoStepsEnabled,
    loginData,
    t
  }
}

export default useSuperAdminSignin
