import { Button, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  tableHeaders,
  requestActions
} from './constants'
import {
  Col,
  Form,
  Row,
  Spinner
} from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import { WalletActionConfirmModal } from '../../../components/ConfirmationModal'
import PaginationComponent from '../../../components/Pagination'
import UseUserWallet from './hooks/useUserWallet'
import ClientFilter from '../../../components/ClientFilter'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../utils/storageUtils'

const AddOnConfig = ({
  t,
  addOnConfigDropDown,
  addOnSelect,
  setAddOnSelect,
  item,
  isHistory,
  handleGetAddonConfig,
  depositAddonConfigSelectedType,
  depositAddonType
}) => {
  const isBothArraysEmpty = depositAddonType?.length === 0 || depositAddonConfigSelectedType?.length === 0
  return (
    <div className='d-flex flex-column gap-1' style={{ minWidth: 240 }}>
      {
          isHistory
            ? (
              <>
                <div className='d-flex align-items-center gap-1'>
                  <span className='flex-title'>
                    {t('addOnConfigType')}:
                  </span>
                  <b>  {item?.addOnConfigType || '-'}</b>
                </div>

                {item?.addOnConfigType === 'HOT_TIME_BONUS'
                  ? (
                    <>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('addOnAmount')}:
                        </span>
                        <b>  {formatNumber(item?.BonusTransaction?.amount) || 0}</b>
                      </div>
                    </>
                    )
                  : (
                    <>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {item?.relativeAmount ? t('addOnRelativeAmount') : t('addOnPercentage')}:
                        </span>
                        <b>  {item?.relativeAmount
                          ? formatNumber(item?.relativeAmount)
                          : (
                            <>
                              {item?.depositPercentage}%
                            </>
                            )}
                        </b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('rollingPercentage')}:
                        </span>
                        <b>  {item?.rollingPercentage || 0}%</b>
                      </div>

                    </>
                    )}

              </>
              )

            : (
                (
                  isBothArraysEmpty
                    ? (
                        t('pleaseSpecifyDepositConfig')
                      )
                    : (
                      <>
                        <div>
                          <Form.Select
                            value={addOnSelect?.type}
                            onChange={(e) =>
                              setAddOnSelect((prev) => ({
                                ...prev,
                                type: e.target.value
                              }))}
                            style={{ maxWidth: '230px' }}
                            size='sm'
                          >
                            <option value=''>{t('selectAddOnConfigType')}</option>
                            {depositAddonType?.map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </Form.Select>

                          {addOnSelect?.type !== 'hotTimeBonus' && (
                            addOnSelect?.type && (
                              <div className='pt-1'>
                                <Form.Select
                                  value={addOnSelect?.name}
                                  onChange={(e) =>
                                    setAddOnSelect((prev) => ({
                                      ...prev,
                                      name: e.target.value
                                    }))}
                                  style={{ maxWidth: '230px' }}
                                  size='sm'
                                >
                                  <option value=''>{t('selectAddOnConfigValueType')}</option>
                                  {depositAddonConfigSelectedType
                                    ?.filter((option) => option !== 'rollingPercentage')
                                    .map((option) => (
                                      <option key={option} value={option}>
                                        {t(option)}
                                      </option>
                                    ))}

                                </Form.Select>
                              </div>
                            )
                          )}

                          {
                          addOnSelect?.type !== 'hotTimeBonus'
                            ? (
                                addOnSelect?.name && addOnSelect?.type && (
                                  <>
                                    <div className='d-flex align-items-center gap-1'>
                                      <span className='flex-title'>{t(addOnSelect?.name)}:</span>
                                      <b>{addOnConfigDropDown[addOnSelect?.type][addOnSelect?.name]}</b>
                                    </div>
                                    <div className='d-flex align-items-center gap-1'>
                                      <span className='flex-title'>{t('rollingPercentage')}:</span>
                                      <b>{addOnConfigDropDown[addOnSelect?.type].rollingPercentage || 0}</b>
                                    </div>
                                  </>
                                )
                              )
                            : (
                              <>
                                <div className='d-flex align-items-center gap-1'>
                                  <span className='flex-title'>{t('amount')}:</span>
                                  <b>{addOnConfigDropDown[addOnSelect?.type]?.amount + ' ₩' || 0}</b>
                                </div>
                                <div className='d-flex align-items-center gap-1'>
                                  <span className='flex-title'>{t('maxBonusLimit')}:</span>
                                  <b>{addOnConfigDropDown[addOnSelect?.type]?.maxBonusLimit + ' ₩' || 0}</b>
                                </div>
                                <div className='d-flex align-items-center gap-1'>
                                  <span className='flex-title'>{t('bonusPercent')}:</span>
                                  <b>{addOnConfigDropDown[addOnSelect?.type]?.bonusPercent + ' %' || 0}</b>
                                </div>
                                <div className='d-flex align-items-center gap-1'>
                                  <span className='flex-title'>{t('amountToBeAdded')}:</span>
                                  <b>{addOnConfigDropDown[addOnSelect?.type]?.amountToBeAdded + ' ₩' || 0}</b>
                                </div>
                              </>
                              )
                        }
                        </div>
                      </>
                      )
                )

              )
      }

    </div>
  )
}
const SingleRow = ({
  t,
  addOnConfigDropDown,
  item,
  page,
  limit,
  index,
  isV2,
  isTransaction,
  hidePlayerStats,
  handleShowModal,
  isHistory,
  handleGetAddonConfig,
  depositAddonConfigSelectedType,
  depositAddonType,
  activeRow,
  setActiveRow,
  dropLoading,
  isDeposit,
  isWithdrawal
}) => {
  const [addOnSelect, setAddOnSelect] = useState(
    {
      type: '',
      name: ''
    })
  const renderStatus = (status) => {
    if (status === 'ACCEPT') {
      return <span className='fw-bolder text-success'>{t('accepted')}</span>
    } else if (status === 'CANCEL') {
      return <span className='fw-bolder text-danger'>{t('canceled')}</span>
    } else {
      return <span className='fw-bolder text-danger'>{t('rejected')}</span>
    }
  }
  return (
    <tr className={item?.isRequestTimeout ? 'under-inspection' : ''}>
      <td>{(page - 1) * limit + index + 1}</td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('userName')}:
            </span>
            <b> {item?.username || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('fullName')}:
            </span>
            <b>  {item?.name || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('affiliate')}:
            </span>
            <b> {item?.Affiliate?.username || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('agent')}:
            </span>
            <b> {item?.Agent?.username || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('ip')}:
            </span>
            <b>  {(item?.ipAddress?.split('::ffff:')?.[1] ?? item.ipAddress) || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('phone')}:
            </span>
            <b> {item?.User?.phoneCode}{item?.User?.phone}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('nickname')}:
            </span>
            <b> {item?.User?.firstName ?? `player_${item?.userId}`}</b>
          </div>

        </div>
      </td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='align-items-center '>
              {t('bankName')}:
            </span>
            <b className='d-flex text-wrap'> {item?.User?.bankName || 'NA'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('accountNumber')}:
            </span>
            <b>  {item?.User?.accountNumber || 'NA'}</b>
          </div>
        </div>
      </td>
      {isV2 && isDeposit && (
        <td>
          {item?.numberOfAcceptedRequests}
        </td>
      )}
      {isV2 && isDeposit && (

        <td>
          {
          (item?.addOnConfigType === null || item?.addOnConfigType !== 'UNIQUE_CODE_BONUS')
            ? (
                depositAddonType?.length === 0 && depositAddonConfigSelectedType?.length === 0 && !isHistory
                  ? (
                    <Button
                      variant='outline-success'
                      type='submit'
                      className='submit-btn justify-content-center'
                      style={{ minWidth: 200 }}
                      onClick={() => {
                        handleGetAddonConfig(item)
                        setActiveRow(item?.userId)
                      }}
                    >
                      {t('applyAddon')}
                    </Button>
                    )
                  : (
                    <>
                      {item?.userId === activeRow || isHistory
                        ? (
                            !dropLoading
                              ? <AddOnConfig
                                  t={t}
                                  addOnSelect={addOnSelect}
                                  setAddOnSelect={setAddOnSelect}
                                  item={item}
                                  isHistory={isHistory}
                                  handleGetAddonConfig={handleGetAddonConfig}
                                  depositAddonConfigSelectedType={depositAddonConfigSelectedType}
                                  depositAddonType={depositAddonType}
                                  addOnConfigDropDown={addOnConfigDropDown}
                                />
                              : <Spinner className='ms-2' size='sm' animation='border' role='status' />
                          )
                        : (
                          <Button
                            variant='outline-success'
                            type='submit'
                            className='submit-btn justify-content-center'
                            style={{ minWidth: 200 }}
                            onClick={() => {
                              handleGetAddonConfig(item)
                              setActiveRow(item?.userId)
                            }}
                          >
                            {t('applyAddon')}
                          </Button>
                          )}
                    </>

                    )
              )
            : (
                '-'
              )
          }
        </td>

      )}
      {
        isV2 && isDeposit && (

          <td>
            {
item?.addOnConfigType !== null && item?.addOnConfigType === 'UNIQUE_CODE_BONUS'
  ? (

    <div className='d-flex flex-column gap-1'>
      <div className='d-flex align-items-center gap-1'>
        <span className='flex-title'>
          <b>{t('uniqueCodeBonus')}</b>
        </span>
      </div>
      <div className='d-flex align-items-center gap-1'>
        <span className='flex-title'>
          {t('couponName')}:
        </span>
        <b> {item?.Voucher?.voucher || 'NA'}</b>
      </div>
      <div className='d-flex align-items-center gap-1'>
        <span className='flex-title'>
          {t('remainingUses')}:
        </span>
        <b>  {!isHistory ? item?.Voucher?.voucherUseLimit - item?.numberOfVouchersUsed : item?.Voucher?.voucherUseLimit - item?.uniqueCodeBonusUsage || 'NA'}</b>
      </div>
      <div className='d-flex align-items-center gap-1'>
        <span className='flex-title'>
          {t('benefitAmount')}:
        </span>
        <b>  {item?.Voucher?.voucherBenefits + ' ₩' || 'NA'}</b>
      </div>
    </div>
    )
  : (
      '-'
    )
        }
          </td>
        )
      }
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('transactionType')}:
            </span>
            <b>   {item?.transactionType || 'REQUESTED'}</b>
          </div>
          <div className='d-flex text-left gap-1'>
            <span className='flex-title'>
              {t('transactionId')}:
            </span>
            <b className='text-wrap' title={item?.transactionId}> {item?.transactionId}</b>
          </div>
        </div>
      </td>
      <td>
        {isTransaction
          ? moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss') || '-'
          : (
            <div className='d-flex flex-column gap-1'>
              <div className='d-flex align-items-center gap-1'>
                <span className='flex-title'>
                  {t('requestedCreatedAt')}:
                </span>
                <b> {moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss') || '-'}</b>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <span className='flex-title'>
                  {t('actionTakenAt')}:
                </span>
                <b>   {moment(item?.updatedAt).format('MM-DD-YYYY HH:mm:ss') || '-'}</b>
              </div>
            </div>
            )}
      </td>
      <td>
        <b>
          {formatNumber(item?.amount)} ₩

        </b>
      </td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('seniorName')}:
            </span>
            <b>  {item?.adminName || '-'}</b>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='flex-title'>
              {t('portal')}:
            </span>
            <b className='d-flex text-wrap'>
              <a className='anchor-tag' href={item?.domain} target='_blank' rel='noopener noreferrer'>{item?.domain}</a>
            </b>
          </div>
        </div>
      </td>
      {hidePlayerStats &&
        <td>
          <div className='d-flex flex-column gap-1'>
            <div className='d-flex align-items-center gap-1'>
              <span className='flex-title'>
                {t('totalBetAmount')}:
              </span>
              <b title={item?.moreDetails?.totalBetAmount}> {item?.moreDetails?.totalBetAmount ?? 0} ₩</b>
            </div>
            <div className='d-flex align-items-center gap-1'>
              <span className='flex-title'>
                {t('totalDepositAmount')}:
              </span>
              <b title={item?.moreDetails?.totalDepositedAmount}> {item?.moreDetails?.totalDepositedAmount ?? 0} ₩</b>
            </div>
            <div className='d-flex align-items-center gap-1'>
              <span className='flex-title'>
                {t('totalBonusAmount')}:
              </span>
              <b title={item?.moreDetails?.totalBonusAmount}> {item?.moreDetails?.totalBonusAmount ?? 0} ₩</b>
            </div>
          </div>
        </td>}
      <td>
        <div className='d-flex flex-column gap-1'>
          <div className='d-flex justify-content-center align-items-center gap-1'>
            {item.status === 'PENDING' && item?.actionType !== 'COMPENSATION-BONUS-DEPOSIT'
              ? (
                <>
                  <Trigger message={t('acceptRequest')}>
                    <Button
                      className='m-1 fw-bolder px-2'
                      size='sm'
                      variant='success'
                      onClick={() => handleShowModal('accept',
                        item?.actionType,
                        'ACCEPT',
                        item?.transactionId,
                        item?.tenantId,
                        item?.TenantConfiguration,
                        item?.addOnConfigType === null ? addOnSelect : 'UNIQUE_CODE_BONUS'
                      )}
                      disabled={!isWithdrawal &&
                          isV2 && !(item?.addOnConfigType === 'UNIQUE_CODE_BONUS' || (addOnSelect?.name && addOnSelect.type) || addOnSelect?.type === 'hotTimeBonus')}
                    >
                      {t('accept')}
                    </Button>
                  </Trigger>
                  <Trigger message={t('rejectRequest')}>
                    <Button
                      className='m-1 fw-bolder px-2'
                      size='sm'
                      variant='danger'
                      disabled={item.transactionType === 'NON-REQUEST' || item?.status !== 'PENDING'}
                      onClick={() => handleShowModal('reject',
                        item.actionType,
                        'REJECT',
                        item?.transactionId,
                        item?.tenantId,
                        item?.TenantConfiguration,
                        addOnSelect
                      )}
                    >
                      {t('reject')}
                    </Button>
                  </Trigger>
                </>
                )
              : (

                  renderStatus(item?.status)

                )}
          </div>
        </div>
      </td>

    </tr>
  )
}

const UserWallet = ({
  isDeposit = false,
  isWithdrawal = false,
  isHistory = false,
  isVault = false,
  isSubPayment = false,
  isTransaction = false,
  isV2 = false
}) => {
  const {
    formData,
    setFormData,
    setState,
    state,
    handleShowModal,
    setShowWalletRequestAction,
    showWalletRequestAction,
    handleConfirmActionRequest,
    walletRequestAction,
    userWalletTransactions,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    handleCsvDownload,
    inputRef,
    initialDate,
    loading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    handleGetAddonConfig,
    addOnConfigDropDown,
    dropLoading
  } = UseUserWallet({ isWithdrawal, isHistory, isVault, isSubPayment, isV2, isDeposit })
  const [depositAddonType, setDepositAddonType] = useState([])
  const [depositAddonConfigSelectedType, setDepositAddonConfigSelectedType] = useState([])
  const [activeRow, setActiveRow] = useState(null)

  useEffect(() => {
    if (addOnConfigDropDown !== undefined) {
      setDepositAddonType(addOnConfigDropDown ? Object?.keys(addOnConfigDropDown) : [])
      setDepositAddonConfigSelectedType(addOnConfigDropDown.defaultDepositConfig ? Object.keys(addOnConfigDropDown.defaultDepositConfig) : [])
    }
  }, [addOnConfigDropDown])
  const dashboardData = userWalletTransactions?.detailsCounts?.[0]
  const hidePlayerStats = !isHistory && isWithdrawal && !isSubPayment

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{isSubPayment ? isWithdrawal ? t('adminWithdrawSubpayment') : t('adminDepositSubpayment') : isVault ? isWithdrawal ? t('vaultWithdrawalHistory') : t('vaultDepositHistory') : isWithdrawal ? isHistory ? t('withdrawalHistory') : t('withdrawalRequest') : isHistory ? t('depositHistory') : t('depositRequest')}</h3>
          </Col>
        </Col>
      </Row>

      <div className='filter-wrapper'>
        <div className='d-flex flex-wrap align-items-center gap-2'>
          <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
          <AffiliateAgentFilter
            selectedClient={selectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
          />
          {isHistory && !isVault && (
            <div className='d-flex align-items-center'>
              <Form.Label
                column='sm' style={{
                  marginBottom: '0',
                  marginRight: '15px',
                  minWidth: 'fit-content'
                }}
              >
                {t('requestStatus')}
              </Form.Label>
              <Form.Select
                value={formData?.actionType}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    actionType: e.target.value
                  })
                  setPage(1)
                }}
                style={{
                  marginBottom: '0',
                  marginRight: '15px',
                  maxWidth: '230px',
                  width: 'auto'
                }}
                size='sm'
              >
                {requestActions?.map((requestAction) => (
                  <option
                    key={requestAction.value}
                    value={requestAction.value}
                  >
                    {t(requestAction.label)}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
          <div xs='auto' className='d-flex'>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex'>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchUsernameOrName')}
              size='sm'
              style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <div className='d-flex'>
              <Trigger message={t('downloadAsCSV')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  disabled={userWalletTransactions?.walletTranactionDetails?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
            </div>
            <div className='d-flex'>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  className='py-1'
                  size='sm'
                  onClick={() => {
                    setFormData(initalWalletState)
                    setState(initialDate)
                    setSelectedClient('')
                    inputRef.current.value = ''
                    setAgent('')
                    setAffiliate('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>

        </div>

      </div>
      <div className='table-dashboard'>
        <div className='item-wrapper'>
          <span className='item-heading'>
            {t('now')}
          </span>
        </div>
        {!isHistory && (
          <>
            <div className='item-wrapper'>
              <span className='item-heading'>
                {isSubPayment ? isWithdrawal ? t('totalWithdrawalCount') : t('totalDepositCount') : isWithdrawal ? t('totalWithdrawalRequest') : t('totalDepositRequest')}
              </span>
              <b className='item-content'>{isSubPayment ? formatNumber(dashboardData?.ACCEPTED || 0) : formatNumber(dashboardData?.PENDING || 0)} {t('case')}</b>
            </div>
          </>
        )}
        {isHistory && (
          <>
            <div className='item-wrapper'>
              <span className='item-heading'>
                {t('confirm')}
              </span>
              <b className='item-content'>{formatNumber(dashboardData?.ACCEPTED || 0)} {t('case')}</b>
            </div>
            {!isVault && (
              <>
                <div className='item-wrapper'>
                  <span className='item-heading'>
                    {t('reject')}
                  </span>
                  <b className='item-content'>{formatNumber(dashboardData?.REJECTED || 0)} {t('case')}</b>
                </div>
                <div className='item-wrapper'>
                  <span className='item-heading'>
                    {t('cancel')}
                  </span>
                  <b className='item-content'>{formatNumber(dashboardData?.CANCELED || 0)} {t('case')}</b>
                </div>
              </>
            )}
          </>
        )}
        <div className='item-wrapper'>
          <span className='item-heading'>
            {isWithdrawal ? t('totalWithdrawalAmount') : t('totalDepositAmount')}
          </span>
          <b className='item-content'>{formatNumber(dashboardData?.amount || 0)} ₩</b>
        </div>

      </div>
      <div className='custom-table  mt-4'>
        <Table responsive className='text-center '>
          <thead className=''>
            <tr>
              {tableHeaders(isV2, isDeposit).map((h, idx) => {
                if (!hidePlayerStats && h.label === 'playerStats') {
                  return null
                }
                return (
                  <th key={h.label}>
                    {t(h.label)} &nbsp;
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && userWalletTransactions &&
              userWalletTransactions?.walletTranactionDetails?.rows?.map((item, index) => {
                return (
                  <SingleRow
                    key={index}
                    t={t}
                    item={item}
                    page={page}
                    limit={limit}
                    index={index}
                    isV2={isV2}
                    isTransaction={isTransaction}
                    hidePlayerStats={hidePlayerStats}
                    handleShowModal={handleShowModal}
                    isHistory={isHistory}
                    handleGetAddonConfig={handleGetAddonConfig}
                    depositAddonConfigSelectedType={depositAddonConfigSelectedType}
                    depositAddonType={depositAddonType}
                    addOnConfigDropDown={addOnConfigDropDown}
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                    dropLoading={dropLoading}
                    isDeposit={isDeposit}
                    isWithdrawal={isWithdrawal}
                  />
                )
              })}
            {!loading && userWalletTransactions?.walletTranactionDetails?.count === 0
              ? (
                <tr>
                  <td colSpan={8} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
                )
              : <></>}
          </tbody>
        </Table>
      </div>
      {!loading && userWalletTransactions?.walletTranactionDetails?.count !== 0 && (
        <PaginationComponent
          page={userWalletTransactions?.walletTranactionDetails?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={userWalletTransactions?.walletTranactionDetails?.count}
        />
      )}
      {showWalletRequestAction && (
        <WalletActionConfirmModal
          action={walletRequestAction}
          setShow={setShowWalletRequestAction}
          show={showWalletRequestAction}
          handleConfirmActionRequest={handleConfirmActionRequest}
        />
      )}
    </div>
  )
}

export default UserWallet
