import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguagesStart, languageStatusStart } from '../../../../store/redux-slices/languages'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'
import { useTranslation } from 'react-i18next'

const useLanguages = () => {
  const { t } = useTranslation()
  const [isLanguageStatusOpen, setIsLangugeStatusOpen] = useState(false)
  const [langugageData, setLanguageData] = useState({})
  const dispatch = useDispatch()
  const { languages, loading } = useSelector(state => state?.languages)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [name, setName] = useState('')
  const totalPages = Math.ceil(languages?.count / limit)
  const isInitialRender = useDidMountEffect()

  useEffect(() => {
    dispatch(getLanguagesStart({ limit, pageNo, name }))
  }, [limit, pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          dispatch(getLanguagesStart({ limit, pageNo, name }))
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [name])

  useEffect(() => {
    !isInitialRender && dispatch(getLanguagesStart({ limit, pageNo, name }))
  }, [pageNo])

  useEffect(() => {
    setPageNo(1)
    dispatch(getLanguagesStart({ limit, pageNo, name }))
  }, [limit])
  const handleLanguageStatus = () => {
    dispatch(languageStatusStart({ ...langugageData }))
    setIsLangugeStatusOpen(false)
  }
  return {
    loading,
    languages,
    limit,
    pageNo,
    totalPages,
    setPageNo,
    setLimit,
    name,
    setName,
    handleLanguageStatus,
    isLanguageStatusOpen,
    setIsLangugeStatusOpen,
    langugageData,
    setLanguageData,
    t
  }
}

export default useLanguages
