import React from 'react'
import { useTranslation } from 'react-i18next'
import useViewSeason from './hooks/useViewSeason'
import moment from 'moment'
import { formatNumber } from '../../../utils/storageUtils'
import Preloader from '../../../components/Preloader'
import { Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import PieChart from '../../../components/charts/PieCharts'
import { useNavigate } from 'react-router-dom'
const ViewSeason = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { seasonDetail, loading } = useViewSeason()

  return (
    <>
      {loading && <Preloader />}
      <div className='p-3'>
        <Button variant='warning' size='sm' role='button' className='d-flex mb-3 gap-3 align-items-center' onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faLeftLong} /> {t('goBack')}
        </Button>
        <h3>{t('detailsOfSeason')}</h3>

        <div className='d-flex flex-column gap-3 mt-3'>
          <div className='d-flex align-items-start gap-4'>
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex gap-2 align-items-center'>
                <b style={{ width: 120 }}>{t('season')}</b>
                <span>{seasonDetail?.season?.seasonName}</span>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <b style={{ width: 120 }}>{t('template')}</b>
                <span>{seasonDetail?.season?.template?.templateName}</span>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <b style={{ width: 120 }}>{t('startDate')}</b>
                <span> {moment(seasonDetail?.season?.startDate).format('MM-DD-YYYY HH:mm')}  </span>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <b style={{ width: 120 }}>{t('endDate')}</b>
                <span>{moment(seasonDetail?.season?.startDate).format('MM-DD-YYYY HH:mm')}</span>
              </div>
            </div>
            {seasonDetail?.rankLevelDistribution?.length > 0 && (
              <div className='d-flex justify-content-center flex-grow-1'>
                <div style={{ marginTop: '-80px' }}>
                  <PieChart
                    series={seasonDetail?.rankLevelDistribution?.map((item) => Number(item?.count))}
                    labels={seasonDetail?.rankLevelDistribution?.map((item) => {
                      if (item?.rank) {
                        return item?.rank
                      } else {
                        return t('base')
                      }
                    })}
                    height={350}
                    width={350}
                  />
                </div>
              </div>
            )}
          </div>
          <div className=''>
            <b style={{ width: 120 }}>{t('ranks')}:</b>
            <Table bordered striped responsive hover size='sm' className='text-center mt-2'>
              <thead className='thead-dark'>
                <tr>
                  {['srNo', 'rank', 'thresholdAmount', 'benefit', 'benefitsType'].map((h, idx) => (
                    <th key={idx}>
                      {t(h)}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {seasonDetail?.rankDetails?.map((rank, index) => {
                  return (
                    <tr key={index}>
                      <td className='py-3'>{index + 1}</td>
                      <td className='py-3'>{rank?.rank?.rank}</td>
                      <td className='py-3'>{formatNumber(rank?.thresholdAmount)}</td>
                      <td className='py-3'>{rank?.benefit
                        ? (
                          <>
                            {formatNumber(rank?.benefit)}
                            {rank?.benefitType === 'BONUS' ? '%' : ' ₩'}

                          </>
                          )
                        : '-'}
                      </td>
                      <td className='py-3'>{rank?.benefitType ? t(rank?.benefitType) : '-'}</td>
                    </tr>
                  )
                })}

              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSeason
