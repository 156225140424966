import React from 'react'
import RollingContestTable from '../../../components/ReportingTable/RollingContestTable'

const RollingContestReport = () => {
  return (
    <RollingContestTable />
  )
}

export default RollingContestReport
