import React, { useState } from 'react'
import {
  Button,
  Modal,
  Form as BForm,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { casinoSchema, editCasinoSchema } from '../schema'
import useCreateCasinoGame from '../hooks/useCreateCasinoGame'
import { getItem } from '../../../../utils/storageUtils'

const CreateCasinoGames = ({
  handleClose,
  data,
  selectedCasinoProviderId,
  limit,
  pageNo,
  t,
  active,
  selectedSubCategoryId,
  selectedProvider,
  defaultRtp,
  fetchData
}) => {
  const {
    loading,
    updateCasinoGame,
    createCasinoGame,
    specificGameData,
    languages
  } = useCreateCasinoGame({ fetchData })
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const isRTP = data?.defaultRtp
  const getTranslatedGameName = (translations, languageCode) => {
    return translations?.[languageCode] || ''
  }

  const initialValues = specificGameData
    ? {
        gameName: data?.translations?.[getItem('language')] || data?.name,
        operatorGameId: data?.identifier,
        providerName: data['MasterCasinoProvider.name'],
        thumbnail: data?.thumbnailUrl || null,
        isActive: data?.isActive,
        subCategoryName: data['MasterGameSubCategory.name'].EN,
        languageCode: getItem('language') || 'en',
        rtpValue: data?.returnToPlayer || '',
        systemRtp: data?.systemRtp || ''
      }
    : {
        gameName: '',
        operatorGameId: '',
        casinoProviderId: null,
        thumbnail: '',
        isActive: false,
        languageCode: 'en'
      }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={specificGameData ? editCasinoSchema(t) : casinoSchema(t)}
      onSubmit={(formValues) => {
        const formData = new FormData()
        formData.append('gameName', formValues.gameName)
        formData.append('operatorGameId', formValues.operatorGameId)
        formData.append('providerName', formValues.providerName)
        formData.append('subCategoryName', formValues.subCategoryName)
        formData.append('languageCode', formValues.languageCode)
        formData.append('thumbnail', thumbnailFile)
        formData.append('rtpValue', formValues.rtpValue)
        formData.append('masterCasinoGameId', data?.masterCasinoGameId)
        formData.append('systemRtp', formValues?.systemRtp)
        if (specificGameData) {
          updateCasinoGame({
            data: formData,
            actionType: 'update-details',
            gameId: specificGameData.identifier,
            limit,
            pageNo,
            active,
            selectedProvider,
            selectedSubCategoryId,
            defaultRtp,
            handleClose
          })
        } else {
          createCasinoGame({
            data: formData,
            casinoProviderId: parseInt(selectedCasinoProviderId, 10),
            limit,
            pageNo,
            handleClose
          })
        }
      }}
    >
      {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
        return (
          <Form>
            <Modal.Body>
              <Row className='mt-3'>
                <Col className='d-grid'>
                  <BForm.Label>{t('selectLanguage')}</BForm.Label>
                  <BForm.Select
                    name='languageCode'
                    value={values.languageCode}
                    onChange={(e) => {
                      handleChange(e)
                      setFieldValue('gameName', getTranslatedGameName(data.translations, e.target.value))
                    }}
                    onBlur={handleBlur}
                  >
                    {languages &&
                      languages
                        .filter((item) => item.isActive)
                        .map(({ languageName, code }, index) => (
                          <option key={index} value={code.toLowerCase()}>
                            {languageName}
                          </option>
                        ))}
                  </BForm.Select>
                  <ErrorMessage component='div' name='languageCode' className='text-danger' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BForm.Label>{t('gameName')}</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='gameName'
                    placeholder={t('Enter Game Name')}
                    value={values.gameName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='gameName' className='text-danger' />
                </Col>
              </Row>
              {!isRTP && (
                <Row>
                  <Col>
                    <BForm.Label>{t('returnToPlayer')}</BForm.Label>
                    <BForm.Control
                      type='text'
                      name='rtpValue'
                      placeholder={t('Enter RTP')}
                      value={values.rtpValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage component='div' name='rtpValue' className='text-danger' />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <BForm.Label>{t('systemReturnToPlayer')}</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='systemRtp'
                    placeholder={t('enterSystemRtp')}
                    value={values.systemRtp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='systemRtp' className='text-danger' />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>{t('operatorGameId')}</BForm.Label>
                  <BForm.Control
                    disabled
                    type='text'
                    name='operatorGameId'
                    placeholder={t('Enter Operator Game Id')}
                    value={values.operatorGameId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='operatorGameId' className='text-danger' />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>{t('providerName')}</BForm.Label>
                  <BForm.Control
                    disabled
                    type='text'
                    name='providerName'
                    placeholder={t('Enter Casino Provider Name')}
                    value={values.providerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='providerName' className='text-danger' />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='d-grid'>
                  <BForm.Label>{t('selectSubCategory')}</BForm.Label>
                  <BForm.Select
                    disabled
                    name='subCategoryName'
                    value={values.subCategoryId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option selected disabled>
                      {specificGameData?.currentSubCategory}
                    </option>
                    {specificGameData?.subcategories?.map(({ subCategoryName, subCategoryId }, index) => (
                      <option key={index} value={subCategoryId}>
                        {subCategoryName}
                      </option>
                    ))}
                  </BForm.Select>
                  <ErrorMessage component='div' name='subCategoryName' className='text-danger' />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='d-grid'>
                  <BForm.Label>{t('thumbnail')}</BForm.Label>
                  <BForm.Text>
                    <input
                      id='file'
                      name='thumbnail'
                      type='file'
                      onChange={(event) => {
                        setThumbnailFile(event.currentTarget.files[0])
                        setFieldValue('thumbnail', event.currentTarget.files[0])
                      }}
                    />
                    {thumbnailFile
                      ? (
                        <img
                          alt='not found'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={URL.createObjectURL(thumbnailFile)}
                        />
                        )
                      : (
                          data?.thumbnailUrl && (
                            <img
                              alt='not found'
                              style={{ maxWidth: '300px', maxHeight: '300px' }}
                              src={data?.thumbnailUrl}
                            />
                          )
                        )}
                  </BForm.Text>
                  <ErrorMessage component='div' name='thumbnail' className='text-danger' />
                </Col>
              </Row>
            </Modal.Body>
            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button variant='warning' onClick={() => handleClose()}>
                  {t('cancel')}
                </Button>
                <Button variant='success' onClick={handleSubmit} className='ml-2'>
                  {t('submit')}
                  {loading && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  )}
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateCasinoGames
