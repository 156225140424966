import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { ErrorMessage, Field } from 'formik'
import React from 'react'
import { notificationCategoryOptions } from '../constant'

const NotificationMessageForm = ({
  handleBack,
  t
}) => {
  return (
    <Row className='mb-3 d-flex justify-content-center'>
      <Col sm={6}>
        <Card className='p-4' style={{ width: '100%' }}>
          <h3 className='mb-3 d-flex justify-content-center'>{t('notificationDetails')}</h3>
          <Col className='mb-3'>
            <label htmlFor='notificationMessage' className='form-label'> {t('notificationMessage')} <span className='text-danger'>*</span></label>
            <Field type='text' placeholder={t('messagePlaceholder')} name='notificationMessage' className='form-control' />
            <ErrorMessage name='notificationMessage' component='div' className='text-danger' />
          </Col>
          <Col className='mb-3'>
            <label htmlFor='notificationType' className='form-label'> {t('notificationCategory')} <span className='text-danger'>*</span></label>
            <Field as='select' name='notificationType' className='form-control'>
              <option value=''>{t('selectNotificationCategory')}</option>
              {notificationCategoryOptions?.map((requestAction) => (
                <option key={requestAction.value} value={requestAction.value}>
                  {requestAction?.label}
                </option>
              ))}
            </Field>
            <ErrorMessage name='notificationType' component='div' className='text-danger' />
          </Col>
          <Col className='mb-3 justify-content-between d-flex'>
            <Button type='submit' className='btn btn-success'>
              {t('submit')}
            </Button>
          </Col>
        </Card>
      </Col>
    </Row>
  )
}

export default NotificationMessageForm
