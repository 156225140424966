import React from 'react'
import './SportsBookBonus.scss'
import useSportsbookCreateBonus from './hooks/useSportsbookCreateBonus'
import { Col, Row, Form as BForm, Button, Table } from 'react-bootstrap'
import { Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { createBonusSchema } from './schema'
import Trigger from '../../components/OverlayTrigger'
import { BonusVal, matchListingTableHeader } from './constant'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputDatePicker from '../../components/FormElements/InputDatePicker/InputDatePicker'
import { toast } from '../../components/Toast'
import PaginationComponent from '../../components/Pagination'
const SportsBookBonusCreation = ({ isEdit }) => {
  const {
    limit,
    page,
    setPage,
    setLimit,
    t,
    navigate,
    handleCreateBonus,
    adminData,
    bonusDropDown,
    bonusDetail,
    getSeniorBonusStart,
    // getTenantCurrencies,
    // tenantCurrencies,
    debounceFn,
    matchSelectedData,
    sportsListing,
    filterData,
    setFilterData,
    matchListing,
    loading,
    setBonusType,
    setSelectedMatchData,
    setDate,
    bonusType,
    isChecked,
    handleSelectMatches,
    handleSelectAll,
    tempSelectedMatch
  } = useSportsbookCreateBonus({ isEdit })
  return (
    <div className='p-3'>
      <Formik
        enableReinitialize={isEdit}
        validateOnMount={false}
        initialValues={{
          type: 'SPORTBOOK',
          bonusType: isEdit ? bonusDetail?.bonusType || '' : '',
          bonusValidFrom: isEdit ? moment(bonusDetail?.bonusValidFrom).toDate() : moment().toDate(),
          bonusValidTo: isEdit ? moment(bonusDetail?.bonusValidTo).toDate() : moment().add(1, 'day').toDate(),
          currencyCode: isEdit ? bonusDetail?.currencyCode || '' : '',
          bonusPercent: isEdit ? bonusDetail?.bonusPercent : '',
          adminId: isEdit ? bonusDetail?.adminId : '',
          adminName: isEdit ? `${bonusDetail?.adminUser?.firstName} ${bonusDetail?.adminUser?.lastName} ` : '',
          tenantId: isEdit ? bonusDetail?.tenantId : '',
          addOnOddBonus: isEdit ? bonusDetail?.addOnOddBonus || '' : '',
          maxBonusLimit: isEdit ? bonusDetail?.maxBonusLimit || '' : '',
          file: isEdit ? bonusDetail?.imageUrl || '' : '',
          rollingPercent: isEdit ? bonusDetail?.rollingPercent : ''
        }}
        validationSchema={createBonusSchema(t)}
        onSubmit={(formValues) => {
          if (matchSelectedData.length === 0) {
            toast(t('pleaseSelectAtLeastOneMatch'), 'error')
          } else if (bonusType === BonusVal.mixedMatchBonus && matchSelectedData?.length < 2) {
            toast(t('pleaseSelectAtLeastTwoMatch'), 'error')
          } else {
            handleCreateBonus(formValues)
          }
        }}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          validateField,
          setValues,
          submitForm
        }) => {
          const isAddOnOddBonus = values?.bonusType === BonusVal.winningBonus
          return (
            <>
              <div className='row mb-3 d-flex align-items-center'>
                <Col className='d-flex justify-content-start col-6 cursor-pointer' role='button'>
                  <span onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;</span>
                  <h3 onClick={() => navigate(-1)}>{isEdit ? t('editSportsBonus') : t('createSportsBonus')}</h3>
                </Col>
                <Col className='d-flex justify-content-end col-6'>
                  <div className='d-flex align-align-items-center gap-4'>
                    <Button
                      variant='outline-warning'
                      onClick={() => navigate(-1)}
                      className='ml-2'
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      onClick={submitForm}
                      className='ml-2'
                      disabled={loading}
                      variant='outline-success'
                    >
                      {t('submit')}
                      {loading && (
                        <Spinner
                          className='ms-1'
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </Col>
              </div>
              <Form>
                <Row>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                        {t('senior')}

                      </BForm.Label>
                      <BForm.Select
                        disabled={isEdit}
                        className='w-100 select-placeholder'
                        id='adminId'
                        name='adminId'
                        value={values.adminId}
                        onChange={(e) => {
                          const tenantId = adminData?.find((item) => item?.adminUserId === Number(e.target.value))?.Tenants?.[0]?.tenantId
                          setValues({ ...values, tenantId: tenantId, adminId: Number(e.target.value) })
                          // getTenantCurrencies(tenantId)
                          getSeniorBonusStart(tenantId)
                        }}
                        onBlur={handleBlur}
                      >
                        <option value='' disabled>
                          {t('selectSenior')}
                        </option>
                        {isEdit
                          ? (
                            <option value={values.adminId}>
                              {values.adminName}
                            </option>
                            )

                          : adminData?.map((option) => (
                            <option key={option.adminUserId} value={option.adminUserId}>
                              {option.firstName + ' ' + option.lastName}
                            </option>
                          ))}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='adminId'
                        className='text-danger ms-3'
                      />
                    </div>
                  </Col>

                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                        {t('bonusType')}

                      </BForm.Label>
                      <Trigger disabled={values?.adminId} placement='top' message={t('pleaseSelectSenior')}>
                        <BForm.Select
                          name='bonusType'
                          value={values.bonusType}
                          disabled={!values?.adminId || isEdit}
                          onChange={(e) => {
                            handleChange(e)
                            setBonusType(e.target.value)
                            if (e.target.value === BonusVal.bettingBonus) {
                              setSelectedMatchData([])
                            }
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('select_bonus_type')}</option>
                          {isEdit
                            ? (
                              <option value={values.bonusType}>
                                {values.bonusType}
                              </option>
                              )

                            : bonusDropDown?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                              >{item}
                              </option>
                            ))}
                        </BForm.Select>
                      </Trigger>
                      <ErrorMessage
                        component='div'
                        name='bonusType'
                        className='text-danger ms-3'
                      />
                    </div>
                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                        {t('selectCurrency')}

                      </BForm.Label>
                      <Trigger disabled={values?.adminId} placement='top' message={t('pleaseSelectSenior')}>
                        <BForm.Select
                          disabled={!values?.adminId || isEdit}
                          name='currencyCode'
                          value={values.currencyCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('selectCurrency')}</option>
                          {isEdit
                            ? (
                              <option value={values.currencyCode}>
                                {values.currencyCode}
                              </option>
                              )

                            : ['KRW']?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item}
                                  >
                                    ₩
                                  </option>
                                )
                              })}
                        </BForm.Select>
                      </Trigger>
                      <ErrorMessage
                        component='div'
                        name='currencyCode'
                        className='text-danger ms-3'
                      />

                    </div>
                  </Col>
                  {isAddOnOddBonus && (
                    <Col sm={6} xxl={4} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                          {t('addOnBonus')}

                        </BForm.Label>
                        <Trigger message={t('valueWillConciderInPercentage')}>
                          <BForm.Control
                            name='addOnOddBonus'
                            type='number'
                            min={1}
                            placeholder={t('enterOddValue')}
                            value={values.addOnOddBonus}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Trigger>
                        <ErrorMessage
                          component='div'
                          name='addOnOddBonus'
                          className='text-danger ms-3'
                        />
                      </div>
                    </Col>
                  )}
                  {!isAddOnOddBonus && (
                    <Col sm={6} xxl={4} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                          {t('bonus_percentage')}
                        </BForm.Label>
                        <Trigger message={t('valueWillConciderInPercentage')}>
                          <BForm.Control
                            name='bonusPercent'
                            type='number'
                            min={1}
                            placeholder={t('enterValueInPercent')}
                            value={values.bonusPercent}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Trigger>
                        <ErrorMessage
                          component='div'
                          name='bonusPercent'
                          className='text-danger ms-3'
                        />
                      </div>

                    </Col>
                  )}
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                        {t('rollingPercent')}

                      </BForm.Label>
                      <Trigger message={t('valueWillConciderInPercentage')}>
                        <BForm.Control
                          type='number'
                          name='rollingPercent'
                          placeholder={t('enterValueInPercent')}
                          value={values.rollingPercent}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Trigger>
                      <ErrorMessage
                        component='div'
                        name='rollingPercent'
                        className='text-danger ms-3'
                      />
                    </div>
                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('maximum_bonus_limit')}
                      </BForm.Label>
                      <BForm.Control
                        name='maxBonusLimit'
                        type='number'
                        min={1}
                        placeholder={t('enterValue')}
                        value={values.maxBonusLimit}
                        onInput={handleChange}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component='div'
                        name='maxBonusLimit'
                        className='text-danger mt-1'
                      />
                    </div>

                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <InputDatePicker
                        isForm
                        label={t('bonus_valid_from')}
                        onChange={(val) => {
                          setFieldValue('bonusValidFrom', val)
                          setDate((prevVal) => ({ ...prevVal, fromDate: moment(val).format('YYYY-MM-DD') }))
                        }}
                        value={values.bonusValidFrom}
                      />
                      <ErrorMessage
                        component='div'
                        name='bonusValidFrom'
                        className='text-danger mt-1'
                      />
                    </div>

                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <InputDatePicker
                        isForm
                        label={t('bonus_valid_to')}
                        onChange={(val) => {
                          setFieldValue('bonusValidTo', val)
                          setDate((prevVal) => ({ ...prevVal, toDate: moment(val).format('YYYY-MM-DD') }))
                        }}
                        value={values.bonusValidTo}
                      />
                      <ErrorMessage
                        component='div'
                        name='bonusValidTo'
                        className='text-danger mt-1'
                      />
                    </div>
                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 '>
                        {t('sports')}
                      </BForm.Label>
                      <BForm.Select
                        value={filterData?.sportId}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            sportId: e.target.value
                          })
                        }}
                      >
                        <option value=''>{t('all')}</option>
                        {sportsListing?.rows?.map(({
                          providerSportId,
                          sportName
                        }) =>
                          (
                            <option
                              key={providerSportId}
                              value={providerSportId}
                            >
                              {sportName}
                            </option>
                          )
                        )}
                      </BForm.Select>
                    </div>
                  </Col>

                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('searchMatch')}
                      </BForm.Label>
                      <BForm.Control
                        name=''
                        type='text'
                        onChange={debounceFn}
                        placeholder={t('searchMatch')}
                      />
                    </div>
                  </Col>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0 d-flex flex-row'>
                        {t('bonusImage')}

                      </BForm.Label>
                      <BForm.Control
                        name='file'
                        type='file'
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setFieldValue('file', event.target.files[0])
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='file'
                        className='text-danger ms-3'
                      />
                      {values?.file &&
                          (
                            <img
                              alt='file'
                              style={{ width: 150, maxHeight: 150, objectFit: 'contain' }}
                              src={
                                typeof values?.file !== 'string'
                                  ? URL.createObjectURL(values?.file)
                                  : values?.file
                              }
                            />
                          )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          )
        }}
      </Formik>
      <div className='row'>
        <div className='col-6'>
          <div className='my-2 mt-3'>
            <div className='d-flex items-center gap-2 mb-2'>
              <h3 className='mb-0'>{t('matchList')}</h3>
              {!isEdit && (
                <div className='d-flex align-items-center gap-2'>
                  <input
                    onChange={handleSelectAll}
                    style={{ width: 20, height: 20 }}
                    checked={tempSelectedMatch?.[page] ? tempSelectedMatch?.[page]?.length === matchListing?.rows.length : false}
                    type='checkbox'
                  />
                  {t('selectAllOfThisPage')}
                </div>
              )}
            </div>
            <div className=' text-center'>

              <Table
                bordered
                striped
                responsive
                hover
                size='sm'
                className='text-center'
              >
                <thead className='thead-dark'>
                  <tr>
                    {matchListingTableHeader.map((h, idx) => <th key={`matchlisting-table_heading ${idx}`}>{t(h.label)}</th>)}
                  </tr>
                </thead>

                <tbody>
                  {matchListing?.rows?.map((item, index) => {
                    return (
                      <tr key={`market-list ${index}`}>
                        <td className='d-flex align-items-center gap-1'>
                          <span>
                            {(index + 1) + (limit * (page - 1))}
                          </span>
                          <input
                            onClick={(e) => handleSelectMatches(e, item)}
                            value={item?.masterMatchId}
                            type='checkbox'
                            checked={!!isChecked(item?.masterMatchId)} className='cursor-pointer'
                          />
                        </td>
                        <td> {item?.sportName} </td>
                        <td title={item?.homeTeam} style={{ maxWidth: '150px' }}> {item?.homeTeam} </td>
                        <td title={item?.awayTeam} style={{ maxWidth: '150px' }}> {item?.awayTeam} </td>
                        <td>
                          {item?.matchDate ? moment(item?.matchDate).format('MM-DD-YYYY HH:mm') : '-'}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>

              </Table>
              {matchListing?.count === 0 && <p className='mt-2 text-danger'>{t('noMatchesAvailable')}</p>}
              {matchListing?.count !== 0 && !loading && (
                <PaginationComponent
                  page={matchListing?.count < page ? setPage(1) : page}
                  totalPages={Math.ceil(matchListing?.count / limit)}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalCount={matchListing?.count}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='my-2 mt-3'>
            <h3>{t('selectedMatchList')}</h3>
            <div className='table-body-container text-center'>
              <Table
                bordered
                striped
                responsive
                hover
                size='sm'
                className='text-center'
              >
                <thead className='thead-dark'>
                  <tr>
                    {matchListingTableHeader.map((h, idx) => <th key={`matchlisting-table_heading ${idx}`}>{t(h.label)}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {matchSelectedData?.map((item, index) => {
                    return (
                      <tr key={`market-list ${index}`}>
                        <td className='d-flex justify-content-between'>
                          <span>
                            {index + 1}
                          </span>
                          <label className='d-flex gap-1 items-center' htmlFor={`market-list ${index}`}>
                            <FontAwesomeIcon className='mt-1 cursor-pointer' color='red' icon={faXmark} />
                            <input
                              style={{ display: 'none' }}
                              id={`market-list ${index}`}
                              onClick={(e) => handleSelectMatches(e, item)}
                              value={item?.masterMatchId}
                              type='checkbox'
                              checked={!!isChecked(item?.masterMatchId)} className='cursor-pointer hidden'
                            />
                          </label>

                        </td>
                        <td> {item?.sportName} </td>
                        <td title={item?.homeTeam} style={{ maxWidth: '150px' }}> {item?.homeTeam} </td>
                        <td title={item?.awayTeam} style={{ maxWidth: '150px' }}> {item?.awayTeam} </td>
                        <td>
                          {item?.matchDate ? moment(item?.matchDate).format('MM-DD-YYYY HH:mm') : '-'}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>

              </Table>
              {matchSelectedData.length === 0 && <p className='mt-2 text-danger'>{t('pleaseSelectMatch')}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SportsBookBonusCreation
