import { createSlice } from '@reduxjs/toolkit'

const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    loading: false,
    agentData: null,
    bonusAgent: {},
    bonusAgentLoading: false
  },
  reducers: {
    getBonusDetailAgentStart: (state) => ({
      ...state,
      bonusAgentLoading: true
    }),
    getBonusDetailAgentSuccess: (state, { payload }) => ({
      ...state,
      bonusAgentLoading: false,
      bonusAgent: payload
    }),
    getBonusDetailAgentFailure: (state) => ({
      ...state,
      bonusAgentLoading: false
    }),
    createAgentStart: (state) => ({
      ...state,
      loading: true
    }),
    createAgentSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createAgentFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAgentStart: (state) => ({
      ...state,
      loading: true
    }),
    getAgentSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      agentData: payload
    }),
    getAgentFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAgentDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getAgentDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      agentData: payload
    }),
    getAgentDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateAgentStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAgentStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateAgentStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateAgentStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAgentSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateAgentFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default agentSlice.reducer

export const {
  createAgentStart,
  createAgentSuccess,
  createAgentFailure,
  getAgentStart,
  getAgentSuccess,
  getAgentFailure,
  getAgentDetailStart,
  getAgentDetailSuccess,
  getAgentDetailFailure,
  updateAgentStatusStart,
  updateAgentStatusSuccess,
  updateAgentStatusFailure,
  updateAgentStart,
  updateAgentSuccess,
  updateAgentFailure,
  getBonusDetailAgentStart,
  getBonusDetailAgentSuccess,
  getBonusDetailAgentFailure
} = agentSlice.actions
