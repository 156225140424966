import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
import {
  getTenantSportsListingStart,
  getTenantSportsListingSuccess,
  getTenantSportsListingFailure,
  getTenantSportsCountriesListingStart,
  getTenantSportsCountriesListingSuccess,
  getTenantSportsCountriesListingFailure,
  getTenantMatchListingStart,
  getTenantMatchListingSuccess,
  getTenantMatchListingFailure,
  getTenantMarketListingStart,
  getTenantMarketsListingSuccess,
  getTenantMarketsListingFailure,
  getTenantLeaguesListingStart,
  getTenantLeaguesListingSuccess,
  getTenantLeaguesListingFailure,
  updateSportsBookActionTAStart,
  updateSportsBookActionTASuccess,
  updateSportsBookActionTAFailure
} from '../redux-slices/tenantSportsBook'
import {
  getSports,
  getSportsCountries,
  getMatches,
  getMarkets,
  getLeagues,
  updateActionSportsBook
} from '../../utils/apiCalls'

export default function * tenantSportsBookWatcher () {
  yield takeLatest(getTenantSportsListingStart.type, getTenantSportsListingWorker)
  yield takeLatest(getTenantSportsCountriesListingStart.type, getTenantSportsCountriesListingWorker)
  yield takeLatest(getTenantMatchListingStart.type, getTenantMatchListingWorker)
  yield takeLatest(getTenantMarketListingStart.type, getTenantMarketListingWorker)
  yield takeLatest(getTenantLeaguesListingStart.type, getTenantLeaguesListingWorker)
  yield takeLatest(updateSportsBookActionTAStart.type, updateSportsBookActionSAWorker)
}

function * getTenantSportsListingWorker (action) {
  try {
    const {
      paramsData
    } = action && action.payload

    const { data } = yield getSports({ paramsData, isTenant: paramsData?.isTenant })
    yield put(getTenantSportsListingSuccess(data?.data?.categorySports))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTenantSportsListingFailure(e.response.data.message))
  }
}

function * getTenantSportsCountriesListingWorker (action) {
  try {
    const { paramsData } = action && action.payload

    const { data } = yield getSportsCountries(paramsData)
    yield put(getTenantSportsCountriesListingSuccess(data?.data?.categoryCountries))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTenantSportsCountriesListingFailure(e.response.data.message))
  }
}

function * getTenantMatchListingWorker (action) {
  try {
    // const { paramsData } = action && action.payload

    const { data } = yield getMatches(action.payload)
    yield put(getTenantMatchListingSuccess(data?.data?.categoryMatches))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTenantMatchListingFailure(e.response.data.message))
  }
}

function * getTenantMarketListingWorker (action) {
  try {
    const { paramsData } = action && action.payload

    const { data } = yield getMarkets(paramsData)
    yield put(getTenantMarketsListingSuccess(data?.data?.categoryMarkets))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTenantMarketsListingFailure(e.response.data.message))
  }
}

function * getTenantLeaguesListingWorker (action) {
  try {
    const { paramsData } = action && action.payload

    const { data } = yield getLeagues({ paramsData, isTenant: paramsData?.isTenant })
    yield put(getTenantLeaguesListingSuccess(data?.data?.categoryTournaments))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTenantLeaguesListingFailure(e.response.data.message))
  }
}
function * updateSportsBookActionSAWorker (action) {
  try {
    const {
      updateData,
      setIsStatusModalOpen,
      fetchData
    } = action && action.payload

    const { data } = yield updateActionSportsBook(
      updateData
    )
    setIsStatusModalOpen(false)
    yield put(updateSportsBookActionTASuccess(data?.data?.message))
    yield toast(data?.data?.message.toLowerCase(), 'success')
    switch (updateData.entityType) {
      case 'sport':
        yield put(fetchData())
        break

      case 'country':
        yield put(fetchData())
        break
      case 'match':
        yield put(fetchData())
        break
      case 'market':
        yield put(fetchData())
        break
      case 'tournament':
        yield put(fetchData())
        break
      default:return
    }
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(updateSportsBookActionTAFailure(e.response.data.message))
  }
}
