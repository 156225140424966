export const EN =
{
  1: 1,
  accept: 'Accept',
  amountShouldBeInAccordingToRank: 'Amounts Should Be In According To Rank',
  accepted: 'Accepted',
  acceptRequest: 'Accept Request',
  accountActions: 'Account Actions',
  accountNumber: 'Account Number',
  action: 'Action',
  actions: 'Actions',
  actionType: 'Action Type',
  active: 'Active',
  address: 'Address',
  addressMax: 'Maximum 100 Characters',
  addressRequired: 'Address Required',
  adminIdRequired: 'Parent Admin is required',
  adminName: 'Admin Name',
  all: 'All',
  allowedCurrencies: 'Allowed Currencies',
  allowedLanguages: 'Allowed Languages',
  allowedProviders: 'Allowed Providers',
  amount: 'Amount',
  amountCheck: 'Amount should be less than available balance',
  amountMin: 'Amount should be greater than 0',
  amountPositive: 'Amount must be positive',
  amountRequired: 'Amount Required',
  amountShouldBeGreaterThan0: 'Amount should be greater than 0',
  amountLength: 'Amount must be less than 7 digits.',
  approve: 'Approve',
  approved: 'Approved',
  approvedKyc: 'Approved KYC',
  areYouSureActionRequest: 'Are you sure you want to {{action}} request?',
  areYouSureKycRequest: 'Are you sure you want to {{action}} kyc request?',
  associatedReferrals: 'Associated Referrals',
  associateReferral: 'Associate Referral',
  bankName: 'Bank Name',
  bannerManagement: 'Banner Management',
  rankingSeasons: 'Ranking Seasons',
  bannerPreview: 'Banner Preview',
  bannerRequired: 'Banner Required',
  bannerTypeRequired: 'Banner Type Required',
  basicInfo: 'Basic Info',
  Bet: 'bet',
  BET: 'Bet',
  betHistory: 'Bet History',
  betLevel: 'Bet Level',
  betLevelMustBeAnIntegerValue: 'Bet Level Must be an Integer Value',
  betLevelMustBeAPositiveNumber: 'Bet Level Must be a Positive Number',
  betLevelRequired: 'Bet Level Required',
  betResult: 'Bet Result',
  betType: 'Bet Type',
  blackList: 'Black List',
  blacklist: 'Blacklist',
  blacklistDescription: 'Blacklist Description',
  blackListPlayer: 'Black List Player',
  blacklistReason: 'Blacklist Reason',
  blacklistStatus: 'Black List Status',
  bonus: 'Bonus',
  Bonus: 'bonus',
  bonus_limit: 'Bonus Limit',
  bonus_percentage: 'Bonus Percentage',
  bonus_type: 'Bonus Type',
  bonus_valid_from: 'Bonus Valid From',
  bonus_valid_to: 'Bonus Valid To',
  bonusLimit: 'Bonus Limit',
  bonusPercentage: 'Bonus Percentage',
  bonusPercentageRequired: 'Bonus Percentage Required',
  bonusPercentMustBeNumber: 'Bonus Percentage must be a number',
  bonusRequired: 'Bonus Required',
  bonusRollingPercentage: 'Bonus Rolling Percentage',
  bonusRollingPercentNumber: 'Bonus Rolling Percent must be a Number',
  bonusTransactions: 'Bonus Transactions',
  bonusType: 'Bonus Type',
  bonusTypeRequired: 'Bonus Type Required',
  bonusUsers: 'Bonus users',
  bonusValidFrom: 'Bonus Valid From',
  bonusValidTo: 'Bonus Valid To',
  cancel: 'Cancel',
  cancelReRequested: 'Cancel-ReRequested',
  cash: 'Cash',
  Cash: 'cash',
  'Cash + Bonus': 'cash + bonus',
  cashAmount: 'Cash Amount',
  casinoCategories: 'Casino Category',
  casinoGame: 'Casino Game',
  casinoGames: 'Casino Games',
  casinoManagement: 'Casino Management',
  casinoProviderIdRequired: 'Casino Provider Id Required',
  casinoProviders: 'Casino Providers',
  casinoReporting: 'Casino Reporting',
  sportsReporting: 'Sports Reporting',
  casinoSubCategories: 'Casino Sub Categories',
  casinoTransaction: 'Casino Transaction',
  casinoTransactionId: 'Casino Transaction Id',
  categories: 'Categories',
  category: 'Category',
  categoryRequired: 'Category Required',
  changeLanguage: 'Change Language',
  chooseNote: "If you don't choose any {{tab}}, it will be created for all {{tab}} by default",
  chooseType: 'Choose Type',
  city: 'City',
  cityMax: 'Maximum 50 Characters',
  cityRequired: 'City Required',
  cms: 'CMS',
  CMS: 'CMS',
  compensation_target: 'Compensation Target',
  compensationBonus: 'Compensation Bonus',
  compensationDepositBonus: 'Compensation Deposit Bonus',
  compensationTarget: 'Compensation Target',
  compensationTargetRequired: 'Compensation Target Required',
  Complete: 'Completed',
  confirm: 'Confirm',
  CONFIRM: 'confirm',
  confirmAction: 'Are you sure you want to  ?',
  confirmDelete: 'Are you sure you want to delete ?',
  confirmMarkStatus: 'Are you sure you want to mark {{name}} {{status}}',
  confirmPasswordRequired: 'Confirm Password Required!',
  confirmResetEmail: 'Are you sure you want to send a password reset email',
  confirmSwitch: 'Are you sure you want to switch to another language?',
  confirmTitle: 'Are you sure?',
  confirmTransactionLimit: 'Are you sure you want to {{buttonTitle}} {{amount}} {{currency}}?',
  content: 'Content',
  contentRequired: 'Content Required',
  contentValidFormat: 'Content Required',
  copiedToClipboard: 'Copied To Clipboard',
  copyImageURL: 'Copy This Image URL to Clipboard',
  country: 'Country',
  countryCode: 'Country Code',
  countryCodeRequired: 'Country Code Required',
  countryName: 'Country / Club Name',
  countryRequired: 'Country Required',
  couponNumber: 'Coupon Number',
  create: 'Create',
  createBonus: 'Create Bonus',
  createCMS: 'Create CMS',
  created: 'Created',
  createdAt: 'Created At',
  createForAll: "If you don't choosee any {placeholder}, It will be created for all {placeholder} by default",
  createNotification: 'Create Notification',
  createTenant: 'Create Tenant',
  CREDIT: 'Credit',
  currency: 'Currency',
  currencyRequire: 'Currency Required',
  currencyRequired: 'Currency Required',
  currentOnlinePlayer: 'Current Online Player',
  daily: 'Daily',
  daily_limit: 'Daily Limit',
  dailyLimit: 'Daily Limit',
  dashboard: 'Dashboard',
  date: 'Date',
  dateEnd: 'Date End',
  dateOfBirth: 'Date of Birth',
  dateOfBirthInvalid: 'Please enter a valid Date of Birth',
  dateOfBirthMax: 'Date Of Birth Must be Earlier than Today',
  dateStart: 'Date Start',
  DEBIT: 'Debit',
  demoMode: 'Demo Mode',
  deposit: 'Deposit',
  depositAccept: 'Deposit Accept',
  depositers: 'Depositers',
  depositersAmount: 'Depositers Amount',
  depositors: 'Depositors',
  depositReject: 'Deposit Reject',
  description: 'Description',
  discardChanges: 'Switching Language would discard all the changes that you made.',
  DOB: 'DOB',
  domain: 'Domain',
  domainName: 'Domain Name',
  downloadAsCSV: 'Download As CSV',
  downloadAsCsv: 'Download as CSV',
  downloadCsv: 'Download as CSV',
  duplicates: 'Duplicates',
  edit: 'Edit',
  editProvider: 'Edit Provider',
  email: 'Email',
  emailRequired: 'Email Required',
  emergency: 'Emergency',
  endDate: 'End Date',
  english: 'English',
  enterAccountNumber: 'Enter Account Number',
  enterAmount: 'Enter Amount',
  enterBetLevel: 'Enter Bet Level',
  enterBonusPercentage: 'Enter Bonus Percentage %',
  enterCasinoProviderName: 'Enter Casino Provider Name',
  enterCompensationTarget: 'Enter compensation target',
  enterCorrectURL: 'Enter correct url!',
  enterCurrency: 'Enter Currency',
  enterDailyLimit: 'Enter daily limit',
  enterDescription: 'Enter Description',
  enterDomain: 'Enter Domain',
  enterEmail: 'Enter Email',
  enterFirstName: 'Enter First Name',
  enterGameName: 'Enter Game Name',
  enterLastName: 'Enter Last Name',
  enterMaxBonusLimit: 'Enter max bonus limit',
  enterMinBalancePercentage: 'Enter Min Balance Percentage %',
  enterMinutesForBonusGiven: 'Enter minutes for bonus given',
  enterMonthlyLimit: 'Enter monthly limit',
  enterName: 'Enter Name',
  enterOperatorGameId: 'Enter Operator GameId',
  enterPassword: 'Enter Password',
  enterReocurringDepositRate: 'Enter reocurring deposit rate %',
  editDetails: 'Edit Details',
  enterSlug: 'Enter Slug',
  enterTitle: 'Enter Title',
  enterUsername: 'Enter Username',
  enterValidAmount: 'Enter valid amount',
  enterValidPhoneNumberWithCountryCode: 'Enter a valid phone number with country code',
  enterWeeklyLimit: 'Enter weekly limit',
  enterWithdrawalRate: 'Enter withdrawal rate',
  enterWithdrawLimit: 'Enter Withdrawal Limit Amount',
  Failed: 'failed',
  fileFormatError: 'Uploaded file has unsupported format.',
  fileFormatValidation: 'Uploaded file has unsupported format',
  fileRequired: 'A file is required',
  fileSizeLessThan1MB: 'File Size Should be Less Than 1MB',
  fileSizeShouldBeLessThan1MB: 'FileSizeShouldBeLessThan1MB',
  fileSizeValidation: 'File Size Should be Less Than 1MB',
  firstDepositors: 'First Depositors',
  firstName: 'First Name',
  firstNameFormat: 'Only Alphabets and Space Allowed and Must Start with Alphabet',
  firstNameMustBeAtleast3Characters: 'First Name must be at least 3 characters',
  firstNameMustBeLessThan25Characters: 'First Name must be less than 25 characters',
  firstNameRequired: 'First Name Required!',
  friday: 'Friday',
  from: 'From',
  fullName: 'Legal Name',
  gallery: 'Gallery',
  game: 'Game',
  gameId: 'Game ID',
  gameName: 'Game Name',
  gameNameRequired: 'Game Name Required',
  games: 'Games',
  gameSubCategory: 'Game Sub Category',
  gender: 'Gender',
  genderRequired: 'Gender Required',
  goBack: 'Go Back',
  greenFlag: 'Green Flag',
  greenflag: 'Green Flag',
  groupNameFormat: 'Only Alphabets, Numbers and Space Allowed',
  groupNameRequired: 'Group Name Required',
  hotTimeBonus: 'Hot Time Bonus',
  id: 'ID',
  imageHeightError: 'The image height needs to be between {{minRequiredHeight}}px - {{maxRequiredHeight}}px!',
  imageWidthError: 'The image width needs to be between {{minRequiredWidth}}px - {{maxRequiredWidth}}px!',
  inActive: 'In-Active',
  inactive: 'Inactive',
  instigator: 'Instigator',
  invalidAccountNumber: 'Invalid Account Number',
  invalidDate: 'Invalid Date',
  invalidEmail: 'Invalid email',
  invalidPassword: 'Invalid Password',
  ipAddress: 'IP Address',
  isActive: 'Is Active',
  isExpired: 'Is Expired',
  japanese: 'Japanese',
  korean: 'Korean',
  koreanWon: 'Korean Won',
  kycDetails: 'KYC Details',
  kycMethod: 'Kyc Method',
  kycSettings: 'KYC Settings',
  kycStatus: 'Kyc Status',
  language: 'Language',
  languageCode: 'Language Code',
  languageModule: 'Language Module',
  languageName: 'Language Name',
  languages: 'Languages',
  languageRequired: 'Language Required',
  lastName: 'Last Name',
  lastNameFormat: 'Only Alphabets and Space Allowed and Must Start with Alphabet',
  lastNameMustBeAtleast3Characters: 'Last Name must be at least 3 characters',
  lastNameMustBeLessThan25Characters: 'Last Name must be less than 25 characters',
  lastNameRequired: 'Last Name Required!',
  limitMustBeAnInteger: 'Limit must be an integer',
  limitMustBePositiveNumber: 'Limit must be positive number',
  limitRequired: 'Limit Required',
  loggedInCount: 'Logged In Count',
  logo: 'Logo',
  logout: 'Logout',
  Lost: 'lost',
  LOST: 'Lost',
  monthly_limit: 'Monthly Limit',
  maintenance: 'Maintenance',
  masterCasinoCategories: 'Master Casino Categories',
  masterId: 'Master ID',
  max16Characters: 'Max 16 Characters',
  maxBonusLimitRequired: 'Max Bonus Limit Required',
  maxCharacters: '{{label}} must be at most {{maxNumber}} characters',
  maxDigits: '{{label}} must be less than {{maxNumber}} digits',
  maximum_bonus_limit: 'Maximum Bonus Limit',
  maximumBonusLimit: 'Maximum bonus limit',
  maxNumber: 'Max {{maxNumber}} Number',
  message: 'Message',
  messagePlaceholder: 'Enter Message',
  min1LowerCase: 'Min 1 LowerCase',
  min1Number: 'Min 1 Number',
  min1SpecialCharacters: 'Min 1 Special Characters',
  min1UpperCase: 'Min 1 UpperCase',
  min2Characters: 'Min 2 Characters',
  min_balance_percentage: 'Min Balance Percentage',
  minAge: 'Must Be 18 Years Old',
  minBalancePercentage: 'Min Balance Percentage',
  minCharacters: '{{label}} must be at least {{minNumber}} characters',
  minDigits: '{{label}} must be at least {{minNumber}} digits',
  minimum1BetLevelRequired: 'Minimum 1 Bet Level Required',
  minimum1SpinRequired: 'Minimum 1 Spin Required',
  minLowerCase: 'Min 1 LowerCase',
  minNumber: 'Min 1 Number',
  minSpecialCharacters: 'Min 1 Special Characters',
  minUpperCase: 'Min 1 UpperCase',
  minutes_for_bonus_given: 'Minutes for Bonus Given',
  minutesForBonusGiven: 'Minutes for bonus given',
  minutesForBonusGivenRequired: 'Minutes for Bonus Given Required',
  monday: 'Monday',
  monthly: 'Monthly',
  monthlyLimit: 'Monthly Limit',
  monthMustBeAnInteger: 'Month must be an integer',
  monthMustBePositiveNumber: 'Month must be positive number',
  monthRequired: 'Month Required',
  moreInfo: 'More Info',
  mustBeAtLeast8Characters: 'Must be at least 8 characters along with 1 uppercase, 1 lowercase letter, 1 special character and 1 digit',
  mustBeAtLeastCharacters: '{{label}} must be at least {{minNumber}} characters',
  mustBeAtMostCharacters: '{{label}} must be at most{{maxNumber}} characters',
  mustBeGreaterThan: 'Must Be Greater Than',
  mustBeGreaterThanOrEqualTo: 'Must be greater than or equal to {value}',
  mustBeGreaterThanZero: 'Amount must be positive',
  mustBeLesserThanCharacters: 'Must be of lesser than {{maxNumber}} characters!!',
  mustBeNumber: 'Must be a number',
  mustBeRealAmount: 'Must be real amount',
  na: 'N/A',
  name: 'Name',
  NEW: 'new',
  newPasswordRequired: 'New Password Required!',
  next: 'Next',
  no: 'No',
  noDataFound: 'No data found',
  noImageFound: 'No Image Found',
  nonCashAmount: 'Non Cash Amount',
  sportsPromotionCash: 'Sports Promotion Cash',
  casinoPromotionCash: 'Casino Promotion Cash',
  nonDepositors: 'Non Depositors',
  none: 'None',
  nonRequest: 'Non Request',
  v2Migration: 'Migration-V2',
  noProvidersAvailable: 'No Providers Available',
  normal: 'Normal',
  notAllowedToView: 'You were not allowed to view this content',
  note: 'Note: ',
  notFound: 'NotFound',
  notification: 'Notification',
  notificationCategory: 'Notification Category',
  notificationCategoryMessageRequired: 'Notification Category Message Required',
  notificationCategoryRequired: 'Notification Category Required',
  notificationCenter: 'Notification Center',
  notificationMessage: 'Notification Message',
  notificationMessageRequired: 'Notification Message Required',
  notificationRecipients: 'Notification recipients',
  notificationRequired: 'Notification Required',
  OLD: 'old',
  oldAndNewPasswordMustBeDifferent: 'Old and New Password Must be Different!',
  oldPasswordRequired: 'Old Password Required!',
  onlinePlayer: 'Online Player',
  onlineUsersDemograph: 'Online Users Demograph',
  onlyAlphabets: 'Only Alphabets Allowed',
  onlyAlphabetsAllowed: 'Only Alphabets Allowed',
  onlyAlphabetsAndNumbers: 'Only Alphabets and Numbers allowed',
  onlyAlphabetsAndSingleSpace: 'Only Alphabets and Single Space Between Words Allowed',
  onlyAlphabetsNumbersAndUnderscore: 'Only Alphabets, Numbers and UnderScore(_) allowed',
  onlyAlphabetsSpaceAndDash: 'Only Alphabets, Space and Dash(-) allowed',
  onlyNumbersAllowed: 'Only Numbers allowed',
  onlyNumbersAreAllowed: 'Only numbers are allowed',
  operatorGameId: 'Operator Game Id',
  operatorGameIdRequired: 'Operator Game Id Required',
  operatorStatus: 'Operator Status',
  origin: 'Origin',
  other: 'Other',
  overview: 'Overview',
  owner: 'Owner',
  ownerIdRequired: 'Owner Id Required',
  ownerName: 'Owner Name',
  pages: 'Pages',
  password: 'Password',
  passwordMatchError: 'Passwords must match',
  passwordRequired: 'Password is required',
  passwordRequirements: 'passwordRequirements',
  passwordsMustMatch: 'Passwords must match',
  pending: 'Pending',
  Pending: 'pending',
  pendingKyc: 'Pending KYC',
  percentGreaterThanEqual: '% Must be greater than or equal to {{percent}}',
  perPage: 'Per Page',
  phone: 'Phone',
  phoneMustBeAtLeast5Digits: 'Phone must be at least 5 digits',
  phoneMustBeLessThan13Digits: 'Phone must be less than 13 digits',
  phoneNumber: 'Phone Number',
  phoneRequired: 'Phone Required',
  player: 'Player',
  playerHistory: 'Player History',
  playerName: 'Player Name',
  playerReferral: 'Player Referral',
  players: 'Players',
  playerStats: 'Player Stats',
  playerWallet: 'Player Wallet',
  portal: 'Portal',
  portalName: 'Portal Name',
  preferredLanguageRequired: 'Preferred Language Required',
  primaryCurrency: 'Primary Currency',
  profile: 'Profile',
  provider: 'Provider',
  providerName: 'Provider Name',
  providers: 'Providers',
  readership: 'readership',
  reason: 'Reason',
  reasonMax: 'Max 100 characters',
  reasonRequired: 'Reason Required',
  recipients: 'recipients',
  recurringDepositRate: 'Reocurring Deposit Rate',
  redFlag: 'Red Flag',
  redflag: 'Red Flag',
  redFlagDescription: 'Red Flag Description',
  redFlagReason: 'Red Flag Reason',
  redflagstatus: 'Red Flag Status',
  referralCode: 'Referral Code',
  referralCount: 'Referral Count',
  referralLink: 'Referral Link',
  referrals: 'Referrals',
  registrationDate: 'Registration Date',
  registrationDateEnd: 'Registration Date End',
  registrationDateStart: 'Registration Date Start',
  reject: 'Reject',
  rejected: 'Rejected',
  rejectKyc: 'Reject KYC',
  rejectRequest: 'Reject Request',
  removeAffiliate: 'Remove Affiliate',
  reoccuringBonus: 'Re-Occuring Bonus',
  reoccurring_deposit_rate: 'Reoccurring Deposit Rate',
  reoccurringDepositBonus: 'Re-Occuring Deposit Bonus',
  reporting: 'Reporting',
  request: 'Request',
  requestStatus: 'Request Status',
  required: 'Required',
  resetConfirmation: 'Are you sure you want to reset {{data}}',
  resetFilters: 'Reset Filters',
  resetPassword: 'Reset Password',
  resetPasswordFor: 'Reset Password for',
  roleRequired: 'Role Required',
  RollBack: 'rollback',
  rollingPercent: 'Rolling Percent',
  rollingPercentDescription: 'Rolling Percentage Description',
  saturday: 'Saturday',
  search: 'Search',
  searchByNameOrEmail: 'Search by Name or Email',
  searchName: 'Search Name',
  searchPlaceholder: 'Search Placeholder',
  searchProvider: 'Search Provider',
  searchSubCategory: 'Search Sub-Category',
  searchSubCategoryName: 'SearchSub Category Name',
  searchTitleSlug: 'Search title, slug',
  searchUsername: 'Search',
  searchUsernameOrName: 'Search username or Name',
  section: 'Section',
  seen: 'Seen',
  seenBy: 'Read By',
  seenStatus: 'Seen Status',
  segmentIdRequired: 'Segment ID Required!',
  select_bonus_limit: 'Select Bonus Limit',
  select_owner: 'Select Owner',
  selectAtLeastOneDay: 'Select at least one day',
  selectBank: 'Select Bank',
  selectBonus: 'Select Bonus',
  selectCategory: 'Select Category',
  selectCountry: 'Select Country',
  selectCurrency: 'Select a Currency',
  selectEndDate: 'Select end date and time',
  selectEndDateAndTime: 'Select end date and time',
  selectEndDateTime: 'Select end date and time',
  selectNotificationCategory: 'Select Notification Category',
  selectLanguage: 'Select Language',
  selectOneCategory: 'Select One Category',
  selectOneSenior: 'Select One Senior',
  selectOwner: 'Select Owner',
  selectPrimaryCurrency: 'Select Primary Currency',
  selectProvider: 'Select Provider',
  selectSenior: 'Select Senior',
  selectStartDate: 'Select start date and time',
  selectStartDateAndTime: 'Select start date and time',
  selectStartDateTime: 'Select start date and time',
  selectSubCategory: 'Select Sub Category',
  selectTenant: 'Select Tenant',
  seletProvider: 'Select Provider',
  sendNotification: 'Send Notification',
  sendPasswordResetEmail: 'Send Password Reset Email to',
  senior: 'Senior',
  seniorRequired: 'Senior (Required)',
  sent: 'Sent',
  setStatusActive: 'Set Status Active',
  setStatusInactive: 'Set Status Inactive',
  signInIP: 'Sign In IP',
  slug: 'Slug',
  slugMaxLength: 'Slug must be at most 30 characters',
  slugMinLength: 'Slug must be at least 3 characters',
  slugRequired: 'Slug is required',
  slugValidFormat: 'Enter a valid url slug',
  smsRequired: 'SMS Required',
  sNo: 'S.No',
  spaceNotAllowed: 'Space not allowed',
  spinsMustBeAnIntegerValue: 'Spins Must be an Integer Value',
  spinsMustBeAPositiveNumber: 'Spins Must be a Positive Number',
  spinsRequired: 'Spins Required',
  stake: 'Stake',
  startDate: 'Start Date',
  status: 'Status',
  subCategories: 'Sub Categories',
  subCategory: 'Sub Category',
  subCategoryName: 'Sub Category Name',
  subCategoryRequired: 'Sub Category Required',
  subject: 'Subject',
  submit: 'Submit',
  sunday: 'Sunday',
  superAdminUsernameFormat: 'Only Alphabets Allowed',
  taskOptions: 'Task Options',
  tenant: 'Tenant',
  tenantAdmin: 'Tenant Admin',
  tenantManagement: 'Tenant Management',
  tenantRequired: 'Tenant Required',
  tenants: 'Tenants',
  tenantSenior: 'Tenant Senior',
  testEmail: 'test@yopmail.com',
  thumbnail: 'Thumbnail',
  thursday: 'Thursday',
  timePeriodMustBeAnInteger: 'Time Period must be an integer',
  timePeriodMustBePositiveNumber: 'Time Period must be positive number',
  timePeriodRequired: 'Time Period Required',
  title: 'Title',
  titleRequired: 'Title for English is required',
  to: 'To',
  totalAmount: 'Total Amount',
  totalBetAmount: 'Total Bet Amount',
  totalDepositAmount: 'Total Deposit Amount',
  totalBonusAmount: 'Total Bonus Amount',
  totalCount: 'Total Count',
  totalGgr: 'Total GGR',
  totalGGR: 'Total GGR',
  totalGgrAmount: 'Total GGR Amount',
  totalNgr: 'Total NGR',
  totalNGR: 'Total NGR',
  totalPlayer: 'Total Player',
  totalUserCount: 'Total User Count',
  totalWager: 'Total Wager',
  totalWhitelisteCount: 'Total Whiteliste Count',
  transactionAction: 'Transaction Action',
  transactionId: 'Transaction Id',
  transactions: 'Transaction',
  transactionStats: 'Transaction Stats',
  transactionStatus: 'Transaction Status',
  transactionType: 'Transaction Type',
  transactionTypeRequired: 'Transaction type required',
  tuesday: 'Tuesday',
  undergoer: 'Under Goer',
  uniqueNumberBonus: 'Unique Number Bonus',
  unseen: 'Unseen',
  unsent: 'Unsent',
  unsupportedFormat: 'Uploaded file has unsupported format.',
  update: 'Update',
  updateCountryData: 'Update Country Data',
  updated: 'Updated',
  updateBanner: 'Update Banner',
  upload: 'Upload',
  userHistory: 'User History',
  userRankHistory: 'User Rank History',
  userId: 'User ID',
  userID: 'User ID',
  userName: 'User Name',
  username: 'Username',
  usernameRequired: 'User Name Required',
  userReporting: 'User Reporting',
  users: 'users',
  userTransaction: 'User Transaction',
  validFrom: 'Valid From',
  validFromDateRequired: 'Valid From Date Required',
  validFromMustBeGreaterThanOrEqualToTodaysDate: "Valid From Must be Greater Than or Equal To Today's Date",
  validInPromotions: 'Visible In Promotions',
  validOnDays: 'Valid on Days',
  validPhoneNumber: 'Enter a valid phone number with country code',
  validTill: 'Valid Till',
  validTo: 'Valid To',
  validToDateRequired: 'Valid To Date Required',
  validToMustBeGreaterThanValidFromDate: 'Valid To Must Be Greater Than Valid From',
  validToMustBeInRangeOfOneMonthFromValidFromDate: 'Valid To Must Be In The Range Of One Month From Valid From Date',
  validUsername: 'Enter Valid Username',
  vaultAmount: 'Vault Amount',
  vaultDeposit: 'Vault Deposit',
  vaultWithdraw: 'Vault WithDraw',
  view: 'View',
  viewDetails: 'View Details',
  cancelBet: 'Cancel Bet',
  viewReferredUser: 'View Referred User',
  viewTenants: 'View Tenants',
  viewTickets: 'View Tickets',
  viewUser: 'View User',
  wageringTypeRequired: 'Wagering Type Required',
  wagerType: 'Wager Type',
  wallet: 'Wallet',
  walletId: 'Wallet Id',
  walletRequest: 'Wallet Request',
  walletStats: 'Wallet Stats',
  walletTypeRequired: 'Wallet type required',
  wednesday: 'Wednesday',
  weekly: 'Weekly',
  weeklyLimit: 'Weekly Limit',
  whiteList: 'White List',
  whitelist: 'White List',
  Win: 'win',
  WIN: 'Win',
  withdraw: 'Withdraw',
  withdrawAccept: 'Withdraw Accept',
  withdrawal: 'Withdrawal',
  withdrawal_rate: 'Withdrawal Rate',
  withdrawalCurrency: 'Withdrawal Currency',
  withdrawalLimit: 'Withdrawal Limit Amount',
  withdrawalRate: 'Withdrawal Rate',
  withdrawLimit: 'Withdraw Limit',
  withdrawlLimit: 'Withdrawal Limit',
  withdrawReject: 'Withdraw Reject',
  withdrwalLimit: 'Withdrawal Limit',
  xxxXxxxxxxxx: '+(xxx)- xxxxxxxx',
  yes: 'Yes',
  zipCode: 'ZipCode',
  zipCodeMax: 'Maximum 6 Characters',
  zipCodeRequired: 'Zip Code Required',
  hot_time_bonus: 'Hot Time Bonus',
  compensation_bonus: 'Compensation Bonus',
  unique_number_bonus: 'Unique Number Bonus',
  re_occurring_bonus: 'Re-occurring Bonus',
  mark: 'Mark',
  selectReason: 'Select Reason',
  returnToPlayer: 'Return To Player',
  systemRtp: 'System RTP',
  bonusAbuser: 'Bonus abuser',
  duplicateAccount: 'Duplicate account',
  fraudulent: 'Fraudulent',
  riskCountry: 'Risk country',
  rudeBehaviour: 'Rude behaviour',
  bannedCountryConnectWithVpnOrTorBrowser: 'Banned country - connect with VPN or Tor Browser',
  kycDeclined: 'KYC declined',
  suspiciousConstantDepositsAndWithdrawalsMoneyLaundering: 'Suspicious - constant deposits and withdrawals (money laundering)',
  contactsSupportForABiggerAmountOfDepositThanAllowed: 'Contacts support for a bigger amount of deposit than allowed',
  responsibleGambling: 'Responsible Gambling',
  frozen: 'Frozen',
  deactivated: 'Deactivated',
  permabanned: 'Permabanned',
  tempbannedBlocked: 'Tempbanned/Blocked',
  gamblingIssues: 'Gambling issues',
  selfExcluded: 'Self-excluded',
  dpoErasureRequested: 'DPO erasure requested',
  kycBlockedSuspended: 'KYC blocked/suspended',
  kycBlockedDuplicate: 'KYC blocked/duplicate',
  kycBlockedBonusAbuse: 'KYC blocked/bonus abuse',
  kycBlockedChargeback: 'KYC blocked/chargeback',
  kycFraud: 'KYC fraud',
  failedKyc: 'Failed KYC',
  amlFailedSow: 'AML failed/SOW',
  amlManagementBan: 'AML management ban',
  amlOnHold: 'AML on hold',
  amlUnderReview: 'AML under review',
  rgUnderaged: 'RG underaged',
  rgUnderReview: 'RG under review',
  enforcedSelfExclusion: 'Enforced self-exclusion',
  locationBan: 'Location ban',
  csManagementBan: 'CS management ban',
  validated: 'Validated',
  usernameLabel: 'Username',
  passwordLabel: 'Password',
  oldPasswordLabel: 'Old Password',
  newPasswordLabel: 'New Password',
  confirmPasswordLabel: 'Confirm Password',
  confirmNewPasswordLabel: 'Confirm New Password',
  idOfUserLabel: 'ID of User',
  legalNameLabel: 'Legal Name',
  nationalityLabel: 'Nationality',
  dateOfBirthLabel: 'Date of Birth',
  selectMonth: 'Select Month',
  selectDay: 'Select Day',
  selectYear: 'Select Year',
  phoneLabel: 'Phone',
  preferredCurrencyLabel: 'Preferred Currency',
  bankNameLabel: 'Bank Name',
  accountNumberLabel: 'Account Number',
  withdrawalPasswordLabel: 'Withdrawal Password ',
  reEnterwithdrawalPasswordLabel: 'Re-enter Withdrawal password ',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  userIdPlaceholder: 'Enter user Id',
  accountFrozen: 'Your account is frozen till',
  userLoggedOut: 'Logged Out Successfully',
  loggingIn: 'Submitting please wait...',
  selectNationality: 'Select Nationality',
  selectBankName: 'Select Bank Name',
  selectPreferredCurrency: 'Select Preferred Currency',
  dontHaveAccount: ' Don’t have an account?',
  registerAnAccount: '  Register an Account',
  alreadyHaveAnAccount: 'Already have an account?',
  fillAllFields: 'Please fill out all required fields before signing up.',
  userNamePlaceholder: 'Enter Username',
  min8Numbers: 'Min 8 numbers required',
  passwordValidationMessage: 'Password must have at least 4 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
  withdrawlPasswordValidationMessage: 'Withdrawal password must have at least 4 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
  passwordPlaceholder: 'Enter Password',
  confirmPasswordPlaceholder: 'Enter Confirm Password',
  passwordWithdrawalPlaceholder: 'Enter Withdrawal Password',
  oldPasswordPlaceholder: 'Enter Old Password',
  newPasswordPlaceholder: 'Enter New Password',
  confirmNewPasswordPlaceholder: 'Enter Confirm New Password',
  confirmPasswordWithdrawalPlaceholder: 'Re Enter Withdrawal Password',
  legalNamePlaceholder: 'Enter Legal Name',
  phonePlaceholder: 'Enter Phone Number',
  searchPhonePlaceholder: 'Search Country',
  accountNumberPlaceholder: 'Enter Account Number',
  userIdRequired: 'User Id Required',
  userIdInvalid: 'Only alphanumeric is accepted',
  passwordWithdrawalRequired: 'Withdrawal Password Required',
  confirmPasswordWithdrawalRequired: 'Confirm Withdrawal Password Required',
  legalNameRequired: 'Legal Name Required',
  dateOfBirthRequired: 'Date Of Birth Required',
  userNameRequired: 'User Name Required',
  requiredAccountNumber: 'Account Number Required',
  matchPassword: 'Password must match',
  matchWithdrawalPassword: 'Withdrawal Password must match',
  onlyAlphabet: 'Only Alphabets allowed',
  mustBe18: 'You Must be 18 Year old to get registered',
  validDOB: 'Please enter a valid Date of Birth',
  max20Characters: 'Maximum 20 Characters Allowed',
  min3Characters: 'Minimum 3 Characters Required',
  min5Numbers: 'Minimum 5 digits required including dial code',
  onlySpaceNotAllowed: 'Spaces not allowed',
  max100Characters: 'Maximum 100 Characters Allowed',
  max50Characters: 'Maximum 50 Characters Allowed',
  max30Characters: 'Maximum 30 Characters Allowed',
  max16Numbers: 'Maximum 16 digits allowed including dial code',
  preferredCurrencyRequired: 'Preferred Currency Required',
  bankNameRequired: 'Bank Name Required',
  nationalityRequired: 'Nationality Required',
  legalName: 'Alphabets and spaces allowed only',
  legalNameOneSpace: 'Only one spaces allowed between words',
  selectPreferredLanguage: 'Select Preferred Language',
  enterValidPhone: 'Please enter valid phone number',
  userNameAllowed: 'Lowercase alphabets allowed and no spaces allowed.',
  validName: 'Username cannot start with a number, space.',

  userCreatedSuccess: 'User created successfully',
  userNameAlreadyExists: 'user name already exists',
  UserCountryCodeWithPhoneAlreadyExists: 'User country code with phone already exists',
  tenanatAreBlocked: 'Tenanat are blocked',
  addNewPlayer: 'Add new Player',
  markedAsInspection: 'Mark as inspection',
  markedSuccess: 'Marked Successfully',
  markedRemovedSuccess: 'Removed from inspection Successfully',
  areYouSureChangeInspectionStatus: 'Are you sure you want to update the inspection status ?',
  inspection: 'Inspection',
  addNote: 'Add Note',
  enterNote: 'Enter Note',
  addNoteFor: 'Add Note for',
  enterNoteRequired: 'Note Required',
  editUserKYC: 'Edit KYC',
  superadminNotes: 'Admin Note',
  seniorNotes: 'Tenant Note',
  enterLegalName: 'Enter Legal Name',
  enterBankName: 'Enter Bank Name',
  enterPhoneNumber: 'Enter Phone Number with code',
  kycEdit: 'Edit Kyc',
  accountNumberRequired: 'Account Number Required',
  enterPhoneCode: 'Enter Phone Code',
  phoneCode: 'Phone Code',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  inspectionDetails: 'Inspection Details',
  type: 'Type',
  select: 'Select',
  banner: 'Banner',
  Update: 'Update',
  Create: 'Create',
  imageFormatRequired: 'Image must be of the format type jpg, png, webp',
  imageWidthBetween: 'Image width must be between',
  imageHeightBetween: 'Image height must be between',
  homePageTopBanner: 'Home Page Top Banner',
  homePageSportsBanner: 'Home Page Sports Banner',
  homePageCasinoBanner: 'Home Page Casino Banner',
  casinoTopBanner: 'Casino Top Banner',
  casinoEntryModel: 'Casino Entry Banner',
  popupBanner: 'Popup Banner',
  withdrawalLimitTime: 'Withdrawal Limit Time',
  enterWithdrawalLimitTime: 'Enter Withdrawal Limit Time',
  withdrawalLimitTimeUnit: 'Withdrawal Limit Time Unit',
  selectTimeUnit: 'Select Withdrawal Limit Time Unit',
  kycSeniorStatus: 'Kyc Senior Status',
  kycStatusSenior: 'Kyc Senior Status',
  kycStatusAdmin: 'Kyc Status Admin',
  valueMustBeTwoDigitAfterDecimal: 'Only 2 digit allowed after decimal',
  valueMustBePositive: 'Value must be positive',
  valueMustBeNumber: 'Value Must Be Number',
  nonCashRequest: 'Non Cash Request',
  userWalletTransaction: 'User Wallet Transaction',
  transaction: 'Transaction',
  walletPlayer: 'Wallet Player',
  walletSenior: 'Senior Wallet',
  rejectTime: 'Rejected due to time limit exceed',
  gameWithCustomRtp: 'Game With Custom Rtp',
  firstDepositBonus: 'First Deposit Bonus',
  phoneCodeTooShort: 'Phone Code Too Short',
  phoneCodeTooLong: 'Phone Code Too Long',
  phoneCodeNoSpaces: 'Phone Code No Spaces',
  phoneCodeRequired: 'Phone Code Required',
  ipLimit: 'No. of IPs allowed',
  incorrectPasswordLimit: 'No. of Incorrect Password Try allowed',
  incorrectPasswordLimitPlaceholder: 'Enter no. of Incorrect Password Try allowed',
  ipLimitPlaceholder: 'Enter no. of IPs allowed',
  maxIpLimit: 'Maximum no. of IPs allowed is 10',
  minIpLimit: 'Minimum 1 IP should be allowed',
  seniorTransactionStats: 'Senior Transaction Stats',
  reportTransaction: 'Transaction Report',
  walletTransaction: 'Wallet Transaction',
  reportFor: 'Report for',
  totalDepositCount: 'Total Deposit Count',
  totalWithdrawlAmount: 'Total Withdrawal Amount',
  totalWithdrawalCount: 'Total Withdrawal Count',
  confirmReject: 'you are rejecting passowrd request!',
  changePassword: 'Change Password',
  resetAccept: 'Password reset accept',
  resetReject: 'Password reset reject',
  resetPending: 'Password reset pending',
  createVoucher: 'Create Voucher',
  voucher: 'Voucher',
  voucherName: 'Voucher Name',
  voucherNamePlaceholder: 'Enter Name',
  voucherValidFrom: 'Voucher Valid From',
  voucherValidTo: 'Voucher Valid To',
  betTarget: 'Bet Target',
  betTargetPlaceholder: 'Enter Bet Target',
  voucherBenefits: 'Voucher Benefit Amount',
  voucherBenefitsPlaceholder: 'Enter Benefit Amount',
  editVoucher: 'Edit Voucher',
  betTargetRequired: 'Bet Target Required',
  voucherBenefitsRequired: 'Voucher Benefit Amount Required',
  voucherRequired: 'Voucher Name Required',
  voucherDetails: 'Voucher Details',
  voucherUsers: 'Voucher Users',
  voucherGames: 'Voucher Games',
  benefitType: 'Benefit Type',
  benefitTypePlaceholder: 'Select Benefit Type',
  voucherUseLimit: 'Voucher Use Limit',
  voucherUseLimitPlaceholder: 'Enter Use Limit',
  levelingSystem: 'Leveling System',
  templates: 'Templates',
  template: 'Template',
  selectTemplate: 'Select Template',
  templateRequired: 'Template Required',
  seasons: 'Seasons',
  listOfTemplates: 'List of Templates',
  listOfSeasons: 'List of Seasons',
  addTemplate: 'Add Template',
  addSeason: 'Add Season',
  editTemplate: 'Edit Template',
  editSeason: 'Edit Season',
  requiredAndGreaterThan0: 'Required and greater than 0',
  nameRequired: 'Name required',
  ranks: 'Ranks',
  nameOfSeason: 'Name of Season',
  nameOfTemplate: 'Name of Template',
  detailsOfSeason: 'Details of Season',
  detailsOfTemplate: 'Details of Template',
  sizeTooLarge: 'Size too large',
  mustBePositive: 'Must be positive',
  updateSuccess: 'Updated Successfully',
  createSuccess: 'Create Successfully',
  season: 'Season',
  enNameRequired: 'English name is required, others are optional',
  makeActive: 'Make Active',
  makeInActive: 'Make In Active',
  confirmStatus: 'Are you sure you want to change the status?',
  oneFieldRequired: 'One of the fields is required from bonus, cash and withdrawal limit',
  thresholdAmount: 'Threshold Amount',
  maximum100: 'Percent value must not be exceed 100',
  deleteTemplate: 'Delete Template',
  deleteSeason: 'Delete Season',
  enterPercentage: 'Enter Percentage',
  base: 'Base',
  searchByTemplateAndSeason: 'Search by Template and Season',
  searchByTemplate: 'Search by Template',
  voucherUseLimitRequired: 'Voucher Use Limit Required',
  benefitTypeRequired: 'Voucher Benefit Type Required',
  minValue: 'Enter a value greater than 0',
  BONUS: 'BONUS',
  CASH: 'CASH',
  'WITHDRAWAL-LIMIT': 'WITHDRAWAL-LIMIT',
  selectBenefitType: 'Selec benefits type',
  enterBenefitValue: 'Enter benefit value',
  bonusIsInPercent: 'Bonus is in percentage and its value must not be greater than 100',
  benefitNote: 'Note: Benefit type BONUS is in percentage and its value must not be greater than 100',
  benefit: 'Benefit',
  benefitsType: 'Benefit Type',
  integerValueError: 'Must be integer value',
  voucherTransactions: 'Voucher Transactions',
  endDateMustBeAfterStartDate: 'End date must be after start date',
  levelWithdrwalLimit: 'Level Withdrawal Limit',
  greaterThan0: 'Number must be greater than 0',
  affiliateManagement: 'Affiliate Management',
  affiliate: 'Affiliate',
  primaryLanguage: 'Primary Language',
  selectPrimaryLanguage: 'Select Primary Language',
  dateOfJoining: 'Date of Joining',
  affiliateId: 'Affiliate ID',
  idAffiliateRequired: 'Id of Affiliate Required',
  primaryLanguageRequired: 'Primary Language Required',
  userTypeRequired: 'User Type Required',
  idRequired: 'Id Required',
  agentNameRequired: 'Agent Name Required',
  dateRequired: 'Date Required',
  invalidDateFormat: 'Invalid Date Format',
  signInAsSuperAdmin: 'Sign In As Super Admin',
  signInAsTenant: 'Sign In As Tenant',
  signInAsAffiliate: 'Sign In As Affiliate',

  emailOrUsername: 'Email or Username',
  loginCode: 'Login Code',
  enterLoginCode: 'Enter Login Code',
  loginCodeRequired: 'Login Code Required',
  emailOrUsernameRequired: 'Email or Username Required',
  enterAffiliateId: 'Enter Affiliate Id',
  fromDate: 'From Date',
  toDate: 'To Date',
  parentRole: 'Parent Role',
  joinDate: 'Date of Joining',
  affiliateToken: 'Affiliate Token',
  parent: 'Parent',
  parentUsername: 'Parent Username',
  agentManagement: 'Agent Management',
  agent: 'Agent',
  agentWithoutAffiliate: 'Agents without affiliate',
  selectAffiliate: 'Select affiliate',
  agentId: 'Enter agent Id',
  enterAgentId: 'Enter Agent Id',
  agentIdRequired: 'Agent id required',
  SignInOption: 'Sign In Options',
  signInAsAgent: 'Singn In As Agent',
  SignIn: 'Sign In',
  selectAgent: 'Select Agent',
  startSeason: 'Start Season',
  startEnd: 'Start End',
  playerRankIncrement: 'Player Rank Increment',
  bonusBenefitClaim: 'Bonus Benefit Claim',
  cashBenefitClaim: 'Cash Benefit Claim',
  withdrawlBenefitClaim: 'Withdrawal Benefit Claim',
  riskManagement: 'Risk Management',
  securityAlerts: 'Security Alerts',
  actionStatus: 'Action Status',
  actionTaken: 'Action Taken',
  superadmin: 'Super Admin',
  admin: 'Admin',
  giftCard: 'Gift Card',
  redemptionDate: 'Redemption Date',
  voucherDeposit: 'Voucher Deposit',
  giftcardDeposit: 'Gift Card Deposit',
  listOfRollingCompetetion: 'List Of Rolling Competitions',
  betAmount: 'Bet Amount',
  enterBetAmount: 'Enter Bet Amount',
  rollingCompetetion: 'Rolling Competition',
  details: 'Details',
  rollingcontestReport: 'Rolling Contest Reporting',
  contestName: 'Contest Name',
  registerUser: 'Register Player',
  targetAmount: 'Target Amount',
  currentAmount: 'Current Amount',
  multipliedOdds: 'Multiplied Odds',
  winningAmount: 'Winning Amount',
  refund: 'Refund',
  cashout: 'Cashout',
  half_won: 'Half Won',
  half_lost: 'Half Lost',
  single: 'Single',
  multiple: 'Multiple',
  betslipId: 'Betslip Id',
  eventId: 'Event Id',
  eventMarketId: 'Event Market Id',
  eventMarketOutcomeId: 'Event Market Outcome Id',
  odds: 'Odds',
  betSettlementStatus: 'Settlement Status',
  mtsStatus: 'Provider Status',
  matchStatus: 'Match Status',
  lost: 'Lost',
  won: 'Won',
  in_game: 'In Game',
  sportsTransactions: 'Sports Transactions',
  betDetails: 'Bet Details',
  betTransactions: 'Bet Transactions',
  winner: 'Won',
  loser: 'Lost',
  canceled: 'Cancelled',
  cash_out: 'Cashout',
  placed: 'Bet Placed',
  rollback: 'Rollback',
  agent_commission: 'Agent Commission',
  journalEntry: 'Journal Entry',
  credit: 'Credit',
  debit: 'Debit',
  currentBalance: 'Current Balance',
  currencyCode: 'Currency Code',
  beforeBalance: 'Before Balance',
  sports: 'Sports',
  countries: 'Countries',
  matches: 'Matches',
  leagues: 'Leagues',
  markets: 'Markets',
  sportsbookManagement: 'SportsBook Management',
  searchSports: 'Search Sports',
  sportsStatus: 'Sports Status',
  sportName: 'Sport Name',
  providerSportId: 'Provider Sport Id',
  searchCountries: 'Search Countries',
  countryStatus: 'Country Status',
  providerCountryId: 'Provider Country Id',
  tournamentName: 'Tournament Name',
  matchTime: 'Match Time',
  homeTeam: 'Home Team',
  awayTeam: 'Away Team',
  liveStatus: 'Live Status',
  searchMarkets: 'Search Markets',
  marketName: 'Market name',
  providerMarketId: 'Provider Market Id',
  Enable: 'Enable',
  Disable: 'Disable',
  enabled: 'Enabled',
  disabled: 'Disabled',
  live: 'Live',
  notLive: 'Pre Matches',
  searchTournaments: 'Search Tournaments',
  event: 'Event',
  market: 'Market',
  selection: 'Selection',
  thumbnailRequired: 'Thumbnail required !',
  editSport: 'Edit Sport',
  editLeague: 'Edit League',
  setEnable: 'Set Enable',
  setDisable: 'Set Disable',
  reorder: 'Reorder',
  reorderLeagues: 'Reorder Leagues',
  reorderSports: 'Reorder Sports',
  reorderNote: 'Note: Drag and drop vertically to reorder.',
  agentToken: 'Agent Token',
  noNotification: 'No Notifications yet',
  eventDate: 'Event Date',
  eventDescription: 'Event description',
  events: 'Events',
  totalWithDrawlAmount: 'Total WithDrawal Amount',
  totalNonCashAmount: 'Total Non-Cash Amount',
  inspectedAmount: 'Inspected Amount',
  inspectedData: 'Inspected Data',
  viewInspectedData: 'View Inspected Data',
  delete: 'Delete',
  selectBonusLimit: 'Select Bonus Limit',
  customLimit: 'Custom Limit',
  select_bonus_type: 'Select Bonus Type',
  enterValue: 'Enter value',
  enterValueInPercent: 'Enter value in percentage',
  mustbeValidDate: 'Must be a valid date',
  fieldRequired: 'Field is required',
  maxBonusLimitGreaterThan: 'Max bonus limit must be greater than limit value',
  reOccuringDepositBonus: 'Re Occuring Deposit bonus',
  uniqueTicketBonus: 'Unique Ticket Bonus',
  deleteSuccess: 'Deleted successfully',
  bonusNotification: 'Bonus Notification',
  allNotification: 'All Notification',
  bulkUpdate: 'Bulk Update',
  selectMoreThanOne: 'Select more than one to update in bulk',
  minimumValue0: 'Minimum value is 0',
  maximumValue100: 'Maximum value is 100',
  maximumValue999: 'Maximum value is 999',
  maximumValue100000: 'Maximum value is 100000',
  maximumValue9: 'Maximum value is 9',
  maxValueIs: 'Maximum value is',
  validToLater: 'Valid to date must be later than valid from date',
  affiliateAgent: 'Affiliate Invited Agent',
  affiliatePlayer: 'Affiliate Invited Player',
  seniorPlayer: 'Senior Invited Player',
  bonusConfigured: 'Bonuses Configured',
  true: 'True',
  false: 'False',
  confirmRemove: 'Are you sure you want to remove?',
  remove: 'Remove',
  removeSuccess: 'Removed Successfully',
  limitType: 'Limit Type',
  'HOT-TIME-BONUS': 'HOT-TIME-BONUS',
  'COMPENSATION-BONUS': 'COMPENSATION-BONUS',
  'RE-OCCURING-DEPOSIT-BONUS': 'RE-OCCURING-DEPOSIT-BONUS',
  'FIRST-DEPOSIT-BONUS': 'FIRST-DEPOSIT-BONUS',
  'UNIQUE-TICKET-BONUS': 'UNIQUE-TICKET-BONUS',
  updatedAt: 'Updated At',
  max50CharactersRequired: 'The maximum length for this field is 250 characters',
  hotCombo: 'Hot Combo',
  nameOfHotcombo: 'Name of Hot Combo',
  sport: 'Sport',
  league: 'League',
  selectLeague: 'Select League',
  match: 'Match',
  selectMatch: 'Select Match',
  selectMarket: 'Select Market',
  createHotCombo: 'Create Hot Combo',
  hotComboDetails: 'Hot Combo Details',
  matchRequired: 'Please select a match',
  marketRequired: 'Please select a market',
  outcomeError: 'Please select minimum 2 or maximum 4 outcomes',
  comboName: 'Combo Name',
  outcomeName: 'Outcome Name',
  sportsTransactionStats: 'SportsBook Transaction Stats',
  partial_won: 'Partial Won',
  completed: 'Completed',
  betSlipStatus: 'BetSlip Status',

  WITHDRAW: 'WITHDRAW',
  DEPOSIT: 'DEPOSIT',
  'VAULT-DEPOSIT': 'VAULT-DEPOSIT',
  'VAULT-WITHDRAW': 'VAULT-WITHDRAW',
  'HOT-TIME-BONUS-DEPOSIT': 'HOT-TIME-BONUS-DEPOSIT',
  'REOCCURING-BONUS-DEPOSIT': 'REOCCURING-BONUS-DEPOSIT',
  'FIRST-DEPOSIT-BONUS-DEPOSIT': 'FIRST-DEPOSIT-BONUS-DEPOSIT',
  promotionCash: 'Promotion Cash',
  revenuePercentage: 'Revenue Percentage',
  withdrawlLimitCurrency: 'Withdrawl Limit Currency',
  revenue: 'Revenue',
  revenueDetails: 'Revenue Details',
  onlyOneSpaceBetweenWordRequired: 'Only one space between words allowed',
  agentWithOutAffiliate: 'Agent Without Affiliate',
  useLimit: 'Use Limit',
  benefitAmount: 'Benefit Amount',
  createGiftCard: 'Create Gift Card',
  giftcardDetails: 'Gift Card Details',
  editGiftcard: 'Edit Gift Card',
  giftcardUsers: 'Gift Card Users',
  giftCardNameRequired: 'Gift Card Name Required',
  giftCardUseLimitRequired: 'Gift Card Use Limit Required',
  giftCardBenefitsRequired: 'Gift Card Benefits Required',
  giftCardBenefitTypeRequired: 'Gift Card Benefits Type Required',
  giftCardTransaction: 'Gift Card Transactions',
  giftCardName: 'Gift Card Name',
  giftCardBenefits: 'Gift Card Benifit',
  somethingWentWrong: 'Something went wrong',
  playerRankHistory: 'Player Rank History',
  role: 'Role',
  bannerUrl: 'Banner URL',
  userNameFirstCharacterLetter: 'First character should be alphabet',
  addGames: 'Add New Games',
  gamesAdded: 'Games Added',
  ifYouDontSelectReason: 'If you do not select an agent or affiliate, the player will not be associated with any agent or affiliate.',
  updatePlayerAffilate: 'Update Player Affiliate',
  updatePlayerAgent: 'Update Player Agent',
  confirmOwnership: 'Do you want to update ownership?',
  depositTransactions: 'Deposit Transaction',
  withdrawalTransactions: 'Withdrawal Transaction',
  totalDepositRequest: 'Total deposit request',
  case: 'Case',
  now: 'Now',
  userInfo: 'User Info',
  withdrawalHistory: 'Withdrawal History',
  withdrawalRequest: 'Withdrawal Request',
  depositHistory: 'Deposit History',
  depositRequest: 'Deposit Request',
  accountInfo: 'Account Info',
  totalWithdrawalRequest: 'Total Withdrawal Request',
  totalWithdrawalAmount: 'Total Withdraw Amount',
  seniorName: 'Senior Name',
  confirmWithdrawalPasswordLabel: 'Confirm Withdrawal Password',
  newWithdrawalPasswordLabel: 'New Withdrawal Password',
  vaultWithdrawalHistory: 'Vault Withdrawal History',
  vaultDepositHistory: 'Vault Deposit History',
  goToPage: 'Go to page',
  price: 'Price',
  possibleWin: 'Possible Win',
  betSlipId: 'BetSlip Id',
  setTop: 'Add in Top',
  removeTop: 'Remove from Top',
  slotCatBonusPercent: 'Slot Categories Percentage',
  liveCatBonusPercent: 'Live Games Categories Percentage',
  searchPage: 'Enter Page Number',
  totalDepositMinusWithdrawal: 'Total (Deposit - Withdrawal) Amount',
  paybackBonus: 'Payback Bonus',
  withdrawalRequestTime: 'Minimum  Time for withdrawal request',
  withdrawalRequestTimeUnit: 'Time unit',
  enterWithdrawalRequestTime: 'Enter Minimum  Time for withdrawal request',
  bonusAmount: 'Bonus Amount',
  totalVaultAmount: 'Total Vault Amount',
  totalBonusWalletAmount: 'Total Bonus Amount',
  adminDepositSubpayment: 'Admin Deposit Sub Payment',
  adminWithdrawSubpayment: 'Admin Withdraw Sub Payment',
  requestedCreatedAt: 'Requested at',
  actionTakenAt: 'Action taken at',
  notificationDetails: 'Notification Details',
  chooseTenantAdmin: 'Select Senior',
  noteForCreateNotification: 'Note: If no user is selected, the message goes to all. Select Senior to proceed.',
  getUser: 'View User',
  back: 'Back',
  editNotification: 'Edit Notification',
  noPlayerSelected: 'No Player Selected',
  selectAgentWithoutAffiliate: 'Select Agent Without Afiliate',
  confirmSendNotification: 'Confirm Send Notification',
  sportsbookTopBanner: 'Sportsbook Top Banner',
  tournaments: 'Tournaments',
  newGames: 'New Games',
  reset: 'Reset',
  confirmCancelBetAndRefund: 'Are you sure you want to cancel and refund the stake to the player?',
  editHotCombo: 'Edit Hot Combo',
  comboEventsList: 'Hot Combo Final Events List',
  clickToAddMoreEvents: 'Click to add more events',
  teams: 'Teams',
  searchTeamByName: 'search team by name',
  desktopImage: 'Desktop Image',
  mobileImage: 'Mobile Image',
  currentIPAddress: 'Current IP Address',
  PrevIpAddress: 'Previous IP Address',
  unHideGame: 'Unhide Game',
  hideGame: 'Hide Game',
  previouslySelectedUser: 'Previously Selected User',
  selectOtheruser: 'Select Other User',
  selectAllOfThisPage: 'Select all of this page',
  dimensions: 'Dimensions',
  depositAddOnConfig: 'Deposit Addon Config',
  selectDepositAddonType: 'Select Deposit Addon Type',
  addOnPercentage: 'Addon Percentage',
  addOnPercentagePlaceholder: 'Enter Addon Percentage',
  addOnRelativeAmount: 'Addon Relative Amount',
  addOnRelativeAmountPlaceholder: 'Enter Addon Relative Amount',
  rollingPercentage: 'Rolling Percentage',
  rollingPercentagePlaceholder: 'Enter Rolling Percentage',
  reoccurringDepositConfig: 'Reoccur Deposit Addon Config',
  firstDepositConfig: 'First Deposit Addon Config',
  defaultDepositConfig: 'Default Depost Addon Config',
  addOnTypeRequired: 'Addon type is required',
  addOnPercentageRequired: 'Addon Percentage is required',
  addOnRelativeAmountRequired: 'Addon Relative Amount is required',
  rollingPercentageRequired: 'Rolling Percentage is required',
  pleaseSelectTenant: 'Please select senior first to Add/Modify Deposit addon config',
  selectAddOnConfigType: 'Select Add On Config Type',
  selectAddOnConfigValueType: 'Select Add On Config Value Type',
  addOnConfigType: 'Add On Config Type',
  numberOfAcceptedRequests: 'No of Accepted Requests',
  addOnDepositSettings: 'Add On Deposit Settings',
  walletType: 'Wallet Type',
  selectWalletType: 'Select Wallet Type',
  casinoBonus: 'Casino bonus',
  uniqueCodeBonus: 'Unique Code Bonus',
  editUniqueCodeBonus: 'Edit Unique Code Bonus',
  viewUniqueCodeBonus: 'Unique Code Bonus Details',
  createUniqueCodeBonus: 'Create Unique Code Bonus',
  depositTarget: 'Deposit Target',
  couponName: 'Coupon Name',
  remainingUses: 'Remaining Uses',
  sportsbookBonus: 'Sportbook Bonus',
  bonusName: 'Bonus Name',
  searchMatch: 'Search Match',
  bonusImage: 'Bonus Logo',
  addOnBonus: 'Addon Odds',
  enterBonusName: 'Enter Bonus Name',
  pleaseSelectSenior: 'Please select senior first to enable this field',
  valueWillConciderInPercentage: 'Value will consider in percentage',
  matchList: 'Match List',
  selectedMatchList: 'Selected Match List',
  pleaseSelectMatch: 'Please Select Match',
  noMatchesAvailable: 'No Matches Available for this selection',
  bonusCreatedSuccessfully: 'Bonus Created Successfully',
  bonusUpdatedSuccessfully: 'Bonus Updated Successfully',
  pleaseSelectAtLeastOneMatch: 'Please select at least one match from the match list',
  onlyOneMatchCanBeSelect: 'Only one match can be select for BETTING BONUS type',
  enterOddValue: 'Enter Odds',
  editSportsBonus: 'Edit Sportbook Bonus',
  createSportsBonus: 'Create Sportbook Bonus',
  pleaseSelectAtLeastTwoMatch: 'Please select at least two match from the match list for MIXED MATCH BONUS',
  sportsReportings: 'Sports Reportings',
  casinoReportings: 'Casino Reportings',
  userReportings: 'User Reportings',
  bonusReportings: 'Bonus Reportings',
  walletReportings: 'Wallet Reportings',
  applyAddon: 'Apply Add-on',
  depositAddonReporting: 'Deposit Add-on Reporting',
  agentName: 'Agent Name',
  affiliateName: 'Affiliate Name',
  amountSelected: 'Amount Selected',
  winningBonus: 'Winning Bonus',
  bettingBonus: 'Betting Bonus',
  mixedMatchBonus: 'Mixed Match Bonus',
  betCompenSationBonus: 'Bet Compensation Bonus',
  casinoBonusTransactions: 'Casino Bonus Transaction',
  sportsBonusTransactions: 'Sportsbook Bonus Transaction',
  v2UserCasinoTransaction: 'V2 User Casino Transaction',
  pleaseSelectProviderType: 'Note: Please select provider type to withdraw amount',
  selectProviderType: 'Select provider type',
  bulkWithdraw: 'Bulk Withdraw',
  totalWithdrawableAmount: 'Total withdrawable amount',
  casino: 'Casino',
  remainingAmount: 'Remaining Amount',
  withdrawalAmount: 'Withdrawal Amount',
  payout: 'Payout',
  payoutHotTimeBonus: 'Payout Hot Time Bonus',
  payoutCompensationBonus: 'Payout Compensation Bonus',
  payoutPaybackBonus: 'Payout Payback Bonus',
  payoutPromotionCash: 'Payout Promotion Cash',
  payoutDailyPaybackBonus: 'Payout Daily Payback Bonus',
  payoutWeeklyPaybackBonus: 'Payout Weekly Payback Bonus',
  payoutBiWeeklyPaybackBonus: 'Payout BI Weekly Payback Bonus',
  payoutMonthlyPaybackBonus: 'Payout Monthly Payback Bonus',
  payoutRackbackBonus: 'Payout Rackback Bonus',
  nonWithdrawableWallet: 'Non-Withdrawable Wallet',
  promotionCashWallet: 'Promotion Cash Wallet',
  bonusWallet: 'Bonus Wallet',
  payoutWallet: 'Payout Wallet',
  maxBonusLimit: 'Max Bonus Limit',
  bonusPercent: 'Bonus Percent',
  amountToBeAdded: 'Amount to be Added',
  addOnPercentageInvalid: 'Add-on percentage cannot exceed 3 digits, be negative, or contain letters.',
  rollingPercentageInvalid: 'Rolling percentage cannot exceed 3 digits, be negative, or contain letters.',
  addOnRelativeAmountInvalid: 'Amount must be positive, less than 6 digits, and cannot contain letters.',
  bonusReportV2: 'V2 User Bonus Report',
  logsReporting: 'Logs Reporting',
  casinoLogs: 'Casino Logs',
  errorLogs: 'Error Logs',
  addOnAmount: 'Add On Amount',
  requestDetails: 'Request Details',
  pleaseSpecifyDepositConfig: 'Please specify the deposit configuration.',
  promotionalTransaction: 'Promotional Transaction',
  vault: 'Vault',
  nicknameRequired: 'Nickname is required.',
  nickNameLabel: 'Nickname',
  nickNamePlaceholder: 'Enter Nickname',
  systemReturnToPlayer: 'System Return To Player',
  customizeRtpUpdate: 'Customize System Rtp Update',
  customizeRtpUpdateMessage: 'Update System RTP by Category and Provider',
  bulkUpdateRtpMessage: 'Update System RTP of selected categories',
  systemRtpRequired: 'System Rtp Required',
  valueMustNotExceed100: 'Value should not exceed value of 100',
  enterSystemRtp: 'Enter System RTP',
  customLimitLessThan8digit: 'Custom limit must be less than 8 digits.',
  maximumValue99: 'Maximum value must be 99.',
  minPercentageShouldBeLesserThan1000: 'Minimum percentage must be less than 1000.',
  anonymousUsers: 'Anonymous User',
  nickNamAlreadyExists: 'Nickname already exists.',
  anonymousUser: 'Anonymous User',
  sportsTransactionsV2: 'Sports Transaction V2',
  payoutWinningBonus: 'Payout Winning Bonus',
  payoutBettingBonus: 'Payout Betting Bonus',
  payoutMixedMatchBonus: 'Payout Mixed Match Bonus',
  payoutBetCompenSationBonus: 'Payout Bet Compensation Bonus',
  promotionPayout: 'Promotion Payout',
  bonusCash: 'Bonus Cash',
  bonusPayout: 'Bonus Payout',
  nonWithrawableRolledAmount: 'NonWithdrawable Rolled Amount',
  bonusRolledDetails: 'Bonus Rolled Details',
  promotionRolledDetails: 'Promotion Rolled Details',
  migratePlayerToV2: 'Migrate Player To V2',
  rackBackBonus: 'Rackback Bonus',
  periodicPaybackBonus: 'Periodic Payback Bonus',
  'RACKBACK-BONUS': 'Rackback Bonus',
  'MONTHLY-PERIODIC-PAYBACK-BONUS': 'Monthly Periodic Payback Bonus',
  'BI-WEEKLY-PERIODIC-PAYBACK-BONUS': 'Bi-Weekly Periodic Payback Bonus',
  'WEEKLY-PERIODIC-PAYBACK-BONUS': 'Weekly Periodic Payback Bonus',
  'DAILY-PERIODIC-PAYBACK-BONUS': 'Daily Periodic Payback Bonus',
  selectTenantBonus: 'Select a tenant to specify and configure the bonus.',
  userMigrationReport: 'User Migration Report',
  migrationHistory: 'Migration History',
  nonWithdrawableRolled: 'NonWithdrawable Rolled',
  bonusRolled: 'Bonus Rolled',
  promotionRolled: 'Promotion Rolled',
  rackbackPercent: 'Rackback Percent',
  dailyStackLimit: 'Daily Stack Limit',
  rackbackPercentPlaceholder: 'Enter Rackback Percent',
  dailyStackLimitPlaceholder: 'Enter Daily Stack Limit',
  anonymous: 'Anonymous',
  known: 'Known',
  sportsTransactionsBetBy: 'BetBy Sports Transaction',
  betTransactionsBetBy: 'BetBy Bet Transaction ',
  userRollTransactions: 'User Roll Transactions',
  dailyPaybackBonus: 'Daily Payback Bonus',
  weeklyPaybachBonus: 'Weekly Payback Bonus',
  biWeeklyPayBackBonus: 'BI Weekly Payback Bonus',
  monthlyPaybackBonus: 'Monthly Payback Bonus',
  rackbackBonus: 'Rackback Bonus',
  walletLogs: 'Wallet Logs',
  user: 'User',
  logType: 'Log Type',
  walletRequestId: 'Wallet Request ID',
  previousData: 'Previous Data',
  updateData: 'Update Data',
  totalNonWithdrawableAmount: 'Total Non Withdrawable Amount',
  totalPromotionCash: 'Total Promotion Cash',
  totalPayoutAmount: 'Total Payout Amount',
  sportsPeriodicPaybackBonus: 'Sports Periodic Payback Bonus',
  sportsPromotionCashConfig: 'Sports Promotion Cash Config',
  pleaseSelectTenantForPromotionCashConfig: 'Please select senior first to Add/Modify Promotion Cash config',
  sportsRollingPercentage: 'Sports Rolling Percentage',
  sportsBookProviders: 'Sportsbook Providers',
  withdrawableWallet: 'Withdrawable Wallet',
  playersV1: 'V1 Players',
  playersV2: 'V2 Players',
  kycApprovedPlayers: 'KYC Apporved Players',
  kycRejectedPlayers: 'KYC Rejected Players',
  kycPendingPlayers: 'KYC Pending Players',
  V2transactions: 'V2 Transactions',
  bonusText: 'Bonus Text',
  bonusTextPlaceholder: 'Bonus Text Placeholder',

  // new notification translation
  markAllRead: 'Mark all read',
  deleteAll: 'Delete all',
  notificationMarkedAllAsRead: 'Marked all as read successfully',
  notificationDeleteAllSuccess: 'Notification deleted all successfully',
  newBonusCreated: 'New bonus created, please check bonus section',
  requestBody: 'Request Body',
  INCORRECT_PASSWORD: 'INCORRECT-PASSWORD',
  WALLET_DEPOSIT_REQUEST: 'WALLET-DEPOSIT-REQUEST',
  WALLET_WITHDRAW_REQUEST: 'WALLET-WITHDRAW-REQUEST',
  KYC_REQUEST: 'KYC-REQUEST',
  betBySportsLogs: 'Sports Logs',
  userWallet: 'Wallet',
  userBonusWallet: 'Casino Bonus Wallet',
  userCasinoPayout: 'Casino Payout',
  userSportsPayout: 'Sports Payout',
  userSportsBonusWallet: 'Sports Bonus Wallet',
  weeklyPaybackBonus: 'Weekly Payback Bonus',
  biWeeklyPaybackBonus: 'Bi-Weekly Payback Bonus',
  nonWithdrawableAmount: 'Non-Withdrawable Amount',
  withdrawableAmount: 'Withdrawable Amount',
  betCompensationBonus: 'Bet Compensation Bonus',
  walletDetails: 'Wallet Details',
  withdrawable: 'Withdrawable',
  nonWithdrawable: 'Non-Withdrawable',
  casinoBonusAmount: 'Casino Bonus Amount',
  sportsBonusAmount: 'Sports Bonus Amount',
  casinoPayoutAmount: 'Casino Payout Amount',
  sportsPayoutAmount: 'Sports Payout Amount',
  imageText: 'Image Text',
  imageDescription: 'Image Description',
  desktopFile: 'Desktop File',
  mobileFile: 'Mobile File',
  desktopBanner: 'Desktop Banner',
  mobileBanner: 'Mobile Banner',
  enterImageText: 'Enter Image Text',
  casinoBetHistory: 'Casino Bet History',
  betBySportsBetHistory: 'Sports Bet History',
  freebet_no_risk: 'Free Bet No Risk',
  freebet_money: 'Free Money',
  freebet_refund: 'Free Bet Refund'
}
