import React, { useEffect } from 'react'

import { Button, Col, Form as BForm, Row } from '@themesberg/react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getPaymentProviderStart } from '../../../../../store/redux-slices/payment'
import { toast } from '../../../../../components/Toast'
import { InlineLoader } from '../../../../../components/Preloader'

const PaymentMethod = ({
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  setCurr,
  bonusDetail,
  setSelectedTab,
  setActiveTab,
  isEdit = false,
  selectedTab,
  isTenant = false
}) => {
  const { paymentProviders, providerLoading } = useSelector((state) => state.payment)
  const dispatch = useDispatch()

  useEffect(() => {
    if (values?.tenantIds?.length) {
      dispatch(getPaymentProviderStart({
        isTenant,
        paymentType: '',
        aggregator: '',
        tenant: '',
        group: '',
        status: '',
        tenantIds: JSON.stringify(values?.tenantIds.map((id) => id.value))
      }))
    }
  }, [values?.tenantIds])

  useEffect(() => {
    if (paymentProviders && !isEdit && selectedTab === 'payment' && (Object.keys(values.paymentMethods).length === 0 || Object.keys(values.paymentMethods).length !== values?.tenantIds?.length)) {
      const paymentProvidersData = {}
      paymentProviders.map(
        ({ depositProviders, tenantId }) => (
          paymentProvidersData[tenantId] = depositProviders
        ))
      setFieldValue('paymentMethods', paymentProvidersData)
    }
  }, [paymentProviders, values?.tenantIds])

  return (
    providerLoading
      ? <InlineLoader />
      : (
        <div className='mt-4'>
          {paymentProviders &&
        paymentProviders?.map(
          ({ depositProviders, tenant, tenantId }) => (
            <Row key={tenantId} className='mb-2'>
              <h5>Portal:&nbsp;{tenant?.name} </h5>
              {depositProviders?.map(({ name, group, aggregator, paymentProviderId, isActive }, index) => (
                <Col sm={2} key={index} className='d-flex'>
                  <BForm.Check
                    name={`paymentMethods[${tenantId}]`}
                    type='checkbox'
                    onBlur={handleBlur}
                    checked={
                      (values?.paymentMethods &&
                      values?.paymentMethods?.[tenantId] &&
                      values?.paymentMethods?.[tenantId]?.filter(obj => obj.paymentProviderId === paymentProviderId)?.length) || false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue('paymentMethods', {
                          ...values.paymentMethods,
                          [tenantId]: [
                            ...(values?.paymentMethods?.[tenantId] || []),
                            { name, group, aggregator, paymentProviderId, isActive }
                          ]
                        })
                      } else if (!e.target.checked && values.paymentMethods[tenantId]) {
                        setFieldValue('paymentMethods', {
                          ...values.paymentMethods,
                          [tenantId]: values.paymentMethods?.[
                            tenantId
                          ].filter((provdr) => provdr.paymentProviderId !== paymentProviderId)
                        })
                      }
                    }}
                  />
                  <BForm.Label className='mx-2 text-nowrap'>
                    {name}
                  </BForm.Label>
                </Col>
              ))}
            </Row>
          )
        )}

          <div className='mt-4 d-flex justify-content-between align-items-center'>
            <Button
              variant='outline-warning'
              onClick={() => {
                bonusDetail?.claimedCount ? setSelectedTab('general') : setSelectedTab('currency')
              }}
              className='ml-2'
            >
              Previous
            </Button>
            <Button
              variant='outline-success'
              onClick={() => {
                const isEmpty = !Object.values(values?.paymentMethods).every(x => x?.length > 0)
                if (isEmpty) toast('Select At least one Payment Method for each Portal', 'error')
                else handleSubmit()
              }}
              className='ml-2'
            >
              Next
            </Button>
          </div>
        </div>)
  )
}
export default PaymentMethod
