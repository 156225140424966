import { createSlice } from '@reduxjs/toolkit'

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    loading: false,
    providerLoading: false,
    paymentAggregators: {},
    paymentProviders: [],
    providerDetails: {},
    detailsLoading: false,
    paymentCategories: [],
    paymentCategoriesLoading: false,
    transactionPaymentCategories: null,
    transactionReportingPaymentCategories: null
  },
  reducers: {
    getPaymentAggregatorsStart: (state) => ({
      ...state,
      loading: true
    }),
    getPaymentAggregatorsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentAggregators: payload
    }),
    getPaymentAggregatorsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getPaymentProviderStart: (state) => ({
      ...state,
      providerLoading: true
    }),
    getPaymentProviderSuccess: (state, { payload }) => ({
      ...state,
      providerLoading: false,
      paymentProviders: payload
    }),
    getPaymentProviderFailure: (state) => ({
      ...state,
      providerLoading: false
    }),
    getProviderDetailsStart: (state) => ({
      ...state,
      detailsLoading: true
    }),
    getProviderDetailsSuccess: (state, { payload }) => ({
      ...state,
      detailsLoading: false,
      providerDetails: payload
    }),
    getProviderDetailsFailure: (state) => ({
      ...state,
      detailsLoading: false
    }),
    configProviderSettingsStart: (state) => ({
      ...state,
      loading: true
    }),
    configProviderSettingsComplete: (state) => ({
      ...state,
      loading: false
    }),
    updatePaymentProviderStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updatePaymentProviderStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    reOrderPaymentProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    reOrderPaymentProvidersComplete: (state) => ({
      ...state,
      loading: false
    }),
    getPaymentCategoriesStart: (state) => ({
      ...state,
      paymentCategoriesLoading: true
    }),
    getPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      paymentCategoriesLoading: false,
      paymentCategories: payload
    }),
    getPaymentCategoriesFailure: (state) => ({
      ...state,
      paymentCategoriesLoading: false
    }),
    reOrderPaymentCategoriesStart: (state) => ({
      ...state,
      paymentCategoriesLoading: true
    }),
    reOrderPaymentCategoriesComplete: (state) => ({
      ...state,
      paymentCategoriesLoading: false
    }),
    getTransactionPaymentCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTransactionPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactionPaymentCategories: payload
    }),
    getTransactionPaymentCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTransactionReportingPaymentCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTransactionReportingPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactionReportingPaymentCategories: payload
    }),
    getTransactionReportingPaymentCategoriesFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default paymentSlice.reducer

export const {
  getPaymentAggregatorsStart,
  getPaymentAggregatorsSuccess,
  getPaymentAggregatorsFailure,
  getPaymentProviderStart,
  getPaymentProviderSuccess,
  getPaymentProviderFailure,
  getProviderDetailsStart,
  getProviderDetailsSuccess,
  getProviderDetailsFailure,
  configProviderSettingsStart,
  configProviderSettingsComplete,
  updatePaymentProviderStatusStart,
  updatePaymentProviderStatusComplete,
  reOrderPaymentProvidersStart,
  reOrderPaymentProvidersComplete,
  getPaymentCategoriesStart,
  getPaymentCategoriesSuccess,
  getPaymentCategoriesFailure,
  reOrderPaymentCategoriesStart,
  reOrderPaymentCategoriesComplete,
  getTransactionPaymentCategoriesStart,
  getTransactionPaymentCategoriesSuccess,
  getTransactionPaymentCategoriesFailure,
  getTransactionReportingPaymentCategoriesStart,
  getTransactionReportingPaymentCategoriesSuccess,
  getTransactionReportingPaymentCategoriesFailure
} = paymentSlice.actions
