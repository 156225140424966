import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllClientsStart } from '../../../store/redux-slices/tenants'
import { permissionLabel } from '../../ProfilePage/constants'
import { getTenantCountriesStart, getCountriesStart } from '../../../store/redux-slices/fetchData'
import { getAffiliateCountryStart, getAffiliateStart } from '../../../store/redux-slices/affiliate'
import { getAgentDetailStart } from '../../../store/redux-slices/agent'

const useCreateAgents = (isTenant, isView, isEdit, isAffiliate) => {
  const { t } = useTranslation()
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const { affiliateData, countries: affiliateCountries } = useSelector((state) => state.affiliate)
  const { agentId } = useParams()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { adminDetails } = useSelector(state => state.admins)
  const [selectedAdminUserId, setSelectedAdminUserId] = useState('')
  const [allowedLanguages, setAllowedLanguages] = useState([
    {
      label: 'English',
      value: 'EN'
    }
  ])
  const clientData = [{
    adminUserId: adminDetails?.adminId,
    firstName: adminDetails?.AdminUser?.firstName,
    lastName: adminDetails?.AdminUser?.lastName
  }]
  const adminData = isAffiliate ? clientData : clientsFilterData?.rows
  const [langOptions, setLangOptions] = useState([])
  const { languages } = useSelector(state => state.languages)
  const { adminUserId } = useParams()
  const [type, setType] = useState('password')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [country, setCountry] = useState(null)
  useEffect(() => {
    const languageOptions = languages?.map((language) => { return { label: language.languageName, value: language.code.toUpperCase() } })
    setLangOptions(languageOptions)
  }, [languages])
  useEffect(() => {
    if (isAffiliate) {
      setCountry(affiliateCountries)
    } else if (isTenant) {
      setCountry(tenantCountries?.rows)
    } else {
      setCountry(countries)
    }
  }, [isTenant, countries, tenantCountries, affiliateCountries])

  useEffect(() => {
    if (isAffiliate) {
      dispatch(getAffiliateCountryStart({ isAffiliate }))
    } else {
      if (!isTenant) {
        dispatch(getAllClientsStart({
          search: '',
          limit: '',
          orderBy: 'email',
          sort: 'desc'
        }))
        dispatch(getCountriesStart({
          limit: '',
          name: '',
          pageNo: '',
          isActive: '',
          kycMethod: ''
        }))
      } else {
        dispatch(getTenantCountriesStart())
      }
    }

    if (isView || isEdit) dispatch(getAgentDetailStart({ agentId: agentId, isTenant: isTenant, isAffiliate }))
  }, [isAffiliate])

  useEffect(() => {
    if (!isAffiliate) {
      dispatch(getAffiliateStart({
        adminUserId: selectedAdminUserId,
        isTenant
      }))
    }
  }, [selectedAdminUserId])

  const handleAdminUserIdChange = (id) => {
    setSelectedAdminUserId(id)
  }

  return {
    navigate,
    adminUserId,
    type,
    setType,
    permissionLabel,
    clientsFilterData,
    handleAdminUserIdChange,
    allowedLanguages,
    setAllowedLanguages,
    langOptions,
    country,
    affiliateData,
    adminData,
    t
  }
}

export default useCreateAgents
