import React from 'react'
import { Button, Col, Form, Table, Row, ButtonGroup } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { associateTableHeaders } from '../constants'
import moment from 'moment'
import PaginationComponent from '../../../../components/Pagination'
import Trigger from '../../../../components/OverlayTrigger'
import UseAssociateReferral from '../hooks/useAssociateReferral'
import DisableReason from '../../UserDetails/components/DisableReason'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faArrowCircleLeft,
  faRedoAlt,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'

const AssociateReferrals = () => {
  const {
    setSearch,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    ReferredUserData,
    navigate,
    loading,
    t,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    setUserData,
    userData,
    updateUserStatus
  } = UseAssociateReferral()

  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className='cursor-pointer'
              onClick={() => navigate(-1)}
            />
            {t('associateReferral')}
          </h3>
        </Col>
      </Row>
      <div className='d-flex gap-2 justify-content-between flex-wrap align-items-center'>
        <div className='d-flex gap-2 align-items-center'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              name='search'
              type='search'
              placeholder={t('search')}
              size='sm'
              className='w-90 mx-auto'
              style={{ minWidth: '180px', maxWidth: '230px', width: 'auto' }}
              onChange={debounceFn}
            />
          </div>

          <Col className='d-flex p-3 float-end'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => setSearch('')}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {associateTableHeaders.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && ReferredUserData &&
            ReferredUserData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      {item?.username}
                    </td>
                    <td>
                      {item?.referralCode || t('na')}
                    </td>
                    <td>
                      {moment(item?.createdAt).format('MM-DD-YYYY HH:mm')}
                    </td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={!item?.isActive ? t('setStatusActive') : t('setStatusInactive')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant={item?.isActive ? 'outline-danger' : 'outline-success'}
                            onClick={() => {
                              setActiveInactiveModal(true)
                              setUserData(item)
                            }}
                          >
                            <FontAwesomeIcon icon={!item?.isActive ? faCheckSquare : faWindowClose} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )
          }
          {!loading && ReferredUserData?.count === 0 &&
            (
              <tr>
                <td
                  colSpan={5}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      {userData?.isActive
        ? <DisableReason
            show={
               isActiveInactivemodalVisible
            }
            handleClose={() => setActiveInactiveModal(false)}
            markUserStatusInactive={updateUserStatus}
            name={userData?.username || '-'}
          />
        : <ConfirmationModal
            show={isActiveInactivemodalVisible}
            setShow={setActiveInactiveModal}
            handleYes={() => {
              updateUserStatus()
              setActiveInactiveModal(false)
            }}
            active={!userData?.isActive}
            name={userData?.username || '-'}
          />}
      {!loading && ReferredUserData?.count !== 0 &&
        (
          <PaginationComponent
            page={ReferredUserData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={ReferredUserData?.count}
          />
        )}
    </div>
  )
}

export default AssociateReferrals
