import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { downloadFile } from '../../../utils/fileDownloader'
import { getLoginToken } from '../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { getBetslipDetailStart } from '../../../store/redux-slices/superAdminSportsBook'
const initalWalletState = {
  actionSource: '',
  search: '',
  actionType: '',
  categoryId: '',
  subCategoryId: '',
  providerId: '',
  transactionType: ''
}
const initialDate = [
  {
    from: '',
    to: '',
    key: 'selection'
  }
]
const useBetslipDetails = () => {
  const isTenant = !window.location.href.match(/super/g)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { betslipDetails, userTenantReportingData, loading } = useSelector((state) => state.superAdminSportsBook)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const totalPages = Math.ceil(betslipDetails?.count / limit)
  const totalPagesUser = Math.ceil(userTenantReportingData?.count / limit)
  const [state, setState] = useState(initialDate)
  const {
    casinoProviders,
    casinoCategories,
    subCategories
  } = useSelector((state) => state.tenantCasino)
  const { betslipId } = useParams()

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  useEffect(() => {
    dispatch(getBetslipDetailStart({ betslipId: betslipId, isTenant: isTenant }))
  }, [])

  const handleCsvDownloadCasino = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/bet-history-details?betslipId=${betslipId || ''}`)
  }
  const handleCsvDownloadUser = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/tenant/report/user-transaction?pageNo=${page || ''}&actionType=${formData.actionType || ''}&limit=${limit || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    formData,
    setFormData,
    totalPages,
    totalPagesUser,
    page,
    limit,
    setPage,
    betslipDetails,
    userTenantReportingData,
    setLimit,
    debounceFn,
    initalWalletState,
    state,
    setState,
    casinoCategories,
    subCategories,
    casinoProviders,
    navigate,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    handleCsvDownloadUser,
    t,
    loading
  }
}

export default useBetslipDetails
