import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllGroupsStart } from '../../../../store/redux-slices/adminUser'
import { permissionLabel } from '../../../../components/ProfilePage/constants'
import { getAdminDetailsStart, getAllAdminsStart, getSuperAdminDetailsStart } from '../../../../store/redux-slices/admins'

const useAdminUserForm = ({ group }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [type, setType] = useState('password')
  const { adminRole } = useSelector((state) => state.login)
  const { data, adminDetails, loading, superAdminUser } = useSelector((state) => state.admins)
  const { groups } = useSelector((state) => state.adminUser)
  const [groupOptions, setGroupOptions] = useState()
  const [selectedGroup, setSelectedGroup] = useState()

  useEffect(() => {
    dispatch(getAllGroupsStart({ isTenant: false }))
    if (group) {
      setSelectedGroup({ label: group, value: group })
    }
  }, [])

  useEffect(() => {
    if (groups.length > 0) {
      const options = groups.map((g) => (
        (g !== '' && g !== null) ? ({ label: g, value: g }) : undefined
      )
      )
      setGroupOptions(options)
    }
  }, [groups])

  const superAdminDetails = () => dispatch(getSuperAdminDetailsStart({ isTenant: false }))
  const getAllAdmins = ({
    limit,
    pageNo,
    sort,
    orderBy,
    search,
    superRoleId,
    superAdminId
  }) => dispatch(
    getAllAdminsStart({
      limit,
      pageNo,
      sort,
      orderBy,
      search,
      superRoleId,
      superAdminId
    })
  )

  const getAdminDetails = ({ adminId }) => dispatch(getAdminDetailsStart({ adminId }))

  return {
    navigate,
    dispatch,
    adminRole,
    data,
    adminDetails,
    permissionLabel,
    superAdminDetails,
    getAllAdmins,
    getAdminDetails,
    loading,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    superAdminUser
  }
}

export default useAdminUserForm
