import React from 'react'
import useCmsDetails from '../hooks/useCmsDetails'
import Preloader from '../../../../components/Preloader'
import CreateCms from './CreateCms'
import { useLocation } from 'react-router-dom'

const CMSDetail = () => {
  const { loading } = useCmsDetails()
  const location = useLocation()
  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <CreateCms cmsData={location.state.data} details />
          </>
          )}
    </>
  )
}

export default CMSDetail
