import React from 'react'
import useCustomPayment from './hooks/useCustomPayment'
import Preloader from '../../../components/Preloader'
import { Button, Col, Form, Row } from '@themesberg/react-bootstrap'
import ConfirmationModal from '../../../components/ConfirmationModal'
import PaymentProvidersList from '../../../components/PaymentProvidersList'
import { providerCategory } from './components/constants'
import useCheckPermission from '../../../utils/checkPermission'
import CategoryReorder from './components/CategoryReorder'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMoneyCheckDollarPen } from '@fortawesome/free-solid-svg-icons'

export default () => {
  const {
    loading,
    navigate,
    handleShowModal,
    handleYes,
    showModal,
    setShowModal,
    name,
    active,
    data,
    type,
    setType,
    status,
    setStatus,
    search,
    setSearch,
    category,
    setCategory,
    reorderModal,
    setReorderModal
  } = useCustomPayment()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row className='mb-3'>
        <Col xs={2}>
          {/* <h3><FontAwesomeIcon icon={faMoneyCheckDollarPen} />&nbsp;Cashier Management</h3> */}
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          <label className='m-1' style={{ width: '110px' }}>Select Type:</label>
          <Form.Select
            size='sm'
            style={{ maxWidth: '120px' }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value=''>All</option>
            <option value='deposit'>Deposit</option>
            <option value='withdraw'>Withdraw</option>
            <option value='both'>Both</option>
          </Form.Select>

          <label className='m-1'>Category:</label>
          <Form.Select
            size='sm'
            style={{ maxWidth: '145px' }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value=''>All</option>
            {Object.keys(providerCategory).map(categoryKey =>
              <option value={categoryKey} key={categoryKey}>{providerCategory[categoryKey]}</option>)}
          </Form.Select>

          <label className='m-1'>Status:</label>
          <Form.Select
            size='sm'
            style={{ maxWidth: '120px' }}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value=''>All</option>
            <option value='true'>Active</option>
            <option value='false'>In-Active</option>
          </Form.Select>

          <label className='m-1'>Search:</label>
          <Form.Control
            type='search'
            placeholder='Search Provider'
            size='sm'
            value={search}
            style={{ maxWidth: '230px' }}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            size='sm'
            style={{ width: '135px' }}
            className='m-1'
            variant='outline-success'
            hidden={isHidden({ module: { key: 'CashierManagement', value: 'U' } })}
            onClick={() => setReorderModal(true)}
          >Category Reorder
          </Button>
        </Col>
      </Row>
      {loading
        ? <Preloader />
        : (
          <PaymentProvidersList
            data={data}
            handleShowModal={handleShowModal}
            navigate={navigate}
          />
          )}

      {showModal &&
        <ConfirmationModal
          setShow={setShowModal}
          show={showModal}
          handleYes={handleYes}
          active={active}
          name={name}
        />}

      {reorderModal &&
        <CategoryReorder
          setShow={setReorderModal}
          show={reorderModal}
        />}

    </>
  )
}
