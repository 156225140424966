import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserLotteryTicketsStart } from '../../../store/redux-slices/bonus'
const initialDate = {
  from: '',
  to: ''
}
const useUniqueTickets = ({ isTenant }) => {
  const [date, setDate] = useState(initialDate)
  const navigate = useNavigate()
  const { userLotteryTickets, loading } = useSelector(state => state.bonus)
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [actionType, setActionType] = useState('')
  const [pageNo, setPageNo] = useState(1)
  const { userId } = useParams()
  const from = date.from ? new Date(date.from).toISOString() : ''
  const to = date.to ? new Date(date.to).toISOString() : ''
  useEffect(() => {
    dispatch(getUserLotteryTicketsStart({ limit, pageNo, userId, from, to, actionType, isTenant }))
  }, [limit, pageNo, userId, from, to, actionType])

  return {
    userLotteryTickets,
    loading,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    navigate,
    date,
    setDate,
    actionType,
    setActionType
  }
}

export default useUniqueTickets
