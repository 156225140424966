// eslint-disable
import React, { useEffect, useState } from 'react'
import {
  Col, Button, Form as BForm, Row, Table, Spinner
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import useHotCombo from './hooks/useHotCombo'
import useCreateHotCombo from './hooks/useCreateHotCombo'
import { outcomeHeaders } from './constants'
import { addHotComboSchema } from './schema'
import { getMarketName, outcomeTypeFormatter } from '../../utils/outcomeFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from '../Toast'
import CustomAsyncSelect from '../AsyncSelect'

const CreateHotCombo = ({ isEdit, handleEditSelectedOutcomes, isEditSelectedCheck, editSelectedMarkets, setEditMarketId }) => {
  const { t } = useHotCombo()
  const {
    sportsListing, sportsCountriesListing, navigate, leaguesListing, loading, matchMarkets, setCountry, setSport, setTournament, setMarketId, setMatchId, marketOutcomes, selectedOutcomes,
    setSelectedOutcomes,
    addCombo,
    setStartDate,
    resetForm,
    addComboLoading,
    selectedMarkets,
    marketId: apiMarketId,
    setSelectedMarkets,
    resetForCountry,
    resetForSports,
    resetForLeagues,
    resetForMatch,
    resetForMarket,
    customAsyncDebounce,
    selectedMatch,
    setSelectedMatch,
    defaultOptions
  } = useCreateHotCombo()
  const [formatedOutcomes, setFormattedOutcomes] = useState([])
  const [listOfMarkets, setListOfMarkets] = useState([])
  useEffect(() => {
    let tempFormatedOutcomes = []
    marketOutcomes?.matchMarket?.forEach((item) => {
      const team1 = marketOutcomes?.teams?.find((item) => item?.type === 'home')
      const team2 = marketOutcomes?.teams?.find((item) => item?.type === 'away')
      const outcomeWithMarket = item?.outcome?.map((outcome) => ({ ...outcome, ...item?.MasterMarkets?.[0], team1, team2, matchMarketId: item?.matchMarketId }))
      tempFormatedOutcomes = [...tempFormatedOutcomes, ...outcomeWithMarket]
    })
    setFormattedOutcomes(tempFormatedOutcomes)
  }, [marketOutcomes])

  const isChecked = (id, marketId) => {
    return (Number(selectedOutcomes?.[marketId]) === Number(id))
  }
  const handleSelectedOutcomes = (e, item, marketId, wholeMarketDetail) => {
    if (!isChecked(e.target.value, marketId)) {
      setSelectedMarkets(apiMarketId)
      setSelectedOutcomes(prev => {
        if (Object.entries(prev)?.length < 4) {
          setStartDate((prevDate) => {
            const currentTime = new Date(marketOutcomes?.matchDate)?.getTime()
            const prevTime = new Date(prevDate || new Date()?.getDate() + 30)?.getTime()
            if (prevTime > currentTime) {
              return marketOutcomes?.matchDate
            } else {
              return prevDate || marketOutcomes?.matchDate
            }
          })
          const tempList = [...listOfMarkets]?.filter(item => item?.matchMarketId !== marketId)
          setListOfMarkets([...tempList, wholeMarketDetail])
          return { ...prev, [marketId]: item }
        } else {
          toast(t('outcomeError'), 'error')
          return { ...prev }
        }
      })
      const tempList = [...listOfMarkets]?.filter(item => item?.matchMarketId !== marketId)
      setListOfMarkets([...tempList, wholeMarketDetail])
    } else {
      setSelectedMarkets(selectedMarkets?.filter((item) => item !== apiMarketId))
      const prevOutcomes = { ...selectedOutcomes }
      delete prevOutcomes[marketId]
      setSelectedOutcomes(prevOutcomes)
      setListOfMarkets(prev => prev?.filter(item => item?.matchMarketId !== marketId))
    }
  }

  return (
    <div className='p-3'>
      {!isEdit && (
        <Col className='mb-4'>
          <h3> <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className='cursor-pointer me-2'
            onClick={() => {
              resetForm()
              navigate(-1)
              setListOfMarkets([])
            }}
               />
            {t('createHotCombo')}
          </h3>
        </Col>
      )}

      <Formik
        enableReinitialize
        initialValues={
          {
            sport: '',
            country: '',
            name: '',
            match: '',
            market: '',
            tournament: ''
          }
        }
        onSubmit={(formValues, formActions) => addCombo(formValues, formActions)}
        validationSchema={addHotComboSchema(t)}

      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset,
          setFieldError
        }) => {
          return (
            <Form>
              <Row>
                {!isEdit && (
                  <>
                    <Col sm={6} className='mb-2'>
                      <div className='d-flex flex-column gap-2'>
                        <div>
                          <BForm.Label>
                            {t('nameOfHotcombo')}<span className='text-danger'> *</span>
                          </BForm.Label>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Control
                              type='text'
                              name='name'
                              placeholder={t('enterName')}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <ErrorMessage
                              component='div'
                              name='name'
                              className='text-danger'
                            />
                          </div>
                        </div>

                      </div>
                    </Col>

                  </>
                )}
                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('sport')}
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='sport'
                          value={values?.sport}
                          onChange={(e) => {
                            const { masterSportId, providerSportId } = sportsListing?.rows?.find(({ masterSportId }) => masterSportId === e.target.value)
                            handleChange(e)
                            setSport({ masterSportId, providerSportId })
                            setCountry('')
                            setTournament('')
                            setMarketId('')
                            setMatchId('')
                            setFieldValue('country', '')
                            setFieldValue('match', '')
                            setFieldValue('market', '')
                            setFieldValue('tournament', '')
                            resetForSports()
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('all')}</option>

                          {sportsListing?.rows?.map(({
                            masterSportId,
                            sportName
                          }) =>
                            (
                              <option
                                key={masterSportId}
                                value={masterSportId}
                              >
                                {sportName}
                              </option>
                            )
                          )}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='sport'
                          className='text-danger'
                        />
                      </div>
                    </div>

                  </div>
                </Col>

                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('country')}
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='country'
                          value={values?.country}
                          onChange={(e) => {
                            handleChange(e)
                            setCountry(e.target.value)
                            setTournament('')
                            setMarketId('')
                            setMatchId('')
                            setFieldValue('match', '')
                            setFieldValue('market', '')
                            setFieldValue('tournament', '')
                            resetForCountry()
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('selectCountry')}</option>

                          {sportsCountriesListing?.rows?.map((item, idx) => {
                            return (
                              <option
                                key={`countryNames2_${idx}`}
                                value={item.countryName}
                              >
                                {item.countryName}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='country'
                          className='text-danger'
                        />
                      </div>
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('league')}
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='tournament'
                          value={values?.tournament}
                          onChange={(e) => {
                            handleChange(e)
                            setTournament(e.target.value)
                            setMarketId('')
                            setMatchId('')
                            setFieldValue('match', '')
                            setFieldValue('market', '')
                            resetForLeagues()
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('selectLeague')}</option>

                          {leaguesListing?.rows?.map((item, idx) => {
                            return (
                              <option
                                key={`countryNames2_${idx}`}
                                value={item.providerTournamentId}
                              >
                                {item.tournamentName}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='tournament'
                          className='text-danger'
                        />
                      </div>
                    </div>

                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-2 match-select-wrapper'>
                    <div>
                      <BForm.Label>
                        {t('selectMatch')}
                      </BForm.Label>
                      <CustomAsyncSelect
                        height='45px'
                        onChange={(option, e) => {
                          setFieldValue('match', option.masterMatchId)
                          setSelectedMatch(option)
                          setMatchId(option.masterMatchId)
                          setMarketId('')
                          setFieldValue('market', '')
                          resetForMatch()
                        }}
                        placeholder={`${t('search')} & ${t('selectMatch')}`}
                        value={selectedMatch || ''}
                        cacheOptions
                        loadOptions={customAsyncDebounce}
                        defaultOptions={defaultOptions}
                      />
                      <ErrorMessage
                        component='div'
                        name='match'
                        className='text-danger'
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={6} className='mb-2'>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('market')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='market'
                          disabled={apiMarketId ? isEdit ? editSelectedMarkets?.includes(apiMarketId) : selectedMarkets?.includes(apiMarketId) : false}
                          value={values?.market}
                          onChange={(e) => {
                            handleChange(e)
                            setMarketId(e.target.value)
                            if (isEdit) {
                              setEditMarketId(e.target.value)
                            }
                            resetForMarket()
                          }}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('selectMarket')}</option>

                          {matchMarkets?.matchMarket?.map((item, idx) => {
                            return (
                              <option
                                key={`countryNames2_${idx}`}
                                value={item.masterMarketId}
                              >
                                {getMarketName(item, matchMarkets?.teams)}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='market'
                          className='text-danger'
                        />
                      </div>
                    </div>

                  </div>
                </Col>
              </Row>

              <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
                <thead className='thead-dark'>
                  <tr>
                    {outcomeHeaders.map((h, idx) => (
                      <th key={idx}>
                        {t(h.label)}{' '}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody id={loading ? 'cover-spin' : ''}>
                  {(!loading && formatedOutcomes?.length > 0) &&
                    formatedOutcomes?.map(
                      (item, index) => {
                        const selection = outcomeTypeFormatter({
                          outcome: item,
                          homeTeamName: item?.team1?.team.teamName,
                          awayTeamName: item?.team2?.team.teamName,
                          providerMarketId: item?.providerMarketId,
                          feedProvider: 1,
                          language: 'en',
                          subTypeId: ''
                        })
                        return (
                          <tr key={item?.masterOutcomeId}>
                            <td className='py-2'>

                              <div className='text-center d-flex align-items-center justify-content-center '>

                                <label className='me-2 d-inline-flex' htmlFor={item?.masterOutcomeId}>
                                  <input
                                    onChange={(e) =>
                                      isEdit ? handleEditSelectedOutcomes(e, item?.masterOutcomeId, item?.matchMarketId, item) : handleSelectedOutcomes(e, item?.masterOutcomeId, item?.matchMarketId, item)}
                                    style={{ width: 20, height: 20 }}
                                    checked={isEdit ? !!isEditSelectedCheck(item?.masterOutcomeId, item?.matchMarketId) : !!isChecked(item?.masterOutcomeId, item?.matchMarketId)}
                                    value={item?.masterOutcomeId}
                                    type='checkbox'
                                    id={item?.masterOutcomeId}
                                  />
                                </label>
                                {(index + 1)}
                              </div>

                            </td>
                            <td className='py-2'>{item?.team1?.team.teamName} </td>
                            <td className='py-2'>{item?.team2?.team.teamName}</td>
                            <td className='py-2'>{selection} </td>
                            <td className='py-2'>{item?.odd} </td>

                          </tr>
                        )
                      }
                    )}

                </tbody>
              </Table>
              {(!loading && formatedOutcomes?.length === 0) && (
                <div className='text-center text-danger w-100'>
                  {t('noDataFound')}
                </div>
              )}
              {!isEdit && (
                <div className='mt-4 '>
                  <Button
                    type='submit'
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                    disabled={addComboLoading}
                    style={{ minWidth: 100 }}
                  >
                    {addComboLoading
                      ? (
                        <Spinner className='ms-2' size='sm' animation='border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                        )
                      : t('submit')}
                  </Button>
                  <Button
                    type='reset'
                    variant='outline-danger'
                    onClick={() => {
                      resetForm()
                      handleReset()
                      setListOfMarkets([])
                    }}
                    className='ms-2'
                    style={{ minWidth: 100 }}
                  >
                    {t('reset')}
                  </Button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>

      {!isEdit && listOfMarkets?.length > 0 && (
        <>
          <h6 className='py-3 mb-0 pt-4'>{t('comboEventsList')}</h6>
          <Table bordered striped responsive hover size='sm' className='text-center '>
            {/* <thead className='thead-dark'>
              <tr>
                {outcomeHeaders.map((h, idx) => (
                  <th key={idx}>
                    {t(h.label)}{' '}
                  </th>
                ))}
              </tr>
            </thead> */}

            <tbody>
              {(listOfMarkets?.length > 0) &&
                listOfMarkets?.map(
                  (item, index) => {
                    const selection = outcomeTypeFormatter({
                      outcome: item,
                      homeTeamName: item?.team1?.team.teamName,
                      awayTeamName: item?.team2?.team.teamName,
                      providerMarketId: item?.providerMarketId,
                      feedProvider: 1,
                      language: 'en',
                      subTypeId: ''
                    })
                    return (
                      <tr key={item?.masterOutcomeId}>
                        <td className='py-2'>

                          <div className='text-center d-flex align-items-center justify-content-center '>
                            {(index + 1)}
                          </div>

                        </td>
                        <td className='py-2'>{item?.team1?.team.teamName} </td>
                        <td className='py-2'>{item?.team2?.team.teamName}</td>
                        <td className='py-2'>{selection} </td>
                        <td className='py-2'>{item?.odd} </td>

                      </tr>
                    )
                  }
                )}

            </tbody>

          </Table>
        </>
      )}
    </div>
  )
}

export default CreateHotCombo
