import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import {
  useDispatch,
  useSelector
} from 'react-redux'
import { getAllRollTransactionsDataStart } from '../../../../store/redux-slices/admins'
import { debounce } from 'lodash'
import moment from 'moment'
import { getLoginToken } from '../../../../utils/storageUtils'
import { downloadFile } from '../../../../utils/fileDownloader'
import { useTranslation } from 'react-i18next'

const initalState = {
  search: '',
  senior: ''
}

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]

const useRollTransactions = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [formData, setFormData] = useState({ ...initalState, limit, pageNo: page })
  const { rollTransactions, loading } = useSelector(state => state.admins)
  const totalPages = Math.ceil(rollTransactions?.count / limit)
  const [state, setState] = useState(initialDate)
  const inputRef = useRef()
  const rollType = ['ALL', 'DEPOSIT-ADDON', 'BONUS-ADDON', 'PROMOTION-ADDON']

  useEffect(() => {
    dispatch(getAllRollTransactionsDataStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        tenantId: selectedClient,
        from: moment(state[0].startDate).toISOString(),
        to: moment(state[0].endDate).toISOString()
      }
    }))
  }, [formData, limit, page, selectedClient, state])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }
  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/roll-transactions?tenantId=${selectedClient || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }

  return {
    handleCsvDownload,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    rollType,
    debounceFn,
    initalState,
    selectedClient,
    setSelectedClient,
    rollTransactions,
    inputRef,
    state,
    setState,
    initialDate,
    loading,
    t
  }
}
export default useRollTransactions
