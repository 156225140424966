import {
  useCallback,
  useEffect,
  useState
} from 'react'
// import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getAllReferredUserlStart } from '../../../../store/redux-slices/referrals'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { updateSAUserStatusStart } from '../../../../store/redux-slices/superAdminSettings'

const UseAssociateReferral = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isActiveInactivemodalVisible, setActiveInactiveModal] = useState(false)
  const [userData, setUserData] = useState({})
  const {
    ReferredUserData,
    loading
  } = useSelector((state) => state.referral)
  const totalPages = Math.ceil(ReferredUserData?.count / limit)
  const { userId } = useParams()

  useEffect(() => {
    dispatch(getAllReferredUserlStart({ userId, search, limit, pageNo: page }))
  }, [userId, search, limit, page])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const updateUserStatus = (res) => {
    dispatch(updateSAUserStatusStart({
      data: {
        ...res,
        code: 'USER',
        userId: userData.userId,
        status: !userData.isActive,
        tenantId: location.state.data.tenantId,
        isReferral: true,
        referralUser: location.state.data.userId
      }
    }))
  }
  return {
    search,
    setSearch,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    ReferredUserData,
    navigate,
    loading,
    t,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    userData,
    setUserData,
    updateUserStatus
  }
}

export default UseAssociateReferral
