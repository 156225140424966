import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
import {
  getSportsListingStart,
  getSportsListingSuccess,
  getSportsListingFailure,
  getSportsCountriesListingStart,
  getSportsCountriesListingSuccess,
  getSportsCountriesListingFailure,
  getMatchListingStart,
  getMatchListingSuccess,
  getMatchListingFailure,
  getMarketListingStart,
  getMarketsListingSuccess,
  getMarketsListingFailure,
  getLeaguesListingStart,
  getLeaguesListingSuccess,
  getLeaguesListingFailure,
  updateSportsBookActionSAStart,
  updateSportsBookActionSASuccess,
  updateSportsBookActionSAFailure,
  getBetslipDetailStart,
  getBetslipDetailSuccess,
  getBetslipDetailFailure,
  addTopStart,
  addTopSuccess,
  addTopFailure,
  sportsCountriesFailure,
  sportsCountriesSuccess,
  sportsCountriesStart,
  reorderLeaguesStart,
  reorderLeaguesSuccess,
  reorderLeaguesFailure,
  reorderSportsStart,
  reorderSportsSuccess,
  reorderSportsFailure,
  getTopTournamentStart,
  getTopTournamentSuccess,
  getTopTournamentFailure,
  getMarketOutcomeStart,
  getMarketOutcomeSuccess,
  getMarketOutcomeFailure,
  getHotComboStart,
  getHotComboSuccess,
  getHotComboFailure,
  addHotComboStart,
  addHotComboSuccess,
  addHotComboFailure,
  getMatchMarketStart,
  getMatchMarketSuccess,
  getMatchMarketFailure,
  getSportsListingReorderStart,
  getSportsListingReorderSuccess,
  getSportsListingReorderFailure,
  deleteHotComboStart,
  deleteHotComboComplete,
  updateHotComboStart,
  updateHotComboComplete,
  getTeamListingStart,
  getTeamListingSuccess,
  getTeamListingFailure,
  updateTeamImageStart,
  updateTeamImageComplete
} from '../redux-slices/superAdminSportsBook'
import {
  getSports,
  getSportsCountries,
  getMatches,
  getMarkets,
  getLeagues,
  updateActionSportsBook,
  getBetslipDetails,
  addTop,
  getAllSportsCountries,
  reorderLeagues,
  getTopTournaments,
  getMarketOutcomes,
  getHotCombos,
  addHotCombo,
  getMatchMarkets,
  getTopSports,
  updateSportsReordering,
  deleteHotCombo,
  updateHotComboBets,
  getTeamListing,
  updateTeamImage
} from '../../utils/apiCalls'
import { t } from 'i18next'
import { serialize } from 'object-to-formdata'

export default function * superAdminSportsBookWatcher () {
  yield takeLatest(getSportsListingStart.type, getSportsListingWorker)
  yield takeLatest(getSportsCountriesListingStart.type, getSportsCountriesListingWorker)
  yield takeLatest(getMatchListingStart.type, getMatchListingWorker)
  yield takeLatest(getMarketListingStart.type, getMarketListingWorker)
  yield takeLatest(getLeaguesListingStart.type, getLeaguesListingWorker)
  yield takeLatest(updateSportsBookActionSAStart.type, updateSportsBookActionSAWorker)
  yield takeLatest(getBetslipDetailStart.type, getBetslipDetailWorker)
  yield takeLatest(addTopStart.type, addTopWorker)
  yield takeLatest(sportsCountriesStart.type, getSportsCountriesWorker)
  yield takeLatest(reorderLeaguesStart.type, reorderLeaguesWorker)
  yield takeLatest(getSportsListingReorderStart.type, getTopSportsDataWorker)
  yield takeLatest(reorderSportsStart.type, reorderSportsWorker)
  yield takeLatest(getTopTournamentStart.type, getTopTournamentWorker)
  yield takeLatest(getMarketOutcomeStart.type, getMarketOutcomesWorker)
  yield takeLatest(getHotComboStart.type, getHotComboWorker)
  yield takeLatest(addHotComboStart.type, addHotComboWorker)
  yield takeLatest(deleteHotComboStart.type, deleteHotComboWorker)
  yield takeLatest(updateHotComboStart.type, updateHotComboWorker)
  yield takeLatest(getMatchMarketStart.type, getMatchMarketWorker)
  yield takeLatest(getTeamListingStart.type, getTeamListingWorker)
  yield takeLatest(updateTeamImageStart.type, updateTeamImageWorker)
}

function * getTeamListingWorker (action) {
  try {
    const { data } = yield getTeamListing(action.payload)
    yield put(getTeamListingSuccess(data?.data))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getTeamListingFailure(e.response.data.message))
  }
}

function * updateTeamImageWorker (action) {
  try {
    const { onSuccess, paramsData } = action.payload
    const transformData = serialize(paramsData)
    yield updateTeamImage({ ...action.payload, paramsData: transformData })
    yield put(updateTeamImageComplete())
    yield onSuccess()
    yield toast(t('updateSuccess'), 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(updateTeamImageComplete(e.response.data.message))
  }
}
function * deleteHotComboWorker (action) {
  try {
    const { onSuccess } = action.payload
    yield deleteHotCombo(action.payload)
    yield put(deleteHotComboComplete())
    yield onSuccess()
    yield toast(t('deleteSuccess'), 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(deleteHotComboComplete(e.response.data.message))
  }
}
function * updateHotComboWorker (action) {
  try {
    const { onSuccess } = action.payload
    yield updateHotComboBets(action.payload)
    yield put(updateHotComboComplete())
    yield onSuccess()
    yield toast(t('updateSuccess'), 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(updateHotComboComplete())
  }
}
function * getSportsListingWorker (action) {
  try {
    const { data } = yield getSports(action.payload)
    yield put(getSportsListingSuccess(data?.data?.masterSports || data?.data?.categorySports))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getSportsListingFailure(e.response.data.message))
  }
}

function * getTopTournamentWorker (action) {
  try {
    const { data } = yield getTopTournaments(action.payload)
    yield put(getTopTournamentSuccess(data?.data?.masterTournaments))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(addHotComboFailure(e.response.data.message))
  }
}

function * getMatchMarketWorker (action) {
  try {
    const { data } = yield getMatchMarkets(action.payload)
    yield put(getMatchMarketSuccess(data?.data?.matchMarketDetails))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getMatchMarketFailure(e.response.data.message))
  }
}

function * addHotComboWorker (action) {
  const { paramsData, onSuccess, isTenant } = action.payload
  try {
    const { data } = yield addHotCombo(paramsData, isTenant)
    yield put(addHotComboSuccess(data?.data?.masterTournaments))
    onSuccess()
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getTopTournamentFailure(e.response.data.message))
  }
}

function * getMarketOutcomesWorker (action) {
  try {
    const { data } = yield getMarketOutcomes(action.payload)
    yield put(getMarketOutcomeSuccess(data?.data?.matchDetail))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getMarketOutcomeFailure(e.response.data.message))
  }
}

function * getHotComboWorker (action) {
  try {
    const { data } = yield getHotCombos(action.payload)
    yield put(getHotComboSuccess(data?.data))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getHotComboFailure(e.response.data.message))
  }
}

function * getSportsCountriesListingWorker (action) {
  try {
    const { paramsData } = action && action.payload

    const { data } = yield getSportsCountries(paramsData)
    yield put(getSportsCountriesListingSuccess(data?.data?.masterCountries))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getSportsCountriesListingFailure(e.response.data.message))
  }
}

function * getMatchListingWorker (action) {
  try {
    const { data } = yield getMatches(action.payload)
    yield put(getMatchListingSuccess(data?.data?.result || data?.data?.categoryMatches))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getMatchListingFailure(e.response.data.message))
  }
}

function * getMarketListingWorker (action) {
  try {
    const { paramsData } = action && action.payload

    const { data } = yield getMarkets(paramsData)
    yield put(getMarketsListingSuccess(data?.data?.masterMarkets))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getMarketsListingFailure(e.response.data.message))
  }
}

function * getLeaguesListingWorker (action) {
  try {
    const { data } = yield getLeagues(
      action.payload
    )
    yield put(getLeaguesListingSuccess(data?.data?.masterTournaments || data?.data?.categoryTournaments))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getLeaguesListingFailure(e.response.data.message))
  }
}

function * updateSportsBookActionSAWorker (action) {
  try {
    const {
      updateData,
      setIsStatusModalOpen,
      fetchData
    } = action && action.payload

    const { data } = yield updateActionSportsBook(
      updateData
    )
    setIsStatusModalOpen(false)
    yield put(updateSportsBookActionSASuccess(data?.data?.message))
    yield toast(data?.data?.message.toLowerCase(), 'success')
    switch (updateData.entityType) {
      case 'sport':
        yield put(fetchData())
        break

      case 'country':
        yield put(fetchData())
        break
      case 'match':
        yield put(fetchData())
        break
      case 'market':
        yield put(fetchData())
        break
      case 'tournament':
        yield put(fetchData())
        break
      default:return
    }
  } catch (e) {
    yield toast(e?.response?.data?.message, 'error')

    yield put(updateSportsBookActionSAFailure(e?.response?.data?.message))
  }
}

function * getBetslipDetailWorker (action) {
  try {
    const { data } = yield getBetslipDetails(action.payload)
    yield put(getBetslipDetailSuccess(data?.data?.sbBetHistoryDetailsReport))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getBetslipDetailFailure(e.response.data.message))
  }
}

function * addTopWorker (action) {
  const { isTenant, formData, fetchData } = action?.payload
  try {
    const { data } = yield addTop({ isTenant, formData })
    yield put(addTopSuccess())
    fetchData()
    yield toast(data.data.message, 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(addTopFailure(e.response.data.message))
  }
}

function * reorderLeaguesWorker (action) {
  try {
    const { data } = yield reorderLeagues(action.payload)
    yield put(reorderLeaguesSuccess())
    yield toast(data.data.message, 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(reorderLeaguesFailure(e.response.data.message))
  }
}

function * reorderSportsWorker (action) {
  try {
    const { data } = yield updateSportsReordering(action.payload)
    yield put(reorderSportsSuccess())
    yield toast(data.data.message, 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(reorderSportsFailure(e.response.data.message))
  }
}

function * getTopSportsDataWorker (action) {
  try {
    const { data } = yield getTopSports(action.payload)
    yield put(getSportsListingReorderSuccess(data?.data?.sports))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(getSportsListingReorderFailure(e.response.data.message))
  }
}

function * getSportsCountriesWorker (action) {
  try {
    const { data } = yield getAllSportsCountries(action.payload)
    yield put(sportsCountriesSuccess(data?.data?.countries?.rows))
  } catch (e) {
    yield toast(e.response.data.message, 'error')
    yield put(sportsCountriesFailure(e.response.data.message))
  }
}
