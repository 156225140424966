import { createSlice } from '@reduxjs/toolkit'

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: {
    loading: false,
    error: null,
    success: false,
    allCurrencies: null,
    currency: null,
    tenantCurrencies: {}
  },
  reducers: {
    getAllCurrenciesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCurrenciesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allCurrencies: payload
    }),
    getAllCurrenciesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllTenantCurrenciesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantCurrenciesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allTenantCurrencies: payload
    }),
    getAllTenantCurrenciesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createCurrenciesStart: (state) => ({
      ...state,
      loading: true
    }),
    createCurrenciesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allCurrencies: payload
    }),
    createCurrenciesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    editCurrencyStart: (state) => ({
      ...state,
      loading: true
    }),
    editCurrencySuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    editCurrencyFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getCurrencyByIdStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getCurrencyByIdSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      currency: payload
    }),
    getCurrencyByIdFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getTenantCurrenciesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantCurrenciesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantCurrencies: payload
    }),
    getTenantCurrenciesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  }
})

export default currenciesSlice.reducer

export const {
  getAllCurrenciesStart,
  getAllCurrenciesSuccess,
  getAllCurrenciesFailure,
  createCurrenciesStart,
  createCurrenciesSuccess,
  createCurrenciesFailure,
  editCurrencyStart,
  editCurrencySuccess,
  editCurrencyFailure,
  getCurrencyByIdStart,
  getCurrencyByIdSuccess,
  getCurrencyByIdFailure,
  getAllTenantCurrenciesStart,
  getAllTenantCurrenciesSuccess,
  getAllTenantCurrenciesFailure,
  getTenantCurrenciesStart,
  getTenantCurrenciesSuccess,
  getTenantCurrenciesFailure
} = currenciesSlice.actions
