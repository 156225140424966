import React from 'react'
import { Accordion, Button, Col, Row, Table, Form as BForm } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Preloader from '../../../components/Preloader'
import EditUploadBanner from './EditUploadBanner'
import useBannerManagement from './useBannerManagement'
import { DeleteConfirmationModal } from '../../../components/ConfirmationModal'
import TableRow from './TableRow'
import useIsGamma from '../../../common/hook/useIsGamma'
import PaginationComponent from '../../../components/Pagination'
// import PaginationComponent from '../../../components/Pagination'

export default () => {
  const {
    isGamma
  } = useIsGamma()
  const {
    loading,
    setSelectedClient,
    selectedClient,
    setSelectedPortal,
    handleCreateEdit,
    handleDelete,
    type,
    data,
    setShow,
    show,
    createUpdate,
    clientsFilterData,
    dispatch,
    SABanners,
    isHidden,
    validation,
    setValidation,
    bannerTypes,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    t,
    limit,
    setLimit,
    page,
    setPage,
    lang,
    setLang,
    languages,
    totalPages,
    updateLoading,
    deleteLoading
  } = useBannerManagement()

  return (
    <>
      <Row className='mb-4'>
        <Col>
          <h3>{t('bannerManagement')}</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
            {
            !isGamma &&
            (
              <Button
                variant='outline-success'
                size='sm'
                onClick={() => handleCreateEdit('Create', {})}
                hidden={isHidden({ module: { key: 'BannerManagement', value: 'C' } })}
              >
                {t('upload')}
              </Button>
            )
           }
          </div>
        </Col>
      </Row>

      <Accordion>
        {loading
          ? <Preloader />
          : SABanners?.rows?.map((item, i) => {
            return (
              <Accordion.Item
                key={`banner-portal-listing-${i}`}
                eventKey={String(i)}
              >
                <Accordion.Header>{item?.['tenant.name']} ({item?.['tenant.domain']})</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped responsive hover size='sm' className='text-center mt-2'>
                    <thead className='thead-dark'>
                      <tr>
                        {[t('pages'), t('bannerPreview')].map((h) => (
                          <th key={h}>{h}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <div className='d-flex justify-content-end gap-2 align-items-center'>
                            <div className=''>
                              <BForm.Label>{t('selectLanguage')}</BForm.Label>
                            </div>
                            <div>
                              <BForm.Select
                                name='languageCode'
                                value={lang}
                                onChange={(e) => {
                                  setLang(e.target.value)
                                }}
                              >
                                {languages && languages?.filter(item => item.isActive).map(({
                                  languageName,
                                  code
                                }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={code.toLowerCase()}
                                    >
                                      {t(String(languageName).toLowerCase())}
                                    </option>
                                  )
                                })}
                              </BForm.Select>
                            </div>

                          </div>
                        </td>
                      </tr>
                      {Object.keys(item?.bannerUrl)?.map((key, indx) => {
                        const ImgObj = item?.bannerUrl
                        const typeOf = typeof item?.bannerUrl[key][lang]
                        return (
                          <TableRow ObjectOfData={item?.bannerUrl[key][lang]} key={indx} bannerKey={key} lang={lang} typeOf={typeOf} item={item} isHidden={isHidden} t={t} handleCreateEdit={handleCreateEdit} ImgObj={ImgObj} handleDelete={handleDelete} />
                        )
                      })}

                      {(Object.keys(item?.bannerUrl)?.length === 0) && (
                        <tr>
                          <td colSpan={4} className='text-danger text-center'>
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
      </Accordion>

      {SABanners?.count === 0 && !loading && <div className='text-center text-danger mt-5'>{t('noDataFound')}</div>}

      {SABanners?.count !== 0 && !loading && (
        <PaginationComponent
          page={SABanners?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={SABanners?.count}
        />
      )}
      {
          deleteModalShow

            ? <DeleteConfirmationModal
                handleDeleteYes={handleDeleteYes}
                setDeleteModalShow={setDeleteModalShow}
                deleteModalShow={deleteModalShow}
                loading={deleteLoading}
              />
            : null
      }
      {
      !isGamma &&
      (
        <EditUploadBanner
          lang={lang}
          bannerTypes={bannerTypes}
          type={type}
          data={data}
          show={show}
          setShow={setShow}
          createUpdate={createUpdate}
          clientsFilterData={clientsFilterData}
          dispatch={dispatch}
          SABanners={SABanners}
          validation={validation}
          setValidation={setValidation}
          loading={updateLoading}
        />
      )
     }
    </>
  )
}
