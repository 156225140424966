import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAllBonusStart } from '../../../store/redux-slices/admins'
import { getTenantStart } from '../../../store/redux-slices/tenants'
import { updateBonusStart, updateSABonusStatusStart } from '../../../store/redux-slices/bonus'

const useSportsPerodicBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [isBonusActive, setBonusActive] = useState([])
  const [loaderForItem, setLoaderForItem] = useState(0)
  const { bonusList, loading } = useSelector((state) => state.admins)
  const { loading: updateBonusLoading, updateLoading } = useSelector((state) => state.bonus)

  const getBonus = () => {
    dispatch(getAllBonusStart({
      formData: {
        type: 'SPORTBOOK',
        adminId: selectedClient,
        size: 15,
        pageNo: 1,
        bonusType: 'PERODIC-PAYBACK-BONUS'
      }
    }))
  }

  const updatePeriodicBonus = (formValues, resetValues) => {
    dispatch(updateBonusStart({
      data: {
        ...formValues,
        type: 'SPORTBOOK'
      },
      navigate: () => {
        getBonus()
      }
    }))
  }

  const handleSetBonusActiveOrInActive = (data) => {
    dispatch(
      updateSABonusStatusStart({
        data: {
          ...data,
          type: 'SPORTBOOK',
          bonusType: 'PERODIC-PAYBACK-BONUS'
        },
        handleClose: () => {
          getBonus()
        }
      })
    )
  }
  useEffect(() => {
    getBonus()
    if (selectedClient) {
      dispatch(getTenantStart({ tenantId: selectedClient }))
    }
  }, [selectedClient])

  return {
    navigate,
    selectedClient,
    setSelectedClient,
    t,
    updatePeriodicBonus,
    bonusList,
    loading,
    loaderForItem,
    setLoaderForItem,
    isBonusActive,
    setBonusActive,
    handleSetBonusActiveOrInActive,
    updateBonusLoading,
    activeInactiveLoading: updateLoading
  }
}

export default useSportsPerodicBonus
