export const tableHeaders = [
  { label: 's.no', value: '' },
  { label: 'portal', value: 'domain' },
  { label: 'name', value: 'name' },
  { label: 'transactionId', value: 'transactionId' },
  { label: 'requestStatus', value: 'status' },
  { label: 'date', value: 'date' },
  { label: 'amount', value: 'amount' },
  { label: 'transactionAction', value: 'actionSource' },
  { label: 'transactionType', value: 'actionSource' }
]
export const requestActions = [
  { label: 'approve', value: 'ACCEPT' },
  { label: 'reject', value: 'REJECT' },
  { label: 'pending', value: 'PENDING' }
]
export const actionSources = [
  { label: 'withdraw', value: 'WITHDRAW' },
  { label: 'deposit', value: 'DEPOSIT' }
]
export const transactionType = [
  { label: 'request', value: 'REQUEST' },
  { label: 'nonRequest', value: 'NON-REQUEST' }
]
