import { Button, Col, Form, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { actionSources, requestActions, tableHeaders } from '../../TransactionsBanking/constants'
import useUserTransactionList from '../hooks/useUserTransactionList'
import PaginationComponent from '../../../../components/Pagination'
import moment from 'moment'
import { BONUS_TYPE_FILTER, VAULT_TRANSACTION_TYPE } from '../../../../utils/constants'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../../utils/storageUtils'

const TransactionBanking = ({ user, t, selectedTab }) => {
  const {
    formData,
    setFormData,
    userTransactionDetails,
    limit,
    page,
    initalWalletState,
    totalPages,
    setPage,
    setLimit,
    userTransactionLoading,
    debounceFn,
    inputRef,
    handleCsvDownload,
    state,
    setState,
    initialDate
  } = useUserTransactionList({
    selectedTab
  })

  return (
    <div className='p-3'>
      <div>
        <div className='d-flex gap-2 justify-content-between flex-wrap align-items-center'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('transactionStatus')}
            </Form.Label>
            <Form.Select
              value={formData?.actionType}
              onChange={(e) => {
                setFormData({ ...formData, actionType: e.target.value })
                setPage(1)
              }}
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
              size='sm'
            >
              <option value=''>{t('all')}</option>
              {requestActions?.map((requestAction) => (
                <option key={requestAction.value} value={requestAction.value}>
                  {requestAction?.value}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('transactionType')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
              size='sm'
              value={formData?.actionSource}
              onChange={(e) => {
                setFormData({ ...formData, actionSource: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {actionSources?.map((action) => (
                <option key={action.value} value={action.value}>
                  {action?.label}
                </option>
              ))}
              {BONUS_TYPE_FILTER?.map((bonus) => (
                <option className='text-uppercase' key={bonus} value={bonus}>
                  {bonus}
                </option>
              ))}
              {VAULT_TRANSACTION_TYPE?.map((action) => (
                <option className='text-uppercase' key={action.value} value={action.value}>
                  {action?.label}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchPlaceholder')}
              size='sm'
              className='w-90 mx-auto'
              style={{ minWidth: '180px', maxWidth: '230px', width: 'auto' }}
              onChange={debounceFn}
            />
          </div>
          <Col xs='auto' className='d-flex'>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
                setPage(1)
              }}
            />
          </Col>
          <Col xs='auto' className='d-flex'>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
                setPage(1)
              }}
            />
          </Col>
          <Col className='d-flex p-3 align-items-center gap-2'>
            <Trigger message={t('downloadCsv')}>
              <Button
                variant='outline-success'
                size='sm'
                disabled={userTransactionDetails?.count === 0}
                onClick={handleCsvDownload}
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </Trigger>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setFormData(initalWalletState)
                  setState(initialDate)
                  setPage(1)
                  inputRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
      </div>
      {!userTransactionLoading && (
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userTransactionDetails?.rows?.map((item, index) => (
              <tr key={index}>
                <td>{(page - 1) * limit + index + 1}</td>
                <td>
                  <span
                    style={{
                      width: '130px',
                      cursor: 'pointer'
                    }}
                    className='d-inline-block text-truncate'
                  >
                    {item?.username}
                  </span>
                </td>
                <td>{item?.transactionId}</td>
                <td>{item?.name}</td>
                <td>{item?.status}</td>
                <td>{(item?.ipAddress?.split('::ffff:')?.[1] ?? item.ipAddress)}</td>
                <td>{moment(item?.createdAt).format('MM-DD-YYYY HH:mm')}</td>
                <td>{item.amount || item.currency ? (formatNumber(item?.amount) + ' ₩') : '-'}</td>
                <td>{item?.actionType}</td>
              </tr>
            ))}
            {userTransactionDetails?.count === 0
              ? (
                <tr>
                  <td colSpan={8} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
                )
              : null}
          </tbody>
        </Table>
      )}
      {userTransactionDetails?.count !== 0 && (
        <PaginationComponent
          page={userTransactionDetails?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={userTransactionDetails?.count}
        />
      )}
    </div>
  )
}

export default TransactionBanking
