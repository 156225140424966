import { Button } from '@themesberg/react-bootstrap'
import { Form, Formik } from 'formik'
import React from 'react'
import { SuperAdminRoutes } from '../../../../routes'
import CountryForm from './CountryForm'

const Country = ({
  providerDetails,
  updateData,
  navigate,
  data,
  setData,
  search,
  setSearch,
  myCountries,
  details,
  kycCountries,
  setKycCountries,
  cashier = false
}) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          blockedRegions: providerDetails?.regions?.blocked || [],
          kycCountries: providerDetails?.kycCountries || []
        }}
        onSubmit={() => {
          cashier
            ? updateData({ data: { blockedRegions: data, kycCountries, logoCheck: 'false' }, country: true })
            : updateData({ data: { blockedRegions: data, logoCheck: 'false' }, country: true })
        }}
      >
        {({ handleSubmit }) => (
          <Form className='align-items-center'>
            <CountryForm
              data={data}
              setData={setData}
              search={search}
              setSearch={setSearch}
              myCountries={myCountries}
              details={details}
              kycCountries={kycCountries}
              setKycCountries={setKycCountries}
              cashier={cashier}
            />
            <div
              className='mt-4 d-flex justify-content-between align-items-center'
            >
              <Button
                variant='outline-warning'
                onClick={() => navigate(SuperAdminRoutes.PaymentAggregators)}
              >
                Cancel
              </Button>

              {!details &&
                <div>
                  <Button
                    variant='outline-success'
                    onClick={() => {
                      handleSubmit()
                    }}
                    className='ml-2'
                  >
                    Submit
                  </Button>
                </div>}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Country
