import React from 'react'
import {
  Button,
  Modal,
  Form as BForm,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import Select from 'react-select'
import { Formik, Form, ErrorMessage } from 'formik'
import { bulkEditDefaultRtpSchema } from '../schema'
import useProviderCategoryDefaultRtpUpdater from '../hooks/useProviderCategoryDefaultRtpUpdater'

const ProviderCategoryDefaultRtpUpdater = ({
  handleClose,
  data,
  t,
  active,
  fetchData,
  selectedDefaultRtpGames
}) => {
  const {
    loading,
    providerIds,
    setProviderIds,
    categoryIds,
    setCategoryIds,
    providerList,
    categoryList,
    updateProviderCategorySystemRtp
  } = useProviderCategoryDefaultRtpUpdater({ fetchData })

  const initialValues = {
    providerIds: [],
    categoryIds: [],
    systemRtp: 0
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={bulkEditDefaultRtpSchema(t)}
      onSubmit={(formValues) => {
        const data = {
          rtp: Number(formValues?.systemRtp),
          masterCasinoProviderId: providerIds.map((o) => o.value),
          masterCasinoCategoryId: categoryIds.map((o) => o.value)
        }
        updateProviderCategorySystemRtp(data, handleClose)
      }}
    >
      {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, setFieldError, setFieldTouched }) => {
        return (
          <Form>
            <Modal.Body>
              <Row>
                <Col>
                  <BForm.Label>{t('provider')}</BForm.Label>
                  <Select
                    isMulti
                    isClearable
                    name='providerIds'
                    value={providerIds}
                    options={providerList}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onBlur={() => {
                      if (providerIds?.length < 1) {
                        setFieldTouched('providerIds', true)
                        setFieldError('providerIds', 'Required')
                      } else {
                        setFieldTouched('providerIds', false)
                        setFieldError('providerIds', null)
                      }
                    }}
                    onChange={(option, e) => {
                      setFieldValue('providerIds', option)
                      setProviderIds(option)
                      setFieldValue('providerIds', option.map((o) => o.value), false)
                      if (option?.length < 1) {
                        setFieldError('providerIds', 'Required')
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BForm.Label>{t('category')}</BForm.Label>
                  <Select
                    isMulti
                    isClearable
                    name='categoryIds'
                    value={categoryIds}
                    options={categoryList}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onBlur={() => {
                      if (categoryIds?.length < 1) {
                        setFieldTouched('categoryIds', true)
                        setFieldError('categoryIds', 'Required')
                      } else {
                        setFieldTouched('categoryIds', false)
                        setFieldError('categoryIds', null)
                      }
                    }}
                    onChange={(option, e) => {
                      setFieldValue('categoryIds', option)
                      setCategoryIds(option)
                      setFieldValue('categoryIds', option.map((o) => o.value), false)
                      if (option?.length < 1) {
                        setFieldError('categoryIds', 'Required')
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BForm.Label>{t('systemReturnToPlayer')}</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='systemRtp'
                    placeholder={t('enterSystemRtp')}
                    value={values.systemRtp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='systemRtp' className='text-danger' />
                </Col>
              </Row>
            </Modal.Body>
            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button variant='warning' onClick={() => handleClose()}>
                  {t('cancel')}
                </Button>
                <Button variant='success' onClick={handleSubmit} disabled={!(providerIds.length || categoryIds.length)} className='ml-2'>
                  {t('submit')}
                  {loading && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  )}
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ProviderCategoryDefaultRtpUpdater
