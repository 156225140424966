import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useClientFilter from './useClientFilter'
import { useTranslation } from 'react-i18next'

const ClientFilter = ({
  isErrorHandle,
  cms,
  cmsCategory,
  cmsData,
  setSelectedClient,
  setSelectedPortal,
  selectedClient,
  hasAllOptions = true,
  refetch = true,
  details,
  setPage,
  setFieldValue,
  setFieldError,
  setFieldTouched
}) => {
  const { clientsFilterData } = useClientFilter({ refetch })
  const { t } = useTranslation()
  return (
    <>
      {
        cmsData
          ? (
            <>
              <Form.Label style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
              >
                {t('senior')} <span className='text-danger'>*</span>
              </Form.Label>

              <Form.Select
                onChange={(e) => {
                  if (isErrorHandle) {
                    if (e.target.value) {
                      setSelectedClient(e.target.value)

                      setFieldError('selectedClient', null)
                    } else {
                      setFieldTouched('selectedClient', true)
                      setSelectedClient(e.target.value)
                      setFieldError('selectedClient', 'Senior Required')
                    }
                    setFieldValue('selectedClient', e.target.value)
                  } else {
                    setSelectedClient(e.target.value)
                  }
                  setSelectedPortal && setSelectedPortal('')
                  setPage && setPage(1)
                }}
                onBlur={(e) => {
                  if (isErrorHandle) {
                    if (e.target.value) {
                      setSelectedClient(e.target.value)
                      setFieldError('selectedClient', null)
                    } else {
                      setFieldTouched('selectedClient', true)
                      setFieldError('selectedClient', 'Senior Required')
                    }
                  }
                }}
                value={selectedClient}
                style={{
                  maxWidth: '200px',
                  marginRight: '10px',
                  fontSize: '13px'
                }}
                size={cmsData ? '' : 'sm'}
                disabled={cms}
              >
                <option
                  key={1}
                  value={cms ? selectedClient : ''}
                >{
                  cms
                    ? cms?.tenant?.AdminUser?.firstName + ' ' + cms?.tenant?.AdminUser?.lastName
                    : t('selectSenior')
                  }
                </option>
                {Array.isArray(cmsCategory) && cmsCategory?.map(
                  ({ adminId, adminName }) => (
                    <option
                      key={adminId}
                      value={adminId}
                    >
                      {adminName}
                    </option>
                  )
                )}
              </Form.Select>
            </>
            )
          : (
            <>
              <Form.Label style={{ marginBottom: '0', marginTop: '5px', marginRight: '5px', fontSize: '13px' }}>
                {t('senior')}
              </Form.Label>

              <Form.Select
                onChange={(e) => {
                  setSelectedClient(e.target.value)
                  setSelectedPortal && setSelectedPortal('')
                }}
                value={selectedClient}
                style={{ maxWidth: '200px', marginRight: '10px', fontSize: '13px' }}
                size={cmsData ? '' : 'sm'}
                disabled={cms}
              >
                <option value='' disabled={!hasAllOptions}>
                  {hasAllOptions ? t('all') : t('chooseTenantAdmin')}
                </option>

                {clientsFilterData?.count === 0 && (
                  <option value='' disabled>
                    {t('noTenantAdminsAvailable')}
                  </option>
                )}

                {clientsFilterData?.rows?.map(
                  ({ adminUserId, firstName, lastName }) => (
                    <option key={adminUserId} value={adminUserId}>
                      {`${firstName} ${lastName}`}
                    </option>
                  )
                )}
              </Form.Select>
            </>

            )
      }
    </>
  )
}

export default ClientFilter
