import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    qrModal: false,
    selectedLanguages: null
  },
  reducers: {
    setLanguage: (state, { payload }) => ({
      ...state,
      selectedLanguages: payload
    }),
    getAuthQrCodeStart: (state) => ({
      ...state,
      loading: true
    }),
    getAuthQrCodeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    getAuthQrCodeFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    verifyAuthCodeStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyAuthCodeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    verifyAuthCodeFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    disableAuthenticatorStart: (state) => ({
      ...state,
      loading: true
    }),
    disableAuthenticatorSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    disableAuthenticatorFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    qrModalOpenHandler: (state) => ({
      ...state,
      qrModal: true
    }),
    qrModalCloseHandler: (state) => ({
      ...state,
      qrModal: false
    })
  }
})

export default authSlice.reducer

export const {
  getAuthQrCodeStart,
  getAuthQrCodeSuccess,
  getAuthQrCodeFailure,
  verifyAuthCodeStart,
  verifyAuthCodeSuccess,
  verifyAuthCodeFailure,
  disableAuthenticatorStart,
  disableAuthenticatorSuccess,
  disableAuthenticatorFailure,
  qrModalCloseHandler,
  qrModalOpenHandler,
  setLanguage
} = authSlice.actions
