import { useDispatch, useSelector } from 'react-redux'
import { tenantCasinoActionStart } from '../../../../store/redux-slices/tenantCasino'

const useEditCasinoGames = () => {
  const { loading } = useSelector((state) => state.tenantCasino)
  const dispatch = useDispatch()

  const editGames = ({
    data,
    limit,
    pageNo
  }) => {
    dispatch(tenantCasinoActionStart({
      casinoName: data?.name,
      thumbnailUrl: data?.thumbnailUrl || '',
      subcategory: data?.tenantGameSubCategoryId,
      gameId: data?.gameId,
      description: data?.description || '',
      languageCode: data?.language,
      limit,
      pageNo
    }))
  }
  return {
    editGames,
    loading
  }
}

export default useEditCasinoGames
