import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllTenantCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getAllTenantTransactionsStart } from '../../../../store/redux-slices/tenantTransactions'
import { getDateDaysAgo, formatDateYMD } from '../../../../utils/dateFormatter'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getTransactionPaymentCategoriesStart, getTransactionReportingPaymentCategoriesStart } from '../../../../store/redux-slices/payment'
import { getSuperAdminAllTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'

const useTransactionBList = ({ isUserDetail, allTransaction = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId } = useParams()

  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [selectedAction, setSelectedAction] = useState(JSON.stringify([]))
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [myUserId, setMyUserId] = useState(userId || '')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const { tenantDetails } = useSelector((state) => state.login)
  const { loading, tenantTransactions } = useSelector((state) => state.tenantTransactions)
  const { tenantCountries: countries } = useSelector(state => state.fetch)
  const totalPages = Math.ceil(tenantTransactions?.count / limit)
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState('')
  const isInitialRender = useDidMountEffect()
  const { transactionPaymentCategories, transactionReportingPaymentCategories } = useSelector((state) => state.payment)
  const { allTransactions } = useSelector((state) => state.superAdminTransactions)
  const allTransactionsTotalPages = Math.ceil(allTransactions?.count / limit)
  const [check, setCheck] = useState({ createdAt: false, updatedAt: true })

  const [sortBy, setSortBy] = useState({
    amount: 'desc',
    Id: 'desc',
    isAmountSorting: null
  })
  const [over, setOver] = useState({
    amount: false,
    Id: false
  })
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const [createdAt, setCreatedAt] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    dispatch(getAllTenantCurrenciesStart({ limit: '', pageNo: '' }))
    dispatch(getTenantCountriesStart())
    dispatch(getTransactionPaymentCategoriesStart({ isTenant: true }))
    dispatch(getTransactionReportingPaymentCategoriesStart({ isTenant: true }))
  }, [])

  const getCsvDownloadUrl = (value) => {
    if (value === 'player') {
      return `${process.env.REACT_APP_API_URL}/api/tenant/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}&transactionId=${transactionId}&currencyId=${selectedCurrency}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&paymentProvider=${selectedPaymentProvider}&token=${getLoginToken()}&status=${status}&tenantId=${getItem('tenant-id')}&userId=${myUserId}&paymentMethod=${paymentMethod}`
    } else if (value === 'allTransactions') {
      return `${process.env.REACT_APP_API_URL}/api/tenant/transactions-all?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}&transactionId=${transactionId}&currencyCode=${selectedCurrency}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&tenantId=${tenantDetails?.tenantId}&adminId=${tenantDetails?.adminUserId}&paymentProvider=${selectedPaymentProvider}&status=${status}&token=${getLoginToken()}&userId=${myUserId}&paymentMethod=${paymentMethod}`
    } else {
      return `${process.env.REACT_APP_API_URL}/api/tenant/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}&transactionId=${transactionId}&currencyId=${selectedCurrency}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&paymentProvider=${selectedPaymentProvider}&token=${getLoginToken()}&status=${status}&tenantId=${getItem('tenant-id')}&userId=${myUserId}&paymentMethod=${paymentMethod}`
    }
  }

  const getParams = (myPage) => {
    return {
      limit,
      pageNo: myPage,
      search,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyId: selectedCurrency,
      transactionType: selectedAction,
      paymentProvider: selectedPaymentProvider,
      isUserDetail,
      userId: myUserId,
      status,
      countryCode: selectedCountry,
      transactionId,
      createdAtStartDate: formatDateYMD(createdAt.map(a => a.startDate)),
      createdAtEndDate: formatDateYMD(createdAt.map(a => a.endDate)),
      paymentMethod,
      check
    }
  }

  useEffect(() => {
    !allTransaction
      ? dispatch(getAllTenantTransactionsStart({
          ...getParams(1)
        }))
      : dispatch(getSuperAdminAllTransactionsStart({
        ...getParams(page),
        tenantId: tenantDetails?.tenantId || '',
        adminId: tenantDetails?.adminUserId || '',
        orderBy: sortBy.isAmountSorting !== null ? (sortBy.isAmountSorting === true ? 'amount' : 'casinoTransactionId') : '',
        orderType: sortBy.isAmountSorting !== null ? (sortBy.isAmountSorting === true ? sortBy.amount : sortBy.Id) : '',
        isTenant: true
      }))
    setPage(1)
  }, [limit, selectedCurrency, selectedCountry, selectedAction, state, search, status, selectedPaymentProvider, sortBy, createdAt, paymentMethod, check])

  useEffect(() => {
    if (!isInitialRender) {
      !allTransaction
        ? dispatch(getAllTenantTransactionsStart({
            ...getParams(page)
          }))
        : dispatch(getSuperAdminAllTransactionsStart({
          ...getParams(page),
          tenantId: tenantDetails?.tenantId || '',
          adminId: tenantDetails?.adminUserId || '',
          orderBy: sortBy.isAmountSorting !== null ? (sortBy.isAmountSorting === true ? 'amount' : 'casinoTransactionId') : '',
          orderType: sortBy.isAmountSorting !== true ? (sortBy.isAmountSorting === true ? sortBy.amount : sortBy.Id) : '',
          isTenant: true
        }))
    }
  }, [page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          !allTransaction
            ? dispatch(getAllTenantTransactionsStart({
                ...getParams(page)
              }))
            : dispatch(getSuperAdminAllTransactionsStart({
              ...getParams(page),
              tenantId: tenantDetails?.tenantId || '',
              adminId: tenantDetails?.adminUserId || '',
              orderBy: sortBy.isAmountSorting !== null ? (sortBy.isAmountSorting === true ? 'amount' : 'casinoTransactionId') : '',
              orderType: sortBy.isAmountSorting !== true ? (sortBy.isAmountSorting === true ? sortBy.amount : sortBy.Id) : '',
              isTenant: true
            }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [transactionId, myUserId])

  return {
    wallets: null,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    navigate,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    tenantTransactions,
    loading,
    selectedPaymentProvider,
    setSelectedPaymentProvider,
    getCsvDownloadUrl,
    tenantDetails,
    status,
    setStatus,
    selectedCountry,
    setSelectedCountry,
    countries,
    transactionPaymentCategories,
    allTransactions,
    allTransactionsTotalPages,
    sortBy,
    setSortBy,
    over,
    setOver,
    userId,
    transactionId,
    setTransactionId,
    myUserId,
    setMyUserId,
    createdAt,
    setCreatedAt,
    transactionReportingPaymentCategories,
    paymentMethod,
    setPaymentMethod,
    check,
    setCheck
  }
}

export default useTransactionBList
