import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { createCmsNewSchema } from '../schema'
import useCreateCms from '../hooks/useCreateCms'
import ClientFilter from '../../../../components/ClientFilter'
// import PortalFilter from '../../../../components/PortalFilter'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GalleryModal } from '../../../../components/ConfirmationModal'
import { SuperAdminRoutes } from '../../../../routes'
import { CreateCMSTemplate } from '../../../../components/EditCMSTemplate/CreateCMSTemplate'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'
import { toast } from '../../../../components/Toast'

const CreateCMSNew = () => {
  const {
    navigate,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages,
    deleteCms,
    cmsCategory,
    t
  } = useCreateCms()
  const { isHidden } = useCheckPermission()
  const [title, setTitle] = useState({ EN: '' })
  const [content, setContent] = useState({ EN: '' })

  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{t('createCMS')}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Trigger message='Gallery'>
            <Button
              hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
              onClick={() => {
                getGalleryData()
                setGalleryModal(true)
              }}
              variant='outline-secondary'
            >
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Formik
        initialValues={{
          slug: '',
          content: '',
          category: '',
          isActive: true,
          selectedClient: ''
        }}
        validationSchema={createCmsNewSchema(t)}
        onSubmit={(formValues) => {
          for (const lang in title) {
            if (([undefined, '']
              .includes(content?.[lang]) &&
            [undefined, ''].includes(title?.[lang]))) {
              delete title[lang]
              delete content[lang]
            }
          }
          createCms({
            cmsData: {
              ...formValues,
              title,
              content,
              tenantId: selectedPortal,
              adminUserId: selectedClient
            }
          })
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldError,
          setFieldTouched
        }) => {
          return (
            <Form>
              <Row className='mb-3 align-items-center'>
                <Col xs={6}>
                  <Col>
                    <BForm.Label>
                      {t('enterSlug')} <span className='text-danger'>*</span>
                    </BForm.Label>
                  </Col>
                  <Col>
                    <BForm.Control
                      type='text'
                      name='slug'
                      placeholder='Enter Slug'
                      value={values.slug}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='slug'
                      className='text-danger'
                    />
                  </Col>
                </Col>

                {
              selectedClient
                ? (
                  <Col>
                    <BForm.Label>
                      {t('category')} <span className='text-danger'>*</span>
                    </BForm.Label>

                    <BForm.Select
                      type='text'
                      name='category'
                      style={{ maxWidth: '200px' }}
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>{t('selectOneCategory')}</option>
                      {selectedClient
                        ? (
                            cmsCategory?.find(item => item.adminId === parseInt(selectedClient))
                              ?.dropdownOptions?.map(
                                (item, index) => (
                                  <option
                                    key={index}
                                    value={item}
                                  >{item}
                                  </option>
                                ))
                          )
                        : <></>}
                    </BForm.Select>
                    <ErrorMessage
                      component='div'
                      name='category'
                      className='text-danger'
                    />
                  </Col>
                  )
                : <></>
             }

              </Row>
              <Row className='mt-3 d-flex'>
                <Col>
                  <ClientFilter
                    setSelectedClient={setSelectedClient}
                    setSelectedPortal={setSelectedPortal}
                    selectedClient={selectedClient}
                    cmsData
                    cmsCategory={cmsCategory}
                    isErrorHandle
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                  />
                  <ErrorMessage
                    component='div'
                    name='selectedClient'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Tabs
                activeKey={selectedTab}
                onSelect={(tab) => {
                  if (content?.EN !== '' && title?.EN !== '') {
                    setSelectedTab(tab)
                  } else {
                    toast('You must enter data for English language before switching to another language ', 'error')
                  }
                }}
                className='nav-light mt-3'
              >
                <Tab
                  eventKey='EN'
                  title={
                    <Trigger message='English'>
                      <span>EN</span>
                    </Trigger>
              }
                  mountOnEnter
                  tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                >
                  <div className='mt-5'>
                    <CreateCMSTemplate
                      t={t}
                      cmsKeys={cmsKeys}
                      isHidden={isHidden}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      navigate={navigate}
                      deleteCms={deleteCms}
                      initValues={values}
                      errors={errors}
                      title={title}
                      setTitle={setTitle}
                      content={content}
                      setContent={setContent}
                      isErrorHandle
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                  <ErrorMessage
                    component='div'
                    name='content'
                    className='text-danger'
                  />
                </Tab>
                {languages?.length
                  ? languages?.map((code) => {
                      return code !== 'EN' && (
                        <Tab
                          eventKey={code}
                          title={
                            <Trigger message={languageCode[code]}>
                              <span>{code}</span>
                            </Trigger>
                    }
                          key={code}
                          mountOnEnter
                          tabClassName={selectedTab !== code
                            ? (![undefined, ''].includes(content?.[code]) ||
                        ![undefined, ''].includes(title?.[code]))
                                ? 'email'
                                : ''
                            : 'email-active'}
                        >
                          <div className='mt-5'>
                            <CreateCMSTemplate
                              cmsKeys={cmsKeys}
                              setFieldValue={setFieldValue}
                              isHidden={isHidden}
                              selectedTab={selectedTab}
                              setSelectedTab={setSelectedTab}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              navigate={navigate}
                              handleSubmit={handleSubmit}
                              deleteCms={deleteCms}
                              initValues={values}
                              errors={errors}
                              title={title}
                              setTitle={setTitle}
                              content={content}
                              setContent={setContent}
                            />
                          </div>
                        </Tab>
                      )
                    })
                  : null}
              </Tabs>

              <div className='d-flex justify-content-between'>
                <Button
                  variant='outline-warning'
                  className='m-2'
                  onClick={() => {
                    navigate(SuperAdminRoutes.CMS)
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant='outline-success'
                  hidden={isHidden({ module: { key: 'CMS', value: 'U' } })}
                  onClick={() => { handleSubmit() }}
                  className='m-2'
                >
                  {t('create')}
                </Button>

              </div>
            </Form>
          )
        }}
      </Formik>
      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
        />}
    </div>
  )
}

export default CreateCMSNew
