import React from 'react'
import {
  Button,
  // Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Breadcrumb
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faEye
} from '@fortawesome/free-solid-svg-icons'

// import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useTenantListing from './hooks/useTenantListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import useIsGamma from '../../../common/hook/useIsGamma'
import PaginationComponent from '../../../components/Pagination'

export default () => {
  const {
    totalPages,
    show,
    limit,
    page,
    allTenantList,
    handleYes,
    handleShow,
    navigate,
    setLimit,
    setPage,
    setShow,
    active,
    adminId,
    loading,
    name,
    t
  } = useTenantListing()
  const { isGamma } = useIsGamma()
  const { isHidden } = useCheckPermission()
  return (
    <div className='px-2'>

      <Row className='px-2'>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/super-admin/tenants/owners')}>{t('tenantAdmin')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('tenant')}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row className='px-2'>
        <Col>
          <h3 className='ms-3'>{t('tenant')}</h3>
        </Col>
        <Col xs='auto'>

          {
            !isGamma &&
            (
              <div className='mb-2 me-2 d-flex justify-content-end align-items-center'>
                {allTenantList?.count === 0 &&
                  <Button
                    variant='outline-success'
                    size='sm'
                    onClick={() => navigate(`/super-admin/tenants/create/${adminId}`)}
                    hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
                  >
                    {t('create')}
                  </Button>}
              </div>
            )
          }
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className=' ms-3 text-center'>
        <thead className='thead-dark'>
          <tr>
            {[t('name'), t('domain'), t('status'), t('action')].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {(Boolean(allTenantList) && !loading) &&
                allTenantList?.rows?.map(
                  ({
                    tenantId,
                    name: tenantName,
                    domain: tenantDomain,
                    isActive
                  }) => {
                    return (
                      <tr key={tenantId}>

                        <td>
                          <Trigger message={tenantName}>
                            <span
                              style={{
                                width: '100px',
                                cursor: 'pointer'
                              }}
                              className='text-link d-inline-block text-truncate'
                              onClick={() =>
                                navigate(
                                  `/super-admin/tenants/details/${adminId}/${tenantId}`
                                )}
                            >
                              {tenantName}
                            </span>
                          </Trigger>
                        </td>

                        <td>
                          <span
                            onClick={() => window.open(tenantDomain)}
                            className='text-link'
                            style={{ cursor: 'pointer' }}
                          >
                            {tenantDomain}
                          </span>
                        </td>
                        <td className={isActive ? 'table-success' : 'table-danger'}>
                          {isActive ? t('active') : t('inactive') || 'NA'}
                        </td>
                        <td>
                          <ButtonGroup>
                            {
                            !isGamma &&
                            (
                              <Trigger message={t('edit')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='warning'
                                  onClick={() =>
                                    navigate(`/super-admin/tenants/edit/${adminId}/${tenantId}`)}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </Trigger>
                            )
                          }

                            <Trigger message={t('viewDetails')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() =>
                                  navigate(
                                    `/super-admin/tenants/details/${adminId}/${tenantId}`, {
                                      state: {
                                        domain: tenantDomain
                                      }
                                    }
                                  )}
                                hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Trigger>
                            {!isActive
                              ? (
                                <Trigger message={t('setStatusActive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='success'
                                    onClick={() => handleShow(tenantId, isActive, `${tenantName}`)}
                                    hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                                )
                              : (
                                <Trigger message={t('setStatusInactive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handleShow(tenantId, isActive, `${tenantName}`)}
                                    hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                                )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  }
                )}

          {allTenantList?.count === 0 && !loading && (
            <tr>
              <td colSpan={5} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {allTenantList?.count !== 0 && !loading && (
        <PaginationComponent
          page={allTenantList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}
    </div>
  )
}
