import { Button, Modal, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RemoveModal = ({
  show,
  handleUpdateYes,
  handleClose,
  loading
}) => {
  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={handleClose} centered>

      <Modal.Body>
        <div className='fs-5'>
          {t('confirmRemove')}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-secondary' disabled={loading} onClick={handleUpdateYes}>
          {t('yes')}
          {loading && (
            <Spinner
              className='ms-1'
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </Button>

        <Button variant='outline-primary' onClick={handleClose}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveModal
