import React from 'react'
import { Button, Col, Table, Row } from '@themesberg/react-bootstrap'
import { tableHeaders } from './constants'
import PaginationComponent from '../../components/Pagination'
import useHotCombo from './hooks/useHotCombo'
import { SuperAdminRoutes } from '../../routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faEye,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { getDateTime } from '../../utils/dateFormatter'
import DeleteModal from '../../common/Modals/DeleteModal'
const HotCombo = () => {
  const {
    navigate,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    referralData,
    loading,
    t,
    hotCombos,
    isTenant,
    handleNo,
    isConfirmationDeleteOpen,
    handleDeleteCombo,
    handleYesDeleteCombo,
    updateLoading
  } = useHotCombo()
  return (
    <>

      <div className='p-3'>
        <Row className='d-flex justify-content-between align-items-center'>
          <Col xs='auto'>
            <h3>{t('hotCombo')}</h3>
          </Col>
          {!isTenant && (
            <Col xs='auto'>
              <Button
                className='m-1'
                size='sm'
                variant='outline-success'
                onClick={() => navigate(SuperAdminRoutes.CreateHotCombo)}
              >
                {t('create')}
              </Button>
            </Col>
          )}
        </Row>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {
            !loading && hotCombos &&
            hotCombos?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>

                    <td>
                      {item?.comboName}
                    </td>
                    <td>
                      {item?.startDate ? getDateTime(item?.startDate) : '-'}
                    </td>
                    <td>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        onClick={() =>
                          navigate(isTenant ? '/tenant/hot-combo/view' : '/super-admin/hot-combo/view', { state: item })}
                      //  hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        onClick={() =>
                          navigate(isTenant ? '/tenant/hot-combo/view?isEdit=true' : '/super-admin/hot-combo/view?isEdit=true', { state: item })}
                      //  hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='danger'
                        onClick={() =>
                          handleDeleteCombo(item?.hotComboId)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                )
              }
            )
          }
            {!loading && hotCombos?.count === 0 &&
            (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {!loading && hotCombos?.count !== 0 &&
        (
          <PaginationComponent
            page={referralData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={referralData?.count}
          />
        )}
      </div>
      <DeleteModal
        loading={updateLoading}
        handleDeleteYes={handleYesDeleteCombo}
        show={isConfirmationDeleteOpen}
        handleClose={handleNo}

      />

    </>
  )
}

export default HotCombo
