import React from 'react'
import { Button, Col, Form } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../OverlayTrigger'
import useSportsTransactions from './hooks/useSportsTransactions'
import ClientFilter from '../ClientFilter'
import SportsTransactionsTable from '../ReportingTable/SportsTransactionsTable'
import InputDatePicker from '../FormElements/InputDatePicker/InputDatePicker'
import CustomAsyncSelect from '../AsyncSelect'

const SportsTransactions = ({ isBetBy, isV2User, userData = {} }) => {
  const {
    formData,
    setFormData,
    state,
    setState,
    sportsBookTransactions,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalState,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    t,
    setSelectedClient,
    selectedClient,
    loading,
    customAsyncDebounce,
    setSelectedMatch,
    selectedMatch,
    sortHandler
  } = useSportsTransactions({ isV2User: !!isV2User, isBetBy, userData })
  const isTenant = !window.location.href.match(/super/g)

  return (
    <div className='p-3'>
      {!userData?.userId &&
        <Col xs='auto'>
          <h3>{isBetBy ? t('sportsTransactionsBetBy') : t('sportsTransactions')}</h3>
        </Col>}
      <div className='d-flex flex-wrap align-items-center'>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          {!isTenant && <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
                        />}
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('journalEntry')}
          </Form.Label>
          <Form.Select
            value={formData?.journalEntry}
            onChange={(e) => {
              setFormData({ ...formData, journalEntry: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['credit', 'debit'].map((type) =>
              (
                <option
                  key={type}
                  value={type}
                >
                  {t(type)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('status')}
          </Form.Label>
          <Form.Select
            value={formData?.status}
            onChange={(e) => {
              setFormData({ ...formData, status: e.target.value })
              setPage(1)
            }}
            style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {(isBetBy ? ['winner', 'refund', 'cash_out', 'placed', 'rollback', 'loser'] : ['canceled', 'winner', 'refund', 'cash_out', 'placed', 'half_won', 'half_lost', 'rollback', 'agent_commission', 'rejected'])?.map((status) =>
              (
                <option
                  key={status}
                  value={status}
                >
                  {t(status)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        {!isBetBy &&
          <Col xs='auto' className='d-flex align-items-center p-2'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              {t('selectMatch')}
            </Form.Label>

            <CustomAsyncSelect
              onChange={(option, e) => {
                setSelectedMatch(option)
              }}
              placeholder={`${t('search')} & ${t('selectMatch')}`}
              value={selectedMatch || ''}
              cacheOptions
              loadOptions={customAsyncDebounce}
              defaultOptions
            />
          </Col>}
        {!userData?.userId &&
          <Col xs='auto' className='d-flex align-items-center p-2'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('username')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('searchUsername')}
              size='sm'
              style={{
                minWidth: '200px',
                maxWidth: '240px',
                width: 'fit-content'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </Col>}
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            showTimeSelect={false}
            value={state[0].from}
            onChange={(val) => {
              setState([{ ...state[0], from: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center p-2'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            showTimeSelect={false}
            value={state[0].to}
            onChange={(val) => {
              setState([{ ...state[0], to: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex p-3 float-end'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='py-1'
              size='sm'
              onClick={() => {
                setFormData(initalState)
                setState(initialDate)
                setSelectedClient('')
                setSelectedMatch('')
                if (inputRef.current) { // Add null check here
                  inputRef.current.value = ''
                }
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        <Col className='d-flex p-3 float-end'>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={sportsBookTransactions?.count === 0}
              onClick={() => {
                handleCsvDownloadCasino()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>
      </div>

      <SportsTransactionsTable
        tableData={sportsBookTransactions}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        t={t}
        loading={loading}
        sortHandler={sortHandler}
        isV2User={!!isV2User}
        isBetBy={!!isBetBy}
      />
    </div>
  )
}
export default SportsTransactions
