import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import { SportsProviderConfirmationModal } from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import useProviderListing from './useProviderListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import { Form, Spinner } from 'react-bootstrap'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    isConfirmationModalOpen,
    setConfirmationModalOpen,
    sportsbookProviders,
    totalPages,
    loading,
    search,
    setSearch,
    t,
    providerDetails,
    setProviderDetails,
    cancelProviderUpdate,
    updateProviderHandler,
    sportsProviderUpdateLoading
  } = useProviderListing()

  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{t('sportsBookProviders')}</h3>
        </Col>

        <Col sm={4}>
          <div className='d-flex justify-content-end text-right mb-2'>
            <Form.Control
              type='search'
              name='search'
              placeholder={t('searchProvider')}
              size='sm'
              className='w-50 me-2'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>{t('sNo')}</th>
            <th>{t('name')}</th>
            <th>{t('status')}</th>
            <th>{t('demoMode')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(sportsbookProviders) &&
            sportsbookProviders?.rows?.map(
              (
                {
                  masterSportProviderId,
                  name,
                  isActive,
                  demo
                },
                index
              ) => {
                return (
                  <tr key={masterSportProviderId}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>
                      <Trigger message={name}>
                        <span
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {name}
                        </span>
                      </Trigger>
                    </td>
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inactive')}
                    </td>

                    <td>{demo ? t('yes') : t('no')}</td>

                    <td>
                      {(!isHidden({ module: { key: 'SportsBookManagement', value: 'U' } }) || !isHidden({ module: { key: 'SportsBookManagement', value: 'T' } }))
                        ? (
                          <ButtonGroup>

                            <Trigger message={isActive ? t('setStatusInactive') : t('setStatusActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant={isActive ? 'danger' : 'success'}
                                onClick={() => {
                                  setConfirmationModalOpen(true)
                                  setProviderDetails({ masterSportProviderId, name, isActive })
                                }}
                                hidden={isHidden({ module: { key: 'SportsBookManagement', value: 'T' } })}
                              >
                                {(sportsProviderUpdateLoading && providerDetails?.masterSportProviderId === masterSportProviderId)

                                  ? <Spinner
                                      as='span'
                                      animation='border'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                  : <FontAwesomeIcon icon={isActive ? faWindowClose : faCheckSquare} />}
                              </Button>
                            </Trigger>
                          </ButtonGroup>)
                        : 'NA'}

                    </td>

                  </tr>
                )
              }
            )}

          {sportsbookProviders?.count === 0 && !loading &&
            (
              <tr>
                <td
                  colSpan={5}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>

      {sportsbookProviders?.count !== 0 && !loading &&
        (
          <PaginationComponent
            page={sportsbookProviders?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={sportsbookProviders?.count}
          />
        )}

      <SportsProviderConfirmationModal
        setOpenClose={setConfirmationModalOpen}
        isOpen={isConfirmationModalOpen}
        handleYes={updateProviderHandler}
        handleNo={cancelProviderUpdate}
        providerDetails={providerDetails}
        t={t}
        loading={sportsProviderUpdateLoading}
      />
    </div>
  )
}
