import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSportsListingStart, updateSportsBookActionSAStart } from '../../../store/redux-slices/superAdminSportsBook'
import { debounce } from 'lodash'
import { getTenantSportsListingStart, updateSportsBookActionTAStart } from '../../../store/redux-slices/tenantSportsBook'

const initialState = {
  sportName: '',
  status: '',
  isTop: 'true'
}

const useSports = (isTenant) => {
  const inputRef = useRef()
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [modalData, setModalData] = useState({})
  const [entityId, setEntityId] = useState()
  const [type, setType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [updateData, setUpdateData] = useState()
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDisable, setIsDisable] = useState(null)
  const {
    sportsListing,
    loading
  } = useSelector(state => !isTenant ? state.superAdminSportsBook : state.tenantSportsBook)
  const {
    addTopLoading
  } = useSelector(state => state.superAdminSportsBook)
  const totalPages = Math.ceil(sportsListing?.count / limit)

  const fetchData = () => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            ...filterData,
            limit,
            pageNo: page,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page,
          isTenant
        }
      }))
  }

  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFilterData(prev => ({ ...prev, sportName: event.target.value }))
  }
  const handleClose = () => setShowModal(false)

  const handleShowModal = (type, data, id) => {
    setModalData(data)
    setEntityId(id)
    setType(type)
    setShowModal(true)
  }

  const handleUpdateSportAction = (updateData) => {
    !isTenant
      ? dispatch(updateSportsBookActionSAStart({ updateData, setIsStatusModalOpen, fetchData }))
      : dispatch(updateSportsBookActionTAStart({ updateData: { ...updateData, isTenant }, setIsStatusModalOpen, fetchData }))
  }
  return {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    entityId,
    setFilterData,
    initialState,
    sportsListing,
    loading,
    totalPages,
    debounceFn,
    inputRef,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    handleUpdateSportAction,
    updateData,
    setUpdateData,
    handleShowModal,
    showModal,
    handleClose,
    modalData,
    type,
    fetchData,
    addTopLoading
  }
}

export default useSports
