import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { deleteTemplateStart, getAllTemplateListStart, getRanksStart } from '../../../../store/redux-slices/superAdminSettings'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getTenantCurrenciesStart } from '../../../../store/redux-slices/currencies'
export const levelTableHeaders = ['sNo', 'title', 'action']
const useLevelTemplates = () => {
  const dispatch = useDispatch()
  const { allTemplateList, loading, ranks, ranksLoading } = useSelector(state => state.superAdminSettings)
  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [deleteTemplateData, setDeleteTemplateData] = useState(null)
  const [templateData, setTemplateData] = useState(null)
  const handleDeleteTemplate = () => {
    dispatch(deleteTemplateStart({
      formData: deleteTemplateData,
      handleClose: () => {
        setRefetch(prev => !prev)
        setDeleteTemplateData(null)
        setIsDeleteModalOpen(false)
      }
    }))
  }
  const getTenantCurrencies = (tenantId) => {
    dispatch(getTenantCurrenciesStart({ tenantId }))
  }

  const formattedRanks = ranks && ranks?.map((rank) => {
    return {
      isChecked: false,
      rankId: rank.rankId,
      name: rank?.rank,
      amount: rank?.ranksInfo?.thresholdAmount,
      benefit: '',
      benefitType: 'BONUS'

    }
  })

  const getRanksList = () => {
    dispatch(getRanksStart())
  }

  const getTemplateList = () => {
    dispatch(getAllTemplateListStart({ limit, pageNo, tenantId: selectedClient, searchParam: search }))
  }
  useEffect(() => {
    getTemplateList()
  }, [refetch, limit, pageNo, selectedClient, search])
  useEffect(() => {
    if (templateData?.tenantId) {
      getTenantCurrencies(templateData?.tenantId)
    }
  }, [templateData?.tenantId])

  useEffect(() => {
    getRanksList()
  }, [])
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  return {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setDeleteTemplateData,
    handleDeleteTemplate,
    allTemplateList,
    loading,
    t,
    selectedClient,
    setSelectedClient,
    navigate,
    isCreateTemplateModalOpen,
    setIsCreateTemplateModalOpen,
    ranks,
    ranksLoading,
    formattedRanks,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    setRefetch,
    templateData,
    setTemplateData,
    debounceFn
  }
}

export default useLevelTemplates
