import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { returnLangName } from '../../../utils/storageUtils'

const RollingDetails = () => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='p-3'>
      <div role='button' onClick={() => navigate(-1)} className='d-flex gap-2 items-center'>
        <span><FontAwesomeIcon icon={faArrowLeft} /></span>
        <h3>{t('details')}</h3>
      </div>
      <div className='my-3 d-flex flex-column gap-3'>
        <div className='d-flex gap-2 items-center'>
          <b style={{ minWidth: 100 }}>{t('name')}</b>
          <span>{returnLangName(state?.name)}</span>
        </div>
        <div className='d-flex gap-2 items-center'>
          <b style={{ minWidth: 100 }}>{t('description')}</b>
          <span>{state?.eventDescriptions}</span>
        </div>
        <div className='d-flex gap-2 items-center'>
          <b style={{ minWidth: 100 }}>{t('startDate')}</b>
          <span>{moment(state?.validFrom).format('MM-DD-YYYY  hh:mm a')}</span>
        </div>
        <div className='d-flex gap-2 items-center'>
          <b style={{ minWidth: 100 }}>{t('endDate')}</b>
          <span>{moment(state?.validTo).format('MM-DD-YYYY  hh:mm a')}</span>
        </div>
        <div className='d-flex gap-2 items-center'>
          <b style={{ minWidth: 100 }}>{t('status')}</b>
          <span className={state?.isActive ? 'text-success' : 'text-danger'}>{state?.isActive ? t('active') : t('inActive')}</span>
        </div>
        <div className='d-flex gap-2 items-start'>
          <b style={{ minWidth: 100 }}>{t('banner')}</b>
          <img style={{ width: 300, objectFit: 'contain' }} src={state?.thumbUrl} alt='' />
        </div>
      </div>
    </div>
  )
}

export default RollingDetails
