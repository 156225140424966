import React, { Suspense, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { CSSTransition } from 'react-transition-group'
import { Nav, Button, Navbar } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import { getItem } from '../../utils/storageUtils'
import IMAGE_PATH from '../../assets/img/dino-logo.png'
import GAMMA_PATH from '../../assets/img/gammastack.png'
import { GAMMA_EMAILS, ADMIN_ROLES } from '../../utils/constants'

const SuperAdminNavItems = React.lazy(() => import('./components/SuperAdminNavItems'))
const TenantNavItems = React.lazy(() => import('./components/TenantNavItems'))
const AffiliateNavItems = React.lazy(() => import('./components/AffiliateNavItems'))
const AgentNavItems = React.lazy(() => import('./components/AgentNavItems'))

export default (props = {}) => {
  const [show, setShow] = useState(false)
  const showClass = show ? 'show' : ''

  let { role, adminRole, tenantAdminRole } = useSelector((state) => state.login)
  const { adminRoleId, superAdminUser, adminDetails } = useSelector(state => state.admins)

  if (!role) {
    role = getItem('role')
  }

  const roleData =
  adminRole
    ? adminRole?.find(val => val.superRoleId !== 1 &&
    val.superRoleId === parseInt(adminRoleId))?.name
    : tenantAdminRole?.find(val => val.adminRoleId !== 1 &&
      val.adminRoleId === parseInt(superAdminUser?.adminRoleId))?.name

  const onCollapse = () => setShow(!show)
  const isGamma = GAMMA_EMAILS.includes(adminDetails?.email)

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant='dark'
        className='navbar-theme-primary px-4 d-md-none'
      >
        <Navbar.Toggle
          as={Button}
          aria-controls='main-navbar'
          onClick={onCollapse}
        >
          <span className='navbar-toggler-icon' />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames='sidebar-transition'>
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white pb-4`}
        >
          <div className='sidebar-inner px-3 pt-3'>
            <Nav className='flex-column pt-3 pt-md-0'>
              <div className='d-flex align-items-center m-auto mb-2'>
                <img
                  style={{ padding: isGamma ? '10px 0' : '0', width: isGamma ? '88%' : '100%', display: 'block', margin: '0 auto' }}
                  // src={GAMMA_PATH}
                  src={isGamma ? GAMMA_PATH : IMAGE_PATH}
                />
              </div>
              <h5 className='d-flex align-items-center m-auto mb-0'>
                {role === ADMIN_ROLES.ADMIN ? 'Senior' : role}&nbsp;
                {roleData && <span style={{ fontSize: '15px' }}>({roleData})</span>}
              </h5>
              {(
                superAdminUser?.email ||
                adminDetails?.email) &&
                  <span style={{ fontSize: '15px', textAlign: 'center' }}>{superAdminUser?.email || adminDetails?.email}</span>}
              <hr />

              {role === ADMIN_ROLES.SUPERADMIN && (
                <Suspense fallback={null}>
                  <SuperAdminNavItems setShow={setShow} />
                </Suspense>
              )}

              {role === ADMIN_ROLES.ADMIN &&
                <Suspense fallback={null}>
                  <TenantNavItems setShow={setShow} isTenant />
                </Suspense>}
              {role === ADMIN_ROLES.AFFILIATE &&
                <Suspense fallback={null}>
                  <AffiliateNavItems setShow={setShow} isAffiliate />
                </Suspense>}
              {role === ADMIN_ROLES.AGENT &&
                <Suspense fallback={null}>
                  <AgentNavItems setShow={setShow} isAgent />
                </Suspense>}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}
