import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getItem } from '../../utils/storageUtils'
import { ADMIN_ROLES } from '../../utils/constants'
import { getAffiliateAndAgentsListStart, getAgentFilterListStart, getUserForNotificationsReset, getUserForNotificationsStart } from '../../store/redux-slices/adminUser'
import { useLocation, useNavigate } from 'react-router-dom'
import { createNotificationStart, getspecificNotificationStart, updateNotificationStart } from '../../store/redux-slices/superAdminSettings'
import { getAllClientsStart } from '../../store/redux-slices/tenants'
import { debounce } from 'lodash'
const useCreateNotification = (isTenant = false, isEdit) => {
  const [tempSelectedPlayer, setTempSelectedPlayer] = useState({})
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const role = getItem('role')
  const data = location?.state?.data
  const {
    clientsFilterData,
    loading: clientLoading
  } = useSelector(state => state.tenants)
  const { getSingleNotification } = useSelector(state => state.superAdminSettings)
  const editValueAffiliate = getSingleNotification?.affiliates?.map(item => item.affiliateId)
  const editValueAgent = getSingleNotification?.agents
    ?.filter((item) => item?.isAffiliate)?.map(item => item.agentId)

  const editValueWithoutAffiliateAgents = getSingleNotification?.agents
    ?.filter((item) => !item?.isAffiliate)?.map(item => item.agentId)
  const client = clientsFilterData?.rows?.filter((client) => {
    return client?.adminUserId === data?.referenceId
  })
  const [agentWithoutAffiliateArray, setAgentWithoutAffiliate] = useState([])
  const [agentWithAffiliate, setAgentWithAffiliate] = useState([])
  const [agent, setAgent] = useState([])
  const [agentOptions, setAgentOptions] = useState([])
  const [step, setStep] = useState(1)
  const { adminDetails } = useSelector(state => state.admins)
  const [affiliate, setAffiliate] = useState(isEdit ? editValueAffiliate : [])
  const [selectedClient, setSelectedClient] = useState(isTenant ? adminDetails?.adminUserId : null)
  const [shouldNextDisabled, setShouldNextDisabled] = useState(true)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedPlayer, setSelectedPlayer] = useState([])
  const [withoutAffiliate, setWithoutAffiliate] = useState([])
  const [defaultAffiliateOptions, setDefaultAffiliateOptions] = useState([])
  const [defaultAgentWithoutAffiliateOptions, setDefaultAgentWithoutAffiliateOptions] = useState([])
  const [defaultAgentOptions, setDefaultAgentOptions] = useState([])
  const [search, setSearch] = useState('')
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  const inputRef = useRef()
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const {
    affiliateAndAgentsList,
    userForNotification,
    userLoading
  } = useSelector((state) => state.adminUser)

  const totalPages = Math.ceil(userForNotification?.count / limit)

  const agentWithoutAffiliate = affiliateAndAgentsList?.agents?.map(
    (item) => ({
      value: item.agentId,
      label: item.username
    })) || []

  const affiliateOptions = affiliateAndAgentsList?.affiliates?.map(item => ({
    value: item.affiliateId,
    label: item.username
  })) || []

  useEffect(() => {
    const mergedAgents = [...agentWithoutAffiliateArray, ...agentWithAffiliate]
    setAgent(mergedAgents)
  }, [agentWithoutAffiliateArray, agentWithAffiliate])
  useEffect(() => {
    if (isEdit && getSingleNotification && Array.isArray(editValueWithoutAffiliateAgents) && Array.isArray(editValueAgent)) {
      setAffiliate(editValueAffiliate)
      setAgentWithAffiliate(editValueAgent)
      setAgentWithoutAffiliate(editValueWithoutAffiliateAgents)
    }
  }, [isEdit, getSingleNotification])

  useEffect(() => {
    const userArray = getSingleNotification?.users?.map((item) => item.userId)
    if (getSingleNotification) {
      setSelectedPlayer(userArray || [])
    }
  }, [getSingleNotification])
  useEffect(() => {
    if (selectedClient) {
      dispatch(getUserForNotificationsStart({
        limit,
        pageNo: page,
        tenantId: Number(selectedClient),
        agentId: agent,
        affiliateId: affiliate,
        notificationId: data?.notificationId || '',
        isTenant,
        search
      }))
    }
  }, [isEdit, selectedClient, data, affiliate, agent, dispatch, limit, page, search])
  useEffect(() => {
    if (isEdit && client?.[0]?.adminUserId && getSingleNotification) {
      setSelectedClient(client[0].adminUserId)
    }
  }, [isEdit, client])
  useEffect(() => {
    const selectedAffiliates = affiliateOptions.filter(option => affiliate?.includes(option.value))
    const selectedAgents = selectedAffiliates?.flatMap(affiliate =>
      affiliateAndAgentsList?.affiliates
        .find(item => item.affiliateId === affiliate.value)
        ?.Agents.map(agent => ({
          value: agent.agentId,
          label: agent.username
        })) || []
    )

    setAgentOptions(selectedAgents)
  }, [affiliateAndAgentsList, affiliate, agent, isEdit])
  useEffect(() => {
    if (isEdit && (selectedClient || isTenant)) {
      dispatch(getspecificNotificationStart({
        formData: {
          notificationId: data?.notificationId,
          tenantId: data?.referenceId,
          isTenant
        }
      }))
    }
  }, [isEdit, selectedClient, data, dispatch])
  useEffect(() => {
    if (isEdit && getSingleNotification) {
      const options = getSingleNotification?.affiliates?.map((item) => ({
        value: item.affiliateId,
        label: item.userName
      }))
      setDefaultAffiliateOptions(options)
      const optionsAgent = getSingleNotification?.agents
        ?.filter(agent => agent.isAffiliate)?.map((item) => ({
          value: item.agentId,
          label: item.userName
        }))
      const optionsWithoutAffiliateAgents = getSingleNotification?.agents
        ?.filter(agent => !agent.isAffiliate)
        ?.map(item => ({
          value: item.agentId,
          label: item.userName
        }))
      setDefaultAgentOptions(optionsAgent)
      setDefaultAgentWithoutAffiliateOptions(optionsWithoutAffiliateAgents)
    }
  }, [getSingleNotification])
  useEffect(() => {
    if (role === ADMIN_ROLES.AFFILIATE) {
      dispatch(getAgentFilterListStart({
        isAffiliate: true
      }))
    } else if (role === ADMIN_ROLES.AGENT) {
      console.log()
    } else {
      if (!isTenant) {
        if (selectedClient) {
          dispatch(getAffiliateAndAgentsListStart({ adminUserId: selectedClient }))
        }
      } else {
        dispatch(getAffiliateAndAgentsListStart({ isTenant: true }))
      }
    }
  }, [selectedClient])

  const handleNext = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleSubmit = (values) => {
    let dataValue = {
      notificationMessage: values?.notificationMessage,
      referenceType: Number(values?.notificationType),
      allowedAffiliates: affiliate,
      allowedAgents: agent || withoutAffiliate,
      allowedUsers: selectedPlayer,
      limit: 15,
      pageNo: 1,
      isTenant
    }

    if (!isTenant) {
      dataValue = { ...dataValue, referenceId: Number(selectedClient) }
    }

    isEdit
      ? dispatch(updateNotificationStart({
          formData: { ...dataValue, notificationId: data?.notificationId }
        }))
      : dispatch(createNotificationStart({
        formData: dataValue
      }))

    navigate(isTenant ? '/tenant/notification' : '/super-admin/notification')
  }

  useEffect(() => {
    setSelectedClient('')
    setSelectedPlayer([])
    dispatch(getUserForNotificationsReset())
  }, [pathname])
  useEffect(() => {
    if (!isTenant) {
      dispatch(getAllClientsStart({
        search: '',
        limit: '',
        orderBy: 'email',
        sort: 'desc'
      }))
    }
  }, [])
  useEffect(() => {
    if (adminDetails?.adminUserId) {
      setSelectedClient(adminDetails.adminUserId)
    }
  }, [adminDetails])

  return {
    t,
    step,
    setStep,
    handleBack,
    handleNext,
    handleSubmit,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    selectedClient,
    setSelectedClient,
    affiliateAndAgentsList,
    clientsFilterData,
    shouldNextDisabled,
    setShouldNextDisabled,
    limit,
    setLimit,
    page,
    setPage,
    userForNotification,
    selectedPlayer,
    setSelectedPlayer,
    navigate,
    totalPages,
    role,
    agentOptions,
    affiliateOptions,
    withoutAffiliate,
    setWithoutAffiliate,
    agentWithoutAffiliate,
    client,
    getSingleNotification,
    setDefaultAffiliateOptions,
    setDefaultAgentWithoutAffiliateOptions,
    setDefaultAgentOptions,
    defaultAgentOptions,
    defaultAgentWithoutAffiliateOptions,
    defaultAffiliateOptions,
    userLoading,
    clientLoading,
    setAgentWithoutAffiliate,
    setAgentWithAffiliate,
    tempSelectedPlayer,
    setTempSelectedPlayer,
    search,
    setSearch,
    debounceFn,
    inputRef
  }
}

export default useCreateNotification
