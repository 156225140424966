import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllCasinoGamesStart,
  getCasinoThemesStart,
  getSpecificCasinoGameStart,
  getSuperAdminGameSubCategoryStart,
  updateActionStart,
  getSuperAdminGameCategoryStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllClientsStart } from '../../../../store/redux-slices/tenants'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { toast } from '../../../../components/Toast'
import { toast as toastify } from 'react-toastify'
import gameImages from '../../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'
import { updateCasinoHideStatusStart } from '../../../../store/redux-slices/superAdminSettings'

const useCasinoGamesListing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [selectedDefaultRtpGames, setDefaultRtpSelectedGames] = useState([])
  const [userStatusData, setUserStatusData] = useState(false)
  const [defaultRtp, setRtp] = useState(true)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedSubCategoryId, setSelectedSubCategoryId] =
    useState('')
  const [categoryId, setCategoryId] = useState('')

  const [show, setShow] = useState(false)
  const [showBulkUpdateForm, setShowBulkUpdateForm] = useState(false)
  const [showCustomizeSystemRtpForm, setShowCustomizeSystemRtpForm] = useState(false)
  const [data, setData] = useState()
  const [type, setType] = useState('')
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const [showGameIds, setShowGameIds] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [isHideOpen, setIsHideOpen] = useState(false)
  const [casinoHideData, setCasinoHideData] = useState(null)
  const { updateLoading } = useSelector(
    (state) => state.superAdminSettings
  )
  const { gameCategory } = useSelector((state) => state.superAdminCasino)

  const handleCasinoHideYes = () => {
    dispatch(updateCasinoHideStatusStart({
      onSuccess: () => {
        handleCasinoHideClose()
        fetchData()
      },
      formData: casinoHideData
    }))
  }
  const handleCasinoGameHideOpen = (data) => {
    setIsHideOpen(true)
    setCasinoHideData(data)
  }
  const handleCasinoHideClose = () => {
    setIsHideOpen(false)
    setCasinoHideData(null)
  }
  const { loading, casinoGamesData, gameSubCategory, allProviders } = useSelector(
    (state) => state.superAdminCasino
  )
  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const getProviderName = (id) =>
    allProviders?.rows.find((val) => val.masterCasinoProviderId === id)?.name

  const getImageByGameCategory = (categoryName) => {
    switch (categoryName) {
      case 'baccarat':
        return gameImages.baccaratGame

      case 'slot':
        return gameImages.slotGame

      case 'video slots':
        return gameImages.liveGame

      case 'blackjack':
        return gameImages.blackjackGame

      case 'roulette':
        return gameImages.rouletteGame

      case 'gamble':
        return gameImages.gambleGame

      case 'poker':
        return gameImages.pokerGame

      case 'scratchcard':
        return gameImages.scratchCardGame

      case 'live games':
        return gameImages.gambleGame

      default:
        return gameImages.gambleGame
    }
  }
  useEffect(() => {
    dispatch(
      getSuperAdminGameSubCategoryStart({
        limit: '',
        pageNo: '',
        gameCategoryId: '',
        search: '',
        isActive: '',
        tenantId: ''

      })
    )
    dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
    dispatch(getAllClientsStart({
      search: '',
      limit: 100,
      pageNo: 1,
      orderBy: 'email',
      sort: 'desc'
    }))
    dispatch(getCasinoThemesStart({ isTenant: false }))
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
  }, [getItem('language')])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllCasinoGamesStart({
              limit,
              pageNo: page,
              casinoCategoryId: selectedSubCategoryId,
              search,
              isActive: active,
              tenantId: '',
              selectedProvider,
              languageCode: getItem('language'),
              defaultRtp,
              categoryId
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const fetchData = () => {
    dispatch(
      getAllCasinoGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId: selectedSubCategoryId,
        categoryId,
        search,
        isActive: active,
        tenantId: '',
        selectedProvider,
        languageCode: getItem('language'),
        defaultRtp
      })
    )
  }
  useEffect(() => {
    fetchData()
  }, [limit, active, page, selectedSubCategoryId, defaultRtp, selectedProvider, categoryId, getItem('language')])

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '', search: '', tenantId: '' }))
  }, [])

  const handleAction = async ({
    actionType,
    gameId,
    activeStatus,
    casinoName,
    masterCasinoProviderId,
    masterCasinoGameId
  }) => {
    dispatch(
      updateActionStart({
        data: {
          actionType: 'update-status',
          gameId,
          activeStatus,
          casinoName,
          masterCasinoProviderId,
          limit,
          pageNo: page,
          active,
          selectedSubCategoryId,
          selectedProvider,
          defaultRtp,
          masterCasinoGameId
        },
        onSuccess: () => {
          toast('Status Updated successfully', 'success')
          setIsStatusModalOpen(false)
          fetchData()
        }
      })
    )
  }

  const handleClose = () => {
    setShow(false)
    setShowBulkUpdateForm(false)
    setShowCustomizeSystemRtpForm(false)
    setDefaultRtpSelectedGames([])
  }
  const handleShow = (type, data) => {
    dispatch(
      getSpecificCasinoGameStart({
        gameId: data?.identifier
      })
    )
    setType(type)
    setData(data)
    setShow(true)
  }
  const code = getItem('language')

  const handleBulkUpdate = () => {
    if (selectedDefaultRtpGames?.length <= 1) {
      toastify.info(t('selectMoreThanOne'))
    } else {
      setShowBulkUpdateForm(true)
    }
  }

  const handleCategoryProviderBasisDefaultRtpUpdate = () => {
    setShowCustomizeSystemRtpForm(true)
  }

  return {
    updateLoading,
    isHideOpen,
    handleCasinoHideYes,
    handleCasinoGameHideOpen,
    handleCasinoHideClose,
    t,
    getImageByGameCategory,
    limit,
    page,
    selectedSubCategoryId,
    show,
    data,
    type,
    setLimit,
    setPage,
    setSelectedSubCategoryId,
    gameSubCategory,
    casinoGamesData,
    totalPages,
    handleClose,
    handleShow,
    loading,
    search,
    setSearch,
    active,
    setActive,
    getProviderName,
    setSelectedProvider,
    selectedProvider,
    showGameIds,
    setShowGameIds,
    handleAction,
    isStatusModalOpen,
    setIsStatusModalOpen,
    userStatusData,
    setUserStatusData,
    code,
    defaultRtp,
    setRtp,
    fetchData,
    selectedDefaultRtpGames,
    setDefaultRtpSelectedGames,
    handleBulkUpdate,
    showBulkUpdateForm,
    showCustomizeSystemRtpForm,
    setShowCustomizeSystemRtpForm,
    handleCategoryProviderBasisDefaultRtpUpdate,
    gameCategory,
    categoryId,
    setCategoryId
  }
}

export default useCasinoGamesListing
