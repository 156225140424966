import React, { useState } from 'react'
import { errorLogTable } from './constant'
import { Table, Button } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import { PrettyJsonPrinter } from '../ConfirmationModal'
import Trigger from '../OverlayTrigger'
// import { formatNumber } from '../../utils/storageUtils'

const ErrorLogTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t,
  isWalletLogs
}) => {
  const [show, setShow] = useState(false)
  const [jsonData, setJsonData] = useState({})
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {errorLogTable(isWalletLogs).map((h, idx) => (
              <th key={h.label}>
                {t(`${h.value}`)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        {/* {
                    "logType": "WITHDRAW",
                    "description": "WITHDRAW - Completed",
                    "transactionType": "REJECT",
                    "userId": "18",
                    "walletRequestId": "98",
                    "tenantId": "1",
                    "superAdminId": null,
                    "previousData": {
                        "amount": 0,
                        "ownerId": 18,
                        "withdrawableAmount": 5000,
                        "nonWithdrawableAmount": 898,
                        "depositTypeTotalAmount": {
                            "REOCCURRING_DEPOSIT": 140
                        },
                        "requestProcessingAmount": 10000
                    },
                    "updatedData": null,
                    "createdAt": "2024-12-18T13:34:33.476Z",
                    "casinoTransactionId": null,
                    "user": {
                        "username": "v2p3"
                    },
                    "tenant": {
                        "name": "Yuji Itadori"
                    }
                }, */}
        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && tableData &&
            tableData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      {item?.user?.username || '-'}
                    </td>
                    <td>
                      {(isWalletLogs ? item?.logType || '-' : item?.errorCode) || 'NA'}
                    </td>
                    <Trigger message={isWalletLogs ? item?.description || '-' : item?.errorMessage || '-'}>
                      <td>

                        {isWalletLogs ? item?.description || '-' : item?.errorMessage || '-'}

                      </td>
                    </Trigger>
                    {
                      isWalletLogs &&
                        <>
                          <td>
                            {item?.casinoTransactionId || '-'}
                          </td>
                          <td>
                            {item?.transactionType || '-'}
                          </td>
                          <td>
                            {item?.walletRequestId || '-'}
                          </td>
                        </>
                    }
                    {!isWalletLogs &&
                      <td>
                        <Button
                          className='actionButton'
                          onClick={() => {
                            setShow(true)
                            setJsonData({ Body: item?.requestBody, Head: item?.requestHeaders })
                          }}
                        >
                          {t('view')}
                        </Button>
                      </td>}
                    {
                      isWalletLogs && (
                        <>
                          <td>
                            <Button
                              className='actionButton'
                              onClick={() => {
                                setShow(true)
                                setJsonData({ Body: item?.previousData })
                              }}
                            >
                              {t('view')}
                            </Button>

                          </td>
                          <td>
                            <Button
                              className='actionButton'
                              onClick={() => {
                                setShow(true)
                                setJsonData({ Body: item?.updatedData })
                              }}
                            >
                              {t('view')}
                            </Button>
                          </td>
                        </>
                      )
                    }
                    {!isWalletLogs &&
                      <td>
                        <span title={item?.requestUrl || ''}>
                          {t(`${item?.requestUrl || 'NA'}`)}
                        </span>
                      </td>}
                    <td>
                      {t(`${moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || 'NA'}`)}
                    </td>
                    {
                      isWalletLogs &&
                        <td>
                          {item?.tenant?.name || '-'}
                        </td>

                    }
                  </tr>
                )
              }
            )}
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('NoDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0
        ? (
          <PaginationComponent
            page={tableData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={tableData?.count}
          />
          )
        : <></>}

      {show &&
        <PrettyJsonPrinter
          setShow={setShow}
          show={show}
          data={jsonData}
        />}

    </div>

  )
}
export default ErrorLogTable
