
export const transactionType = [
  { label: 'Win', value: 'win' },
  { label: 'Bet', value: 'bet' },
  { label: 'Lost', value: 'lost' }
]

export const statusType = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Complete', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'RollBack', value: 'rollback' }
]
