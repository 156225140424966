import React from 'react'
import { casinoReportingTable } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import Trigger from '../OverlayTrigger'
import { formatNumber } from '../../utils/storageUtils'
const CasinoReportingTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t,
  isV2
}) => {
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {casinoReportingTable(isV2).map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
    !loading && tableData?.rows &&
    tableData?.rows?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{(page - 1) * limit + index + 1}</td>
          {isV2
            ? (
              <>
                <td>{item?.username || t('na')}</td>
                <td>{item?.gameMaster?.name || t('na')}</td>
                <td>{item?.actionType || t('na')}</td>
                <td>{item?.casinoTransactionId || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameCategory?.categoryName?.EN || t('na')}</td>
                <td>{item?.gameProvider?.providerName || t('na')}</td>
                <>

                  <td>{formatNumber(item?.nonWithdrawableAmount || 0)} ₩</td>
                  <td>{formatNumber(item?.withdrawableAmount || 0)} ₩</td>
                  <td>{formatNumber(item?.promotionCash || 0)} ₩</td>
                  <td>
                    <div className='d-flex flex-column gap-1'>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('hotTimeBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.hotTimeBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('compensationBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.compensationBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('paybackBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.paybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('dailyPaybackBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.dailyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('weeklyPaybachBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.weeklyPaybachBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('biWeeklyPayBackBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.biWeeklyPayBackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('monthlyPaybackBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.monthlyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('rackbackBonus')}:
                        </span>
                        <b title={item?.bonusDetails}> {item?.bonusDetails?.rackbackBonus ?? 0} ₩</b>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column gap-1'>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutHotTimeBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutHotTimeBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutCompensationBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutCompensationBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutPaybackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutPromotionCash')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutPromotionCash ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutDailyPaybackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutDailyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutWeeklyPaybackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutWeeklyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutBiWeeklyPaybackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutBiWeeklyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutMonthlyPaybackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutMonthlyPaybackBonus ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('payoutRackbackBonus')}:
                        </span>
                        <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutRackbackBonus ?? 0} ₩</b>
                      </div>
                    </div>
                  </td>
                  <td>{formatNumber(item?.nonWithdrawableRollAmount || 0)} ₩</td>
                  <td>
                    <div className='d-flex flex-column gap-1'>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('bonusCash')}:
                        </span>
                        <b title={item?.bonusRollDetails}> {item?.bonusRollDetails?.bonusCash ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('bonusPayout')}:
                        </span>
                        <b title={item?.bonusRollDetails}> {item?.bonusRollDetails?.bonusPayout ?? 0} ₩</b>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column gap-1'>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('promotionCash')}:
                        </span>
                        <b title={item?.promotionRollDetails}> {item?.promotionRollDetails?.promotionCash ?? 0} ₩</b>
                      </div>
                      <div className='d-flex align-items-center gap-1'>
                        <span className='flex-title'>
                          {t('promotionPayout')}:
                        </span>
                        <b title={item?.promotionRollDetails}> {item?.promotionRollDetails?.promotionPayout ?? 0} ₩</b>
                      </div>
                    </div>
                  </td>
                </>
                <td>{formatNumber(item?.seasonalBenefit || 0)} ₩</td>
                <td>{formatNumber(item?.voucherAmount || 0)} ₩</td>
                <td>{formatNumber(item?.giftCardAmount || 0)} ₩</td>
                <td>{formatNumber(item?.beforeBalance) || t('na')} ₩</td>
                <td>{formatNumber(item?.afterBalance) || t('na')} ₩</td>
                <td>{item?.createdAt ? moment(item?.createdAt).format('MM-DD-YYYY') : t('na')}</td>
                <td>{item?.createdAt ? moment(item?.createdAt).format('HH:mm:ss') : t('na')}</td>
              </>
              )
            : (
              <>
                <td>{item?.casinoTransactions?.username || t('na')}</td>
                <td>{item?.gameMaster?.name || t('na')}</td>
                {/* <td>{item?.actionType || t('na')}</td>
                <td>{item?.casinoTransactionId || t('na')}</td> */}
                <td>{item?.gameMaster?.MasterGameCategory?.categoryName?.EN || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || t('na')}</td>
                <td>{item?.gameProvider?.providerName || t('na')}</td>
                <td>{formatNumber(item?.amount + item?.nonCashAmount + item?.bonus) + ' ₩' || t('na')} ₩</td>
                <td>
                  <Trigger message={item?.superadminNotes}>
                    <div>{item?.superadminNotes ?? '-'}</div>
                  </Trigger>
                </td>
                <td>
                  <Trigger message={item?.seniorNotes}>
                    <div>{item?.seniorNotes ?? '-'}</div>
                  </Trigger>
                </td>
                <td>{item?.actionId || t('na')}</td>
                <td>{moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss') || t('na')}</td>
              </>
              )}
        </tr>
      )
    })
  }
          {!loading && tableData?.count === 0 && (
            <tr>
              <td colSpan={isV2 ? 13 : 12} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>

      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
    </div>
  )
}
export default CasinoReportingTable
