import { takeLatest, put } from 'redux-saga/effects'
import {
  cancelDocumentRequest,
  getDocumentLabel,
  updateDocumentLabel,
  createDocumentLabel,
  requestDocument,
  getloyaltyLevel,
  updateloyaltyLevel,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  updateSAUserStatus,
  deleteBannerSuperAdmin,
  getAllNotification,
  getAllReadReciept,
  createNotification,
  updateNotificationStatus,
  userCreate,
  markUnderInspection,
  getUserInspection,
  getTemplateList,
  getSeasonList,
  createSeason,
  createTemplate,
  getRanks,
  getSeasonDetail,
  getTemplateDetail,
  getAllTemplateList,
  deleteSeason,
  updateTemplate,
  updateSeason,
  updateSeasonStatus,
  deleteTemplate,
  getRollingCompetetionList,
  createAndUpdateRollingCompetion,
  updateRollingCompetionStatus,
  deleteRollingCompetetion,
  getRollingList,
  getAllEvents,
  updateEventStatus,
  deleteEvent,
  createAndUpdateEvent,
  updateNotification,
  getSpecificNotification,
  updateCasinoGamesHideStatus,
  updatetV2WithdrawalTransaction,
  getV2WithdrawalTransaction,
  updateAnonymityStatus,
  migratePlayerV2
} from '../../utils/apiCalls'
import {
  updatetV2WithdrawalTransactionStart,
  updatetV2WithdrawalTransactionComplete,
  getV2WithdrawalTransactionDataStart,
  getV2WithdrawalTransactionDataSuccess,
  getV2WithdrawalTransactionDataFailure,
  updateNotificationFailure,
  updateNotificationSuccess,
  updateNotificationStart,
  getspecificNotificationStart,
  getspecificNotificationSuccess,
  getspecificNotificationFailure,
  getRollingListStart,
  getRollingListSuccess,
  getRollingListFailure,
  deleteRollingStart,
  deleteRollingComplete,
  updateRollingStatusStart,
  updateRollingStatusComplete,
  createAndUpdateRollingStart,
  createAndUpdateRollingComplete,
  updateSeasonStatusStart,
  updateSeasonStatusComplete,
  updateSeasonListStart,
  updateSeasonListComplete,
  updateTemplateListStart,
  updateTemplateListComplete,
  deleteSeasonStart,
  deleteSeasonComplete,
  getAllTemplateListStart,
  getAllTemplateListSuccess,
  getAllTemplateListFailure,
  getSeasonDetailStart,
  getSeasonDetailSuccess,
  getSeasonDetailFailure,
  getTemplateDetailStart,
  getTemplateDetailSuccess,
  getTemplateDetailFailure,
  getRanksStart,
  getRanksSuccess,
  getRanksFailure,
  getDocumentLabelStart,
  getDocumentLabelSuccess,
  getDocumentLabelFailure,
  updateDocumentLabelStart,
  updateDocumentLabelComplete,
  createDocumentLabelStart,
  createDocumentLabelComplete,
  updateDocumentStart,
  updateDocumentComplete,
  getloyaltyLevelStart,
  getloyaltyLevelSuccess,
  getloyaltyLevelFailure,
  updateloyaltyLevelStart,
  updateloyaltyLevelComplete,
  getAllSABannersStart,
  getAllSABannersSuccess,
  getAllSABannersFailure,
  uploadSABannerStart,
  uploadSABannerComplete,
  updateSABannerStart,
  updateSABannerComplete,
  updateSAUserStatusStart,
  updateSAUserStatusCompleted,
  updateSAUserStatusFailure,
  deleteBannerSuperAdminStart,
  deleteBannerSuperAdminSuccess,
  deleteBannerSuperAdminFailure,
  getAllNotificationStart,
  getAllNotificationSuccess,
  getAllNotificationFailure,
  getAllReadRecieptStart,
  getAllReadRecieptSuccess,
  getAllReadRecieptFailure,
  createNotificationStart,
  createNotificationSuccess,
  createNotificationFailure,
  updateNotificationStatusStart,
  updateNotificationStatusSuccess,
  updateNotificationStatusFaliure,
  userCreateStart,
  userCreateComplete,
  markUnderInspectionStart,
  markUnderInspectionComplete,
  getUserInspectionStart,
  getUserInspectionSuccess,
  getUserInspectionFailure,
  getTemplateListStart,
  getTemplateListSuccess,
  getTemplateListFailure,
  getSeasonListStart,
  getSeasonListSuccess,
  getSeasonListFailure,
  createSeasonListStart,
  createSeasonListComplete,
  createTemplateListStart,
  createTemplateListComplete,
  deleteTemplateComplete,
  deleteTemplateStart,
  getRollingReportingListStart,
  getRollingReportingListFailure,
  getRollingReportingListSuccess,
  getAllEventsStart,
  getAllEventsFailure,
  getAllEventsSuccess,
  createEventStart,
  createEventSuccess,
  createEventFailure,
  updateEventStatusStart,
  updateEventStatusSuccess,
  updateEventStatusFailure,
  updateEventStart,
  updateEventFailure,
  updateEventSuccess,
  deleteEventStart,
  deleteEventFailure,
  deleteEventSuccess,
  updateCasinoHideStatusStart,
  updateCasinoHideStatusComplete,
  updateAnonymityStatusStart,
  updateAnonymityStatusSuccess,
  updateAnonymityStatusFailure,
  migratePlayerV2Start,
  migratePlayerV2Success,
  migratePlayerV2Failure
} from '../redux-slices/superAdminSettings'
import { toast } from '../../components/Toast'
import { getSAUserDocumentStart, getUserStart } from '../redux-slices/fetchData'
import { getAllTenantSpecificNotificationStart, getTenantStart } from '../redux-slices/tenants'
import { t } from 'i18next'
import { getAllReferredUserlStart } from '../redux-slices/referrals'
import { serialize } from 'object-to-formdata'
import { getSecurityReportStart } from '../redux-slices/admins'

export default function * superAdminSettingsWatcher () {
  yield takeLatest(updateAnonymityStatusStart.type, updateAnonymityStatusWorker)
  yield takeLatest(updateCasinoHideStatusStart.type, updateCasinoHideStatusWorker)
  yield takeLatest(updatetV2WithdrawalTransactionStart.type, updatetV2WithdrawalTransactionWorker)
  yield takeLatest(getV2WithdrawalTransactionDataStart.type, getV2WithdrawalTransactionDataWorker)
  yield takeLatest(getDocumentLabelStart.type, getDocumentLabelWorker)
  yield takeLatest(getRollingListStart.type, getRollingListWorker)
  yield takeLatest(deleteRollingStart.type, deleteRollingWorker)
  yield takeLatest(updateRollingStatusStart.type, updateRollingStatusWorker)
  yield takeLatest(createAndUpdateRollingStart.type, createAndUpdateRollingWorker)
  yield takeLatest(userCreateStart.type, userCreateWorker)
  yield takeLatest(getSeasonDetailStart.type, getSeasonDetailWorker)
  yield takeLatest(getTemplateDetailStart.type, getTemplateDetailWorker)
  yield takeLatest(getTemplateListStart.type, getTemplateListWorker)
  yield takeLatest(getAllTemplateListStart.type, getAllTemplateListWorker)
  yield takeLatest(deleteSeasonStart.type, deleteSeasonWorker)
  yield takeLatest(deleteTemplateStart.type, deleteTemplateWorker)
  yield takeLatest(getRanksStart.type, getRanksWorker)
  yield takeLatest(createSeasonListStart.type, createSeasonListWorker)
  yield takeLatest(createTemplateListStart.type, createTemplateListWorker)
  yield takeLatest(updateTemplateListStart.type, updateTemplateListWorker)
  yield takeLatest(updateSeasonListStart.type, updateSeasonListWorker)
  yield takeLatest(updateSeasonStatusStart.type, updateSeasonStatusWorker)
  yield takeLatest(getSeasonListStart.type, getSeasonListWorker)
  yield takeLatest(markUnderInspectionStart.type, markUnderInspectionWoker)
  yield takeLatest(updateDocumentLabelStart.type, updateDocumentLabelWorker)
  yield takeLatest(createDocumentLabelStart.type, createDocumentLabelWorker)
  yield takeLatest(updateDocumentStart.type, updateDocumentWorker)
  yield takeLatest(getloyaltyLevelStart.type, getloyaltyLevelWorker)
  yield takeLatest(updateloyaltyLevelStart.type, updateloyaltyLevelWorker)
  yield takeLatest(getAllSABannersStart.type, getAllSABannersWorker)
  yield takeLatest(updateSABannerStart.type, updateSABannerWorker)
  yield takeLatest(uploadSABannerStart.type, uploadSABannerWorker)
  yield takeLatest(updateSAUserStatusStart.type, updateSAUserUpdateWorker)
  yield takeLatest(deleteBannerSuperAdminStart.type, deleteBannerSuperAdminWorker)
  yield takeLatest(getAllNotificationStart.type, getAllNotificationWorker)
  yield takeLatest(getAllReadRecieptStart.type, getAllReadRecieptWorker)
  yield takeLatest(createNotificationStart.type, createNotificationWorker)
  yield takeLatest(updateNotificationStatusStart.type, updateNotificationStatusWorker)
  yield takeLatest(getUserInspectionStart.type, getUserInspectionWorker)
  yield takeLatest(getRollingReportingListStart.type, getRollingReportingListWorker)
  yield takeLatest(getAllEventsStart.type, getAllEventsWorker)
  yield takeLatest(createEventStart.type, createEventWorker)
  yield takeLatest(updateEventStatusStart.type, updateEventStatusWorker)
  yield takeLatest(updateEventStart.type, updateEventWorker)
  yield takeLatest(deleteEventStart.type, deleteEventWorker)
  yield takeLatest(updateNotificationStart.type, updateNotificationWorker)
  yield takeLatest(getspecificNotificationStart.type, getspecificNotificationWorker)
  yield takeLatest(migratePlayerV2Start.type, migratePlayerToV2)
}

function * updateAnonymityStatusWorker (action) {
  try {
    const { isAnonymousFlag, isTenant, userId } = action.payload
    const res = yield updateAnonymityStatus({ isAnonymousFlag, userId }, isTenant)
    if (res.status === 200) {
      yield put(updateAnonymityStatusSuccess())
      yield toast(res?.data?.data?.message, 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAnonymityStatusFailure())
  }
}
function * updatetV2WithdrawalTransactionWorker (action) {
  try {
    const { onSuccess } = action.payload
    const { status } = yield updatetV2WithdrawalTransaction(action?.payload?.formData)
    if (status === 200) {
      yield toast(`${t('updateSuccess')}`, 'success')
      yield onSuccess()
    }
    yield put(updatetV2WithdrawalTransactionComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updatetV2WithdrawalTransactionComplete())
  }
}
function * updateCasinoHideStatusWorker (action) {
  try {
    const { onSuccess } = action.payload
    const { status } = yield updateCasinoGamesHideStatus(action?.payload?.formData)
    if (status === 200) {
      yield toast(`${t('updateSuccess')}`, 'success')
      yield onSuccess()
    }
    yield put(updateCasinoHideStatusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateCasinoHideStatusComplete(e?.response?.data?.errors[0]?.description))
  }
}
function * getV2WithdrawalTransactionDataWorker (action) {
  try {
    const { data } = yield getV2WithdrawalTransaction(action?.payload)
    yield put(getV2WithdrawalTransactionDataSuccess(data?.data?.promotionCashDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getV2WithdrawalTransactionDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getspecificNotificationWorker (action) {
  try {
    const { data } = yield getSpecificNotification(action?.payload?.formData)
    yield put(getspecificNotificationSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getspecificNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateNotificationWorker (action) {
  try {
    const { data } = yield updateNotification(action?.payload?.formData)
    yield put(updateNotificationSuccess(data?.data?.eventCompetition))
    toast('update Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAllEventsWorker (action) {
  try {
    const { data } = yield getAllEvents(action?.payload?.formData)
    yield put(getAllEventsSuccess(data?.data?.eventCompetition))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllEventsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createEventWorker (action) {
  try {
    const {
      formData,
      isUpdate,
      handleClose
    } = action?.payload
    yield createAndUpdateEvent({ ...action?.payload, formData: serialize(formData) })
    yield put(createEventSuccess())
    handleClose()
    yield put(getAllEventsStart({
      limit: 15,
      pageNo: 1
    }))
    if (isUpdate) {
      yield toast(`${t('updateSuccess')}`, 'success')
    } else {
      yield toast(`${t('createSuccess')}`, 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createEventFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateEventStatusWorker (action) {
  try {
    const {
      handleClose
    } = action?.payload
    yield updateEventStatus(action.payload)
    yield put(updateEventStatusSuccess())
    handleClose()
    yield toast(`${t('updateSuccess')}`, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateEventStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateEventWorker (action) {
  try {
    const {
      formData,
      handleClose
    } = action?.payload
    const { data } = yield createAndUpdateEvent(formData)
    if (data.status === 200) {
      yield put(updateEventSuccess())
      handleClose()
      yield toast(`${t('updateSuccess')}`, 'success')
    }
    yield put(getAllEventsStart({
      limit: 15,
      pageNo: 1
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateEventFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * deleteEventWorker (action) {
  try {
    const { formData, handleClose } = action && action?.payload
    yield deleteEvent({ formData })
    yield put(deleteEventSuccess())
    handleClose()
    yield toast(`${t('updateSuccess')}`, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteEventFailure())
  }
}

function * getRollingReportingListWorker (action) {
  try {
    const { data } = yield getRollingList(action?.payload)
    yield put(getRollingReportingListSuccess(data?.data?.userCompetitionLeaderBoard))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRollingReportingListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRollingListWorker (action) {
  try {
    const { data } = yield getRollingCompetetionList(action?.payload)
    yield put(getRollingListSuccess(data?.data?.rollingCompetition))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRollingListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * deleteRollingWorker (action) {
  try {
    const { formData, handleClose } = action && action?.payload
    const res = yield deleteRollingCompetetion({ formData })
    if (res.status === 200) {
      yield put(deleteRollingComplete())
      handleClose()
      yield toast(`${t('updateSuccess')}`, 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteRollingComplete())
  }
}
function * updateRollingStatusWorker (action) {
  try {
    const { formData, handleClose } = action && action?.payload
    const res = yield updateRollingCompetionStatus({ formData })
    if (res.status === 200) {
      yield put(updateRollingStatusComplete())
      handleClose()
      yield toast(`${t('updateSuccess')}`, 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateRollingStatusComplete())
  }
}
function * createAndUpdateRollingWorker (action) {
  try {
    const {
      formData,
      isUpdate,
      handleClose
    } = action?.payload
    const res = yield createAndUpdateRollingCompetion({ ...action?.payload, formData: serialize(formData) })
    if (res?.status === 200) {
      yield put(createAndUpdateRollingComplete())
      handleClose()
      if (isUpdate) {
        yield toast(`${t('updateSuccess')}`, 'success')
      } else {
        yield toast(`${t('createSuccess')}`, 'success')
      }
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createAndUpdateRollingComplete())
  }
}

function * getUserInspectionWorker (action) {
  try {
    const {
      startDate,
      endDate,
      tenantId,
      userId,
      contentFilter
    } = action && action.payload

    const { data } = yield getUserInspection({
      startDate,
      endDate,
      tenantId,
      userId,
      ...contentFilter
    })
    yield put(getUserInspectionSuccess(data?.data?.userTransactionDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserInspectionFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRanksWorker (action) {
  try {
    const { data } = yield getRanks()
    yield put(getRanksSuccess(data?.data?.ranks?.rows))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRanksFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * deleteSeasonWorker (action) {
  try {
    const { formData, handleClose } = action.payload
    const res = yield deleteSeason(formData)
    if (res.status === 200) {
      yield put(deleteSeasonComplete())
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteSeasonComplete())
  }
}
function * deleteTemplateWorker (action) {
  try {
    const { formData, handleClose } = action.payload
    const res = yield deleteTemplate(formData)
    if (res.status === 200) {
      yield put(deleteTemplateComplete())
      handleClose()
    }
    toast('Delete successful', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteTemplateComplete())
  }
}
function * getSeasonDetailWorker (action) {
  try {
    const { data } = yield getSeasonDetail(action.payload)
    yield put(getSeasonDetailSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSeasonDetailFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTemplateDetailWorker (action) {
  try {
    const { data } = yield getTemplateDetail(action.payload)
    yield put(getTemplateDetailSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTemplateDetailFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTemplateListWorker (action) {
  try {
    const { data } = yield getTemplateList(action.payload)
    yield put(getTemplateListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTemplateListFailure(e?.response?.data?.errors[0]?.description))
    yield put(getTemplateListSuccess({}))
  }
}
function * getAllTemplateListWorker (action) {
  try {
    const { data } = yield getAllTemplateList(action.payload)
    yield put(getAllTemplateListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllTemplateListFailure(e?.response?.data?.errors[0]?.description))
    yield put(getAllTemplateListSuccess({}))
  }
}
function * getSeasonListWorker (action) {
  try {
    const { data } = yield getSeasonList(action.payload)
    yield put(getSeasonListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSeasonListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createSeasonListWorker (action) {
  try {
    const { formData, handleClose } = action.payload
    const transformData = serialize(formData)
    const res = yield createSeason(transformData)
    if (res.status === 200) {
      yield put(createSeasonListComplete())
      yield toast(`${t('createSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createSeasonListComplete())
  }
}
function * updateSeasonListWorker
(action) {
  try {
    const { formData, handleClose } = action.payload
    const transformData = serialize(formData)
    const res = yield updateSeason(transformData)
    if (res.status === 200) {
      yield put(updateSeasonListComplete())
      yield toast(`${t('updateSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSeasonListComplete())
  }
}
function * updateSeasonStatusWorker
(action) {
  try {
    const { formData, handleClose } = action.payload
    const res = yield updateSeasonStatus(formData)
    if (res.status === 200) {
      yield put(updateSeasonStatusComplete())
      yield toast(`${t('updateSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSeasonStatusComplete())
  }
}
function * createTemplateListWorker (action) {
  try {
    const { formData, handleClose } = action.payload
    const res = yield createTemplate(formData)
    if (res.status === 200) {
      yield put(createTemplateListComplete())
      yield toast(`${t('createSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createTemplateListComplete())
  }
}
function * updateTemplateListWorker (action) {
  try {
    const { formData, handleClose } = action.payload
    const res = yield updateTemplate(formData)
    if (res.status === 200) {
      yield put(updateTemplateListComplete())
      yield toast(`${t('updateSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTemplateListComplete())
  }
}
function * deleteBannerSuperAdminWorker (action) {
  try {
    const { data, handleClose } = action.payload
    const response = yield deleteBannerSuperAdmin({ data: data })
    if (response?.status === 200) {
      handleClose()
    }
    yield put(deleteBannerSuperAdminSuccess())
    yield toast(response?.data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteBannerSuperAdminFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getDocumentLabelWorker (action) {
  try {
    const { userId } = action && action.payload

    const { data } = yield getDocumentLabel(userId)

    yield put(getDocumentLabelSuccess(data?.data?.documentLabel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDocumentLabelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateDocumentLabelWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateDocumentLabel(data)

    yield put(updateDocumentLabelComplete())
    yield toast('Labels Updated SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateDocumentLabelComplete())
  }
}

function * createDocumentLabelWorker (action) {
  try {
    const { data } = action && action.payload

    yield createDocumentLabel(data)

    yield put(createDocumentLabelComplete())
    yield toast('Label Created SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createDocumentLabelComplete())
  }
}

function * updateDocumentWorker (action) {
  try {
    const { data, isRequested } = action && action.payload

    isRequested ? yield requestDocument(data) : yield cancelDocumentRequest(data)

    yield updateDocumentComplete()
    yield put(getUserStart({ userId: data?.userId }))
    yield put(getSAUserDocumentStart({ userId: data?.userId }))
    yield put(getDocumentLabelStart({ userId: data?.userId }))

    yield toast(`Document ${isRequested ? 'Requested' : 'UnRequested'} Successfully`, 'success')
  } catch (e) {
    yield updateDocumentComplete()

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getloyaltyLevelWorker (action) {
  try {
    const { isTenant, tenantId } = action && action.payload

    const { data } = yield getloyaltyLevel({ isTenant, tenantId: tenantId || '' })

    yield put(getloyaltyLevelSuccess(data?.data?.loyaltyLevel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getloyaltyLevelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateloyaltyLevelWorker (action) {
  try {
    const { loyaltyLevel, isTenant, tenant = false, tenantId = '' } = action && action.payload

    yield updateloyaltyLevel({ isTenant, data: { loyaltyLevel: loyaltyLevel?.loyaltyLevel, tenantId } })

    yield put(updateloyaltyLevelComplete())

    yield toast('Data Updated SuccessFully', 'success')
    if (tenant) yield put(getTenantStart({ tenantId }))
    else yield put(getloyaltyLevelStart({ isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateloyaltyLevelComplete())
  }
}

function * getAllSABannersWorker (action) {
  try {
    const { limit, pageNo, tenantId } = action && action.payload

    const { data } = yield getAllSABanners({ limit, pageNo, tenantId })

    yield put(getAllSABannersSuccess(data?.data?.banners))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllSABannersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateSABannerWorker (action) {
  try {
    const { data, setShow, setRefetch } = action && action.payload
    const response = yield updateSABanner(data)
    if (response?.status === 200 || response?.status === 201) {
      yield put(updateSABannerComplete())
      yield toast('Banner Updated SuccessFully', 'success')
      setShow(false)
      setRefetch(prev => !prev)
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSABannerComplete())
  }
}

function * uploadSABannerWorker (action) {
  try {
    const { data, setShow, setRefetch } = action && action.payload
    const response = yield uploadSABanner(data)
    if (response?.status === 200) {
      yield put(uploadSABannerComplete())
      yield toast('Banner Uploaded SuccessFully', 'success')
      setShow(false)
      setRefetch(prev => !prev)
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(uploadSABannerComplete())
  }
}

function * updateSAUserUpdateWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateSAUserStatus({ ...data })
    yield toast('User Status Updated SuccessFully', 'success')
    data.isReferral
      ? yield put(getAllReferredUserlStart({
          limit: 15,
          pageNo: 1,
          userId: data.referralUser
        }))
      : yield put(updateSAUserStatusCompleted())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSAUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllNotificationWorker (action) {
  try {
    const {
      limit,
      pageNo,
      referenceType,
      status,
      affiliateId,
      agentId,
      referenceId
    } = action && action.payload
    const { data } = yield getAllNotification({
      limit,
      pageNo,
      referenceType,
      status,
      affiliateId,
      agentId,
      referenceId
    })
    yield put(getAllNotificationSuccess(data?.data?.notifications))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllReadRecieptWorker (action) {
  try {
    const {

      notificationId,
      limit,
      pageNo,
      isRead
    } = action && action.payload
    const { data } = yield getAllReadReciept(
      notificationId,
      limit,
      pageNo,
      isRead
    )
    yield put(getAllReadRecieptSuccess(data?.data?.results))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllReadRecieptFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createNotificationWorker (action) {
  try {
    const {
      formData
    } = action && action.payload
    const isTenant = formData?.isTenant
    const {
      data
    } = yield createNotification(formData)
    yield put(createNotificationSuccess(data?.data?.notifications))
    if (isTenant) {
      yield put(getAllTenantSpecificNotificationStart({
        limit: formData.limit,
        pageNo: formData.pageNo
      }))
    } else {
      yield put(getAllNotificationStart({
        limit: formData.limit,
        pageNo: formData.pageNo
      }))
    }
    yield toast('Notification Created', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateNotificationStatusWorker (action) {
  try {
    const {
      formData,
      setShowConfirmActiveNotificationModal,
      limit,
      pageNo
    } = action && action.payload
    const {
      data
    } = yield updateNotificationStatus(formData)
    yield put(updateNotificationStatusSuccess(data?.data))
    yield toast('Notification Sent', 'success')
    yield put(getAllNotificationStart({
      limit,
      pageNo
    }))
    setShowConfirmActiveNotificationModal(false)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateNotificationStatusFaliure(e?.response?.data?.errors[0]?.description))
  }
}
function * markUnderInspectionWoker (action) {
  try {
    const { formData, fetchData, setShow } = action && action.payload
    const res = yield markUnderInspection({
      formData
    })
    if (res.status === 200) {
      if (!formData?.flag) {
        yield toast(`${t('markedRemovedSuccess')}`, 'success')
      } else {
        yield toast(`${t('markedSuccess')}`, 'success')
      }
      setShow(false)
      fetchData()
    }
    yield put(getSecurityReportStart({
      limit: 15,
      pageNo: 1
    }))
    yield put(markUnderInspectionComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(markUnderInspectionComplete())
  }
}
function * userCreateWorker (action) {
  try {
    const { formData, resetForm, isTenant, isAffiliate, navigate, isAgent } = action && action.payload
    const tempData = { ...formData }

    const encryptedPass = btoa(formData.password)
    const encryptedWithDrawPassWord = btoa(
      formData.withdrawalPassword
    )
    delete tempData.confirmPassword
    delete tempData.confirmPasswordWithdrawal
    if (isTenant) {
      delete tempData.adminId
    }
    const res = yield userCreate({
      formData: {
        ...tempData,
        password: encryptedPass,
        withdrawalPassword: encryptedWithDrawPassWord
      },
      isTenant,
      isAffiliate,
      isAgent
    })
    if (res.status === 200) {
      yield toast(`${t('userCreatedSuccess')}`, 'success')
      resetForm()
      navigate(-1)
    }
    yield put(userCreateComplete())
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'UserNameExists') {
      yield toast(`${t('userNameAlreadyExists')}`, 'error')
    } else if (
      e?.response?.data?.errors[0]?.name === 'UserCountryCodeWithPhone'
    ) {
      yield toast(`${t('UserCountryCodeWithPhoneAlreadyExists')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'TenanatAreBlocked') {
      yield toast(`${t('tenanatAreBlocked')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'NickNameExists') {
      yield toast(`${t('nickNamAlreadyExists')}`, 'error')
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
    }
    yield put(userCreateComplete())
  }
}

function * migratePlayerToV2 (action) {
  try {
    const { onSuccess, setIsMigrated } = action.payload
    const { status } = yield migratePlayerV2({ userId: action?.payload.userId })
    if (status === 200) {
      yield toast(`${t('updateSuccess')}`, 'success')
      setIsMigrated(true)
      yield onSuccess()
    }
    yield put(migratePlayerV2Success())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(migratePlayerV2Failure())
  }
}
