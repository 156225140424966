export const profileConstants = [
  { key: 'firstName', value: 'firstName', edit: true },
  { key: 'lastName', value: 'lastName', edit: true },
  { key: 'email', value: 'email' },
  { key: 'username', value: 'superAdminUsername' },
  { key: 'role', value: 'role', subValue: 'name' }
]

export const profileConstantsTA = [
  { key: 'firstName', value: 'firstName', edit: true },
  { key: 'lastName', value: 'lastName', edit: true },
  { key: 'email', value: 'email' },
  { key: 'phone', value: 'phone', edit: true },
  { key: 'role', value: 'role', subValue: 'name' },
  { key: 'username', value: 'superAdminUsername' }
]
export const affiliateAndAgentConstantsTA = [
  { key: 'email', value: 'email' },
  { key: 'phone', value: 'phone', edit: true },
  { key: 'role', value: 'role', subValue: 'name' },
  { key: 'username', value: 'superAdminUsername' }
]

export const permissionLabel = (label, key) => {
  switch (label) {
    case 'C':
      return 'Create'
    case 'R':
      return 'Read'
    case 'U':
      return 'Update'
    case 'D':
      return 'Delete'
    case 'T':
      return 'Toggle Status'
    case 'A':
      return 'Apply'
    case 'CC':
      return 'Create Custom'
    case 'AB':
      return 'Manage Money'
    case 'SR':
      return 'Limit'
    case 'TE':
      return 'Test Email'
    case 'EV':
      return 'Verify Email'
    case 'UP':
      return 'Reset Password'
    case 'RUI':
      return 'Read Player Info'
    case 'SM':
      return 'Sent Mails'
    default:
      return label
  }
}
