import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllBonusStart } from '../../../../store/redux-slices/admins'
import { updateSABonusStatusStart } from '../../../../store/redux-slices/bonus'
import { useTranslation } from 'react-i18next'
// import { useDidMountEffect } from '../../../../utils/useDidMountEffect'

const useBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const isInitialRender = useDidMountEffect()
  const { t } = useTranslation()
  const { bonusList, loading } = useSelector((state) => state.admins)
  const [filterType, setFilterType] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('')
  const [bonusId, setBonusId] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [bonusTyp, setBonusTyp] = useState('')
  const [search, setSearch] = useState('')
  const [isActive, setIsActive] = useState('')
  const [name, setName] = useState()
  const [showGiveModal, setShowGiveModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showFreeSpinModal, setShowFreeSpinModal] = useState(false)
  const [searchByBonusId, setSearchByBonusId] = useState('')
  const totalPages = Math.ceil(bonusList?.count / limit)

  const handleShow = (id, active, myName) => {
    setBonusId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }
  const handleYes = () => {
    dispatch(
      updateSABonusStatusStart({
        data: {
          isActive: active,
          bonusId
        },
        params: {
          adminId: selectedClient,
          tenantId: selectedPortal,
          limit,
          pageNo: page,
          isActive,
          bonusType: bonusTyp || ''
        }
      })
    )
    setShow(false)
  }

  useEffect(() => {
    dispatch(getAllBonusStart({
      adminId: selectedClient,
      tenantId: selectedPortal,
      limit,
      pageNo: page,
      isActive,
      bonusType: bonusTyp || ''
    }))
  }, [page, selectedClient, selectedPortal, bonusTyp, isActive, limit])

  return {
    navigate,
    loading,
    bonusList,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    handleShow,
    handleYes,
    show,
    setShow,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    bonusTyp,
    setBonusTyp,
    search,
    setSearch,
    isActive,
    setIsActive,
    name,
    showGiveModal,
    setShowGiveModal,
    showDeleteModal,
    setShowDeleteModal,
    showFreeSpinModal,
    setShowFreeSpinModal,
    searchByBonusId,
    setSearchByBonusId,
    filterType,
    setFilterType,
    t
  }
}

export default useBonus
