import * as Yup from 'yup'

export const createCmsSchema = (t) => Yup.object().shape({
  title: Yup.string()
    .required(t('titleRequired')),
  slug: Yup.string()
    .required(t('slugRequired'))
    .min(3, t('slugMinLength'))
    .max(30, t('slugMaxLength'))
    .matches(/^[a-z0-9]+(?:[_-][a-z0-9]+)*$/, t('slugValidFormat'))
})

export const createCmsNewSchema = (t) => Yup.object().shape({
  title: Yup.string()
    .required(t('titleRequired')),
  slug: Yup.string()
    .required(t('slugRequired'))
    .min(3, t('slugMinLength'))
    .max(30, t('slugMaxLength'))
    .matches(/^[a-z0-9]+(?:[_-][a-z0-9]+)*$/, t('slugValidFormat')),
  category: Yup.string()
    .required(t('categoryRequired')),
  selectedClient: Yup.string().required(t('seniorRequired')),
  content: Yup.string().required(t('contentRequired')).matches(/<p\s*>.+<\/p>/, t('contentValidFormat'))
})
