import * as Yup from 'yup'
import { getItem } from '../../../../utils/storageUtils'

export const userSchema = (permission) =>
  Yup.object().shape({
    email:
      permission
        ? Yup
            .string()
            .email('Invalid Email').max(50).required('Email Required')
        : Yup
          .string()
          .nullable(),
    firstName:
      Yup
        .string()
        .min(3, 'Minimum 3 Characters Required')
        .max(50, 'Maximum 50 Characters Allowed')
        .required('First Name Required'),
    lastName: Yup
      .string()
      .min(3, 'Minimum 3 Characters Required')
      .max(50, 'Maximum 50 Characters Allowed')
      .required('Last Name Required'),
    phone: Yup
      .string()
      .nullable(),
    dateOfBirth: Yup
      .date()
      .max(new Date(new Date().getFullYear() - 18,
        new Date().getMonth(), new Date().getDate() - 1), 'Must Be 18 Years Old')
      .required('DOB Required'),
    gender: Yup
      .string()
      .required('Gender Required'),
    username: Yup
      .string()
      .max(20, 'Maximum 20 Characters Allowed')
      .min(3, 'Minimum 3 Characters Required')
      .required('User Name Required'),
    address: Yup
      .string()
      .max(100, 'Maximum 100 Characters Allowed')
      .required('Address Required'),
    city: Yup
      .string()
      .max(50, 'Maximum 50 Characters Allowed')
      .required('City Required'),
    zipCode: Yup
      .string()
      .required('ZipCode Required'),
    currencyCode: Yup
      .string(),
    countryCode: Yup
      .string()
      .nullable()
  })

export const depositSchema = (amount, isWithdraw, t, isV2User) => {
  return Yup.object().shape({
    amount: Yup
      .number()
      .required(t('amountRequired'))
      .positive(t('amountPositive'))
      .min('1', t('minDigits', { label: t('amount'), minNumber: '1' }))
      .test('check-amount', t('amountCheck'), value => {
        return value <= amount
      }),
    currency: Yup
      .string()
      .required(t('currencyRequired')),
    actionSource: isV2User && !isWithdraw
      ? Yup
          .string()
          .required(t('walletTypeRequired'))
      : Yup
        .string()
        .notRequired(),
    rollingPercentage: isV2User && !isWithdraw
      ? Yup
          .number()
          .max(999, t('maximumValue999'))
          .min(1, t('minimumValue0'))
          .required(t('rollingPercentageRequired'))
      : Yup
        .number()
        .notRequired()
  })
}
export const depositSchemaForTenant = (amount, isWithdraw, t, isV2User) => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(t('amountRequired'))
      .positive(t('amountPositive'))
      .test('check-amount', t('amountCheck'), value => {
        return isWithdraw ? value <= amount : true
      })
      .min(1, t('minDigits', { label: t('amount'), minNumber: 1 })),
    // .test('amountLength', t('amountLength'), value => {
    //   return value && value.toString().length <= 7
    // }),
    currency: Yup
      .string()
      .required(t('currencyRequire')),
    actionSource: isV2User
      ? Yup
          .string()
          .required(t('walletTypeRequired'))
      : Yup
        .string()
        .notRequired(),
    rollingPercentage: isV2User && !isWithdraw
      ? Yup
          .number()
          .max(999, t('maximumValue999'))
          .min(1, t('minimumValue0'))
          .required(t('rollingPercentageRequired'))
      : Yup
        .number()
        .notRequired()
  })
}
export const editKycSchema = (t) => Yup.object().shape({
  legalName: getItem('langauge') === 'EN'
    ? Yup
        .string()
        .min(1, t('minCharacters'))
        .matches(/^[\p{L} ]+$/u, t('legalName'))
        .matches(/^\p{L}+( \p{L}+)*$/u, t('legalNameOneSpace'))
        .max(50, t('max50Characters')).required(t('legalNameRequired'))
        .required(t('legalNameRequired'))
    : Yup
      .string()
      .min(1, t('minCharacters'))
      .max(50, t('max50Characters')).required(t('legalNameRequired'))
      .required(t('legalNameRequired')),
  phone: Yup
    .string()
    .min(5, t('min5Numbers'))
    .max(16, t('max16Numbers'))
    .matches(/^\d{5,16}$/, t('enterValidPhone'))
    .required(t('phoneRequired')),
  accountNumber:
    Yup.string()
      .min(8, t('min8Numbers'))
      .max(20, t('max20Characters'))
      .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, t('invalidAccountNumber'))
      .required(t('requiredAccountNumber')),
  bankName:
    Yup.string().trim().required(t('bankNameRequired'))
})
