import {
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  createNotificationStart,
  getAllNotificationStart,
  getAllReadRecieptStart,
  updateNotificationStatusStart
} from '../../../../store/redux-slices/superAdminSettings'
import { getAllClientsStart } from '../../../../store/redux-slices/tenants'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'
import { ADMIN_ROLES } from '../../../../utils/constants'
const initialState = {
  referenceType: '',
  status: '',
  isRead: ''
}
const useNotification = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { t } = useTranslation()
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [notificationId, setNotificatonId] = useState(null)
  const [status, setStatus] = useState(true)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isReadReceiptTable, setIsReadReceiptTable] = useState(false)
  const [creatNotificationShow, setCreateNotificationshow] = useState(false)
  const [tenantToNotify, setTenantToNotify] = useState([])
  const [data, setData] = useState([])
  const [isShowTenant, setShowTenant] = useState(false)
  const [tableDataToShow, setDataToShowAtTenantTable] = useState([])
  const [showConfirmActiveNotification, setShowConfirmActiveNotificationModal] = useState(false)
  const {
    allNotificationList,
    allReadRecieptList,
    loading
  } = useSelector((state) => state.superAdminSettings)

  const {
    clientsFilterData
  } = useSelector(state => state.tenants)

  const totalPages = Math.ceil(allNotificationList?.count / limit)
  const totalPagesReadReciept = Math.ceil(allReadRecieptList?.count / limit)
  const role = getItem('role')
  useEffect(() => {
    if (isReadReceiptTable) {
      dispatch(getAllReadRecieptStart({
        notificationId: notificationId,
        limit,
        pageNo: page,
        ...filterData
      }))
    }
  }, [limit, page, filterData])
  useEffect(() => {
    dispatch(getAllNotificationStart({
      ...filterData,
      limit,
      pageNo: page,
      affiliateId: affiliate || '',
      agentId: agent || '',
      referenceId: selectedClient || ''
    }))
  }, [limit, page, filterData, affiliate, agent, selectedClient])
  useEffect(() => {
    if (role === ADMIN_ROLES.SUPERADMIN) {
      dispatch(getAllClientsStart({
      }))
    }
  }, [])

  const handleViewReadReciept = (notificationId) => {
    setNotificatonId(notificationId)
    dispatch(getAllReadRecieptStart({
      notificationId: notificationId,
      limit,
      pageNo: page,
      ...filterData
    }))
    setIsReadReceiptTable((prev) => !prev.isReadReceiptTable)
  }

  const handleCreateNotification = (formData) => {
    dispatch(createNotificationStart({
      formData:
      {
        ...formData,
        referenceType: Number(formData?.referenceType),
        limit,
        pageNo: page
      }
    }))
  }

  const handleActiveNotification = () => {
    dispatch(updateNotificationStatusStart({
      formData: { notificationId, status: status },
      setShowConfirmActiveNotificationModal,
      limit,
      pageNo: page
    }))
  }

  return {
    totalPages,
    totalPagesReadReciept,
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    isReadReceiptTable,
    setIsReadReceiptTable,
    handleViewReadReciept,
    allNotificationList,
    allReadRecieptList,
    handleCreateNotification,
    setCreateNotificationshow,
    creatNotificationShow,
    clientsFilterData,
    tenantToNotify,
    setTenantToNotify,
    data,
    setData,
    showConfirmActiveNotification,
    setShowConfirmActiveNotificationModal,
    handleActiveNotification,
    notificationId,
    setNotificatonId,
    status,
    setStatus,
    isShowTenant,
    setShowTenant,
    setDataToShowAtTenantTable,
    tableDataToShow,
    filterData,
    setFilterData,
    initialState,
    loading,
    t,
    selectedClient,
    setSelectedClient,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useNotification
