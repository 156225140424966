export const tableHeaders = [
  { label: 'sno', value: '' },
  { label: 'instigator', value: 'instigatorName' },
  { label: 'undergoer', value: 'underGoer' },
  { label: 'section', value: 'section' },
  { label: 'action', value: 'action' },
  { label: 'reason', value: 'reason' },
  { label: 'description', value: 'description' },
  { label: 'createdAt', value: 'createdAt' },
  { label: 'action', value: 'action' }
]
export const action = [
  { label: 'playerRankIncrement', value: 'PLAYER-RANK-INCREMENT' },
  { label: 'bonusBenefitClaim', value: 'BONUS-BENEFIT-CLAIM' },
  { label: 'cashBenefitClaim', value: 'CASH-BENEFIT-CLAIM' },
  { label: 'withdrawlBenefitClaim', value: 'WITHDRAWL-BENEFIT-CLAIM' }
]
