import React from 'react'
import {
  Row,
  Col,
  Button,
  Card,
  Form as BForm,
  Spinner
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { TenantRoutes } from '../../../../routes'
import Trigger from '../../../../components/OverlayTrigger'
import { createTenantUserSchema } from '../schemas'
import useCreateTenantUser from '../hooks/useCreateTenantUser'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CreateTenantUser = ({ user }) => {
  const {
    navigate,
    userFields,
    loading,
    tenantDetails,
    initialState,
    updateTenantUser,
    createTenantUser,
    showStar,
    tenantCountries,
    completePhone
  } = useCreateTenantUser({ user })

  return (
    <>
      <Row>
        <Col className='d-flex'>
          <h2>{user ? 'Player ' : 'Players '}</h2>
          <h2>{user && ' : '}</h2>
          <h2> &nbsp;{user && user.firstName}</h2>
        </Col>
      </Row>

      <Card body>
        <h2>{user ? 'Edit' : 'Create'}</h2>
        <Formik
          initialValues={{
            ...initialState
          }}
          validationSchema={createTenantUserSchema(userFields, user)}
          onSubmit={(initialState) => {
            user
              ? updateTenantUser({ initialState })
              : createTenantUser({ initialState })
          }}
        >
          {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, setFieldError }) => (
            <Form>
              {userFields && (
                <Row>
                  {userFields.username !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        User Name
                        {showStar(userFields.username)}
                      </BForm.Label>

                      <BForm.Control
                        name='username'
                        type='text'
                        placeholder='Enter User Name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='username'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.firstName !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        First Name
                        {showStar(userFields.firstName)}
                      </BForm.Label>

                      <BForm.Control
                        name='firstName'
                        type='text'
                        placeholder='Enter First Name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />

                      <ErrorMessage
                        component='div'
                        name='firstName'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.lastName !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Last Name
                        {showStar(userFields.lastName)}
                      </BForm.Label>

                      <BForm.Control
                        name='lastName'
                        type='text'
                        placeholder='Enter Last Name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='lastName'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.email !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Email<span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Control
                        name='email'
                        type='text'
                        placeholder='Enter Email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />

                      <ErrorMessage
                        component='div'
                        name='email'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.password !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Password<span className='text-danger'>*</span>
                      </BForm.Label>

                      <Trigger message='Must be atleast 8 characters long with 1 uppercase and 1 lowercase letters, 1 special character and 1 digit'>
                        <BForm.Control
                          name='password'
                          type='password'
                          placeholder='Enter Password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password || ''}
                        />
                      </Trigger>

                      <ErrorMessage
                        component='div'
                        name='password'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.confirmPassword !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Confirm Password<span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Control
                        name='confirmPassword'
                        type='password'
                        placeholder='Enter Confirm Password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='confirmPassword'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.phone !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Phone Number
                        {showStar(userFields.phone)}
                      </BForm.Label>

                      <PhoneInput
                        name='phone'
                        country='us'
                        type='text'
                        aria-describedby='emailHelp'
                        enableSearch
                        value={completePhone || ''}
                        onChange={(phone, code) => {
                          code = String(code?.dialCode)
                          const newPhone = phone.substring(code.length)
                          const newCode = '+' + code
                          setFieldValue('phone', newPhone)
                          setFieldValue('phoneCode', newCode)
                          if (code === '') {
                            setFieldError('phone', 'Phone Required')
                          } else {
                            setFieldError('phone', '')
                          }
                        }}
                        onBlur={() => {
                          if (values.phone === '' || values.phoneCode === '') {
                            setFieldError('phone', 'Phone Required')
                          }
                        }}
                        inputStyle={{ width: '100%' }}
                      />

                      <ErrorMessage
                        component='div'
                        name='phone'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.dateOfBirth !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Date Of Birth
                        {showStar(userFields.dateOfBirth)}
                      </BForm.Label>

                      <BForm.Control
                        name='dateOfBirth'
                        type='date'
                        max={formatDateYMD(Date.now())}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dateOfBirth || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='dateOfBirth'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.address !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Address
                        {showStar(userFields.address)}
                      </BForm.Label>

                      <BForm.Control
                        name='address'
                        type='text'
                        placeholder='Enter Address'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='address'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.address !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        City
                        {showStar(userFields.address)}
                      </BForm.Label>

                      <BForm.Control
                        name='city'
                        type='text'
                        placeholder='Enter City'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='city'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.address !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Zip Code
                        {showStar(userFields.address)}
                      </BForm.Label>

                      <BForm.Control
                        name='zipCode'
                        type='text'
                        placeholder='Enter Zip Code'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode || ''}
                      />

                      <ErrorMessage
                        component='div'
                        name='zipCode'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.gender !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Gender
                        {showStar(userFields.gender)}
                      </BForm.Label>

                      <BForm.Group
                        name='gender'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gender || ''}
                      >
                        <BForm.Check
                          label='Male'
                          name='gender'
                          type='radio'
                          value='M'
                          defaultChecked={
                            user && user.gender && (user.gender === 'M' || user.gender === 'Male')
                          }
                        />

                        <BForm.Check
                          label='Female'
                          name='gender'
                          type='radio'
                          value='F'
                          defaultChecked={
                            user && user.gender && (user.gender === 'F' || user.gender === 'Female')
                          }
                        />

                        <BForm.Check
                          label='Other'
                          name='gender'
                          type='radio'
                          value='Other'
                          defaultChecked={
                            user && user.gender && user.gender === 'Other'
                          }
                        />

                      </BForm.Group>
                      <ErrorMessage
                        component='div'
                        name='gender'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.countryCode !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Country
                        {showStar(userFields.countryCode)}
                      </BForm.Label>

                      <BForm.Select
                        name='countryCode'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.countryCode || ''}
                      >
                        <option value=''>
                          ---Select Country---
                        </option>

                        {tenantCountries && tenantCountries?.rows.map(({ name, code, countryId }) => {
                          return <option value={code} key={countryId}>{name}</option>
                        })}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='countryCode'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.currencyCode !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Currency Code<span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Select
                        name='currencyCode'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currencyCode || 'none'}
                        disabled={!!(user && user)}
                      >
                        <option value='none' disabled>
                          ---Select Currency Code---
                        </option>
                        {tenantDetails &&
                          tenantDetails.tenantConfig.allowedCurrencies.map(
                            (curr) => {
                              return (
                                <option value={curr} key={curr}>
                                  {curr}
                                </option>
                              )
                            }
                          )}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='currencyCode'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.preferredLanguage !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        Preferred Language
                        {showStar(userFields.preferredLanguage)}
                      </BForm.Label>

                      <BForm.Select
                        name='preferredLanguage'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.preferredLanguage || ''}
                      >
                        <option value=''>
                          ---Select Language---
                        </option>
                        <option value='English'>English</option>
                        <option value='German'>German</option>
                        <option value='Spanish'>Spanish</option>
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='preferredLanguage'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.newsLetter !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        News Letter
                        {showStar(userFields.newsLetter)}
                      </BForm.Label>
                      <BForm.Check
                        name='newsLetter'
                        type='checkbox'
                        placeholder='Enter News Letter'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newsLetter || ''}
                      />
                      <ErrorMessage
                        component='div'
                        name='newsLetter'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {userFields.sms !== 0 && (
                    <Col xs={6} className='mt-3'>
                      <BForm.Label>
                        SMS
                        {showStar(userFields.sms)}
                      </BForm.Label>
                      <BForm.Check
                        name='sms'
                        type='checkbox'
                        placeholder='Enter SMS'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sms || ''}
                      />
                      <ErrorMessage
                        component='div'
                        name='sms'
                        className='text-danger'
                      />
                    </Col>
                  )}
                </Row>
              )}

              <div className='mt-4 d-flex justify-content-between align-items-center'>
                <Button
                  variant='warning'
                  onClick={() => navigate(TenantRoutes.Users)}
                >
                  Cancel
                </Button>
                <Button
                  variant='success'
                  className='ml-2'
                  onClick={handleSubmit}
                >
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  )
}

export default CreateTenantUser
