export const tableHeaders = (isV2 = false, userDetail = false, isAffiliate, isAgent) => {
  if (userDetail) {
    return isV2
      ? [
          'sno',
          'game name',
          'actionType',
          'casinoTransactionId',
          'subcategory',
          'category',
          'provider',
          'nonWithdrawableWallet',
          'withdrawableWallet',
          'promotionCashWallet',
          'bonusWallet',
          'payoutWallet',
          'nonWithrawableRolledAmount',
          'bonusRolledDetails',
          'promotionRolledDetails',
          'seasonalBenefit',
          'before money',
          'after money',
          'transaction date',
          'transaction time',
          'superadminNotes',
          'seniorNotes',
          !(isAffiliate && isAgent) && 'action'
        ].filter(Boolean)
      : [
          'sNo',
          'gameName',
          'actionType',
          'casinoTransactionId',
          'category',
          'subCategory',
          'provider',
          'cashAmount',
          'nonCashAmount',
          'amount',
          'beforeMoney',
          'afterMoney',
          'createdAt',
          'superadminNotes',
          'seniorNotes',
          !(isAffiliate && isAgent) && 'action'
        ].filter(Boolean)
  }
}

export const transactionType = [
  { label: 'all', value: 'all' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'Lost', value: 'lost' }
]

export const statusType = [
  { label: 'all', value: 'all' },
  { label: 'pending', value: 'pending' },
  { label: 'complete', value: 'completed' },
  { label: 'failed', value: 'failed' },
  { label: 'rollBack', value: 'rollback' }
]

export const AMOUNT_TYPE = [
  { label: 'cash', value: '0' },
  { label: 'bonus', value: '1' },
  { label: 'cash + bonus', value: '2' }
]
