import React from 'react'
import { sportsReportingTable } from './constant'
import { Table, Button } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import ChangeStatusModal from '../../common/Modals/ChangeStatusModal'
import { formatNumber } from '../../utils/storageUtils'

const SportsReportingTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  betCancelHandler,
  t,
  handleYesBetSlip,
  handleNoBetSlip,
  isConfirmationOpenForBetCancel,
  betslipId,
  isBetBy
}) => {
  const navigate = useNavigate()
  const isTenant = !window.location.href.match(/super/g)

  const { cancelBetSlipLoading } = useSelector((state) => state.superAdminTransactions)

  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {sportsReportingTable(isBetBy)?.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && tableData &&
       tableData?.rows?.map(
         (item, index) => {
           return (
             <tr key={index} style={{ backgroundColor: ['cancel-rejected', 'cashout-rejected'].includes(item?.mtsStatus) ? '#F6CECE' : '' }}>
               <td>{(page - 1) * limit + index + 1}</td>
               <td>
                 {
                   item?.betslipId || t('na')
                 }
               </td>
               <td>
                 {
                   item?.user?.username || t('na')
                 }
               </td>
               <td>
                 {
                   item?.betType || t('na')
                 }
               </td>
               <td>
                 {
                   item?.stake || t('na')
                 }
               </td>
               <td>
                 {formatNumber(item?.multiPrice) || t('na')}
               </td>
               <td>
                 {formatNumber(item?.possibleWinAmount) || t('na')}
               </td>
               <td>
                 {t(item?.settlementStatus) || t('na')}
               </td>
               <td>
                 {t(item?.betslipStatus) || t('na')}
               </td>
               {!isBetBy &&
                 <td>
                   {t(item?.mtsStatus) || t('na')}
                 </td>}
               <td>
                 {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || t('na')}
               </td>
               {!isBetBy &&
                 <td className='d-flex align-item-start'>
                   <Trigger message={t('viewDetails')}>
                     <Button
                       className='m-1'
                       size='sm'
                       variant='info'
                       onClick={() => {
                         navigate(
                              `${isTenant ? '/tenant' : '/super-admin'}/bet-reporting/details/${item?.betslipId}`
                         )
                       }}
                     >
                       <FontAwesomeIcon icon={faEye} />
                     </Button>
                   </Trigger>
                   {(item?.mtsStatus === 'accepted' && item?.settlementStatus === 'in_game') &&
                     <Trigger message={t('cancelBet')}>
                       <Button
                         className='m-1'
                         size='sm'
                         variant='danger'
                         onClick={() => betCancelHandler(item?.betslipId)}
                       ><FontAwesomeIcon icon={faWindowClose} />

                       </Button>
                     </Trigger>}

                 </td>}
             </tr>
           )
         }
       )
          }
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
      <ChangeStatusModal
        show={isConfirmationOpenForBetCancel}
        handleClose={handleNoBetSlip}
        handleUpdateYes={() => handleYesBetSlip()}
        loading={cancelBetSlipLoading?.betslipId === betslipId}
        text='confirmCancelBetAndRefund'
      />

    </div>
  )
}
export default SportsReportingTable
