import React from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import VoucherGamesListing from './VoucherGamesListing'
import useVoucherGames from './useVoucherGames'
import { faArrowCircleLeft, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProviderFilter from '../../../../components/ProviderFilter'
import { useTranslation } from 'react-i18next'
import Trigger from '../../../../components/OverlayTrigger'

const VoucherGames = () => {
  const isTenant = !window.location.href.match(/super/g)
  const { t } = useTranslation()

  const {
    voucherGames,
    loading,
    handleSearch,
    limit,
    setLimit,
    pageNo,
    navigate,
    setPageNo,
    inputRef,
    selectedProvider,
    setSelectedProvider,
    selectedSubCategoryId,
    setSelectedSubCategoryId,
    subcategories
  } = useVoucherGames({ isTenant })
  return (
    <div className='p-2'>
      <h3>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer me-1'
          onClick={() => navigate(-1)}
        />
        {t('voucherGames')}
      </h3>
      <Row className='my-2 gap-2'>
        <Col className='d-flex mt-2' xs='auto'>
          <ProviderFilter
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
            t={t}
          />
        </Col>

        <Col className='d-flex mt-2' xs='auto'>
          <Form.Label
            className='flex-shrink-0'
            style={{
              width: 'auto',
              marginRight: '15px',
              fontSize: '13px',
              marginTop: '5px'
            }}
          >
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setSelectedSubCategoryId(e.target.value)
            }}
            value={selectedSubCategoryId || 'all'}
            size='sm'
            style={{
              maxWidth: '180px',
              marginRight: '10px',
              height: 'auto'
            }}
          >
            <option value=''>{t('all')}</option>
            {subcategories?.rows?.map(({ masterGameSubCategoryId, name }) => (
              <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                {name?.EN}
              </option>
            ))}
          </Form.Select>
        </Col>

        <Col className='d-flex mt-2' xs='auto'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>
          <Form.Control
            type='search'
            ref={inputRef}
            placeholder={t('search')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '15px',
              fontSize: '13px'
            }}
            onChange={handleSearch}
          />
        </Col>
        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                inputRef.current.value = ''
                setPageNo(1)
                setSelectedProvider('')
                setSelectedSubCategoryId('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>

      </Row>
      <VoucherGamesListing
        data={voucherGames?.rows}
        count={voucherGames?.count}
        limit={limit}
        setLimit={setLimit}
        page={pageNo}
        setPage={setPageNo}
        totalPages={Math.ceil(voucherGames?.count / limit)}
        navigate={navigate}
        loading={loading}
        t={t}
      />
    </div>
  )
}

export default VoucherGames
