import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal, { DeleteConfirmationModal } from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  //   faEdit,
  // faTrash,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'

import CreateCasinoCategory from './components/CreateCasinoCategory'
import useCasinoCategoriesListing from './hooks/useCasinoCategoriesListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    limit,
    page,
    loading,
    casinoCategories,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    showModal,
    type,
    handleClose,
    selectedCategory,
    active,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    name,
    t
  } = useCasinoCategoriesListing()
  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      <Row className='mb-2'>
        <Col>
          <h3>{t('casinoCategories')}</h3>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {[
              'sNO',
              'name',
              'status',
              'actions'
            ].map((h) => (
              <th key={h}>{t(h)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {
          !loading && casinoCategories &&
                    casinoCategories?.rows?.map(
                      ({
                        categoryname,
                        categoryid,
                        isactive
                      }, index) => {
                        return (
                          <tr key={categoryid}>
                            <td>{(index + 1) + (limit * (page - 1))}</td>
                            <td>
                              {categoryname}
                            </td>

                            <td className={isactive ? 'table-success' : 'table-danger'}>
                              {isactive ? t('active') : t('inactive')}
                            </td>

                            <td>
                              <ButtonGroup>
                                {!isactive
                                  ? (
                                    <Trigger message={t('setStatusActive')}>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='success'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(categoryid, true, categoryname)}
                                      >
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </Button>
                                    </Trigger>
                                    )
                                  : (
                                    <Trigger message={t('setStatusInactive')}>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='danger'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(categoryid, false, categoryname)}
                                      >
                                        <FontAwesomeIcon icon={faWindowClose} />
                                      </Button>
                                    </Trigger>
                                    )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      }
                    )
                }

          {casinoCategories?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={5}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {casinoCategories?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={casinoCategories?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}

      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
            name={`${name} (category)`}
          />)}

      {type === 'Edit'
        ? (
            selectedCategory && (
              <CreateCasinoCategory
                categoryName={selectedCategory.name}
                isActive={selectedCategory.isActive}
                categoryId={selectedCategory?.categoryId}
                handleClose={handleClose}
                limit={limit}
                pageNo={page}
                showModal={showModal}
                type={type}
              />
            )
          )
        : (
          <CreateCasinoCategory
            handleClose={handleClose}
            limit={limit}
            pageNo={page}
            showModal={showModal}
            type={type}
          />
          )}
    </div>
  )
}
