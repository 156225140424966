import React from 'react'
import {
  Row,
  Col,
  Breadcrumb
} from '@themesberg/react-bootstrap'
import Tenant from './components/Tenant'
import useTenantDetails from './hooks/useTenantDetails'
import Preloader from '../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const TenantDetails = () => {
  const {
    tenantDetails,
    navigate,
    loading,
    adminId
  } = useTenantDetails()
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <div className='px-3'>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/super-admin/tenants/owners')}>Tenant Admin</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate(`/super-admin/tenants/${adminId}`)}>Tenant</Breadcrumb.Item>
                <Breadcrumb.Item active>Tenant:- {tenantDetails && tenantDetails?.name} (Info)</Breadcrumb.Item>
              </Breadcrumb>
            </Row>

            <div className='px-3'>
              <Row className='d-flex flex-row  text-right mb-2'>
                <Col className='d-flex'>
                  <h3>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className='pe-1 pt-2 cursor-pointer'
                      onClick={() => navigate(-1)}
                    />
                      &nbsp; Back
                  </h3>
                </Col>
              </Row>
              <Tenant tenantData={tenantDetails} />
            </div>

          </div>
          )}
    </>
  )
}

export default TenantDetails
