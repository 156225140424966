import React from 'react'
import { Button } from 'react-bootstrap'
import AffiliatesList from './AffiliatesList'
import AgentsList from './AgentsList'
import PlayersTab from './PlayersTab'

const BonusTabContent = ({
  t,
  selectedTab,
  setSelectedTab,
  isRecurring,
  isAffiliate,
  isTenant,
  TABS_KEYS
}) => {
  return (
    <div>
      <div className='d-flex gap-2 my-3'>
        {Object.values(TABS_KEYS)?.map((item) => {
          if (isAffiliate && (item === TABS_KEYS.affiliate || item === TABS_KEYS.player)) {
            return null
          }
          return (
            <Button onClick={() => setSelectedTab(item)} key={item} variant={selectedTab === item ? 'success' : 'outline-success'}>
              {t(item)}
            </Button>
          )
        })}
      </div>
      <div className='d-flex flex-column gap-2'>
        {selectedTab === TABS_KEYS.affiliate && isTenant && <AffiliatesList isTenant={isTenant} />}
        {selectedTab === TABS_KEYS.agent && <AgentsList isAffiliate={isAffiliate} isTenant={isTenant} />}
        {selectedTab === TABS_KEYS.player && <PlayersTab isAffiliate={isAffiliate} />}

      </div>
    </div>
  )
}

export default BonusTabContent
