import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { tableHeaders } from './constants'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt,
  faEye,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import ClientFilter from '../../../components/ClientFilter'
import useSecurityAlerts from './hooks/useSecurityAlerts'
import { ChangeInspectionStatus } from '../../../components/ConfirmationModal'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { markUnderInspectionStart } from '../../../store/redux-slices/superAdminSettings'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import moment from 'moment'

const SecurityAlerts = () => {
  const {
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    securityReport,
    inputRef,
    setState,
    initialDate,
    handleCsvDownload,
    loading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    fetchReport,
    state
  } = useSecurityAlerts()

  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const [inspectData, setInspectData] = useState(null)
  const dispatch = useDispatch()
  const handleMarkAsInspection = () => {
    dispatch(
      markUnderInspectionStart({
        setShow,
        formData: inspectData,
        fetchData: fetchReport
      })
    )
  }

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('securityAlerts')}</h3>
          </Col>
        </Col>
      </Row>
      <div>
        <div className='d-flex flex-wrap align-items-center gap-2'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px'
              }}
            >
              {t('searchUsername')}
            </Form.Label>
            &nbsp;
            <Form.Control
              ref={inputRef}
              autoComplete='off'
              name='search'
              type='search'
              placeholder={t('searchUsername')}
              size='sm'
              style={{
                marginRight: '15px',
                width: '260px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <Col xs='auto' className='d-flex align-items-center'>
            <InputDatePicker
              isFilter
              label={t('from')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </Col>
          <Col xs='auto' className='d-flex align-items-center'>
            <InputDatePicker
              isFilter
              label={t('to')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </Col>
          <div className='d-flex gap-2  align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
            <AffiliateAgentFilter
              selectedClient={selectedClient}
              agent={agent}
              setAgent={setAgent}
              affiliate={affiliate}
              setAffiliate={setAffiliate}
            />

            <div className='d-flex px-3 float-end'>
              <Trigger message={t('downloadCsv')}>
                <Button
                  variant='outline-success'
                  className='m-1'
                  size='sm'
                  disabled={securityReport?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  className='m-1'
                  onClick={() => {
                    setState(initialDate)
                    setSelectedClient('')
                    setAgent('')
                    setAffiliate('')
                    setFormData(initalWalletState)
                    inputRef.current.value = ''
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>
        </div>
      </div>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((heading, idx) => (
              <th key={heading.label}>{t(heading.label)} &nbsp;</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading &&
            securityReport &&
            securityReport?.rows?.map((item, index) => {
              return (
                <tr key={index} className='row-height'>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <span
                      style={{
                        width: '130px',
                        cursor: 'pointer'
                      }}
                      className='d-inline-block text-truncate'
                    >
                      {item?.adminUserName || 'NA'}
                    </span>
                  </td>
                  <td>{item?.username || 'NA'}</td>
                  <td>{item?.description || 'NA'}</td>
                  <td>
                    {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || '-'}
                  </td>
                  <td>{item?.actionTaken || 'NA'}</td>
                  <td>
                    <Trigger message={t('viewDetails')}>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        onClick={() => {
                          navigate(
                            `/super-admin/users/details/${item?.userId}`,
                            { state: { securityFlag: true } }
                          )
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </Trigger>
                    <Trigger message={t('markedAsInspection')}>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        // disabled={item?.actionTaken !== null}
                        onClick={() => {
                          setShow(true)
                          setInspectData({
                            userId: item.userId,
                            flag: !item?.isUnderInspection,
                            securityFlag: true
                          })
                        }}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </Button>
                    </Trigger>
                  </td>
                </tr>
              )
            })}
          {!loading && securityReport.count === 0
            ? (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : (
              <></>
              )}
        </tbody>
      </Table>

      {!loading && securityReport.count !== 0 && (
        <PaginationComponent
          page={securityReport.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={securityReport.count}
        />
      )}
      {show
        ? (
          <ChangeInspectionStatus
            show={show}
            setShow={setShow}
            onClick={handleMarkAsInspection}
          />
          )
        : (
          <></>
          )}
    </div>
  )
}

export default SecurityAlerts
