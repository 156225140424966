import {
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { getDashboardSBTransactionsStart, getLivePlayersReportStart, getProviderStatStart, getSeniorUserReportStart, getUserLoggedInCountByCountryStart, getWalletStatsStart } from '../../../../store/redux-slices/dashboard'
import { getAllCasinoCategoriesStart } from '../../../../store/redux-slices/tenantCasino'
import { countryFilter } from '../../../../utils/countryFilter'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'
import { ADMIN_ROLES } from '../../../../utils/constants'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useDashboard = ({ isAffiliate, isAgent }) => {
  const role = getItem('role')
  const { t } = useTranslation()
  const { affiliateAndAgentsList } = useSelector((state) => state.adminUser)
  const [sbTransactionDate, setSBTransactionDate] = useState({
    startDate: '',
    endDate: ''
  })
  const dispatch = useDispatch()
  const [count, setCount] = useState(0)
  const {
    loading,
    livePlayerData,
    walletStats,
    providerDataStat,
    userLogginbyCountry,
    seniorUserStatData,
    dashboardSBTransactions,
    sbTransactionLoading
  } = useSelector((state) => state.dashboard)

  const { casinoCategories } = useSelector((state) => state.tenantCasino)
  const [selectedTab, setSelectedTab] = useState('transaction')
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [state, setState] = useState(initialDate)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const tableHeaders = [
    { label: 'sNo', value: '' },
    { label: 'wagerType', value: 'actionType' },
    { label: 'totalWager', value: 'totalCount' },
    { label: 'totalAmount', value: 'totalWinAmount' }
  ]
  const tableHeaderSeniorUser = [
    { label: 'sNo', value: '' },
    { label: 'reportFor', value: '' },
    { label: 'totalDepositAmount', value: 'TotalDepositAmount' },
    { label: 'totalDepositCount', value: 'TotalDepositCount' },
    { label: 'totalWithdrawlAmount', value: 'TotalWithdrawlAmount' },
    { label: 'totalWithdrawalCount', value: 'TotalWithdrawalCount' },
    { label: 'totalDepositMinusWithdrawal', value: 'totalDepositMinusWithdrawal' }
  ]
  const tableHeadersUserCountryCount = [
    { label: 'country', value: 'country_code' },
    { label: 'totalPlayer', value: 'totalUserCount' },
    { label: 'onlinePlayer', value: 'loggedInCount' },
    { label: 'sNo', value: '' },
    { label: 'country', value: 'country_code' },
    { label: 'depositers', value: 'userWallet.totalDepositers' },
    { label: 'depositersAmount', value: 'userWallet.totalDepositAmount' },
    { label: 'pendingKyc', value: 'pendingCount' },
    { label: 'approvedKyc', value: 'approvedCount' },
    { label: 'rejectKyc', value: 'rejectCount' }
  ]

  const seniorReport = seniorUserStatData?.transaction?.transactionReport[0]
  const playerReport = seniorUserStatData?.transaction?.userTransactionReport[0]

  const result = isAffiliate || isAgent
    ? [
        {
          role: 'Player',
          ...playerReport
        }
      ]
    : [
        {
          role: 'Senior',
          ...seniorReport
        },
        {
          role: 'Player',
          ...playerReport
        }
      ]
  useEffect(() => {
    dispatch(getProviderStatStart({
      isTenant: true,
      providerId: selectedProvider,
      categoryId: selectedCategory,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      isAffiliate,
      isAgent,
      agentId: agent,
      affiliateId: affiliate
    }))
  }, [selectedProvider, selectedCategory, state, agent, affiliate])

  const transformString = (str) =>
    str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

  const countryCodes = userLogginbyCountry?.map((data) => {
    const { countryName } = countryFilter(data?.country_code)
    return countryName || 'Other'
  })

  const loggedInCounts = userLogginbyCountry?.map(data => data.loggedInCount)
  const pieChartValuesSeries = livePlayerData?.[0] && Object.values(livePlayerData?.[0])?.map((item) => Number(item))
  const pieChartValuesLabels = livePlayerData?.[1] && Object.keys(livePlayerData?.[1])?.map(transformString)

  const pieChartValuesWalletSeries = walletStats?.[0] && Object.values(walletStats?.[0])?.map((item) => Number(item))
  const pieChartValuesWalletLabels = walletStats?.[1] && Object.keys(walletStats?.[1])?.map(transformString)

  useEffect(() => {
    dispatch(getAllCasinoCategoriesStart({
      limit: '',
      pageNo: '',
      isAffiliate,
      isAgent
    }))
    dispatch(getLivePlayersReportStart({
      isTenant: true,
      isAffiliate,
      isAgent,
      agentId: agent,
      affiliateId: affiliate,
      languageCode: getItem('language')
    }))

    dispatch(getUserLoggedInCountByCountryStart({
      isTenant: true,
      isAffiliate,
      isAgent,
      languageCode: getItem('language'),
      agentId: agent,
      affiliateId: affiliate
    }))
    dispatch(getWalletStatsStart({
      isTenant: true,
      isAffiliate,
      isAgent,
      languageCode: getItem('language'),
      agentId: agent,
      affiliateId: affiliate
    }))

    dispatch(getSeniorUserReportStart({
      transactionType,
      isTenant: true,
      isAffiliate,
      isAgent,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      agentId: agent,
      affiliateId: affiliate
    }))
  }, [getItem('language'), transactionType, state, agent, affiliate])

  useEffect(() => {
    const intervals = setInterval(() => {
      clearInterval(intervals)
      dispatch(getLivePlayersReportStart({
        isTenant: true,
        isAffiliate,
        isAgent,
        languageCode: getItem('language'),
        agentId: agent,
        affiliateId: affiliate
      }))
      setCount(count + 1)
    }, 30000)
  }, [count, isAffiliate, isAgent])

  useEffect(() => {
    if (role === ADMIN_ROLES.ADMIN) {
      dispatch(getDashboardSBTransactionsStart({
        isTenant: true,
        data: {
          tenantId: getItem('tenant-id'),
          from: moment(sbTransactionDate.startDate).toISOString() || '',
          to: moment(sbTransactionDate.endDate).toISOString() || ''
        }
      }))
    }
  }, [sbTransactionDate])

  return {
    t,
    loading,
    pieChartValuesSeries,
    pieChartValuesLabels,
    livePlayerData,
    providerDataStat,
    tableHeaders,
    selectedProvider,
    setSelectedProvider,
    selectedCategory,
    setSelectedCategory,
    casinoCategories,
    pieChartValuesWalletSeries,
    pieChartValuesWalletLabels,
    countryCodes,
    loggedInCounts,
    tableHeadersUserCountryCount,
    userLogginbyCountry,
    state,
    setState,
    initialDate,
    transactionType,
    setTransactionType,
    selectedTab,
    setSelectedTab,
    result,
    tableHeaderSeniorUser,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    affiliateAndAgentsList,
    dashboardSBTransactions,
    sbTransactionLoading,
    setSBTransactionDate,
    sbTransactionDate
  }
}

export default useDashboard
