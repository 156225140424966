import CryptoJS from 'crypto-js'
import i18n from 'i18next'
const FE_ENCRYPTION_KEY = process.env.REACT_APP_FE_ENCRYPTION_KEY

export const encryptCredentials = (data) => {
  return CryptoJS.AES.encrypt(data, FE_ENCRYPTION_KEY).toString()
}

export const decryptCredentials = (data) => {
  return CryptoJS.AES.decrypt(data, FE_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)
}

export const setLoginToken = (token) =>
  window.localStorage.setItem('access-token', encryptCredentials(token))

export const getItem = (key) => window.localStorage.getItem(key)

export const removeItem = (key) => window.localStorage.removeItem(key)

export const getLoginToken = () => {
  if (window.localStorage.getItem('access-token')) return decryptCredentials(window.localStorage.getItem('access-token'))
  else return ''
}

export const removeLoginToken = () => {
  window.localStorage.clear()
  setItem('language', 'en')
}

export const setItem = (key, value) => {
  if (key === 'language') i18n.changeLanguage(value?.toUpperCase())
  return window.localStorage.setItem(key, value)
}
export const getCurrentLang = () => {
  return getItem('language')?.toLowerCase() || 'en'
}
export const returnLangName = (nameObj) => {
  const selecteLangName = nameObj[getCurrentLang()]
  if (selecteLangName) {
    return selecteLangName
  } else {
    let tempName
    Object.keys(nameObj).forEach(key => {
      if (nameObj[key]) {
        tempName = nameObj[key]
      }
    })
    return tempName || ''
  }
}
export const formatNumber = (number) => {
  // const tempNumber = Number(number).toFixed(2)
  // return new Intl.NumberFormat('en-US', {
  //   maximumFractionDigits: 50
  // }).format(tempNumber)
  return parseFloat(parseFloat(number || 0).toFixed(2)).toLocaleString(
    'ko-KR',
    {
      useGrouping: true
    }
  )
}
