export const tableHeaders = [
  { label: 's.no', value: '' },
  { label: 'gameId', value: 'masterCasinoGameId' },
  { label: 'name', value: 'name' },
  { label: 'provider', value: 'casionoprovider' },
  { label: 'returnToPlayer', value: 'returnToPlayer' },
  { label: 'systemRtp', value: 'systemRtp' },
  { label: 'Category', value: 'casinocat' },
  { label: 'subCategory', value: 'casinosubcat' },
  { label: 'thumbnail', value: 'thumbnail' },
  { label: 'status', value: 'status' },
  { label: 'action', value: 'action' }

  // { label: 'RTP', value: 'returnToPlayer' },
]

export const volatilityRating = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
  { label: 'Medium High', value: 'medium-high' },
  { label: 'Very High', value: 'very-high' },
  { label: 'Low Nedium', value: 'low-medium' }
]
