import React from 'react'
import UpdateLimitForm from './UpdateLimitForm'
import usePlayersTab from './hooks/usePlayersTab'
import { Card, Col, Row } from 'react-bootstrap'

const PlayersTab = ({ isAffiliate }) => {
  const {
    t,
    bonusDetail,
    handleUpdateLimit,
    updateLoading,
    entityBonusDetails
  } = usePlayersTab({ isAffiliate })
  return (
    <div>
      <Row>
        <Col sm={6}>
          <Card>
            <Card.Body>
              <UpdateLimitForm
                bonusDetail={entityBonusDetails || bonusDetail}
                t={t}
                handleUpdateYes={handleUpdateLimit}
                handleClose={null}
                loading={updateLoading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PlayersTab
