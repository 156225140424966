import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal, { DeleteConfirmationModal } from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  // faEdit,
  faWindowClose,
  faRedoAlt
  // faTrash
} from '@fortawesome/free-solid-svg-icons'

import CreateSubCategory from './components/CreateSubCategory'
import useCasinoSubCategoriesListing from './hooks/useCasinoSubCategoriesListing'
import Trigger from '../../../components/OverlayTrigger'
import { tableHeaders } from './constants'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    limit,
    page,
    loading,
    subCategories,
    casinoCategories,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    showModal,
    type,
    handleClose,
    selectedSubCategory,
    search,
    setSearch,
    setCategoryFilter,
    categoryFilter,
    active,
    statusFilter,
    setStatusFilter,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    name,
    t
  } = useCasinoSubCategoriesListing()
  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      <Row className='mb-3'>
        <Col sm={8}>
          <h3>{t('casinoSubCategories')}</h3>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100 mb-2'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('category')}
            </Form.Label>

            <Form.Select
              value={categoryFilter}
              onChange={(e) => {
                setPage(1)
                setCategoryFilter(e.target.value)
              }}
              size='sm'
              style={{ maxWidth: '230px' }}
            >
              <option value=''>{t('all')}</option>

              {casinoCategories && casinoCategories?.rows?.map((c) => (
                <option key={c?.categoryid} value={c?.categoryid}>{c?.categoryname}</option>
              ))}
            </Form.Select>
          </div>
        </Col>

        <Col xs='auto'>
          <div column='sm' className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('status')}
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setPage(1)
                setStatusFilter(e.target.value)
              }}
              value={statusFilter}
              size='sm'
              style={{ maxWidth: '230px' }}
            >
              <option value=''>{t('all')}</option>
              <option value='true'>{t('active')}</option>
              <option value='false'>{t('inactive')}</option>
            </Form.Select>
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder={t('searchSubCategoryName')}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%@^&*#=)()><?]+/g, '')
                )}
              size='sm'
              style={{ minWidth: '205px' }}
            />
          </div>
        </Col>
        <Col xs='auto' className='d-flex  float-end'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSearch('')
                setCategoryFilter('')
                setStatusFilter('')
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, index) => (
              <th key={index}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && subCategories &&
                    subCategories?.rows?.map(
                      ({
                        masterGameSubCategoryId,
                        name,
                        isActive,
                        categoryName,
                        imageUrl
                      }, index) => {
                        return (
                          <tr key={index}>
                            <td>{(index + 1) + (limit * (page - 1))}</td>
                            <td>
                              <Trigger message={name?.EN}>
                                <span
                                  style={{
                                    width: '100px',
                                    cursor: 'pointer'
                                  }}
                                  className='d-inline-block text-truncate'
                                >
                                  {name?.EN}
                                </span>
                              </Trigger>
                            </td>
                            <td className={isActive ? 'table-success' : 'table-danger'}>
                              {isActive ? t('active') : t('inactive')}
                            </td>
                            <td>{categoryName}</td>

                            <td>
                              <ButtonGroup>
                                {!isActive
                                  ? (
                                    <Trigger message={t('setStatusActive')}>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='success'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(masterGameSubCategoryId, isActive, name?.EN)}
                                      >
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </Button>
                                    </Trigger>
                                    )
                                  : (
                                    <Trigger message={t('setStatusInactive')}>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='danger'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(masterGameSubCategoryId, isActive, name?.EN)}
                                      >
                                        <FontAwesomeIcon icon={faWindowClose} />
                                      </Button>
                                    </Trigger>
                                    )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      }
                    )}

          {subCategories?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={7}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {subCategories?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={subCategories?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={`${name} (sub-category)`}
        />
      )}

      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
            name={`${name} (sub-category)`}
          />)}

      {type === 'Edit'
        ? (
            selectedSubCategory && (
              <CreateSubCategory
                selectedSubCategory={selectedSubCategory}
                handleClose={handleClose}
                limit={limit}
                pageNo={page}
                showModal={showModal}
                type={type}
                search={search}
                categoryFilter={categoryFilter}
                statusFilter={statusFilter}
              />
            )
          )
        : (
          <CreateSubCategory
            handleClose={handleClose}
            limit={limit}
            pageNo={page}
            showModal={showModal}
            type={type}
            search={search}
            categoryFilter={categoryFilter}
            statusFilter={statusFilter}
          />
          )}
    </div>
  )
}
