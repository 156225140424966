import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getBonusStart } from '../../../store/redux-slices/bonus'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useViewBonusDetail = ({ isTenant, isAffiliate }) => {
  const TABS_KEYS = {
    affiliate: 'affiliate',
    agent: isAffiliate ? 'agent' : 'agentWithOutAffiliate',
    player: 'player'
  }
  const [selectedTab, setSelectedTab] = useState(isAffiliate ? TABS_KEYS.agent : TABS_KEYS.affiliate)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, bonusDetail } = useSelector((state) => state.bonus)

  const { bonusId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ formData: { bonusId, type: 'CASINO' }, isTenant, isAffiliate }))
    }
  }, [bonusId])
  return {
    loading,
    bonusDetail,
    t,
    navigate,
    selectedTab,
    setSelectedTab,
    TABS_KEYS

  }
}

export default useViewBonusDetail
