import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import useVoucherUsers from './useVoucherUsers'
import VoucherUsersListing from './VoucherUsersListing'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const VoucherUsers = ({ isGiftCard }) => {
  const isTenant = !window.location.href.match(/super/g)
  const { t } = useTranslation()

  const {
    voucherUsers,
    loading,
    handleSearch,
    limit,
    setLimit,
    pageNo,
    navigate,
    setPageNo
  } = useVoucherUsers({ isTenant, isGiftCard })
  return (
    <div className='p-2'>
      <h3>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer me-1'
          onClick={() => navigate(-1)}
        />
        {isGiftCard ? t('giftcardUsers') : t('voucherUsers')}
      </h3>
      <Row className='my-2 gap-2'>
        <Col className='d-flex align-self-center '>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}

          </Form.Label>
          <Form.Control
            type='search'
            placeholder={t('search')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '15px',
              fontSize: '13px'
            }}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <VoucherUsersListing
        isTenant={isTenant}
        userList={voucherUsers?.rows}
        count={voucherUsers?.count}
        limit={limit}
        setLimit={setLimit}
        page={pageNo}
        setPage={setPageNo}
        totalPages={Math.ceil(voucherUsers?.count / limit)}
        navigate={navigate}
        loading={loading}
        t={t}
      />
    </div>
  )
}

export default VoucherUsers
