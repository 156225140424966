import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import moment from 'moment'
import {
  tableHeaders,
  actionSources,
  requestActions,
  transactionType
} from './constants'
import {
  Col,
  Form,
  Row
} from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import { WalletActionConfirmModal } from '../../../components/ConfirmationModal'
import PaginationComponent from '../../../components/Pagination'
import useTenantWallet from './hooks/useTenantWallet'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../utils/storageUtils'

const TenantWallet = () => {
  const {
    state,
    setState,
    formData,
    setFormData,
    setShowWalletRequestAction,
    showWalletRequestAction,
    walletRequestAction,
    tenantSeniorWalletTransactions,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    handleConfirmActionRequest,
    setSelectedClient,
    handleCsvDownload,
    inputRef,
    initialDate,
    loading,
    t
  } = useTenantWallet()

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('wallet')}</h3>
          </Col>
        </Col>
      </Row>
      <div>
        <div className='d-flex flex-wrap align-items-center gap-2'>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('requestStatus')}
            </Form.Label>
            <Form.Select
              value={formData?.status}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  status: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>
              {requestActions?.map((requestAction) => (
                <option
                  key={requestAction.value}
                  value={requestAction.value}
                >
                  {t(requestAction.label)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div xs='auto' className='d-flex '>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('transactionType')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '150px', width: 'auto' }}
              size='sm'
              value={formData?.transactionType}
              onChange={(e) => {
                setFormData({ ...formData, transactionType: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {transactionType?.map((transactionRequest) => (
                <option
                  key={transactionRequest.value}
                  value={transactionRequest.value}
                >
                  {t(transactionRequest.label)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm'
              style={{ marginBottom: '0px', marginRight: '15px', minWidth: 'fit-content' }}
            >
              {t('transactionAction')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px', width: 'auto' }}
              size='sm'
              value={formData?.actionType}
              onChange={(e) => {
                setFormData({ ...formData, actionType: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {actionSources?.map((action) => (
                <option
                  key={action.value}
                  value={action.value}
                >
                  {t(action.label)}
                </option>
              ))}
            </Form.Select>
            <div className='d-flex align-items-center'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                {t('search')}
              </Form.Label>&nbsp;
              <Form.Control
                ref={inputRef}
                name='search'
                type='search'
                placeholder={t('search')}
                size='sm'
                style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
                onChange={(e) => {
                  debounceFn(e)
                  setPage(1)
                }}
              />
            </div>
            <Col className='d-flex p-3 float-end'>
              <Trigger message={t('downloadAsCSV')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  disabled={tenantSeniorWalletTransactions?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
            </Col>
            <Col className='d-flex p-3 float-end'>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  className='py-1'
                  size='sm'
                  onClick={() => {
                    setFormData(initalWalletState)
                    setState(initialDate)
                    setSelectedClient('')
                    inputRef.current.value = ''
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </Col>
          </div>
        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && tenantSeniorWalletTransactions &&
            tenantSeniorWalletTransactions?.rows?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <span
                      style={{
                        width: '130px',
                        cursor: 'pointer'
                      }}
                      className='d-inline-block text-truncate'
                    >
                      {item?.domain || 'NA'}
                    </span>
                  </td>
                  <td>
                    {item?.name || 'NA'}
                  </td>
                  <td>
                    {item?.transactionId || 'NA'}
                  </td>
                  <td>
                    {item?.requestStatus || 'NA'}
                  </td>
                  <td>
                    {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || '-'}
                  </td>
                  <td>
                    {item?.amount || item?.currency
                      ? formatNumber(item?.amount) + ' ₩'
                      : '-'}
                  </td>

                  <td>
                    {item?.actionType || 'NA'}
                  </td>
                  <td>
                    {item?.transactionType || 'REQUESTED'}
                  </td>
                </tr>
              )
            })}
          {!loading && tenantSeniorWalletTransactions?.count === 0
            ? (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tenantSeniorWalletTransactions?.count !== 0 && (
        <PaginationComponent
          page={tenantSeniorWalletTransactions?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={tenantSeniorWalletTransactions?.count}
        />
      )}
      {showWalletRequestAction && (
        <WalletActionConfirmModal
          action={walletRequestAction}
          setShow={setShowWalletRequestAction}
          show={showWalletRequestAction}
          handleConfirmActionRequest={handleConfirmActionRequest}
        />
      )}
    </div>
  )
}

export default TenantWallet
