import * as Yup from 'yup'

export const adminProfileSchema = (t) => {
  Yup.object().shape({
    firstName: Yup
      .string()
      .min(3, t('firstNameMustBeAtleast3Characters'))
      .max(25, t('firstNameMustBeLessThan25Characters'))
      .matches(/^[A-Za-z]+$/, t('onlyAlphabetsAllowed'))
      .required(t('firstNameRequired')),
    lastName: Yup
      .string()
      .min(3, t('lastNameMustBeAtleast3Characters'))
      .max(25, t('lastNameMustBeLessThan25Characters'))
      .matches(/^[A-Za-z]+$/, t('onlyAlphabetsAllowed'))
      .required(t('lastNameRequired')),
    phone: Yup
      .string()
      .min(5, t('phoneMustBeAtLeast5Digits'))
      .max(13, t('phoneMustBeLessThan13Digits'))
      .matches(
        /^\+[1-9]{1}[0-9]{3,14}$/,
        t('enterValidPhoneNumberWithCountryCode')
      )
  })
}

export const profilePasswordSchema = (t) => {
  Yup.object().shape({
    password: Yup
      .string()
      .matches(/^\S*$/, t('spaceNotAllowed'))
      .matches(/^(().{4,})$/, t('min4Characters'))
      .matches(/[a-z]/, t('min1LowerCase'))
      .matches(/[A-Z]/, t('min1UpperCase'))
      .matches(/[0-9]/, t('min1Number'))
      .matches(/[^A-Za-z0-9\s]/, t('min1SpecialCharacters'))
      .max(16, t('max16Characters'))
      .required(t('oldPasswordRequired')),

    newPassword: Yup
      .string()
      .matches(/^\S*$/, t('spaceNotAllowed'))
      .matches(/^(().{4,})$/, t('min4Characters'))
      .matches(/[a-z]/, t('min1LowerCase'))
      .matches(/[A-Z]/, t('min1UpperCase'))
      .matches(/[0-9]/, t('min1Number'))
      .matches(/[^A-Za-z0-9\s]/, t('min1SpecialCharacters'))
      .max(16, t('max16Characters'))
      .test('match', t('oldAndNewPasswordMustBeDifferent'), function (newPassword) {
        return newPassword !== this.options.parent.password
      })
      .max(50)
      .required(t('newPasswordRequired')),
    confirmPassword:
      Yup
        .string()
        .matches(/^\S*$/, t('spaceNotAllowed'))
        .matches(/^(().{4,})$/, t('min4Characters'))
        .matches(/[a-z]/, t('min1LowerCase'))
        .matches(/[A-Z]/, t('min1UpperCase'))
        .matches(/[0-9]/, t('min1Number'))
        .matches(/[^A-Za-z0-9\s]/, t('min1SpecialCharacters'))
        .max(16, t('max16Characters'))
        .required(t('confirmPasswordRequired'))
  })
}
