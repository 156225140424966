import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { viewNewGameStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import { debounce } from 'lodash'
import gameImages from '../../../utils/constants'

const useViewNewGame = ({ providerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const inputRef = useRef()
  const { newGamesData, loading } = useSelector((state) => state.superAdminCasino)
  const totalPages = Math.ceil(newGamesData?.count / limit)
  const getImageByGameCategory = (categoryName) => {
    switch (categoryName) {
      case 'baccarat':
        return gameImages.baccaratGame

      case 'slot':
        return gameImages.slotGame

      case 'video slots':
        return gameImages.liveGame

      case 'blackjack':
        return gameImages.blackjackGame

      case 'roulette':
        return gameImages.rouletteGame

      case 'gamble':
        return gameImages.gambleGame

      case 'poker':
        return gameImages.pokerGame

      case 'scratchcard':
        return gameImages.scratchCardGame

      case 'live games':
        return gameImages.gambleGame

      default:
        return gameImages.gambleGame
    }
  }
  useEffect(() => {
    dispatch(viewNewGameStart({
      limit,
      pageNo: page,
      search,
      masterCasinoProviderId: providerId
    }))
  }, [limit, page, search])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(prev => (event.target.value))
  }
  return {
    debounceFn,
    inputRef,
    limit,
    page,
    setLimit,
    setPage,
    totalPages,
    loading,
    search,
    setSearch,
    t,
    newGamesData,
    getImageByGameCategory
  }
}

export default useViewNewGame
