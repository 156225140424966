export const KO = {
  1: 1,
  accept: '수용하다',
  accepted: '수락됨',
  acceptRequest: '요청 수락',
  amountShouldBeInAccordingToRank: '금액은 순위에 따라야 합니다.',
  accountActions: '계정 작업',
  accountNumber: '계좌 번호',
  action: '행동',
  actions: '행위',
  actionType: '작업 유형',
  active: '활동적인',
  address: '주소',
  addressMax: '최대 100자',
  addressRequired: '주소 필수',
  adminIdRequired: '상위 관리자가 필요합니다',
  adminName: '관리자 이름',
  all: '모두',
  allowedCurrencies: '허용되는 통화',
  allowedLanguages: '허용되는 언어',
  allowedProviders: '허용된 제공업체',
  amount: '양',
  amountCheck: '금액은 사용 가능한 잔액보다 적어야 합니다.',
  amountMin: '금액은 0보다 커야 합니다.',
  amountPositive: '금액은 양수여야 합니다.',
  amountRequired: '필요한 금액',
  amountShouldBeGreaterThan0: '금액은 0보다 커야 합니다.',
  amountLength: '금액은 7자리 미만이어야 합니다.',
  approve: '승인하다',
  approved: '승인됨',
  approvedKyc: '승인된 KYC',
  areYouSureActionRequest: '정말로 하시겠어요? {{action}} 요구?',
  areYouSureKycRequest: '정말로 하시겠어요? {{action}} kyc 요청?',
  associatedReferrals: '관련 추천',
  associateReferral: '준회원 추천',
  bankName: '은행 이름',
  bannerManagement: '배너 관리',
  rankingSeasons: '랭킹 시즌',
  bannerPreview: '배너 미리보기',
  bannerRequired: '배너 필수',
  bannerTypeRequired: '배너 유형 필수',
  basicInfo: '기본 정보',
  Bet: '내기',
  BET: '내기',
  betHistory: '베팅 내역',
  betLevel: '베팅 수준',
  betLevelMustBeAnIntegerValue: '베팅 수준은 정수 값이어야 합니다',
  betLevelMustBeAPositiveNumber: '베팅 수준은 양수여야 합니다.',
  betLevelRequired: '베팅 수준 필요',
  betResult: '베팅 결과',
  betType: '베팅 유형',
  blackList: '블랙리스트',
  blacklist: '블랙리스트',
  blacklistDescription: '블랙리스트 설명',
  blackListPlayer: '블랙리스트 플레이어',
  blacklistReason: '블랙리스트 이유',
  blacklistStatus: '블랙리스트 상태',
  bonus: '보너스',
  Bonus: '보너스',
  bonus_limit: '보너스 한도',
  bonus_percentage: '보너스 백분율',
  bonus_type: '보너스 유형',
  bonus_valid_from: '보너스 유효 기간: 부터',
  bonus_valid_to: '보너스 유효 기간:',
  bonusLimit: '보너스 한도',
  bonusPercentage: '보너스 백분율',
  bonusPercentageRequired: '보너스 백분율 필요',
  bonusPercentMustBeNumber: '보너스 비율은 숫자여야 합니다.',
  bonusRequired: '보너스 필요',
  bonusRollingPercentage: '보너스 롤링 백분율',
  bonusRollingPercentNumber: '보너스 롤링 비율은 숫자여야 합니다.',
  bonusTransactions: '보너스 거래',
  bonusType: '보너스 유형',
  bonusTypeRequired: '보너스 유형 필수',
  bonusUsers: '보너스 사용자',
  bonusValidFrom: '보너스 유효 기간: 부터',
  bonusValidTo: '보너스 유효 기간:',
  cancel: '취소',
  cancelReRequested: '취소-재요청됨',
  cash: '현금',
  Cash: '현금',
  'Cash + Bonus': '현금 보너스',
  cashAmount: '현금금액',
  casinoCategories: '카지노 카테고리',
  casinoGame: '카지노 게임',
  casinoGames: '카지노 게임',
  casinoManagement: '카지노 관리',
  casinoProviderIdRequired: '카지노 제공업체 ID 필수',
  casinoProviders: '카지노 제공업체',
  casinoReporting: '카지노 보고',
  casinoSubCategories: '카지노 하위 카테고리',
  casinoTransaction: '카지노 거래',
  casinoTransactionId: '카지노 거래 ID',
  categories: '카테고리',
  category: '범주',
  categoryRequired: '카테고리 필수',
  changeLanguage: '언어 변경',
  chooseNote: '아무 것도 선택하지 않으면 {{tab}}, 모든 사용자를 위해 생성됩니다. {{tab}} 기본적으로',
  chooseType: '유형 선택',
  city: '도시',
  cityMax: '최대 50자',
  cityRequired: '도시 필수',
  cms: 'CMS',
  CMS: 'CMS',
  compensation_target: '보상대상',
  compensationBonus: '보상 보너스',
  compensationDepositBonus: '보상입금 보너스',
  compensationTarget: '보상대상',
  compensationTargetRequired: '보상대상 필수',
  Complete: '완전한',
  confirm: '확인하다',
  CONFIRM: '확인하다',
  confirmAction: '정말로 하시겠습니까?',
  confirmDelete: '삭제 하시겠습니까 ?',
  confirmMarkStatus: '표시하시겠습니까?  ',
  confirmPasswordRequired: '비밀번호 확인 필수!',
  confirmResetEmail: '비밀번호 재설정 이메일을 보내시겠습니까?',
  confirmSwitch: '정말로 다른 언어로 전환하시겠습니까?',
  confirmTitle: '확실합니까?',
  confirmTransactionLimit: '정말로 하시겠어요? {{buttonTitle}} {{amount}} {{currency}}?',
  content: '콘텐츠',
  contentRequired: '필수 콘텐츠',
  contentValidFormat: '필수 콘텐츠',
  copiedToClipboard: '클립보드에 복사됨',
  copyImageURL: '이 이미지 URL을 클립보드에 복사',
  country: '국가',
  countryCode: '국가 코드',
  countryCodeRequired: '국가 코드가 필요합니다',
  countryName: '국가 / 클럽 이름',
  countryRequired: '국가 필수',
  couponNumber: '쿠폰번호',
  create: '만들다',
  createBonus: '보너스 만들기',
  createCMS: 'CMS 생성',
  created: '만들어진',
  createdAt: '생성 날짜',
  createForAll: '{placeholder}을(를) 선택하지 않으면 기본적으로 모든 {placeholder}에 대해 생성됩니다.',
  createNotification: '알림 생성',
  createTenant: '테넌트 생성',
  CREDIT: '신용 거래',
  currency: '통화',
  currencyRequire: '필요한 통화',
  currencyRequired: '필요한 통화',
  currentOnlinePlayer: '현재 온라인 플레이어',
  daily: '일일',
  daily_limit: '일일 한도',
  dailyLimit: '일일 한도',
  dashboard: '계기반',
  date: '날짜',
  dateEnd: '날짜 종료',
  dateOfBirth: '생일',
  dateOfBirthInvalid: '유효한 생년월일을 입력하세요.',
  dateOfBirthMax: '생년월일은 오늘보다 이전이어야 합니다.',
  dateStart: '날짜 시작',
  DEBIT: '차변',
  demoMode: '데모 모드',
  deposit: '보증금',
  depositAccept: '입금 수락',
  depositers: '예금자',
  depositersAmount: '예금자 금액',
  depositors: '예금자',
  depositReject: '입금 거부',
  description: '설명',
  discardChanges: '언어를 전환하면 변경한 내용이 모두 삭제됩니다.',
  DOB: '생년월일',
  domain: '도메인',
  domainName: '도메인 이름',
  downloadAsCSV: 'CSV로 다운로드',
  downloadAsCsv: 'CSV로 다운로드',
  downloadCsv: 'CSV로 다운로드',
  duplicates: '중복',
  edit: '편집하다',
  editProvider: '제공자 편집',
  email: '이메일',
  emailRequired: '이메일 필수',
  emergency: '비상',
  endDate: '종료일',
  english: 'English',
  enterAccountNumber: '계좌번호 입력',
  enterAmount: '금액을 입력하세요',
  enterBetLevel: '베팅 레벨 입력',
  enterBonusPercentage: '보너스 백분율을 입력하세요.',
  enterCasinoProviderName: '카지노 제공업체 이름을 입력하세요.',
  enterCompensationTarget: '보상 대상을 입력하세요',
  enterCorrectURL: '올바른 URL을 입력하세요!',
  enterCurrency: '통화 입력',
  enterDailyLimit: '일일 한도를 입력하세요.',
  enterDescription: '설명 입력',
  enterDomain: '도메인 입력',
  enterEmail: '이메일을 입력하세요',
  enterFirstName: '이름을 입력하세요',
  enterGameName: '게임 이름을 입력하세요',
  enterLastName: '성을 입력하세요',
  enterMaxBonusLimit: '최대 보너스 한도를 입력하세요',
  enterMinBalancePercentage: '최소 잔액 백분율(%) 입력',
  enterMinutesForBonusGiven: '주어진 보너스에 대한 분을 입력하세요',
  enterMonthlyLimit: '월별 한도를 입력하세요.',
  enterName: '이름을 입력하시오',
  enterOperatorGameId: '운영자 GameId 입력',
  enterPassword: '암호를 입력',
  enterReocurringDepositRate: '정기예금율(%)을 입력하세요.',
  enterRollingPercent: '롤링 퍼센트 입력',
  editDetails: '편집세부사항',
  enterSlug: '슬러그 입력',
  enterTitle: '제목 입력',
  enterUsername: '사용자 이름을 입력하세요',
  enterValidAmount: '유효한 금액을 입력하세요.',
  enterValidPhoneNumberWithCountryCode: '국가 코드와 함께 유효한 전화번호를 입력하세요.',
  enterWeeklyLimit: '주간 한도를 입력하세요.',
  enterWithdrawalRate: '인출률 입력',
  enterWithdrawLimit: '출금 한도 금액 입력',
  Failed: '실패한',
  fileFormatError: '업로드된 파일은 지원되지 않는 형식입니다.',
  fileFormatValidation: '업로드한 파일이 지원되지 않는 형식입니다.',
  fileRequired: '파일이 필요합니다.',
  fileSizeLessThan1MB: '파일 크기는 1MB 미만이어야 합니다.',
  fileSizeShouldBeLessThan1MB: '파일 크기는 1MB보다 작아야 합니다.',
  fileSizeValidation: '파일 크기는 1MB 미만이어야 합니다.',
  firstDepositors: '첫 번째 예금자',
  firstName: '이름',
  firstNameFormat: '알파벳과 공백만 허용되며 알파벳으로 시작해야 합니다.',
  firstNameMustBeAtleast3Characters: '이름은 3자 이상이어야 합니다.',
  firstNameMustBeLessThan25Characters: '이름은 25자 미만이어야 합니다.',
  firstNameRequired: '이름이 필요합니다!',
  friday: '금요일',
  from: '에서',
  fullName: '법적 이름',
  gallery: '갤러리',
  game: '게임',
  gameId: '게임 ID',
  gameName: '게임 이름',
  gameNameRequired: '게임 이름이 필요합니다',
  games: '계략',
  gameSubCategory: '게임 하위 카테고리',
  gender: '성별',
  genderRequired: '성별 필수',
  goBack: '돌아가기',
  greenFlag: '녹색 깃발',
  greenflag: '녹색 깃발',
  groupNameFormat: '알파벳, 숫자, 공백만 허용됩니다.',
  groupNameRequired: '그룹 이름 필수',
  hotTimeBonus: '핫타임 보너스',
  id: 'ID',
  imageHeightError: '이미지 높이는 다음 사이여야 합니다. {{minRequiredHeight}}px - {{maxRequiredHeight}}px!',
  imageWidthError: '이미지 너비는 다음 사이여야 합니다. {{minRequiredWidth}}px - {{maxRequiredWidth}}px!',
  inActive: '비활성',
  inactive: '비활성',
  instigator: '선동자',
  invalidAccountNumber: '잘못된 계좌번호',
  invalidDate: '잘못된 날짜',
  invalidEmail: '잘못된 이메일',
  invalidPassword: '유효하지 않은 비밀번호',
  ipAddress: 'IP 주소',
  isActive: '활성',
  isExpired: '만료됨',
  japanese: '일본어',
  korean: '한국인',
  koreanWon: '원화',
  kycDetails: 'KYC 세부정보',
  kycMethod: 'Kyc 방법',
  kycSettings: 'KYC 설정',
  kycStatus: 'Kyc 상태',
  language: '언어',
  languageCode: '언어 코드',
  languageModule: '언어 모듈',
  languageName: '언어 이름',
  languageRequired: '필요한 언어',
  languages: '언어',
  lastName: '성',
  lastNameFormat: '알파벳과 공백만 허용되며 알파벳으로 시작해야 합니다.',
  lastNameMustBeAtleast3Characters: '성은 3자 이상이어야 합니다.',
  lastNameMustBeLessThan25Characters: '성은 25자 미만이어야 합니다.',
  lastNameRequired: '성 필수!',
  limitMustBeAnInteger: '한도는 정수여야 합니다.',
  limitMustBePositiveNumber: '한도는 양수여야 합니다.',
  limitRequired: '필수 한도',
  loggedInCount: '로그인 횟수',
  logo: '심벌 마크',
  logout: '로그 아웃',
  Lost: '잃어버린',
  LOST: '잃어버린',
  maintenance: '유지',
  masterCasinoCategories: '마스터 카지노 카테고리',
  masterId: '마스터 아이디',
  max16Characters: '최대 16자',
  maxBonusLimitRequired: '최대 보너스 한도 필요',
  maxCharacters: '{{label}} 기껏해야 {{maxNumber}} 문자',
  maxDigits: '{{label}} 다음보다 작아야 합니다. {{maxNumber}} 숫자',
  maximum_bonus_limit: '최대 보너스 한도',
  maximumBonusLimit: '최대 보너스 한도',
  maxNumber: '맥스 {{maxNumber}} 숫자',
  message: '메시지',
  messagePlaceholder: '메시지 입력',
  min1LowerCase: '최소 1개의 소문자',
  min1Number: '최소 1개',
  min1SpecialCharacters: '최소 1개의 특수 문자',
  min1UpperCase: '최소 1 대문자',
  min4Characters: '최소 4자',
  min_balance_percentage: '최소 잔액 비율',
  minAge: '18세 이상이어야 합니다',
  minBalancePercentage: '최소 잔액 비율',
  minCharacters: '{{label}} 최소한이어야합니다 {{minNumber}} 문자',
  minDigits: '{{label}} 최소한이어야합니다 {{minNumber}} 숫자',
  minimum1BetLevelRequired: '최소 1개의 베팅 레벨 필요',
  minimum1SpinRequired: '최소 1회전 필요',
  minLowerCase: '최소 1개의 소문자',
  minNumber: '최소 1개',
  minSpecialCharacters: '최소 1개의 특수 문자',
  minUpperCase: '최소 1 대문자',
  minutes_for_bonus_given: '보너스 부여 시간(분)',
  minutesForBonusGiven: '보너스 제공 시간(분)',
  minutesForBonusGivenRequired: '보너스 부여 시간(분) 필요',
  monday: '월요일',
  monthly: '월간 간행물',
  monthly_limit: '월 한도',
  monthlyLimit: '월 한도',
  monthMustBeAnInteger: '월은 정수여야 합니다.',
  monthMustBePositiveNumber: '월은 양수여야 합니다.',
  monthRequired: '필요한 달',
  moreInfo: '더 많은 정보',
  mustBeAtLeast8Characters: '대문자 1개, 소문자 1개, 특수문자 1개, 숫자 1개를 포함하여 8자 이상이어야 합니다.',
  mustBeAtLeastCharacters: '{{label}} 최소한이어야합니다 {{minNumber}} 문자',
  mustBeAtMostCharacters: '{{label}} 기껏해야 {{maxNumber}} 문자',
  mustBeGreaterThan: '다음보다 커야 합니다.',
  mustBeGreaterThanOrEqualTo: '{value}보다 크거나 같아야 합니다.',
  mustBeGreaterThanZero: '금액은 양수여야 합니다.',
  mustBeLesserThanCharacters: '미만이어야 합니다. {{maxNumber}} 캐릭터!!',
  mustBeNumber: '숫자여야 합니다.',
  mustBeRealAmount: '실제 금액이어야 합니다.',
  na: '해당 없음',
  name: '이름',
  NEW: '새로운',
  newPasswordRequired: '새로운 비밀번호가 필요합니다!',
  next: '다음',
  no: '아니요',
  noDataFound: '데이터가 없습니다',
  noImageFound: '이미지를 찾을 수 없습니다',
  nonCashAmount: '비현금금액',
  sportsPromotionCash: '체육진흥현금',
  casinoPromotionCash: '카지노 프로모션 캐시',
  nonDepositors: '비예금자',
  none: '없음',
  nonRequest: '비요청',
  noProvidersAvailable: '이용 가능한 제공업체 없음',
  normal: '정상',
  notAllowedToView: '이 콘텐츠를 볼 수 없습니다.',
  note: '메모: ',
  notFound: '찾을 수 없음',
  notification: '공고',
  notificationCategory: '알림 카테고리',
  notificationCategoryMessageRequired: '알림 카테고리 메시지 필수',
  notificationCategoryRequired: '알림 카테고리 필수',
  notificationCenter: '알림 센터',
  notificationMessage: '알림 메시지',
  notificationMessageRequired: '알림 메시지 필요',
  notificationRecipients: '알림 수신자',
  notificationRequired: '통지 필요',
  OLD: '오래된',
  oldAndNewPasswordMustBeDifferent: '이전 비밀번호와 새 비밀번호는 달라야 합니다!',
  oldPasswordRequired: '이전 비밀번호가 필요합니다!',
  onlinePlayer: '온라인 플레이어',
  onlineUsersDemograph: '온라인 사용자 인구통계',
  onlyAlphabets: '알파벳만 허용됨',
  onlyAlphabetsAllowed: '알파벳만 허용됨',
  onlyAlphabetsAndNumbers: '알파벳과 숫자만 허용됩니다.',
  onlyAlphabetsAndSingleSpace: '단어 사이에는 알파벳과 공백 하나만 허용됩니다.',
  onlyAlphabetsNumbersAndUnderscore: '알파벳, 숫자, 밑줄(_)만 허용됩니다.',
  onlyAlphabetsSpaceAndDash: '알파벳, 공백, 대시(-)만 허용됩니다.',
  onlyNumbersAllowed: '숫자만 허용됨',
  onlyNumbersAreAllowed: '숫자만 허용됩니다.',
  operatorGameId: '운영자 게임 ID',
  operatorGameIdRequired: '운영자 게임 ID가 필요합니다',
  operatorStatus: '운영자 상태',
  origin: '기원',
  other: '다른',
  overview: '개요',
  owner: '소유자',
  ownerIdRequired: '소유자 ID 필수',
  ownerName: '소유자 이름',
  pages: '페이지',
  password: '비밀번호',
  passwordMatchError: '비밀번호가 일치해야합니다',
  passwordRequired: '비밀번호가 필요합니다',
  passwordRequirements: '비밀번호요구 사항',
  passwordsMustMatch: '비밀번호가 일치해야합니다',
  pending: '보류 중',
  Pending: '보류 중',
  pendingKyc: 'KYC 보류 중',
  percentGreaterThanEqual: '%는 다음보다 크거나 같아야 합니다. {{percent}}',
  perPage: '페이지 당',
  phone: '핸드폰',
  phoneMustBeAtLeast5Digits: '전화번호는 5자리 이상이어야 합니다.',
  phoneMustBeLessThan13Digits: '전화번호는 13자리 미만이어야 합니다.',
  phoneNumber: '전화 번호',
  phoneRequired: '전화 필수',
  player: '플레이어',
  playerHistory: '플레이어 기록',
  playerName: '선수 이름',
  playerReferral: '플레이어 추천',
  players: '플레이어',
  playerStats: '플레이어 통계',
  playerWallet: '플레이어 지갑',
  portal: '문',
  portalName: '포털 이름',
  preferredLanguageRequired: '선호하는 언어가 필요합니다',
  primaryCurrency: '기본 통화',
  profile: '프로필',
  provider: '공급자',
  providerName: '제공자 이름',
  providers: '공급자',
  readership: '독자층',
  reason: '이유',
  reasonMax: '최대 100자',
  reasonRequired: '필수 사유',
  recipients: '수신자',
  recurringDepositRate: '정기예금율',
  redFlag: '빨간 깃발',
  redflag: '빨간 깃발',
  redFlagDescription: '위험 신호 설명',
  redFlagReason: '위험 신호 이유',
  redflagstatus: '위험 신호 상태',
  referralCode: '추천 코드',
  referralCount: '추천 수',
  referralLink: '관련 링크',
  referrals: '추천',
  registrationDate: '등록 날짜',
  registrationDateEnd: '등록일 종료',
  registrationDateStart: '등록일 시작',
  reject: '거부하다',
  rejected: '거부됨',
  rejectKyc: 'KYC 거부',
  rejectRequest: '요청 거부',
  removeAffiliate: '제휴사 제거',
  reoccuringBonus: '재발생 보너스',
  reoccurring_deposit_rate: '정기예금율',
  reoccurringDepositBonus: '재발생 예금 보너스',
  reporting: '보고',
  request: '요구',
  requestStatus: '요청 상태',
  required: '{{label}} 필수의',
  resetConfirmation: '재설정하시겠습니까? {{data}}',
  resetFilters: '필터 재설정',
  resetPassword: '암호를 재설정',
  resetPasswordFor: '비밀번호 재설정',
  roleRequired: '필요한 역할',
  RollBack: '롤백',
  rollingPercent: '롤링 퍼센트',
  rollingPercentDescription: '롤링 백분율 설명',
  saturday: '토요일',
  search: '찾다',
  searchByNameOrEmail: '이름이나 이메일로 검색',
  searchName: '이름 검색',
  searchPlaceholder: '검색자리 표시자',
  searchProvider: '검색 공급자',
  searchSubCategory: '하위 카테고리 검색',
  searchSubCategoryName: 'SearchSub 카테고리 이름',
  searchTitleSlug: '제목, 슬러그 검색',
  searchUsername: '사용자',
  searchUsernameOrName: '사용자 이름 또는 이름 검색',
  section: '부분',
  seen: '본',
  seenBy: '읽은 사람',
  seenStatus: '본 상태',
  segmentIdRequired: '세그먼트 ID가 필요합니다!',
  select_bonus_limit: '보너스 한도 선택',
  select_bonus_type: '보너스 유형 선택',
  select_owner: '소유자 선택',
  selectAtLeastOneDay: '하루 이상 선택하세요.',
  selectBank: '은행 선택',
  selectBonus: '보너스 선택',
  selectBonusLimit: '보너스 한도 선택',
  selectCategory: '카테고리 선택',
  selectCountry: '국가 선택',
  selectCurrency: '통화를 선택하세요',
  selectEndDate: '종료 날짜 및 시간 선택',
  selectEndDateAndTime: '종료 날짜 및 시간 선택',
  selectEndDateTime: '종료 날짜 및 시간 선택',
  selectLanguage: '언어 선택',
  selectNotificationCategory: '알림 카테고리 선택',
  selectOneCategory: '하나의 카테고리를 선택하세요',
  selectOneSenior: '시니어 1명 선택',
  selectOwner: '소유자 선택',
  selectPrimaryCurrency: '기본 통화 선택',
  selectProvider: '제공업체 선택',
  selectStartDate: '시작 날짜 및 시간 선택',
  selectStartDateAndTime: '시작 날짜 및 시간 선택',
  selectStartDateTime: '시작 날짜 및 시간 선택',
  selectSubCategory: '하위 카테고리 선택',
  selectTenant: '테넌트 선택',
  seletProvider: '제공업체 선택',
  selectSenior: '시니어를 선택하세요',
  sendNotification: '알림 보내기',
  sendPasswordResetEmail: '비밀번호 재설정 이메일 보내기',
  senior: '상위',
  seniorRequired: '시니어 필수',
  sent: '전송된',
  setStatusActive: '상태를 활성으로 설정',
  setStatusInactive: '상태를 비활성으로 설정',
  signInIP: '로그인 IP',
  slug: '강타',
  slugMaxLength: '슬러그는 최대 30자(영문 기준)여야 합니다.',
  slugMinLength: '슬러그는 3자 이상이어야 합니다.',
  slugRequired: '슬러그가 필요합니다',
  slugValidFormat: '유효한 URL 슬러그를 입력하세요.',
  smsRequired: 'SMS 필요',
  sNo: '번호',
  sno: '번호',
  spaceNotAllowed: '공간이 허용되지 않음',
  spinsMustBeAnIntegerValue: '스핀은 정수 값이어야 합니다',
  spinsMustBeAPositiveNumber: '스핀은 양수여야 합니다.',
  spinsRequired: '필요한 스핀',
  startDate: '시작일',
  status: '상태',
  stake: '말뚝',
  subCategories: '하위 카테고리',
  subCategory: '하위 카테고리',
  subCategoryName: '하위 카테고리 이름',
  subCategoryRequired: '하위 카테고리 필수',
  subject: '주제',
  submit: '제출하다',
  sunday: '일요일',
  superAdminUsernameFormat: '알파벳만 허용됨',
  taskOptions: '작업 옵션',
  tenant: '거주자',
  tenantAdmin: '테넌트 관리자',
  tenantManagement: '임차인 관리',
  tenantRequired: '임차인 필요',
  tenants: '임차인',
  tenantSenior: '세입자 노인',
  testEmail: 'test@yopmail.com',
  thumbnail: '썸네일',
  thursday: '목요일',
  timePeriodMustBeAnInteger: '기간은 정수여야 합니다.',
  timePeriodMustBePositiveNumber: '기간은 양수여야 합니다.',
  timePeriodRequired: '필수 기간',
  title: '제목',
  titleRequired: '영어 제목이 필요합니다',
  to: '에게',
  totalAmount: '총액',
  totalCount: '총 합계',
  totalGgr: '총 GGR',
  totalGGR: '총 GGR',
  totalGgrAmount: '총 GGR 금액',
  totalNgr: '총 NGR',
  totalNGR: '총 NGR',
  totalPlayer: '총 플레이어',
  totalUserCount: '총 사용자 수',
  totalWager: '총 베팅',
  totalWhitelisteCount: '총 화이트리스트 수',
  totalBetAmount: '총 베팅 금액',
  totalDepositAmount: '총 입금액',
  totalBonusAmount: '총 보너스 금액',
  transactionAction: '거래 조치',
  transactionId: '거래 ID',
  transactions: '거래',
  transactionStats: '거래 통계',
  transactionStatus: '거래현황',
  transactionType: '거래 유형',
  transactionTypeRequired: '거래 유형이 필요합니다.',
  tuesday: '화요일',
  undergoer: '언더 고어',
  uniqueNumberBonus: '고유번호 보너스',
  unseen: '보이지 않는 것',
  unsent: '보내지 않음',
  unsupportedFormat: '업로드된 파일은 지원되지 않는 형식입니다.',
  update: '업데이트',
  updateBanner: '배너 업데이트',
  updateCountryData: '국가 데이터 업데이트',
  updated: '업데이트됨',
  upload: '업로드',
  userHistory: '사용자 기록',
  userRankHistory: '사용자 순위 역사',
  userId: '사용자 ID',
  userID: '사용자 ID',
  userName: '사용자 이름',
  username: '사용자 이름',
  usernameRequired: '사용자 이름이 필요합니다',
  userReporting: '사용자 보고',
  users: '사용자',
  userTransaction: '사용자 거래',
  validFrom: '유효',
  validFromDateRequired: '유효 날짜부터 필수',
  validFromMustBeGreaterThanOrEqualToTodaysDate: '유효 날짜는 오늘 날짜보다 크거나 같아야 합니다.',
  validInPromotions: '프로모션에 표시',
  validOnDays: '요일에 유효',
  validPhoneNumber: '국가 코드와 함께 유효한 전화번호를 입력하세요.',
  validTill: '까지 유효',
  validTo: '까지 유효하다',
  validToDateRequired: '유효 종료 날짜 필수',
  validToMustBeGreaterThanValidFromDate: '유효한 날짜는 유효한 날짜보다 커야 합니다.',
  validToMustBeInRangeOfOneMonthFromValidFromDate: '유효 기간은 유효 시작 날짜로부터 1개월 이내여야 합니다.',
  validUsername: '유효한 사용자 이름을 입력하세요',
  vaultAmount: '금고 금액',
  vaultDeposit: '금고 예금',
  vaultWithdraw: 'Vault WithDraw',
  view: '보다',
  viewDetails: '세부 정보보기',
  viewReferredUser: '추천 사용자 보기',
  viewTenants: '테넌트 보기',
  viewTickets: '티켓 보기',
  viewUser: '사용자 보기',
  wageringTypeRequired: '베팅 유형 필수',
  wagerType: '베팅 유형',
  wallet: '지갑',
  walletId: 'ウォレットID지갑 ID',
  walletRequest: '지갑 요청',
  walletStats: '지갑 통계',
  walletTypeRequired: '지갑 유형 필요',
  wednesday: '수요일',
  weekly: '주간',
  weeklyLimit: '주간 한도',
  whiteList: '바람직한 것의 리스트',
  whitelist: '바람직한 것의 리스트',
  Win: '이기다',
  WIN: '이기다',
  withdraw: '철회하다',
  withdrawAccept: '철회 수락',
  withdrawal: '철수',
  withdrawal_rate: '인출률',
  withdrawalCurrency: '출금통화',
  withdrawalLimit: '출금 한도 금액',
  withdrawalRate: '인출률',
  withdrawLimit: '출금한도',
  withdrawlLimit: '출금한도',
  withdrawReject: '철회 거부',
  withdrwalLimit: '출금한도',
  xxxXxxxxxxxx: '(xxx)-xxxxxxxx',
  yes: '예',
  zipCode: '우편 번호',
  zipCodeMax: '최대 6자',
  zipCodeRequired: '우편번호 필수',
  hot_time_bonus: '핫타임 보너스',
  compensation_bonus: '보상 보너스',
  unique_number_bonus: '고유번호 보너스',
  re_occurring_bonus: '반복 보너스',
  mark: '표시',
  selectReason: '이유 선택',
  bonusAbuser: '보너스 남용자',
  duplicateAccount: '중복 계정',
  fraudulent: '사기적인',
  riskCountry: '위험 국가',
  rudeBehaviour: '무례한 행동',
  bannedCountryConnectWithVpnOrTorBrowser: '금지된 국가 - VPN 또는 Tor 브라우저로 연결',
  kycDeclined: 'KYC 거부됨',
  suspiciousConstantDepositsAndWithdrawalsMoneyLaundering: '의심스러운 - 계속되는 입출금 (자금 세탁)',
  contactsSupportForABiggerAmountOfDepositThanAllowed: '허용된 금액보다 큰 입금을 위해 지원팀과 연락',
  responsibleGambling: '책임 있는 도박',
  frozen: '동결됨',
  deactivated: '비활성화됨',
  permabanned: '영구 제한',
  tempbannedBlocked: '일시적으로 제한됨/차단됨',
  gamblingIssues: '도박 문제',
  selfExcluded: '자체 제외',
  dpoErasureRequested: 'DPO 삭제 요청됨',
  kycBlockedSuspended: 'KYC 차단/일시정지됨',
  kycBlockedDuplicate: 'KYC 차단/중복됨',
  kycBlockedBonusAbuse: 'KYC 차단/보너스 남용',
  kycBlockedChargeback: 'KYC 차단/청구 취소',
  kycFraud: 'KYC 사기',
  failedKyc: 'KYC 실패',
  amlFailedSow: 'AML 실패/SOW',
  amlManagementBan: 'AML 관리 제한',
  amlOnHold: 'AML 대기 중',
  amlUnderReview: 'AML 검토 중',
  rgUnderaged: 'RG 미성년',
  rgUnderReview: 'RG 검토 중',
  enforcedSelfExclusion: '강제 자체 제외',
  locationBan: '위치 제한',
  csManagementBan: 'CS 관리 제한',
  validated: '검증됨',
  returnToPlayer: '리턴 투 플레이어',
  systemRtp: '시스템 RTP',
  confirmReject: '비밀번호 요청을 거부하고 있습니다',
  // Form validation
  usernameLabel: '사용자명',
  passwordLabel: '비밀번호',
  oldPasswordLabel: '이전 비밀번호',
  newPasswordLabel: '새 비밀번호',
  confirmPasswordLabel: '비밀번호 확인',
  confirmNewPasswordLabel: '새 비밀번호 확인',
  idOfUserLabel: '사용자 ID',
  legalNameLabel: '법적 이름',
  nationalityLabel: '국적',
  dateOfBirthLabel: '생년월일',
  selectMonth: '월 선택',
  selectDay: '일 선택',
  selectYear: '년 선택',
  phoneLabel: '전화번호',
  preferredCurrencyLabel: '선호 통화',
  bankNameLabel: '은행 이름',
  accountNumberLabel: '계좌 번호',
  withdrawalPasswordLabel: '출금 비밀번호',
  reEnterwithdrawalPasswordLabel: '출금 비밀번호 재입력',

  // 가입 및 계정 정보
  signIn: '로그인',
  signUp: '가입',
  userIdPlaceholder: '사용자 ID 입력',
  accountFrozen: '귀하의 계정은 다음까지 동결됩니다',
  userLoggedOut: '성공적으로 로그아웃되었습니다',
  loggingIn: '제출 중입니다. 잠시 기다려주세요...',
  selectNationality: '국적 선택',
  selectBankName: '은행 이름 선택',
  selectPreferredCurrency: '선호하는 통화 선택',
  dontHaveAccount: '계정이 없으신가요?',
  registerAnAccount: '계정 등록하기',
  alreadyHaveAnAccount: '이미 계정이 있으신가요?',
  fillAllFields: '가입 전에 모든 필수 필드를 작성하십시오.',
  userNamePlaceholder: '사용자명 입력',
  min8Numbers: '최소 8자리 이상이어야 합니다',
  passwordValidationMessage: '비밀번호는 적어도 하나의 대문자, 하나의 숫자 및 하나의 특수 문자를 포함하여 4자 이상이어야 합니다. 공백, 점 및 쉼표는 허용되지 않습니다.',
  withdrawlPasswordValidationMessage: '출금 비밀번호는 적어도 하나의 대문자, 하나의 숫자 및 하나의 특수 문자를 포함하여 4자 이상이어야 합니다. 공백, 점 및 쉼표는 허용되지 않습니다.',
  passwordPlaceholder: '비밀번호 입력',
  confirmPasswordPlaceholder: '비밀번호 확인 입력',
  passwordWithdrawalPlaceholder: '출금 비밀번호 입력',
  oldPasswordPlaceholder: '이전 비밀번호 입력',
  newPasswordPlaceholder: '새 비밀번호 입력',
  confirmNewPasswordPlaceholder: '새 비밀번호 확인 입력',
  confirmPasswordWithdrawalPlaceholder: '출금 비밀번호 확인 입력',
  legalNamePlaceholder: '법적 이름 입력',
  phonePlaceholder: '전화번호 입력',
  searchPhonePlaceholder: '국가 검색',
  accountNumberPlaceholder: '계좌 번호 입력',
  userIdRequired: '사용자 ID 필수',
  userIdInvalid: '알파벳과 숫자만 허용됩니다',
  passwordWithdrawalRequired: '출금 비밀번호 필수',
  confirmPasswordWithdrawalRequired: '출금 비밀번호 확인 필수',
  legalNameRequired: '법적 이름 필수',
  dateOfBirthRequired: '생년월일 필수',
  userNameRequired: '사용자명 필수',
  requiredAccountNumber: '계좌 번호 필수',
  matchPassword: '비밀번호가 일치해야 합니다',
  matchWithdrawalPassword: '출금 비밀번호가 일치해야 합니다',
  onlyAlphabet: '알파벳만 허용됩니다',
  mustBe18: '등록하려면 18세 이상이어야 합니다',
  validDOB: '유효한 생년월일을 입력하세요',
  max20Characters: '최대 20자까지 허용됩니다',
  min3Characters: '최소 3자 이상이어야 합니다',
  min5Numbers: '국제 전화 코드를 포함한 최소 5자리 이상이어야 합니다',
  onlySpaceNotAllowed: '공백만 허용되지 않습니다',
  max100Characters: '최대 100자까지 허용됩니다',
  max50Characters: '최대 50자까지 허용됩니다',
  max30Characters: '최대 30자까지 허용됩니다',
  max16Numbers: '국제 전화 코드를 포함한 최대 16자리까지 허용됩니다',
  preferredCurrencyRequired: '선호하는 통화 필수',
  bankNameRequired: '은행 이름 필수',
  nationalityRequired: '국적 필수',
  legalName: '알파벳과 공백만 허용됩니다',
  legalNameOneSpace: '단어 사이에는 하나의 공백만 허용됩니다',
  selectPreferredLanguage: '선호하는 언어 선택',
  enterValidPhone: '유효한 전화번호를 입력하세요',
  userNameAllowed: '소문자 알파벳만 허용되며 공백은 허용되지 않습니다',
  validName: '사용자 이름은 숫자 또는 공백으로 시작할 수 없습니다.',
  userCreatedSuccess: '사용자가 성공적으로 생성되었습니다',
  userNameAlreadyExists: '사용자 이름이 이미 존재합니다',
  UserCountryCodeWithPhoneAlreadyExists: '국가 코드와 전화 번호를 가진 사용자가 이미 존재합니다',
  tenanatAreBlocked: '테넌트가 차단되었습니다',
  addNewPlayer: '새로운 플레이어 추가',
  markedAsInspection: '검사로 표시하기',
  markedSuccess: '성공적으로 표시됨',
  markedRemovedSuccess: '검사에서 성공적으로 제거됨',
  areYouSureChangeInspectionStatus: '검사 상태를 업데이트하시겠습니까?',
  inspection: '검사',
  addNote: '메모를 추가',
  enterNote: '메모 입력',
  addNoteFor: '다음에 대한 메모 추가',
  enterNoteRequired: '참고 필수',
  editUserKYC: 'KYC 편집',
  superadminNotes: '관리자 메모',
  seniorNotes: '임차인 메모',
  enterLegalName: '법적 이름을 입력하세요',
  enterBankName: '은행명을 입력하세요',
  enterPhoneNumber: '코드와 함께 전화번호를 입력하세요',
  kycEdit: 'Kyc 편집',
  accountNumberRequired: '계좌번호 필수',
  enterPhoneCode: '전화번호',
  PENDING: '보류 중',
  APPROVED: '승인하다',
  REJECTED: '거부하다',
  min2Characters: '최소 2자',
  type: '유형',
  select: '선택',
  banner: '배너',
  Update: '업데이트',
  Create: '생성',
  imageFormatRequired: '이미지는 jpg, png, webp 형식이어야 합니다',
  imageWidthBetween: '이미지 너비는 다음 사이어야 합니다',
  imageHeightBetween: '이미지 높이는 다음 사이어야 합니다',
  homePageTopBanner: '홈 페이지 상단 배너',
  homePageSportsBanner: '홈 페이지 스포츠 배너',
  homePageCasinoBanner: '홈 페이지 카지노 배너',
  casinoTopBanner: '카지노 상단 배너',
  casinoEntryModel: '카지노 입장 배너',
  popupBanner: '팝업 배너',
  withdrawalLimitTime: '출금 제한 시간',
  enterWithdrawalLimitTime: '출금 제한 시간 입력',
  withdrawalLimitTimeUnit: '출금 제한 시간 단위',
  selectTimeUnit: '출금 제한 시간 단위 선택',
  phoneCode: '전화번호',
  inspectionDetails: '검사 내용',
  kycSeniorStatus: 'Kyc 시니어 지위',
  kycStatusSenior: 'Kyc 시니어 지위',
  kycStatusAdmin: 'Kyc 상태 관리자',
  valueMustBeTwoDigitAfterDecimal: '소수점 이하 2자리만 허용',
  valueMustBePositive: '값은 양수여야 합니다.',
  valueMustBeNumber: '값은 숫자여야 합니다.',
  nonCashRequest: '비현금 요청',
  userWalletTransaction: '사용자 지갑 거래',
  transaction: '거래',
  walletPlayer: '월렛 플레이어',
  walletSenior: '시니어 지갑',
  rejectTime: '시간 제한 초과로 인해 거부됨',
  gameWithCustomRtp: '맞춤 RTP를 사용한 게임',
  firstDepositBonus: '첫 입금 보너스',
  phoneCodeTooShort: '전화 코드가 너무 짧습니다',
  phoneCodeTooLong: '전화 코드가 너무 깁니다',
  phoneCodeNoSpaces: '공백을 포함할 수 없습니다',
  phoneCodeRequired: '전화 코드를 입력해주세요',
  ipLimit: '허용되는 IP 수',
  ipLimitPlaceholder: '허용되는 IP 수 입력',
  minIpLimit: '최소 1개의 IP가 허용되어야 합니다',
  maxIpLimit: '허용되는 최대 IP 수는 10개입니다',
  seniorTransactionStats: '고급 거래 통계',
  reportTransaction: '거래 보고서',
  walletTransaction: '지갑 거래',
  reportFor: '보고서 대상',
  totalDepositCount: '총 예금 코인',
  totalWithdrawlAmount: '총 출금 금액',
  totalWithdrawalCount: '총 출금 횟수',
  changePassword: '비밀번호 변경',
  resetAccept: '비밀번호 재설정 수락',
  resetReject: '비밀번호 재설정 거부',
  resetPending: '비밀번호 재설정 대기 중',
  createVoucher: '바우처 생성',
  voucherName: '바우처 이름',
  voucherValidFrom: '바우처 유효 시작일',
  voucherValidTo: '바우처 유효 종료일',
  betTarget: '배팅 대상',
  betTargetPlaceholder: '배팅 대상 입력',
  voucher: '보증인',
  voucherBenefits: '바우처 혜택 금액',
  editVoucher: '바우처 수정',
  voucherDetails: '바우처 세부 정보',
  voucherUsers: '바우처 사용자',
  voucherGames: '바우처 게임',
  benefitType: '바우처 혜택 유형',
  benefitTypePlaceholder: '바우처 혜택 유형 입력',
  voucherUseLimit: '바우처 사용 한도',
  levelingSystem: '레벨링 시스템',
  templates: '템플릿',
  template: '템플릿',
  selectTemplate: '템플릿 선택',
  templateRequired: '템플릿 필요',
  seasons: '시즌',
  listOfTemplates: '템플릿 목록',
  listOfSeasons: '시즌 목록',
  addTemplate: '템플릿 추가',
  addSeason: '시즌 추가',
  editTemplate: '템플릿 편집',
  editSeason: '시즌 편집',
  requiredAndGreaterThan0: '필요하며 0보다 커야 함',
  nameRequired: '이름 필요',
  ranks: '랭크',
  nameOfSeason: '시즌 이름',
  nameOfTemplate: '템플릿 이름',
  detailsOfSeason: '시즌 상세',
  detailsOfTemplate: '템플릿 상세',
  sizeTooLarge: '크기가 너무 큽니다',
  mustBePositive: '양수여야 합니다',
  updateSuccess: '성공적으로 업데이트되었습니다',
  createSuccess: '성공적으로 생성되었습니다',
  season: '시즌',
  makeActive: '활성화하기',
  makeInActive: '비활성화하기',
  confirmStatus: '상태를 변경하시겠습니까?',
  oneFieldRequired: '보너스, 현금 및 출금 한도 중 하나의 필드가 필요합니다',
  thresholdAmount: '임계값 금액',
  maximum100: '퍼센트 값은 100을 초과할 수 없습니다',
  deleteTemplate: '템플릿 삭제',
  deleteSeason: '시즌 삭제',
  enterPercentage: '퍼센트를 입력하세요',
  base: '기본',
  searchByTemplateAndSeason: '템플릿 및 시즌으로 검색',
  searchByTemplate: '템플릿으로 검색',
  voucherUseLimitRequired: '바우처 사용 한도가 필요합니다',
  benefitTypeRequired: '바우처 혜택 유형이 필요합니다',
  minValue: '0보다 큰 값을 입력하세요',
  voucherRequired: '바우처 이름이 필요합니다',
  betTargetRequired: '배팅 목표가 필요합니다',
  voucherBenefitsRequired: '바우처 혜택 금액이 필요합니다',
  BONUS: '보너스',
  CASH: '현금',
  'WITHDRAWAL-LIMIT': '출금 한도',
  selectBenefitType: '혜택 유형 선택',
  enterBenefitValue: '혜택 값을 입력하세요',
  bonusIsInPercent: '보너스는 백분율이며 값이 100을 초과할 수 없습니다',
  benefitNote: '참고: 혜택 유형 보너스는 백분율이며 값이 100을 초과할 수 없습니다',
  benefit: '혜택',
  benefitsType: '혜택 유형',
  integerValueError: '정수 값이어야 합니다',
  voucherTransactions: '바우처 거래',
  endDateMustBeAfterStartDate: '종료 날짜는 시작 날짜 이후여야',
  levelWithdrwalLimit: '레벨 출금 한도',
  greaterThan0: '0보다 커야 합니다',
  affiliateManagement: '제휴 관리',
  affiliate: '제휴사',
  primaryLanguage: '기본 언어',
  selectPrimaryLanguage: '기본 언어 선택',
  dateOfJoining: '가입 날짜',
  affiliateId: '제휴사 ID',
  enterAffiliateId: '제휴사 ID 입력',
  idAffiliateRequired: '제휴사 ID가 필요합니다',
  primaryLanguageRequired: '기본 언어가 필요합니다',
  userTypeRequired: '사용자 유형이 필요합니다',
  idRequired: 'ID가 필요합니다',
  agentNameRequired: '대리인 이름이 필요합니다',
  dateRequired: '날짜가 필요합니다',
  invalidDateFormat: '잘못된 날짜 형식',
  signInAsSuperAdmin: '슈퍼 관리자로 로그인',
  signInAsTenant: '임차인으로 로그인',
  signInAsAffiliate: '제휴사로 로그인',
  emailOrUsername: '이메일 또는 사용자 이름',
  loginCode: '로그인 코드',
  enterLoginCode: '로그인 코드를 입력하세요',
  loginCodeRequired: '로그인 코드 필수',
  emailOrUsernameRequired: '이메일 또는 사용자 이름 필수',
  agentIdRequired: 'Agent id required',
  fromDate: '시작 날짜',
  toDate: '종료 날짜',
  parentRole: '상위 역할',
  joinDate: '가입 날짜',
  affiliateToken: '제휴사 토큰',
  parent: '상위',
  parentUsername: '상위 사용자 이름',
  agentManagement: '에이전트 관리',
  agent: '에이전트',
  agentWithoutAffiliate: '제휴사가 없는 에이전트',
  selectAffiliate: '제휴사 선택',
  agentId: '에이전트 ID',
  enterAgentId: '에이전트 ID 입력',
  SignInOption: '로그인 옵션',
  signInAsAgent: '에이전트로 로그인',
  SignIn: '로그인',
  selectAgent: '선택 대리인',
  startSeason: '시즌 시작',
  startEnd: '시작 끝',
  playerRankIncrement: '플레이어 순위 증가',
  bonusBenefitClaim: '보너스 혜택 청구',
  cashBenefitClaim: '현금 혜택 청구',
  withdrawlBenefitClaim: '인출 혜택 청구',
  riskManagement: '위험 관리',
  securityAlerts: '보안 경고',
  actionStatus: '행동 상태',
  actionTaken: '조치 완료',
  superadmin: '슈퍼 관리자',
  admin: '관리자',
  giftCard: '기프트 카드',
  redemptionDate: '상환일',
  voucherDeposit: '바우처 입금',
  giftcardDeposit: '기프트 카드 입금',
  listOfRollingCompetetion: '롤링 대회 목록',
  betAmount: '베팅 금액',
  enterBetAmount: '베팅 금액 입력',
  rollingCompetetion: '롤링 대회',
  details: '세부 정보',
  rollingcontestReport: '롤링 콘테스트 보고서',
  contestName: '콘테스트 이름',
  registerUser: '참가자 등록',
  targetAmount: '목표 금액',
  currentAmount: '현재 금액',
  multipliedOdds: '승산 확률',
  winningAmount: '당첨금액',
  won: '이겼다',
  lost: '잃어버린',
  refund: '환불하다',
  cashout: '현금 인출',
  half_won: '반원',
  half_lost: '절반의 손실',
  single: '단일',
  multiple: '다중',
  sportsReporting: '스포츠 보도',
  betslipId: '배팅티켓 ID',
  eventId: '이벤트 ID',
  eventMarketId: '이벤트 마켓 ID',
  eventMarketOutcomeId: '이벤트 마켓 결과 ID',
  odds: '배당률',
  betSettlementStatus: '배팅 결제 상태',
  mtsStatus: 'MTS 상태',
  in_game: '게임 중',
  sportsTransactions: '스포츠 거래',
  betDetails: '베팅 세부정보',
  betTransactions: '베팅 거래',
  winner: '승리',
  loser: '패배',
  canceled: '취소됨',
  cash_out: '현금화',
  placed: '배팅 완료',
  rollback: '롤백',
  agent_commission: '에이전트 수수료',
  journalEntry: '저널 항목',
  credit: '신용',
  debit: '차변',
  currentBalance: '현재 잔액',
  currencyCode: '통화 코드',
  beforeBalance: '이전 잔액',
  sports: '스포츠',
  countries: '국가',
  matches: '경기',
  leagues: '리그',
  markets: '시장',
  sportsbookManagement: '스포츠북 관리',
  searchSports: '스포츠 검색',
  sportsStatus: '스포츠 상태',
  sportName: '스포츠 이름',
  providerSportId: '제공업체 스포츠 ID',
  searchCountries: '국가 검색',
  countryStatus: '국가 상태',
  providerCountryId: '제공업체 국가 ID',
  tournamentName: '토너먼트 이름',
  matchTime: '경기 시간',
  homeTeam: '홈 팀',
  awayTeam: '원정 팀',
  liveStatus: '라이브 상태',
  searchMarkets: '시장 검색',
  marketName: '시장 이름',
  providerMarketId: '제공업체 시장 ID',
  Enable: '사용',
  Disable: '사용 안 함',
  enabled: '활성화됨',
  disabled: '비활성화됨',
  live: '실시간',
  notLive: '프리 매치',
  searchTournaments: '토너먼트 검색',
  event: '이벤트',
  market: '마켓',
  selection: '선택',
  thumbnailRequired: '썸네일 필요합니다!',
  editSport: '스포츠 편집',
  editLeague: '리그 편집',
  setEnable: '사용 설정',
  setDisable: '사용 해제',
  reorder: '재 주문',
  reorderLeagues: '리그 재정렬',
  reorderSports: '스포츠 재정렬',
  reorderNote: '참고: 순서를 변경하려면 위아래로 드래그 앤 드롭하세요.',
  agentToken: '에이전트 토큰',
  incorrectPasswordLimit: '허용된 잘못된 비밀번호 시도 횟수',
  incorrectPasswordLimitPlaceholder: '허용된 잘못된 비밀번호 시도 횟수를 입력하세요',
  noNotification: '아직 알림이 없습니다',
  eventDate: '이벤트 날짜',
  eventDescription: '이벤트 설명',
  events: '이벤트',
  totalWithDrawlAmount: '총출금액',
  totalNonCashAmount: '총비현금액',
  inspectedAmount: '검사된금액',
  inspectedData: '검사된 데이터',
  viewInspectedData: '검사된 데이터 보기',
  delete: '삭제',
  enterLimitValue: '한도 값 입력',
  customLimit: '맞춤 한도',
  enterValue: '값 입력',
  enterValueInPercent: '퍼센트로 값 입력',
  mustbeValidDate: '유효한 날짜여야 합니다',
  fieldRequired: '필수 필드입니다',
  maxBonusLimitGreaterThan: '최대 보너스 한도는 한도 값보다 커야 합니다',
  reOccuringDepositBonus: '반복 입금 보너스',
  uniqueTicketBonus: '유니크 티켓 보너스',
  deleteSuccess: '성공적으로 삭제되었습니다',
  bonusNotification: '보너스 알림',
  allNotification: '모든 알림',
  bulkUpdate: '대량 업데이트',
  selectMoreThanOne: '대량 업데이트하려면 둘 이상을 선택하십시오',
  minimumValue0: '최소 값은 0입니다',
  maximumValue100: '최대 값은 100입니다',
  maximumValue999: '최대값은 999입니다.',
  maximumValue100000: '최대값은 100000.',
  maximumValue9: '최대값은9',
  maxValueIs: '최대 값은',
  validToLater: '유효 종료 날짜는 유효 시작 날짜 이후여야 합니다',
  affiliateAgent: '제휴 초대 에이전트',
  affiliatePlayer: '제휴 초대 플레이어',
  seniorPlayer: '시니어 초대 플레이어',
  bonusConfigured: '보너스 구성됨',
  true: '참',
  false: '거짓',
  confirmRemove: '제거하시겠습니까?',
  remove: '제거',
  removeSuccess: '성공적으로 제거됨',
  limitType: '제한 유형',
  'HOT-TIME-BONUS': '핫타임 보너스',
  'COMPENSATION-BONUS': '보상 보너스',
  'RE-OCCURING-DEPOSIT-BONUS': '재발생 예금 보너스',
  'FIRST-DEPOSIT-BONUS': '첫 예금 보너스',
  'UNIQUE-TICKET-BONUS': '고유 티켓 보너스',
  updatedAt: '업데이트 날짜',
  max50CharactersRequired: '이 필드의 최대 길이는 250자입니다',
  hotCombo: '핫 콤보',
  nameOfHotcombo: '핫 콤보 이름',
  sport: '스포츠',
  league: '리그',
  selectLeague: '리그 선택',
  match: '경기',
  selectMatch: '경기 선택',
  selectMarket: '마켓 선택',
  createHotCombo: '핫 콤보 생성',
  hotComboDetails: '핫 콤보 세부 정보',
  matchRequired: '경기를 선택해 주세요',
  marketRequired: '마켓을 선택해 주세요',
  outcomeError: '최소 2개에서 최대 4개의 결과를 선택해 주세요',
  comboName: '콤보 이름',
  outcomeName: '결과 이름',
  sportsTransactionStats: '스포츠북 거래 통계',
  partial_won: '부분 승리',
  completed: '완료',
  betSlipStatus: '베팅 슬립 상태',
  WITHDRAW: '출금',
  DEPOSIT: '입금',
  'VAULT-DEPOSIT': '금고 입금',
  'VAULT-WITHDRAW': '금고 출금',
  'HOT-TIME-BONUS-DEPOSIT': '핫 타임 보너스 입금',
  'REOCCURING-BONUS-DEPOSIT': '정기 보너스 입금',
  'FIRST-DEPOSIT-BONUS-DEPOSIT': '첫 입금 보너스 입금',
  promotionCash: '프로모션 현금',
  revenuePercentage: '수익 비율',
  withdrawlLimitCurrency: '출금 한도 통화',
  revenue: '수익',
  revenueDetails: '수익 세부사항',
  onlyOneSpaceBetweenWordRequired: '단어 사이에 한 칸의 공백만 허용됩니다',
  agentWithOutAffiliate: '제휴 없는 에이전트',
  useLimit: '사용 한도',
  benefitAmount: '혜택 금액',
  voucherNamePlaceholder: '이름 입력',
  voucherUseLimitPlaceholder: '사용 한도 입력',
  voucherBenefitsPlaceholder: '혜택 금액 입력',
  createGiftCard: '기프트 카드 생성',
  giftcardDetails: '기프트 카드 세부 정보',
  editGiftcard: '기프트 카드 수정',
  giftcardUsers: '기프트 카드 사용자',
  giftCardNameRequired: '기프트 카드 이름이 필요합니다',
  giftCardUseLimitRequired: '기프트 카드 사용 한도가 필요합니다',
  giftCardBenefitsRequired: '기프트 카드 혜택이 필요합니다',
  giftCardBenefitTypeRequired: '기프트 카드 혜택 유형이 필요합니다',
  giftCardTransaction: '기프트 카드 거래',
  giftCardName: '기프트 카드 이름',
  giftCardBenefits: '기프트 카드 혜택',
  somethingWentWrong: '뭔가 잘못됐어',
  playerRankHistory: '플레이어 랭크 기록',
  role: '역할',
  bannerUrl: '배너 URL',
  userNameFirstCharacterLetter: '첫 번째 문자는 알파벳이어야 합니다.',
  addGames: '새로운 게임 추가',
  gamesAdded: '추가된 게임',
  ifYouDontSelectReason: '에이전트나 제휴사를 선택하지 않으면 에이전트 및 제휴사에 포함되지 않습니다.',
  updatePlayerAffiliate: '플레이어 제휴사 업데이트',
  updatePlayerAgent: '플레이어 에이전트 업데이트',
  confirmOwnership: '소유권을 업데이트하시겠습니까?',
  depositTransactions: '입금 거래',
  withdrawalTransactions: '출금 거래',
  totalDepositRequest: '총 입금 요청',
  case: '케이스',
  now: '지금',
  userInfo: '사용자 정보',
  withdrawalHistory: '출금 내역',
  withdrawalRequest: '출금 요청',
  depositHistory: '입금 내역',
  depositRequest: '입금 요청',
  accountInfo: '계정 정보',
  totalWithdrawalRequest: '총 출금 요청',
  totalWithdrawalAmount: '총 출금 금액',
  seniorName: '상급자 이름',
  confirmWithdrawalPasswordLabel: '출금 비밀번호 확인',
  newWithdrawalPasswordLabel: '새 출금 비밀번호',
  vaultWithdrawalHistory: '금고 출금 내역',
  vaultDepositHistory: '금고 입금 내역',
  searchPage: '페이지 번호 입력',
  goToPage: '페이지로 이동',
  price: '가격',
  possibleWin: '가능한 승리',
  betSlipId: '베팅 슬립 ID',
  slotCatBonusPercent: '슬롯 카테고리 보너스 비율',
  liveCatBonusPercent: '라이브 게임 카테고리 보너스 비율',
  paybackBonus: '페이백 보너스',
  totalDepositMinusWithdrawal: '총 입차',
  withdrawalRequestTime: '출금 요청을 위한 최소 시간',
  withdrawalRequestTimeUnit: '시간 단위',
  enterWithdrawalRequestTime: '출금 요청을 위한 최소 시간을 입력하세요',
  bonusAmount: '보너스 금액',
  totalVaultAmount: '총 금고 금액',
  totalBonusWalletAmount: '총 보너스 금액',
  adminDepositSubpayment: '관리자 입금 보조 결제',
  adminWithdrawSubpayment: '관리자 출금 보조 결제',
  requestedCreatedAt: '요청 일시',
  actionTakenAt: '조치 일시',
  sportsbookTopBanner: '스포츠북 상단 배너',
  tournaments: '토너먼트',
  newGames: '새로운 게임',
  reset: '리셋',
  confirmCancelBetAndRefund: '플레이어에게 스테이크를 환불하고 베팅을 취소하시겠습니까?',
  editHotCombo: '핫 콤보 수정',
  comboEventsList: '핫 콤보 최종 이벤트 목록',
  clickToAddMoreEvents: '이벤트를 추가하려면 클릭하세요',
  teams: '팀',
  searchTeamByName: '팀 이름으로 검색',
  desktopImage: '데스크탑 이미지',
  mobileImage: '모바일 이미지',
  currentIPAddress: '현재 IP 주소',
  PrevIpAddress: '이전 IP 주소',
  unHideGame: '게임 숨기기 해제',
  hideGame: '게임 숨기기',
  hidden: '숨겨진 게임',
  notificationDetails: '알림 세부정보',
  chooseTenantAdmin: '시니어 선택',
  noteForCreateNotification: '참고: 사용자가 선택되지 않으면 메시지가 모두에게 전송됩니다. 진행하려면 시니어를 선택하세요.',
  getUser: '사용자 보기',
  back: '뒤쪽에',
  editNotification: '알림 수정',
  noPlayerSelected: '선택된 플레이어 없음',
  selectAgentWithoutAffiliate: '제휴사 없이 에이전트 선택',
  confirmSendNotification: '알림 전송 확인',
  previouslySelectedUser: '이전에 선택된 사용자',
  selectOtheruser: '다른 사용자 선택',
  selectAllOfThisPage: '이 페이지의 모든 항목 선택',
  dimensions: '크기',
  depositAddOnConfig: '입금 애드온 구성',
  selectDepositAddonType: '입금 애드온 유형 선택',
  addOnPercentage: '애드온 비율',
  addOnPercentagePlaceholder: '애드온 비율 입력',
  addOnRelativeAmount: '애드온 상대 금액',
  addOnRelativeAmountPlaceholder: '애드온 상대 금액 입력',
  rollingPercentage: '롤링 비율',
  rollingPercentagePlaceholder: '롤링 비율 입력',
  reoccurringDepositConfig: '재입금 애드온 구성',
  firstDepositConfig: '첫 입금 애드온 구성',
  defaultDepositConfig: '기본 입금 애드온 구성',
  addOnTypeRequired: '애드온 유형이 필요합니다',
  addOnPercentageRequired: '애드온 비율이 필요합니다',
  addOnRelativeAmountRequired: '애드온 상대 금액이 필요합니다',
  rollingPercentageRequired: '롤링 비율이 필요합니다',
  pleaseSelectTenant: '입금 애드온 구성을 추가/수정하려면 먼저 상급자를 선택하세요',
  selectAddOnConfigType: '애드온 구성 유형 선택',
  selectAddOnConfigValueType: '애드온 구성 값 유형 선택',
  addOnConfigType: '애드온 구성 유형',
  numberOfAcceptedRequests: '승인된 요청 수',
  addOnDepositSettings: '애드온 입금 설정',
  walletType: '지갑 유형',
  selectWalletType: '지갑 유형 선택',
  casinoBonus: '카지노 보너스',
  uniqueCodeBonus: '고유 코드 보너스',
  editUniqueCodeBonus: '고유 코드 보너스 수정',
  viewUniqueCodeBonus: '고유 코드 보너스 세부 사항',
  createUniqueCodeBonus: '고유 코드 보너스 생성',
  depositTarget: '입금 목표',
  couponName: '쿠폰 이름',
  remainingUses: '남은 사용 횟수',
  sportsbookBonus: '스포츠북 보너스',
  bonusName: '보너스 이름',
  searchMatch: '경기 검색',
  bonusImage: '보너스 로고',
  addOnBonus: '추가 오즈',
  enterBonusName: '보너스 이름을 입력하세요',
  pleaseSelectSenior: '이 필드를 활성화하려면 먼저 상급자를 선택하세요',
  valueWillConciderInPercentage: '값은 백분율로 고려됩니다',
  matchList: '경기 목록',
  selectedMatchList: '선택된 경기 목록',
  pleaseSelectMatch: '경기를 선택하세요',
  noMatchesAvailable: '이 선택에 사용할 수 있는 경기가 없습니다',
  bonusCreatedSuccessfully: '보너스가 성공적으로 생성되었습니다',
  bonusUpdatedSuccessfully: '보너스가 성공적으로 업데이트되었습니다',
  pleaseSelectAtLeastOneMatch: '경기 목록에서 최소 하나의 경기를 선택하세요',
  onlyOneMatchCanBeSelect: 'BETTING BONUS 유형에는 하나의 경기만 선택할 수 있습니다',
  enterOddValue: '오즈를 입력하세요',
  editSportsBonus: '스포츠북 보너스 수정',
  createSportsBonus: '스포츠북 보너스 생성',
  pleaseSelectAtLeastTwoMatch: 'MIXED MATCH BONUS를 위해 경기 목록에서 최소 두 경기를 선택하세요',
  sportsReportings: '스포츠 보고서',
  casinoReportings: '카지노 보고서',
  userReportings: '사용자 보고서',
  bonusReportings: '보너스 보고서',
  walletReportings: '지갑 보고서',
  applyAddon: '애드온 적용',
  depositAddonReporting: '입금 애드온 보고서',
  agentName: '에이전트 이름',
  affiliateName: '제휴사 이름',
  amountSelected: '선택된 금액',
  winningBonus: '승리 보너스',
  bettingBonus: '베팅 보너스',
  mixedMatchBonus: '혼합 경기 보너스',
  betCompenSationBonus: '베팅 보상 보너스',
  casinoBonusTransactions: '카지노 보너스 거래',
  sportsBonusTransactions: '스포츠북 보너스 거래',

  v2UserCasinoTransaction: 'V2 사용자 카지노 거래',
  pleaseSelectProviderType: '참고: 금액을 출금하려면 제공자 유형을 선택하십시오',
  selectProviderType: '제공자 유형 선택',
  bulkWithdraw: '대량 출금',
  totalWithdrawableAmount: '총 출금 가능 금액',
  casino: '카지노',
  remainingAmount: '남은 금액',
  withdrawalAmount: '출금 금액',
  payout: '지급',
  payoutHotTimeBonus: '핫타임 보너스 지급',
  payoutCompensationBonus: '보상 보너스 지급',
  payoutPaybackBonus: '페이백 보너스 지급',
  payoutPromotionCash: '프로모션 캐시 지급',
  nonWithdrawableWallet: '출금 불가 지갑',
  promotionCashWallet: '프로모션 캐시 지갑',
  bonusWallet: '보너스 지갑',
  payoutWallet: '지급 지갑',
  maxBonusLimit: '최대 보너스 한도',
  bonusPercent: '보너스 퍼센트',
  amountToBeAdded: '추가할 금액',
  addOnPercentageInvalid: '애드온 퍼센트는 3자리를 초과할 수 없으며, 음수이거나 문자를 포함할 수 없습니다.',
  rollingPercentageInvalid: '롤링 퍼센트는 3자리를 초과할 수 없으며, 음수이거나 문자를 포함할 수 없습니다.',
  addOnRelativeAmountInvalid: '금액은 양수여야 하며 6자리를 초과할 수 없으며 문자를 포함할 수 없습니다.',
  pleaseSpecifyDepositConfig: '입금 구성을 지정하세요.',
  promotionalTransaction: '프로모션 거래',
  vault: '금고',
  bonusReportV2: 'V2 사용자 보너스 보고서',
  logsReporting: '로그 보고',
  casinoLogs: '카지노 로그',
  errorLogs: '오류 로그',
  addOnAmount: '추가 금액',
  requestDetails: '요청 세부 정보',
  nicknameRequired: '닉네임이 필요합니다.',
  nickNameLabel: '닉네임',
  nickNamePlaceholder: '닉네임을 입력하세요',
  systemReturnToPlayer: '시스템 플레이어로 복귀',
  customizeRtpUpdate: '시스템 Rtp 업데이트 사용자 정의',
  customizeRtpUpdateMessage: '카테고리 및 제공자별로 시스템 RTP 업데이트',
  bulkUpdateRtpMessage: '선택한 카테고리의 시스템 RTP 업데이트',
  systemRtpRequired: '시스템 RTP 필요',
  valueMustNotExceed100: '값은 100을 초과할 수 없습니다.',
  enterSystemRtp: '시스템 RTP 입력',
  customLimitLessThan8digit: '사용자 정의 한도는 8자리 미만이어야 합니다.',
  minimumValue10: '최소 값은 10이어야 합니다.',
  maximumValue99: '최대 값은 99이어야 합니다.',
  minPercentageShouldBeGreaterThan99: '최소 퍼센트는 99보다 커야 합니다.',
  minPercentageShouldBeLesserThan1000: '최소 퍼센트는 1000보다 작아야 합니다.',
  anonymousUser: '익명 사용자',
  sportsTransactionsV2: '스포츠 거래 V2',
  payoutWinningBonus: '승리 보너스 지급',
  payoutBettingBonus: '베팅 보너스 지급',
  payoutMixedMatchBonus: '혼합 매치 보너스 지급',
  payoutBetCompenSationBonus: '배팅 보상 보너스 지급',
  promotionPayout: '프로모션 지급',
  bonusCash: '보너스 현금',
  bonusPayout: '보너스 지급',
  nonWithrawableRolledAmount: '출금 불가 롤링 금액',
  bonusRolledDetails: '보너스 롤링 상세',
  promotionRolledDetails: '프로모션 롤링 상세',
  migratePlayerToV2: '플레이어를 V2로 마이그레이션',
  rackBackBonus: '랙백 보너스',
  periodicPaybackBonus: '정기 페이백 보너스',
  'RACKBACK-BONUS': '랙백 보너스',
  'MONTHLY-PERIODIC-PAYBACK-BONUS': '월간 정기 페이백 보너스',
  'BI-WEEKLY-PERIODIC-PAYBACK-BONUS': '격주 정기 페이백 보너스',
  'WEEKLY-PERIODIC-PAYBACK-BONUS': '주간 정기 페이백 보너스',
  'DAILY-PERIODIC-PAYBACK-BONUS': '일일 정기 페이백 보너스',
  selectTenantBonus: '보너스를 지정하고 구성하려면 테넌트를 선택하세요.',
  rackbackPercent: '랙백 비율',
  dailyStackLimit: '일일 스택 한도',
  rackbackPercentPlaceholder: '랙백 비율을 입력하세요.',
  dailyStackLimitPlaceholder: '일일 스택 한도를 입력하세요.',
  anonymous: '익명',
  known: '알려진',
  sportsTransactionsBetBy: '스포츠 거래 베팅자',
  betTransactionsBetBy: '베팅 거래 베팅자',
  userMigrationReport: '사용자 마이그레이션 보고서',
  migrationHistory: '마이그레이션 기록',
  nonWithdrawableRolled: '인출 불가 압연',
  bonusRolled: '보너스 롤링',
  promotionRolled: '프로모션 압연',
  userRollTransactions: '사용자 롤 트랜잭션',
  walletLogs: '지갑 로그',
  user: '사용자',
  logType: '로그 유형',
  walletRequestId: '지갑 요청 ID',
  previousData: '이전 데이터',
  updateData: '업데이트 데이터',
  sportsPeriodicPaybackBonus: '스포츠 주기적 환급 보너스',
  sportsPromotionCashConfig: '스포츠 프로모션 현금 설정',
  pleaseSelectTenantForPromotionCashConfig: '프로모션 현금 설정을 추가/수정하려면 먼저 시니어를 선택하세요',
  sportsRollingPercentage: '스포츠 롤링 비율',
  sportsBookProviders: '스포츠 북 프로바이더',
  withdrawableWallet: '출금 가능한 지갑',
  totalNonWithdrawableAmount: '출금 불가 총액',
  totalPromotionCash: '총 프로모션 캐시',
  totalPayoutAmount: '총 지급 금액',
  playersV1: 'V1 플레이어',
  playersV2: 'V2 플레이어',
  kycApprovedPlayers: 'KYC 승인 플레이어',
  kycRejectedPlayers: 'KYC 거부 플레이어',
  kycPendingPlayers: 'KYC 대기 중인 플레이어',
  V2transactions: 'V2 거래',
  markAllRead: '모두 읽음으로 표시',
  deleteAll: '모두 삭제',
  notificationMarkedAllAsRead: '모두 읽음으로 성공적으로 표시되었습니다',
  notificationDeleteAllSuccess: '알림이 모두 성공적으로 삭제되었습니다',
  newBonusCreated: '새 보너스가 생성되었습니다. 보너스 섹션을 확인하세요',
  requestBody: '요청 본문',
  INCORRECT_PASSWORD: '잘못된 비밀번호',
  WALLET_DEPOSIT_REQUEST: '지갑 입금 요청',
  WALLET_WITHDRAW_REQUEST: '지갑 출금 요청',
  KYC_REQUEST: 'KYC 요청',
  betBySportsLogs: '스포츠 로그',
  userWallet: '지갑',
  userBonusWallet: '카지노 보너스',
  userCasinoPayout: '카지노 지급금',
  userSportsPayout: '스포츠 지급금',
  userSportsBonusWallet: '스포츠 보너스 지갑',
  weeklyPaybackBonus: '주간 페이백 보너스',
  biWeeklyPaybackBonus: '격주 페이백 보너스',
  nonWithdrawableAmount: '출금 불가 금액',
  withdrawableAmount: '출금 가능 금액',
  betCompensationBonus: '베팅 보상 보너스',
  walletDetails: '지갑 세부 정보',
  withdrawable: '출금 가능',
  nonWithdrawable: '출금 불가',
  casinoBonusAmount: '카지노 보너스 금액',
  sportsBonusAmount: '스포츠 보너스 금액',
  casinoPayoutAmount: '카지노 지급 금액',
  sportsPayoutAmount: '스포츠 지급 금액',
  imageText: '이미지 텍스트',
  imageDescription: '이미지 설명',
  desktopFile: '데스크톱 파일',
  mobileFile: '모바일 파일',
  desktopBanner: '데스크톱 배너',
  mobileBanner: '모바일 배너',
  enterImageText: '이미지 텍스트 입력',
  bonusText: '보너스 텍스트',
  bonusTextPlaceholder: '보너스 텍스트 자리 표시자',
  casinoBetHistory: '카지노 베팅 기록',
  betBySportsBetHistory: '스포츠 베팅 기록',
  freebet_no_risk: '무위험 무료 베팅',
  freebet_money: '무료 머니',
  freebet_refund: '무료 베팅 환불'
}
