import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBonusStart, issueBonusStart } from '../../../store/redux-slices/bonus'
import { getCountriesStart, getUserStart } from '../../../store/redux-slices/fetchData'
import { updateSAUserStatusStart } from '../../../store/redux-slices/superAdminSettings'
import { formatNumber } from '../../../utils/storageUtils'

const useUserDetails = (securityFlag) => {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('overview')
  const {
    userData,
    loading
  } = useSelector((state) => state.fetch)
  const dispatch = useDispatch()
  const { bonusList } = useSelector((state) => state.admins)
  const { bonusDetail } = useSelector((state) => state.bonus)
  const { userId } = useParams()
  const [show, setShow] = useState(false)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [enable, setEnable] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState()
  const [bonusAmount, setBonusAmount] = useState()
  const [isActiveInactivemodalVisible, setActiveInactiveModal] = useState(false)
  const [isFlag, setFlag] = useState(false)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const handleClose = () => {
    setShowReasonModal(false)
    setEnable(false)
  }

  useEffect(() => {
    dispatch(getUserStart({ userId }))
  }, [userId])

  useEffect(() => {
    dispatch(getCountriesStart({
      limit: '',
      name: '',
      pageNo: '',
      isActive: true,
      kycMethod: ''
    }))
  }, [])

  useEffect(() => {
    if (!selectedBonus && bonusList?.length > 0) {
      setSelectedBonus(bonusList?.[0])
    }
    if (selectedBonus) {
      dispatch(getBonusStart({ userBonusId: '', bonusId: selectedBonus?.bonusId, isTenant: false }))
    }
  }, [bonusList, selectedBonus?.bonusId])

  const issueBonusHandler = () => {
    setShowModal(false)
    dispatch(issueBonusStart({
      data: {
        userId: parseInt(userId),
        bonusId: selectedBonus?.bonusId,
        amount: bonusAmount
      }
    }))
    setBonusAmount('')
  }

  const showStyle = (data) => (data ? 'text-success' : 'text-danger')

  const basicInfo = [
    { label: 'id', value: userId },
    { label: 'fullName', value: `${userData?.legalName}` },
    { label: 'userName', value: userData?.username },
    { label: 'nickNameLabel', value: userData?.firstName },
    { label: 'status', value: userData?.isActive ? 'Active' : 'Inactive', subValue: showStyle(userData?.isActive) },
    { label: 'redFlag', value: userData?.isFlaggedRed ? 'Yes' : 'No', subValue: showStyle(userData?.isFlaggedRed) },
    { label: 'portal', value: `${userData?.['tenant.name']} (${userData?.['tenant.domain']})` },
    { label: 'blacklistReason', value: userData?.defaultDisableReason || '-' },
    { label: 'blacklistDescription', value: userData?.disableReason || '-' },
    { label: 'redFlagReason', value: userData?.redFlagReason || '-' },
    { label: 'redFlagDescription', value: userData?.redFlagDescription || '-' },
    {
      label: 'withdrawLimit',
      value: `${formatNumber(userData?.withdrawlLimit ? userData?.levelWithdrawlLimit ? Number(userData?.withdrawlLimit) + Number(userData?.levelWithdrawlLimit) : userData?.withdrawlLimit : userData?.['tenant.tenantConfig.withdrawlLimit'] || '')} ₩`
    }
  ]

  const updateUserStatus = (res) => {
    dispatch(updateSAUserStatusStart({
      data: {
        ...res,
        code: 'USER',
        userId,
        status: !userData.isActive,
        tenantId: userData.tenantId,
        securityFlag
      }
    }))
    setTimeout(() => {
      dispatch(getUserStart({ userId }))
    }, 1000)
  }

  return {
    bonusList,
    selectedTab,
    userData,
    loading,
    show,
    showReasonModal,
    enable,
    showModal,
    selectedBonus,
    bonusDetail,
    bonusAmount,
    basicInfo,
    setBonusAmount,
    issueBonusHandler,
    setSelectedBonus,
    setShowModal,
    setSelectedTab,
    setShow,
    handleClose,
    setEnable,
    navigate,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    isFlag,
    setFlag,
    updateUserStatus,
    limit,
    pageNo,
    setLimit,
    setPageNo
  }
}

export default useUserDetails
