import React from 'react'
import { Row } from '@themesberg/react-bootstrap'
import { Button, Col, Card, Table } from 'react-bootstrap'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faAreaChart } from '@fortawesome/free-solid-svg-icons'
import { getTextColor } from '../../utils/dashboardEffects'
import InputDatePicker from '../FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../utils/storageUtils'

export default ({
  dashboardSBTransactions,
  sbTransactionLoading,
  setDate,
  date,
  t
}) => {
  const transactionTypes = {
    PLACED: { key: 'placed', value: 'totalSbBetAmount' },
    WINNER: { key: 'winner', value: 'totalSbWinAmount' },
    CASH_OUT: { key: 'cash_out', value: 'totalSbCashOutAmount' },
    ROLLBACK: { key: 'rollback', value: 'totalSbRollbackAmount' },
    REFUND: { key: 'refund', value: 'totalSbRefundAmount' },
    CANCELED: { key: 'canceled', value: 'totalSbCanceledAmount' }
  }

  const tableHeaders = [
    { label: 'sNo' },
    { label: 'transactionType' },
    { label: 'totalCount' },
    { label: 'totalAmount' }
  ]

  return (
    <div className='px-2'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3><FontAwesomeIcon icon={faAreaChart} />&nbsp;{t('sportsTransactionStats')}</h3>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex gap-2 align-items-center'>
          <InputDatePicker
            isFilter
            label={t('from')}
            onChange={(val) => {
              setDate((oldState) => ({ ...oldState, startDate: val }))
            }}
            value={date?.startDate}
          />
          <InputDatePicker
            isFilter
            label={t('to')}
            onChange={(val) => {
              setDate((oldState) => ({ ...oldState, endDate: val }))
            }}
            value={date?.endDate}
          />
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setDate({ startDate: '', endDate: '' })
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        <Col className='d-flex align-items-center gap-3'>
          <Card className='p-2'>
            <p style={{ fontWeight: 700, margin: '0' }} className={`small ${getTextColor(dashboardSBTransactions?.totalGgrAmount)}`}>  <span className='fw-bold text-dark'>{t('totalGgr')}</span>: {dashboardSBTransactions?.totalGgrAmount !== undefined ? formatNumber(dashboardSBTransactions?.totalGgrAmount || 0) : 0}</p>
          </Card>
          <Card className='p-2'>
            <p style={{ fontWeight: 700, margin: '0' }} className={`small ${getTextColor(dashboardSBTransactions?.totalNgrAmount)}`}> <span className='fw-bold text-dark'>{t('totalNgr')}</span>:  {dashboardSBTransactions?.totalNgrAmount !== undefined ? formatNumber(dashboardSBTransactions?.totalGgrAmount || 0) : 0}</p>
          </Card>
        </Col>
      </Row>

      <Row className='gap-3'>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={idx}>
                  {t(h.label)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {dashboardSBTransactions?.sbTransactionStats?.length > 0
              ? dashboardSBTransactions?.sbTransactionStats?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td>{t([transactionTypes?.[item?.status]?.key]) || '-'}</td>
                      <td>{item?.totalCount ? formatNumber(item?.totalCount) : '-'}</td>
                      <td>{item?.[transactionTypes?.[item?.status]?.value] ? formatNumber(item?.[transactionTypes?.[item?.status]?.value]) : '-'}</td>
                    </tr>
                  )
                })

              : (
                <tr>
                  <td colSpan={8} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
                )}

          </tbody>
        </Table>
      </Row>
    </div>
  )
}
