import { Modal, Form, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { reasonSchema } from '../../../Tenant/Users/schemas'
import { userDisableReasons } from '../constants'
import { useTranslation } from 'react-i18next'

const DisableReason = ({
  show,
  handleClose,
  markUserStatusInactive,
  name
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          reason: '',
          description: ''
        }}
        validationSchema={reasonSchema(t)}
        onSubmit={(formValues, { resetForm }) => {
          markUserStatusInactive(formValues)
          resetForm({ formValues: '' })
          handleClose()
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h5>{t('mark')} {name || t('Player')} {t('inactive')}</h5>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label
                  htmlFor='reason'
                  className={touched.reason && errors.reason ? 'text-danger' : ''}
                >
                  {t('reason')}<span className='text-danger'> *</span>
                </label>
                <Form.Select
                  name='reason'
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={touched.reason && errors.reason ? 'border border-danger' : ''}
                >
                  <option value=''>{t('selectReason')}</option>
                  {userDisableReasons.map(reason =>
                    <option value={reason.value} key={reason.value}>
                      {t(reason.label)}
                    </option>)}
                </Form.Select>
                <ErrorMessage
                  component='div'
                  name='reason'
                  className='text-danger'
                />
              </div>
              <div className='form-group mt-3'>
                <label
                  htmlFor='reason'
                >
                  {t('description')}
                </label>
                <Form.Control
                  name='description'
                  type='text'
                  placeholder={t('enterDescription')}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  {t('submit')}
                </Button>
              </Modal.Footer>
            </div>
          </Modal>

        )}

      </Formik>
    </>
  )
}

export default DisableReason
