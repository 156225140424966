import React from 'react'
// import useCmsDetails from '../hooks/useCmsDetails'
import CreateCms from './CreateCms'
import { useLocation } from 'react-router-dom'
// import Preloader from '../../../../components/Preloader

const EditCms = () => {
  const location = useLocation()
  return (
    <CreateCms cmsData={location.state.data} />
  )
}

export default EditCms
