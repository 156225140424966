import { Col, InputGroup, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { adminProfileSchema } from './schema'
import { ADMIN_ROLES } from '../../utils/constants'

const Overview = ({
  details,
  isTenant,
  setEditable,
  editable,
  updateData,
  constant,
  loading,
  t,
  isAffiliateAndAgent,
  isAffiliate,
  isAgent
}) => {
  return (
    <>
      <Row className='my-n2 pt-3'>
        {!isAffiliateAndAgent && (
          <Col sm={12} className='my-2'>
            <div className='text-right m-n1'>
              <button
                type='button' className=' btn btn-warning px-4'
                onClick={() => {
                  setEditable(true)
                }}
              >{t('edit')}
              </button>
            </div>
          </Col>
        )}

        {details &&
          <Formik
            enableReinitialize
            initialValues={{
              ...(!isAffiliateAndAgent && {
                firstName: details?.firstName,
                lastName: details?.lastName
              }),
              email: details?.email,
              superAdminUsername: isAffiliateAndAgent ? details?.username : isTenant ? details?.agentName : details?.superAdminUsername || '',
              phone: isTenant || isAffiliateAndAgent ? details?.phone : '',
              role: isAffiliate ? ADMIN_ROLES.AFFILIATE : isAgent ? ADMIN_ROLES.AGENT : isTenant ? ADMIN_ROLES.ADMIN : ADMIN_ROLES.SUPERADMIN
            }}
            validationSchema={adminProfileSchema(t)}
            onSubmit={(
              formValues
            ) => {
              updateData({
                ...formValues,
                superAdminId: isTenant ? '' : details?.superAdminUserId,
                adminUserId: isTenant ? details?.adminUserId : ''
              })
            }}
          >
            {({ values, handleChange, handleSubmit, handleBlur }) => {
              return (
                <Form>
                  <Row lg={4} md={4} sm={6}>
                    {details && constant.map(({ key, value, subValue, edit }, index) => {
                      return (
                        <Col lg={4} md={4} sm={6} className='my-2' key={index} hidden={(details?.adminRoleId === 1 || details?.superRoleId === 1) ? key === 'Group' : false}>
                          <div className='bg-light py-2 px-3 rounded'>
                            <label className='fw-bold'>{t(key) || 'N/A'}</label>
                            <span className='mb-0'>
                              <>
                                <InputGroup>
                                  <BForm.Control
                                    type='text'
                                    name={value}
                                    disabled={!edit || !editable}
                                    value={values?.[value]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </InputGroup>

                                <ErrorMessage
                                  component='div'
                                  name={value}
                                  className='text-danger'
                                />
                              </>
                            </span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                  <div className='mt-4 mb-3'>
                    <Button
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                      hidden={!editable}
                    >
                      {t('submit')}
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                    {
                    editable &&
                      <Button
                        variant='outline-warning'
                        onClick={() => setEditable(false)}
                        className='float-end'
                      >
                        {t('cancel')}
                      </Button>
                   }
                  </div>
                </Form>
              )
            }}
          </Formik>}

      </Row>
    </>
  )
}

export default Overview
