import { takeLatest, put } from 'redux-saga/effects'
import {
  getTenantLivePlayerReport,
  getSuperAdminLivePlayerReport,
  getTAWalletStats,
  getSAWalletStats,
  getProviderStatSuperAdmin,
  getProviderStatTenant,
  getUserCounountryTenant,
  getUserCounountrySuperAdmin,
  getSeniorUserReport,
  getDashboardSBTransactions,
  getRevenueSeniorData,
  getRevenueAffiliateAndAgentData
} from '../../utils/apiCalls'

import {
  getProviderStatStart,
  getProviderStatSuccess,
  getProviderStatFailure,
  getLivePlayersReportStart,
  getLivePlayersReportSuccess,
  getLivePlayersReportFailure,
  getWalletStatsFailure,
  getWalletStatsSuccess,
  getWalletStatsStart,
  getUserLoggedInCountByCountryStart,
  getUserLoggedInCountByCountrySuccess,
  getUserLoggedInCountByCountryFailure,
  getSeniorUserReportStart,
  getSeniorUserReportSuccess,
  getSeniorUserReportFailure,
  getDashboardSBTransactionsFailure,
  getDashboardSBTransactionsStart,
  getDashboardSBTransactionsSuccess,
  getRevenueSeniorDataStart,
  getRevenueSeniorDataSuccess,
  getRevenueSeniorDataFailure,
  getRevenueAffiliateAndAgentDataStart,
  getRevenueAffiliateAndAgentDataSuccess,
  getRevenueAffiliateAndAgentDataFailure
} from '../redux-slices/dashboard'
import { toast } from '../../components/Toast'

export default function * dataWatcher () {
  yield takeLatest(getProviderStatStart.type, getProviderStatWorker)
  yield takeLatest(getRevenueSeniorDataStart.type, getRevenueSeniorDataWorker)
  yield takeLatest(getRevenueAffiliateAndAgentDataStart.type, getRevenueAffiliateAndAgentDataWorker)
  yield takeLatest(getLivePlayersReportStart.type, getTopPlayerReportWorker)
  yield takeLatest(getWalletStatsStart.type, getWalletStatsWorker)
  yield takeLatest(getUserLoggedInCountByCountryStart.type, getUserLoggedInCountByCountryWorker)
  yield takeLatest(getSeniorUserReportStart.type, getSeniorUserReportWorker)
  yield takeLatest(getDashboardSBTransactionsStart.type, getDashboardSportsBookTransactionWorker)
}

function * getSeniorUserReportWorker (action) {
  try {
    const {
      isTenant,
      tenantId,
      from,
      to,
      transactionType,
      isAffiliate,
      isAgent,
      agentId,
      affiliateId
    } = action && action.payload

    const {
      data
    } =
    yield getSeniorUserReport({
      from,
      to,
      isTenant,
      tenantId,
      transactionType,
      isAffiliate,
      isAgent,
      agentId,
      affiliateId
    })

    yield put(getSeniorUserReportSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSeniorUserReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getProviderStatWorker (action) {
  try {
    const {
      isTenant,
      providerId,
      tenantId,
      categoryId,
      agentId,
      affiliateId,
      from,
      to,
      isAffiliate,
      isAgent
    } = action && action.payload

    const {
      data
    } =
    isTenant
      ? yield getProviderStatTenant({
          providerId,
          categoryId,
          from,
          isAffiliate,
          isAgent,
          agentId,
          affiliateId,
          to
        })
      : yield getProviderStatSuperAdmin({
        providerId,
        tenantId,
        agentId,
        affiliateId,
        categoryId,
        from,
        to,
        isAffiliate,
        isAgent

      })

    yield put(getProviderStatSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getProviderStatFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTopPlayerReportWorker (action) {
  try {
    const {
      isTenant,
      tenantId,
      languageCode,
      isAffiliate,
      isAgent,
      agentId,
      affiliateId
    } = action && action.payload

    const { data } = isTenant
      ? yield getTenantLivePlayerReport({
          languageCode,
          isAffiliate,
          isAgent,
          agentId,
          affiliateId
        })
      : yield getSuperAdminLivePlayerReport({
        tenantId,
        languageCode,
        isAffiliate,
        isAgent,
        agentId,
        affiliateId
      })
    yield put(getLivePlayersReportSuccess(data?.data?.userCounts))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getLivePlayersReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getWalletStatsWorker (action) {
  try {
    const {
      isTenant,
      from,
      to,
      tenantId,
      languageCode,
      isAffiliate,
      isAgent,
      agentId,
      affiliateId
    } = action && action.payload

    const { data } = isTenant
      ? yield getTAWalletStats({
          from,
          to,
          languageCode,
          isAffiliate,
          isAgent,
          agentId,
          affiliateId
        })
      : yield getSAWalletStats({
        from,
        to,
        tenantId,
        languageCode,
        isAffiliate,
        isAgent,
        agentId,
        affiliateId
      })
    yield put(getWalletStatsSuccess(data?.data?.transactionCounts))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getWalletStatsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserLoggedInCountByCountryWorker (action) {
  try {
    const { isTenant, tenantId, isAffiliate, isAgent, agentId, affiliateId } = action && action.payload
    const { data } = isTenant
      ? yield getUserCounountryTenant({ isAffiliate, isAgent, agentId, affiliateId })
      : yield getUserCounountrySuperAdmin({ isAffiliate, isAgent, tenantId, agentId, affiliateId })
    yield put(getUserLoggedInCountByCountrySuccess(data?.data?.userCounts))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserLoggedInCountByCountryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getDashboardSportsBookTransactionWorker (action) {
  try {
    const { isTenant, data } = action && action.payload
    const res = yield getDashboardSBTransactions(isTenant, data)
    yield put(getDashboardSBTransactionsSuccess(res?.data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDashboardSBTransactionsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRevenueSeniorDataWorker (action) {
  try {
    const res = yield getRevenueSeniorData(action.payload)
    yield put(getRevenueSeniorDataSuccess(res?.data?.data?.senior))
  } catch (e) {
    yield put(getRevenueSeniorDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRevenueAffiliateAndAgentDataWorker (action) {
  try {
    const res = yield getRevenueAffiliateAndAgentData(action.payload)
    yield put(getRevenueAffiliateAndAgentDataSuccess(res?.data?.data))
  } catch (e) {
    yield put(getRevenueAffiliateAndAgentDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
