import {
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  getDashboardSBTransactionsStart,
  getLivePlayersReportStart,
  getProviderStatStart,
  getSeniorUserReportStart,
  getUserLoggedInCountByCountryStart,
  getWalletStatsStart
} from '../../../../store/redux-slices/dashboard'
import { getSuperAdminGameCategoryStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { countryFilter } from '../../../../utils/countryFilter'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useDashboard = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedTab, setSelectedTab] = useState('transaction')
  const [state, setState] = useState(initialDate)
  const [sbTransactionDate, setSBTransactionDate] = useState({
    startDate: '',
    endDate: ''
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    loading,
    livePlayerData,
    walletStats,
    userLogginbyCountry,
    seniorUserStatData,
    dashboardSBTransactions,
    sbTransactionLoading
  } = useSelector((state) => state.dashboard)

  const countryCodes = userLogginbyCountry?.map((data) => {
    const { countryName } = countryFilter(data?.country_code)
    return countryName || 'Other'
  })

  const loggedInCounts = userLogginbyCountry?.map(data => data.loggedInCount)
  const {
    providerDataStat
  } = useSelector((state) => state.dashboard)
  const { gameCategory } = useSelector((state) => state.superAdminCasino)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')

  const [transactionType, setTransactionType] = useState('')
  const tableHeaders = [
    { label: 'sNo', value: '' },
    { label: 'wagerType', value: 'actionType' },
    { label: 'totalWager', value: 'totalCount' },
    { label: 'totalAmount', value: 'totalWinAmount' }

  ]

  const tableHeaderSeniorUser = [
    { label: 'sNo', value: '' },
    { label: 'reportFor', value: '' },
    { label: 'totalDepositAmount', value: 'TotalDepositAmount' },
    { label: 'totalDepositCount', value: 'TotalDepositCount' },
    { label: 'totalWithdrawlAmount', value: 'TotalWithdrawlAmount' },
    { label: 'totalWithdrawalCount', value: 'TotalWithdrawalCount' },
    { label: 'totalDepositMinusWithdrawal', value: 'totalDepositMinusWithdrawal' }
  ]
  const tableHeadersUserCountryCount = [
    { label: 'country', value: 'country_code' },
    { label: 'totalPlayer', value: 'totalUserCount' },
    { label: 'onlinePlayer', value: 'loggedInCount' },
    { label: 'sNo', value: '' },
    { label: 'country', value: 'country_code' },
    { label: 'depositers', value: 'userWallet.totalDepositers' },
    { label: 'depositersAmount', value: 'userWallet.totalDepositAmount' },
    { label: 'pendingKyc', value: 'pendingCount' },
    { label: 'approvedKyc', value: 'approvedCount' },
    { label: 'rejectKyc', value: 'rejectCount' }
  ]

  const seniorReport = seniorUserStatData?.transaction?.transactionReport[0]
  const playerReport = seniorUserStatData?.transaction?.userTransactionReport[0]

  const result = [
    {
      role: 'Senior',
      ...seniorReport
    },
    {
      role: 'Player',
      ...playerReport
    }
  ]

  const code = getItem('language')
  const transformString = (str) =>
    str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

  const pieChartValuesSeries = livePlayerData?.[0] && Object.values(livePlayerData?.[0])?.map((item) => Number(item))
  const pieChartValuesLabels = livePlayerData?.[1] && Object.keys(livePlayerData?.[1])?.map(transformString)

  const pieChartValuesWalletSeries = walletStats?.[0] && Object.values(walletStats?.[0])?.map((item) => Number(item))
  const pieChartValuesWalletLabels = walletStats?.[1] && Object.keys(walletStats?.[1])?.map(transformString)

  useEffect(() => {
    dispatch(getProviderStatStart({
      isTenant: false,
      tenantId: selectedClient,
      affiliateId: affiliate,
      agentId: agent,
      providerId: selectedProvider,
      categoryId: selectedCategory,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || ''
    }))
  }, [selectedClient, selectedProvider, selectedCategory, state, agent, affiliate])

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '' }))
    dispatch(getLivePlayersReportStart({
      isTenant: false,
      tenantId: selectedClient,
      languageCode: code,
      affiliateId: affiliate,
      agentId: agent
    }))
    dispatch(getWalletStatsStart({
      isTenant: false,
      tenantId: selectedClient,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      languageCode: code,
      affiliateId: affiliate,
      agentId: agent
    }))
    dispatch(getUserLoggedInCountByCountryStart({
      isTenant: false,
      tenantId: selectedClient,
      affiliateId: affiliate,
      agentId: agent
    }))
    dispatch(getSeniorUserReportStart({
      transactionType,
      isTenant: false,
      tenantId: selectedClient,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      affiliateId: affiliate,
      agentId: agent
    }))
  }, [selectedClient, state, code, transactionType, agent, affiliate])

  useEffect(() => {
    dispatch(getDashboardSBTransactionsStart({
      isTenant: false,
      data: {
        adminUserId: selectedClient,
        from: moment(sbTransactionDate.startDate).toISOString() || '',
        to: moment(sbTransactionDate.endDate).toISOString() || ''
      }
    }))
  }, [selectedClient, sbTransactionDate])

  return {
    selectedClient,
    setSelectedClient,
    loading,
    pieChartValuesSeries,
    pieChartValuesLabels,
    pieChartValuesWalletSeries,
    pieChartValuesWalletLabels,
    livePlayerData,
    providerDataStat,
    tableHeaders,
    selectedProvider,
    setSelectedProvider,
    selectedCategory,
    setSelectedCategory,
    gameCategory,
    walletStats: walletStats?.[0] || [],
    userLogginbyCountry,
    countryCodes,
    loggedInCounts,
    tableHeadersUserCountryCount,
    state,
    setState,
    initialDate,
    t,
    transactionType,
    setTransactionType,
    selectedTab,
    setSelectedTab,
    result,
    tableHeaderSeniorUser,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    dashboardSBTransactions,
    sbTransactionLoading,
    setSBTransactionDate,
    sbTransactionDate

  }
}

export default useDashboard
