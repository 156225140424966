import * as Yup from 'yup'

export const promotionCashSchema = (t) => {
  return Yup.object().shape({
    rollingPercentage: Yup
      .number()
      .typeError(t('rollingPercentageInvalid'))
      .integer(t('rollingPercentageInvalid'))
      .positive(t('rollingPercentageInvalid'))
      .max(999, t('rollingPercentageInvalid')) // Restrict percentage for non-bonus
      .required(t('rollingPercentageRequired'))
  })
}
