import React from 'react'
import RollingContestTable from '../../../components/ReportingTable/RollingContestTable'

const TenantRollingContestReport = () => {
  return (
    <RollingContestTable isTenant />
  )
}

export default TenantRollingContestReport
