import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'
import { editTenantStart } from '../../../../store/redux-slices/tenants'
import { getAllThemeStart } from '../../../../store/redux-slices/theme'
import { getLanguagesStart } from '../../../../store/redux-slices/languages'

const useCreateTenant = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('tenant')
  const { adminId } = useParams()
  const { allCredentialsKey, loading } = useSelector((state) => state.tenants)
  const { adminUserDetails } = useSelector((state) => state.adminUser)
  const { data } = useSelector((state) => state.theme)
  const themeData = data?.rows
  const [createTenantData, setCreateTenantData] = useState({
    domainName: '',
    domain: '',
    primaryCurrency: '',
    allowedCurrencies: '',
    allowedLanguages: '',
    themeId: null,
    logoUrl: null,
    logo: null,
    primaryColor: null,
    secondaryColor: null,
    code: 'jap',
    languageName: 'English',
    themeMode: null,
    value: null,
    description: null,
    adminId,
    depositMethods: [],
    withdrawMethods: [],
    withdrawlLimit: '',
    currency: ''
  })

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId: adminId }))
  }, [adminId])

  useEffect(() => {
    dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])

  useEffect(() => {
    if (allCredentialsKey && Object.keys(allCredentialsKey)?.length > 0) {
      const keys = allCredentialsKey.map((key) => key.name)
      const data = Object.fromEntries(keys.map(k => [k, '']))
      setCreateTenantData({
        ...createTenantData,
        value: { ...data },
        description: { ...data }
      })
    }
  }, [allCredentialsKey])

  useEffect(() => {
    if (themeData && createTenantData?.themeId === null) {
      setCreateTenantData({
        ...createTenantData,
        themeId: themeData?.[0]?.themeId,
        primaryColor: themeData?.[0]?.primaryColor,
        secondaryColor: themeData?.[0]?.secondaryColor,
        themeMode: 'dark'
      })
    }
  }, [themeData])

  useEffect(() => {
    !data && dispatch(getAllThemeStart({ limit: '', pageNo: '' }))
  }, [])

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => dispatch(
    editTenantStart({
      createTenantData: {
        ...createTenantData,
        applyToBonus: !!createTenantData?.depositMethods?.filter(item => item?.applyToBonus)?.length
      },
      navigate,
      val,
      tenantId
    })
  )
  return {
    selectedTab,
    setSelectedTab,
    allCredentialsKey,
    createTenantData,
    setCreateTenantData,
    dispatch,
    adminUserDetails,
    loading,
    editTenant
  }
}

export default useCreateTenant
