import { Button, Col, Form as BForm, Form, Row } from '@themesberg/react-bootstrap'
import useLanguageWise from '../hooks/useLanguageWise'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import React from 'react'
import Select from 'react-select'
import { ErrorMessage, Formik } from 'formik'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import Preloader from '../../Preloader'
import { keysWiseSchema } from './schema'
import { GalleryModal } from '../../ConfirmationModal'
import Trigger from '../../OverlayTrigger'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const KeyWise = ({ errorCodes = false }) => {
  const {
    navigate,
    isTenant,
    keyOptions,
    setSelectedKey,
    selectedKey,
    myKeys,
    keys,
    myKeyData,
    loading,
    updateData,
    galleryModal,
    setGalleryModal,
    isHidden
  } = useLanguageWise({ errorCodes })

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row className='mb-3'>
              <Col>
                <h3>{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                {(selectedKey && ['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.label)) &&
                  <Trigger message='Gallery'>
                    <Button
                      hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
                      onClick={() => setGalleryModal(true)}
                      variant='outline-secondary'
                    >
                      <FontAwesomeIcon icon={faImages} />
                    </Button>
                  </Trigger>}
                <Button
                  variant='warning'
                  className='float-end ms-2'
                  onClick={() => !isTenant
                    ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguage : SuperAdminRoutes.EditSupportLanguage)
                    : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguage : TenantRoutes.EditSupportLanguage)}
                >
                  Edit by Position
                </Button>
              </Col>
            </Row>

            {(myKeys[keys] !== undefined || keys === ':keys')
              ? (
                <>
                  <Row>
                    <Col className='d-flex align-items-center'>
                      <Col xs={1}>
                        <BForm.Label>Keys</BForm.Label>
                      </Col>
                      <Col xs={4}>
                        <Select
                          isClearable={false}
                          name='keys'
                          options={keyOptions}
                          value={selectedKey}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          onChange={(option, e) => {
                            setSelectedKey({ label: option.value, value: option.value })
                          }}
                        />
                      </Col>
                    </Col>
                  </Row>
                  <Formik
                    enableReinitialize
                    initialValues={
              { [selectedKey?.value]: { ...myKeyData?.[selectedKey?.value] } }
}
                    validationSchema={errorCodes ? null : keysWiseSchema(selectedKey?.value, myKeyData?.[selectedKey?.value])}
                    onSubmit={(formValues) => {
                      const data = Object.assign({ }, formValues)
                      updateData({
                        data: errorCodes ? { key: selectedKey?.value, data: formValues?.[selectedKey?.value] } : data,
                        type: 'key'
                      })
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <Form>
                        <Row className=' mt-3 mb-3'>
                          {selectedKey?.value && Object.keys(myKeyData?.[selectedKey?.value])?.map(lang => {
                            return (
                              <Col sm={6} key={lang} className='mb-3'>
                                <label
                                  htmlFor={lang}
                                >
                                  {languageCode[lang]}{!['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.value) && !errorCodes && <span className='text-danger'> *</span>}
                                </label>
                                <BForm.Control
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  as='textarea'
                                  placeholder='Enter Value'
                                  value={values?.[selectedKey.value]?.[lang]}
                                  onInput={handleChange}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                />
                                <ErrorMessage
                                  component='div'
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  className='text-danger'
                                />
                              </Col>
                            )
                          })}
                        </Row>
                        <div className='mt-4 d-flex justify-content-between'>
                          <Button
                            variant='outline-warning'
                            onClick={() => isTenant
                              ? navigate(errorCodes ? TenantRoutes.ErrLanguageManagement : TenantRoutes.LanguageManagement)
                              : navigate(errorCodes ? SuperAdminRoutes.ErrLanguageManagement : SuperAdminRoutes.LanguageManagement)}
                            className='ml-2'
                          >
                            Cancel
                          </Button>
                          <Button
                            className='ml-2'
                            variant='outline-success'
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
                )
              : (
                <>
                  <Row>
                    <h5 className='text-center text-danger'>Key Not Found</h5>
                  </Row>
                </>)}
          </>
          )}

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default KeyWise
