export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'userId', value: 'id' },
  { label: 'userName', value: 'username' },
  { label: 'referralCode', value: 'referralcode' },
  { label: 'referralLink', value: 'referrallink' },
  { label: 'createdAt', value: 'date' },
  { label: 'referralCount', value: 'referedByCount' },
  { label: 'associatedReferrals', value: 'associatedreferrals' }
]
export const associateTableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'referralCode', value: 'referralcode' },
  { label: 'createdAt', value: 'date' },
  { label: 'action', value: 'action' }
]
