import * as Yup from 'yup'

// Schema for EN with Extra Validations
export const userSigninSchemaEN = (isTenant, t) =>
  Yup.object().shape({
    firstName:
        Yup.string().max(50, t('max50Characters'))
          .min(2, t('min2Characters'))
          .matches(/^[a-z][^A-Z \s]*$/, t('userNameAllowed'))
          .matches(/^[^ ]+$/, t('onlySpaceNotAllowed'))
          .required(t('nicknameRequired')),
    adminId: isTenant ? Yup.number().nullable() : Yup.number().required(t('seniorRequired')),
    password:
      Yup.string()
        .max(16, t('max16Characters'))
        .required(t('passwordRequired')),
    accountNumber:
      Yup.string()
        .min(8, t('min8Numbers'))
        .max(20, t('max20Characters'))
        .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, t('invalidAccountNumber'))
        .required(t('requiredAccountNumber')),
    confirmPassword:
      Yup.string()
        .max(16, t('max16Characters'))
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),
    withdrawalPassword:
      Yup.string()
        // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9\s])[^\s.,]{4,16}$/, t('withdrawlPasswordValidationMessage'))
        .max(16, t('max16Characters'))
        .required(t('passwordRequired')),
    confirmPasswordWithdrawal:
      Yup.string()
        .max(16, t('max16Characters'))
        .oneOf([Yup.ref('withdrawalPassword'), null], t('matchWithdrawalPassword'))
        .required(t('confirmPasswordWithdrawalRequired')),
    legalName:
      Yup.string()
        .min(3, t('min3Characters'))
        .matches(/^[\p{L} ]+$/u, t('legalName'))
        .matches(/^\p{L}+( \p{L}+)*$/u, t('legalNameOneSpace'))
        .max(50, t('max50Characters')).required(t('legalNameRequired')),
    phone:
      Yup.string()
        .min(5, t('min5Numbers'))
        .max(16, t('max16Numbers'))
        .matches(/^\d{5,20}$/, t('enterValidPhone'))
        .required(t('phoneRequired')),
    dateOfBirth:
      Yup.date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
        .typeError(t('validDOB'))
        .required(t('dateOfBirthRequired')),
    username:
      Yup.string().max(50, t('max50Characters'))
        .min(2, t('min2Characters'))
        .matches(/^[^\d\s]/, t('validName'))
        .matches(/^[^\s]+$/, t('onlySpaceNotAllowed'))
        .required(t('userNameRequired')),
    preferredCurrency: Yup.string().trim().required(t('preferredCurrencyRequired')),
    bankName: Yup.string().trim().required(t('bankNameRequired')),
    nationality: Yup.string().trim().required(t('nationalityRequired'))
  })

// Schema for Other Languages
export const userSigninSchema = (isTenant, t) =>
  Yup.object().shape({
    firstName:
    Yup.string().max(50, t('max50Characters'))
      .min(2, t('min2Characters')).required(t('nicknameRequired')),
    adminId: isTenant ? Yup.number().nullable() : Yup.number().required(t('seniorRequired')),
    username:
      Yup.string().max(50, t('max50Characters'))
        .min(1, t('min2Characters')).required(t('userNameRequired')),
    password:
      Yup.string()
        .max(16, t('max16Characters')).required(t('passwordRequired')),
    confirmPassword:
      Yup.string()
        .max(16, t('max16Characters'))
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),
    withdrawalPassword:
      Yup.string()
        // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9\s])[^\s.,]{4,16}$/, t('withdrawlPasswordValidationMessage'))
        .max(16, t('max16Characters'))
        .required(t('passwordRequired')),
    confirmPasswordWithdrawal:
      Yup.string()
        .max(16, t('max16Characters'))
        .oneOf([Yup.ref('withdrawalPassword'), null], t('matchWithdrawalPassword'))
        .required(t('confirmPasswordWithdrawalRequired')),
    accountNumber:
      Yup.string()
        .min(8, t('min8Numbers'))
        .max(20, t('max20Characters'))
        .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, t('invalidAccountNumber'))
        .required(t('requiredAccountNumber')),
    legalName:
      Yup.string()
        .min(3, t('min3Characters'))
        .max(50, t('max50Characters')).required(t('legalNameRequired')),
    phone: Yup.string()
      .min(5, t('min5Numbers'))
      .max(16, t('max16Numbers'))
      .matches(/^\d{5,16}$/, t('enterValidPhone'))
      .required(t('phoneRequired')),
    dateOfBirth: Yup.date()
      .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), t('mustBe18'))
      .typeError(t('validDOB'))
      .required(t('dateOfBirthRequired')),
    preferredCurrency: Yup.string().trim().required(t('preferredCurrencyRequired')),
    bankName: Yup.string().trim().required(t('bankNameRequired')),
    nationality: Yup.string().trim().required(t('nationalityRequired'))
  })
