import React from 'react'
import { isReadFilter, readRecieptTable } from './constant'
import { Button, Table } from '@themesberg/react-bootstrap'
import PaginationComponent from '../Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckDouble, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Form } from 'react-bootstrap'
import Trigger from '../OverlayTrigger'

const ReadReceiptTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  setIsReadReceiptTable,
  isReadReceiptTable,
  filterData,
  setFilterData,
  initialState,
  loading,
  t
}) => {
  return (
    <div className=''>
      <div className='d-flex flex-wrap align-items-center gap-2'>
        <span
          className=' d-flex gap-2 align-items-center fw-bold' onClick={() => {
            setIsReadReceiptTable(false)
            setPage(1)
          }} role='button'
        />
        <div className='d-flex align-items-center'>
          <Form.Label
            column='sm' style={{
              marginBottom: '0',
              marginRight: '15px',
              minWidth: 'fit-content'
            }}
          >
            {t('seenStatus')}
          </Form.Label>
          <Form.Select
            value={filterData?.isRead}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                isRead: e.target.value
              })
              setPage(1)
            }}
            style={{
              marginBottom: '0',
              marginRight: '15px',
              maxWidth: '230px',
              width: 'auto'
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {isReadFilter?.map((requestAction) =>
              (
                <option
                  key={requestAction.value}
                  value={requestAction.value}
                >
                  {requestAction?.label}
                </option>
              )
            )}
          </Form.Select>
        </div>

        <Trigger message={t('resetFilters')}>
          <Button
            variant='outline-success'
            className='py-1'
            size='sm'
            onClick={() => {
              setFilterData(initialState)
            }}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
        </Trigger>

      </div>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {readRecieptTable.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && tableData &&
      tableData?.rows?.map(
        (item, index) => {
          return (
            <tr key={index}>
              <td>{(page - 1) * limit + index + 1}</td>
              <td>
                <span
                  style={{
                    width: '130px',
                    cursor: 'pointer'
                  }}
                  className='d-inline-block text-truncate'
                >
                  {item?.username || 'NA'}
                </span>
              </td>
              <td>
                <FontAwesomeIcon icon={item?.isNotificationRead ? faCheckDouble : faCheck} />
              </td>
              <td>
                {
                  item?.readAt
                    ? moment(item?.readAt).format('YYYY-MM-DD HH:mm')
                    : '-'
                }
              </td>
            </tr>
          )
        }
      )
          }
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
    </div>
  )
}

export default ReadReceiptTable
