import React from 'react'
import { Card, Table } from 'react-bootstrap'
import PieChart from '../../../../../components/charts/PieCharts'
import { countryFilter } from '../../../../../utils/countryFilter'
import Trigger from '../../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPieChart } from '@fortawesome/free-solid-svg-icons'
import { formatNumber } from '../../../../../utils/storageUtils'

export default ({
  pieChartValuesWalletSeries,
  pieChartValuesWalletLabels,
  tableHeadersUserCountryCount,
  userLogginbyCountry,
  t
}) => {
  return (
    <div className='gap-3 d-flex flex  wallet-stats'>
      <div className='flex-shrink-0 wallet-left'>
        <Card className='p-2 mb-2 graph-container d-flex flex-column gap-1  h-100'>
          <h3 className='mb-3'><FontAwesomeIcon icon={faPieChart} />&nbsp;{t('walletStats')}</h3>
          <div className='d-block d-xl-flex align-items-center'>
            <PieChart
              series={pieChartValuesWalletSeries}
              labels={pieChartValuesWalletLabels}
              height={450}
              width={450}
            />
          </div>
        </Card>
      </div>
      <div className='flex-grow-1  wallet-right'>
        <div className=''>
          <Table bordered striped responsive hover size='sm' className='text-center'>
            <thead className='thead-dark'>
              <tr>
                {tableHeadersUserCountryCount.slice(3).map((h, idx) => (
                  <th key={idx}>
                    {t(h.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userLogginbyCountry && userLogginbyCountry.map((item, index) => {
                const { countryImage, countryName } = countryFilter(item?.country_code)
                return (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                      {!countryName
                        ? t('other')
                        : (
                          <Trigger message={countryName}>
                            <img width='25px' src={countryImage} alt={countryName} />
                          </Trigger>
                          )}
                    </td>
                    <td>{formatNumber(item['userWallet.totalDepositers']) || '-'}</td>
                    <td>{formatNumber(item['userWallet.totalDepositAmount']?.toFixed(2)) || '-'}</td>
                    <td>{formatNumber(item?.pendingCount) || '-'}</td>
                    <td>{formatNumber(item?.approvedCount) || '-'}</td>
                    <td>{formatNumber(item?.rejectedCount) || '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}
