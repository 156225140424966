import * as Yup from 'yup'
import { t } from 'i18next'

export const tenantLoginSchema = (isAffiliate) => {
  return Yup.object().shape({
    loginCode: isAffiliate ? Yup.string().required(t('loginCodeRequired')) : Yup.string().nullable().notRequired(),
    user: Yup.string().required(t('emailOrUsernameRequired')),
    password: Yup.string().required(t('passwordRequired'))
  })
}

export const tenantAdminAuthenticate = Yup.object().shape({
  token: Yup.number()
    .min(6, 'Minimum 6 digit Required')
    .required('Authentication Code is Required')
})
