import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  fetchTenantDetailsStart,
  getAdminRoleStart,
  getTenantAdminRoleStart,
  getTenantRoleStart
} from '../../store/redux-slices/login'
import {
  getAffiliateAllDetailsStart,
  getSuperAdminDetailsStart
} from '../../store/redux-slices/admins'
import { getLoginToken } from '../../utils/storageUtils'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../../routes'
import useSocketConnect from './useSocketConnect'

const usePrivateRoute = ({ isTenantRoute, isAffiliate, isAgent }) => {
  const accessToken = getLoginToken()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login)
  useSocketConnect()
  useEffect(() => {
    if (accessToken) {
      if (isTenantRoute) {
        dispatch(fetchTenantDetailsStart())
        dispatch(getTenantAdminRoleStart({ isAffiliate: false }))
        dispatch(getSuperAdminDetailsStart({ isTenant: true }))
      } else if (isAffiliate) {
        dispatch(getTenantAdminRoleStart({ isAffiliate: true }))
        dispatch(getAffiliateAllDetailsStart({ isAgent, isAffiliate }))
      } else if (isAgent) {
        dispatch(getTenantAdminRoleStart({ isAgent: true }))
        dispatch(getAffiliateAllDetailsStart({ isAgent, isAffiliate }))
      } else {
        dispatch(getAdminRoleStart())
        dispatch(getTenantRoleStart())
        dispatch(getSuperAdminDetailsStart({ isTenant: false, onStart: true }))
      }
    } else {
      if (isTenantRoute) {
        navigate(TenantRoutes.TenantSignin)
      } else if (isAffiliate) {
        navigate(AffiliateRoutes.AffiliateSignin)
      } else if (isAgent) {
        navigate(AgentRoutes.AgentSignin)
      } else {
        navigate(SuperAdminRoutes.SuperAdminSignin)
      }
    }
  }, [accessToken, isTenantRoute, isAffiliate, isAgent])

  return {
    accessToken,
    loading
  }
}

export default usePrivateRoute
