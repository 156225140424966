import React from 'react'
import useVoucherTransactions from './hooks/useVoucherTransactions'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import Trigger from '../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientFilter from '../../components/ClientFilter'
import VoucherTransactionsList from './components/VoucherTransactionsList'
import InputDatePicker from '../FormElements/InputDatePicker/InputDatePicker'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'

const VoucherTransactions = ({ isGiftCard }) => {
  const isTenant = !window.location.href.match(/super/g)

  const {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    initialDate,
    setBonusType,
    voucherTransactions,
    loading,
    selectedClient,
    setSelectedClient,
    inputRef,
    handleDebouncSearch,
    setSearch,
    handleCsvDownload,
    t
  } = useVoucherTransactions(isTenant, isGiftCard)

  return (
    <div className='p-2'>

      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{isGiftCard ? t('giftCardTransaction') : t('voucherTransactions')}</h3>
          </Col>
        </Col>
      </Row>

      <Row className='search-section pb-2 gap-2  mt-1'>

        {!isTenant && (
          <Col xs='auto' className='d-flex '>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              setPage={setPage}
            />
          </Col>)}

        <Col xs='auto' className='d-flex'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>

          <Form.Control
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              fontSize: '13px'
            }}
            ref={inputRef}
            onChange={handleDebouncSearch}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={voucherTransactions?.count === 0}
              onClick={() => {
                handleCsvDownload()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>

        <Col xs='auto' className='d-flex '>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setLimit(15)
                setPage(1)
                setState(initialDate)
                setBonusType('')
                setSelectedClient('')
                setSearch('')
                inputRef.current.value = ''
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <VoucherTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={voucherTransactions}
        isTenant={isTenant}
        loading={loading}
        t={t}
        isGiftCard={isGiftCard}
      />
    </div>
  )
}

export default VoucherTransactions
