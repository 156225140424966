
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createTemplateListStart, getTemplateDetailStart, updateTemplateListStart } from '../../../../store/redux-slices/superAdminSettings'
import { getTenantCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { useEffect } from 'react'

const useCreateTemplate = ({ templateData, setIsCreateTemplateModalOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { tenantCurrencies } = useSelector(state => state.currencies)
  const { languages } = useSelector(state => state.languages)
  const { createLoading } = useSelector(state => state.superAdminSettings)
  const handleCreateTemplate = (values, handleClose, setRefetch) => {
    const formData = {
      ...values,
      tenantId: Number(values.tenantId),
      ranks: values?.ranks?.filter(item => item?.isChecked).map((item) => {
        return {
          thresholdAmount: item?.amount,
          benefit: item?.benefit,
          benefitType: item?.benefitType,
          rollingPercentage: item?.rollingPercentage || 0,
          rankId: item?.rankId
        }
      })
    }
    dispatch(createTemplateListStart({
      formData,
      handleClose: () => {
        setRefetch(prev => !prev)
        handleClose()
      }
    }))
  }
  const handleUpdateTemplate = (values, handleClose, setRefetch) => {
    const formData = {
      lang: values.lang,
      templateName: values.templateName,
      templateId: Number(templateData?.templateId),
      ranks: values?.ranks?.filter(item => item?.isChecked).map((item) => {
        const rankObj = {
          thresholdAmount: item?.amount,
          benefit: item?.benefit,
          benefitType: item?.benefitType,
          rankId: item?.rankId,
          rollingPercentage: item?.rollingPercentage || 0
        }

        if (item?.templateConfigId) {
          rankObj.templateConfigId = item.templateConfigId
        }
        return rankObj
      })

    }
    dispatch(updateTemplateListStart({
      formData,
      handleClose: () => {
        setRefetch(prev => !prev)
        handleClose()
      }
    }))
    setIsCreateTemplateModalOpen(false)
  }

  useEffect(() => {
    if (templateData) {
      dispatch(getTemplateDetailStart({ templateId: templateData?.templateId }))
    }
  }, [templateData?.templateId])

  const getTenantCurrencies = (tenantId) => {
    dispatch(getTenantCurrenciesStart({ tenantId }))
  }
  return {
    clientsFilterData,
    languages,
    t,
    handleCreateTemplate,
    createLoading,
    handleUpdateTemplate,
    getTenantCurrencies,
    tenantCurrencies
  }
}

export default useCreateTemplate
