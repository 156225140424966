import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { updateTenantFlagStart } from '../../../../store/redux-slices/tenantUsers'
import { updateFlagStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { useState } from 'react'
import { updateUserWalletLimitStart } from '../../../../store/redux-slices/superAdminTransactions'
import { superadminUpdateUserPasswordStart, updateEntityStart } from '../../../../store/redux-slices/admins'
import { updateAnonymityStatusStart, migratePlayerV2Start } from '../../../../store/redux-slices/superAdminSettings'
import { tenantUpdateUserPasswordStart } from '../../../../store/redux-slices/tenants'
const useUserOverview = ({ user, isAffiliate, isAgent, securityFlag }) => {
  const showStyle = (data) => (data ? 'text-success' : 'text-danger')
  const isTenant = window.location.pathname.includes('/tenant/')
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { adminPermissions } = useSelector(state => state.admins)
  const { loadingMigrate } = useSelector(state => state.superAdminSettings)
  const [passwordForm, setPasswordForm] = useState(false)
  const [showRedFlagReasonMoadl, setShowRedFlagReasonMoadl] = useState(false)
  const [withdrawlModal, setWithdrawlLimitModal] = useState(false)
  const [updateEntityForm, setUpdateEntitiyForm] = useState(false)
  const [entityType, setEntityType] = useState('')
  const [formValue, setFormValue] = useState({})
  const [isConfirm, setIsConfirm] = useState(false)
  const [isMigrated, setIsMigrated] = useState(false)

  const basicInfo = [
    { label: 'ID', value: userId },
    { label: 'userName', value: user?.username },
    { label: 'fullName', value: `${user?.legalName}` },
    {
      label: 'blacklistStatus',
      value: user?.isActive ? 'White List' : 'Black List',
      subValue: showStyle(user?.isActive)
    },
    {
      label: 'redFlag',
      value: user?.isFlaggedRed ? 'Yes' : 'No',
      subValue: showStyle(!user?.isFlaggedRed)
    },
    {
      label: 'portal',
      value: `  ${user?.['tenant.name']} ${user?.['tenant.domain']}`
    },
    // { label: 'reason', value: !user?.isActive ? user?.reason : '' },
    { label: 'blacklistReason', value: user?.defaultDisableReason || ' ' },
    { label: 'blacklistDescription', value: user?.disableReason || ' ' },
    { label: 'redFlagReason', value: user?.redFlagReason || ' ' },
    { label: 'redFlagDescription', value: user?.redFlagDescription || ' ' },
    {
      label: 'withdrawLimit',
      value: `${user?.withdrawlLimit ? user?.levelWithdrawlLimit ? Number(user?.withdrawlLimit) + Number(user?.levelWithdrawlLimit) : user?.withdrawlLimit : user?.['tenant.tenantConfig.withdrawlLimit'] || ''
        } ${user?.withdrawlLimitCurrency || ' ₩'}`
    }
  ]

  const moreInfo = [
    { label: 'ipAddress', value: user?.signInIp, subValue: 'text-success' },
    { label: 'currentIPAddress', value: user?.currentIPAddress || user?.signInIp, subValue: 'text-success' },
    { label: 'dateOfBirth', value: formatDateYMD(user?.dateOfBirth) },
    { label: 'countryCode', value: user?.countryCode }
  ]

  const [isAnonymous, setIsAnonymous] = useState(user?.isAnonymous)
  const handleStatus = () => {
    const newStatus = !isAnonymous
    setIsAnonymous(newStatus)
    dispatch(updateAnonymityStatusStart({
      isAnonymousFlag: newStatus,
      isTenant,
      userId: Number(userId)
    }))
  }
  const handleFlag = ({ role, ...data }) => {
    if (role === 'superAdmin') {
      dispatch(
        updateFlagStart(
          {
            ...data,
            userId: user?.userId,
            tenantId: user?.tenantId,
            redFlag: !user?.isFlaggedRed,
            securityFlag
          }))
    } else {
      dispatch(
        updateTenantFlagStart(
          {
            ...data,
            userId: user?.userId,
            redFlag: !user?.isFlaggedRed,
            isAffiliate: isAffiliate,
            isAgent
          }))
    }
    setShowRedFlagReasonMoadl(false)
  }
  const handleWithdrawl = (data) => {
    dispatch(
      updateUserWalletLimitStart(
        {
          userId: user?.userId,
          ...data
        }))
  }

  const migrateTov2 = (data) => {
    dispatch(
      migratePlayerV2Start(
        {
          setIsMigrated,
          userId: user?.userId
        }))
  }

  const handleRedFlagClose = () => {
    setShowRedFlagReasonMoadl(false)
  }

  const requestData = {
    username: user?.username,
    userId: Number(userId),
    tenantId: user?.tenantId
  }
  const handleUpdatePassword = (data) => {
    const isTenant = !window.location.href.match(/super/g)
    if (isAgent) {
      dispatch(tenantUpdateUserPasswordStart({
        dataForm: {
          ...data,
          ...requestData
        },
        isAgent
      }))
    } else if (isAffiliate) {
      dispatch(tenantUpdateUserPasswordStart({
        dataForm:
         { ...data, ...requestData },
        isAffiliate
      }))
    } else if (!isTenant) {
      dispatch(superadminUpdateUserPasswordStart({
        dataForm:
           {
             ...data,
             ...requestData
           }
      }
      ))
    } else {
      dispatch(tenantUpdateUserPasswordStart({
        dataForm:
         { ...data, ...requestData }
      }
      ))
    }
    setPasswordForm(false)
  }
  const handleUpdateEntity = () => {
    const entityId = Number(formValue?.entityId)
    dispatch(updateEntityStart({
      formData: {
        entityId,
        entityType,
        userId: Number(userId)
      },
      setUpdateEntitiyForm
    }))
    formValue?.resetForm()
    setIsConfirm(false)
  }
  return {
    handleUpdateEntity,
    userId,
    basicInfo,
    moreInfo,
    adminPermissions,
    withdrawlModal,
    showRedFlagReasonMoadl,
    setShowRedFlagReasonMoadl,
    setWithdrawlLimitModal,
    handleFlag,
    handleRedFlagClose,
    showStyle,
    handleWithdrawl,
    setPasswordForm,
    passwordForm,
    handleUpdatePassword,
    updateEntityForm,
    setUpdateEntitiyForm,
    entityType,
    setEntityType,
    formValue,
    setFormValue,
    isConfirm,
    setIsConfirm,
    handleStatus,
    isAnonymous,
    setIsAnonymous,
    migrateTov2,
    isMigrated,
    loadingMigrate
  }
}

export default useUserOverview
