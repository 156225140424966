import { Card, Col, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

const NotificationForm = ({
  t,
  clientsFilterData,
  affiliateOptions,
  agentOptions,
  isTenant,
  affiliateAndAgentsList,
  selectedClient,
  setSelectedClient,
  setSelectedPortal,
  setAffiliate,
  affiliate,
  setAgent,
  agent,
  role,
  ADMIN_ROLES,
  shouldNextDisabled,
  handleNext,
  withoutAffiliate,
  setWithoutAffiliate,
  agentWithoutAffiliate,
  data,
  isEdit,
  client,
  setDefaultAffiliateOptions,
  setDefaultAgentWithoutAffiliateOptions,
  setDefaultAgentOptions,
  defaultAgentOptions,
  defaultAgentWithoutAffiliateOptions,
  defaultAffiliateOptions,
  loading,
  setAgentWithoutAffiliate,
  setAgentWithAffiliate,
  setIsGetUserActive
}) => {
  return (
    <Row className='mb-3 justify-content-center'>
      <Col sm={6}>
        <Card className='p-4' style={{ width: '100%' }}>
          <h3 className='mb-3 d-flex justify-content-center'>{t('notificationDetails')}</h3>
          <Form style={{ width: '100%' }}>
            <Row>
              {
              !isTenant && (
                <Col sm={12}>
                  <Form.Group controlId='seniorClientSelect'>
                    <Form.Label style={{ marginBottom: '0', marginTop: '5px', marginRight: '5px', fontSize: '13px' }}>
                      {t('seniorRequired')}
                    </Form.Label>

                    <Form.Select
                      onChange={(e) => {
                        setSelectedClient(e.target.value)
                        setDefaultAffiliateOptions([])
                        setAffiliate([])
                        setDefaultAgentOptions([])
                        setAgentWithAffiliate([])
                        setDefaultAgentWithoutAffiliateOptions([])
                        setAgentWithoutAffiliate([])
                      }}
                      value={selectedClient}
                      style={{ width: '100%', marginRight: '10px', fontSize: '13px' }}
                      disabled={isEdit}
                    >
                      <option value={isEdit ? client?.[0]?.adminUserId : ''}>
                        {isEdit ? client?.[0]?.firstName + ' ' + client?.[0]?.lastName : t('chooseTenantAdmin')}
                      </option>

                      {clientsFilterData?.count === 0 && (
                        <option value={isEdit ? client?.[0]?.firstName + ' ' + client?.[0]?.lastName : ''} disabled>
                          {t('noTenantAdminsAvailable')}
                        </option>
                      )}

                      {clientsFilterData?.rows?.map(
                        ({ adminUserId, firstName, lastName }) => (
                          <option key={adminUserId} value={adminUserId}>
                            {`${firstName} ${lastName}`}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )
             }

              {
              !loading && (
                <>
                  {(isTenant || selectedClient) && (
                    <>
                      <Col sm={12} className='mb-3'>
                        {((affiliateOptions.length > 0)) && (
                          <Form.Group controlId='affiliateSelect'>
                            <Form.Label
                              style={{
                                marginBottom: '0',
                                marginRight: '15px',
                                marginTop: '5px',
                                fontSize: '13px'
                              }}
                            >
                              {t('selectAffiliate')}
                            </Form.Label>
                            <Select
                              isMulti
                              options={affiliateOptions}
                              onChange={(selectedOptions, e) => {
                                setDefaultAffiliateOptions(selectedOptions)
                                const valueOptions = selectedOptions.map(option => option.value)
                                setAffiliate(valueOptions)
                                setDefaultAgentOptions([])
                                setAgentWithAffiliate([])
                                setDefaultAgentWithoutAffiliateOptions([])
                                setAgentWithoutAffiliate([])
                              }}
                              value={defaultAffiliateOptions}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  marginRight: '10px',
                                  fontSize: '13px'
                                })
                              }}
                            />
                          </Form.Group>
                        )}

                      </Col>

                      {((agentOptions.length > 0)) && (
                        <Col sm={12} className='mb-3'>
                          <Form.Group controlId='agentSelect'>
                            <Form.Label
                              style={{
                                width: 'auto',
                                marginBottom: '0',
                                marginRight: '15px',
                                marginTop: '5px',
                                fontSize: '13px'
                              }}
                            >
                              {t('selectAgent')}
                            </Form.Label>
                            <Select
                              isMulti
                              options={agentOptions}
                              onChange={(selectedOptions, e) => {
                                setDefaultAgentOptions(selectedOptions)
                                const valueOptions = selectedOptions.map(option => option.value)
                                // setAgent(valueOptions)
                                setAgentWithAffiliate(valueOptions)
                                setDefaultAgentWithoutAffiliateOptions([])
                                setAgentWithoutAffiliate([])
                              }}
                              value={defaultAgentOptions}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  marginRight: '10px',
                                  fontSize: '13px'
                                })
                              }}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </>
                  )}

                  {(selectedClient || isTenant) && (
                    <Col sm={12} className='mb-3'>
                      {(affiliateAndAgentsList?.agents?.length > 0) && (
                        <Form.Group>
                          <Form.Label
                            style={{
                              width: 'auto',
                              marginBottom: '0',
                              marginRight: '15px',
                              marginTop: '5px',
                              fontSize: '13px'
                            }}
                          >
                            {t('selectAgentWithoutAffiliate')}
                          </Form.Label>
                          <Select
                            isMulti
                            options={agentWithoutAffiliate}
                            onChange={(selectedOptions) => {
                              setDefaultAgentWithoutAffiliateOptions(selectedOptions)
                              const valueOptions = selectedOptions.map(option => option.value)
                              setAgentWithoutAffiliate(valueOptions)
                              // setAgent(valueOptions)
                            }}
                            value={defaultAgentWithoutAffiliateOptions}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                width: '100%',
                                marginRight: '10px',
                                fontSize: '13px'
                              })
                            }}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  )}
                </>
              )
            }
            </Row>

          </Form>

        </Card>
      </Col>
    </Row>
  )
}

export default NotificationForm
