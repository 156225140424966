import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import usePayProviderList from './hooks/usePayProviderList'
import Preloader from '../../../components/Preloader'
import { providerCategory } from '../../Super-Admin/CustomPayment/components/constants'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMoneyCheckDollarPen } from '@fortawesome/free-solid-svg-icons' //pro-free replacement

export default () => {
  const {
    setType,
    type,
    loading,
    state,
    onDragEnd,
    handleSave,
    isHidden,
    reorderPayment,
    setReorderPayment
  } = usePayProviderList()

  const ReOrderComponent = () => {
    return (
      <>
        <div className='reorder-heading'>
          {[
            'ID',
            'Name',
            'Aggregator',
            'Group',
            'Category'
          ].map((h) => (
            <p key={h}>{h}</p>
          ))}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list text-left'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {state?.length > 0 &&
                    state?.map(
                      ({
                        name,
                        paymentProviderId,
                        aggregator,
                        group,
                        category
                      }, idx) => (
                        <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                          {provided => (
                            <div
                              className='reorder-content'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p>{paymentProviderId}</p>
                              <p>
                                {name}
                              </p>
                              <p>{aggregator.toUpperCase()}</p>
                              <p>{group}</p>
                              <p>{providerCategory?.[category || 'OTHER']}</p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }

  const PaymentTable = () => {
    return (
      <>
        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center'
        >
          <thead className='thead-dark'>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Aggregator</th>
              <th>Group</th>
              <th>Category</th>
            </tr>
          </thead>

          <tbody>
            {state?.length > 0 &&
                    state?.map(
                      ({
                        name,
                        paymentProviderId,
                        aggregator,
                        group,
                        category
                      }, idx) => (
                        <tr key={idx}>
                          <td>{paymentProviderId}</td>
                          <td>{name}</td>
                          <td>{aggregator}</td>
                          <td>{group}</td>
                          <td>{providerCategory?.[category || 'OTHER']}</td>
                        </tr>
                      ))}
          </tbody>
        </Table>
      </>
    )
  }

  return (
    <>
      <Row className='mb-3'>
        <Col>
          {/* <h3><FontAwesomeIcon icon={faMoneyCheckDollarPen} />&nbsp;Cashier Management</h3> */}
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          <label>Select Type: &nbsp;</label>
          <Form.Select
            size='sm'
            value={type}
            className='w-25'
            onChange={(e) => setType(e.target.value)}
          >
            <option value='depositProviders'>Deposit</option>
            <option value='withdrawProviders'>Withdraw</option>
          </Form.Select>
          <Button
            variant='outline-success'
            size='sm'
            className='m-2'
            hidden={isHidden({ module: { key: 'TenantSettings', value: 'U' } }) || reorderPayment}
            onClick={() => setReorderPayment(true)}
          >
            Reorder
          </Button>
        </Col>
      </Row>

      {loading
        ? <Preloader />
        : (
            state.length > 0 &&
              (reorderPayment
                ? <ReOrderComponent />
                : <PaymentTable />
              ))}
      {state?.length === 0
        ? <p className='text-danger text-center'>No data found</p>
        : reorderPayment && (
          <div className='mt-3 d-flex justify-content-between mb-3'>
            <Button
              variant='outline-warning'
              onClick={() => setReorderPayment(false)}
            >
              Cancel
            </Button>
            <Button
              variant='outline-success'
              onClick={handleSave}
              hidden={isHidden({ module: { key: 'TenantSettings', value: 'U' } })}
            >
              Submit
            </Button>
          </div>
        )}
    </>
  )
}
