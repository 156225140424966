import React from 'react'
import { Button } from 'react-bootstrap'
import AdminTabListing from './AdminTabListing'
export const TABS_ADMIN_KEYS = {
  affiliate: 'affiliate',
  agent: 'agentWithOutAffiliate',
  affiliateAgent: 'affiliateAgent',
  // affiliatePlayer: 'affiliatePlayer',
  seniorPlayer: 'seniorPlayer'
}
const BonusAdminTabContent = ({
  t,
  selectedTab,
  setSelectedTab
}) => {
  return (
    <div>
      <div className='d-flex gap-2 my-3'>
        {Object.values(TABS_ADMIN_KEYS)?.map((item) => {
          return (
            <Button onClick={() => setSelectedTab(item)} key={item} variant={selectedTab === item ? 'success' : 'outline-success'}>
              {t(item)}
            </Button>
          )
        })}
      </div>
      <div className='d-flex flex-column gap-2'>
        <AdminTabListing key={selectedTab} selectedTab={selectedTab} />
      </div>
    </div>
  )
}

export default BonusAdminTabContent
