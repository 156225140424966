import { useEffect } from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTopTournamentStart } from '../../../store/redux-slices/superAdminSportsBook'

const useReorderLeagues = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = !window.location.href.match(/super/g)
  const { loading, topTournaments } = useSelector(state => state.superAdminSportsBook)

  useEffect(() => {
    dispatch(getTopTournamentStart({ isTenant: isTenant }))
  }, [])

  return {
    navigate,
    loading,
    topTournaments,
    isTenant
  }
}

export default useReorderLeagues
