import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getCmsDetailsStart } from '../../../../store/redux-slices/tenantsFetchData'

const useEditCms = () => {
  const { loading, cmsDetails } = useSelector((state) => state.tenantsFetch)
  const { cmsId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCmsDetailsStart({ cmsId }))
  }, [])

  return {
    loading,
    cmsDetails,
    cmsId
  }
}

export default useEditCms
