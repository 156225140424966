import React, { useEffect, useState } from 'react'
import { Button, Form } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form as FormikForm, Formik } from 'formik'
import { depositSchema, depositTenantSchema } from './schema'
import useAccountTransaction from '../hooks/useAccountTransaction'
import { TransactionLimitConfirmationModal } from '../../../../components/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import CurrencyInput from 'react-currency-input-field'
import { formatNumber } from '../../../../utils/storageUtils'
import { WALLET_V2_TYPE_DEPOSIT_ARRAY, WALLET_V2_TYPE_WITHDRAW_ARRAY } from '../../../../utils/constants'

const AccountTransaction = ({
  buttonTitle,
  userData,
  isWithdraw,
  isDeposit,
  tenantDetails,
  isTenant
}) => {
  const isV2User = userData?.isV2User

  const {
    handleDeposit,
    setFormData,
    formDataValues,
    setConfirmTransactionModal,
    confirmTransactionModal
  } = useAccountTransaction(isTenant, isDeposit, isV2User)
  const { t } = useTranslation()
  const [condition, setCondition] = useState(null)
  const [valuesEffect, setValueEffect] = useState('')

  useEffect(() => {
    if (valuesEffect === 'SPORTS-PROMOTION-CASH-WITHDRAW') {
      setCondition(
        Number(userData?.['userWallet.sportsPromotionCash'] || 0)
      )
    } else if (valuesEffect) {
      setCondition(
        Number(userData?.['userWallet.casinoPromotionCash'] || 0)
      )
    }
  }, [valuesEffect])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formDataValues}
        validationSchema={isTenant
          ? depositTenantSchema(tenantDetails?.promotionCash ?? 0, isWithdraw, t, isV2User)
          : depositSchema(condition || 0, !!isWithdraw, t, isV2User)}
        onSubmit={(formData, { resetForm }) => {
          setFormData(formData)
          setConfirmTransactionModal(true)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <FormikForm className='d-flex justify-content-center align-items-center '>
              <div className='card w-50 p-3 pt-4' style={{ minWidth: 500 }}>
                {isWithdraw &&
                  (
                    isTenant
                      ? (
                        <div className='d-flex mb-3 w-100 align-items-center'>
                          <div style={{ minWidth: 120 }}>
                            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                              {t('nonCashAmount')}
                            </Form.Label>
                          </div>
                          <div className='d-flex flex-column flex-grow-1 gap-2 fw-bold'>
                            {
                              formatNumber(tenantDetails?.promotionCash) + ' ₩'
                            }
                          </div>
                        </div>
                        )
                      : (
                          values?.actionSource !== '' &&
                            <div className='d-flex mb-3 w-100 align-items-center'>
                              <div style={{ minWidth: 120 }}>
                                <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                                  {values?.actionSource === 'SPORTS-PROMOTION-CASH-WITHDRAW' ? t('sportsPromotionCash') : t('casinoPromotionCash')}
                                </Form.Label>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 gap-2 fw-bold'>
                                {
                              (
                                values?.actionSource === 'SPORTS-PROMOTION-CASH-WITHDRAW'
                                  ? formatNumber(Number(userData?.['userWallet.sportsPromotionCash'] || 0).toFixed(2))
                                  : formatNumber(Number(userData?.['userWallet.casinoPromotionCash'] || 0).toFixed(2))
                              ) + ' ₩'

                            }
                              </div>
                            </div>
                        )
                  )}
                <div className='d-flex mb-3 w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                      {t('currency')}
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column flex-grow-1 gap-2'>
                    <Form.Select
                      className='w-100'
                      size='sm'
                      name='currency'
                      style={{ width: '200px' }}
                      value={values?.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value=''>{t('selectCurrency')}</option>
                      <option key={1} value='KRW'>
                        ₩
                      </option>
                    </Form.Select>
                    <ErrorMessage
                      component='div'
                      name='currency'
                      className='text-danger'
                    />
                  </div>
                </div>
                {isV2User && (
                  <div className='d-flex mb-3 w-100'>
                    <div style={{ minWidth: 120 }}>
                      <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                        {t('walletType')}
                      </Form.Label>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 gap-2'>
                      <Form.Select
                        className='w-100'
                        size='sm'
                        name='actionSource'
                        style={{ width: '200px' }}
                        value={values?.actionSource}
                        onChange={(e) => {
                          setFieldValue('actionSource', e.target.value)
                          setValueEffect(e.target.value)
                        }}
                        onBlur={handleBlur}
                      >
                        <option value=''>{t('selectWalletType')}</option>
                        {(isDeposit ? WALLET_V2_TYPE_DEPOSIT_ARRAY : WALLET_V2_TYPE_WITHDRAW_ARRAY)?.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {t(item)}
                            </option>
                          )
                        })}

                      </Form.Select>
                      <ErrorMessage
                        component='div'
                        name='actionSource'
                        className='text-danger'
                      />
                    </div>
                  </div>
                )}
                <div className='d-flex w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                      {t('amount')}
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column mb-3 flex-grow-1 gap-2'>
                    <CurrencyInput
                      intlConfig={{ locale: 'ko-KR' }}
                      value={values?.amount}
                      placeholder={t('enterAmount')}
                      onValueChange={(value) => {
                        setFieldValue('amount', value !== undefined ? Number(value) : '')
                      }}
                      className='w-100 form-control form-control-sm'
                      size='sm'
                    />
                    <ErrorMessage
                      component='div'
                      name='amount'
                      className='text-danger'
                    />
                  </div>
                </div>

                {isV2User && isDeposit
                  ? (
                    <div className='d-flex mt-3 mb-3 w-100'>
                      <div style={{ minWidth: 120 }}>
                        <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                          {t('rollingPercent')}
                        </Form.Label>
                      </div>
                      <div className='d-flex flex-column flex-grow-1 gap-2'>

                        <CurrencyInput
                          intlConfig={{ locale: 'ko-KR' }}
                          value={values?.rollingPercentage}
                          placeholder={t('enterValueInPercent')}
                          onValueChange={(value) => {
                            setFieldValue('rollingPercentage', value !== undefined ? Number(value) : '')
                          }}
                          className='w-100 form-control form-control-sm'
                          size='sm'
                          decimalScale={2}
                        />
                        <ErrorMessage
                          component='div'
                          name='rollingPercentage'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    )
                  : <></>}
                <div className='mt-4'>
                  <div className='d-flex justify-content-center'>
                    <Button
                      className='depositButton'
                      variant='outline-info'
                      type='submit'
                    >
                      {buttonTitle}
                    </Button>
                  </div>
                </div>
              </div>
            </FormikForm>
          )
        }}
      </Formik>
      {
        confirmTransactionModal
          ? <TransactionLimitConfirmationModal
              setShow={setConfirmTransactionModal}
              show={confirmTransactionModal}
              values={formDataValues}
              handleConfirmActionRequest={handleDeposit}
              isDeposit={isDeposit}
              buttonTitle={buttonTitle}
            />
          : <></>
      }
    </>
  )
}

export default AccountTransaction
