import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import moment from 'moment'
import { getAffiliateStart, updateAffiliateStatusStart } from '../../../store/redux-slices/affiliate'

const useAffiliates = () => {
  const isTenant = !window.location.href.match(/super/g)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const initialDate = [
    {
      startDate: '',
      endDate: '',
      key: 'selection'
    }
  ]
  const { t } = useTranslation()
  const { affiliateData, loading } = useSelector((state) => state.affiliate)
  const [parentRole, setParentRole] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('')
  const [affiliateId, setAffiliateId] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const totalPages = Math.ceil(affiliateData?.count / limit)
  const inputRef = useRef()
  const [state, setState] = useState(initialDate)
  const [search, setSearch] = useState('')
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  const handleShow = (id, active) => {
    setAffiliateId(id)
    setActive(!active)
    setShow(true)
  }
  const handleYes = () => {
    dispatch(
      updateAffiliateStatusStart({
        formData: {
          status: active,
          affiliateId

        },
        isTenant,
        handlClose: () => {
          setShow(false)
          getAffiliateList()
        }
      })
    )
  }
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const getAffiliateList = () => {
    dispatch(getAffiliateStart({
      adminUserId: selectedClient,
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      search: search,
      status: status,
      parentRole: parentRole,
      limit,
      pageNo: page,
      isTenant
    }))
  }
  useEffect(() => {
    getAffiliateList()
  }, [page, selectedClient, search, state, limit, status, parentRole])

  return {
    navigate,
    loading,
    affiliateData,
    handleDebounceFn,
    debounceFn,
    inputRef,
    selectedClient,
    setSelectedClient,
    handleShow,
    handleYes,
    show,
    setShow,
    status,
    setStatus,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    parentRole,
    setParentRole,
    state,
    setState,
    search,
    setSearch,
    initialDate,
    showDeleteModal,
    setShowDeleteModal,
    t,
    useRef
  }
}

export default useAffiliates
