export const tableHeaders = [
  { label: 'sno', value: '' },
  { label: 'username', value: 'username' },
  { label: 'amount', value: 'amount' },
  { label: 'type', value: 'type' },
  { label: 'adminName', value: 'adminName' },
  { label: 'affiliateName', value: 'affiliateName' },
  { label: 'agentName', value: 'agentName' },
  { label: 'createdAt', value: 'createdAt' }
]
