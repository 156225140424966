import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllTenantAffiliates,
  getAllTenantAdmins,
  getAllTenantCMS,
  createCms,
  getCmsDetails,
  updateCms,
  tenantViewToggleStatus,
  getTenantAffiliateById,
  getUserDocument,
  updateLoyaltyLimit,
  getCommentsList,
  addComments,
  updateComment
} from '../../utils/apiCalls'
import {
  getAllTenantAdminsStart,
  getAllTenantAdminsSuccess,
  getAllTenantAdminsFailure,
  getAllTenantAffiliatesStart,
  getAllTenantAffiliatesSuccess,
  getAllTenantAffiliatesFailure,
  getAllTenantCMSStart,
  getAllTenantCMSSuccess,
  getAllTenantCMSFailure,
  getTenantAffiliateByIdStart,
  getTenantAffiliateByIdSuccess,
  getTenantAffiliateByIdFailure,
  createCmsStart,
  createCmsSuccess,
  createCmsFailure,
  getCmsDetailsStart,
  getCmsDetailsSuccess,
  getCmsDetailsFailure,
  updateCmsStart,
  updateCmsComplete,
  updateCmsStatusStart,
  updateCmsStatusComplete,
  getUserDocumentStart,
  getUserDocumentSuccess,
  getUserDocumentFailure,
  updateLoyaltyLimitStart,
  updateLoyaltyLimitComplete,
  getCommentsStart,
  getCommentsSuccess,
  getCommentsFailure,
  addCommentsStart,
  addCommentsSuccess,
  addCommentsFailure,
  updateCommentStart,
  updateCommentSuccess,
  updateCommentFailure
} from '../redux-slices/tenantsFetchData'
import { toast } from '../../components/Toast'
import { SuperAdminRoutes } from '../../routes'
import { fetchTenantDetailsStart } from '../redux-slices/login'

export default function * tenantsFetchDataWatcher () {
  yield takeLatest(
    getTenantAffiliateByIdStart.type,
    getTenantAffiliateByIdWorker
  )
  yield takeLatest(getAllTenantCMSStart.type, getAllTenantCMSWorker)
  yield takeLatest(
    getAllTenantAffiliatesStart.type,
    getAllTenantAffiliatesWorker
  )
  yield takeLatest(getAllTenantAdminsStart.type, getAllTenantAdminsWorker)
  yield takeLatest(createCmsStart.type, createCmsWorker)
  yield takeLatest(getCmsDetailsStart.type, getCmsDetailsWorker)
  yield takeLatest(updateCmsStart.type, updateCmsWorker)
  yield takeLatest(updateCmsStatusStart.type, updateCmsStatusWorker)
  yield takeLatest(getUserDocumentStart.type, getUserDocumentWorker)
  yield takeLatest(updateLoyaltyLimitStart.type, updateLoyaltyLimitWorker)
  yield takeLatest(getCommentsStart.type, getCommentListWorker)
  yield takeLatest(addCommentsStart.type, addCommentListWorker)
  yield takeLatest(updateCommentStart.type, updateCommentWorker)
}

function * getAllTenantAffiliatesWorker (action) {
  try {
    const { limit, pageNo, tenantId, search } = action && action.payload

    const { data } = yield getAllTenantAffiliates({
      limit,
      pageNo,
      tenantId,
      search
    })

    yield put(getAllTenantAffiliatesSuccess(data?.data?.affiliates))
  } catch (e) {
    yield put(getAllTenantAffiliatesFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getAllTenantAdminsWorker (action) {
  try {
    const { limit, pageNo, orderBy, sort, search, status = '' } = action && action.payload

    const { data } = yield getAllTenantAdmins({
      limit,
      pageNo,
      orderBy,
      sort,
      search: search || '',
      status
    })

    yield put(getAllTenantAdminsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield put(getAllTenantAdminsFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getAllTenantCMSWorker (action) {
  try {
    const { limit, pageNo, search, isActive } = action && action.payload

    const { data } = yield getAllTenantCMS({
      limit,
      pageNo,
      search,
      isActive
    })

    yield put(getAllTenantCMSSuccess(data?.data?.cmsPages))
  } catch (e) {
    yield put(getAllTenantCMSFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getTenantAffiliateByIdWorker (action) {
  try {
    const { affiliateId } = action && action?.payload

    const { data } = yield getTenantAffiliateById({
      affiliateId
    })

    yield put(getTenantAffiliateByIdSuccess(data?.data?.affiliate))
  } catch (e) {
    yield put(getTenantAffiliateByIdFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * createCmsWorker (action) {
  try {
    const { cmsData, navigate } = action && action.payload

    yield createCms({ data: cmsData?.cmsData })

    yield toast('CMS created', 'success')

    navigate(SuperAdminRoutes.CMS)

    yield put(createCmsSuccess())
  } catch (e) {
    yield put(createCmsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCmsDetailsWorker (action) {
  try {
    const { cmsId } = action && action.payload

    const { data } = yield getCmsDetails(cmsId)

    yield put(getCmsDetailsSuccess(data?.data?.cmsDetails))
  } catch (e) {
    yield put(getCmsDetailsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCmsWorker (action) {
  try {
    const { cmsData } = action && action.payload

    yield updateCms(cmsData)

    yield toast('CMS edited', 'success')

    yield put(updateCmsComplete())
    yield put(getCmsDetailsStart({ cmsId: cmsData?.cmsPageId }))
  } catch (e) {
    yield put(updateCmsComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCmsStatusWorker (action) {
  try {
    const { cmsPageId, status, limit, search, pageNo, isActive } =
      action && action.payload

    yield tenantViewToggleStatus({ cmsPageId, status, code: 'CMS' })

    yield toast('CMS status updated', 'success')

    yield put(updateCmsStatusComplete())

    yield put(getAllTenantCMSStart({ limit, search, pageNo, isActive }))
  } catch (e) {
    yield put(updateCmsStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getUserDocumentWorker (action) {
  try {
    const { userId } = action && action.payload

    const { data } = yield getUserDocument(userId, 'tenant')

    yield put(getUserDocumentSuccess(data?.data?.userDocument || data?.data))
  } catch (e) {
    yield put(getUserDocumentFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateLoyaltyLimitWorker (action) {
  try {
    const { loyaltyLimit } = action && action.payload

    const { data } = yield updateLoyaltyLimit({ loyaltyLimit })

    yield toast('Currencies Data Updated SuccessFully', 'success')

    yield put(updateLoyaltyLimitComplete(data?.data?.loyaltyPoint))
    yield put(fetchTenantDetailsStart())
  } catch (e) {
    yield put(updateLoyaltyLimitComplete())
    yield put(fetchTenantDetailsStart())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCommentListWorker (action) {
  try {
    const { limit, search, pageNo, isTenant, userId, role, status } = action && action.payload

    const { data } = yield getCommentsList({ limit, search, pageNo, userId, role, status, isTenant })

    yield put(getCommentsSuccess(data?.data?.comment))
  } catch (e) {
    yield put(getCommentsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * addCommentListWorker (action) {
  try {
    const { data, isTenant, getComment } = action && action.payload

    yield addComments({ data, isTenant })

    yield toast('Comment is added Succesfully', 'success')

    yield put(addCommentsSuccess())
    yield put(getCommentsStart({ ...getComment }))
  } catch (e) {
    yield put(addCommentsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCommentWorker (action) {
  try {
    const { data, isTenant, getComment } = action && action.payload

    yield updateComment({ data, isTenant })

    yield put(updateCommentSuccess())
    yield put(getCommentsStart({ ...getComment }))
    yield toast('Comment Resolved Successfully', 'success')
  } catch (e) {
    yield put(updateCommentFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
