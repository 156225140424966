import React from 'react'
import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { StepTwoSchema } from './schema'
import useCreateNotification from './useCreateNotification'
import NotificationForm from './component/NotificationForm'
import { ADMIN_ROLES } from '../../utils/constants'
import NotificationForUser from './component/NotificationForUser'
import NotificationMessageForm from './component/NotificationMessageForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowLeft, faArrowRight, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { Spinner } from '@themesberg/react-bootstrap'
import Trigger from '../OverlayTrigger'
const CreateNotification = ({ isTenant, isEdit = false }) => {
  const location = useLocation()
  const data = location?.state?.data
  const {
    t,
    step,
    handleBack,
    handleNext,
    handleSubmit,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    selectedClient,
    setSelectedClient,
    affiliateAndAgentsList,
    clientsFilterData,
    shouldNextDisabled,
    limit,
    setLimit,
    page,
    setPage,
    userForNotification,
    selectedPlayer,
    setSelectedPlayer,
    navigate,
    totalPages,
    role,
    agentOptions,
    affiliateOptions,
    withoutAffiliate,
    setWithoutAffiliate,
    agentWithoutAffiliate,
    client,
    getSingleNotification,
    setDefaultAffiliateOptions,
    setDefaultAgentWithoutAffiliateOptions,
    setDefaultAgentOptions,
    defaultAgentOptions,
    defaultAgentWithoutAffiliateOptions,
    defaultAffiliateOptions,
    userLoading,
    clientLoading,
    setAgentWithAffiliate,
    setAgentWithoutAffiliate,
    tempSelectedPlayer,
    setTempSelectedPlayer,
    search,
    setSearch,
    debounceFn,
    inputRef
  } = useCreateNotification(isTenant, isEdit)
  return (
    (
      !clientLoading
        ? (
          <>
            <div className='d-flex justify-content-between gap-2 align-items-center'>
              <h3>
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  className='cursor-pointer'
                  onClick={() => navigate(isTenant ? '/tenant/notification' : '/super-admin/notification')}
                /> {t(!isEdit ? 'createNotification' : 'editNotification')}
              </h3>
            </div>
            <Formik
              initialValues={{
                notificationMessage: isEdit ? data?.notificationMessage : '',
                notificationType: isEdit ? data?.referenceId : ''
              }}
              validationSchema={StepTwoSchema(t)}
              onSubmit={handleSubmit}
            >
              {({ values, error, handleChange, setFieldValue, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    {step === 1 && (
                      <>
                        <NotificationForm
                          t={t}
                          clientsFilterData={clientsFilterData}
                          affiliateOptions={affiliateOptions}
                          agentOptions={agentOptions}
                          isTenant={isTenant}
                          affiliateAndAgentsList={affiliateAndAgentsList}
                          selectedClient={selectedClient}
                          setSelectedClient={setSelectedClient}
                          setAffiliate={setAffiliate}
                          affiliate={affiliate}
                          setAgent={setAgent}
                          agent={agent}
                          role={role}
                          ADMIN_ROLES={ADMIN_ROLES}
                          selectedPlayer={selectedPlayer}
                          setSelectedPlayer={setSelectedPlayer}
                          limit={limit}
                          setLimit={setLimit}
                          page={page}
                          setPage={setPage}
                          shouldNextDisabled={shouldNextDisabled}
                          handleNext={handleNext}
                          defaultNotificationData={location?.state?.data}
                          withoutAffiliate={withoutAffiliate}
                          setWithoutAffiliate={setWithoutAffiliate}
                          agentWithoutAffiliate={agentWithoutAffiliate}
                          data={location?.state?.data}
                          isEdit={isEdit}
                          client={client}
                          getSingleNotification={getSingleNotification}
                          setDefaultAffiliateOptions={setDefaultAffiliateOptions}
                          setDefaultAgentWithoutAffiliateOptions={setDefaultAgentWithoutAffiliateOptions}
                          setDefaultAgentOptions={setDefaultAgentOptions}
                          defaultAgentOptions={defaultAgentOptions}
                          defaultAgentWithoutAffiliateOptions={defaultAgentWithoutAffiliateOptions}
                          defaultAffiliateOptions={defaultAffiliateOptions}
                          clientLoading={clientLoading}
                          setAgentWithoutAffiliate={setAgentWithoutAffiliate}
                          setAgentWithAffiliate={setAgentWithAffiliate}
                        />

                      </>
                    )}

                    {step === 2 && (
                      <NotificationMessageForm
                        handleBack={handleBack}
                        t={t}
                        defaultNotificationData={location?.state?.data}
                        isEdit={isEdit}
                      />
                    )}
                    <div className='d-flex align-items-center row justify-content-center gap-4 py-2'>
                      <div className='col-sm-6 justify-content-end d-flex gap-2 align-items-center'>
                        <Trigger message={t('back')}>
                          <Button className='d-flex gap-2 align-items-center' disabled={step === 1} type='button' variant='dark' onClick={handleBack}>
                            <FontAwesomeIcon size='sm' icon={faArrowLeft} />  <span>{t('back')}</span>
                          </Button>
                        </Trigger>
                        <Trigger message={t('next')}>
                          <Button className='d-flex gap-2 align-items-center' disabled={step === 2 || !selectedClient} type='button' variant='dark' onClick={handleNext}>
                            <span>{t('next')} </span><FontAwesomeIcon size='sm' icon={faArrowRight} />
                          </Button>
                        </Trigger>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
            {step === 1 && <span className='text-danger text-center fw-bold'>{t('noteForCreateNotification')}</span>}
            {
              userForNotification?.count > 0 && (
                <div className='d-flex align-items-center mt-3'>
                  <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                    {t('search')}
                  </Form.Label>&nbsp;
                  <Form.Control
                    ref={inputRef}
                    name='search'
                    type='search'
                    placeholder={t('searchUsernameOrName')}
                    size='sm'
                    style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
                    onChange={(e) => {
                      debounceFn(e)
                      setPage(1)
                    }}
                  />
                  <div className='mx-2'>
                    <Trigger message={t('resetFilters')}>
                      <Button
                        variant='outline-success'
                        className='py-1'
                        size='sm'
                        onClick={() => {
                          inputRef.current.value = ''
                        }}
                      >
                        <FontAwesomeIcon icon={faRedoAlt} />
                      </Button>
                    </Trigger>
                  </div>
                </div>
              )
            }
            {

      !userLoading
        ? (
          <NotificationForUser
            t={t}
            userForNotification={userForNotification}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            loading={userLoading}
            totalPages={totalPages}
            isEdit={isEdit}
            getSingleNotification={getSingleNotification}
            tempSelectedPlayer={tempSelectedPlayer}
            setTempSelectedPlayer={setTempSelectedPlayer}
            search={search}
            setSearch={setSearch}
            debounceFn={debounceFn}
            inputRef={inputRef}
          />
          )
        : (
          <div className='d-flex align-items-center justify-content-center mt-3'>
            <Spinner
              as='span'
              animation='border'
              size='lg'
              role='status'
              aria-hidden='true'
            />
          </div>
          )
     }
          </>
          )
        : (
          <div className='d-flex align-items-center justify-content-center mt-3'>
            <Spinner
              as='span'
              animation='border'
              size='lg'
              role='status'
              aria-hidden='true'
            />
          </div>
          )
    )
  )
}
export default CreateNotification
