import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getTenantBonusTransactionsStart } from '../../../../store/redux-slices/tenantTransactions'
import { debounce } from 'lodash'
import { downloadFile } from '../../../../utils/fileDownloader'
import { useTranslation } from 'react-i18next'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useBonusTransactions = ({ isReporting, isAffiliate, isAgent, selectedTab, isSportsBook }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { userId } = useParams()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [bonusType, setBonusType] = useState('')
  const { bonusTransactions, bonusTransactionsLoading } = useSelector(state => state.tenantTransactions)
  const totalPages = Math.ceil(bonusTransactions?.count / limit)

  const [state, setState] = useState(initialDate)
  const handleDebouncSearch = debounce((e) => {
    setSearch(e.target.value)
    setPage(1)
  }, 1000)

  useEffect(() => {
    if (selectedTab === 'bonusTransactions' || isReporting || isAffiliate || isAgent) {
      dispatch(getTenantBonusTransactionsStart({
        data: {
          limit,
          pageNo: page,
          from: moment(state[0].startDate).toISOString() || '',
          to: moment(state[0].endDate).toISOString() || '',
          actionType: bonusType,
          userId,
          search: search,
          agentId: agent,
          affiliateId: affiliate,
          type: isSportsBook ? 'SPORTBOOK' : 'CASINO'
        },
        isReporting: isReporting,
        isAffiliate,
        isAgent
      }))
    }
  }, [selectedTab, limit, page, search, state, bonusType, agent, affiliate, isSportsBook])

  const handleCsvDownload = () => {
    isReporting
      ? downloadFile(`${process.env.REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/bonus-transactions?pageNo=${page}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&actionType=${bonusType || ''}&csvDownload=true&token=${getLoginToken()}`)
      : downloadFile(`${process.env.REACT_APP_API_URL}/api/tenant/user/bonus-transaction?pageNo=${page}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&actionType=${bonusType || ''}&userId=${userId || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    handleCsvDownload,
    initialDate,
    setBonusType,
    bonusType,
    bonusTransactions,
    bonusTransactionsLoading,
    handleDebouncSearch,
    inputRef,
    setSearch,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useBonusTransactions
