import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getItem, getLoginToken } from '../../utils/storageUtils'
import { setIsNewNotification, updateNewNotification } from '../../store/redux-slices/bonus'
import { bonusSocketService, incorrectPasswordSocketService, affiliateBonusSocketService, walletRequestService } from '../../utils/service/Socket.service'
import { EVENT_LISTENERS, SOCKET_ROOM_NAME } from '../../utils/service/socketConstant'
import { getAllBonusStart, updateBonusList } from '../../store/redux-slices/admins'
import { ADMIN_ROLES, NOTIFICATION_TYPE } from '../../utils/constants'

const useSocketConnect = () => {
  const accessToken = getLoginToken()
  const dispatch = useDispatch()
  const role = getItem('role')
  useEffect(() => {
    if (accessToken) {
      if (!incorrectPasswordSocketService?.socket?.connected) { incorrectPasswordSocketService.initializeSocket(accessToken, SOCKET_ROOM_NAME.INCORRECT_PASSWORD_NOTIFICATION, false) }

      incorrectPasswordSocketService.on(EVENT_LISTENERS.INCORRECT_PASSWORD_NOTIFICATION, (data) => {
        dispatch(setIsNewNotification(true))
        dispatch(updateNewNotification({ message: data?.data?.socketObj?.description, type: NOTIFICATION_TYPE.INCORRECT_PASSWORD }))
      })

      if (!walletRequestService?.socket?.connected) { walletRequestService.initializeSocket(accessToken, SOCKET_ROOM_NAME.WALLET_REQUEST, false) }
      walletRequestService.on(EVENT_LISTENERS.WALLET_REQUEST, (data) => {
        dispatch(setIsNewNotification(true))
        dispatch(updateNewNotification({ message: data?.data?.socketObj?.translations?.[`${getItem('language')}`], handlerType: data?.data?.socketObj?.type, type: NOTIFICATION_TYPE.INCORRECT_PASSWORD }))
      })

      if (role === ADMIN_ROLES.ADMIN) {
        //  Notification
        if (!bonusSocketService?.socket?.connected) { bonusSocketService.initializeSocket(accessToken, SOCKET_ROOM_NAME.BONUS_NOTIFICATION) }
        bonusSocketService.on(EVENT_LISTENERS.BONUS_NOTIFICATION, (data) => {
          dispatch(setIsNewNotification(true))
          dispatch(updateBonusList(data?.data))
          dispatch(updateNewNotification({ message: 'New bonus created, please check bonus section', type: NOTIFICATION_TYPE.BONUS }))
        })
      } else if (role === ADMIN_ROLES.AFFILIATE) {
        if (!affiliateBonusSocketService?.socket?.connected) { affiliateBonusSocketService.initializeSocket(accessToken, SOCKET_ROOM_NAME.BONUS_NOTIFICATION_AFFILIATE) }
        affiliateBonusSocketService.on(EVENT_LISTENERS.BONUS_NOTIFICATION_AFFILIATE, (data) => {
          dispatch(setIsNewNotification(true))
          dispatch(getAllBonusStart({
            formData: {
              size: 15,
              pageNo: 1

            },
            isAffiliate: true
          }))
          dispatch(updateNewNotification({ message: 'New bonus created, please check bonus section', type: NOTIFICATION_TYPE.BONUS }))
        })
      }
    }
    return () => {
      // Disconnections
      bonusSocketService.off(EVENT_LISTENERS.BONUS_NOTIFICATION)
      bonusSocketService.off(EVENT_LISTENERS.DISCONNECT)
      incorrectPasswordSocketService.off(EVENT_LISTENERS.INCORRECT_PASSWORD_NOTIFICATION)
      incorrectPasswordSocketService.off(EVENT_LISTENERS.DISCONNECT)
      affiliateBonusSocketService.off(EVENT_LISTENERS.BONUS_NOTIFICATION_AFFILIATE)
      affiliateBonusSocketService.off(EVENT_LISTENERS.DISCONNECT)
      walletRequestService.off(EVENT_LISTENERS.WALLET_REQUEST)
      walletRequestService.off(EVENT_LISTENERS.DISCONNECT)
    }
  }, [accessToken])
}

export default useSocketConnect
