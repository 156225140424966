import {
  Row,
  Col,
  Button,
  Form as BForm
} from '@themesberg/react-bootstrap'
import React from 'react'
import ClientFilter from '../ClientFilter'
import { tableHeaders, affiliateStatus } from './affiliateConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../OverlayTrigger'
import AffiliateListing from './AffiliateListing'
import useAffiliates from './hooks/useAffiliates'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { parentRoles } from '../Agent/agentConstants'
import InputDatePicker from '../../components/FormElements/InputDatePicker/InputDatePicker'
import useIsGamma from '../../common/hook/useIsGamma'
import { useLocation } from 'react-router-dom'
const Affiliates = () => {
  const { isGamma } = useIsGamma()
  const isTenant = !window.location.href.match(/super/g)
  const location = useLocation()
  const path = location.pathname

  const {
    selectedClient,
    state,
    setState,
    setSelectedClient,
    affiliateData,
    navigate,
    loading,
    handleShow,
    initialDate,
    show,
    setShow,
    handleYes,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    name,
    setSearch,
    t,
    inputRef,
    parentRole,
    setParentRole,
    status,
    setStatus,
    debounceFn
  } = useAffiliates({ isTenant })

  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>{t('affiliate')}</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            {!isTenant && <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
                          />}
            <>
              {
                !isGamma &&
                (
                  <Button
                    className='m-1'
                    size='sm'
                    variant='outline-success'
                    onClick={() => navigate(isTenant ? TenantRoutes.createAffiliate : SuperAdminRoutes.createAffiliate)}
                  >
                    {t('create')}
                  </Button>
                )
              }
            </>
          </div>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
            {t('search')}
          </BForm.Label>&nbsp;
          <BForm.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('search')}
            size='sm'
            style={{ minWidth: '200px', maxWidth: '240px', width: 'fit-content' }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </Col>

        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('fromDate')}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
            value={state[0].startDate}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('toDate')}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
            value={state[0].endDate}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
            {t('parentRole')}
          </BForm.Label>
          <BForm.Select
            name='parentRole'
            size='sm'
            value={parentRole}
            onChange={(e) => setParentRole(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {parentRoles(path)?.map((item) => {
              return (
                item.value !== 'cashfreespins' &&
                  <option key={item.value} value={item.value}>
                    {t(item?.label).toUpperCase()}
                  </option>
              )
            })}
          </BForm.Select>
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <BForm.Label column='sm' style={{ margin: '0 15px', minWidth: 'fit-content' }}>
            {t('status')}
          </BForm.Label>
          <BForm.Select
            name='isActive'
            size='sm'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {affiliateStatus?.map(({ label, value }, i) => {
              return (
                <option value={value} key={`status-filter ${i}`}>
                  {t(label)}
                </option>
              )
            })}
          </BForm.Select>
        </Col>
        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setState(initialDate)
                setStatus('')
                setParentRole('')
                setSearch('')
                setSelectedClient('')
                inputRef.current.value = ''
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <AffiliateListing
        affiliateData={affiliateData}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        tableHeaders={tableHeaders}
        show={show}
        setShow={setShow}
        handleYes={handleYes}
        active={active}
        handleShow={handleShow}
        navigate={navigate}
        name={name}
        loading={loading}
        isTenant={isTenant}
        t={t}
      />
    </div>
  )
}

export default Affiliates
