
import React from 'react'
import {
  Navbar,
  Image,
  Col,
  Row,
  Dropdown
} from '@themesberg/react-bootstrap'
import './Header.scss'
import useHeader from './hooks/useHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { ADMIN_ROLES } from '../../utils/constants'
import { getItem } from '../../utils/storageUtils'

const Header = ({ isTenantRoute, isAffiliate, isAgent }) => {
  const {
    navigate,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    isNewNotification,
    newNotificationData,
    handleCheckNotification,
    t,
    role,
    adminDetails,
    handleNavigate
  } = useHeader({ isTenantRoute, isAffiliate, isAgent })

  const tenantBar = (tenantDetails) => {
    return (
      <>
        {tenantDetails?.tenantConfig?.logoUrl && (
          <Image
            thumbnail
            alt=''
            src={tenantDetails.tenantConfig.logoUrl}
            className='d-inline-block align-top mx-2'
          />
        )}
        {tenantDetails?.name}
      </>
    )
  }

  const superAdminBar = (adminDetails) => {
    return (
      <>
        {adminDetails?.tenantConfig?.logoUrl && (
          <Image
            thumbnail
            alt=''
            src={adminDetails.tenantConfig.logoUrl}
            className='d-inline-block align-top mx-2'
          />
        )}
        {role === ADMIN_ROLES.AFFILIATE ? adminDetails?.username : adminDetails?.firstName}
      </>
    )
  }

  return (
    <div style={{ padding: '0 0.8rem 0 0.8rem' }}>
      <Row className='mb-2 px-3 py-2 rounded-2 bg-white shadow align-items-center'>
        {/* Tenant info name and logo */}
        <Col>
          <Navbar.Brand>
            {(role === ADMIN_ROLES.SUPERADMIN || role === ADMIN_ROLES.AFFILIATE) ? superAdminBar(adminDetails) : tenantBar(tenantDetails)}
          </Navbar.Brand>
        </Col>
        <Col>
          <div className='d-flex gap-1 align-items-center justify-content-end'>
            {/* Notification Dropdown */}
            <Dropdown>
              <Dropdown.Toggle id='notification-dropdown' variant='white'>
                <div onClick={handleCheckNotification} className='notification-icon'>
                  <FontAwesomeIcon size='25' icon={faBell} />
                  {isNewNotification && <div className='dot' />}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className='notification-dropdown p-1'>

                {newNotificationData?.length > 0
                  ? newNotificationData?.map((item, index) =>
                    <Dropdown.Item key={index} className='mb-1'>
                      <div onClick={() => handleNavigate(item)} className='notification-content'> <span className='me-1'> <FontAwesomeIcon icon={faMoneyCheckAlt} /></span>
                        {
                      item?.message && typeof item?.message === 'object'
                        ? item?.message[getItem('language')]
                        : item?.message
                      }
                      </div>
                    </Dropdown.Item>
                    )
                  : (
                    <Dropdown.Item>
                      <div className='notification-content text-center'>{t('noNotification')}</div>
                    </Dropdown.Item>
                    )}

              </Dropdown.Menu>
            </Dropdown>

            {/* Tenant urls dropdown */}
            {tenantsList && tenantsList.rows && tenantDetails?.name &&
              <Dropdown
                className=' d-inline mx-2'
              >
                <Dropdown.Toggle id='dropdown-autoclose-outside flex-end' variant='white'>
                  <button
                    size='sm'
                    className={`tenant-circle-button ${tenantDetails?.isActive ? 'active-button' : 'inactive-button'}`}
                  />&nbsp;{`${tenantDetails?.name} (${tenantDetails?.domain})`}
                </Dropdown.Toggle>
                <Dropdown.Menu className='user-dropdown tenant-dropdown'>
                  {tenantsList.rows.map(({ name, tenantId, domain, isActive }) => {
                    return (
                      <Dropdown.Item
                        key={name}
                        onClick={(e) => {
                          tenantChangeHandler(tenantId)
                          navigate(0)
                        }}
                      >
                        <button
                          size='sm'
                          className={`tenant-circle-button ${isActive ? 'active-button' : 'inactive-button'}`}
                        />&nbsp;{name}({domain})
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Header
