import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllTenantsListStart } from '../../../store/redux-slices/tenants'
import { debounce } from '../../../utils/debounce'
import { getItem, setItem } from '../../../utils/storageUtils'
import { setIsNewNotification } from '../../../store/redux-slices/bonus'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION_TYPE } from '../../../utils/constants'
import { AffiliateRoutes, TenantRoutes, SuperAdminRoutes } from '../../../routes'

const useHeader = ({ isTenantRoute, isAffiliate, isAgent }) => {
  const role = getItem('role')
  const { isNewNotification, newNotificationData } = useSelector(state => state.bonus)
  const { adminDetails } = useSelector(state => state.admins)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading, tenantsList } = useSelector((state) => state.tenants)
  const [tenantDetails, setTenantDetails] = useState()
  const handleNavigate = (item) => {
    if (item?.handlerType === 'WALLET-WITHDRAW-REQUEST') {
      navigate(SuperAdminRoutes.V2UserWithdrawal)
    }
    if (item?.handlerType === 'WALLET-DEPOSIT-REQUEST') {
      navigate(SuperAdminRoutes.V2UserDeposit)
    }
    if (item?.type === NOTIFICATION_TYPE.BONUS) {
      if (isAffiliate) {
        navigate(AffiliateRoutes.bonusListing)
      } else if (isTenantRoute) {
        navigate(TenantRoutes.TenantBonusNotification)
      }
    }
  }
  const handleCheckNotification = () => {
    dispatch(setIsNewNotification(false))
  }
  const tenantChangeHandler = (id) => {
    const details = tenantsList?.rows?.filter((item) => item.tenantId === Number(id))
    setItem('tenant-id', id)
    setTenantDetails(details[0])
  }

  useEffect(() => {
    if (tenantsList && tenantsList.rows && tenantsList.rows.length > 0) {
      tenantChangeHandler(getItem('tenant-id'))
    } else {
      setTenantDetails()
    }
  }, [tenantsList])

  useEffect(() => {
    if (tenantsList === null) {
      if (isTenantRoute) { debounce(dispatch(getAllTenantsListStart({})), 1000) }
    }
  }, [])

  return {
    handleNavigate,
    isNewNotification,
    navigate,
    loading,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    newNotificationData,
    handleCheckNotification,
    t,
    role,
    adminDetails
  }
}

export default useHeader
