import { createSlice } from '@reduxjs/toolkit'

const superAdminSportsBookSlice = createSlice({
  name: 'superAdminSportsBook',
  initialState: {
    loading: false,
    sportsListing: [],
    sportsCountriesListing: [],
    leaguesListing: [],
    matchListing: [],
    marketListing: [],
    betslipDetails: [],
    addTopLoading: false,
    sportsCountries: [],
    sportsCountriesLoading: false,
    marketOutcomes: [],
    hotCombos: [],
    matchMarkets: [],
    addComboLoading: false,
    reorderSportsList: {},
    updateLoading: false,
    teamListing: {}

  },
  reducers: {
    getTeamListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getTeamListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      teamListing: payload
    }),
    getTeamListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTeamListingStartComboComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    deleteHotComboStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    deleteHotComboComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    updateTeamImageStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateTeamImageComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    updateHotComboStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateHotComboComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    getSportsListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getSportsListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportsListing: payload
    }),
    getSportsListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSportsListingReorderStart: (state) => ({
      ...state,
      loading: true
    }),
    getSportsListingReorderSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      reorderSportsList: payload
    }),
    getSportsListingReorderFailure: (state) => ({
      ...state,
      loading: false
    }),
    getMarketOutcomeStart: (state) => ({
      ...state,
      loading: true
    }),
    getMarketOutcomeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      marketOutcomes: payload
    }),
    getMarketOutcomeFailure: (state) => ({
      ...state,
      loading: false
    }),
    getMatchMarketStart: (state) => ({
      ...state,
      loading: true
    }),
    getMatchMarketSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      matchMarkets: payload
    }),
    getMatchMarketFailure: (state) => ({
      ...state,
      loading: false
    }),
    addHotComboStart: (state) => ({
      ...state,
      addComboLoading: true
    }),
    addHotComboSuccess: (state, { payload }) => ({
      ...state,
      addComboLoading: false
    }),
    addHotComboFailure: (state) => ({
      ...state,
      addComboLoading: false
    }),
    getHotComboStart: (state) => ({
      ...state,
      loading: true
    }),
    getHotComboSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      hotCombos: payload
    }),
    getHotComboFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSportsCountriesListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getSportsCountriesListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportsCountriesListing: payload
    }),
    getSportsCountriesListingFailure: (state) => ({
      ...state,
      loading: false

    }),
    getMatchListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getMatchListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      matchListing: payload
    }),
    getMatchListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getMarketListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getMarketsListingSuccess: (state, { payload }) => ({
      ...state,
      marketListing: payload,
      loading: false
    }),
    getMarketsListingFailure: (state) => ({
      ...state,
      loading: false
    }),
    getLeaguesListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getLeaguesListingSuccess: (state, { payload }) => ({
      ...state,
      leaguesListing: payload,
      loading: false
    }),
    getLeaguesListingFailure: (state) => ({
      ...state,
      loading: false,
      leaguesListing: []

    }),
    getTopTournamentStart: (state) => ({
      ...state,
      loading: true,
      topTournaments: {}
    }),
    getTopTournamentSuccess: (state, { payload }) => ({
      ...state,
      topTournaments: payload,
      loading: false
    }),
    getTopTournamentFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateSportsBookActionSAStart: (state) => ({
      ...state,
      loadingFallback: true
    }),
    updateSportsBookActionSASuccess: (state, payload) => ({
      ...state,
      loadingFallback: false
    }),
    updateSportsBookActionSAFailure: (state) => ({
      ...state,
      loadingFallback: false
    }),
    reorderLeaguesStart: (state) => ({
      ...state,
      loading: true
    }),
    reorderLeaguesSuccess: (state, payload) => ({
      ...state,
      loading: false
    }),
    reorderLeaguesFailure: (state) => ({
      ...state,
      loading: false
    }),
    reorderSportsStart: (state) => ({
      ...state,
      loading: true
    }),
    reorderSportsSuccess: (state, payload) => ({
      ...state,
      loading: false
    }),
    reorderSportsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBetslipDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getBetslipDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      betslipDetails: payload
    }),
    getBetslipDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    addTopStart: (state, action) => ({
      ...state,
      addTopLoading: action?.payload?.entityId
    }),
    addTopSuccess: (state, { payload }) => ({
      ...state,
      addTopLoading: false
    }),
    addTopFailure: (state) => ({
      ...state,
      addTopLoading: false
    }),
    sportsCountriesStart: (state) => ({
      ...state,
      sportsCountriesLoading: true
    }),
    sportsCountriesSuccess: (state, { payload }) => ({
      ...state,
      sportsCountries: payload,
      sportsCountriesLoading: false
    }),
    sportsCountriesFailure: (state) => ({
      ...state,
      sportsCountriesLoading: false
    })
  }
})

export default superAdminSportsBookSlice.reducer

export const {
  getSportsListingStart,
  getSportsListingSuccess,
  getSportsListingFailure,
  getSportsListingReorderStart,
  getSportsListingReorderSuccess,
  getSportsListingReorderFailure,
  getSportsCountriesListingStart,
  getSportsCountriesListingSuccess,
  getSportsCountriesListingFailure,
  getMatchListingStart,
  getMatchListingSuccess,
  getMatchListingFailure,
  getMarketListingStart,
  getMarketsListingSuccess,
  getMarketsListingFailure,
  getLeaguesListingStart,
  getLeaguesListingSuccess,
  getLeaguesListingFailure,
  updateSportsBookActionSAStart,
  updateSportsBookActionSASuccess,
  updateSportsBookActionSAFailure,
  getBetslipDetailStart,
  getBetslipDetailSuccess,
  getBetslipDetailFailure,
  addTopStart,
  addTopSuccess,
  addTopFailure,
  sportsCountriesFailure,
  sportsCountriesSuccess,
  sportsCountriesStart,
  reorderLeaguesStart,
  reorderLeaguesSuccess,
  reorderLeaguesFailure,
  reorderSportsStart,
  reorderSportsSuccess,
  reorderSportsFailure,
  getTopTournamentStart,
  getTopTournamentSuccess,
  getTopTournamentFailure,
  getMarketOutcomeStart,
  getMarketOutcomeSuccess,
  getMarketOutcomeFailure,
  getHotComboStart,
  getHotComboSuccess,
  getHotComboFailure,
  addHotComboStart,
  addHotComboSuccess,
  addHotComboFailure,
  getMatchMarketStart,
  getMatchMarketSuccess,
  getMatchMarketFailure,
  deleteHotComboStart,
  deleteHotComboComplete,
  updateHotComboStart,
  updateHotComboComplete,
  getTeamListingStart,
  getTeamListingSuccess,
  getTeamListingFailure,
  updateTeamImageStart,
  updateTeamImageComplete
} = superAdminSportsBookSlice.actions
