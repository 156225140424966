import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputGroup, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Row, Form as BForm } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetProfilePasswordStart } from '../../store/redux-slices/login'
import { profilePasswordSchema } from './schema'
import Trigger from '../OverlayTrigger'

const Password = ({
  loading,
  isTenant,
  setSelectedTab,
  t,
  isAffiliate,
  isAgent,
  isAffiliateAndAgent
}) => {
  const formData = {
    OLD: 'password',
    NEW: 'newPassword',
    CONFIRM: 'confirmPassword'
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPass, setShowPass] = useState({
    OLD: false,
    NEW: false,
    CONFIRM: false
  })
  const handleCancel = () => {
    setSelectedTab('overview')
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={profilePasswordSchema(t)}
        onSubmit={(formValues) => {
          dispatch(resetProfilePasswordStart({
            data: {
              password: isAffiliateAndAgent ? btoa(formValues?.password) : formValues?.password,
              newPassword: isAffiliateAndAgent ? btoa(formValues?.newPassword) : formValues?.newPassword
            },
            navigate,
            isTenant,
            isAffiliate,
            isAgent
          }))
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur }) => {
          return (
            <Form className='mt-5'>
              {Object.keys(formData)?.map((key, index) => (
                <Row key={index} className='mt-3'>
                  <Col xs={6}>
                    <BForm.Label>
                      {t(key)} {t('password')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>
                    <Trigger message={t('passwordRequirements')}>

                      <InputGroup>
                        <BForm.Control
                          type={showPass?.[key] ? 'text' : 'password'}
                          maxLength={16}
                          name={formData[key]}
                          value={values?.[formData[key]]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onPaste={e => e.preventDefault()}
                        />
                        <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                          {!showPass?.[key]
                            ? <FontAwesomeIcon
                                icon={faEyeSlash} style={{ cursor: 'pointer' }}
                                onClick={() => { setShowPass({ ...showPass, [key]: true }) }}
                              />
                            : <FontAwesomeIcon
                                icon={faEye} style={{ cursor: 'pointer' }}
                                onClick={() => { setShowPass({ ...showPass, [key]: false }) }}
                              />}
                        </InputGroup.Text>
                      </InputGroup>
                    </Trigger>

                    <ErrorMessage
                      component='div'
                      name={formData[key]}
                      className='text-danger'
                    />
                  </Col>
                </Row>))}
              <div className='mt-4 mb-3'>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2  px-3'
                >
                  {t('submit')}
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
                <Button
                  variant='outline-warning'
                  onClick={handleCancel}
                  className='float-end'
                >
                  {t('cancel')}
                </Button>
              </div>

            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Password
