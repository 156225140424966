import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getAffiliateAndAgentsListStart, getAgentFilterListStart } from '../../store/redux-slices/adminUser'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../utils/storageUtils'
import { ADMIN_ROLES } from '../../utils/constants'

const useAffiliateAgent = ({ selectedClient, isTenant, affiliate }) => {
  const dispatch = useDispatch()
  const { affiliateAndAgentsList } = useSelector((state) => state.adminUser)
  const { t } = useTranslation()
  const role = getItem('role')
  useEffect(() => {
    if (role === ADMIN_ROLES.AFFILIATE) {
      dispatch(getAgentFilterListStart({
        isAffiliate: true
      }))
    } else if (role === ADMIN_ROLES.AGENT) {
      console.log()
    } else {
      if (!isTenant) {
        if (selectedClient) { dispatch(getAffiliateAndAgentsListStart({ adminUserId: selectedClient })) }
      } else {
        dispatch(getAffiliateAndAgentsListStart({ isTenant: true }))
      }
    }
  }, [selectedClient])
  return {
    affiliateAndAgentsList,
    t
  }
}

export default useAffiliateAgent
