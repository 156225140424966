import * as Yup from 'yup'

export const sendEmailSchema = (t) => {
  Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('emailRequired'))
  })
}

export const resetPasswordSchema = (t) => {
  Yup.object().shape({
    password:
      Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          t('invalidPassword')
        )
        .max(50)
        .required(t('passwordRequired')),
    confirmPassword:
      Yup.string()
        .max(50)
        .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
        .required(t('confirmPasswordRequired'))
  })
}
