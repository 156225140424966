export const reasonOptions = [
  'Image Not Visible Properly',
  'Not A Valid Document',
  'Document Validation Expired',
  'Add Custom Reason'
]

export const tableHeaders = [
  { label: 'Id', value: 'userBonusId' },
  { label: 'Promotion Title', value: 'promotionTitle' },
  { label: 'Bonus Type', value: 'bonusType' },
  { label: 'Valid Till', value: 'validTo' },
  { label: 'Is Expired', value: 'isExpired' },
  { label: 'Status', value: 'isActive' },
  { label: 'Issued By', value: 'issuerRole' },
  { label: 'Issued At', value: 'createdAt' },
  { label: 'Claimed At', value: 'claimedAt' },
  { label: 'Amount To Wager', value: 'amountToWager' },
  { label: 'Wagered Amount', value: 'wageredAmount' },
  { label: 'Cancelled By', value: 'cancelledBy' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'Action', value: 'action' }
]
export const tableInspectionHeaders = [
  { label: 'sNo', value: '' },
  { label: 'wagerType', value: 'actionType' },
  { label: 'totalWager', value: 'totalCount' },
  { label: 'totalAmount', value: 'totalWinAmount' }
]

export const bonusStatus = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Forfeited', value: 'FORFEITED' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Completed', value: 'COMPLETED' }
]

export const bonusTypes = [
  { label: 'All', value: '' },
  { label: 'MATCH', value: 'match', id: 0 },
  { label: 'BALANCE', value: 'balance', id: 1 },
  { label: 'FREESPINS', value: 'freespins', id: 2 },
  { label: 'DEPOSIT(CASHBACK)', value: 'deposit', id: 4 },
  { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 }
]

export const affiliateList = [
  { label: 'Parent Affiliate ID', value: 'parentIdAffiliate' },
  { label: 'Parent Username', value: 'parentUsername' },
  { label: 'Affiliate ID', value: 'userIdAffiliate' },
  { label: 'User Name', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Country', value: 'country' },
  { label: 'Currency', value: 'currency' },
  { label: 'Mobile', value: 'mobile' }
]

export const userDisableReasons = [
  { label: 'bonusAbuser', value: 'Bonus abuser' },
  { label: 'duplicateAccount', value: 'Duplicate account' },
  { label: 'fraudulent', value: 'Fraudulent' },
  { label: 'riskCountry', value: 'Risk country' },
  { label: 'rudeBehaviour', value: 'Rude behaviour' },
  { label: 'bannedCountryConnectWithVpnOrTorBrowser', value: 'Banned country - connect with VPN or Tor Browser' },
  { label: 'kycDeclined', value: 'KYC declined' },
  { label: 'suspiciousConstantDepositsAndWithdrawalsMoneyLaundering', value: 'Suspicious - constant deposits and withdrawals (money laundering)' },
  { label: 'contactsSupportForABiggerAmountOfDepositThanAllowed', value: 'Contacts support for a bigger amount of deposit than allowed' },
  { label: 'responsibleGambling', value: 'Responsible Gambling' },
  { label: 'pending', value: 'Pending' },
  { label: 'frozen', value: 'Frozen' },
  { label: 'deactivated', value: 'Deactivated' },
  { label: 'permabanned', value: 'Permabanned' },
  { label: 'tempbannedBlocked', value: 'Tempbanned/Blocked' },
  { label: 'gamblingIssues', value: 'Gambling issues' },
  { label: 'selfExcluded', value: 'Self-excluded' },
  { label: 'dpoErasureRequested', value: 'DPO erasure requested' },
  { label: 'kycBlockedSuspended', value: 'KYC blocked/suspended' },
  { label: 'kycBlockedDuplicate', value: 'KYC blocked/duplicate' },
  { label: 'kycBlockedBonusAbuse', value: 'KYC blocked/bonus abuse' },
  { label: 'kycBlockedChargeback', value: 'KYC blocked/chargeback' },
  { label: 'kycFraud', value: 'KYC fraud' },
  { label: 'failedKyc', value: 'Failed KYC' },
  { label: 'amlFailedSow', value: 'AML failed/SOW' },
  { label: 'amlManagementBan', value: 'AML management ban' },
  { label: 'amlOnHold', value: 'AML on hold' },
  { label: 'amlUnderReview', value: 'AML under review' },
  { label: 'rgUnderaged', value: 'RG underaged' },
  { label: 'rgUnderReview', value: 'RG under review' },
  { label: 'enforcedSelfExclusion', value: 'Enforced self-exclusion' },
  { label: 'locationBan', value: 'Location ban' },
  { label: 'csManagementBan', value: 'CS management ban' },
  { label: 'validated', value: 'Validated' },
  { label: 'other', value: 'Other' }
]

export const inspectionTable = [
  { label: 's.no', value: '' },
  { label: 'MATCH', value: 'match', id: 0 },
  { label: 'BALANCE', value: 'balance', id: 1 },
  { label: 'FREESPINS', value: 'freespins', id: 2 },
  { label: 'DEPOSIT(CASHBACK)', value: 'deposit', id: 4 },
  { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 }
]
