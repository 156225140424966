export const tableHeaders = (isUniqueCodeBonus) => {
  const headers = [
    { label: 'sNo', value: '' },
    { label: 'name', value: 'username' },
    { label: 'validFrom', value: 'regDate' },
    { label: 'validTo', value: 'country' },
    { label: isUniqueCodeBonus ? 'depositTarget' : 'betTarget', value: 'kycStatus' },
    { label: 'benefitType', value: 'kycStatus' },
    { label: 'useLimit', value: 'kycStatus' },
    { label: 'benefitAmount', value: 'vaultAmount' },
    { label: 'createdAt', value: 'amount' },
    { label: 'status', value: 'status' },
    { label: 'action', value: 'action' }
  ]

  if (isUniqueCodeBonus) {
    const actionIndex = headers.findIndex(header => header.label === 'action')
    headers.splice(actionIndex, 0,
      { label: 'isExpired', value: 'isExpired' },
      { label: 'rollingPercentage', value: 'rollingPercentage' }
    )
  }

  return headers
}

export const benefitTypes = [
  { label: 'all', value: '' },
  { label: 'Cash', value: 'cash' },
  { label: 'Bonus', value: 'bonus' }
]

export const voucherStatus = [
  { label: 'all', value: '' },
  { label: 'active', value: true },
  { label: 'inactive', value: false }
]
