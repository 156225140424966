import { Row, Col, Table, Dropdown, Button, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../OverlayTrigger'
import useLanguageMgmt from './hooks/useLanguageMgmt'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { keysNotToBeShown } from './hooks/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { languageCode } from '../../pages/Tenant/TenantConfigurations/Languages/constants'
import UploadModal from './components/uploadModal'
import './style.css'
import Select from 'react-select'
import Preloader from '../Preloader'
import useCheckPermission from '../../utils/checkPermission'
import { downloadFile } from '../../utils/fileDownloader'

export default ({
  isTenant = false,
  errorCodes = false
}) => {
  const {
    myKeyData,
    navigate,
    handleDownload,
    handleUpload,
    uploadModal,
    setUploadModal,
    availableLanguages,
    setKeySearch,
    keyOptions,
    myKeys,
    search,
    setSearch,
    myLanguages
  } = useLanguageMgmt({ isTenant, errorCodes })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col xs={4}>
          <h3>{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          {(!errorCodes ? !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) : !isHidden({ module: { key: 'CashierManagement', value: 'U' } })) &&
            <div className='m-1'>
              <Dropdown
                className='d-inline'
              >
                <Dropdown.Toggle id='dropdown-autoclose-outside' className='float-end btn-success'>
                  Add Language
                </Dropdown.Toggle>

                <Dropdown.Menu className='user-dropdown language'>
                  {availableLanguages?.length
                    ? availableLanguages?.map(lang => {
                        return (
                          <Dropdown.Item
                            key={lang}
                            onClick={() => !isTenant
                              ? navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/add-language/${lang}`)
                              : navigate(`/tenant/${errorCodes ? 'err/' : ''}language-management/add-language/${lang}`)}
                          >{languageCode[lang]}
                          </Dropdown.Item>
                        )
                      })
                    : (
                      <Dropdown.Item
                        className='text-danger'
                        disabled
                      ><strong>No Languages Available To Add</strong>
                      </Dropdown.Item>
                      )}
                </Dropdown.Menu>
              </Dropdown>
            </div>}
          {(!errorCodes ? !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) : !isHidden({ module: { key: 'CashierManagement', value: 'U' } })) &&
            <div className='m-1'>
              <Dropdown
                className=' d-inline'
              >
                <Dropdown.Toggle id='dropdown-autoclose-outside' className='float-end btn-warning'>
                  Edit
                </Dropdown.Toggle>

                <Dropdown.Menu className='user-dropdown lang'>
                  <Dropdown.Item
                    onClick={() => !isTenant
                      ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguage : SuperAdminRoutes.EditSupportLanguage)
                      : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguage : TenantRoutes.EditSupportLanguage)}
                  >Edit by Position
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => !isTenant
                      ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguageKeys : SuperAdminRoutes.EditSupportLanguageKeys)
                      : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguageKeys : TenantRoutes.EditSupportLanguageKeys)}
                  >Edit By Key
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>}
          <Trigger message='Download Xls File'>
            <Button
              variant='success'
              hidden={errorCodes}
              className='m-1'
              onClick={() => { downloadFile(handleDownload()) }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Trigger>
          <Trigger message='Upload Xls File'>
            <Button
              variant='success'
              className='m-1'
              hidden={isHidden({ module: { key: 'MultiLanguage', value: 'C' } }) || errorCodes}
              onClick={() => setUploadModal(true)}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Row className='mt-3 d-flex'>
        <Col className='d-flex align-items-center'>
          <Form.Label>Search Keys : </Form.Label> &nbsp;
          <Select
            name='keys'
            options={keyOptions}
            placeholder='Select Keys'
            defaultValue={{ label: 'All', value: '' }}
            styles={{ container: styles => ({ ...styles, zIndex: '2', width: '250px' }) }}
            className='basic-multi-select zindex-dropdown m-1'
            classNamePrefix='select'
            onChange={(option, e) => {
              setKeySearch(option.value)
            }}
          />
        </Col>
        <Col className='d-flex  align-items-center'>
          <Form.Label>Search : </Form.Label>&nbsp;
          <Form.Control
            name='search'
            type='search'
            placeholder='Search'
            value={search}
            className='w-75'
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Col>
        <Col />
      </Row>
      {(!myKeys || !Object.keys(myKeys).length)
        ? <Preloader />
        : (
          <>

            <div style={{ overflow: 'auto', maxHeight: '82vh', marginTop: '10px' }}>
              <Table
                bordered
                striped
                size='sm'
              >
                <thead className='thead-dark fixTableHeadSA-th'>
                  <tr>
                    <th>Keys</th>
                    {(errorCodes ? !isHidden({ module: { key: 'CashierManagement', value: 'U' } }) : !isHidden({ module: { key: 'MultiLanguage', value: 'U' } })) && <th>Action</th>}
                    {Array.from(myLanguages).map(language => {
                      return myLanguages.has(language) && (
                        <th key={language}>{languageCode[language]}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {myKeyData && Object.keys(myKeyData).map(key => {
                    return (
                      !keysNotToBeShown.includes(key) && (
                        <tr key={key}>
                          <td className='fw-bold fs-6'>{key}</td>
                          {(errorCodes ? !isHidden({ module: { key: 'CashierManagement', value: 'U' } }) : !isHidden({ module: { key: 'MultiLanguage', value: 'U' } })) &&
                            <td>
                              <Button
                                variant='warning'
                                size='sm'
                                onClick={() => {
                                  isTenant
                                    ? navigate(`/tenant/language-management/edit/${key}`)
                                    : navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/edit/${key}`)
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </td>}
                          {
                 Array.from(myLanguages).map(lang => {
                   return (
                     <td key={lang}>
                       <Trigger message={myKeyData[key][lang]}>
                         <span
                           style={{
                             width: '300px',
                             cursor: 'pointer'
                           }}
                         >
                           {myKeyData[key][lang]}
                         </span>
                       </Trigger>
                     </td>
                   )
                 })
                }
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </Table>
              {(!myKeyData || !Object.keys(myKeyData)?.length) && (
                <p className='text-danger text-center'> No data found</p>
              )}
            </div>
          </>)}

      {uploadModal &&
        <UploadModal
          show={uploadModal}
          setShow={setUploadModal}
          handleYes={handleUpload}
        />}
    </>
  )
}
