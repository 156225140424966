import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { Form } from 'react-bootstrap'
import { InlineLoader } from '../../../../components/Preloader'

const CountryForm = ({
  data,
  setData,
  search,
  setSearch,
  myCountries,
  loading = false,
  details = false,
  cashier = false,
  kycCountries,
  setKycCountries
}) => {
  const renderCountry = (code, name) => {
    return (
      <tr key={code}>
        {details
          ? cashier
              ? (
                <>
                  {(data?.includes(code) || kycCountries?.includes(code)) &&
             (data?.includes(code)
               ? kycCountries?.includes(code)
                   ? (
                     <>
                       <td>{name} ({code})</td>
                       <td>{name} ({code})</td>
                     </>
                     )
                   : (
                     <>
                       <td>{name} ({code})</td>
                       <td>-</td>
                     </>
                     )
               : data?.includes(code)
                 ? (
                   <>
                     <td>{name} ({code})</td>
                     <td>{name} ({code})</td>
                   </>
                   )
                 : (
                   <>
                     <td>-</td>
                     <td>{name} ({code})</td>
                   </>
                   ))}
                </>
                )
              : data?.includes(code) && (
                <td>{name} ({code})</td>
              )
          : (
            <>
              <td>{name} ({code})</td>
              <td>
                <input
                  type='checkbox'
                  name={code}
                  value={code}
                  disabled={details}
                  checked={data?.includes(code)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      data?.length
                        ? setData([...data, code])
                        : setData([code])
                    } else {
                      setData(data?.filter(c => c !== code))
                    }
                  }}
                />
              </td>

              {cashier &&
                <td>
                  <input
                    type='checkbox'
                    name={code}
                    value={code}
                    disabled={details}
                    checked={kycCountries?.includes(code)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        kycCountries?.length
                          ? setKycCountries([...kycCountries, code])
                          : setKycCountries([code])
                      } else {
                        setKycCountries(kycCountries?.filter(c => c !== code))
                      }
                    }}
                  />
                </td>}
            </>)}
      </tr>
    )
  }

  return (
    <>
      {!details &&
        <div className='d-flex justify-content-end align-items-center mt-5 mb-3'>
          <Form.Control
            type='text'
            placeholder='Search Country Name'
            size='sm'
            style={{ maxWidth: '230px' }}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value.replace(/[^\w\s\n]/gi, ''))
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        </div>}
      <div style={{ overflowY: 'auto', maxHeight: '700px' }}>

        {loading
          ? <InlineLoader />
          : (
            <Table
              bordered
              striped
              hover
              size='sm'
              className='text-center mt-3 fixTableHead'
            >
              <thead className='thead-dark'>
                <tr>
                  <th>{details && 'Blocked'} Country</th>
                  {!details &&
                    <th>
                      <input
                        type='checkbox'
                        name='selectAll'
                        hidden={details}
                        checked={myCountries.every(v => data?.includes(v.code))}
                        onChange={(e) => {
                          const newData = []
                          if (e.target.checked) {
                            for (const index in myCountries) {
                              newData.push(myCountries?.[index]?.code)
                            }
                            setData(newData)
                          } else {
                            setData([])
                          }
                        }}
                      />&nbsp;
                      Block
                    </th>}

                  {cashier &&
                    <th>
                      <input
                        type='checkbox'
                        name='selectAll'
                        hidden={details}
                        checked={myCountries.every(v => kycCountries?.includes(v.code))}
                        onChange={(e) => {
                          const newData = []
                          if (e.target.checked) {
                            for (const index in myCountries) {
                              newData.push(myCountries?.[index]?.code)
                            }
                            setKycCountries(newData)
                          } else {
                            setKycCountries([])
                          }
                        }}
                      />&nbsp;
                      KYC Required
                    </th>}
                </tr>
              </thead>
              <tbody>
                {!loading && myCountries?.map(({ name, code }) => {
                  return (
                    renderCountry(code, name)
                  )
                })}
                {!loading && (myCountries?.length === 0 || (details && !data?.length && !kycCountries?.length)) && (
                  <tr>
                    <td colSpan={2} className='text-danger text-center'>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>)}
      </div>
    </>
  )
}

export default CountryForm
