export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'affiliate', value: 'affiliate' },
  { label: 'agent', value: 'agent' }
]
export const notificationCategoryOptions = [
  { label: 'Emergency', value: 0 },
  { label: 'Maintenance', value: 1 }
]
