import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, InputGroup, Row, Form as BForm, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import BgImage from '../../../../assets/img/illustrations/signin.svg'
import { sendEmailSchema } from '../schema'
import React from 'react'

const TakeUserMail = ({ sendMailLoading, handleSendMail }) => {
  return (
    <>
      <main>
        <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
          <Container>
            <Row
              className='justify-content-center form-bg-image'
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <div className='text-center text-md-center mb-4 mt-md-0'>
                    <h3 className='mb-2'>Forgot Password</h3>
                    <p>No worries, we’ll send you reset instructions!</p>
                  </div>

                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={sendEmailSchema}
                    onSubmit={({ email }) =>
                      handleSendMail({ email })}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <div>
                        <Form>
                          <div className='form-group'>
                            <label
                              htmlFor='email'
                              className={
                            touched.email && errors.email ? 'text-danger' : ''
                          }
                            >
                              Email
                            </label>

                            <InputGroup
                              className={
                            touched.email && errors.email
                              ? 'border border-danger'
                              : ''
                          }
                            >
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope} />
                              </InputGroup.Text>

                              <BForm.Control
                                name='email'
                                autoFocus
                                required
                                type='email'
                                placeholder='example@company.com'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputGroup>

                            <ErrorMessage
                              component='div'
                              name='email'
                              className='text-danger'
                            />
                          </div>

                          <button
                            type='submit'
                            className='btn btn-primary btn-block mt-4'
                            onClick={handleSubmit}
                          >
                            {sendMailLoading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                            Reset Password
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default TakeUserMail
