import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllTenantUsersStart,
  updateTenantUserNoteStart,
  updateTenantUserStatusStart
} from '../../../../store/redux-slices/tenantUsers'
import { useNavigate } from 'react-router-dom'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { getTenantCountriesStart, getTotalAmountUserStart } from '../../../../store/redux-slices/fetchData'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getAffiliateCountryStart } from '../../../../store/redux-slices/affiliate'

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useTenantUserListing = ({ affiliateId, isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const forEntity = isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'tenant'
  const tableHeaders = [
    { label: 'sNo', value: '' },
    { label: 'userName', value: 'username' },
    { label: 'createdAt', value: 'regDate' },
    { label: 'amount', value: 'amount' },
    { label: 'withdrawable', value: 'withdrawableAmount' },
    { label: 'nonWithdrawable', value: 'nonWithdrawableAmount' },
    { label: 'nonCashAmount', value: 'nonCashAmount' },
    { label: 'promotionCash', value: 'promotionCash' },
    { label: 'bonusAmount', value: 'bonusAmount' },
    { label: 'payoutAmount', value: 'payoutAmount' },
    { label: 'seniorNotes', value: 'seniorNotes' },
    { label: 'vaultAmount', value: 'vaultAmount' },
    { label: 'withdrwalLimit', value: 'withdrwalLimit' },
    { label: 'country', value: 'country' },
    { label: 'currentIPAddress', value: 'currentIPAddress' },
    { label: 'PrevIpAddress', value: 'PrevIpAddress' },
    { label: 'blacklistStatus', value: 'status' },
    { label: 'redflagstatus', value: 'status' },
    { label: 'kycStatus', value: 'kycStatus' },
    { label: 'kycStatusSenior', value: 'kycStatusSenior' },
    { label: 'action', value: 'action' }
  ]
  const startDate = new Date()

  startDate.setDate(startDate.getDate() - 20)
  const [isAnonymous, setIsAnonymous] = useState(true)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [affiliateSearch, setAffiliateSearch] = useState('')
  const [search, setSearch] = useState('')
  const [activeFilter, setActiveFilter] = useState('')
  const [flagFilter, setRedFlagFilter] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()
  const [kycOptions, setKycOptions] = useState('')
  const [kycSeniorOptions, setKycSeniorOptions] = useState('')
  const [name, setName] = useState()
  const [userId, setUserId] = useState('')
  const [orderBy, setOrderBy] = useState('userId')
  const [sortBy, setSortBy] = useState('desc')
  const [over, setOver] = useState(false)
  const [isInternal, setIsInternal] = useState(false)
  const [regPlayersType, setRegPlayersType] = useState('all')
  const [regPlayerDate, setRegPlayerDate] = useState(initialDate)
  const [state, setState] = useState(initialDate)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [individualUserData, setIndividualUserData] = useState(null)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [regState, setRegState] = useState(initialDate)

  const handleShow = (id, active, myName) => {
    setSelectedUserId(id)
    setStatus(!active)
    setShowModal(true)
    setName(myName)
  }
  const { totalPlayerAmount } = useSelector((state) => state.fetch)
  const selected = (h) =>
    orderBy === h.value &&
  (h.value === 'email' || h.value === 'userId' || h.value === 'username')

  const handleClose = () => setShowModal(false)

  const dispatch = useDispatch()
  const { allUsers, loading } = useSelector((state) => state.tenantUsers)
  const { adminPermissions } = useSelector((state) => state.admins)
  const { tenantCountries } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(allUsers?.count / limit)
  const { countries: affiliateCountries } = useSelector((state) => state.affiliate)
  useEffect(() => {
    if (isAffiliate || isAgent) {
      dispatch(getAffiliateCountryStart({ isAgent, isAffiliate }))
    } else {
      dispatch(getTenantCountriesStart())
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchTenantUser()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, affiliateSearch, userId])

  const fetchTenantUser = () => {
    dispatch(
      getAllTenantUsersStart({
        limit,
        pageNo: page,
        search: search.replace('+', '%2B'),
        isActive: activeFilter,
        redFlag: flagFilter,
        affiliateId: affiliate,
        kycStatus: kycOptions,
        kycSeniorStatus: kycSeniorOptions,
        affiliateSearch,
        dobStart: moment(state[0].startDate).toISOString() || '',
        dobEnd: moment(state[0].endDate).toISOString() || '',
        myUserId: userId,
        orderBy,
        sortBy,
        registrationDateStart: moment(regState[0].startDate).toISOString() || '',
        registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
        isInternal,
        countryCode: selectedCountry,
        ftdTo: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.endDate)),
        ftdFrom: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.startDate)),
        usersType: regPlayersType,
        isAffiliate,
        isAgent,
        agentId: agent,
        isAnonymous
      })
    )
    dispatch((getTotalAmountUserStart({
      forEntity: forEntity,
      search: search.replace('+', '%2B'),
      isActive: activeFilter,
      redFlag: flagFilter,
      affiliateId: affiliate,
      kycStatus: kycOptions,
      kycSeniorStatus: kycSeniorOptions,
      affiliateSearch,
      registrationDateStart: moment(regState[0].startDate).toISOString() || '',
      registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
      countryCode: selectedCountry,
      isAffiliate,
      isAgent,
      agentId: agent
    })))
  }
  useEffect(() => {
    fetchTenantUser()
  }, [limit, isAnonymous, kycSeniorOptions, flagFilter, activeFilter, kycOptions, state, orderBy, sortBy, isInternal, regState, selectedCountry, regPlayerDate, regPlayersType, page, agent, affiliate])

  const updateTenantUserStatus = (data) =>

    dispatch(updateTenantUserStatusStart({
      ...data,
      limit,
      pageNo: page,
      search: search.replace('+', '%2B'),
      isActive: activeFilter,
      userId: selectedUserId,
      status,
      affiliateId,
      code: 'USER',
      isPlayersPage: true,
      kycStatus: kycOptions,
      affiliateSearch,
      dobStart: formatDateYMD(state.map(a => a.startDate)),
      dobEnd: formatDateYMD(state.map(a => a.endDate)),
      myUserId: userId,
      orderBy,
      sortBy,
      registrationDateStart: moment(regState[0].startDate).toISOString() || '',
      registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
      isInternal,
      countryCode: selectedCountry
    }))

  const handleNote = (data) => {
    dispatch(updateTenantUserNoteStart({
      userId: individualUserData.userId,
      note: data.description,
      isTenant: true,
      fetchTenantUser
    }))
  }

  return {
    totalPlayerAmount: totalPlayerAmount?.[0] ?? totalPlayerAmount,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    navigate,
    limit,
    page,
    search,
    activeFilter,
    showModal,
    status,
    selectedUserId,
    affiliateSearch,
    setAffiliateSearch,
    handleClose,
    setLimit,
    setPage,
    setSearch,
    setActiveFilter,
    setShowModal,
    handleShow,
    allUsers,
    totalPages,
    updateTenantUserStatus,
    kycOptions,
    setKycOptions,
    loading,
    name,
    state,
    setState,
    userId,
    setUserId,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy,
    over,
    setOver,
    selected,
    adminPermissions,
    regState,
    setRegState,
    setIsInternal,
    isInternal,
    countries: isAffiliate ? affiliateCountries : tenantCountries?.rows,
    selectedCountry,
    setSelectedCountry,
    regPlayerDate,
    setRegPlayerDate,
    regPlayersType,
    setRegPlayersType,
    tableHeaders,
    flagFilter,
    setRedFlagFilter,
    initialDate,
    t,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleNote,
    kycSeniorOptions,
    setKycSeniorOptions,
    isAnonymous,
    setIsAnonymous
  }
}

export default useTenantUserListing
