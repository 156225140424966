import * as Yup from 'yup'

export const editGamesSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, t('nameMaxLength'))
      .required(t('gameNameRequired')),
    description: Yup.string()
      .max(1000, t('descriptionMaxLength'))
      .nullable(),
    language: Yup
      .string()
      .required('languageRequired')
  })
}
