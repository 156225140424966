import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import moment from 'moment'
import { tableHeaders, section, action } from './constants'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import ClientFilter from '../../../components/ClientFilter'
import useUserHistory from './hooks/useUserHistory'
import { ButtonGroup } from 'react-bootstrap'
import ConfirmationModal, { ResetUserPasswordModal } from '../../../components/ConfirmationModal'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'

const UserHistory = () => {
  const {
    formData,
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    userHistory,
    inputRef,
    state,
    setState,
    initialDate,
    handleCsvDownload,
    loading,
    t,
    handleShowModal,
    handleUpdatePassword,
    passwordForm,
    setPasswordForm,
    actionShow,
    setShowAction,
    handleUpdatePasswordReject,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = useUserHistory()

  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('playerHistory')}</h3>
          </Col>
        </Col>
      </Row>
      <div>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px'
              }}
            >
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              autoComplete='off'
              name='search'
              type='search'
              placeholder={t('searchPlaceholder')}
              size='sm'
              style={{
                marginRight: '15px',
                width: '260px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div xs='auto' className='d-flex m-2'>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={state[0].startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex m-2 '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={state[0].endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('section')}
            </Form.Label>
            <Form.Select
              value={formData?.section}
              onChange={(e) => {
                setFormData({ ...formData, section: e.target.value })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'

            >
              <option value=''>{t('all')}</option>

              {section?.map((requestAction) =>
                (
                  <option
                    key={requestAction.value}
                    value={requestAction.value}
                  >
                    {t(requestAction.label)}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('actionType')}
            </Form.Label>
            <Form.Select
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
              value={formData?.action}
              onChange={(e) => {
                setFormData({ ...formData, action: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>

              {action?.map((action) =>
                (
                  <option
                    key={action.value}
                    value={action.value}
                  >
                    {t(action.label)}
                  </option>
                )
              )}
            </Form.Select>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
            <AffiliateAgentFilter
              selectedClient={selectedClient}
              agent={agent}
              setAgent={setAgent}
              affiliate={affiliate}
              setAffiliate={setAffiliate}
            />

            <div className='d-flex px-3 float-end'>
              <Trigger message={t('downloadCsv')}>
                <Button
                  variant='outline-success'
                  className='m-1'
                  size='sm'
                  disabled={userHistory?.count === 0}
                  onClick={() => {
                    handleCsvDownload()
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  className='m-1'
                  onClick={() => {
                    setState(initialDate)
                    setSelectedClient('')
                    setFormData(initalWalletState)
                    inputRef.current.value = ''
                    setAgent('')
                    setAffiliate('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>

        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((heading, idx) => (
              <th key={heading.label}>
                {t(heading.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && userHistory &&
            userHistory?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index} className='row-height'>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {item?.instigatorName || 'NA'}
                      </span>
                    </td>
                    <td>
                      {item?.undergoerName || 'NA'}
                    </td>
                    <td>
                      {item?.section || 'NA'}
                    </td>
                    <td>
                      {item?.action || 'NA'}
                    </td>

                    <td>
                      {item?.reason
                        ? (
                          <Trigger message={item.reason} placement='left'>
                            <span>{item.reason}</span>
                          </Trigger>
                          )
                        : (
                            '-'
                          )}
                    </td>

                    <td className='cell expand-maximum-on-hover'>
                      {item?.description
                        ? (
                          <Trigger message={item.description.toLowerCase()} placement='left'>
                            <span>{item.description.toLowerCase()}</span>
                          </Trigger>
                          )
                        : (
                            '-'
                          )}
                    </td>

                    <td>
                      {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || 'NA'}
                    </td>
                    <td>
                      {item.isActionRequired && !item.isActionPerformed
                        ? (
                          <ButtonGroup>
                            <Trigger message={t('acceptRequest')}>
                              <Button
                                className='m-1 fw-bolder'
                                size='sm'
                                variant='success'
                                onClick={() => {
                                  setPasswordForm(true)
                                  handleShowModal(
                                    item?.logId,
                                    'ACCEPT',
                                    item?.undergoer
                                  )
                                }}
                              >
                                {t('accept')}
                              </Button>
                            </Trigger>
                            <Trigger message={t('rejectRequest')}>
                              <Button
                                className='m-1 fw-bolder'
                                size='sm'
                                variant='danger'
                                onClick={() => {
                                  handleShowModal(
                                    item?.logId,
                                    'REJECT',
                                    item?.undergoer
                                  )
                                  setShowAction(true)
                                }}
                              >
                                {t('reject')}
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                          )
                        : (
                            item.action === 'PASSWORD-RESET-ACCEPT'
                              ? <span className='fw-bolder text-success'>{t('accepted')}</span>
                              : item.action === 'PASSWORD-RESET-REJECT'
                                ? <span className='fw-bolder text-danger'>{t('rejected')}</span>
                                : <span className='fw-bolder text-success'>{t('accepted')}</span>
                          )}
                    </td>
                  </tr>
                )
              }
            )
          }
          {!loading && userHistory.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {
        !loading && userHistory.count !== 0 &&
        (
          <PaginationComponent
            page={userHistory.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={userHistory.count}
          />
        )
      }
      {
        passwordForm
          ? (
            <ResetUserPasswordModal
              show={passwordForm}
              setShow={setPasswordForm}
              handleResetUserPassword={handleUpdatePassword}
            />
            )
          : (<></>)
      }
      {
        actionShow &&
          <ConfirmationModal
            setShow={setShowAction}
            show={actionShow}
            handleYes={handleUpdatePasswordReject}
            active
            isHistory
          />
      }
    </div>
  )
}

export default UserHistory
