import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { debounce } from 'lodash'
import { getTenantAllUserHistoryStart, updateTenantUserPasswordStart } from '../../../../store/redux-slices/tenants'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const initalWalletState = {
  section: '',
  search: '',
  action: '',
  senior: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]

const valuesData = {
  logId: null,
  action: '',
  undergoerId: null
}
const useTenantUserHistory = ({ isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [dataForm, setDataForm] = useState(valuesData)
  const [limit, setLimit] = useState(15)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState(initalWalletState)
  const { userHistoryTenant } = useSelector(state => state.tenants)
  const totalPages = Math.ceil(userHistoryTenant?.count / limit)
  const [state, setState] = useState(initialDate)
  const inputRef = useRef()
  const [passwordForm, setPasswordForm] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const handleShowModal = (logId, action, undergoerId) => {
    setDataForm({
      ...dataForm,
      logId,
      action,
      undergoerId
    })
  }
  useEffect(() => {
    dispatch(getTenantAllUserHistoryStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || '',
        agentId: agent,
        affiliateId: affiliate
      },
      isAffiliate,
      isAgent
    }))
  }, [formData, limit, page, state, refetch, agent, affiliate])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }
  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isAgent ? 'agent/report' : isAffiliate ? 'affiliate/report' : 'tenant/admin'}/logs?action=${formData.action || ''}&section=${formData.section || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}&agentId=${agent || ''}&affiliateId=${affiliate || ''}`)
  }

  const handleUpdatePassword = (data) => {
    dispatch(updateTenantUserPasswordStart({
      dataForm: { ...data, ...dataForm },
      isTenant: true,
      isAffiliate,
      isAgent,
      handleClose: () => {
        setPasswordForm(false)
        setFormData(initalWalletState)
        setRefetch(prev => !prev)
      }
    }))
  }
  const [actionShow, setShowAction] = useState(false)
  const handleUpdatePasswordReject = (data = {}) => {
    dispatch(updateTenantUserPasswordStart({
      dataForm: { ...dataForm },
      isTenant: true,
      isAffiliate,
      isAgent,
      handleClose: () => {
        setPasswordForm(false)
        setFormData(initalWalletState)
        setRefetch(prev => !prev)
      }
    }))
  }
  return {
    formData,
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    userHistoryTenant,
    handleCsvDownload,
    inputRef,
    state,
    setState,
    initialDate,
    t,
    handleShowModal,
    handleUpdatePassword,
    setShowAction,
    actionShow,
    handleUpdatePasswordReject,
    passwordForm,
    setPasswordForm,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useTenantUserHistory
