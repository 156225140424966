export const tableHeaders = [
  { label: 'sno', value: '' },
  { label: 'username', value: 'username' },
  { label: 'amount', value: 'amount' },
  { label: 'nonCashAmount', value: 'nonCashAmount' },
  { label: 'hotTimeBonus', value: 'hotTimeBonus' },
  { label: 'compensationBonus', value: 'compensationBonus' },
  { label: 'paybackBonus', value: 'paybackBonus' },
  { label: 'createdAt', value: 'createdAt' }
]
