import React from 'react'
import { useTranslation } from 'react-i18next'

const Tenants = ({ tenantData }) => {
  const { t } = useTranslation()
  return (
    <>

      <div className='d-flex flex-column gap-3 pb-5'>

        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('name')}</strong>
          <span>{tenantData?.name}</span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('domain')}</strong>
          <span>{tenantData?.domain}</span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('primaryCurrency')}</strong>
          <span>{tenantData?.primaryCurrency}</span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('allowedCurrencies')}</strong>
          <span> {tenantData?.tenantConfig.allowedCurrencies.map(
            (currency) => `${currency} `
          ).join(', ')}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('allowedLanguages')}</strong>
          <span>{tenantData?.tenantConfig.allowedLanguages.map(
            (languages) => `${languages} `
          ).join(', ')}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('allowedProviders')}</strong>
          <span>{tenantData?.tenantConfig.gameProviders
            .map(
              (providerId) => `${providerId?.name} `
            ).join(', ')}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('withdrawlLimitCurrency')}</strong>
          <span>{tenantData?.tenantConfig?.withdrawlLimitCurrency}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('withdrawalLimit')}</strong>
          <span>{tenantData?.tenantConfig?.withdrawlLimit}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('withdrawalLimitTime')}</strong>
          <span>{tenantData?.tenantConfig?.withdrawlLimitTime} {tenantData?.tenantConfig?.withdrawlLimitTimeUnit}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('ipLimit')}</strong>
          <span>{tenantData?.tenantConfig?.ipLimit}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('incorrectPasswordLimit')}</strong>
          <span>{tenantData?.tenantConfig?.incorrectPasswordLimit}
          </span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          <strong style={{ minWidth: 220 }}>{t('revenuePercentage')}</strong>
          <span>
            {tenantData?.tenantConfig?.revenuePercentage}%
          </span>
        </div>
      </div>

    </>
  )
}
export default Tenants
