import React from 'react'
import {
  Tabs,
  Tab,
  Row,
  Col,
  Breadcrumb
} from '@themesberg/react-bootstrap'
import { useLocation } from 'react-router-dom'
import Overview from './components/Overview'
import useUserDetails from './useUserDetails'
import Preloader from '../../../components/Preloader'
import CasinoTransactions from './components/CasinoTransactions'
import useCheckPermission from '../../../utils/checkPermission'
import DisableReason from './components/DisableReason'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Settings from './components/Settings'
import AccountTransaction from './components/AccountTransaction'
import BonusTransactions from './components/BonusTransactions'
import { useTranslation } from 'react-i18next'
import InspectionDetails from './components/InspectionDetails'
import WithdrawalTableData from './components/WithdrawalTableData'
import SportsTransactions from '../../../components/SportsTransactions'

const UserDetails = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { securityFlag } = location.state || {}

  const {
    selectedTab,
    userData,
    loading,
    basicInfo,
    setShowRemoveAffiliate,
    updateUserAffiliateLoading,
    addUserAffiliateLoading,
    setShowAddAffiliate,
    setShowModal,
    setSelectedTab,
    setShowManageMoneyModal,
    setFreeSpinModal,
    setIsInternalModal,
    navigate,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    updateUserStatus,
    setVerifyEmailModal,
    setPasswordEmailModal,
    setUserPasswordModal,
    setEditUserModal,
    addTag,
    getDuplicateUsers,
    duplicateUsers,
    userCurrentStatus,
    userStatusLoading
  } = useUserDetails(securityFlag)
  const isV2User = userData?.isV2User
  const { isHidden } = useCheckPermission()
  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div className='p-3'>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => securityFlag ? navigate('/super-admin/security-alerts') : navigate('/super-admin/users')}>{securityFlag ? t('securityAlerts') : t('players')}</Breadcrumb.Item>
                <Breadcrumb.Item active> {t('username')}: &nbsp;
                  {userData && userData?.username}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row className='px-3'>
              <Col className='d-flex'>
                <h3>{t('userID')}:&nbsp;</h3>
                <h3>
                  <div className='d-flex'>
                    {userData && userData?.userId}
                  </div>
                </h3>
              </Col>
            </Row>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light px-3'
              mountOnEnter
              unmountOnExit
            >
              <Tab eventKey='overview' title={t('overview')}>
                <Row className='mt-5 px-3'>
                  <Overview
                    basicInfo={basicInfo}
                    userLimits={userData?.userLimit}
                    user={userData}
                    setActiveInactiveModal={setActiveInactiveModal}
                    setIsInternalModal={setIsInternalModal}
                    setVerifyEmailModal={setVerifyEmailModal}
                    setShowModal={setShowModal}
                    setFreeSpinModal={setFreeSpinModal}
                    setShowManageMoneyModal={setShowManageMoneyModal}
                    setShowAddAffiliate={setShowAddAffiliate}
                    addUserAffiliateLoading={addUserAffiliateLoading}
                    setShowRemoveAffiliate={setShowRemoveAffiliate}
                    updateUserAffiliateLoading={updateUserAffiliateLoading}
                    isHidden={isHidden}
                    setPasswordEmail={setPasswordEmailModal}
                    setUserPassword={setUserPasswordModal}
                    setEditModal={setEditUserModal}
                    addTag={addTag}
                    getDuplicateUsers={getDuplicateUsers}
                    duplicateUsers={duplicateUsers}
                    userCurrentStatus={userCurrentStatus}
                    loading={userStatusLoading}
                    securityFlag={securityFlag}
                  />
                </Row>
              </Tab>
              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) &&
                <Tab eventKey='casinoTransactions' title={t('casinoBetHistory')}>
                  <CasinoTransactions />
                </Tab>}
              <Tab eventKey='settings' title={t('kycSettings')}>
                <div className='mt-5'>
                  <Row className='mt-3 d-flex flex-row-reverse '>
                    {userData &&
                      <Settings userDetails={userData} />}
                  </Row>
                </div>
              </Tab>
              {
              userData?.isActive
                ? (
                  <Tab eventKey='deposit' title={t('deposit')}>
                    <div className='mt-5'>
                      <Row className='mt-3 d-flex flex-row-reverse '>
                        <AccountTransaction isDeposit buttonTitle={t('deposit')} userData={userData} />
                      </Row>
                    </div>
                  </Tab>
                  )
                : <></>
              }
              {
               userData?.isActive
                 ? (
                   <Tab eventKey='withdrawal' title={t('withdrawal')}>
                     <div className='mt-5'>
                       {isV2User
                         ? <WithdrawalTableData userData={userData} />
                         : (
                           <Row className='mt-3 d-flex flex-row-reverse '>
                             <AccountTransaction buttonTitle={t('withdrawal')} userData={userData} isWithdraw />
                           </Row>
                           )}
                     </div>
                   </Tab>
                   )
                 : <></>
              }
              <Tab eventKey='casinoBonusTransactions' title={t('casinoBonusTransactions')}>
                <div className=''>
                  <BonusTransactions userData={userData} />
                </div>
              </Tab>
              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) &&
                <Tab eventKey='betBySportsBetHistory' title={t('betBySportsBetHistory')}>
                  <div className='pt-3'>
                    <SportsTransactions isBetBy userData={userData} />
                  </div>
                </Tab>}
              <Tab eventKey='sportsBonusTransactions' title={t('sportsBonusTransactions')}>
                <div className=''>
                  <BonusTransactions isSportsBook userData={userData} />
                </div>
              </Tab>
              {
            userData?.isUnderInspection
              ? (
                <Tab eventKey='inspection' title={t('inspection')}>
                  <div className=''>
                    <InspectionDetails userData={userData} />
                  </div>
                </Tab>
                )
              : (
                <></>
                )
             }
            </Tabs>
          </div>
          )}
      {userData?.isActive
        ? <DisableReason
            show={
               isActiveInactivemodalVisible
            }
            handleClose={() => setActiveInactiveModal(false)}
            markUserStatusInactive={updateUserStatus}
            name={`${userData?.legalName}(${userData?.username || '-'})`}
          />
        : <ConfirmationModal
            show={isActiveInactivemodalVisible}
            setShow={setActiveInactiveModal}
            handleYes={() => {
              updateUserStatus()
              setActiveInactiveModal(false)
            }}
            active={userData?.isActive}
            name={`${userData?.legalName}(${userData?.username || '-'})`}
          />}
    </>
  )
}

export default UserDetails
