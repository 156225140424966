import React from 'react'
import ReactApexChart from 'react-apexcharts'
import PropTypes from 'prop-types'

const BarGraph = ({ seriesData = [], categories = [] }) => {
  const data = {
    series: [{
      name: 'Count',
      data: seriesData || []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 450,
        foreColor: '#333'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          vertical: true
        }
      },
      xaxis: {
        categories: categories || [],
        labels: {
          style: {
            fontSize: '14px'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px'
          }
        }
      },
      colors: ['#007bff']
    }
  }

  return (
    <ReactApexChart options={data?.options} series={data?.series} type='bar' height={450} />
  )
}

BarGraph.propTypes = {
  confirm: PropTypes.array,
  cancel: PropTypes.array,
  date: PropTypes.array
}

export default BarGraph
