import React from 'react'
import { Row, Col, Card, Tabs, Tab } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import LivePlayers from './components/LivePlayers'
import ProviderStats from './components/ProviderStats'
import { getTextColor } from '../../../utils/dashboardEffects'
import Trigger from '../../../components/OverlayTrigger'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAreaChart, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import WalletStats from './components/WalletStats'
import useDashboard from './hooks/useDashboard'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import SportsDashBoardTransactions from '../../../components/SportsDashBoardTransactions'
import RevenueDetails from './components/RevenueDetails'
import { formatNumber } from '../../../utils/storageUtils'
export default () => {
  const {
    selectedClient,
    setSelectedClient,
    pieChartValuesSeries,
    pieChartValuesLabels,
    pieChartValuesWalletSeries,
    pieChartValuesWalletLabels,
    livePlayerData,
    providerDataStat,
    tableHeaders,
    selectedProvider,
    setSelectedProvider,
    selectedCategory,
    setSelectedCategory,
    gameCategory,
    userLogginbyCountry,
    countryCodes,
    loggedInCounts,
    tableHeadersUserCountryCount,
    state,
    setState,
    initialDate,
    t,
    transactionType,
    setTransactionType,
    selectedTab,
    setSelectedTab,
    result,
    tableHeaderSeniorUser,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    dashboardSBTransactions,
    sbTransactionLoading,
    setSBTransactionDate,
    sbTransactionDate
  } = useDashboard()

  return (
    <>
      <Card className='p-3 mb-2'>
        <Row>
          <Col xs='auto' className='mb-3'>
            <h3><FontAwesomeIcon icon={faAreaChart} />&nbsp;{t('dashboard')}</h3>
            <div className='d-flex align-items-center gap-2'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
              />
              <AffiliateAgentFilter
                selectedClient={selectedClient}
                agent={agent}
                setAgent={setAgent}
                affiliate={affiliate}
                setAffiliate={setAffiliate}
              />
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  onClick={() => {
                    setSelectedClient('')
                    setAffiliate('')
                    setAgent('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
        <Row className='gap-3'>
          <Col className='p-0'>
            <Card
              title={t('onlinePlayer')} className='p-3'
              style={{ borderRadius: 8, minWidth: 150 }}
            >
              <p style={{ fontWeight: 700 }} className={`small  ${getTextColor(livePlayerData?.[0]?.logged_in_count)}`}> {formatNumber(livePlayerData?.[0]?.logged_in_count || 0)}</p>
              <span className='fw-bold text-dark'>{t('onlinePlayer')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card
              title={t('totalPlayer')} className='p-3'
              style={{ borderRadius: 8, minWidth: 150 }}
            >
              <p style={{ fontWeight: 700 }} className={`small fw-bold ${getTextColor(livePlayerData?.[0]?.totalUserCount)}`}>{formatNumber(livePlayerData?.[0]?.totalUserCount || 0)}</p>
              <span className='fw-bold text-dark'>{t('totalPlayer')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card
              title={t('blackListPlayer')} className='p-3'
              style={{ borderRadius: 8, minWidth: 150 }}
            >
              <p style={{ fontWeight: 700 }} className={`small fw-bold ${getTextColor(livePlayerData?.[0]?.blacklist_count)}`}>{formatNumber(livePlayerData?.[0]?.blacklist_count || 0)}</p>
              <span className='fw-bold text-dark'>{t('blackListPlayer')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card
              title={t('totalNgr')} className='p-3'
              style={{ borderRadius: 8, minWidth: 150 }}
            >
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(providerDataStat?.totalGgrAmount)}`}> {providerDataStat?.totalGgrAmount !== undefined ? formatNumber(providerDataStat?.totalGgrAmount) : 0}</p>
              <span className='fw-bold text-dark'>{t('totalGgr')}</span>
            </Card>
          </Col>
          <Col className='p-0'>
            <Card
              title={t('totalNgr')} className='p-3'
              style={{ borderRadius: 8, minWidth: 150 }}
            >
              <p style={{ fontWeight: 700 }} className={`small ${getTextColor(providerDataStat?.totalNgrAmount)}`}> {providerDataStat?.totalNgrAmount !== undefined ? formatNumber(providerDataStat?.totalNgrAmount) : 0}</p>
              <span className='fw-bold text-dark'>{t('totalNgr')}</span>
            </Card>
          </Col>
        </Row>
      </Card>

      <LivePlayers
        pieChartValuesSeries={pieChartValuesSeries}
        pieChartValuesLabels={pieChartValuesLabels}
        countryCodes={countryCodes}
        loggedInCounts={loggedInCounts}
        userLogginbyCountry={userLogginbyCountry}
        tableHeadersUserCountryCount={tableHeadersUserCountryCount}
        t={t}
      />
      <Card className='p-2 mb-2'>
        <WalletStats
          pieChartValuesWalletSeries={pieChartValuesWalletSeries}
          pieChartValuesWalletLabels={pieChartValuesWalletLabels}
          userLogginbyCountry={userLogginbyCountry}
          tableHeadersUserCountryCount={tableHeadersUserCountryCount}
          t={t}
        />
      </Card>
      <Card className='p-2 mb-2'>
        <RevenueDetails
          agent={agent}
          affiliate={affiliate}
          selectedClient={selectedClient}
        />
      </Card>
      <Card className='p-2 mb-2'>
        <Tabs
          activeKey={selectedTab}
          onSelect={tab => {
            setSelectedTab(tab)
          }}
          mountOnEnter
          unmountOnExit
          className='nav-light'
        >
          <Tab eventKey='transaction' title={t('casinoTransaction')}>
            <ProviderStats
              providerDataStat={providerDataStat}
              tableHeaders={tableHeaders}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setTransactionType={setTransactionType}
              transactionType={transactionType}
              gameCategory={gameCategory}
              state={state}
              setState={setState}
              initialDate={initialDate}
              t={t}
            />
          </Tab>

          <Tab eventKey='seniorTransactionStats' title={t('reportTransaction')}>
            <ProviderStats
              isUserSenior
              result={result}
              tableHeaderSeniorUser={tableHeaderSeniorUser}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setTransactionType={setTransactionType}
              transactionType={transactionType}
              gameCategory={gameCategory}
              state={state}
              setState={setState}
              initialDate={initialDate}
              t={t}
            />
          </Tab>

        </Tabs>
      </Card>
      <Card className='p-3 mb-2'>
        <SportsDashBoardTransactions
          dashboardSBTransactions={dashboardSBTransactions}
          sbTransactionLoading={sbTransactionLoading}
          date={sbTransactionDate}
          setDate={setSBTransactionDate}
          t={t}
        />
      </Card>

    </>
  )
}
