import { createSlice } from '@reduxjs/toolkit'

const languagesSlice = createSlice({
  name: 'languages',
  initialState: {
    loading: false,
    languageKeys: null,
    languages: null,
    languageTabs: null,
    tenantLanguages: null,
    languageMessage: null
  },
  reducers: {
    languageStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    languageStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languageMessage: payload
    }),
    languageStatusFaliure: (state, { payload }) => ({
      ...state,
      loading: false,
      languageMessage: payload
    }),
    getLanguageSupportKeysStart: (state) => ({
      ...state,
      loading: true,
      languageKeys: null
    }),
    getLanguageSupportKeysSuccess: (state, { payload }) =>
      ({
        ...state,
        loading: false,
        languageKeys: payload.languageData || payload,
        languageTabs: payload.languageKeys || { CODES: payload?.map(keys => keys.key) }
      }),
    getLanguageSupportKeysFailure: (state) => ({
      ...state,
      loading: false
    }),
    uploadLanguageCSVStart: (state) => ({
      ...state,
      loading: true
    }),
    uploadLanguageCSVComplete: (state) => ({
      ...state,
      loading: false
    }),
    getLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languages: payload
    }),
    getLanguagesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateLanguageSupportStart: (state) => ({
      ...state,
      loading: true
    }),
    updateLanguageSupportComplete: (state) => ({
      ...state,
      loading: false
    }),
    getCMSLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getCMSLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantLanguages: payload
    }),
    getCMSLanguagesFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default languagesSlice.reducer

export const {
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  uploadLanguageCSVStart,
  uploadLanguageCSVComplete,
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  updateLanguageSupportStart,
  updateLanguageSupportComplete,
  getCMSLanguagesStart,
  getCMSLanguagesSuccess,
  getCMSLanguagesFailure,
  languageStatusStart,
  languageStatusSuccess,
  languageStatusFaliure
} = languagesSlice.actions
