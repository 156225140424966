import * as Yup from 'yup'

export const createSuperAdminUserSchema = (t) => {
  Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmail'))
      .max(200)
      .required(t('emailRequired')),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('invalidPassword')
      )
      .max(50)
      .required(t('passwordRequired')),
    firstName: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('firstName'), minNumber: 3 }))
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, t('firstNameFormat'))
      .required(t('firstNameRequired')),
    lastName: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('lastName'), minNumber: 3 }))
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, t('lastNameFormat'))
      .required(t('lastNameRequired')),
    role: Yup.string().required(t('roleRequired')).nullable(),
    adminId: Yup.string().when('role', {
      is: (role) => role === 'Support',
      then: Yup.string().required(t('adminIdRequired')).nullable(),
      otherwise: Yup.string().nullable()
    }),
    superAdminUsername: Yup.string()
      .matches(/^[A-Za-z]+$/, t('superAdminUsernameFormat'))
      .min(8, t('mustBeAtLeastCharacters', { label: t('superAdminUsername'), minNumber: 8 }))
      .max(100)
      .required(t('usernameRequired')),
    group: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('groupName'), minNumber: 3 }))
      .max(200)
      .matches(/^[A-Za-z0-9 ]+$/, t('groupNameFormat'))
      .required(t('groupNameRequired'))
  })
}

export const updateSuperAdminUserSchema = (t) => {
  Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmail'))
      .max(200)
      .required(t('emailRequired')),
    firstName: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('firstName'), minNumber: 3 }))
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, t('firstNameFormat'))
      .required(t('firstNameRequired')),
    lastName: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('lastName'), minNumber: 3 }))
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, t('lastNameFormat'))
      .required(t('lastNameRequired')),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('invalidPassword')
      )
      .max(50),
    role: Yup.string().required(t('roleRequired')).nullable(),
    adminId: Yup.string().when('role', {
      is: (role) => role === 'Support',
      then: Yup.string().required(t('adminIdRequired')).nullable(),
      otherwise: Yup.string().nullable()
    }),
    superAdminUsername: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z0-9_]+$/, t('superAdminUsernameFormat'))
      .min(8, t('mustBeAtLeastCharacters', { label: t('superAdminUsername'), minNumber: 8 }))
      .max(100)
      .required(t('usernameRequired')),
    group: Yup.string()
      .min(3, t('mustBeAtLeastCharacters', { label: t('groupName'), minNumber: 3 }))
      .max(200)
      .matches(/^[A-Za-z0-9 ]+$/, t('groupNameFormat'))
      .required(t('groupNameRequired'))
  })
}
