import React from 'react'

import Preloader from '../../../../components/Preloader'
import useCmsDetails from '../hooks/useCmsDetails'
import CreateCms from './CreateCms'

const CmsDetails = () => {
  const {
    loading,
    cmsDetails
  } = useCmsDetails()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <CreateCms cmsDetails={cmsDetails} details />
          </>
          )}
    </>
  )
}

export default CmsDetails
