import React from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Table
} from 'react-bootstrap'
import {
  disableAction,
  sportsCountriesListingTableHeader
} from './constants'
import useSportsCountries from './hooks/useSportsCountries'
import PaginationComponent from '../Pagination'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft,
  faRedoAlt,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../ConfirmationModal'
import { useTranslation } from 'react-i18next'

const SportsCountriesListing =
 ({
   title,
   isTenant
 }) => {
   const {
     page,
     limit,
     setPage,
     setLimit,
     navigate,
     filterData,
     setFilterData,
     initialState,
     sportsCountriesListing,
     loading,
     totalPages,
     debounceFn,
     inputRef,
     isStatusModalOpen,
     setIsStatusModalOpen,
     isDisable,
     setIsDisable,
     setUpdateData,
     handleUpdateSportAction,
     updateData,
     sportsListing
   } = useSportsCountries(isTenant)
   const { t } = useTranslation()

   return (
     <div>
       <h3>
         <FontAwesomeIcon
           icon={faArrowCircleLeft}
           className='cursor-pointer px-2'
           onClick={() => navigate(-1)}
         />
         {t(title.toLowerCase())}
       </h3>
       <div className='p-3'>
         <div className='d-flex flex-wrap align-items-center'>
           <div className='d-flex align-items-center px-2'>
             <Form.Label
               column='sm'
               style={{ marginBottom: '0', marginRight: '15px' }}
             >
               {t('search')}
             </Form.Label>&nbsp;
             <Form.Control
               ref={inputRef}
               name='search'
               type='search'
               placeholder={t('searchCountries')}
               size='sm'
               style={{
                 minWidth: '200px',
                 maxWidth: '240px',
                 width: 'fit-content'
               }}
               onChange={(e) => {
                 debounceFn(e)
                 setPage(1)
               }}
             />
           </div>
           <div className='d-flex align-items-center'>
             <Form.Label
               column='sm' style={{
                 marginBottom: '0',
                 marginRight: '15px',
                 minWidth: 'fit-content'
               }}
             >
               {t('countryStatus')}
             </Form.Label>
             <Form.Select
               value={filterData?.status}
               onChange={(e) => {
                 setFilterData({
                   ...filterData,
                   status: e.target.value
                 })
                 setPage(1)
               }}
               style={{
                 marginBottom: '0',
                 marginRight: '15px',
                 maxWidth: '230px',
                 width: 'auto'
               }}
               size='sm'
             >
               <option value=''>{t('all')}</option>

               {disableAction?.map((actionRequest) =>
                 (
                   <option
                     key={actionRequest.value}
                     value={actionRequest.label}
                   >
                     {t(actionRequest?.value)}
                   </option>
                 )
               )}
             </Form.Select>
           </div>
           <div className='d-flex align-items-center'>
             <Form.Label
               column='sm' style={{
                 marginBottom: '0',
                 marginRight: '15px',
                 minWidth: 'fit-content'
               }}
             >
               {t('sports')}
             </Form.Label>
             <Form.Select
               value={filterData?.sportId}
               onChange={(e) => {
                 setFilterData({
                   ...filterData,
                   sportId: e.target.value
                 })
                 setPage(1)
               }}
               style={{
                 marginBottom: '0',
                 marginRight: '15px',
                 maxWidth: '230px',
                 width: 'auto'
               }}
               size='sm'
             >
               <option value=''>{t('all')}</option>

               {sportsListing?.rows?.map(({
                 masterSportId,
                 sportName
               }) =>
                 (
                   <option
                     key={masterSportId}
                     value={masterSportId}
                   >
                     {sportName}
                   </option>
                 )
               )}
             </Form.Select>
           </div>
           <Col className='d-flex p-3 float-end'>
             <Trigger message='Reset Filters'>
               <Button
                 variant='outline-success'
                 className='py-1'
                 size='sm'
                 onClick={() => {
                   setFilterData(initialState)
                   inputRef.current.value = ''
                   setPage(1)
                 }}
               >
                 <FontAwesomeIcon icon={faRedoAlt} />
               </Button>
             </Trigger>
           </Col>
         </div>
         <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
           <thead className='thead-dark'>
             <tr>
               {sportsCountriesListingTableHeader.map((h, idx) => (
                 <th key={h.label}>
                   {t(h.label)} &nbsp;
                 </th>
               ))}
             </tr>
           </thead>
           <tbody id={loading ? 'cover-spin' : ''}>
             {
              !loading && sportsCountriesListing &&
           sportsCountriesListing?.rows?.map((item, index) => {
             return (
               <tr key={index}>
                 <td> {(index + 1) + (limit * (page - 1))} </td>
                 <td>
                   {item?.countryName || '-'}
                 </td>
                 <td>
                   {item?.sportName || '-'}
                 </td>
                 <td>
                   {item?.providerCountryId || '-'}
                 </td>
                 <td className={item?.systemStatus ? 'table-success' : 'table-danger'}>
                   {item?.systemStatus ? t('enabled') : t('disabled') || ''}
                 </td>
                 <td>
                   <ButtonGroup>
                     <Trigger
                       message={
                        item?.systemStatus
                          ? t('setEnable')
                          : t('setDisable')
                      }
                     >
                       <Button
                         className='m-1'
                         size='sm'
                         variant={!item?.systemStatus ? 'success' : 'danger'}
                         onClick={() => {
                           setIsStatusModalOpen(true)
                           setIsDisable(item?.systemStatus)
                           setUpdateData({
                             entityType: 'country',
                             status: !item?.systemStatus,
                             entityId: Number(item?.masterCountryId)
                           })
                         }}
                       >
                         <FontAwesomeIcon icon={!item?.systemStatus ? faArrowCircleLeft : faWindowClose} />
                       </Button>
                     </Trigger>
                   </ButtonGroup>
                 </td>
               </tr>
             )
           }
           )
          }
             {!loading && sportsCountriesListing?.count === 0
               ? (
                 <tr>
                   <td
                     colSpan={8}
                     className='text-danger text-center'
                   >
                     No data found
                   </td>
                 </tr>
                 )
               : <></>}
           </tbody>
         </Table>
         {!loading && sportsCountriesListing.count !== 0 &&
            (
              <PaginationComponent
                page={sportsCountriesListing.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={sportsCountriesListing.count}
              />
            )}
       </div>
       {isStatusModalOpen &&
         <ConfirmationModal
           setShow={setIsStatusModalOpen}
           show={isStatusModalOpen}
           handleYes={() => handleUpdateSportAction(updateData)}
           active={isDisable}
           isEnable
         />}
     </div>
   )
 }
export default SportsCountriesListing
