import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import useReviewManagement from './useReviewManagement'
// import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    reviewList,
    totalPages,
    loading,
    search,
    setSearch,
    active,
    setActive
  } = useReviewManagement()
  return (
    <>
      <Row>
        <Col>
          <h3>Review Management</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-end'>

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px' }}
              value={search}
              placeholder='Search title, description'
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Status
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '200px', marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>All</option>
              <option key='true' value>Active</option>
              <option key='false' value={false}>In-Active</option>
            </Form.Select>

          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>Id</th>
            <th>UserName</th>
            <th>Description</th>
            <th>Rating</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(reviewList) &&
                      reviewList?.rows?.map(
                        (
                          {
                            reviewId,
                            userName,
                            description,
                            status,
                            rating
                          },
                          index
                        ) => {
                          return (
                            <tr key={reviewId}>
                              <td>{reviewId}</td>
                              <td>
                                {userName}
                              </td>

                              <td title={description}>

                                <span>{description}</span>
                              </td>
                              <td>{rating}</td>
                              <td>
                                {status
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>
                            </tr>
                          )
                        }
                      )}

          {reviewList?.count === 0 && !loading &&
                        (
                          <tr>
                            <td
                              colSpan={5}
                              className='text-danger text-center'
                            >
                              No data found
                            </td>
                          </tr>
                        )}
        </tbody>
      </Table>

      {reviewList?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={reviewList?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
    </>
  )
}
