import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { useLocation } from 'react-router-dom'
import { getTenantCasinoReportingDataStart, getTenantUserReportingDataStart } from '../../../../store/redux-slices/tenants'
import moment from 'moment'
import { getAllCasinoCategoriesStart, getAllSubCategoriesStart, tenantCasinoProviderStart } from '../../../../store/redux-slices/tenantCasino'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
const initalWalletState = {
  actionSource: '',
  search: '',
  actionType: '',
  categoryId: '',
  subCategoryId: '',
  providerId: '',
  transactionType: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useTenantCasinoReporting = ({ isAffiliate, isAgent, isV2 }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [page, setPage] = useState(1)
  const { casinoTenantReportingData, userTenantReportingData } = useSelector((state) => state.tenants)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const totalPages = Math.ceil(casinoTenantReportingData?.count / limit)
  const totalPagesUser = Math.ceil(userTenantReportingData?.count / limit)
  const [state, setState] = useState(initialDate)
  const { pathname } = useLocation()
  const {
    casinoProviders,
    casinoCategories,
    subCategories
  } = useSelector((state) => state.tenantCasino)
  useEffect(() => {
    pathname.split('/')[2] === 'casino-reporting' || pathname.split('/')[2] === 'v2-casino-reporting'
      ? (dispatch(getTenantCasinoReportingDataStart({
          form: {
            ...formData,
            from: moment(state[0].startDate).toISOString() || '',
            to: moment(state[0].endDate).toISOString() || '',
            limit,
            pageNo: page,
            agentId: agent,
            affiliateId: affiliate,
            isV2: isV2
          },
          isAffiliate,
          isAgent,
          isV2: isV2
        })))
      : (
          dispatch(getTenantUserReportingDataStart({
            form: {
              ...formData,
              from: moment(state[0].startDate).toISOString() || '',
              to: moment(state[0].endDate).toISOString() || '',
              limit,
              pageNo: page,
              agentId: agent,
              affiliateId: affiliate
            },
            isAffiliate,
            isAgent
          })))
  }, [formData, limit, page, state, agent, affiliate, isV2])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  useEffect(() => {
    if (pathname.split('/')[2] === 'casino-reporting') {
      dispatch(tenantCasinoProviderStart({ limit: '', pageNo: '', isAffiliate, isAgent }))
      dispatch(getAllCasinoCategoriesStart({ limit: '', pageNo: '', isAffiliate, isAgent }))
      dispatch(getAllSubCategoriesStart({ limit: '', pageNo: '', isAffiliate, isAgent }))
    }
  }, [])

  const handleCsvDownloadCasino = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/casino-transaction?pageNo=${page || ''}&actionType=${formData.actionType || ''}&limit=${limit || ''}&search=${formData.search || ''}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&categoryId=${formData.categoryId || ''}&subCategoryId=${formData.subCategoryId || ''}&providerId=${formData.providerId || ''}&csvDownload=true&token=${getLoginToken()}&agentId=${agent || ''}&affiliateId=${affiliate || ''}`)
  }
  const handleCsvDownloadUser = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/user-transaction?pageNo=${page || ''}&affiliateId=${affiliate ?? ''}&agentId=${agent ?? ''}&actionType=${formData.actionType || ''}&limit=${limit || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    formData,
    setFormData,
    totalPages,
    totalPagesUser,
    page,
    limit,
    setPage,
    casinoTenantReportingData,
    userTenantReportingData,
    setLimit,
    debounceFn,
    initalWalletState,
    state,
    setState,
    casinoCategories,
    subCategories,
    casinoProviders,
    initialDate,
    inputRef,
    handleCsvDownloadCasino,
    handleCsvDownloadUser,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default useTenantCasinoReporting
