import { useDispatch } from 'react-redux'
import { depositSuperAdminAmountStart, nonCashWalletSuperAdminStart, withdrawalSuperAdminAmountStart } from '../../../../store/redux-slices/superAdminTransactions'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const useAccountTransaction = (isForTenant, show, setShow, tenantData, isDeposit, isV2User) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [formDataValues, setFormData] = useState()
  const [confirmTransactionModal, setConfirmTransactionModal] = useState(false)
  const formInitialValues = isV2User
    ? {
        currency: '',
        amount: '',
        actionSource: '',
        rollingPercentage: ''
      }
    : {
        currency: '',
        amount: '',
        actionSource: ''
      }
  const handleAccountTransaction = (formData) => {
    const payload = {
      ...formDataValues,
      actionSource: isV2User ? formDataValues?.actionSource : isDeposit ? 'DEPOSIT' : 'WITHDRAW',
      userId: userId
    }
    !isForTenant
      ? (
          isDeposit
            ? dispatch(depositSuperAdminAmountStart(payload))
            : dispatch(withdrawalSuperAdminAmountStart(payload))
        )

      : dispatch(nonCashWalletSuperAdminStart({
        actionType: isDeposit ? 'DEPOSIT' : 'WITHDRAW', // DEPOSIT/WITHDRAW
        ...tenantData,
        ...formDataValues,
        setShow
      }))
  }
  return {
    handleAccountTransaction,
    setFormData,
    formDataValues,
    formInitialValues,
    setConfirmTransactionModal,
    confirmTransactionModal
  }
}

export default useAccountTransaction
