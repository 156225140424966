import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getTenantStart } from '../../../../store/redux-slices/tenants'
import { promotionCashAddEditStart } from '../../../../store/redux-slices/admins'

const useSportsPromotionCashConfig = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [loaderForItem, setLoaderForItem] = useState(0)
  const { tenantDetails, loading } = useSelector((state) => state.tenants)
  const { promotionCashAddEditLoading } = useSelector((state) => state.admins)

  const promotionCashAddEditHandler = (formValues) => {
    console.log(formValues)
    dispatch(promotionCashAddEditStart({ data: formValues }))
  }

  useEffect(() => {
    if (selectedClient) {
      dispatch(getTenantStart({ tenantId: selectedClient }))
    }
  }, [selectedClient])

  return {
    navigate,
    selectedClient,
    setSelectedClient,
    t,
    promotionCashAddEditHandler,
    tenantDetails,
    promotionCashAddEditLoading,
    loading,
    loaderForItem,
    setLoaderForItem
  }
}

export default useSportsPromotionCashConfig
