import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  createAdminUserStart,
  getAdminUserDetailsStart,
  getAllGroupsStart,
  updateTenantAdminUserStart
} from '../../../../store/redux-slices/adminUser'
import { permissionLabel } from '../../../../components/ProfilePage/constants'

const useCreateAdminUser = ({ group }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [type, setType] = useState('password')

  const { tenantAdminRole } = useSelector((state) => state.login)
  const { adminDetails } = useSelector(state => state.admins)
  const { groups } = useSelector(state => state.adminUser)

  const [groupOptions, setGroupOptions] = useState()
  const [selectedGroup, setSelectedGroup] = useState()

  const updateTenantAdminUser = (data) =>
    dispatch(
      updateTenantAdminUserStart(data)
    )

  const createTenantAdminUser = (data) =>
    dispatch(
      createAdminUserStart(data)
    )

  const getAdminUserDetails = (adminUserId) =>
    dispatch(getAdminUserDetailsStart({ adminUserId }))

  useEffect(() => {
    dispatch(getAllGroupsStart({ isTenant: true }))
    if (group) {
      setSelectedGroup({ label: group, value: group })
    }
  }, [])

  useEffect(() => {
    if (groups.length > 0) {
      const options = []
      groups.forEach((g) => {
        if (g !== '' && g !== null) {
          options.push({ label: g, value: g })
        }
      })
      setGroupOptions(options)
    }
  }, [groups])

  return {
    getAdminUserDetails,
    navigate,
    tenantAdminRole,
    getPermissionLabel: permissionLabel,
    updateTenantAdminUser,
    createTenantAdminUser,
    adminDetails,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup
  }
}

export default useCreateAdminUser
