import React, { useState } from 'react'
import {
  Row,
  Col,
  Card
} from '@themesberg/react-bootstrap'
import Tenants from './components/Tenant'
import useCreateTenant from './hooks/useCreateTenant'
import Preloader from '../../../components/Preloader'
import { useTranslation } from 'react-i18next'

const CreateTenant = () => {
  const {
    setSelectedTab,
    createTenantData,
    setCreateTenantData,
    adminUserDetails,
    loading
  } = useCreateTenant()
  const [fieldsValidation, setFieldValidation] = useState(true)
  const { t } = useTranslation()

  return (
    <>
      <Row className='ms-2 me-2 mt-2'>
        <Col>
          <h3>{t('tenants')}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <h3>{t('owner')}: {adminUserDetails?.firstName}{' '}{adminUserDetails?.lastName}</h3>
        </Col>
      </Row>

      <Card body>
        {loading
          ? (
            <Preloader />
            )
          : (
            <div className='mt-1'>
              <h3>{t('createTenant')}</h3>
              <Tenants
                fieldsValidation={fieldsValidation}
                createTenantData={createTenantData}
                setCreateTenantData={setCreateTenantData}
                setSelectedTab={setSelectedTab}
                setFieldValidation={setFieldValidation}
              />
            </div>
            )}
      </Card>
    </>
  )
}

export default CreateTenant
