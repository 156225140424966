import {
  faEdit
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Row,
  Col,
  Button,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import Preloader from '../../../../components/Preloader'
import useBonusDetail from '../components/hooks/useBonusDetail'
import GeneralDetails from './generalDetail'
import useCheckPermission from '../../../../utils/checkPermission'

const BonusDetail = () => {
  const {
    bonusDetail,
    loading,
    bonusId,
    navigate
  } = useBonusDetail()
  const { isHidden } = useCheckPermission()

  return (
    <div className='p-3'>
      {loading && !bonusDetail && bonusDetail?.bonusId !== bonusId
        ? (
          <Preloader />
          )
        : (
          <>
            <Row>
              <Col className='d-flex pt-2'>
                <Col>
                  {' '}
                  <h3>Bonus Details</h3>
                </Col>
                <Col className='d-flex justify-content-end '>
                  <ButtonGroup>
                    <Trigger message='Edit'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        onClick={() =>
                          navigate(`/super-admin/bonus/edit/${bonusId}`)}
                        hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>
                </Col>
              </Col>
            </Row>
            <div className='mt-2'>
              <GeneralDetails bonusDetail={bonusDetail} />
            </div>
          </>
          )}
    </div>
  )
}
export default BonusDetail
