import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import { getAllTenantCasinoTransactionsStart } from '../../../../store/redux-slices/tenantTransactions'
import { getTenantUserGGRStart, updateTenantBetNoteStart } from '../../../../store/redux-slices/tenantUsers'
import moment from 'moment'

const initialDate = [{
  startDate: '',
  endDate: '',
  key: 'selection'
}]
const useCasinoTransactionsList = ({ selectedTab, isAffiliate, isAgent, user }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const isTenant = !isAffiliate && !isAgent
  const { adminDetails } = useSelector(state => state.admins)
  const { allowedCurrencies } = useSelector((state) => state.login.tenantDetails?.tenantConfig || {})
  const { loading, tenantCasinoTransactions: casinoBetTransaction } = useSelector((state) => state.tenantTransactions)
  const { user: userData, ggrData } = useSelector((state) => state.tenantUsers)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const tenantCasinoTransactions = casinoBetTransaction
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [individualUserData, setIndividualUserData] = useState(null)
  const totalPages = Math.ceil(tenantCasinoTransactions?.count / limit)
  const [nonWithdrawableRolled, setNonWithdrawableRolled] = useState(false)
  const [bonusRolled, setBonusRolled] = useState(false)
  const [promotionRolled, setPromotionRolledRolled] = useState(false)
  const [state, setState] = useState(initialDate)
  const [formData, setFormData] = useState({})

  useEffect(() => {
    if (user?.username && selectedTab === 'casino-transactions') {
      dispatch(getAllTenantCasinoTransactionsStart({
        limit,
        pageNo: page,
        startDate: moment(state[0].startDate).toISOString() || '',
        endDate: moment(state[0].endDate).toISOString() || '',
        transactionType: selectedAction,
        userId,
        isAffiliate,
        isAgent,
        username: user?.username || '',
        isUserV2: userData.isV2User,
        nonWithdrawableRolled,
        bonusRolled,
        promotionRolled,
        formData
      }))
      dispatch(getTenantUserGGRStart({
        startDate: moment(state[0].startDate).toISOString() || '',
        endDate: moment(state[0].endDate).toISOString() || '',
        tenantId: isAffiliate ? adminDetails?.tenantId : '',
        userId,
        isAffiliate,
        isAgent
      }))
    }
  }, [user, selectedTab, limit, page, selectedCurrency, selectedAction, promotionRolled, nonWithdrawableRolled, bonusRolled, state, userId, formData])
  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate/user' : isAgent ? 'agent/user' : 'tenant/casino'}/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&startDate=${moment(state[0].startDate).toISOString() || ''}&endDate=${moment(state[0].endDate).toISOString() || ''}&currencyCode=${selectedCurrency || ''}&transactionType=${selectedAction || ''}&email=&token=${getLoginToken()}&userId=${userId || ''}&tenantId=${isTenant ? getItem('tenant-id') : '' || ''}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&nonWithdrawable=${formData.nonWithdrawable || ''}&bonus=${formData.bonus || ''}&promotionCash=${formData.promotionCash || ''}&isV2User=${userData.isV2User || ''}&adminId=`

  const handleSubmit = (data) => {
    dispatch(updateTenantBetNoteStart({
      transactionId: individualUserData.casinoTransactionId,
      note: data.description,
      isTenant: true,
      userId
    }))
  }
  return {
    allowedCurrencies,
    setSelectedCurrency,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    state,
    setState,
    tenantCasinoTransactions,
    loading,
    getCsvDownloadUrl,
    userData,
    initialDate,
    addNoteModal,
    setAddNoteModal,
    setIndividualUserData,
    individualUserData,
    handleSubmit,
    nonWithdrawableRolled,
    setNonWithdrawableRolled,
    bonusRolled,
    setBonusRolled,
    promotionRolled,
    setPromotionRolledRolled,
    formData,
    setFormData,
    ggrData
  }
}

export default useCasinoTransactionsList
