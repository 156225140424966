import React from 'react'
import useNotification from './hooks/useNotification'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBell, faPlus } from '@fortawesome/free-solid-svg-icons'
import NotificationTable from '../../../components/NotificationTable/NotificationTable'
import ReadReceiptTable from '../../../components/NotificationTable/ReadReceiptTable'
import Trigger from '../../../components/OverlayTrigger'
import ConfirmationModal, { CreateNotificationModal, TenantNotificationTable } from '../../../components/ConfirmationModal'
import useIsGamma from '../../../common/hook/useIsGamma'

const Notification = () => {
  const {
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    isReadReceiptTable,
    setIsReadReceiptTable,
    handleViewReadReciept,
    allNotificationList,
    totalPagesReadReciept,
    allReadRecieptList,
    handleCreateNotification,
    setCreateNotificationshow,
    creatNotificationShow,
    clientsFilterData,
    tenantToNotify,
    setTenantToNotify,
    data,
    setData,
    showConfirmActiveNotification,
    setShowConfirmActiveNotificationModal,
    handleActiveNotification,
    setNotificatonId,
    setStatus,
    isShowTenant,
    setShowTenant,
    setDataToShowAtTenantTable,
    tableDataToShow,
    filterData,
    setFilterData,
    initialState,
    loading,
    t,
    navigate,
    selectedClient,
    setSelectedClient,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = useNotification()
  const {
    isGamma
  } = useIsGamma()
  return (
    <div className='p-3'>
      <Row>
        <Col>
          <Row>
            <div className='d-flex gap-2 flex-column '>
              <div
                className='d-flex gap-1 align-items-center cursor-pointer' onClick={() => {
                  setIsReadReceiptTable(!isReadReceiptTable)
                  setPage(1)
                }}
              >
                {isReadReceiptTable && (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className='cursor-pointer me-1'

                    />
                    {t('goBack')}
                  </>
                )}
              </div>

              <h3 className='mb-2'><FontAwesomeIcon icon={faBell} />&nbsp; {t('notification')}</h3>
            </div>
          </Row>
        </Col>
        <Col>
          {
          !isReadReceiptTable && !isGamma && (
            <Trigger message={t('createNotification')}>
              <FontAwesomeIcon
                icon={faPlus}
                data-tip={t('createNotification')}
                className='cursor-pointer float-end'
                onClick={() => {
                  navigate('/super-admin/create-notification')
                  // setCreateNotificationshow((prev) => !prev.creatNotificationShow)
                }}
                size='xl'
              />
            </Trigger>
          )
        }

        </Col>
      </Row>
      {
      !isReadReceiptTable
        ? (<NotificationTable
            tableData={allNotificationList}
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            totalPages={totalPages}
            handleViewReadReciept={handleViewReadReciept}
            setShowConfirmActiveNotificationModal={setShowConfirmActiveNotificationModal}
            setNotificatonId={setNotificatonId}
            setStatus={setStatus}
            setShowTenant={setShowTenant}
            setDataToShowAtTenantTable={setDataToShowAtTenantTable}
            tableDataToShow={tableDataToShow}
            filterData={filterData}
            setFilterData={setFilterData}
            initialState={initialState}
            loading={loading}
            t={t}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
           />)
        : (<ReadReceiptTable
            tableData={allReadRecieptList}
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            totalPages={totalPagesReadReciept}
            setIsReadReceiptTable={setIsReadReceiptTable}
            isReadReceiptTable={isReadReceiptTable}
            filterData={filterData}
            setFilterData={setFilterData}
            initialState={initialState}
            loading={loading}
            t={t}
           />)
     }
      {
      creatNotificationShow
        ? <CreateNotificationModal
            setShow={setCreateNotificationshow}
            show={creatNotificationShow}
            handleCreateNotification={handleCreateNotification}
            tenant={clientsFilterData}
            tenantToNotify={tenantToNotify}
            setTenantToNotify={setTenantToNotify}
            data={data}
            setData={setData}
            isTenant={false}
            t={t}
          />
        : <></>
     }
      {
      showConfirmActiveNotification
        ? <ConfirmationModal
            show={showConfirmActiveNotification}
            setShow={setShowConfirmActiveNotificationModal}
            handleYes={handleActiveNotification}
            isNotification
          />
        : <></>

     }{
      isShowTenant
        ? <TenantNotificationTable
            show={isShowTenant}
            setShow={setShowTenant}
            tableDataToShow={tableDataToShow}
          />
        : <></>
     }
    </div>
  )
}

export default Notification
