import React from 'react'
import {
  Button,
  Modal,
  Form as BForm,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { bulkEditDefaultRtpSchema } from '../schema'
import useUpdateBulkSystemRtp from '../hooks/useUpdateBulkSystemRtp'

const BulkEditSystemRtp = ({
  handleClose,
  data,
  t,
  active,
  fetchData,
  selectedDefaultRtpGames
}) => {
  const {
    loading,
    updateBulkDefaultRtp
  } = useUpdateBulkSystemRtp({ fetchData })

  const initialValues = {
    systemRtp: 0
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={bulkEditDefaultRtpSchema(t)}
      onSubmit={(formValues) => {
        const data = {
          rtp: Number(formValues?.systemRtp),
          masterCasinoGameIds: selectedDefaultRtpGames
        }
        updateBulkDefaultRtp(data, handleClose)
      }}
    >
      {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
        return (
          <Form>
            <Modal.Body>
              <Row>
                <Col>
                  <BForm.Label>{t('systemReturnToPlayer')}</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='systemRtp'
                    placeholder={t('Enter Rtp')}
                    value={values.systemRtp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='systemRtp' className='text-danger' />
                </Col>
              </Row>
            </Modal.Body>
            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button variant='warning' onClick={() => handleClose()}>
                  {t('cancel')}
                </Button>
                <Button variant='success' onClick={handleSubmit} className='ml-2'>
                  {t('submit')}
                  {loading && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  )}
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BulkEditSystemRtp
