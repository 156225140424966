import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getPaymentProviderStart } from '../../../../store/redux-slices/payment'
import { createTenantStart, editTenantStart } from '../../../../store/redux-slices/tenants'

const useWithdraw = (selectedTab) => {
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const { loading, paymentProviders } = useSelector(state => state.payment)

  useEffect(() => {
    if (selectedTab === 'withdraw') {
      dispatch(getPaymentProviderStart({
        isTenant: false,
        paymentType: 'withdraw',
        aggregator: '',
        tenant: 'true',
        group: '',
        status: '',
        tenantIds: []
      }))
    }
  }, [selectedTab])

  useEffect(() => {
    const myData = {}
    if (!Object.keys(myData)?.length && paymentProviders) {
      paymentProviders.forEach(({ aggregator, group }) => {
        if (myData?.[aggregator]?.[group] === undefined) {
          const provDetails = paymentProviders.filter((item) =>
            item.group === group && item.aggregator === aggregator)
          if (provDetails?.length) {
            if (myData[aggregator] === undefined) myData[aggregator] = []
            if (myData[aggregator][group] === undefined) myData[aggregator][group] = []
            myData[aggregator][group] = provDetails
          }
        }
      }
      )
      setData(myData)
    }
  }, [paymentProviders])

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => dispatch(
    editTenantStart({
      createTenantData: {
        ...createTenantData,
        applyToBonus: !!createTenantData?.depositMethods?.filter(item => item?.applyToBonus)?.length
      },
      navigate,
      val,
      tenantId
    })
  )

  const createTenant = (createTenantData, setCreateTenantData, setSelectedTab) => dispatch(createTenantStart({ createTenantData, navigate, setCreateTenantData, setSelectedTab }))

  return {
    data,
    loading,
    navigate,
    createTenant,
    editTenant,
    tenantId
  }
}

export default useWithdraw
