import React from 'react'
import useViewBonusDetail from './hooks/useViewBonusDetail'
import moment from 'moment'
import { BonusVal } from './constant'
import Preloader from '../../components/Preloader'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BonusTabContent from './components/BonusTabContent'
import BonusAdminTabContent from './components/BonusAdminTabContent'
import { formatNumber } from '../../utils/storageUtils'

const ViewBonusDetail = ({ isTenant, isAffiliate }) => {
  const {
    loading,
    bonusDetail,
    t,
    navigate,
    selectedTab,
    setSelectedTab,
    TABS_KEYS
  } = useViewBonusDetail({ isTenant, isAffiliate })
  const isCompensation = bonusDetail?.bonusType === BonusVal.compensationBonus
  const isRecurring = bonusDetail?.bonusType === BonusVal.reOccuringDepositBonus
  const isPayback = bonusDetail?.bonusType === BonusVal.paybackBonus
  const isRackBack = bonusDetail?.bonusType === BonusVal.rackbackBonus
  return (
    <>
      {loading && <Preloader />}
      <div className='p-3'>
        <div role='button' onClick={() => navigate(-1)} className='d-flex gap-2 items-center mb-2'>
          <span><FontAwesomeIcon icon={faArrowLeft} /></span>
          <h3 className=''>{t('details')}</h3>

        </div>
        <div className='d-flex flex-column gap-3'>
          {!isAffiliate && (
            <div className='d-flex align-items-center gap-2'>
              <strong style={{ minWidth: 220 }}>{t('senior')}</strong>
              <span>{bonusDetail?.parentUsername}</span>
            </div>
          )}
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('bonusType')}</strong>
            <span>{t(bonusDetail?.bonusType)}</span>
          </div>
          {!isRackBack && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('bonus_valid_from')}</strong>
                <span>{moment(bonusDetail?.bonusValidFrom).format('MM-DD-YYYY HH:mm')}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('bonus_valid_to')}</strong>
                <span>{moment(bonusDetail?.bonusValidTo).format('MM-DD-YYYY HH:mm')}</span>
              </div>
            </>
          )}
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('rollingPercent')}</strong>
            <span>{bonusDetail?.rollingPercent}%</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('maximum_bonus_limit')}</strong>
            <span>{formatNumber(bonusDetail?.maxBonusLimit)} ₩</span>
          </div>
          {!isPayback && (
            <div className='d-flex align-items-center gap-2'>
              <strong style={{ minWidth: 220 }}>{t('bonus_percentage')}</strong>
              <span>{bonusDetail?.bonusPercent}%</span>
            </div>
          )}
          {
          isCompensation && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('limitType')}</strong>
                <span>{bonusDetail?.limitType}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('customLimit')}</strong>
                <span>{bonusDetail?.customLimit} ₩</span>
              </div>
              {/* <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('minutes_for_bonus_given')}</strong>
                <span>{bonusDetail?.minsForBonusGiven}</span>
              </div> */}
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('compensation_target')}</strong>
                <span>{bonusDetail?.compensationTarget} ₩</span>
              </div>
            </>
          )

        }
          {
          isRecurring && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('reoccurring_deposit_rate')}</strong>
                <span>{bonusDetail?.reoccuringDepositRate}%</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('withdrawal_rate')}</strong>
                <span>{bonusDetail?.withdrawalRate}%</span>
              </div>

            </>
          )

        }
          {
          isPayback && (
            <>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('slotCatBonusPercent')}</strong>
                <span>{bonusDetail?.slotCatBonusPercent}%</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('liveCatBonusPercent')}</strong>
                <span>{bonusDetail?.liveCatBonusPercent}%</span>
              </div>

            </>
          )

        }

        </div>
        {(isTenant || isAffiliate) && (
          <div className='my-3'>
            <BonusTabContent
              isTenant={isTenant}
              isAffiliate={isAffiliate}
              t={t}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              TABS_KEYS={TABS_KEYS}
            />
          </div>
        )}
        {(!isTenant && !isAffiliate) && (
          <BonusAdminTabContent
            t={t}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </div>

    </>
  )
}

export default ViewBonusDetail
