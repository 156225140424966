import React from 'react'
import usePerodicBonus from './usePerodicBonus'
import { Accordion, Col, Form, Row, Form as BForm, Spinner, Button } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Preloader from '../../../components/Preloader'
import { ErrorMessage, Formik } from 'formik'
import { perodicBonusSchema } from './schema'

export const RackBackBonus = () => {
  const isRackBackBonus = true
  const {
    selectedClient,
    setSelectedClient,
    t,
    updatePeriodicBonus,
    bonusList,
    loading,
    setLoaderForItem,
    handleSetBonusActiveOrInActive
  } = usePerodicBonus(isRackBackBonus)

  return (
    <div className='p-3'>
      <Row className='mb-3'>
        <Col>
          <h3>{t('rackBackBonus')}</h3>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          </div>
        </Col>
      </Row>

      {selectedClient
        ? (
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            {loading
              ? <Preloader />
              : bonusList?.rows?.map((item, i) => {
                return (
                  <Accordion.Item
                    key={`deposit-type-add-on-listing-${i}`}
                    eventKey={String(i)}
                  >
                    <Accordion.Header>{t(item?.bonusType)}</Accordion.Header>
                    <Accordion.Body>
                      <div className='ps-2'>
                        <Formik
                          enableReinitialize
                          initialValues={{
                            maxBonusLimit: item?.maxBonusLimit || '',
                            rollingPercent: item?.rollingPercent || '',
                            bonusPercent: item?.bonusPercent || ''
                          }}
                          validationSchema={perodicBonusSchema(t)}
                          onSubmit={(formValues, { resetForm }) => {
                            const newFormsValue = {
                              ...formValues,
                              tenantId: Number(selectedClient),
                              bonusId: item.bonusId
                            }
                            setLoaderForItem(i)
                            updatePeriodicBonus(newFormsValue, resetForm)
                          }}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            handleBlur
                          }) => {
                            return (
                              <Form className='mt-2'>
                                <Row>
                                  <Col sm={6} className='mb-2'>
                                    <label>
                                      {t('dailyStackLimit')}<span className='text-danger'> *</span>
                                    </label>
                                    <BForm.Control
                                      type='number'
                                      onWheel={(e) => e.target.blur()}
                                      name='maxBonusLimit'
                                      maxLength={25}
                                      placeholder={t('dailyStackLimitPlaceholder')}
                                      value={values.maxBonusLimit || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      name='maxBonusLimit'
                                      className='text-danger'
                                    />
                                  </Col>

                                  <Col sm={6} className='mb-2'>
                                    <label>
                                      {t('rackbackPercent')}<span className='text-danger'> *</span>
                                    </label>
                                    <BForm.Control
                                      type='number'
                                      onWheel={(e) => e.target.blur()}
                                      name='bonusPercent'
                                      maxLength={25}
                                      placeholder={t('rackbackPercentPlaceholder')}
                                      value={values.bonusPercent || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      name='bonusPercent'
                                      className='text-danger'
                                    />
                                  </Col>

                                  <Col className='mb-2' sm={6}>
                                    <label>
                                      {t('rollingPercent')}<span className='text-danger'> *</span>
                                    </label>
                                    <BForm.Control
                                      type='number'
                                      onWheel={(e) => e.target.blur()}
                                      name='rollingPercent'
                                      placeholder={t('rollingPercentPlaceholder')}
                                      maxLength={25}
                                      value={values.rollingPercent || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      name='rollingPercent'
                                      className='text-danger'
                                    />
                                  </Col>
                                </Row>

                                <div className='mt-4 d-flex justify-content-end align-items-center'>
                                  <Col>
                                    <Button
                                      variant={!item?.status ? 'outline-success' : 'outline-danger'}
                                      onClick={() => {
                                        handleSetBonusActiveOrInActive({
                                          bonusId: item.bonusId,
                                          status: !item?.status,
                                          tenantId: Number(selectedClient),
                                          adminId: item.adminId
                                        })
                                      }}
                                      className='ml-2 gap-2 d-flexfloat-left'
                                    >
                                      {t(!item?.status ? t('active') : t('inActive'))}
                                    </Button>
                                  </Col>
                                  <Button
                                    variant='outline-success'
                                    onClick={handleSubmit}
                                    className='ml-2'
                                  >
                                    {t('update')}
                                    {loading && (
                                      <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                      />
                                    )}
                                  </Button>
                                </div>
                              </Form>
                            )
                          }}
                        </Formik>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
          </Accordion>
          )
        : (
          <div className='depositType-select-tenant'>
            <p>{t('selectTenantBonus')}</p>
          </div>
          )}
    </div>
  )
}
