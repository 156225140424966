import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createCmsStart } from '../../../../store/redux-slices/tenantsFetchData'
import { useEffect, useState } from 'react'
import { deleteCMSLanguageStart, getCmsCategoryStart, updateSuperAdminCMSStart } from '../../../../store/redux-slices/fetchData'
import { getImageGalleryStart } from '../../../../store/redux-slices/emailTemplate'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import { EditorState } from 'draft-js'
import { SuperAdminRoutes } from '../../../../routes'
import { useTranslation } from 'react-i18next'
// import { convertToRaw } from 'draft-js'

const useCreateCms = (cmsData) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cmsPageId } = useParams()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [text, setText] = useState()
  const [selectedClient, setSelectedClient] = useState(cmsData ? cmsData?.adminUserId : '')
  const [selectedPortal, setSelectedPortal] = useState(cmsData ? cmsData?.tenantId : '')
  const [err, setErr] = useState('')
  const [selectedTab, setSelectedTab] = useState('EN')
  const [template, setTemplate] = useState('')
  const [galleryModal, setGalleryModal] = useState('')
  const [isTouched, setIsTouched] = useState({ client: false, portal: true })
  const { loading } = useSelector((state) => state.tenantsFetch)
  const { cmsKeys, cmsCategory } = useSelector((state) => state.fetch)
  const { tenantLanguages } = useSelector((state) => state.languages)

  const getGalleryData = () => {
    dispatch(getImageGalleryStart({
      isTenant: false
    }))
  }

  useEffect(() => {
    dispatch(getCmsCategoryStart())
  }, [])

  // useEffect(() => {
  //   dispatch(getCMSDynamicKeysStart({
  //     isTenant: false
  //   }))
  // }, [])

  useEffect(() => {
    if (!tenantLanguages?.length || !tenantLanguages.includes(selectedTab)) {
      setSelectedTab('EN')
    }
  }, [tenantLanguages])

  useEffect(() => {
    dispatch(getCMSLanguagesStart({ adminId: selectedClient, tenantId: selectedPortal }))
  }, [selectedClient, selectedPortal])

  const createCms = (data) => dispatch(createCmsStart({
    cmsData: data,
    navigate
  }))
  const editCms = (data) => {
    dispatch(updateSuperAdminCMSStart(data))
    navigate(SuperAdminRoutes.CMS)
  }

  const deleteCms = (data) => dispatch(deleteCMSLanguageStart({ isTenant: false, data }))
  return {
    navigate,
    t,
    loading,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    isTouched,
    setIsTouched,
    err,
    setErr,
    editCms,
    cmsPageId,
    template,
    setTemplate,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages: tenantLanguages,
    deleteCms,
    editorState,
    setEditorState,
    text,
    setText,
    cmsCategory
  }
}

export default useCreateCms
