import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getEntityBonusDetailsStart, resetEntityBonusDetails, updateAffiliateAndAgentBonusLimitStart } from '../../../../store/redux-slices/bonus'
import { returnFormBonusData } from '../../../../utils/helpers'
import { useEffect, useState } from 'react'
import { entityType } from './useAdminTabContent'

const usePlayersTab = ({ isAffiliate }) => {
  const { entityBonusDetails, entityLoading } = useSelector(state => state?.bonus)
  const { bonusDetail, updateLoading } = useSelector((state) => state.bonus)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fetchEntity, setFetchEntity] = useState(false)

  const handleUpdateLimit = (values, formActions) => {
    const formData = returnFormBonusData(values, true)
    dispatch(updateAffiliateAndAgentBonusLimitStart({
      formData: {
        entityType: 'senior-player',
        bonusId: bonusDetail?.bonusId,
        playerLimitObject: formData
      },
      handleClose: () => {
        setFetchEntity(prev => !prev)
      },
      isAffiliate,
      isPlayer: true
    }))
  }
  const getEntityBonusDetails = (formData) => {
    dispatch(resetEntityBonusDetails())
    dispatch(getEntityBonusDetailsStart({ formData: { ...formData, entityType: entityType.SENIOR_PLAYER }, bonusType: bonusDetail?.bonusType, isTenant: true }))
  }
  useEffect(() => {
    if (bonusDetail?.bonusId) {
      getEntityBonusDetails({
        entityId: '',
        bonusId: bonusDetail?.bonusId
      })
    }
  }, [bonusDetail, fetchEntity])

  return {
    entityBonusDetails,
    entityLoading,
    t,
    bonusDetail,
    handleUpdateLimit,
    updateLoading
  }
}

export default usePlayersTab
