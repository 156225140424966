import { Col, Row, Form as BForm } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import HtmlTextEditor from '../../common/HtmlTextEditor/HtmlTextEditor'
import { ErrorMessage } from 'formik'

export const CreateCMSTemplate = ({
  isErrorHandle,
  setFieldError,
  setFieldTouched,
  cmsKeys,
  setFieldValue,
  handleChange,
  handleBlur,
  selectedTab,
  title,
  setTitle,
  content,
  setContent,
  t
}) => {
  const [template, setTemplate] = useState('')
  const [err, setErr] = useState('')
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (cmsKeys?.dynamicKeys && Object.keys(cmsKeys?.dynamicKeys)?.length) {
      let tempDataAll = {}
      let tempData = {}
      const dynamicKeys = cmsKeys?.dynamicKeys
      dynamicKeys.forEach((item) => {
        tempDataAll = { ...tempDataAll, [item.key]: item.description }
        if (item.required) {
          tempData = { ...tempData, [item.key]: item.description }
        }
      })
    }
  }, [cmsKeys?.dynamicKeys])

  useEffect(() => {
    if (template) {
      setErr('')
    }
    const delayDebounceFn = setTimeout(() => {
      setContent({
        ...content,
        [selectedTab]: template
      })
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [template])

  useEffect(() => {
    setFieldValue && setFieldValue('language', selectedTab)
    setLabel(label)
  }, [selectedTab])

  useEffect(() => {
    setFieldValue && setFieldValue('title', label)
    setTitle({
      ...title,
      [selectedTab]: label
    })
  }, [label])

  return (
    <>
      <Row className='mt-3'>
        <Col>
          <Col xs={2}>
            <BForm.Label>
              {t('title')} <span className='text-danger'>* </span>
            </BForm.Label>
          </Col>

          <Col xs={5}>
            <BForm.Control
              type='text'
              name='title'
              placeholder={t('enterTitle')}
              value={label}
              onChange={(e) => {
                setLabel(e.target.value)
                handleChange(e)
              }}
              onBlur={handleBlur}
            />
            <ErrorMessage
              component='div'
              name='title'
              className='text-danger'
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex m-2 align-items-center'>
            <BForm.Label>
              {t('content')} <span className='text-danger'>*</span>
            </BForm.Label>
            <Col />
          </div>

          <HtmlTextEditor
            value={content?.[selectedTab] || ''}
            setValue={(v) => {
              if (isErrorHandle) {
                if (v) {
                  setFieldError('content', null)
                  setFieldValue('content', v)
                } else {
                  setFieldTouched('content', true)
                  setFieldError('content', t('contentRequired'))
                  setFieldValue('content', '')
                }
              }
              setTemplate(v)
            }}
          />
        </Col>
      </Row>
      {err &&
        <Row>
          <span className='text-danger'>{err}</span>
        </Row>}
    </>
  )
}
