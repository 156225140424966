import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import moment from 'moment'
import { getAgentStart, updateAgentStatusStart } from '../../../store/redux-slices/agent'

const useAgents = ({ isAffiliate }) => {
  const isTenant = !window.location.href.match(/super/g)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const initialDate = [
    {
      startDate: '',
      endDate: '',
      key: 'selection'
    }
  ]
  const { t } = useTranslation()
  const { agentData, loading } = useSelector((state) => state.agent)
  const [parentRole, setParentRole] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('')
  const [agentId, setAgentId] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('')
  const totalPages = Math.ceil(agentData?.count / limit)
  const inputRef = useRef()
  const [state, setState] = useState(initialDate)
  const [search, setSearch] = useState('')
  const [agentWithoutAffiliate, setAgentWithoutAffiliate] = useState('false')
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  const handleShow = (id, active) => {
    setAgentId(id)
    setActive(!active)
    setShow(true)
  }
  const handleYes = () => {
    dispatch(
      updateAgentStatusStart({
        formData: {
          status: active,
          agentId

        },
        isTenant,
        isAffiliate,
        handleClose: () => {
          setShow(false)
          getAgentsList()
        }
      })
    )
  }
  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  const getAgentsList = () => {
    dispatch(getAgentStart({
      adminUserId: selectedClient,
      affiliateId: '',
      from: moment(state[0].startDate).toISOString() || '',
      to: moment(state[0].endDate).toISOString() || '',
      search: search,
      status: status,
      parentRole: parentRole,
      limit,
      pageNo: page,
      agentWithoutAffiliate: agentWithoutAffiliate,
      isTenant,
      isAffiliate
    }))
  }
  useEffect(() => {
    getAgentsList()
  }, [page, selectedClient, search, state, limit, status, parentRole, agentWithoutAffiliate])

  return {
    navigate,
    loading,
    agentData,
    handleDebounceFn,
    debounceFn,
    inputRef,
    selectedClient,
    setSelectedClient,
    handleShow,
    handleYes,
    show,
    setShow,
    status,
    setStatus,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    parentRole,
    setParentRole,
    state,
    setState,
    search,
    setSearch,
    initialDate,
    t,
    useRef,
    agentWithoutAffiliate,
    setAgentWithoutAffiliate
  }
}

export default useAgents
