import { Row, Col, Button, Tabs, Tab } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import useLanguageWise from '../hooks/useLanguageWise'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import Trigger from '../../OverlayTrigger'
import EditLangWise from './EditLangWise'
import Preloader from '../../Preloader'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import { ChangeLangConfirmationModal, GalleryModal } from '../../ConfirmationModal'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LanguageWise = ({ errorCodes = false }) => {
  const {
    navigate,
    isTenant,
    languages,
    languageKeys,
    selectedTab,
    myKeys,
    languageTabs,
    selectedKeyTab,
    setSelectedKeyTab,
    loading,
    updateData,
    isError,
    setIsError,
    check,
    show,
    setShow,
    handleYes,
    setMySelectedTab,
    galleryModal,
    setGalleryModal,
    myKeyData,
    myLangauges,
    isHidden
  } = useLanguageWise({ errorCodes })

  const [keysData, setKeysData] = useState({})
  useEffect(() => {
    if (errorCodes) {
      const keyData = {}
      Object.keys(myKeyData).forEach((key) => {
        keyData[key] = myKeyData[key][selectedTab]
      })
      setKeysData(keyData)
    }
  }, [myKeyData, errorCodes, selectedTab])

  return (
    <>
      {(!selectedKeyTab || loading)
        ? <Preloader />
        : (
          <>
            <Row className='mb-3'>
              <Col>
                <h3>{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                <Trigger message='Gallery'>
                  <Button
                    hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
                    onClick={() => setGalleryModal(true)}
                    variant='outline-secondary'
                  >
                    <FontAwesomeIcon icon={faImages} />
                  </Button>
                </Trigger>
                <Button
                  variant='warning'
                  className='float-end ms-2'
                  onClick={() => !isTenant
                    ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguageKeys : SuperAdminRoutes.EditSupportLanguageKeys)
                    : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguageKeys : TenantRoutes.EditSupportLanguageKeys)}
                >
                  Edit by Key
                </Button>
              </Col>
            </Row>

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                setShow(true)
                setMySelectedTab(tab)
              }}
              className='nav-light'
            >
              <Tab
                eventKey='EN'
                tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                title={
                  <Trigger message='English'>
                    <span>EN</span>
                  </Trigger>
                }
              >
                <div className='mt-5'>
                  <EditLangWise
                    languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === 'EN')}
                    selectedTab={selectedTab}
                    myKeys={myKeys}
                    isTenant={isTenant}
                    languageTabs={languageTabs}
                    selectedKeyTab={selectedKeyTab}
                    setSelectedKeyTab={setSelectedKeyTab}
                    updateData={updateData}
                    isError={isError}
                    setIsError={setIsError}
                    check={check}
                    errorCodes={errorCodes}
                    myKeyData={myKeyData}
                  />
                </div>
              </Tab>
              {!isTenant
                ? languages?.count && languages?.rows?.map(({ languageName, code }) => {
                    return code !== 'EN' && (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) && (
                      <Tab
                        eventKey={code}
                        title={
                          <Trigger message={languageName}>
                            <span>{code}</span>
                          </Trigger>
                }
                        key={code}
                        mountOnEnter
                        tabClassName={selectedTab !== code ? (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) ? 'email' : '' : 'email-active'}
                      >
                        <div className='mt-5'>
                          <EditLangWise
                            languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === selectedTab)}
                            selectedTab={selectedTab}
                            myKeys={myKeys}
                            isTenant={isTenant}
                            languageTabs={languageTabs}
                            selectedKeyTab={selectedKeyTab}
                            setSelectedKeyTab={setSelectedKeyTab}
                            updateData={updateData}
                            isError={isError}
                            setIsError={setIsError}
                            check={check}
                            errorCodes={errorCodes}
                            myKeyData={myKeyData}
                          />
                        </div>
                      </Tab>
                    )
                  })
                : languages?.length && languages?.map((code) => {
                  return code !== 'EN' && (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code ? (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditLangWise
                          languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === selectedTab)}
                          selectedTab={selectedTab}
                          myKeys={myKeys}
                          isTenant={isTenant}
                          languageTabs={languageTabs}
                          selectedKeyTab={selectedKeyTab}
                          setSelectedKeyTab={setSelectedKeyTab}
                          updateData={updateData}
                          isError={isError}
                          setIsError={setIsError}
                          check={check}
                          errorCodes={errorCodes}
                          myKeyData={myKeyData}
                        />
                      </div>
                    </Tab>
                  )
                })}
            </Tabs>
          </>
          )}
      <ChangeLangConfirmationModal
        show={show}
        setShow={setShow}
        handleYes={handleYes}
      />

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default LanguageWise
