import * as Yup from 'yup'
import moment from 'moment'

export const depositAddonSchema = (t) => {
  return Yup.object().shape({
    addOnRelativeAmount: Yup.number()
      .when('type', {
        is: (val) => val !== 'bonus',
        then: Yup.number()
          .typeError(t('addOnRelativeAmountInvalid'))
          .integer(t('addOnRelativeAmountInvalid'))
          .positive(t('addOnRelativeAmountInvalid'))
          .min(1, t('addOnRelativeAmountInvalid'))
          .max(99999, t('addOnRelativeAmountInvalid')) // Restrict max amount for non-bonus
          .required(t('addOnRelativeAmountRequired'))
      }),

    addOnPercentage: Yup.number()
      .when('type', {
        is: (val) => val !== 'bonus',
        then: Yup.number()
          .typeError(t('addOnPercentageInvalid'))
          .integer(t('addOnPercentageInvalid'))
          .positive(t('addOnPercentageInvalid'))
          .max(999, t('addOnPercentageInvalid')) // Restrict percentage range for non-bonus
          .required(t('addOnPercentageRequired'))
      }),

    rollingPercentage: Yup.number()
      .when('type', {
        is: (val) => val !== 'bonus',
        then: Yup.number()
          .typeError(t('rollingPercentageInvalid'))
          .integer(t('rollingPercentageInvalid'))
          .positive(t('rollingPercentageInvalid'))
          .max(999, t('rollingPercentageInvalid')) // Restrict percentage for non-bonus
          .required(t('rollingPercentageRequired'))
      }),

    maxBonusLimit: Yup.number().when('type', {
      is: (val) => val === 'bonus',
      then: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .max(99999999, t('customLimitLessThan8digit'))
        .required(t('fieldRequired'))
    }),

    rollingPercent: Yup.number().when('type', {
      is: (val) => val === 'bonus',
      then: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .max(999, t('rollingPercentageInvalid'))
        .required(t('fieldRequired'))
    }),

    bonusPercent: Yup
      .number()
      .when('type',
        {
          is: (val) => {
            return val === 'bonus'
          },
          then: Yup.number()
            .transform((value) => {
              return (Number.isNaN(value) ? null : value)
            })
            .positive()
            .max(99, t('maximumValue99'))
            .required(t('fieldRequired'))
        }),

    bonusValidFrom: Yup.date().when('type', {
      is: (val) => val === 'bonus',
      then: Yup.date()
        .required(t('fieldRequired'))
        .transform((currentValue, originalValue) =>
          moment(originalValue, 'YYYY-MM-DDTHH:mm', true).toDate()
        )
        .typeError(t('mustbeValidDate'))
    }),

    bonusValidTo: Yup.date().when('type', {
      is: (val) => val === 'bonus',
      then: Yup.date()
        .required(t('fieldRequired'))
        .min(Yup.ref('bonusValidFrom'), t('validToLater'))
        .transform((currentValue, originalValue) =>
          moment(originalValue, 'YYYY-MM-DDTHH:mm', true).toDate()
        )
        .typeError(t('mustbeValidDate'))
    })
  })
}
