import React from 'react'
import Trigger from '../../../components/OverlayTrigger'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'

const TableRow = ({ typeOf, banner, banners, isHidden, t, setData, setShow, lang, ImgObj, ObjectOfData }) => {
  let noData = true
  if (typeOf === 'object') {
    if (Object.keys(ObjectOfData || {}).length < 1) {
      noData = false
    }
  } else {
    if (!ObjectOfData) {
      noData = false
    }
  }
  if (!noData) {
    return (
      <>
        {!['popupBanner', 'casinoEntryModel'].includes(banner) &&
          <tr>
            <td>
              {t(banner)}
            </td>
            <td>
              <div className='py-2'>
                -
              </div>
            </td>
          </tr>}

      </>
    )
  }
  return (
    <>
      {!['popupBanner', 'casinoEntryModel'].includes(banner) &&
        <tr>
          <td>
            {t(banner)}
          </td>
          <td>
            {typeOf === 'string'
              ? (
                <>
                  <span
                    style={{
                      cursor: 'pointer',
                      marginRight: 10
                    }}
                    className='text-link'
                    onClick={() => window.open(banners?.bannerUrl?.[banner][lang])}
                  >
                    {t('bannerPreview')}
                  </span>
                  {isHidden({ module: { key: 'BannerManagement', value: 'U' } })
                    ? '-'
                    : (
                      <Trigger message={t('updateBanner')}>
                        <Button
                          className='ml-2'
                          size='sm'
                          variant='warning'
                          onClick={() => {
                            setData({ type: banner, imageUrl: banners?.bannerUrl?.[banner][lang] })
                            setShow(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>)}
                </>
                )
              : (
                <>
                  {
                        Object.keys(ImgObj[banner][lang] || {}).map((imgKey) => (
                          <div key={imgKey} className='d-flex mt-2 align-items-center justify-content-center '>
                            <span
                              style={{
                                cursor: 'pointer',
                                marginRight: 10
                              }}
                              className='text-link'
                              onClick={() => window.open(ImgObj[banner][lang][imgKey])}
                            >
                              {t('bannerPreview')}
                            </span>
                            {isHidden({ module: { key: 'BannerManagement', value: 'U' } })
                              ? '-'
                              : (
                                <Trigger message={t('updateBanner')}>
                                  <Button
                                    className='ml-2'
                                    size='sm'
                                    variant='warning'
                                    onClick={() => {
                                      setData({ type: banner, imageKey: imgKey, imageUrl: ImgObj[banner][lang][imgKey] })
                                      setShow(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Trigger>)}
                          </div>
                        ))
                      }

                </>
                )}
          </td>
        </tr>}

    </>
  )
}

export default TableRow
