import React from 'react'
import useSportsBookViewBonusDetail from './hooks/useSportsBookViewBonusDetail'
import moment from 'moment'
import { BonusVal } from './constant'
import Preloader from '../../components/Preloader'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BonusTabContent from './components/BonusTabContent'
import BonusAdminTabContent from './components/BonusAdminTabContent'
import { formatNumber } from '../../utils/storageUtils'

const SportsBookViewBonusDetail = ({ isTenant, isAffiliate }) => {
  const {
    loading,
    bonusDetail,
    t,
    navigate,
    selectedTab,
    setSelectedTab,
    TABS_KEYS
  } = useSportsBookViewBonusDetail({ isTenant, isAffiliate })
  const isAddOnOddBonus = bonusDetail?.bonusType === BonusVal.winningBonus

  return (
    <>
      {loading && <Preloader />}
      <div className='p-3'>
        <div role='button' onClick={() => navigate(-1)} className='d-flex gap-2 items-center mb-2'>
          <span><FontAwesomeIcon icon={faArrowLeft} /></span>
          <h3 className=''>{t('details')}</h3>

        </div>
        <div className='d-flex flex-column gap-3'>
          {!isAffiliate && (
            <div className='d-flex align-items-center gap-2'>
              <strong style={{ minWidth: 220 }}>{t('senior')}</strong>
              <span>{bonusDetail?.parentUsername}</span>
            </div>
          )}
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('bonusType')}</strong>
            <span>{t(bonusDetail?.bonusType)}</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('bonus_valid_from')}</strong>
            <span>{moment(bonusDetail?.bonusValidFrom).format('MM-DD-YYYY HH:mm')}</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('bonus_valid_to')}</strong>
            <span>{moment(bonusDetail?.bonusValidTo).format('MM-DD-YYYY HH:mm')}</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('rollingPercent')}</strong>
            <span>{bonusDetail?.rollingPercent}%</span>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <strong style={{ minWidth: 220 }}>{t('maximum_bonus_limit')}</strong>
            <span>{formatNumber(bonusDetail?.maxBonusLimit)} ₩</span>
          </div>
          {isAddOnOddBonus
            ? (
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('addOnBonus')}</strong>
                <span>{bonusDetail?.addOnOddBonus}</span>
              </div>)
            : (
              <div className='d-flex align-items-center gap-2'>
                <strong style={{ minWidth: 220 }}>{t('bonus_percentage')}</strong>
                <span>{bonusDetail?.bonusPercent}%</span>
              </div>
              )}

        </div>
        {(isTenant || isAffiliate) && (
          <div className='my-3'>
            <BonusTabContent
              isTenant={isTenant}
              isAffiliate={isAffiliate}
              t={t}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              TABS_KEYS={TABS_KEYS}
            />
          </div>
        )}
        {(!isTenant && !isAffiliate) && (
          <BonusAdminTabContent
            t={t}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </div>

    </>
  )
}

export default SportsBookViewBonusDetail
