import { createSlice } from '@reduxjs/toolkit'

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    transactionStatData: [],
    providerDataStat: [],
    livePlayerData: [],
    walletStats: [],
    userLogginbyCountry: [],
    seniorUserStatData: [],
    sbTransactionLoading: false,
    dashboardSBTransactions: null,
    revenueSeniorData: [],
    revenueAffiliateAndAgentData: {

    },
    revenueLoading: false
  },
  reducers: {
    getRevenueSeniorDataStart: (state) => ({
      ...state,
      revenueLoading: true
    }),
    getRevenueSeniorDataSuccess: (state, { payload }) => ({
      ...state,
      revenueLoading: false,
      revenueSeniorData: payload
    }),
    getRevenueSeniorDataFailure: (state) => ({
      ...state,
      revenueLoading: false
    }),
    getRevenueAffiliateAndAgentDataStart: (state) => ({
      ...state,
      revenueLoading: true
    }),
    getRevenueAffiliateAndAgentDataSuccess: (state, { payload }) => ({
      ...state,
      revenueLoading: false,
      revenueAffiliateAndAgentData: payload
    }),
    getRevenueAffiliateAndAgentDataFailure: (state) => ({
      ...state,
      revenueLoading: false
    }),
    getSeniorUserReportStart: (state) => ({
      ...state,
      loading: true
    }),
    getSeniorUserReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      seniorUserStatData: payload
    }),
    getSeniorUserReportFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTransactionStatStart: (state) => ({
      ...state,
      loading: true
    }),
    getTransactionStatSuccess: (state, { payload }) => (
      {
        ...state,
        loading: false,
        transactionStatData: payload
      }),
    getTransactionStatFailed: (state) => ({
      ...state,
      loading: false
    }),

    getProviderStatStart: (state) => ({
      ...state,
      loading: true
    }),
    getProviderStatSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      providerDataStat: payload
    }),
    getProviderStatFailure: (state) => ({
      ...state,
      loading: false
    }),

    getLivePlayersReportStart: (state) => ({
      ...state,
      loading: true
    }),
    getLivePlayersReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      livePlayerData: payload
    }),
    getLivePlayersReportFailure: (state) => ({
      ...state,
      loading: false
    }),
    getWalletStatsStart: (state) => ({
      ...state,
      loading: true
    }),
    getWalletStatsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      walletStats: payload
    }),
    getWalletStatsFailure: (state) => ({
      ...state,
      loading: false
    }),

    getUserLoggedInCountByCountryStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserLoggedInCountByCountrySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userLogginbyCountry: payload
    }),
    getUserLoggedInCountByCountryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getDashboardSBTransactionsStart: (state) => ({
      ...state,
      sbTransactionLoading: true
    }),
    getDashboardSBTransactionsSuccess: (state, { payload }) => ({
      ...state,
      sbTransactionLoading: false,
      dashboardSBTransactions: payload
    }),
    getDashboardSBTransactionsFailure: (state) => ({
      ...state,
      sbTransactionLoading: false
    })
  }
})

export default dashboardSlice.reducer

export const {
  getSeniorUserReportStart,
  getSeniorUserReportSuccess,
  getSeniorUserReportFailure,
  getTransactionStatStart,
  getTransactionStatSuccess,
  getTransactionStatFailure,
  getProviderStatStart,
  getProviderStatSuccess,
  getProviderStatFailure,
  getLivePlayersReportStart,
  getLivePlayersReportSuccess,
  getLivePlayersReportFailure,
  getKPIReportFailure,
  getWalletStatsFailure,
  getWalletStatsSuccess,
  getWalletStatsStart,
  getUserLoggedInCountByCountryFailure,
  getUserLoggedInCountByCountrySuccess,
  getUserLoggedInCountByCountryStart,
  getDashboardSBTransactionsFailure,
  getDashboardSBTransactionsStart,
  getDashboardSBTransactionsSuccess,
  getRevenueSeniorDataStart,
  getRevenueSeniorDataSuccess,
  getRevenueSeniorDataFailure,
  getRevenueAffiliateAndAgentDataStart,
  getRevenueAffiliateAndAgentDataSuccess,
  getRevenueAffiliateAndAgentDataFailure
} = dashboardSlice.actions
