import { createSlice } from '@reduxjs/toolkit'

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {
    loading: false,
    statusLoading: false,
    voucherData: [],
    voucherUsers: [],
    voucherTransactions: []
  },
  reducers: {
    createVoucherStart: (state) => ({
      ...state,
      loading: true
    }),
    createVoucherSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createVoucherFailure: (state) => ({
      ...state,
      loading: false
    }),
    getVoucherStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      voucherData: payload
    }),
    getVoucherFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateVoucherStart: (state) => ({
      ...state,
      loading: true
    }),
    updateVoucherSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateVoucherFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateVoucherStatusStart: (state) => ({
      ...state,
      statusLoading: true
    }),
    updateVoucherStatusSuccess: (state, { payload }) => ({
      ...state,
      statusLoading: false
    }),
    updateVoucherStatusFailure: (state) => ({
      ...state,
      statusLoading: false
    }),
    getVoucherDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      voucherData: payload
    }),
    getVoucherDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    getVoucherUserStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherUserSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      voucherUsers: payload
    }),
    getVoucherUserFailure: (state) => ({
      ...state,
      loading: false
    }),
    getVoucherGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherGameSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      voucherGames: payload
    }),
    getVoucherGameFailure: (state) => ({
      ...state,
      loading: false
    }),
    getVoucherReportStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      voucherTransactions: payload
    }),
    getVoucherReportFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default voucherSlice.reducer

export const {
  createVoucherStart,
  createVoucherSuccess,
  createVoucherFailure,
  getVoucherStart,
  getVoucherSuccess,
  getVoucherFailure,
  updateVoucherStart,
  updateVoucherSuccess,
  updateVoucherFailure,
  getVoucherDetailStart,
  getVoucherDetailSuccess,
  getVoucherDetailFailure,
  getVoucherUserStart,
  getVoucherUserSuccess,
  getVoucherUserFailure,
  getVoucherGameStart,
  getVoucherGameSuccess,
  getVoucherGameFailure,
  updateVoucherStatusStart,
  updateVoucherStatusSuccess,
  updateVoucherStatusFailure,
  getVoucherReportStart,
  getVoucherReportSuccess,
  getVoucherReportFailure
} = voucherSlice.actions
