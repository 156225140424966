import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Modal,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import useEmailTemplate from './hooks/useEmailTemplate'
import Trigger from '../../../components/OverlayTrigger'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'

export default () => {
  const navigate = useNavigate()
  const {
    emailTemplates,
    loading,
    show,
    setShow,
    setModalData,
    templateData,
    isHidden,
    lang,
    setLang,
    languages,
    type
  } = useEmailTemplate()

  return (
    <>
      <Row>
        <Col>
          <h3>Email Templates</h3>
        </Col>
      </Row>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Label', 'Type', 'Action'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Object.keys(emailTemplates).map((key, index) => {
            return (
              <tr key={index}>
                <td>{emailTemplates[key]?.[0].emailTemplateId}</td>
                <td>{emailTemplates[key]?.[0].label}</td>
                <td>{key}</td>
                <td>
                  <ButtonGroup>
                    <Trigger message='Edit'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='warning'
                        hidden={isHidden({ module: { key: 'EmailTemplate', value: 'U' } })}
                        onClick={() =>
                          navigate(`/super-admin/email-templates/edit/${emailTemplates[key]?.[0].emailTemplateId}`)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Trigger>

                    <Trigger message='View Template'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        onClick={() =>
                          setModalData(key,
                            emailTemplates[key]?.[0].templateCode
                          )}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>
                </td>
              </tr>
            )
          }
          )}
        </tbody>
      </Table>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName='modal-90w'
        size='lg'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header closeButton>
          <Col>
            <h4>{type}</h4>
          </Col>
          <Col className='m-2'>
            <Form.Select
              name='language'
              size='sm'
              className='w-auto float-end'
              value={lang}
              onChange={(e) => {
                setLang(e.target.value)
              }}
            >
              <option value='EN'>English</option>
              {languages?.count && languages?.rows?.map(({ languageName, code }) => {
                return code !== 'EN' && templateData?.[code] !== undefined && (
                  <option key={code} value={code}>{languageName}</option>
                )
              })}
            </Form.Select>
          </Col>
        </Modal.Header>
        <Modal.Body>

          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(templateData?.[lang])
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
