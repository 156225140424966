import React from 'react'
import useRollingReportingTable from './hooks/useRollingContestReporting'
import PaginationComponent from '../Pagination'
import { Button, Form, Table } from '@themesberg/react-bootstrap'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import ClientFilter from '../ClientFilter'
import { rollingReportingTable } from './constant'
import moment from 'moment'
import InputDatePicker from '../FormElements/InputDatePicker/InputDatePicker'
import { returnLangName } from '../../utils/storageUtils'
const RollingContestTable = (isTenant) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    selectedClient,
    setSelectedClient,
    inputRef,
    state,
    setState,
    initialDate,
    setSearch,
    t,
    rollingReportList,
    rollingListLoading,
    totalPages
  } = useRollingReportingTable(isTenant)
  return (
    <div className='p-3'>
      <div>
        <div xs='auto' className='mb-3'>
          <h3>{t('rollingcontestReport')}</h3>
        </div>
        <div className='d-flex flex-wrap align-items-center gap-4'>
          <div className='d-flex gap-2 align-items-center'>
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0'
              }}
            >
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              autoComplete='off'
              name='search'
              type='search'
              placeholder={t('searchPlaceholder')}
              size='sm'
              style={{
                marginRight: '15px',
                width: '260px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div xs='auto' className='d-flex mt-2'>
            <InputDatePicker
              isFilter
              label={t('fromDate')}
              value={state[0]?.startDate}
              onChange={(val) => {
                setState([{ ...state[0], startDate: val }])
              }}
            />
          </div>
          <div xs='auto' className='d-flex mt-2'>
            <InputDatePicker
              isFilter
              label={t('toDate')}
              value={state[0]?.endDate}
              onChange={(val) => {
                setState([{ ...state[0], endDate: val }])
              }}
            />
          </div>
          <div className='d-flex gap-2 align-items-center'>
            {!isTenant && <ClientFilter setSelectedClient={setSelectedClient} selectedClient={selectedClient} />}
            <div className='d-flex px-3 float-end'>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  className='m-1'
                  onClick={() => {
                    setState(initialDate)
                    setSelectedClient('')
                    inputRef.current.value = ''
                    setSearch('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </div>

        </div>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {rollingReportingTable?.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={rollingListLoading ? 'cover-spin' : ''}>
          {
            !rollingListLoading && rollingReportList &&
       rollingReportList?.rows?.map(
         (item, index) => {
           return (
             <tr key={index}>
               <td>{(page - 1) * limit + index + 1}</td>
               <td>{returnLangName(item?.['competition.name']) || t('na')}</td>
               <td>{item?.['user.legalName'] || t('na')}</td>
               <td>{item?.['competition.betAmount'] + ' ₩' || t('na')}</td>
               <td>{item?.currentBetAmount + ' ₩' || t('na')}</td>
               <td>{moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || t('na')}</td>
               <td>{moment(item?.updatedAt).format('MM-DD-YYYY HH:mm') || t('na')}</td>
             </tr>
           )
         }
       )
          }
          {!rollingListLoading && rollingReportList?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!rollingListLoading && rollingReportList?.count !== 0 &&
            (
              <PaginationComponent
                page={rollingReportList?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={rollingReportList?.count}
              />
            )}
    </div>
  )
}
export default RollingContestTable
