import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { tableHeaders } from '../../pages/Super-Admin/CasinoTransactions/constants'
import Trigger from '../OverlayTrigger'
import './CasinoTransaction.scss'
import moment from 'moment'
import NoteModal from '../NoteModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { formatNumber } from '../../../src/utils/storageUtils'
const CasinoTransactionsList = ({
  t,
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  isTenant,
  loading = false,
  userDetail = false,
  over,
  setOver,
  bannedGames,
  showBannedGameAmount,
  userData,
  addNoteModal,
  setAddNoteModal,
  individualUserData,
  setIndividualUserData,
  handleSubmit,
  isAffiliate,
  isAgent
}) => {
  const isV2 = userData?.isV2User
  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(userData?.isV2User, userDetail, isAffiliate, isAgent).map((c) => (
              <th key={c}>
                {t(c)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions && !loading &&
    transactions?.rows?.map(
      ({
        casinoTransactionId,
        User,
        createdAt,
        actionType,
        amountType,
        currencyCode,
        amount,
        status,
        beforeBalance,
        afterBalance,
        nonCashAmount,
        gameMaster,
        gameProvider,
        Wallet,
        seniorNotes,
        superadminNotes,
        bonus,
        bonusDetails,
        payoutBonusDetails,
        nonWithdrawableAmount,
        withdrawableAmount,
        promotionCash,
        promotionRollDetails,
        bonusRollDetails,
        nonWithdrawableRollAmount,
        seasonalBenefit
      }, index) => {
        const rowNumber = (page - 1) * limit + index + 1

        return (
          <tr key={`casino-transaction-list ${casinoTransactionId}`}>
            <td>{rowNumber}</td>
            <td>{gameMaster?.name || 'NA'}</td>
            <td>{actionType}</td>
            <td>{casinoTransactionId}</td>
            <td>{gameMaster?.MasterGameCategory?.categoryName?.EN || 'NA'}</td>
            <td>{gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || 'NA'}</td>
            <td>{gameProvider?.providerName || 'NA'}</td>

            {isV2 && userDetail &&
            (
              <>
                <td>{formatNumber(nonWithdrawableAmount || 0)} ₩</td>
                <td>{formatNumber(withdrawableAmount || 0)} ₩</td>
                <td>{formatNumber(promotionCash || 0)} ₩</td>
                <td>
                  <div className='d-flex flex-column gap-1'>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('hotTimeBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.hotTimeBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('compensationBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.compensationBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('paybackBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.paybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('dailyPaybackBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.dailyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('weeklyPaybachBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.weeklyPaybachBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('biWeeklyPayBackBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.biWeeklyPayBackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('monthlyPaybackBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.monthlyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('rackbackBonus')}:
                      </span>
                      <b title={bonusDetails}> {formatNumber(bonusDetails?.rackbackBonus ?? 0)} ₩</b>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column gap-1'>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutHotTimeBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutHotTimeBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutCompensationBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutCompensationBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutPaybackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutPromotionCash')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutPromotionCash ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutDailyPaybackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutDailyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutWeeklyPaybackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutWeeklyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutBiWeeklyPaybackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutBiWeeklyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutMonthlyPaybackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutMonthlyPaybackBonus ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('payoutRackbackBonus')}:
                      </span>
                      <b title={payoutBonusDetails}> {formatNumber(payoutBonusDetails?.payoutRackbackBonus ?? 0)} ₩</b>
                    </div>
                  </div>
                </td>
                <td>
                  {formatNumber(nonWithdrawableRollAmount)} ₩
                </td>
                <td>
                  <div className='d-flex flex-column gap-1'>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('bonusCash')}:
                      </span>
                      <b title={bonusRollDetails}> {formatNumber(bonusRollDetails?.bonusCash ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('bonusPayout')}:
                      </span>
                      <b title={bonusRollDetails}> {formatNumber(bonusRollDetails?.bonusPayout ?? 0)} ₩</b>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column gap-1'>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('promotionCash')}:
                      </span>
                      <b title={promotionRollDetails}> {formatNumber(promotionRollDetails?.promotionCash ?? 0)} ₩</b>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <span className='flex-title'>
                        {t('promotionPayout')}:
                      </span>
                      <b title={promotionRollDetails}> {formatNumber(promotionRollDetails?.promotionPayout ?? 0)} ₩</b>
                    </div>
                  </div>
                </td>
              </>
            )}

            {/* <td>{amount === 0 ? '-' : t('cash')}</td>
            <td>{nonCashAmount === 0 ? (bonus === 0 ? '-' : t('bonus')) : '-'}</td> */}
            {!isV2 && <td>{`${formatNumber(amount + nonCashAmount + bonus)} '₩'`}</td>}
            <td>{`${formatNumber(seasonalBenefit?.toFixed(2))} ₩`}</td>
            <td>{`${formatNumber(beforeBalance?.toFixed(2))} ₩`}</td>
            <td>{`${formatNumber(afterBalance?.toFixed(2))} ₩`}</td>
            <td>{createdAt ? moment(createdAt).format(isV2 ? 'MM-DD-YYYY' : 'MM-DD-YYYY hh:mm:ss') : 'NA'}</td>
            {isV2 && <td>{createdAt ? moment(createdAt).format('HH:mm:ss') : 'NA'}</td>}

            <td>
              <Trigger message={superadminNotes}>
                <div>{superadminNotes ?? '-'}</div>
              </Trigger>
            </td>
            <td>
              <Trigger message={seniorNotes}>
                <div>{seniorNotes ?? '-'}</div>
              </Trigger>
            </td>

            {(!isAffiliate || !isAgent) && (
              <td>
                <Trigger message={t('addNote')}>
                  <Button
                    className='m-1'
                    size='sm'
                    variant='info'
                    onClick={() => {
                      setAddNoteModal(true)
                      setIndividualUserData({ casinoTransactionId })
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Trigger>
              </td>
            )}
          </tr>
        )
      }
    )}

          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={userDetail && isV2 ? 15 : userDetail ? 14 : 11} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>

      </Table>
      {
        addNoteModal
          ? <NoteModal
              addNoteModal={addNoteModal}
              setAddNoteModal={setAddNoteModal}
              individualUserData={individualUserData}
              handleSubmit={handleSubmit}
            />
          : <></>
      }
      {
        transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={transactions?.count}
          />
        )
      }
    </>
  )
}

export default CasinoTransactionsList
