import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetRestrictedCountriesStart } from '../../../store/redux-slices/fetchData'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { useTranslation } from 'react-i18next'
import { getAllSportsbookProvidersStart, sportsProviderUpdateStart } from '../../../store/redux-slices/admins'

const useProviderListing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [providerDetails, setProviderDetails] = useState(null)
  const [providerStatus, setProviderStatus] = useState(null)
  const isInitialRender = useDidMountEffect()

  const { sportsbookProviders, loading, sportsProviderUpdateLoading } = useSelector((state) => state.admins)
  const totalPages = Math.ceil(sportsbookProviders?.count / limit)

  const fetchData = () => {
    dispatch(getAllSportsbookProvidersStart({ form: { limit, pageNo: page, search } }))
  }

  useEffect(() => {
    fetchData()
    dispatch(resetRestrictedCountriesStart())
  }, [limit, page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchData()
        } else {
          setPage(1)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const updateProviderHandler = (data) => {
    const form = {
      code: 'SPORTBOOK_PROVIDER',
      masterSportProviderId: data?.masterSportProviderId,
      status: !data?.isActive
    }
    dispatch(sportsProviderUpdateStart({
      form,
      callBack: () => {
        cancelProviderUpdate()
        fetchData()
      }
    }))
  }

  const cancelProviderUpdate = () => {
    setProviderStatus(null)
    setProviderDetails(null)
    setConfirmationModalOpen(false)
  }

  return {
    limit,
    setLimit,
    page,
    setPage,
    isConfirmationModalOpen,
    setConfirmationModalOpen,
    sportsbookProviders,
    totalPages,
    loading,
    search,
    setSearch,
    t,
    providerDetails,
    setProviderDetails,
    cancelProviderUpdate,
    updateProviderHandler,
    providerStatus,
    setProviderStatus,
    sportsProviderUpdateLoading
  }
}

export default useProviderListing
