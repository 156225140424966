import React from 'react'
import SportListing from '../../../components/SportsBook/SportListing'
import SportsCountriesListing from '../../../components/SportsBook/SportsCountriesListing'
import MatchListing from '../../../components/SportsBook/MatchListing'
import MarketListing from '../../../components/SportsBook/MarketListing'
import LeagueListing from '../../../components/SportsBook/LeagueListing'
import TeamListing from '../../../components/SportsBook/TeamListing'

const Sports = (
  listingProp
) => {
  const renderComponent = (name) => {
    switch (name) {
      case 'Sports':
        return <SportListing title={name} />

      case 'Countries':
        return <SportsCountriesListing title={name} />

      case 'Matches':
        return <MatchListing title={name} />

      case 'Markets':
        return <MarketListing title={name} />

      case 'Leagues':
        return <LeagueListing title={name} />
      case 'Teams':
        return <TeamListing title={name} />

      default:
    }
  }
  return (
    <div>
      {
      renderComponent(Object.keys(listingProp)[0])
      }
    </div>
  )
}

export default Sports
