import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Tab, Table, Tabs } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import './modalStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import Trigger from '../OverlayTrigger'
import { toast } from '../Toast'
import PaginationComponent from '../Pagination'
import { formatDateYMD } from '../../utils/dateFormatter'
import CopyToClipboard from 'react-copy-to-clipboard'
import { kycMethods, notificationCategoryOptions } from './constants'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ErrorMessage, Formik } from 'formik'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { bothValidation, createNotificationValidation, updateEntityValidation } from './schema'
import AccountTransaction from '../../pages/Super-Admin/UserDetails/components/AccountTransaction'
import { Spinner } from 'react-bootstrap'
import useModalHook from './useModalHook'
import { getEntityArray, transformArray } from '../../utils/helpers'
import { formatNumber } from '../../utils/storageUtils'
import ReactJson from 'react-json-view'

export const NonCashWalletRequest = ({
  show,
  setShow,
  tenantData
}) => {
  const [selectedTab, setSelectedTab] = useState('deposit')
  const { t } = useTranslation()
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header className='justify-content-center'>
          <h5>{t('nonCashRequest')}</h5>
        </Modal.Header>
        <Modal.Body className='p-3'>
          <Tabs
            activeKey={selectedTab}
            onSelect={(tab) => setSelectedTab(tab)}
            className='nav-light justify-content-between'
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey='deposit' title={t('deposit')}>
              <div className='mt-5'>
                <AccountTransaction
                  buttonTitle={t('deposit')}
                  isDeposit
                  isForTenant
                  show
                  setShow={setShow}
                  tenantData={tenantData}
                />
              </div>
            </Tab>
            <Tab eventKey='withdrawal' title={t('withdrawal')}>
              <div className=' mt-5'>
                <AccountTransaction
                  buttonTitle={t('withdrawal')}
                  isWithdraw
                  isForTenant
                  show
                  setShow={setShow}
                  tenantData={tenantData}
                />
              </div>
            </Tab>

          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const PrettyJsonPrinter = ({
  show,
  setShow,
  data
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal show={show} size='xl' onHide={() => setShow(false)} centered>
        <Modal.Header className='justify-content-center' closeButton>
          <h5>{t('requestDetails')}</h5>
        </Modal.Header>
        <Modal.Body className='p-3' style={{ overflow: 'auto' }}>
          <ReactJson name='Request Details' src={data} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export const UpdateKycMethodModal = ({
  show,
  setShow,
  countryData,
  setCountryData,
  handleKycUpdateMethod,
  languages
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header className='justify-content-center'>
        <h5>{t('updateCountryData')}</h5>
      </Modal.Header>
      <Modal.Body>
        <Table borderless>
          <tbody>
            <tr>
              <td>
                <b>{t('countryName')}</b>
              </td>
              <td>
                {countryData?.countryName}
              </td>
            </tr>
            <tr>
              <td>
                <b>{t('kycMethod')}</b>
              </td>
              <td>
                <Form.Select
                  size='sm'
                  className='ml-2'
                  style={{ maxWidth: '230px' }}
                  value={countryData?.kycMethod}
                  onChange={(e) => {
                    setCountryData((prev) => ({ ...prev, kycMethod: e.target.value }))
                  }}
                >
                  {kycMethods?.map(
                    ({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    )
                  )}
                </Form.Select>
              </td>
            </tr>

            <tr>
              <td>
                <b>{t('language')}</b>
              </td>
              <td>
                <Form.Select
                  size='sm'
                  className='ml-2'
                  style={{ maxWidth: '230px' }}
                  value={countryData?.languageId}
                  onChange={(e) => {
                    setCountryData((prev) => ({ ...prev, languageId: e.target.value }))
                  }}
                >
                  {languages?.count > 0 &&
                    languages?.rows?.map(
                      ({ languageId, languageName }) => (
                        <option key={languageId} value={languageId}>
                          {languageName}
                        </option>
                      )
                    )}
                </Form.Select>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer className='justify-content-between'>
        <Button variant='outline-warning' onClick={() => { setShow(false) }}>
          {t('cancel')}
        </Button>

        <Button
          variant='outline-success' onClick={() => {
            handleKycUpdateMethod({ countryData })
          }}
        >
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const WalletActionConfirmModal = ({
  action,
  show,
  setShow,
  handleConfirmActionRequest,
  name
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('areYouSureActionRequest', { action: action.toLowerCase() })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleConfirmActionRequest}>
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const KycActionConfirmModal = ({
  action,
  show,
  setShow,
  handleConfirmActionRequest,
  name
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('areYouSureKycRequest', { action: action ? t('accept') : t('reject') })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleConfirmActionRequest}>
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const TransactionLimitConfirmationModal = ({
  action,
  show,
  setShow,
  handleConfirmActionRequest,
  values,
  isDeposit,
  buttonTitle
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('confirmTransactionLimit', { buttonTitle: t(buttonTitle), amount: formatNumber(values.amount), currency: values.currency })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={() => {
            handleConfirmActionRequest(values, isDeposit)
            setShow(false)
          }}
        >
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const TenantNotificationTable = ({
  show,
  setShow,
  tableDataToShow
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('notificationRecipients')}
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {['S.No', 'Name'].map((h, idx) => (
                  <th key={idx}>
                    {h} &nbsp;
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableDataToShow?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item?.name || ''}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ({
  show,
  setShow,
  handleYes,
  active,
  name,
  identifier,
  isNotification,
  isHistory
}) => {
  const { t } = useTranslation()
  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div className='fs-5'>
          {isNotification
            ? t('confirmSendNotification')
            : isHistory
              ? t('confirmReject')
              : t('confirmMarkStatus', { name: name || '', status: active ? t('inactive') : t('active') })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleYes}>
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>

  )
}

export const DeleteConfirmationModal = ({
  deleteModalShow,
  setDeleteModalShow,
  handleDeleteYes,
  name,
  loading
}) => {
  const { t } = useTranslation()
  return (
    <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('confirmDelete', { name: name || '' })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleDeleteYes}>
          {t('yes')} {loading && <Spinner className='ms-1' size='sm' />}
        </Button>
        <Button variant='outline-primary' onClick={() => setDeleteModalShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ApproveRejectModal = ({
  show,
  setShow,
  handleYes,
  status,
  name
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('confirmAction', { status: status === 'approved' ? t('approve') : t('cancelReRequested'), name: name })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary' onClick={() => {
            if (status === 'approved') { handleYes('', '') } else { handleYes('', 'cancel') }
          }}
        >
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const GalleryModal = ({
  galleryModal,
  setGalleryModal
}) => {
  const { t } = useTranslation() // Use the useTranslation hook
  const { gallery } = useSelector(state => state.emailTemplate)

  return (
    <Modal show={galleryModal} onHide={() => setGalleryModal(false)} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('gallery')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className='text-center align-items-center'>
          {gallery?.length > 0
            ? gallery?.map((img, index) => {
                return (
                  <Col key={index} md={3} className='imagecontainer'>
                    <CopyToClipboard
                      text={img?.imageUrl}
                      onCopy={() => {
                        setGalleryModal(false)
                        toast(t('copiedToClipboard'), 'success')
                      }}
                    >
                      <img
                        src={img?.imageUrl}
                        width='200'
                        height='150'
                        alt='gallery-img'
                        style={{ border: '2px solid aliceblue', borderRadius: '12px', cursor: 'pointer' }}
                        className='mb-2'
                      />
                    </CopyToClipboard>
                    <div className='text'>{img?.name}</div>
                    <Trigger message={t('copyImageURL')}>
                      <CopyToClipboard
                        text={img?.imageUrl}
                        onCopy={() => {
                          setGalleryModal(false)
                          toast(t('copiedToClipboard'), 'success')
                        }}
                      >
                        <Button
                          className='copy d-flex align-items-center'
                          variant='light'
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </Button>
                      </CopyToClipboard>
                    </Trigger>
                  </Col>
                )
              })
            : <h4 className='text-danger'>{t('noImageFound')}</h4>}
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export const DuplicateUserModal = ({
  show,
  setShow,
  limit,
  pageNo,
  setLimit,
  setPageNo
}) => {
  const { t } = useTranslation() // Use the useTranslation hook
  const { duplicateUsers, user } = useSelector(state => state.tenantUsers)
  const { userData } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(duplicateUsers?.count / limit)
  const myUser = user || userData
  const tableHeaders = [
    { label: t('userId'), value: myUser?.userId || 'NA' },
    { label: t('firstName'), value: myUser?.firstName || 'NA' },
    { label: t('lastName'), value: myUser?.lastName || 'NA' },
    { label: t('email'), value: myUser?.email || 'NA' },
    { label: t('username'), value: myUser?.username || 'NA' },
    { label: t('phone'), value: myUser?.phone || 'NA' },
    { label: t('signInIP'), value: myUser?.signInIp || 'NA' },
    { label: t('DOB'), value: myUser?.dateOfBirth ? formatDateYMD(myUser?.dateOfBirth) : 'NA' }
  ]

  return (
    <Modal show={show} onHide={() => setShow(false)} size='xl' centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-2'>{t('duplicates')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ overflow: 'auto', maxHeight: '640px' }}>
          <Table bordered size='sm' className='text-center fixTableHead'>
            <thead>
              <tr className='thead-dark fixTableHead-th'>
                {tableHeaders.map((h, idx) => (
                  <th key={`T-table_heading ${idx}`}>
                    <span>{h.label}</span><br />
                    <small>({h.value})</small>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {duplicateUsers &&
                duplicateUsers?.rows?.map(
                  ({
                    firstName,
                    lastName,
                    email,
                    username,
                    phone,
                    signInIp,
                    dateOfBirth,
                    address,
                    userId
                  }) => {
                    return (
                      <tr key={`duplicate-list ${userId}`}>
                        <td>{userId}</td>
                        <td>{firstName || 'NA'}</td>
                        <td>{lastName || 'NA'}</td>
                        <td>{email || 'NA'}</td>
                        <td>{username || 'NA'}</td>
                        <td>{phone || 'NA'}</td>
                        <td>{signInIp || 'NA'}</td>
                        <td>{dateOfBirth ? formatDateYMD(dateOfBirth) : 'NA'}</td>
                      </tr>
                    )
                  }
                )}
              {duplicateUsers?.count === 0 && (
                <tr>
                  <td colSpan={9} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {duplicateUsers?.count > 0 && (
          <PaginationComponent
            page={duplicateUsers?.count < pageNo ? setPageNo(1) : pageNo}
            totalPages={totalPages}
            setPage={setPageNo}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export const ResetConfirmationModal = ({
  show,
  setShow,
  handleYes,
  data
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <div className='fs-5'>
          {t('resetConfirmation', { data: data || '' })} {/* Translate and insert data */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => handleYes(data)}>
          {t('yes')} {/* Translate "Yes" */}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')} {/* Translate "No" */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ChangeLangConfirmationModal = ({
  show,
  setShow,
  handleYes
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('confirmTitle')}</Modal.Title> {/* Translate the title */}
      </Modal.Header>
      <Modal.Body>
        <h6>{t('discardChanges')}</h6> {/* Translate the first message */}
        <h6>{t('confirmSwitch')}</h6> {/* Translate the second message */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => handleYes()}>
          {t('yes')} {/* Translate "Yes" */}
        </Button>
        <Button variant='outline-primary' onClick={() => setShow(false)}>
          {t('no')} {/* Translate "No" */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResetUserPasswordModal = ({
  show,
  setShow,
  handleResetUserPassword
}) => {
  const [type, setType] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
    withdrawalNewPassword: 'password',
    confirmWithdrawalPassword: 'password'
  })
  const { t } = useTranslation()

  const togglePasswordVisibility = (fieldName) => {
    setType((prevState) => ({
      ...prevState,
      [fieldName]: prevState[fieldName] === 'password' ? 'text' : 'password'
    }))
  }

  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
        withdrawalNewPassword: '',
        confirmWithdrawalPassword: ''
      }}
      validateOnChange
      validateOnBlur
      validationSchema={bothValidation(t)}
      onSubmit={(values, formActions) => {
        if (values?.confirmPassword === '' && values?.withdrawalNewPassword === '') {
          toast(t('fieldRequired'), 'error')
          return
        }
        handleResetUserPassword(values, formActions)
      }}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <Form className='align-items-center mt-3'>
          <Modal show={show} onHide={() => {}}>
            <Modal.Header>
              <h4>{t('resetPassword')}</h4>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Label>
                    {t('newPasswordLabel')} <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='newPassword'
                      autoComplete='new-password'
                      type={type.newPassword}
                      placeholder={t('enterPassword')}
                      value={values?.newPassword}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type.newPassword === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('newPassword')} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('newPassword')} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage component='div' name='newPassword' className='text-danger' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    {t('confirmPasswordLabel')} <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='confirmPassword'
                      type={type.confirmPassword}
                      placeholder={t('enterPassword')}
                      value={values?.confirmPassword}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type.confirmPassword === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('confirmPassword')} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('confirmPassword')} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage component='div' name='confirmPassword' className='text-danger' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    {t('newWithdrawalPasswordLabel')} <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='withdrawalNewPassword'
                      autoComplete='new-password'
                      type={type.withdrawalNewPassword}
                      placeholder={t('enterPassword')}
                      value={values?.withdrawalNewPassword}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type.withdrawalNewPassword === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('withdrawalNewPassword')} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('withdrawalNewPassword')} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage component='div' name='withdrawalNewPassword' className='text-danger' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    {t('confirmWithdrawalPasswordLabel')} <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='confirmWithdrawalPassword'
                      type={type.confirmWithdrawalPassword}
                      placeholder={t('enterPassword')}
                      value={values?.confirmWithdrawalPassword}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type.confirmWithdrawalPassword === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('confirmWithdrawalPassword')} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => togglePasswordVisibility('confirmWithdrawalPassword')} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage component='div' name='confirmWithdrawalPassword' className='text-danger' />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button className='m-2' variant='outline-warning' onClick={() => setShow(false)}>
                {t('cancel')}
              </Button>
              <Button className='m-2' variant='outline-success' style={{ width: '75px' }} onClick={handleSubmit}>
                {t('submit')}
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export const CreateNotificationModal = ({
  show,
  setShow,
  handleCreateNotification,
  tenant,
  isTenant = false,
  data,
  setData
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  const option = tenant?.rows?.map((item) => ({
    label: item?.firstName + ' ' + item?.lastName,
    value: item?.adminUserId
  }))

  return (
    <Formik
      initialValues={{
        referenceId: [],
        notificationMessage: '',
        referenceType: null
      }}
      validationSchema={createNotificationValidation(isTenant, t)}
      onSubmit={(formValues, { resetForm }) => {
        handleCreateNotification(formValues)
        resetForm()
        setShow(false)
        setData([])
      }}
    >
      {({ values, error, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <Form className='align-items-center mt-3'>
            <Modal show={show} centered>
              <Modal.Header>
                <h4>{t('createNotification')}</h4> {/* Translate the modal title */}
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Label column='sm'>
                      {t('notificationCategory')} <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Select
                      name='referenceType'
                      value={values.referenceType}
                      onChange={handleChange}
                    >
                      <option value=''>{t('selectNotificationCategory')}</option> {/* Translate the placeholder */}
                      {notificationCategoryOptions?.map((requestAction) => (
                        <option key={requestAction.value} value={requestAction.value}>
                          {requestAction?.label}
                        </option>
                      ))}
                    </Form.Select>
                    <ErrorMessage
                      component='div'
                      name='referenceType'
                      className='text-danger'
                    />
                    {!isTenant && (
                      <>
                        <Form.Label className='flex-shrink-0'>
                          {t('selectTenant')} <span className='text-danger'>*</span>
                        </Form.Label>
                        <Select
                          isMulti
                          isClearable
                          name='referenceId'
                          value={data}
                          options={option}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          onChange={(option, e) => {
                            setData(option)
                            setFieldValue(
                              'referenceId',
                              option.map((o) => o.value)
                            )
                          }}
                        />
                        <ErrorMessage
                          component='div'
                          name='referenceId'
                          className='text-danger'
                        />
                      </>
                    )}
                    <Form.Label>
                      {t('notificationMessage')} <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      name='notificationMessage'
                      placeholder={t('messagePlaceholder')}
                      value={values.notificationMessage}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component='div'
                      name='notificationMessage'
                      className='text-danger'
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className='m-2'
                  variant='outline-warning'
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  {t('cancel')} {/* Translate "Cancel" */}
                </Button>
                <Button
                  className='m-2'
                  variant='outline-success'
                  style={{ width: '75px' }}
                  onClick={handleSubmit}
                >
                  {t('submit')} {/* Translate "Submit" */}
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export const ChangeInspectionStatus = ({
  show,
  setShow,
  onClick
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <h4 className='text-center'>{t('areYouSureChangeInspectionStatus')}</h4> {/* Translate the title */}
      </Modal.Header>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          {t('no')} {/* Translate "No" */}
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={onClick}
        >
          {t('yes')} {/* Translate "Yes" */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export const ResetPasswordEmailModal = ({
  show,
  setShow,
  userData,
  handleResetPasswordEmail
}) => {
  const { t } = useTranslation() // Use the useTranslation hook

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <h4>{t('sendPasswordResetEmail', { firstName: userData?.firstName, lastName: userData?.lastName, email: userData?.email })}</h4>
      </Modal.Header>
      <Modal.Body>
        <p>{t('confirmResetEmail', { firstName: userData?.firstName, lastName: userData?.lastName, email: userData?.email })}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          {t('no')} {/* Translate "No" */}
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleResetPasswordEmail}
        >
          {t('yes')} {/* Translate "Yes" */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export const UpdateEntitiyForm = ({
  show,
  setShow,
  entityType,
  user,
  t,
  isTenant,
  setFormValue,
  setIsConfirm
}) => {
  const {
    affiliateAndAgentsList
  } = useModalHook({ selectedClient: user?.tenantId, isTenant })

  const entityArray = getEntityArray(user, affiliateAndAgentsList, entityType)
  const transformedArray = transformArray(entityArray)
  return (
    <Formik
      initialValues={{
        entityId: '0'
      }}
      validationSchema={updateEntityValidation(isTenant, t)}
      onSubmit={(formValues, { resetForm }) => {
        setFormValue({ ...formValues, resetForm })
        setIsConfirm(true)
      }}
    >
      {({
        values,
        error,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => {
        return (
          <Form className='align-items-center mt-3'>
            <Modal show={show} centered>
              <Modal.Header>
                <h4>{entityType === 'Affiliate' ? t('updatePlayerAffilate') : t('updatePlayerAgent')}</h4>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Label column='sm'>
                      {t('select' + entityType)}
                    </Form.Label>
                    <Form.Select
                      name='entityId'
                      value={values.entityId}
                      onChange={handleChange}
                    >
                      <option value='0'>{t('select' + entityType)}</option>
                      {transformedArray?.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item?.label}
                        </option>
                      ))}
                    </Form.Select>
                    <ErrorMessage
                      component='div'
                      name='referenceType'
                      className='text-danger'
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <div>{t('note')}{t('ifYouDontSelectReason')}</div>
                <Button
                  className='m-2'
                  variant='outline-warning'
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  className='m-2'
                  variant='outline-success'
                  style={{ width: '75px' }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}
export const ConfirmOwnership = ({
  setIsConfirm,
  isConfirm,
  handleUpdateEntity,
  t
}) => {
  return (
    <Modal centered show={isConfirm} onHide={() => setIsConfirm(false)}>
      <Modal.Body>
        <div className='fs-5'>
          {t('confirmOwnership')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleUpdateEntity}>
          {t('yes')}
        </Button>
        <Button variant='outline-primary' onClick={() => setIsConfirm(false)}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>

  )
}

export const SportsProviderConfirmationModal = ({
  setOpenClose,
  isOpen,
  handleYes,
  t,
  handleNo,
  providerDetails,
  loading
}) => {
  return (
    <Modal centered show={isOpen} onHide={() => setOpenClose(false)}>
      <Modal.Body>
        <div className='fs-5'>
          {t('confirmMarkStatus', { name: providerDetails?.name ?? '', status: providerDetails?.isActive ? t('inactive') : t('active') })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' disabled={loading} onClick={() => handleYes(providerDetails)}>
          {t('yes')}
          {loading &&
            <Spinner
              style={{ marginLeft: '5px' }}
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />}
        </Button>
        <Button variant='outline-primary' onClick={handleNo}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>

  )
}
