import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {
  Spinner,
  Col,
  Row,
  Form as BForm,
  Container,
  InputGroup
} from '@themesberg/react-bootstrap'

import BgImage from '../../../assets/img/illustrations/signin.svg'
import { superAdminAuthenticate, superAdminLoginSchema } from './schema'
import useSuperAdminSignin from './useSuperAdminSignin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Otpinput from '../../../utils/OtpInput'
import { Button } from 'react-bootstrap'
import SignAsSelect from '../../../components/SignInAsSelect'

export default () => {
  const {
    loading,
    showPassword,
    setShowPassword,
    handleSignIn,
    twoStepsEnabled,
    loginData,
    t
  } = useSuperAdminSignin()

  return (
    <main>
      <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
        <Container>
          <Row
            className='justify-content-center form-bg-image'
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className='d-flex align-items-center justify-content-center'
            >
              <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>{!twoStepsEnabled ? t('signInAsSuperAdmin') : 'Two-Factor Authentication'}</h3>
                </div>
                <Formik
                  validateOnBlur={false}
                  initialValues={{ user: loginData.user, password: loginData.password }}
                  validationSchema={!twoStepsEnabled ? superAdminLoginSchema : superAdminAuthenticate}
                  onSubmit={(data) => {
                    handleSignIn({ ...data })
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    setErrors,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue
                  }) => (
                    <div>
                      <Form>
                        {!twoStepsEnabled
                          ? (

                            <>
                              <div className='form-group'>
                                <label
                                  htmlFor='user'
                                  className={
                              touched.user && errors.user ? 'text-danger' : ''
                            }
                                >
                                  {t('emailOrUsername')}
                                </label>

                                <InputGroup
                                  className={
                              touched.user && errors.user
                                ? 'border border-danger'
                                : ''
                            }
                                >
                                  <InputGroup.Text>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </InputGroup.Text>

                                  <BForm.Control
                                    name='user'
                                    autoFocus
                                    required
                                    type='text'
                                    placeholder='example@company.com'
                                    value={values.user}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </InputGroup>

                                <ErrorMessage
                                  component='div'
                                  name='user'
                                  className='text-danger'
                                />
                              </div>

                              <div className='form-group mb-4'>
                                <label
                                  htmlFor='password'
                                  className={`mt-3 ${
                              touched.password && errors.password
                                ? 'text-danger'
                                : ''
                            }`}
                                >
                                  {t('password')}
                                </label>

                                <InputGroup
                                  className={
                              touched.password && errors.password
                                ? 'border border-danger'
                                : ''
                            }
                                >
                                  <InputGroup.Text>
                                    <FontAwesomeIcon icon={faUnlockAlt} />
                                  </InputGroup.Text>

                                  <BForm.Control
                                    name='password'
                                    required
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='qwerty'
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />

                                  <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                                    {showPassword
                                      ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => !prev) }} />
                                      : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => !prev) }} />}
                                  </InputGroup.Text>

                                </InputGroup>

                                <ErrorMessage
                                  component='div'
                                  name='password'
                                  className='text-danger'
                                />
                              </div>
                            </>
                            )
                          : (
                            <div className='mb-5'>
                              <h6 className='text-center mb-4'>
                                Open the two-step verification app on your mobile device to get your verification code.
                              </h6>
                              <Otpinput onChange={(value) => {
                                setFieldValue('token', value)
                              }}
                              />
                              <ErrorMessage
                                component='div'
                                name='token'
                                className='text-danger'
                              />
                            </div>
                            )}
                        <Row>
                          <SignAsSelect />
                          <Button
                            type='submit'
                            className='mt-1'
                            onClick={handleSubmit}
                          >
                            {loading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                            {t('SignIn')}
                          </Button>

                        </Row>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
