import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'

import { createCmsSchema } from '../schema'
import useCreateCms from '../hooks/useCreateCms'
import { GalleryModal } from '../../../../components/ConfirmationModal'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import EditCMSTemplate from '../../../../components/EditCMSTemplate'
import { languageCode } from '../../TenantConfigurations/Languages/constants'

const CreateCms = ({
  cmsDetails,
  details = false
}) => {
  const {
    navigate,
    createCms,
    updateCms,
    setTemplate,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages,
    deleteCms,
    t
  } = useCreateCms()

  const { isHidden } = useCheckPermission()

  return (
    <div className='px-3'>
      <Row>
        <Col sm={8}>
          <h3>{cmsDetails ? `${!details ? t('edit') : ''} ${t('cms')}: ${cmsDetails?.title?.EN}` : t('createCmsPage')}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Trigger message={t('gallery')}>
            <Button
              hidden={details || isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
              onClick={() => {
                getGalleryData()
                setGalleryModal(true)
              }}
              variant='outline-secondary'
            >
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Formik
        initialValues={{
          title: cmsDetails?.title?.EN || '',
          slug: cmsDetails?.slug || '',
          content: cmsDetails?.content?.EN || '',
          category: cmsDetails?.category || 1
        }}
        validationSchema={createCmsSchema}
        onSubmit={(formValues) => {
          cmsDetails
            ? updateCms({
                cmsData: {
                  ...formValues,
                  cmsPageId: cmsDetails?.cmsPageId,
                  isActive: cmsDetails?.status
                },
                navigate
              })
            : createCms({ cmsData: formValues, navigate })
        }}
      >
        {({ values, errors, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>
                  {t('slug')} <span className='text-danger'>*</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='slug'
                  disabled={details || cmsDetails}
                  placeholder={t('enterSlug')}
                  value={values.slug}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='slug'
                  className='text-danger'
                />
              </Col>
              <Col>
                <BForm.Label>
                  {t('category')} <span className='text-danger'>*</span>
                </BForm.Label>

                <BForm.Select
                  type='text'
                  name='category'
                  style={{ maxWidth: '200px' }}
                  disabled={details}
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={1}>{t('supportLinks')}</option>
                  <option value={2}>{t('otherLinks')}</option>
                  <option value={3}>{t('landingPage')}</option>
                </BForm.Select>
              </Col>
            </Row>
            <Row className='mt-3 d-flex'>
              <Col>

                <Tabs
                  activeKey={selectedTab}
                  onSelect={(tab) => setSelectedTab(tab)}
                  className='nav-light mt-3'
                >
                  <Tab
                    eventKey='EN'
                    title={
                      <Trigger message={t('english')}>
                        <span>EN</span>
                      </Trigger>
                    }
                    mountOnEnter
                    tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                  >
                    <div className='mt-5'>
                      <EditCMSTemplate
                        t={t}
                        values={cmsDetails}
                        cmsKeys={cmsKeys}
                        setFieldValue={setFieldValue}
                        isHidden={isHidden}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setTemp={setTemplate}
                        handleSubmit={handleSubmit}
                        navigate={navigate}
                        deleteCms={deleteCms}
                        details={details}
                        isTenant
                        initValues={values}
                        errors={errors}
                      />
                    </div>
                  </Tab>
                  {details
                    ? languages?.length && languages?.map((code) => {
                        return code !== 'EN' && cmsDetails?.content?.[code] !== undefined && (
                          <Tab
                            eventKey={code}
                            title={
                              <Trigger message={languageCode[code]}>
                                <span>{code}</span>
                              </Trigger>
                          }
                            key={code}
                            mountOnEnter
                            disabled={(!cmsDetails && cmsDetails?.content.EN === undefined)}
                            tabClassName={selectedTab !== code ? cmsDetails?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                          >
                            <div className='mt-5'>
                              <EditCMSTemplate
                                t={t}
                                values={cmsDetails}
                                cmsKeys={cmsKeys}
                                setFieldValue={setFieldValue}
                                isHidden={isHidden}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setTemp={setTemplate}
                                navigate={navigate}
                                handleSubmit={handleSubmit}
                                deleteCms={deleteCms}
                                details={details}
                                isTenant
                                initValues={values}
                                errors={errors}
                              />
                            </div>
                          </Tab>
                        )
                      })
                    : languages?.length && languages?.map((code) => {
                      return code !== 'EN' && (
                        <Tab
                          eventKey={code}
                          title={
                            <Trigger message={languageCode[code]}>
                              <span>{code}</span>
                            </Trigger>
                          }
                          key={code}
                          mountOnEnter
                          disabled={(!cmsDetails && cmsDetails?.content.EN === undefined)}
                          tabClassName={selectedTab !== code ? cmsDetails?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                        >
                          <div className='mt-5'>
                            <EditCMSTemplate
                              values={cmsDetails}
                              cmsKeys={cmsKeys}
                              setFieldValue={setFieldValue}
                              isHidden={isHidden}
                              selectedTab={selectedTab}
                              setSelectedTab={setSelectedTab}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setTemp={setTemplate}
                              navigate={navigate}
                              handleSubmit={handleSubmit}
                              deleteCms={deleteCms}
                              details={details}
                              isTenant
                              initValues={values}
                              errors={errors}
                            />
                          </div>
                        </Tab>
                      )
                    })}
                </Tabs>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
        />}
    </div>
  )
}

export default CreateCms
