import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteEventStart, getAllEventsStart, updateEventStatusStart } from '../../../../store/redux-slices/superAdminSettings'
import { debounce } from 'lodash'
export const tableHeaders = ['sNo', 'name', 'betAmount', 'banner', 'startDate', 'endDate', 'status', 'action']
const useEvents = ({ isTenant }) => {
  const searchRef = useRef()
  const dateStartRef = useRef()
  const dateEndRef = useRef()
  const dispatch = useDispatch()
  const { eventsList, eventsListLoading } = useSelector(state => state.superAdminSettings)
  const { tenantDetails } = useSelector(state => state.login)
  const [isCreateRollingModalOpen, setIsCreateRollingModalOpen] = useState(false)
  const [eventData, setEventData] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState('')
  const [isActive, setIsActive] = useState('')
  const [refetch, setRefetch] = useState(false)
  const [limit, setLimit] = useState(15)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [search, setSearch] = useState('')
  const [pageNo, setPageNo] = useState(1)
  const [eventDeleteData, setEventDeleteData] = useState(null)
  const [statusData, setStatusData] = useState(null)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const getAllEventsList = () => {
    dispatch(getAllEventsStart({
      formData: {
        isTenant: isTenant,
        tenantId: isTenant
          ? tenantDetails?.tenantId
          : selectedClient,
        limit,
        pageNo,
        isActive: isActive,
        search: search,
        from: startDate ? new Date(startDate).toISOString() : '',
        to: endDate ? new Date(endDate).toISOString() : ''
      }
    }))
  }

  const handleDeleteEvent = () => {
    dispatch(deleteEventStart({
      formData: eventDeleteData,
      handleClose: () => {
        setRefetch(prev => !prev)
        setIsDeleteModalOpen(false)
      }
    }))
  }
  const handleUpdateStatus = () => {
    dispatch(updateEventStatusStart({
      formData: statusData,
      handleClose: () => {
        setRefetch(prev => !prev)
        setIsStatusModalOpen(false)
      }
    }))
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setSearch(event.target.value)
  }
  useEffect(() => {
    getAllEventsList()
  }, [refetch, selectedClient, isActive, limit, pageNo, search, startDate, endDate])

  return {
    t,
    selectedClient,
    setSelectedClient,
    navigate,
    isCreateRollingModalOpen,
    setIsCreateRollingModalOpen,
    eventData,
    setEventData,
    isActive,
    setIsActive,
    setRefetch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    handleDeleteEvent,
    setEventDeleteData,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setStatusData,
    isStatusModalOpen,
    setIsStatusModalOpen,
    handleUpdateStatus,
    debounceFn,
    setStartDate,
    setEndDate,
    searchRef,
    dateStartRef,
    dateEndRef,
    setSearch,
    endDate,
    startDate,
    tenantDetails,
    eventsList,
    eventsListLoading
  }
}

export default useEvents
