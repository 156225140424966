import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllBonusStart } from '../../store/redux-slices/admins'
import { getAllTABonusStart, issueBonusStart } from '../../store/redux-slices/bonus'
import { getAllCasinoGamesStart } from '../../store/redux-slices/superAdminCasinoManagement'
import { getAllMasterGamesStart } from '../../store/redux-slices/tenantCasino'
import { safeStringify } from '../../utils/helpers'
import { useDidMountEffect } from '../../utils/useDidMountEffect'

const useFreeSpinBonusModal = ({ isTenant, countryCode, userData }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { masterGames, loading: tenantLoading } = useSelector(state => state.tenantCasino)
  const { casinoGamesData, loading: superAdminLoading } = useSelector(state => state.superAdminCasino)
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const [gameIds, setGameIds] = useState([])
  const [gamesData, setGamesData] = useState([])
  const { bonusList } = useSelector((state) => isTenant ? state.bonus : state.admins)

  const getTAGames = () => {
    dispatch(
      getAllMasterGamesStart({
        limit: '',
        pageNo: '',
        search,
        casinoCategoryId: '',
        providerId: '',
        freespins: true,
        countryCode
      })
    )
  }

  const getSAGames = () => {
    dispatch(
      getAllCasinoGamesStart({
        limit: '',
        pageNo: '',
        casinoCategoryId: '',
        search,
        isActive: true,
        tenantId: '',
        selectedProvider: '',
        freespins: true,
        bonusId: '',
        countryCode,
        portalId: userData?.tenantId || ''
      })
    )
  }

  useEffect(() => {
    if (isTenant) {
      getTAGames()
      dispatch(getAllTABonusStart({
        limit: '',
        pageNo: '',
        search: '',
        bonusType: safeStringify(['freespins']),
        isActive: '',
        userId
      }))
    } else {
      getSAGames()
      dispatch(getAllBonusStart({
        adminId: '',
        tenantId: '',
        limit: '',
        pageNo: '',
        search: '',
        bonusType: safeStringify(['freespins']),
        isActive: '',
        userId
      }))
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        isTenant
          ? getTAGames()
          : getSAGames()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const handleSelect = (e, id) => {
    if (!e.target.checked) {
      setGameIds(gameIds.filter(gameId => gameId !== id))
    } else {
      const data = [...gameIds]
      data.unshift(id)
      setGameIds(data)
    }
  }

  const availableGames = casinoGamesData || masterGames
  const getData = (type, gameId) => {
    const data = gamesData?.find((game) => game.masterCasinoGameId === gameId)
    if (type === 'name') {
      return data?.name
    } else {
      return data?.masterCasinoGameId
    }
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const data = [...gameIds]
      for (const index in availableGames) {
        const id = availableGames?.[index]?.masterCasinoGameId
        !data.includes(id) && data.push(id)
      }
      setGameIds(data)
    } else {
      let data = [...gameIds]
      for (const index in availableGames) {
        const gameId = availableGames?.[index]?.masterCasinoGameId
        data = data.filter(id => id !== gameId)
      }
      setGameIds(data)
    }
  }

  const bonusOptions = []
  bonusList?.length > 0 &&
    bonusList.forEach(function (element) {
      bonusOptions.push({ label: `${element.promotionTitle?.EN} (${element.bonusId})`, value: element.bonusId })
    })

  const submitData = (formValues) => {
    dispatch(issueBonusStart({
      data: {
        ...formValues,
        gameIds,
        userId: parseInt(userId)
      },
      isTenant
    }))
  }

  return {
    masterGames,
    casinoGamesData,
    search,
    setSearch,
    bonusList,
    handleSelect,
    handleSelectAll,
    gameIds,
    setGameIds,
    availableGames,
    getData,
    bonusOptions,
    submitData,
    gamesData,
    setGamesData,
    loading: isTenant ? tenantLoading : superAdminLoading
  }
}

export default useFreeSpinBonusModal
