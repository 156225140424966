import React from 'react'
import useEvents from './hooks/useEvents'
import Preloader from '../../../components/Preloader'
import { Button, Form, Table } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose, faEdit, faEye, faRedoAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import PaginationComponent from '../../../components/Pagination'
import CreateAndEditEvent from './components/CreateAndEditEvent'
import ChangeStatusModal from '../../../common/Modals/ChangeStatusModal'
import { eventHeader } from './constant'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import DeleteModal from '../../../common/Modals/DeleteModal'
import { returnLangName } from '../../../utils/storageUtils'
import useIsGamma from '../../../common/hook/useIsGamma'

const Events = ({ isTenant }) => {
  const {
    eventsList,
    eventsListLoading,
    t,
    selectedClient,
    setSelectedClient,
    isCreateRollingModalOpen,
    setIsCreateRollingModalOpen,
    eventData,
    setEventData,
    isActive,
    setIsActive,
    setRefetch,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    handleDeleteEvent,
    setEventDeleteData,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setStatusData,
    isStatusModalOpen,
    setIsStatusModalOpen,
    handleUpdateStatus,
    debounceFn,
    setStartDate,
    setEndDate,
    searchRef,
    setSearch,
    startDate,
    endDate,
    tenantDetails,
    navigate
  } = useEvents({ isTenant })
  const {
    isGamma
  } = useIsGamma()
  return (
    <>
      {eventsListLoading && <Preloader />}

      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
          <h3>{t('event')}</h3>
          {!isTenant && !isGamma && (
            <Button
              variant='outline-success' onClick={() => {
                setEventData(null)
                setIsCreateRollingModalOpen(true)
              }}
            >
              {t('create')}
            </Button>
          )}
        </div>
        <div className='mb-2 d-flex align-items-center flex-wrap gap-2'>
          <div className='d-flex align-self-center align-items-center mt-2'>
            {!isTenant && (
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
              />
            )}
          </div>
          <div className='d-flex gap-2  align-items-center mt-2 '>
            <Form.Label
              column='sm'
              style={{
                marginRight: '15px',
                fontSize: '13px'
              }}
            >
              {t('status')}
            </Form.Label>
            <Form.Select
              onChange={(e) => { setIsActive(e.target.value) }}
              value={isActive}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              <option value='true'>{t('active')}</option>
              <option value='false'>{t('inActive')}</option>
            </Form.Select>
          </div>
          <div className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              ref={searchRef}
              type='search'
              placeholder={t('search')}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPageNo(1)
              }}
            />
          </div>

          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={startDate}
              onChange={(val) => {
                setStartDate(val)
                setPageNo(1)
              }}
            />
          </div>
          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={endDate}
              onChange={(val) => {
                setEndDate(val)
                setPageNo(1)
              }}
            />
          </div>
          <div className='d-flex mt-2'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='align-self-center'
                size='sm'
                onClick={() => {
                  setSearch('')
                  setSelectedClient('')
                  setPageNo(1)
                  setStartDate('')
                  setEndDate('')
                  setIsActive('')
                  searchRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </div>

        </div>
        <div className='list'>
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {eventHeader?.map((h, idx) => (
                  <th key={idx}>
                    {t(h)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>

              {eventsList?.count > 0 && !eventsListLoading
                ? (
                    eventsList?.rows?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td> {(index + 1) + (limit * (pageNo - 1))}</td>
                          <td>{returnLangName(item?.name)}</td>
                          <td>{item?.eventDescriptions}</td>
                          <td>
                            <img style={{ width: 60, height: 60, objectFit: 'contain' }} src={item?.thumbUrl} alt='' />
                          </td>
                          <td>{format(new Date(item?.validFrom), 'MM-dd-yyyy HH:mm')}</td>
                          <td>{format(new Date(item?.validTo), 'MM-dd-yyyy HH:mm')}</td>
                          <td className={item?.isActive === true ? 'table-success' : 'table-danger'}>{item?.isActive === false ? t('inActive') : t('active')}</td>
                          <td>
                            <Trigger message={t('view')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() => {
                                  isTenant ? navigate(TenantRoutes.RollingDetails, { state: item }) : navigate(SuperAdminRoutes.RollingDetails, { state: item })
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Trigger>
                            {!isTenant && !isGamma && (
                              <>
                                <Trigger message={t('edit')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='info'
                                    disabled={item?.isActive === true}
                                    onClick={() => {
                                      setEventData(item)
                                      setIsCreateRollingModalOpen(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Trigger>
                                <Trigger message={t('delete')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => {
                                      setEventDeleteData(
                                        {
                                          competitionId: item?.competitionId,
                                          tenantId: item?.tenantId
                                        }

                                      )
                                      setIsDeleteModalOpen(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Trigger>
                                <Trigger message={item?.isActive === false ? t('makeActive') : t('makeInActive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant={item?.isActive === true ? 'success' : 'danger'}
                                    onClick={() => {
                                      setStatusData({
                                        isActive: item?.isActive === false,
                                        competitionId: item?.competitionId,
                                        tenantId: item?.tenantId

                                      })
                                      setIsStatusModalOpen(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={item?.isActive === true ? faCheck : faClose} />
                                  </Button>
                                </Trigger>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  )
                : (
                  <tr>
                    <td colSpan={8} className='text-danger text-center'>
                      {t('noDataFound')}
                    </td>
                  </tr>
                  )}

            </tbody>
          </Table>
          {eventsList?.count > 0 && !eventsListLoading && (
            <PaginationComponent
              page={eventsList?.count < pageNo ? setPageNo(1) : pageNo}
              totalPages={Math.ceil(eventsList?.count / limit)}
              setPage={setPageNo}
              limit={limit}
              setLimit={setLimit}
              totalCount={eventsList?.count}
            />
          )}
        </div>
      </div>
      {isCreateRollingModalOpen
        ? <CreateAndEditEvent
            isTenant={isTenant}
            tenantDetails={tenantDetails}
            type={eventData ? 'edit' : 'create'}
            eventData={eventData}
            setRefetch={setRefetch}
            show={isCreateRollingModalOpen}
            handleClose={() => {
              setIsCreateRollingModalOpen(false)
            }}
          />
        : null}
      <DeleteModal
        show={isDeleteModalOpen}
        handleClose={() => {
          setIsDeleteModalOpen(false)
          setEventDeleteData(null)
        }}
        handleDeleteYes={() => {
          handleDeleteEvent()
        }}
      />
      <ChangeStatusModal
        show={isStatusModalOpen}
        handleClose={() => {
          setIsStatusModalOpen(false)
          setStatusData(null)
        }}
        handleUpdateYes={() => {
          handleUpdateStatus()
        }}
      />
    </>
  )
}

export default Events
