import * as Yup from 'yup'
import { BonusVal } from './constant'
import moment from 'moment'
export const createBonusSchema = (t) => {
  return Yup.object().shape({
    bonusType: Yup.string().required(t('fieldRequired')),
    bonusValidFrom: Yup.date()
      .required(t('fieldRequired'))
      .transform((currentValue, originalValue) => moment(originalValue, 'YYYY-MM-DDTHH:mm').toDate())
      .typeError(t('mustbeValidDate')),
    bonusValidTo: Yup.date()
      .required(t('fieldRequired'))
      .min(Yup.ref('bonusValidFrom'), t('validToLater'))
      .transform((currentValue, originalValue) => moment(originalValue, 'YYYY-MM-DDTHH:mm').toDate())
      .typeError(t('mustbeValidDate')),
    currencyCode: Yup.string().required(t('fieldRequired')),
    addOnOddBonus: Yup.number().when('bonusType', {
      is: (val) => val === BonusVal.winningBonus,
      then: Yup.number()
        .positive(t('mustBePositive'))
        .min(0, t('minimumValue0'))
        .max(100, t('maximumValue100'))
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required(t('fieldRequired')),
      otherwise: Yup.number().nullable()
    }),
    bonusPercent: Yup.number().when('bonusType', {
      is: (val) => val !== BonusVal.winningBonus,
      then: Yup.number()
        .positive(t('mustBePositive'))
        .min(0, t('minimumValue0'))
        .max(100, t('maximumValue100'))
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required(t('fieldRequired')),
      otherwise: Yup.number().nullable()
    }),
    rollingPercent: Yup.number()
      .positive(t('mustBePositive'))
      .min(0, t('minimumValue0'))
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required(t('fieldRequired')),
    maxBonusLimit: Yup.number()
      .positive(t('mustBePositive'))
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required(t('fieldRequired')),
    adminId: Yup.number().transform((value) => Number.isNaN(value) ? null : value).required(t('fieldRequired')),
    tenantId: Yup.number().transform((value) => Number.isNaN(value) ? null : value).required(t('fieldRequired')),
    file: Yup.mixed().required(t('fileRequired'))
      .test('fileFormat', t('imageFormatRequired'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/svg+xml'].includes(value.type)
      })
      .test('fileSize', t('sizeTooLarge'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return value.size <= 10485760 // 10MB limit
      })
  })
}

export const updateLimitSchema = (t, data) => {
  return Yup.object().shape({
    bonusType: Yup.string().required(t('fieldRequired')),
    addOnOddBonus: Yup.number().when('bonusType', {
      is: (val) => val === BonusVal.winningBonus,
      then: Yup.number()
        .positive(t('mustBePositive'))
        .min(0, t('minimumValue0'))
        .max(100, t('maximumValue100'))
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required(t('fieldRequired')),
      otherwise: Yup.number().nullable()
    }),
    maxBonusLimit: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired'))
      .default(1)
      .max(data?.maxBonusLimit || 0, `${t('maxValueIs')} ${data?.maxBonusLimit}`),
    bonusPercent: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value)
      .when('bonusType', {
        is: (val) => val !== BonusVal.winningBonus,
        then: Yup.number()
          .transform((value) => Number.isNaN(value) ? null : value)
          .required(t('fieldRequired'))
          .default(1)
          .positive().min(0, t('minimumValue0'))
          .max(data?.bonusPercent || 0, `${t('maxValueIs')} ${data?.bonusPercent}`)
      })
  })
}
