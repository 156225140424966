import * as Yup from 'yup'

export const tagSchema = (t) => {
  Yup.object().shape({
    tags: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .matches(/^[A-Za-z0-9_]*$/, t('onlyAlphabetsNumbersAndUnderscore'))
            .nullable()
        })
      ).nullable()
  })
}

export const depositSchema = (amount, isWithdraw, t, isV2User) => {
  return Yup.object().shape({
    amount: Yup
      .number()
      .positive(t('amountPositive'))
      .min('1', t('amountMin'))
      .required(t('amountRequired'))
      .test('check-amount', t('amountCheck'), value => {
        return isWithdraw ? value <= amount : true
      }),
    currency: Yup
      .string()
      .required(t('currencyRequired')),
    actionSource: isV2User
      ? Yup
          .string()
          .required(t('walletTypeRequired'))
      : Yup
        .string()
        .notRequired(),
    rollingPercentage: isV2User && !isWithdraw
      ? Yup
          .number()
          .max(999, t('maximumValue999'))
          .min(1, t('minimumValue0'))
          .required(t('rollingPercentageRequired'))
      : Yup
        .number()
        .notRequired()
  })
}
export const depositTenantSchema = (amount, isWithdraw, t, isV2User) => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(t('amountRequired'))
      .positive(t('amountPositive'))
      .test('check-amount', t('amountCheck'), value => {
        return isWithdraw ? value <= amount : true
      })
      .min(1, t('minDigits', { label: t('amount'), minNumber: 1 })),
    currency: Yup
      .string()
      .required(t('currencyRequired')),
    actionSource: isV2User
      ? Yup
          .string()
          .required(t('walletTypeRequired'))
      : Yup
        .string()
        .notRequired(),
    rollingPercentage: isV2User && !isWithdraw
      ? Yup
          .number()
          .max(999, t('maximumValue999'))
          .required(t('rollingPercentageRequired'))
      : Yup
        .number()
        .notRequired()
  })
}
export const editKycSchema = (t) => Yup.object().shape({
  phone: Yup
    .string()
    .min(5, t('min5Numbers'))
    .max(16, t('max16Numbers'))
    .matches(/^\d{5,16}$/, t('enterValidPhone'))
    .required(t('phoneRequired')),
  accountNumber:
    Yup.string()
      .min(8, t('min8Numbers'))
      .max(20, t('max20Characters'))
      .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, t('invalidAccountNumber'))
      .required(t('requiredAccountNumber')),
  bankName:
    Yup.string().trim().required(t('bankNameRequired'))
})
