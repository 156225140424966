import React from 'react'
import {
  Button,
  Card,
  Col
} from '@themesberg/react-bootstrap'
import useUserOverview from '../../../Tenant/UserDetails/hooks/useUserOverview'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Reason from '../../../Tenant/UserDetails/components/Reason'
import ConfirmationModal, { ConfirmOwnership, ResetUserPasswordModal, UpdateEntitiyForm } from '../../../../components/ConfirmationModal'
import WithdrawlLimitModal from '../../../../components/WithdrawlLimitModal'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

const Overview = ({
  basicInfo,
  userLimits,
  user,
  setActiveInactiveModal,
  setFlag,
  setIsInternalModal,
  setVerifyEmailModal,
  setShowModal,
  setFreeSpinModal,
  setShowManageMoneyModal,
  setShowAddAffiliate,
  addUserAffiliateLoading,
  setShowRemoveAffiliate,
  updateUserAffiliateLoading,
  isHidden,
  setPasswordEmail,
  setUserPassword,
  setEditModal,
  addTag,
  getDuplicateUsers,
  duplicateUsers,
  userCurrentStatus,
  loading,
  handleRedFlagClose,
  securityFlag
}) => {
  const { t } = useTranslation()
  const {
    moreInfo,
    adminPermissions,
    setShowRedFlagReasonMoadl,
    showRedFlagReasonMoadl,
    handleFlag,
    setWithdrawlLimitModal,
    withdrawlModal,
    handleWithdrawl,
    setPasswordForm,
    passwordForm,
    handleUpdatePassword,
    updateEntityForm,
    setUpdateEntitiyForm,
    entityType,
    setEntityType,
    handleUpdateEntity,
    setFormValue,
    isConfirm,
    setIsConfirm,
    migrateTov2,
    isMigrated,
    loadingMigrate
    // handleStatus
  } = useUserOverview({ user, securityFlag })
  return (
    <>

      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview d-flex mx-auto '>
          <h6 className='h4-overview text-center mt-1'>{t('basicInfo')} <hr className='h4-hr mt-1' /></h6>
          <div className='div-overview'>
            {basicInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h7 style={{ fontSize: '14px' }}>{t(label)}</h7>
                    <div>
                      <span style={{ fontSize: '14px' }} className={`${subValue} `}>{value || 'NA'}</span>
                      {!adminPermissions?.Users?.includes('RUI') && label === 'Email' &&
                        <Trigger message={t('notAllowedToViewContent')}>
                          <Button
                            variant='outline-warning'
                            size='xs'
                            className='m-1'
                            color='warning'
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </Button>
                        </Trigger>}
                    </div>
                  </div>
                  )
            })}
          </div>
          <h6 className='h4-overview text-center'>{t('moreInfo')}<hr className='h4-hr' /></h6>
          <div className='div-overview'>
            {moreInfo?.map(({ label, value, subValue }) => {
              return (
                <div key={label} className='d-flex justify-content-between m-1'>
                  <h7 style={{ fontSize: '14px' }}>{t(label)}</h7>&nbsp;
                  <div>
                    <span style={{ fontSize: '14px' }} className={subValue}>{value || 'NA'}</span>
                    {!adminPermissions?.Users?.includes('RUI') && label === 'Phone Number' &&
                      <Trigger message={t('notAllowedToViewContent')}>
                        <Button
                          variant='outline-warning'
                          size='xs'
                          className='m-1'
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Button>
                      </Trigger>}
                  </div>
                </div>
              )
            })}
          </div>

        </Card>
      </Col>

      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview'>
          <h6 className='h4-overview text-center'>{t('accountActions')} <hr className='h4-hr' /></h6>
          <div className='div-overview d-flex justify-content-center flex-wrap bg-green'>
            <Button
              className='actionButton'
              variant={user?.isActive ? 'outline-danger' : 'outline-success'}
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => setActiveInactiveModal(true)}
            >
              {user && user?.isActive ? t('blacklist') : t('whitelist')}
            </Button>

            <Button
              className='actionButton'
              variant={user?.isFlaggedRed ? 'outline-success' : 'outline-danger'}
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() =>
                setShowRedFlagReasonMoadl(true)}
              disabled={!user?.isActive}
            >
              {user && user?.isFlaggedRed ? t('greenFlag') : t('redFlag')}
            </Button>
            <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => setWithdrawlLimitModal(true)}
            >
              {t('withdrawlLimit')}
            </Button>
            <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => setPasswordForm(true)}
            >
              {t('changePassword')}
            </Button>
            <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => {
                setEntityType('Affiliate')
                setUpdateEntitiyForm(true)
              }}
            >
              {t('updatePlayerAffilate')}
            </Button>
            <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => {
                setEntityType('Agent')
                setUpdateEntitiyForm(true)
              }}
            >
              {t('updatePlayerAgent')}
            </Button>
            {!user?.isV2User && !isMigrated && (
              <Button
                className='actionButton'
                variant='outline-info'
                hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                onClick={migrateTov2}
                disabled={loadingMigrate}
              >
                {t('migratePlayerToV2')}
                {loadingMigrate && (
                  <Spinner
                    className='ms-1'
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>
            )}
          </div>
          {showRedFlagReasonMoadl
            ? (!user?.isFlaggedRed)
                ? <Reason
                    show={showRedFlagReasonMoadl}
                    setShow={setShowRedFlagReasonMoadl}
                    handleClose={handleRedFlagClose}
                    markUserStatusInactive={handleFlag}
                    name={user?.legalName}
                    isRedFlagged={showRedFlagReasonMoadl}
                    isSuperAdmin='superAdmin'
                  />
                : <ConfirmationModal
                    show={showRedFlagReasonMoadl}
                    setShow={setShowRedFlagReasonMoadl}
                    handleYes={() => {
                      handleFlag({ role: 'superAdmin' })
                      setShowRedFlagReasonMoadl(false)
                    }}
                    active={!user?.isFlaggedRed}
                    name={user?.legalName}
                  />
            : null}
          {
            withdrawlModal
              ? <WithdrawlLimitModal
                  show={withdrawlModal}
                  setWithdrawlLimitModal={setWithdrawlLimitModal}
                  withdrawlLimitData={handleWithdrawl}
                  user={user}
                />
              : <></>
          }
          {
            passwordForm
              ? (
                <ResetUserPasswordModal
                  show={passwordForm}
                  setShow={setPasswordForm}
                  handleResetUserPassword={handleUpdatePassword}
                />
                )
              : (<></>)
          }
          {
            updateEntityForm
              ? <UpdateEntitiyForm
                  show={updateEntityForm}
                  setShow={setUpdateEntitiyForm}
                  user={user}
                  entityType={entityType}
                  handleUpdateEntity={handleUpdateEntity}
                  t={t}
                  setFormValue={setFormValue}
                  setIsConfirm={setIsConfirm}
                />
              : null
          }
          {
            isConfirm
              ? <ConfirmOwnership
                  setIsConfirm={setIsConfirm}
                  isConfirm={isConfirm}
                  t={t}
                  handleUpdateEntity={handleUpdateEntity}
                />
              : null
          }
        </Card>
      </Col>
    </>
  )
}

export default Overview
