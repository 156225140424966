import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import {
  useDispatch,
  useSelector
} from 'react-redux'

import { debounce } from 'lodash'
import { getSecurityReportStart } from '../../../../store/redux-slices/admins'
import moment from 'moment'
import { getLoginToken } from '../../../../utils/storageUtils'
import { downloadFile } from '../../../../utils/fileDownloader'
import { useTranslation } from 'react-i18next'

const initalWalletState = {
  section: '',
  search: '',
  action: '',
  senior: ''
}
const valuesData = {
  logId: null,
  action: '',
  undergoerId: null
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]

const useSecurityAlerts = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const { userHistory, securityReport, loading } = useSelector(state => state.admins)
  const totalPages = Math.ceil(securityReport?.count / limit)
  const [state, setState] = useState(initialDate)
  const inputRef = useRef()
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [dataForm, setDataForm] = useState(valuesData)
  const [passwordForm, setPasswordForm] = useState(false)
  const [actionShow, setShowAction] = useState(false)

  const handleShowModal = (logId, action, undergoerId) => {
    setDataForm({
      ...dataForm,
      logId,
      action,
      undergoerId
    })
  }
  const fetchReport = () => {
    dispatch(getSecurityReportStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        tenantId: selectedClient,
        from: moment(state[0].startDate).toISOString(),
        to: moment(state[0].endDate).toISOString(),
        agentId: agent,
        affiliateId: affiliate
      }
    }))
  }
  useEffect(() => {
    fetchReport()
  }, [formData, limit, page, selectedClient, state, agent, affiliate])
  useEffect(() => {
    const intervalId = setInterval(() => {
      return dispatch(getSecurityReportStart({
        form: {
          ...formData,
          limit,
          pageNo: page,
          tenantId: selectedClient,
          from: moment(state[0].startDate).toISOString(),
          to: moment(state[0].endDate).toISOString(),
          agentId: agent,
          affiliateId: affiliate
        }
      }))
    }, 30000)
    return () => clearInterval(intervalId)
  }, [formData, limit, page, selectedClient, state, agent, affiliate])
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }
  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/securtity/get-user-report?action=${formData.action || ''}&section=${formData.section || ''}&tenantId=${selectedClient || ''}&search=${formData.search || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    handleCsvDownload,
    formData,
    setFormData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    userHistory,
    inputRef,
    state,
    setState,
    initialDate,
    loading,
    t,
    handleShowModal,
    dataForm,
    setDataForm,
    passwordForm,
    setPasswordForm,
    actionShow,
    setShowAction,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    securityReport,
    fetchReport
  }
}
export default useSecurityAlerts
