import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMatchListingStart, sportsCountriesStart, updateSportsBookActionSAStart, getSportsListingStart, addTopStart, getLeaguesListingStart, getLeaguesListingFailure } from '../../../store/redux-slices/superAdminSportsBook'
import { debounce } from 'lodash'
import { getTenantMatchListingStart, updateSportsBookActionTAStart, getTenantSportsListingStart, getTenantLeaguesListingStart } from '../../../store/redux-slices/tenantSportsBook'

const initialState = {
  matchName: '',
  status: '',
  // liveStatus: true,
  countryName: '',
  sportId: '1',
  tournamentId: ''
}
const useMatches = (isTenant) => {
  const {
    matchListing,
    loading
  } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )
  const { sportsCountries, addTopLoading } = useSelector((state) => state.superAdminSportsBook)
  const inputRef = useRef()
  const [filterData, setFilterData] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isDisable, setIsDisable] = useState(null)
  const [updateData, setUpdateData] = useState()
  const totalPages = Math.ceil(matchListing?.count / limit)
  const { leaguesListing: tournamentsListing } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )

  const fetchData = () => {
    if (!isTenant) {
      dispatch(getMatchListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page
        }
      }))
      if (filterData.countryName && filterData.sportId) {
        dispatch(getLeaguesListingStart({
          paramsData: {
            matchName: '',
            status: '',
            countryName: filterData.countryName,
            sportId: filterData.sportId || '1',
            limit: 500,
            pageNo: 1,
            isTenant
          }

        }))
      }
    } else {
      dispatch(getTenantMatchListingStart({
        paramsData: {
          ...filterData,
          limit,
          pageNo: page,
          isTenant
        }
      }))
      if (filterData.countryName && filterData.sportId) {
        dispatch(getTenantLeaguesListingStart({
          paramsData: {
            matchName: '',
            status: '',
            countryName: filterData.countryName,
            sportId: filterData.sportId || '1',
            limit: 500,
            pageNo: 1,
            isTenant

          }

        }))
      }
    }
  }
  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant
        }
      }))
  }, [])

  useEffect(() => {
    fetchData()
  }, [limit, page, filterData])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  const { sportsListing } = useSelector(
    state =>
      !isTenant
        ? state.superAdminSportsBook
        : state.tenantSportsBook
  )
  function handleDebounceFn (event) {
    setFilterData(prev => ({ ...prev, matchName: event.target.value }))
  }

  const handleUpdateSportAction = (updateData) => {
    !isTenant
      ? dispatch(updateSportsBookActionSAStart({ updateData, setIsStatusModalOpen, fetchData }))
      : dispatch(updateSportsBookActionTAStart({ updateData: { ...updateData, isTenant }, setIsStatusModalOpen, fetchData }))
  }

  useEffect(() => {
    dispatch(sportsCountriesStart({
      limit: 1000,
      pageNo: '',
      isTenant
    }))
  }, [])
  const handleResetFilterData = () => {
    dispatch(getLeaguesListingFailure())
  }
  const handleTopMatch = (entityId, status) => {
    const formData = new FormData()
    formData.append('status', status)
    formData.append('entityId', entityId)
    formData.append('entityType', 'events')

    const data = {
      formData,
      isTenant: isTenant,
      fetchData,
      entityId
    }
    dispatch(addTopStart(data))
  }

  return {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    totalPages,
    loading,
    debounceFn,
    inputRef,
    sportsListing,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    matchListing,
    handleUpdateSportAction,
    updateData,
    setUpdateData,
    sportsCountries,
    handleTopMatch,
    addTopLoading,
    tournamentsListing,
    handleResetFilterData
  }
}

export default useMatches
