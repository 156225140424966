import * as Yup from 'yup'
import { t } from 'i18next'
/* eslint-disable */
export const uploadBannerSchema = ({ minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight }) => Yup.object().shape({
  file: Yup.mixed().required(t('bannerRequired')).imageDimensionCheck('File Required', minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight)
    .test('FILE_FORMAT', t('imageFormatRequired'),
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type)))
})

const imageWidthAndHeight = (provideFile) => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: null, height: null }

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.readAsDataURL(provideFile)
    reader.onload = function () {
      const img = new Image()
      img.src = reader.result

      img.onload = function () {
        imgDimensions.width = img.width
        imgDimensions.height = img.height

        resolve(imgDimensions)
      }
    }
  })
}

const imageDimensionCheck = Yup.addMethod(Yup.mixed, 'imageDimensionCheck', function (message, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight) {
  return this.test('image-width-height-check', message, async function (value) {
    const { path, createError } = this

    if (!value) {
      return
    }

    const imgDimensions = await imageWidthAndHeight(value)

    if (imgDimensions.width < minRequiredWidth || imgDimensions.width > maxRequiredWidth) {
      return createError({
        path,
        message: `${t('imageWidthBetween')}` + ' ' + `${minRequiredWidth}px - ${maxRequiredWidth}px!`
      })
    }

    if (imgDimensions.height < minRequiredHeight || imgDimensions.height > maxRequiredHeight) {
      return createError({
        path,
        message: `${t('imageHeightBetween')}` + ' ' + `${minRequiredHeight}px - ${maxRequiredHeight}px!`
      })
    }

    return true
  })
})
