import { Row, Modal, Col, Table, Form, Button, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import useBonusIssue from './useBonusIssue'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'
import Select from 'react-select'
import { ErrorMessage, Formik } from 'formik'
import { giveBonusSchema, giveFreespinSchema } from './schema'
import BonusDetailTable from './BonusDetailTable'
import { formatDateYMD } from '../../utils/dateFormatter'
import GamesTable from '../FreeSpinBonusModal/GamesTable'
import FormFields from '../FreeSpinBonusModal/FormFields'
import { toast } from '../Toast'

export const GiveBonus = ({
  isTenant = false,
  showModal,
  setShowModal
}) => {
  const {
    selectedBonus,
    setSelectedBonus,
    bonusList,
    bonusDetail,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    segmentsOptions,
    issueBonusHandler,
    TABonusLoading,
    excludeSegmentsOptions,
    setExcludeSegmentOptions,
    t
  } = useBonusIssue(isTenant, 'bonus', setShowModal)

  return (
    <Modal size='xl' show={showModal}>
      <Modal.Header className='justify-content-center'>
        <h4>Give Bonus</h4>
      </Modal.Header>
      <Formik
        initialValues={{
          bonusId: '',
          includeUserIds: [],
          excludeUserIds: [],
          amount: 0
        }}
        validationSchema={giveBonusSchema(t)}
        onSubmit={(formValues) => {
          if (!['match', 'wagering'].includes(bonusDetail?.bonusType)) {
            delete formValues.amount
          }

          issueBonusHandler({
            ...formValues,
            bonusId: selectedBonus?.bonusId,
            includeUserIds: formValues.includeUserIds.map(item => item.value),
            excludeUserIds: formValues?.excludeUserIds?.map(item => item.value)
          })
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form className='align-items-center mt-3'>
            <Modal.Body>
              {!isTenant &&
                <Row className='mb-4'>
                  <Col className='d-flex align-items-center'>
                    <ClientFilter
                      setSelectedClient={setSelectedClient}
                      setSelectedPortal={setSelectedPortal}
                      selectedClient={selectedClient}
                      hasAllOptions={false}
                    />

                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cms={selectedClient === ''}
                      hasAllOptions={false}
                    />

                  </Col>
                </Row>}
              {selectedBonus &&
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>Segments <span className='text-danger'>* </span>
                    </Form.Label>
                    <Select
                      isMulti
                      isClearable={false}
                      name='includeUserIds'
                      options={segmentsOptions}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={(option, e) => {
                        setFieldValue('includeUserIds', option)
                        const currData = option.map(item => item.value)
                        let opt = []
                        if (e === 'selected-action') {
                          opt = excludeSegmentsOptions
                        } else {
                          opt = segmentsOptions
                        }
                        const prevData = opt.map(item => item.value)
                        const nextData = prevData.filter(item => currData.includes(item))
                        setExcludeSegmentOptions(opt.filter(item => !nextData.includes(item.value)))
                        setFieldValue('excludeUserIds', values?.excludeUserIds?.filter(item => !currData.includes(item.value)))
                      }}
                    />
                    <ErrorMessage
                      component='div'
                      name='includeUserIds'
                      className='text-danger'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Exclude Segments</Form.Label>
                    <Select
                      isMulti
                      isClearable={false}
                      name='excludeUserIds'
                      options={excludeSegmentsOptions}
                      value={values?.excludeUserIds}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={(option) => {
                        setFieldValue('excludeUserIds', option)
                      }}
                    />
                  </Col>
                </Row>}

              {bonusList?.length > 0 &&
                <Row>
                  <Col style={{ maxWidth: '35%' }}>
                    <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                      <Table bordered>
                        <thead>
                          <tr className='thead-dark'>
                            <th>Available Bonuses</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bonusList?.length > 0 && bonusList?.map((item, i) => {
                            return (
                              <tr key={`player-modal-bonus-history ${i}`}>
                                <td>
                                  <Form.Check
                                    readOnly
                                    type='checkbox'
                                    label={item?.promotionTitle?.EN}
                                    checked={item?.bonusId === selectedBonus?.bonusId}
                                    onClick={() => setSelectedBonus(item)}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col style={{ maxWidth: '65%' }}>
                    <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                      <BonusDetailTable
                        bonusDetail={bonusDetail}
                        selectedBonus={selectedBonus}
                      />
                      {bonusDetail && ['match', 'wagering'].includes(bonusDetail?.bonusType) &&
                        <div className='d-flex justify-content-start align-items-center'>
                          <Form.Label className='mb-0' style={{ marginRight: '10px' }}>Amount in EUR <small>(optional)</small></Form.Label>
                          <Form.Control
                            type='number'
                            min={0}
                            step='any'
                            name='amount'
                            className='w-50'
                            placeholder='Enter Amount'
                            value={values?.bonusAmount}
                            onChange={handleChange}
                          />
                        </div>}
                    </div>
                  </Col>
                </Row>}
              {bonusList?.length === 0 &&
                <div className='text-danger text-center'>No Bonuses Found</div>}
            </Modal.Body>
            <Modal.Footer className={`d-flex justify-content-${bonusList?.length > 0 ? 'between' : 'center'}`}>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShowModal(false)
                  setSelectedClient('')
                  setSelectedPortal('')
                }}
              >
                Cancel
              </Button>
              {bonusList?.length > 0 &&
                <Button
                  className='m-2'
                  variant='outline-success'
                  onClick={handleSubmit}
                >
                  Add
                  {TABonusLoading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>}
            </Modal.Footer>
          </Form>)}
      </Formik>
    </Modal>
  )
}

export const GiveFreeSpins = ({
  isTenant = false,
  showModal,
  setShowModal
}) => {
  const {
    bonusDetail,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    segmentsOptions,
    issueBonusHandler,
    TABonusLoading,
    search,
    setSearch,
    gameIds,
    availableGames,
    handleSelectAll,
    loading,
    handleSelect,
    getData,
    bonusOptions,
    excludeSegmentsOptions,
    setExcludeSegmentOptions,
    t
  } = useBonusIssue(isTenant, 'freespin', setShowModal)

  return (
    <Modal size='xl' show={showModal}>
      <Modal.Header className='justify-content-center'>
        <h4>Issue Freespins</h4>
      </Modal.Header>
      <Formik
        initialValues={{
          quantity: 1,
          betLevel: 1,
          validTo: formatDateYMD(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)),
          validFrom: formatDateYMD(new Date()),
          bonusId: '',
          includeUserIds: [],
          excludeUserIds: []
        }}
        validationSchema={giveFreespinSchema(t)}
        onSubmit={(formValues) => {
          if (!['match', 'wagering'].includes(bonusDetail?.bonusType)) {
            delete formValues.amount
          }
          if (gameIds?.length) {
            issueBonusHandler({
              ...formValues,
              includeUserIds: formValues.includeUserIds.map(item => item.value),
              excludeUserIds: formValues?.excludeUserIds?.map(item => item.value)
            })
          } else {
            toast('Select At least One Game', 'error')
          }
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, handleBlur }) => (
          <Form className='align-items-center mt-3'>
            <Modal.Body>

              {!isTenant &&
                <Row className='mb-4'>
                  <Col className='d-flex align-items-center'>
                    <ClientFilter
                      setSelectedClient={setSelectedClient}
                      setSelectedPortal={setSelectedPortal}
                      selectedClient={selectedClient}
                      hasAllOptions={false}
                    />

                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cms={selectedClient === ''}
                      hasAllOptions={false}
                    />
                  </Col>
                </Row>}

              <Row>
                <Col>
                  <GamesTable
                    search={search}
                    setSearch={setSearch}
                    gameIds={gameIds}
                    availableGames={availableGames}
                    handleSelectAll={handleSelectAll}
                    loading={loading}
                    handleSelect={handleSelect}
                    getData={getData}
                  />
                </Col>
              </Row>

              {(selectedPortal || isTenant) &&
                <FormFields
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  bonusOptions={bonusOptions}
                  setFieldValue={setFieldValue}
                  segmentsOptions={segmentsOptions}
                  excludeSegmentsOptions={excludeSegmentsOptions}
                  setExcludeSegmentOptions={setExcludeSegmentOptions}
                />}
            </Modal.Body>
            <Modal.Footer className={`d-flex justify-content-${bonusOptions?.length ? 'between' : 'center'}`}>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShowModal(false)
                  setSelectedClient('')
                  setSelectedPortal('')
                }}
              >
                Cancel
              </Button>
              {(selectedPortal || isTenant) &&
                <Button
                  className='m-2'
                  variant='outline-success'
                  onClick={handleSubmit}
                >
                  Issue
                  {TABonusLoading && values?.bonusId && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>}
            </Modal.Footer>
          </Form>)}
      </Formik>
    </Modal>
  )
}
