import { createSlice } from '@reduxjs/toolkit'

const tenantUsersSlice = createSlice({
  name: 'tenantUsers',
  initialState: {
    error: null,
    c: false,
    allUsers: null,
    user: null,
    userFields: null,
    tags: [],
    duplicateUsers: [],
    duplicateLoading: false,
    limitsLoading: false,
    cashBackData: {},
    cashBackLoading: false,
    currentCashBackData: {},
    currentCashBackLoading: false,
    emailLoading: false,
    emailLogs: {},
    userCurrUpdatePermission: false,
    userTransactionDetails: null,
    noteMessage: '',
    ggrData: []
  },
  reducers: {
    getTenantUserGGRStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantUserGGRSuccess: (state, { payload }) => ({
      ...state,
      ggrData: payload,
      loading: false
    }),
    getTenantUserGGRFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserKycDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserKycDetailsSuccess: (state, { payload }) => ({
      ...state,
      kycUpdateMessage: payload,
      loading: false
    }),
    updateTenantUserKycDetailsFailure: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserNoteStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserNoteSuccess: (state, { payload }) => ({
      ...state,
      noteMessage: payload,
      loading: false
    }),
    updateTenantUserNoteFailure: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantBetNoteStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantBetNoteSuccess: (state, { payload }) => ({
      ...state,
      noteMessage: payload,
      loading: false
    }),
    updateTenantBetNoteFailure: (state) => ({
      ...state,
      loading: true
    }),
    updateKycRequestStart: (state) => ({
      ...state,
      loading: true

    }),
    updateKycRequestSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      kycMessage: payload
    }),
    updateKycRequestFaliure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getUserTransactionDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getUserTransactionDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userTransactionDetails: payload,
      error: null
    }),
    getUserTransactionDetailsFaliure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllTenantUsersStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTenantUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allUsers: payload,
      user: null
    }),
    getAllTenantUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantUserStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateTenantUserStatusSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantFlagStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantFlagSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      flagTenantMessage: payload
    }),
    updateTenantFlagFaliure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getTenantUserDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getTenantUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      user: payload
    }),
    getTenantUserDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserFieldsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userFields: payload
    }),
    getUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserFieldsSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    createTenantUserStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    createTenantUserSuccess: (state) => ({
      ...state,
      loading: false
    }),
    createTenantUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTagsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTagsComplete: (state) => ({
      ...state,
      loading: false
    }),
    getDuplicateUsersStart: (state) => ({
      ...state,
      duplicateLoading: true
    }),
    getDuplicateUsersSuccess: (state, { payload }) => ({
      ...state,
      duplicateLoading: false,
      duplicateUsers: payload
    }),
    getDuplicateUsersFailure: (state) => ({
      ...state,
      duplicateLoading: false
    }),
    updateLimitsStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    updateLimitsComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    disableUserStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    disableUserComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    updateUserAffiliateStart: (state) => ({
      ...state,
      updateUserAffiliateLoading: true
    }),
    updateUserAffiliateComplete: (state) => ({
      ...state,
      updateUserAffiliateLoading: false
    }),
    addAffiliateUserStart: (state) => ({
      ...state,
      addUserAffiliateLoading: true
    }),
    addAffiliateUserComplete: (state) => ({
      ...state,
      addUserAffiliateLoading: false
    }),
    verifyUserEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyUserEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetPasswordEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    resetPasswordEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    resetUserPasswordComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    getCashbackDataStart: (state) => ({
      ...state,
      cashBackLoading: true
    }),
    getCashbackDataComplete: (state, { payload }) => ({
      ...state,
      cashBackLoading: false,
      cashBackData: payload
    }),
    getCashbackDataFailure: (state) => ({
      ...state,
      cashBackLoading: false
    }),
    getCurrentCashbackDataStart: (state) => ({
      ...state,
      currentCashBackLoading: true
    }),
    getCurrentCashbackDataComplete: (state, { payload }) => ({
      ...state,
      currentCashBackLoading: false,
      currentCashBackData: payload
    }),
    getCurrentCashbackDataFailure: (state) => ({
      ...state,
      currentCashBackLoading: false
    }),
    sendUserEmailStart: (state) => ({
      ...state,
      emailLoading: true
    }),
    sendUserEmailComplete: (state) => ({
      ...state,
      emailLoading: false
    }),
    getEmailLogsStart: (state) => ({
      ...state,
      emailLoading: true
    }),
    getEmailLogsComplete: (state, { payload }) => ({
      ...state,
      emailLoading: false,
      emailLogs: payload
    }),
    getEmailLogsFailure: (state) => ({
      ...state,
      emailLoading: false
    }),
    getTAWalletDataComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      user: { ...state?.user, userWallet: payload }
    }),
    updateUserCurrPermissionStart: (state) => ({
      ...state,
      userCurrUpdatePermission: false
    }),
    updateUserCurrPermissionComplete: (state, { payload }) => ({
      ...state,
      userCurrUpdatePermission: payload
    }),
    updateUserCurrPermissionFailure: (state) => ({
      ...state,
      userCurrUpdatePermission: false
    })
  }
})

export default tenantUsersSlice.reducer

export const {
  updateTenantUserKycDetailsStart,
  updateTenantUserKycDetailsSuccess,
  updateTenantUserKycDetailsFailure,
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getTenantUserDetailsStart,
  getTenantUserDetailsSuccess,
  getTenantUserDetailsFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  createTenantUserStart,
  createTenantUserSuccess,
  createTenantUserFailure,
  updateTenantUserStart,
  updateTenantUserSuccess,
  updateTenantUserFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserStart,
  updateUserComplete,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  sendUserEmailStart,
  sendUserEmailComplete,
  getEmailLogsStart,
  getEmailLogsComplete,
  getEmailLogsFailure,
  getTAWalletDataComplete,
  updateUserCurrPermissionStart,
  updateUserCurrPermissionComplete,
  updateUserCurrPermissionFailure,
  getCurrentCashbackDataStart,
  getCurrentCashbackDataComplete,
  getCurrentCashbackDataFailure,
  updateTenantFlagStart,
  updateTenantFlagSuccess,
  updateTenantFlagFaliure,
  getUserTransactionDetailsStart,
  getUserTransactionDetailsSuccess,
  getUserTransactionDetailsFaliure,
  updateKycRequestStart,
  updateKycRequestSuccess,
  updateKycRequestFaliure,
  updateTenantBetNoteFailure,
  updateTenantBetNoteStart,
  updateTenantBetNoteSuccess,
  updateTenantUserNoteStart,
  updateTenantUserNoteSuccess,
  updateTenantUserNoteFailure,
  getTenantUserGGRStart,
  getTenantUserGGRSuccess,
  getTenantUserGGRFailure
} = tenantUsersSlice.actions
