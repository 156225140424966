import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import CreateCasinoProviders from './components/CreateCasinoProvider'
import ConfirmationModal from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faEye,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import useProviderListing from './useProviderListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    show,
    statusShow,
    setStatusShow,
    data,
    type,
    casinoProvidersData,
    totalPages,
    handleClose,
    handleStatusShow,
    handleYes,
    loading,
    status,
    aggregators,
    createProvider,
    updateProvider,
    name,
    search,
    setSearch,
    t,
    addProviderGames
  } = useProviderListing()

  const { isHidden } = useCheckPermission()
  const navigate = useNavigate()
  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{t('casinoProviders')}</h3>
        </Col>

        <Col sm={4}>
          <div className='d-flex justify-content-end text-right mb-2'>
            <Form.Control
              type='search'
              name='search'
              placeholder={t('searchProvider')}
              size='sm'
              className='w-50 me-2'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>{t('sNo')}</th>
            <th>{t('name')}</th>
            <th>{t('status')}</th>
            <th>{t('demoMode')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(casinoProvidersData) &&
            casinoProvidersData?.rows?.map(
              (
                {
                  masterCasinoProviderId,
                  name,
                  isActive,
                  thumbnailUrl,
                  demo
                },
                index
              ) => {
                return (
                  <tr key={masterCasinoProviderId}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>
                      <Trigger message={name}>
                        <span
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {name}
                        </span>
                      </Trigger>
                    </td>
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inactive')}
                    </td>

                    <td>{demo ? t('yes') : t('no')}</td>

                    <td>
                      {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
                        ? (
                          <ButtonGroup>
                            {!isActive
                              ? (
                                <Trigger message={t('setStatusActive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='success'
                                    onClick={() =>
                                      handleStatusShow(
                                        masterCasinoProviderId,
                                        isActive,
                                        name
                                      )}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                                )
                              : (
                                <Trigger message={t('setStatusInactive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() =>
                                      handleStatusShow(
                                        masterCasinoProviderId,
                                        isActive,
                                        name
                                      )}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                                )}
                          </ButtonGroup>)
                        : 'NA'}
                      <Trigger message={t('viewNewGames')}>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() =>
                            navigate('/super-admin/view-new-games', { state: { data: masterCasinoProviderId } })}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </Trigger>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='success'
                        onClick={() =>
                          addProviderGames(masterCasinoProviderId)}
                      >
                        {t('addGames')}
                      </Button>

                    </td>

                  </tr>
                )
              }
            )}

          {casinoProvidersData?.count === 0 && !loading &&
            (
              <tr>
                <td
                  colSpan={5}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>

      {casinoProvidersData?.count !== 0 && !loading &&
        (
          <PaginationComponent
            page={casinoProvidersData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={casinoProvidersData?.count}
          />
        )}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <CreateCasinoProviders
        handleClose={handleClose}
        data={data}
        type={type}
        show={show}
        aggregators={aggregators}
        loading={loading}
        createProvider={createProvider}
        updateProvider={updateProvider}
      />
    </div>
  )
}
