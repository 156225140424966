import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllClientsStart, getAllPortalsStart } from '../../../../../store/redux-slices/tenants'
import { createBonusStart, getSelectedSeniorBonusStart, getWageringTemplateStart } from '../../../../../store/redux-slices/bonus'
import { getBalanceBonuses } from '../../../../../utils/apiCalls'
import { getloyaltyLevelStart } from '../../../../../store/redux-slices/superAdminSettings'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useCreateBonus = (bonusDetail, isClone) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [set, setSet] = useState(false)
  const [selectedLimit, setSelectedLimit] = useState('')
  const [selectedGames, setSelectedGames] = useState([])
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [selectedTab, setSelectedTab] = useState('general')
  const [selectedClient, setSelectedClient] = useState('')
  const { clientsFilterData, portalsFilterData } = useSelector(state => state.tenants)
  const { bonusDropDown, wageringTemplateList, loading, languages } = useSelector(state => state.bonus)
  const { wageringTemplateDetail } = useSelector(state => state.wageringTemplate)
  const [activeTab, setActiveTab] = useState(0)
  const [portalOptions, setPortalOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [balanceBonusOptions, setBalanceBonusOptions] = useState(null)
  const [appliedBonusOptions, setAppliedBonusOptions] = useState({ label: '', value: '', country: [] })
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const [selectedProvider, setSelectedProvider] = useState('')
  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)
  const { loyaltyLevel } = useSelector(state => state.superAdminSettings)
  const [gamesPageNo, setGamesPageNo] = useState(1)
  const [gamesLimit, setGamesLimit] = useState(15)
  const [loyaltyCount, setLoyaltyCount] = useState(1)
  const [countryData, setCountryData] = useState([])
  const [data, setData] = useState({
    promoTitle: bonusDetail?.promotionTitle || { EN: '' },
    desc: bonusDetail?.description || { EN: '' },
    terms: bonusDetail?.termCondition || { EN: '' }
  })

  useEffect(() => {
    if (bonusDetail) {
      setData({
        promoTitle: bonusDetail?.promotionTitle,
        desc: bonusDetail?.description,
        terms: bonusDetail?.termCondition
      })
      setSelectedGames(bonusDetail?.gameDetails?.map((item) => ({ masterCasinoGameId: item?.master_casino_game_id, name: item?.name })))
      setSelectedPlayers(bonusDetail?.playerDetails?.map((item) => ({ userId: item?.userId, name: item?.username })))
      setSet(true)
    }
  }, [bonusDetail])

  const { casinoProvidersData, casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const [state, setState] = useState([
    {
      startDate: moment(new Date()).format('YYYY-MM-DD') + 'T' + moment(new Date()).format('HH:mm'),
      endDate: moment(new Date()).format('YYYY-MM-DD') + 'T' + moment(new Date()).add('hour', 1).format('HH:mm'),
      key: 'selection'
    }
  ])

  const [curr, setCurr] = useState({
    KRW: {
      maxBonusThreshold: '',
      minDeposit: '',
      maxWinAmount: ''
    }
  })

  useEffect(() => {
    dispatch(getloyaltyLevelStart({ isTenant: false }))
  }, [])

  const getBalanceBonusesOptions = async (tenantId) => {
    await getBalanceBonuses({ tenantId }).then((data) => {
      setBalanceBonusOptions(data?.data?.data?.bonusDetails)
    })
  }

  useEffect(() => {
    if (bonusDetail) {
      dispatch(getWageringTemplateStart({ isTenant: false, adminId: bonusDetail?.adminId }))
      setState([{
        startDate: moment(new Date(bonusDetail?.validFrom)).format('YYYY-MM-DD') + 'T' + moment(new Date(bonusDetail?.validFrom)).format('hh:mm'),
        endDate: moment(new Date(bonusDetail?.validTo)).format('YYYY-MM-DD') + 'T' + moment(new Date(bonusDetail?.validTo)).format('hh:mm'),
        key: 'selection2'
      }])
    }
  }, [bonusDetail?.adminId])

  const handelCreateBonus = (formValues) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === null || formValues[key] === '') {
        delete formValues[key]
      }
    })
    delete formValues.validFrom
    delete formValues.validTo
    const gameIds = selectedGames?.map(item => item?.masterCasinoGameId) || []
    const userId = selectedPlayers?.map(item => item?.userId) || []
    const tempObject = {
      ...formValues,
      userId,
      gameIds,
      validFrom: moment(state[0].startDate).toISOString(),
      validTo: moment(state[0].endDate).toISOString(),
      tenantIds: formValues.tenantIds.map((id) => id.value)

    }
    dispatch(
      createBonusStart({
        data: tempObject,
        navigate
      })
    )
  }

  const [preview, setPreview] = useState({
    image_preview: !isClone ? bonusDetail?.imageUrl : null,
    image_file: !isClone ? bonusDetail?.imageUrl : null
  })

  useEffect(() => {
    if (bonusDetail?.imageUrl && !isClone) {
      setPreview({
        image_preview: bonusDetail?.imageUrl,
        image_file: bonusDetail?.imageUrl
      })
    }
    if (bonusDetail?.gameIds) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  const handleImagePreview = (e) => {
    if (e.target.files[0]) {
      const imageAsBase64 = URL.createObjectURL(e.target.files[0])
      const imageAsFiles = e.target.files[0]
      setPreview({
        image_preview: imageAsBase64,
        image_file: imageAsFiles
      })
    }
  }

  useEffect(() => {
    dispatch(getAllClientsStart({
      search: '',
      orderBy: 'email',
      sort: 'desc'
    }))
  }, [])

  useEffect(() => {
    let options = []
    if (portalsFilterData && selectedClient) {
      options = portalsFilterData.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient])

  useEffect(() => {
    if (balanceBonusOptions) {
      const options = balanceBonusOptions.map((opt) => { return ({ label: opt.promotionTitle?.EN, value: opt.bonusId, country: opt?.other?.countries }) })
      setAppliedBonusOptions(options)
    }
  }, [balanceBonusOptions])

  useEffect(() => {
    if (selectedClient) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    const options = casinoProvidersData?.rows?.map((provider) => { return { value: provider.masterCasinoProviderId, label: provider.name } })
    setProviderOptions(options)
  }, [casinoProvidersData])

  useEffect(() => {
    dispatch(getWageringTemplateStart({ isTenant: false, adminId: selectedClient }))
    dispatch(getSelectedSeniorBonusStart())
  }, [selectedClient])

  return {
    selectedTab,
    setSelectedTab,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    providerOptions,
    state,
    setState,
    dispatch,
    navigate,
    preview,
    handleImagePreview,
    activeTab,
    setActiveTab,
    curr,
    setCurr,
    wageringTemplateList,
    wageringTemplateDetail,
    enableSubmit,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    casinoGamesData,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gameIds,
    setGameIds,
    selectedClient,
    loyaltyLevel,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    data,
    setData,
    set,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData,
    location,
    selectedGames,
    setSelectedGames,
    selectedPlayers,
    setSelectedPlayers,
    bonusDropDown,
    selectedLimit,
    setSelectedLimit,
    t
  }
}

export default useCreateBonus
