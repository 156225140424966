import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faEdit, faEye, faRedoAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useCmsListing from './hooks/useCmsListing'
import Trigger from '../../../components/OverlayTrigger'
import ClientFilter from '../../../components/ClientFilter'
import { SuperAdminRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useIsGamma from '../../../common/hook/useIsGamma'

export default () => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    setSearch,
    search,
    setSelectedClient,
    navigate,
    cmsData,
    totalPages,
    selectedClient,
    setSelectedPortal,
    loading,
    handleStatusShow,
    statusShow,
    setStatusShow,
    handleYes,
    status,
    active,
    setActive,
    name,
    t
  } = useCmsListing()
  const { isGamma } = useIsGamma()
  const { isHidden } = useCheckPermission()
  return (
    <div className='p-3'>
      <Row>
        <Col>
          <h3>{t('cms')}</h3>
        </Col>
        <Col
          className='d-flex
         justify-content-end
         align-items-center'
        >
          {
          !isGamma &&
            (
              <Button
                variant='outline-success'
                size='sm'
                onClick={() => navigate(SuperAdminRoutes.CreateCMS)}
                hidden={isHidden({ module: { key: 'CMS', value: 'C' } })}
              >
                {t('create')}
              </Button>
            )
         }
        </Col>
      </Row>
      <Row>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
            <Form.Label style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{
                width: 'auto',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              value={search}
              placeholder={t('searchTitleSlug')}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            <Form.Label style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
            >
              {t('status')}
            </Form.Label>

            <Form.Select
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>{t('all')}</option>
              <option key='true' value>{t('active')}</option>
              <option key='false' value={false}>{t('inactive')}</option>
            </Form.Select>
            <Col>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  size='sm'
                  onClick={() => {
                    setSelectedClient('')
                    setSelectedPortal('')
                    setSearch('')
                    setActive('')
                    setLimit(15)
                    setPage(1)
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </Col>

          </div>

        </Col>

      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {[
              'sNO',
              'title',
              'slug',
              'portal',
              'status',
              'action'
            ].map((h) => (
              <th key={h}>{t(h)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(cmsData) &&
                    cmsData?.rows?.map(
                      ({
                        cmsPageId,
                        title,
                        slug,
                        isActive,
                        tenant,
                        tenantId
                      }, index) => {
                        return (
                          <tr key={cmsPageId}>
                            <td>{(index + 1) + (limit * (page - 1))}</td>
                            <td>
                              {title?.EN}
                            </td>

                            <td>{slug}</td>

                            <td>
                              <Trigger message={tenant?.domain}>
                                <span
                                  style={{
                                    width: '150px',
                                    cursor: 'pointer'
                                  }}
                                  className='d-inline-block text-truncate'
                                >
                                  {tenant?.domain}
                                </span>
                              </Trigger>
                            </td>

                            <td className={isActive ? 'table-success' : 'table-danger'}>
                              {isActive ? t('active') : t('inactive')}
                            </td>

                            <td>
                              {
                                !isGamma &&
                                (
                                  <Trigger message={t('edit')}>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='warning'
                                      onClick={() =>
                                        navigate(
                                      `/super-admin/cms/edit/${cmsPageId}`, { state: { data: cmsData?.rows?.[index] } }
                                        )}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'U' } })}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </Trigger>
                                )
                              }
                              <Trigger message={t('viewDetails')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='info'
                                  onClick={() =>
                                    navigate(
                                      `/super-admin/cms/details/${cmsPageId}`, { state: { data: cmsData?.rows?.[index] } }
                                    )}
                                  hidden={isHidden({ module: { key: 'CMS', value: 'R' } })}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </Button>
                              </Trigger>

                              {!isActive
                                ? (
                                  <Trigger message={t('setStatusActive')}>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='success'
                                      onClick={() =>
                                        handleStatusShow(cmsPageId, isActive, tenantId, title?.EN)}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </Button>
                                  </Trigger>
                                  )
                                : (
                                  <Trigger message={t('setStatusInactive')}>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='danger'
                                      onClick={() =>
                                        handleStatusShow(cmsPageId, isActive, tenantId, title?.EN)}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                  </Trigger>
                                  )}
                            </td>
                          </tr>
                        )
                      }
                    )}

          {cmsData?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {cmsData?.count !== 0 && !loading && (
        <PaginationComponent
          page={cmsData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={cmsData?.count}
        />
      )}
      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={!status}
        name={name}
      />
    </div>
  )
}
