import React from 'react'
import useCreateBonus from './hooks/useCreateBonus'
import { Col, Row, Form as BForm, Button } from 'react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { createBonusSchema } from './schema'
import Trigger from '../../components/OverlayTrigger'
import { BonusVal, LIMITTYPE } from './constant'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import InputDatePicker from '../../components/FormElements/InputDatePicker/InputDatePicker'
const BonusCreation = ({ isEdit }) => {
  const {
    t,
    navigate,
    handleCreateBonus,
    adminData,
    bonusDropDown,
    bonusDetail,
    getSeniorBonusStart,
    getTenantCurrencies,
    tenantCurrencies
  } = useCreateBonus({ isEdit })
  return (
    <div className='p-3'>
      <div role='button' onClick={() => navigate(-1)} className='d-flex gap-2 items-center'>
        <span><FontAwesomeIcon icon={faArrowLeft} /></span>
        <h3>{isEdit ? t('edit') : t('create')}</h3>
      </div>
      <div className='my-2'>
        <Formik
          enableReinitialize={isEdit}
          validateOnMount={false}
          initialValues={{
            type: 'CASINO',
            bonusType: isEdit ? bonusDetail?.bonusType : '',
            bonusValidFrom: isEdit ? moment(bonusDetail?.bonusValidFrom).toDate() : moment().toDate(),
            bonusValidTo: isEdit ? moment(bonusDetail?.bonusValidTo).toDate() : moment().add(1, 'hour').toDate(),
            currencyCode: isEdit ? bonusDetail?.currencyCode : '',
            reoccuringDepositRate: isEdit ? bonusDetail?.reoccuringDepositRate ? bonusDetail?.reoccuringDepositRate : '' : '',
            withdrawalRate: isEdit ? bonusDetail?.withdrawalRate ? bonusDetail?.withdrawalRate : '' : '',
            limitType: isEdit ? bonusDetail?.limitType ? bonusDetail?.limitType : '' : '',
            customLimit: isEdit ? bonusDetail?.customLimit ? bonusDetail?.customLimit : '' : '',
            // minsForBonusGiven: isEdit ? bonusDetail?.minsForBonusGiven ? bonusDetail?.minsForBonusGiven : '' : '',
            compensationTarget: isEdit ? bonusDetail?.compensationTarget ? bonusDetail?.compensationTarget : '' : '',
            maxBonusLimit: isEdit ? bonusDetail?.maxBonusLimit : 1,
            bonusPercent: isEdit ? bonusDetail?.bonusPercent : 1,
            rollingPercent: isEdit ? bonusDetail?.rollingPercent : '',
            adminId: isEdit ? bonusDetail?.adminId : '',
            adminName: isEdit ? `${bonusDetail?.adminUser?.firstName} ${bonusDetail?.adminUser?.lastName} ` : '',
            tenantId: isEdit ? bonusDetail?.tenantId : '',
            slotCatBonusPercent: isEdit ? bonusDetail?.slotCatBonusPercent ? bonusDetail?.slotCatBonusPercent : '' : '',
            liveCatBonusPercent: isEdit ? bonusDetail?.liveCatBonusPercent ? bonusDetail?.liveCatBonusPercent : '' : ''

          }}
          validationSchema={createBonusSchema(t)}
          onSubmit={handleCreateBonus}
          validateOnChange
          validateOnBlur
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            validateField,
            setValues
          }) => {
            const isCompensation = values?.bonusType === BonusVal.compensationBonus
            const isRecurring = values?.bonusType === BonusVal.reOccuringDepositBonus
            const isPayback = values?.bonusType === BonusVal.paybackBonus
            return (
              <Form>
                <Row>
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('senior')}
                      </BForm.Label>
                      <BForm.Select
                        disabled={isEdit}
                        className='w-100 select-placeholder'
                        id='adminId'
                        name='adminId'
                        value={values.adminId}
                        onChange={(e) => {
                          const tenantId = adminData?.find((item) => item?.adminUserId === Number(e.target.value))?.Tenants?.[0]?.tenantId
                          setValues({ ...values, tenantId: tenantId, adminId: Number(e.target.value) })
                          getTenantCurrencies(tenantId)
                          getSeniorBonusStart(tenantId)
                        }}
                        onBlur={handleBlur}
                      >
                        <option value='' disabled>
                          {t('selectSenior')}
                        </option>
                        {isEdit
                          ? (
                            <option value={values.adminId}>
                              {values.adminName}
                            </option>
                            )
                          : (
                            <>
                              {adminData?.map((option) => (
                                <option key={option.adminUserId} value={option.adminUserId}>
                                  {option.firstName + ' ' + option.lastName}
                                </option>
                              ))}
                            </>
                            )}
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='adminId'
                        className='text-danger mt-1'
                      />

                    </div>
                  </Col>
                  {values?.adminId && (
                    <Col sm={6} xxl={4} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('bonusType')}
                        </BForm.Label>
                        <BForm.Select
                          disabled={isEdit}
                          name='bonusType'
                          value={values.bonusType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=''>
                            {
                              t('select_bonus_type')
                            }
                          </option>
                          {isEdit
                            ? (
                              <option value={values.bonusType}>
                                {values.bonusType}
                              </option>
                              )
                            : bonusDropDown?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                              >{item}
                              </option>
                            ))}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='bonusType'
                          className='text-danger mt-1'
                        />
                      </div>

                    </Col>
                  )}
                  {values?.adminId && (
                    <Col sm={6} xxl={4} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>{t('selectCurrency')} </BForm.Label>
                        <BForm.Select
                          disabled={isEdit}
                          name='currencyCode'
                          value={values.currencyCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=''>{t('selectCurrency')}</option>
                          {isEdit
                            ? (
                              <option value={values.currencyCode}>
                                {values.currencyCode}
                              </option>
                              )

                            : tenantCurrencies?.currencies?.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item}
                                >
                                  {t(item)}
                                </option>
                              )
                            })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='currencyCode'
                          className='text-danger'
                        />
                      </div>
                    </Col>
                  )}
                  {!isPayback && (
                    <Col sm={6} xxl={4} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('bonus_percentage')}
                        </BForm.Label>
                        <Trigger message='Value will consider in percentage'>
                          <BForm.Control
                            name='bonusPercent'
                            type='number'
                            min={1}
                            placeholder={t('enterValueInPercent')}
                            value={values.bonusPercent}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Trigger>
                        <ErrorMessage
                          component='div'
                          name='bonusPercent'
                          className='text-danger mt-1'
                        />
                      </div>

                    </Col>
                  )}
                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('maximum_bonus_limit')}
                      </BForm.Label>
                      <BForm.Control
                        name='maxBonusLimit'
                        type='number'
                        min={1}
                        placeholder={t('enterValue')}
                        value={values.maxBonusLimit}
                        onInput={handleChange}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component='div'
                        name='maxBonusLimit'
                        className='text-danger mt-1'
                      />
                    </div>

                  </Col>
                  {
                    values?.bonusType !== BonusVal.rackbackBonus
                      ? (

                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <InputDatePicker
                              isForm
                              label={t('bonus_valid_from')}
                              onChange={(val) => {
                                setFieldValue('bonusValidFrom', val)
                              }}
                              value={values.bonusValidFrom}
                            />
                            <ErrorMessage
                              component='div'
                              name='bonusValidFrom'
                              className='text-danger mt-1'
                            />
                          </div>

                        </Col>
                        )
                      : (
                        <></>
                        )
                  }
                  {
                    values?.bonusType !== BonusVal.rackbackBonus
                      ? (

                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <InputDatePicker
                              isForm
                              label={t('bonus_valid_to')}
                              onChange={(val) => {
                                setFieldValue('bonusValidTo', val)
                              }}
                              value={values.bonusValidTo}
                            />
                            <ErrorMessage
                              component='div'
                              name='bonusValidTo'
                              className='text-danger mt-1'
                            />
                          </div>

                        </Col>
                        )
                      : (
                        <></>
                        )

                  }

                  <Col sm={6} xxl={4} className='mb-2'>
                    <div className='d-flex flex-column gap-1'>
                      <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                        {t('rollingPercent')}
                      </BForm.Label>
                      <BForm.Control
                        type='number'
                        name='rollingPercent'
                        placeholder={t('enterValueInPercent')}
                        value={values.rollingPercent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component='div'
                        name='rollingPercent'
                        className='text-danger mt-1'
                      />
                    </div>

                  </Col>
                  {isCompensation
                    ? (
                      <>
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('bonus_limit')}
                            </BForm.Label>
                            <BForm.Select
                              name='limitType'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={
                                t('select_bonus_limit')
                              }
                              value={values?.limitType}
                            >
                              <option key='' value=''>{
                                t('select_bonus_limit')
                              }
                              </option>
                              {
                                LIMITTYPE?.map((item, index) => (
                                  <option key={index} value={item.value}>{t(item.label.toLowerCase())}</option>
                                ))
                              }

                            </BForm.Select>
                            <ErrorMessage
                              component='div'
                              name='limitType'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                        <Col sm={4} className='mb-3'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('customLimit')}
                            </BForm.Label>

                            <BForm.Control
                              name='customLimit'
                              type='number'
                              min={1}
                              placeholder={t('enterValue')}
                              value={values.customLimit}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component='div'
                              name='customLimit'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                        {/* <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('minutes_for_bonus_given')}
                            </BForm.Label>

                            <BForm.Control
                              name='minsForBonusGiven'
                              type='number'
                              min={1}
                              placeholder={t('enterValue')}
                              value={values.minsForBonusGiven}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component='div'
                              name='minsForBonusGiven'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col> */}
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('compensation_target')}
                            </BForm.Label>

                            <BForm.Control
                              name='compensationTarget'
                              type='number'
                              min={1}
                              placeholder={t('enterValue')}
                              value={values.compensationTarget}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />

                            <ErrorMessage
                              component='div'
                              name='compensationTarget'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                      </>
                      )
                    : null}
                  {isRecurring
                    ? (
                      <>
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('withdrawal_rate')}
                            </BForm.Label>

                            <Trigger message='Value will consider in percentage'>
                              <BForm.Control
                                name='withdrawalRate'
                                type='number'
                                min={1}
                                placeholder={t('enterValueInPercent')}
                                value={values.withdrawalRate}
                                onInput={handleChange}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Trigger>

                            <ErrorMessage
                              component='div'
                              name='withdrawalRate'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('reoccurring_deposit_rate')}
                            </BForm.Label>

                            <Trigger message='Value will consider in percentage'>
                              <BForm.Control
                                name='reoccuringDepositRate'
                                type='number'
                                min={1}
                                placeholder={t('enterValueInPercent')}
                                value={values.reoccuringDepositRate}
                                onInput={handleChange}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Trigger>

                            <ErrorMessage
                              component='div'
                              name='reoccuringDepositRate'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                      </>
                      )
                    : null}
                  {isPayback
                    ? (
                      <>
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('slotCatBonusPercent')}
                            </BForm.Label>

                            <Trigger message='Value will consider in percentage'>
                              <BForm.Control
                                name='slotCatBonusPercent'
                                type='number'
                                min={1}
                                placeholder={t('enterValueInPercent')}
                                value={values.slotCatBonusPercent}
                                onInput={handleChange}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Trigger>

                            <ErrorMessage
                              component='div'
                              name='slotCatBonusPercent'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                        <Col sm={6} xxl={4} className='mb-2'>
                          <div className='d-flex flex-column gap-1'>
                            <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                              {t('liveCatBonusPercent')}
                            </BForm.Label>

                            <Trigger message='Value will consider in percentage'>
                              <BForm.Control
                                name='liveCatBonusPercent'
                                type='number'
                                min={1}
                                placeholder={t('enterValueInPercent')}
                                value={values.liveCatBonusPercent}
                                onInput={handleChange}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Trigger>

                            <ErrorMessage
                              component='div'
                              name='liveCatBonusPercent'
                              className='text-danger mt-1'
                            />
                          </div>
                        </Col>
                      </>
                      )
                    : null}

                  <Col sm={12}>
                    <div className='mt-4 d-flex align-align-items-center gap-4'>
                      <Button
                        variant='outline-warning'
                        onClick={() => navigate(-1)}
                        className='ml-2'
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        type='submit'
                        className='ml-2'
                        variant='outline-success'
                      >
                        {t('submit')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>

      </div>
    </div>
  )
}

export default BonusCreation
