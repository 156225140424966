import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, errorInfo) {
    console.log(error, errorInfo)
    this.setState({
      hasError: true
    })
  }

   handleReloadPage = () => {
     window.location.reload()
   };

   render () {
     const { t } = this.props

     if (this.state.hasError) {
       return (
         <div style={{ minHeight: '100vh' }} className='d-flex justify-content-center align-items-center flex-column '>
           <p className=''>
             {t('somethingWentWrong')}
           </p>
           <Button
             variant='primary'
             onClick={this.handleReloadPage}
           >{t('goBack')}
           </Button>
         </div>
       )
     }

     return this.props.children
   }
}

export default withTranslation()(ErrorBoundary)
