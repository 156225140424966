import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllCurrencies,
  createCurrency,
  editCurrency,
  getCurrencyById,
  getAllTenantCurrencies,
  getTenantsCurrencies
} from '../../utils/apiCalls'

import {
  getAllCurrenciesStart,
  getAllCurrenciesSuccess,
  getAllCurrenciesFailure,
  createCurrenciesStart,
  createCurrenciesSuccess,
  createCurrenciesFailure,
  editCurrencyStart,
  editCurrencySuccess,
  editCurrencyFailure,
  getCurrencyByIdStart,
  getCurrencyByIdSuccess,
  getCurrencyByIdFailure,
  getAllTenantCurrenciesStart,
  getAllTenantCurrenciesFailure,
  getAllTenantCurrenciesSuccess,
  getTenantCurrenciesStart,
  getTenantCurrenciesSuccess,
  getTenantCurrenciesFailure
} from '../redux-slices/currencies'

import { toast } from '../../components/Toast'
import { SuperAdminRoutes } from '../../routes'

export default function * currenciesWatcher () {
  yield takeLatest(getAllCurrenciesStart.type, getAllCurrenciesWorker)
  yield takeLatest(createCurrenciesStart.type, createCurrenciesWorker)
  yield takeLatest(editCurrencyStart.type, editCurrencyWorker)
  yield takeLatest(getCurrencyByIdStart.type, getCurrencyByIdWorker)
  yield takeLatest(getAllTenantCurrenciesStart.type, getAllTenantCurrenciesWorker)
  yield takeLatest(getTenantCurrenciesStart.type, getTenantcurrenciesWorker)
}

function * getAllCurrenciesWorker (action) {
  try {
    const { limit, pageNo } = action && action.payload

    const { data } = yield getAllCurrencies({
      limit,
      pageNo
    })

    yield put(getAllCurrenciesSuccess(data?.data?.sortedCurrency))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCurrenciesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllTenantCurrenciesWorker (action) {
  try {
    const { limit, pageNo } = action && action.payload

    const { data } = yield getAllTenantCurrencies({
      limit,
      pageNo
    })

    yield put(getAllTenantCurrenciesSuccess(data?.data?.sortedCurrency))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTenantCurrenciesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createCurrenciesWorker (action) {
  try {
    const { name, code, exchangeRate, symbol, type, isPrimary, dailyLimit, loyaltyPoint, navigate } =
      action && action.payload

    yield createCurrency({ name, code, exchangeRate, symbol, type, isPrimary, dailyLimit, loyaltyPoint })

    yield put(createCurrenciesSuccess())

    yield toast('Currency Added Successfully', 'success')

    navigate(SuperAdminRoutes.Currencies)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createCurrenciesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * editCurrencyWorker (action) {
  try {
    const {
      currencyId,
      name,
      exchangeRate,
      code,
      symbol,
      type,
      isPrimary,
      dailyLimit,
      loyaltyPoint,
      navigate
    } = action && action.payload

    yield editCurrency({
      currencyId,
      name,
      code,
      exchangeRate,
      symbol,
      type,
      dailyLimit,
      isPrimary,
      loyaltyPoint
    })

    yield put(editCurrencySuccess())

    yield toast('Currency Edited Successfully', 'success')

    navigate(SuperAdminRoutes.Currencies)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(editCurrencyFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCurrencyByIdWorker (action) {
  try {
    const { currencyId } = action && action.payload

    const { data } = yield getCurrencyById(currencyId)

    yield put(getCurrencyByIdSuccess(data?.data?.currencyDetail))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCurrencyByIdFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTenantcurrenciesWorker (action) {
  try {
    const { data } = yield getTenantsCurrencies(action.payload)

    yield put(getTenantCurrenciesSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantCurrenciesFailure(e?.response?.data?.errors[0]?.description))
  }
}
