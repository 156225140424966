import { takeLatest, put, select } from 'redux-saga/effects'
import {
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getTenantUserDetailsStart,
  getTenantUserDetailsSuccess,
  getTenantUserDetailsFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  createTenantUserStart,
  createTenantUserSuccess,
  createTenantUserFailure,
  updateTenantUserStart,
  updateTenantUserSuccess,
  updateTenantUserFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserComplete,
  updateUserStart,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  sendUserEmailStart,
  sendUserEmailComplete,
  getEmailLogsStart,
  getEmailLogsComplete,
  getEmailLogsFailure,
  updateUserCurrPermissionStart,
  updateUserCurrPermissionComplete,
  updateUserCurrPermissionFailure,
  getCurrentCashbackDataStart,
  getCurrentCashbackDataComplete,
  getCurrentCashbackDataFailure,
  updateTenantFlagStart,
  updateTenantFlagSuccess,
  updateTenantFlagFaliure,
  getUserTransactionDetailsStart,
  getUserTransactionDetailsSuccess,
  getUserTransactionDetailsFaliure,
  updateKycRequestStart,
  updateKycRequestSuccess,
  updateKycRequestFaliure,
  updateTenantUserNoteStart,
  updateTenantUserNoteSuccess,
  updateTenantUserNoteFailure,
  updateTenantBetNoteStart,
  updateTenantBetNoteSuccess,
  updateTenantBetNoteFailure,
  updateTenantUserKycDetailsStart,
  updateTenantUserKycDetailsSuccess,
  updateTenantUserKycDetailsFailure,
  getTenantUserGGRStart,
  getTenantUserGGRSuccess,
  getTenantUserGGRFailure
} from '../redux-slices/tenantUsers'
import {
  createTenantUser,
  getAllTenantUsers,
  getTenantUserDetails,
  getUserFields,
  updateTenantUser,
  tenantViewToggleStatus,
  updateUserFields,
  updateTags,
  getDuplicateUsers,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  disableUser,
  setSessionLimit,
  updateUserAffiliate,
  addUserAffiliate,
  verifyPlayerEmail,
  resetUserPassword,
  resetPasswordEmail,
  updateUser,
  getCashbackData,
  sendUserEmail,
  getEmailLogs,
  updateUserCurrPermission,
  getCurrentCashbackData,
  updateTenantFlag,
  getUserTransactionDetails,
  updateKycStatus,
  updateUserNote,
  updateBetNote,
  updateKycDetails,
  getUserGGR
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { TenantRoutes } from '../../routes'
import { formatDateYMD, formatDate } from '../../utils/dateFormatter'
import { getUserDocumentStart } from '../redux-slices/tenantsFetchData'
import { getUserStart, getSAUserDocumentStart } from '../redux-slices/fetchData'
import { getAllTenantCasinoTransactionsStart } from '../redux-slices/tenantTransactions'
const getUserData = state => state.tenantUsers
export default function * tenantUsersWatcher () {
  yield takeLatest(getAllTenantUsersStart.type, getAllTenantUsersWorker)
  yield takeLatest(updateTenantUserStatusStart.type, updateTenantUserStatusWorker)
  yield takeLatest(getTenantUserDetailsStart.type, getTenantUserDetailsWorker)
  yield takeLatest(updateTenantFlagStart.type, updateTenantFlagWorker)
  yield takeLatest(getUserFieldsStart.type, getUserFieldsWorker)
  yield takeLatest(updateUserFieldsStart.type, updateUserFieldsWorker)
  yield takeLatest(createTenantUserStart.type, createTenantUserWorker)
  yield takeLatest(updateTenantUserStart.type, updateTenantUserWorker)
  yield takeLatest(updateTagsStart.type, updateTagsWorker)
  yield takeLatest(getDuplicateUsersStart.type, getDuplicateUsersWorker)
  yield takeLatest(updateLimitsStart.type, updateLimitsWorker)
  yield takeLatest(disableUserStart.type, disableUserWorker)
  yield takeLatest(updateUserAffiliateStart.type, updateUserAffiliateWorker)
  yield takeLatest(addAffiliateUserStart.type, addAffiliateUserWorker)
  yield takeLatest(verifyUserEmailStart.type, verifyUserEmailWorker)
  yield takeLatest(resetPasswordEmailStart.type, resetPasswordEmailWorker)
  yield takeLatest(resetUserPasswordStart.type, resetUserPasswordWorker)
  yield takeLatest(updateUserStart.type, updateUserWorker)
  yield takeLatest(getCashbackDataStart.type, getCashbackDataWorker)
  yield takeLatest(getCurrentCashbackDataStart.type, getCurrentCashbackDataWorker)
  yield takeLatest(sendUserEmailStart.type, sendUserEmailWorker)
  yield takeLatest(getEmailLogsStart.type, getEmailLogsWorker)
  yield takeLatest(updateUserCurrPermissionStart.type, updateUserCurrPermissionWorker)
  yield takeLatest(getUserTransactionDetailsStart.type, getUserTransactionDetailsWorker)
  yield takeLatest(updateKycRequestStart.type, updateKycRequestStatusWorker)
  yield takeLatest(updateTenantUserNoteStart.type, updateTenantUserNoteWorker)
  yield takeLatest(updateTenantBetNoteStart.type, updateTenantBetNoteWorker)
  yield takeLatest(updateTenantUserKycDetailsStart.type, updateTenantUserKycDetailsWorker)
  yield takeLatest(getTenantUserGGRStart.type, getTenantUserGGRWorker)
}

function * getTenantUserGGRWorker (action) {
  try {
    const {
      startDate,
      endDate,
      tenantId,
      userId,
      contentFilter,
      isAffiliate,
      isAgent
    } = action && action.payload
    const { data } = yield getUserGGR({
      startDate,
      endDate,
      tenantId,
      userId,
      ...contentFilter,
      isTenant: true,
      isAffiliate,
      isAgent
    })
    yield put(getTenantUserGGRSuccess(data?.data?.totalGgrAmount))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTenantUserGGRFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateTenantUserKycDetailsWorker (action) {
  try {
    const {
      userId,
      phone,
      phoneCode,
      accountNumber,
      bankName,
      isTenant,
      isAffiliate,
      isAgent
    } = action && action.payload
    const { data } = yield updateKycDetails({
      userId,
      phone,
      phoneCode,
      accountNumber,
      bankName,
      isTenant,
      isAffiliate,
      isAgent
    })
    yield put(updateTenantUserKycDetailsSuccess(data?.data?.message))
    yield toast(data?.data?.message, 'success')
    yield put(getTenantUserDetailsStart({
      userId,
      isAffiliate,
      isAgent
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantUserKycDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantUserNoteWorker (action) {
  try {
    const {
      userId,
      note,
      isTenant,
      fetchTenantUser
    } = action && action.payload
    const { data } = yield updateUserNote({
      userId,
      note,
      isTenant
    })
    yield put(updateTenantUserNoteSuccess(data?.data?.message))
    yield toast(data?.data?.message, 'success')
    fetchTenantUser()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantUserNoteFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateTenantBetNoteWorker (action) {
  try {
    const {
      transactionId,
      note,
      isTenant,
      userId
    } = action && action.payload
    const { data } = yield updateBetNote({
      transactionId,
      note,
      isTenant
    })
    yield put(updateTenantBetNoteSuccess(data?.data?.message))
    yield put(getAllTenantCasinoTransactionsStart({
      limit: 15,
      pageNo: 1,
      userId
    }))
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantBetNoteFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateKycRequestStatusWorker (action) {
  try {
    const {
      userId,
      kycStatus,
      isAffiliate
    } = action && action.payload
    const { user } = yield select(getUserData)
    const { data } = yield updateKycStatus({
      userId,
      kycStatus,
      isAffiliate
    })
    yield put(updateKycRequestSuccess(data?.data?.message))
    yield toast(data?.data?.message, kycStatus ? 'success' : 'error')
    yield put(getTenantUserDetailsStart({ ...user, isAffiliate, kycStatus: kycStatus ? 'ACCEPTED' : 'REJECTED' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateKycRequestFaliure(e?.response?.data?.errors[0].description))
  }
}
function * getUserTransactionDetailsWorker (action) {
  try {
    const {
      form
    } = action && action.payload

    const { data } = yield getUserTransactionDetails({
      ...form
    })
    yield put(getUserTransactionDetailsSuccess(data?.data?.walletTranactionDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getUserTransactionDetailsFaliure(e?.response?.data?.errors[0].description))
  }
}
function * updateTenantFlagWorker (action) {
  try {
    const {
      reason,
      description,
      userId,
      redFlag,
      isAffiliate,
      isAgent
    } = action && action.payload

    const { user } = yield select(getUserData)
    const { data } = yield updateTenantFlag({
      userId,
      redFlag,
      reason,
      description,
      isAffiliate,
      isAgent
    })
    yield put(updateTenantFlagSuccess(data))
    yield toast('Flag Change Successfully', 'success')
    yield put(getTenantUserDetailsStart({
      ...user,
      isFlaggedRed: redFlag,
      redFlagReason: description,
      redFlagDescription: reason,
      isAffiliate: isAffiliate,
      isAgent: isAgent
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateTenantFlagFaliure(e?.response?.data?.errors[0].description))
  }
}
function * getAllTenantUsersWorker (action) {
  try {
    const {
      limit, search, pageNo, isActive,
      affiliateId, kycStatus, kycSeniorStatus, affiliateSearch,
      dobStart, dobEnd, myUserId,
      orderBy = '', sortBy = '',
      registrationDateStart,
      registrationDateEnd, isInternal, countryCode = '', ftdTo, ftdFrom,
      usersType, redFlag, isAffiliate, isAgent, adminId = '', agentId,
      isAnonymous
    } =
      action && action.payload

    const { data } = yield getAllTenantUsers({
      limit,
      search,
      pageNo,
      isActive,
      redFlag,
      affiliateId: affiliateId || '',
      kycStatus,
      kycSeniorStatus,
      affiliateSearch,
      dobStart,
      dobEnd,
      userId: myUserId,
      orderBy,
      sortBy,
      registrationDateStart,
      registrationDateEnd,
      isInternal,
      countryCode,
      ftdTo,
      ftdFrom,
      usersType,
      isAffiliate,
      isAgent,
      adminId,
      agentId,
      isAnonymous
    })
    yield put(getAllTenantUsersSuccess(data?.data?.users))
  } catch (e) {
    yield put(getAllTenantUsersFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTenantUserStatusWorker (action) {
  try {
    const {
      kycStatus, userId, status, limit, pageNo, search, isActive, code, reason = false,
      isPlayersPage = false, isUserPage = false, affiliateSearch, description,
      myUserId, dobStart, dobEnd, orderBy, sortBy, usersType,
      registrationDateStart, registrationDateEnd, isInternal, country = '', redFlag
    } =
      action && action.payload

    reason && (yield tenantViewToggleStatus({ userId, status, code, reason, description }))
    !reason && (yield tenantViewToggleStatus({ userId, status, code }))
    yield put(updateTenantUserStatusSuccess())
    yield toast('User Status Updated Successfully', 'success')
    isPlayersPage && (yield put(getAllTenantUsersStart({
      limit,
      pageNo,
      search,
      isActive,
      redFlag,
      kycStatus,
      affiliateSearch,
      myUserId,
      dobStart,
      dobEnd,
      orderBy,
      sortBy,
      registrationDateStart,
      registrationDateEnd,
      isInternal,
      country,
      usersType: usersType || 'all'
    })))
    isUserPage && (yield put(getTenantUserDetailsStart({ userId })))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantUserDetailsWorker (action) {
  try {
    const { userId, isAffiliate, isAgent } = action && action.payload

    const { data } = yield getTenantUserDetails({
      userId,
      isAffiliate,
      isAgent
    })

    data?.data?.getUser.dateOfBirth &&
      (data.data.getUser.dateOfBirth = formatDateYMD(
        data.data.getUser.dateOfBirth
      ))

    yield put(getTenantUserDetailsSuccess(data?.data?.getUser))
  } catch (e) {
    yield put(getTenantUserDetailsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getUserFieldsWorker () {
  try {
    const { data } = yield getUserFields()

    yield put(getUserFieldsSuccess(data?.data?.tenantRegistration))
  } catch (e) {
    yield put(getUserFieldsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserFieldsWorker (action) {
  try {
    const data = action && action.payload

    yield updateUserFields(data)

    yield put(updateUserFieldsSuccess())

    yield toast('Fields updated', 'success')

    yield put(getUserFieldsStart())
  } catch (e) {
    yield put(updateUserFieldsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createTenantUserWorker (action) {
  try {
    const { initialState, navigate } = action && action.payload
    initialState.dateOfBirth &&
      (initialState.dateOfBirth = formatDate(initialState.dateOfBirth))

    // const encryptedPass = Buffer.from(initialState.password).toString('base64')
    const newPassword = initialState.password
    initialState.password = newPassword
    initialState.confirmPassword = newPassword

    yield createTenantUser(initialState)

    yield put(createTenantUserSuccess())

    yield toast('User Created Successfully', 'success')
    navigate(TenantRoutes.Users)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createTenantUserFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantUserWorker (action) {
  try {
    const { initialState, navigate } = action && action.payload
    initialState.dateOfBirth &&
      (initialState.dateOfBirth = formatDate(initialState.dateOfBirth))

    if (
      initialState.password !== '' &&
      initialState.password !== null &&
      initialState.password !== undefined
    ) {
      // const encryptedPass = Buffer.from(initialState.password).toString(
      //   'base64'
      // )
      const newPassword = initialState.password
      initialState.password = newPassword
      initialState.confirmPassword = newPassword
    } else {
      initialState.password = null
      initialState.confirmPassword = null
    }

    yield updateTenantUser(initialState)

    yield put(updateTenantUserSuccess())

    yield toast('User Updated Successfully', 'success')
    navigate(TenantRoutes.Users)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantUserFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTagsWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield updateTags({ data, isTenant })
    yield put(updateTagsComplete())

    if (isTenant) yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    else yield put(getUserStart({ userId: data?.userId }))

    if (isTenant) yield put(getUserDocumentStart({ userId: data?.userId }))
    else yield put(getSAUserDocumentStart({ userId: data?.userId }))

    yield toast('Tags Updated Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTagsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getDuplicateUsersWorker (action) {
  try {
    const { limit, pageNo, userId, isTenant } = action && action.payload
    const { data } = yield getDuplicateUsers({ limit, pageNo, userId, isTenant })

    yield put(getDuplicateUsersSuccess(data?.data?.users))
  } catch (e) {
    yield put(getDuplicateUsersFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateLimitsWorker (action) {
  try {
    const { isTenant, data } = action && action.payload

    data?.type === 'wager'
      ? yield setDailyLimit({ isTenant, data })
      : (
          data?.type === 'deposit'
            ? yield setDepositLimit({ isTenant, data })
            : yield setLossLimit({ isTenant, data })
        )
    yield put(updateLimitsComplete())

    data?.reset
      ? yield toast('Limits Reset Successfully', 'success')
      : yield toast('Limits Updated Successfully', 'success')

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateLimitsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * disableUserWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    data?.type
      ? yield disableUser({ isTenant, data })
      : yield setSessionLimit({ isTenant, data })

    yield put(disableUserComplete())

    yield toast(`Account ${data?.reset ? 'Enabled' : 'Disabled'} Successfully`, 'success')

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))
  } catch (e) {
    yield put(disableUserComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserAffiliateWorker (action) {
  try {
    const { data, isSuperAdmin, setShowRemoveAffiliate } = action && action.payload

    yield updateUserAffiliate({ isSuperAdmin, data })

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    if (isSuperAdmin) {
      yield put(getUserStart({ userId: data?.userId }))
    } else {
      yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    }

    yield toast('affiliateRemovedSuccess', 'success')
  } catch (e) {
    const { setShowRemoveAffiliate } = action && action.payload

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * addAffiliateUserWorker (action) {
  try {
    const { data, isSuperAdmin, setShowAddAffiliate } = action && action.payload

    yield addUserAffiliate({ isSuperAdmin, data })

    yield setShowAddAffiliate(prev => false)

    yield put(addAffiliateUserComplete())

    if (isSuperAdmin) {
      yield put(getUserStart({ userId: data?.userId }))
    } else {
      yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    }

    yield toast('affiliateAddedSuccess', 'success')
  } catch (e) {
    const { setShowAddAffiliate } = action && action.payload

    yield put(addAffiliateUserComplete())

    yield setShowAddAffiliate(prev => false)

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * verifyUserEmailWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield verifyPlayerEmail({ isTenant, data })

    yield put(verifyUserEmailComplete())

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))

    yield toast('emailVerifiedSuccess', 'success')
  } catch (e) {
    yield put(verifyUserEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * resetPasswordEmailWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield resetPasswordEmail({ isTenant, data })

    yield put(resetPasswordEmailComplete())

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))

    yield toast('resetLinkSentSuccess', 'success')
  } catch (e) {
    yield put(resetPasswordEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * resetUserPasswordWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield resetUserPassword({ isTenant, data })

    yield put(resetUserPasswordComplete())

    yield toast('passwordResetSuccessfully', 'success')
  } catch (e) {
    yield put(resetUserPasswordComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserWorker (action) {
  try {
    const { isTenant, data } = action && action.payload

    yield updateUser({ data, isTenant })
    yield put(updateUserComplete())
    yield put(getDuplicateUsersStart({
      limit: 15,
      pageNo: 1,
      userId: data?.userId,
      isTenant
    }))
    yield toast('userDataUpdatedSuccessfully', 'success')

    if (!isTenant) yield put(getUserStart({ userId: data?.userId }))
    else yield put(getTenantUserDetailsStart({ userId: data?.userId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateUserComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getCashbackDataWorker (action) {
  try {
    const { isTenant, userId, level, tenantId, currencyCode } = action && action.payload

    const { data } = yield getCashbackData({ isTenant, userId, level, tenantId, currencyCode })

    yield put(getCashbackDataComplete(data?.data))
  } catch (e) {
    yield put(getCashbackDataFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCurrentCashbackDataWorker (action) {
  try {
    const { data } = yield getCurrentCashbackData(action.payload)

    yield put(getCurrentCashbackDataComplete(data?.data))
  } catch (e) {
    yield put(getCurrentCashbackDataFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * sendUserEmailWorker (action) {
  try {
    const { data, isTenant, resetForm } = action && action.payload

    yield sendUserEmail({ data, isTenant, resetForm })

    yield put(sendUserEmailComplete())

    yield resetForm()

    yield toast('emailSentSuccessfully', 'success')
  } catch (e) {
    yield put(sendUserEmailComplete(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getEmailLogsWorker (action) {
  try {
    const { isTenant, limit, pageNo, search, role, userId } = action && action.payload

    const { data } = yield getEmailLogs({ isTenant, limit, pageNo, search, role: role?.toLowerCase(), userId })

    yield put(getEmailLogsComplete(data?.data?.emailLogs))
  } catch (e) {
    yield put(getEmailLogsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserCurrPermissionWorker (action) {
  try {
    const { userId, isTenant } = action && action.payload

    const { data } = yield updateUserCurrPermission({ isTenant, userId })

    yield put(updateUserCurrPermissionComplete(data?.data?.currencyEditable))
  } catch (e) {
    yield put(updateUserCurrPermissionFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
