import React from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import UpdateLimitModal from './updateLimitModal'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import useAdminTabContent from './hooks/useAdminTabContent'
import { TABS_ADMIN_KEYS } from './BonusAdminTabContent'
import AdminPlayersTab from './AdminPlayersTab'
import RemoveModal from '../../Modals/RemoveModal'
import BonusDetailModal from './BonusDetailModal'
const AdminTabListing = ({ selectedTab }) => {
  const {
    t,
    adminAgentsAndAffiliatesListing: data,
    loading,
    limit,
    setLimit,
    page,
    setPage,
    debounceFn,
    searchRef,
    isChecked,
    handleCheck,
    bonusDetail,
    isLimitUpdateModalOpen,
    setLimitIsUpdateModalOpen,
    handleUpdateLimit,
    setSelectedData,
    selectedData,
    updateLoading,
    isRemoveModalOpen,
    setIsRemoveModalOpen,
    setRemovedData,
    handleYesRemove,
    entitySelectedType,
    entityBonusDetails,
    isEntityDetailModalOpen,
    setIsEntityDetailModalOpen,
    getEntityBonusDetails,
    handleResetEntityDetails
  } = useAdminTabContent({ selectedTab })

  return (
    <>
      {selectedTab === TABS_ADMIN_KEYS.affiliatePlayer || selectedTab === TABS_ADMIN_KEYS.seniorPlayer
        ? <AdminPlayersTab key={selectedTab} updateLoading={updateLoading} bonusDetail={entityBonusDetails || bonusDetail} t={t} handleUpdateLimit={handleUpdateLimit} />
        : (
          <div>
            <div className='mb-2 d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center '>
                <Form.Label
                  style={{
                    width: 'auto',
                    marginBottom: '0',
                    marginRight: '15px',
                    fontSize: '13px'
                  }}
                >
                  {t('search')}
                </Form.Label>
                <Form.Control
                  ref={searchRef}
                  type='search'
                  placeholder={t('search')}
                  size='sm'
                  style={{
                    width: '230px',
                    marginRight: '10px',
                    maxHeight: '15px',
                    fontSize: '13px'
                  }}
                  onChange={(e) => {
                    debounceFn(e)
                    setPage(1)
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  if (selectedData?.length <= 1) {
                    toast.info(t('selectMoreThanOne'))
                    return
                  }
                  setLimitIsUpdateModalOpen(true)
                  handleResetEntityDetails()
                }} variant='outline-success'
              >
                {t('bulkUpdate')}
              </Button>
            </div>
            <div>
              <Table
                bordered
                striped
                responsive
                hover
                size='sm'
                className='text-center '
              >
                <thead className='thead-dark'>
                  <tr>
                    {['sr.No', 'username', 'name', 'bonusConfigured', 'action']?.map((h, idx) => (
                      <th key={`T-table_heading ${idx}`}>{t(h)}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {data && !loading &&
            data?.rows?.map(
              (item, index) => {
                let Id
                if (selectedTab === TABS_ADMIN_KEYS.affiliate) {
                  Id = item?.affiliateId
                } else if (selectedTab === TABS_ADMIN_KEYS.agent || selectedTab === TABS_ADMIN_KEYS.affiliateAgent) {
                  Id = item?.agentId
                }
                return (
                  <tr key={`bonus-list ${index}`}>
                    <td>
                      <div className='d-flex gap-1 align-items-center justify-content-center'>
                        <label className='me-2 d-inline-flex' htmlFor={Id}>
                          <input
                            onChange={(e) => handleCheck(e, Id)}
                            style={{ width: 20, height: 20 }}
                            checked={!!isChecked(Id)}
                            value={Id}
                            type='checkbox'
                            id={Id}
                          />
                        </label>
                        {(index + 1) + (limit * (page - 1))}
                      </div>
                    </td>
                    <td>{item?.username}</td>
                    <td>{item?.legalName}</td>
                    <td className={item?.hasBonus ? 'table-success' : 'table-danger'}>{item?.hasBonus ? t('true') : t('false')}</td>

                    <td>
                      <Trigger message={t('edit')}>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() => {
                            item?.hasBonus && getEntityBonusDetails({
                              entityId: Id,
                              bonusId: bonusDetail?.bonusId
                            })
                            setLimitIsUpdateModalOpen(true)
                            setSelectedData([Id])
                          }}

                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>
                      {item?.hasBonus && (
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() => {
                              getEntityBonusDetails({
                                entityId: Id,
                                bonusId: bonusDetail?.bonusId
                              })
                              setIsEntityDetailModalOpen(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      )}
                      {item?.hasBonus && (
                        <Trigger message={t('remove')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() => {
                              setIsRemoveModalOpen(true)
                              setRemovedData({
                                entityId: Id,
                                entityType: entitySelectedType,
                                bonusId: bonusDetail?.bonusId

                              })
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </Trigger>
                      )}
                    </td>

                  </tr>
                )
              }
            )}

                  {data?.count === 0 && !loading && (
                    <tr>
                      <td colSpan={8} className='text-danger text-center'>
                        {t('noDataFound')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {data?.count > 0 && !loading && (
              <PaginationComponent
                page={data?.count < page ? setPage(1) : page}
                totalPages={Math.ceil(data?.count / limit)}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={data?.count}
              />
            )}
          </div>
          )}
      <UpdateLimitModal
        handleClose={() => {
          setLimitIsUpdateModalOpen(false)
          setPage(1)
          handleResetEntityDetails()
        }}
        handleUpdateYes={handleUpdateLimit}
        show={isLimitUpdateModalOpen}
        bonusDetail={entityBonusDetails || bonusDetail}
        loading={updateLoading}

      />
      <BonusDetailModal
        show={isEntityDetailModalOpen}
        handleClose={() => {
          setIsEntityDetailModalOpen(false)
          handleResetEntityDetails()
        }}
      />
      <RemoveModal
        show={isRemoveModalOpen}
        loading={updateLoading}
        handleClose={() => {
          setIsRemoveModalOpen(false)
        }}
        handleUpdateYes={handleYesRemove}
      />

    </>
  )
}

export default AdminTabListing
