import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBannerSuperAdminStart, getAllSABannersStart, updateSABannerStart, uploadSABannerStart } from '../../../store/redux-slices/superAdminSettings'
import useCheckPermission from '../../../utils/checkPermission'
import { getBannerTypesStart } from '../../../store/redux-slices/admins'
import { useTranslation } from 'react-i18next'

const useBannerManagement = () => {
  const { t } = useTranslation()
  const { loading, SABanners, updateLoading, deleteLoading } = useSelector(state => state.superAdminSettings)
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { bannerTypes, bannerTypeLoading } = useSelector(state => state.admins)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [type, setType] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [lang, setLang] = useState('ko')
  const { languages } = useSelector(state => state.languages)
  const totalPages = Math.ceil(SABanners?.count / limit)
  const [validation, setValidation] = useState({
    minRequiredWidth: '',
    minRequiredHeight: '',
    maxRequiredWidth: '',
    maxRequiredHeight: ''
  })

  useEffect(() => {
    dispatch(getBannerTypesStart())
  }, [])

  useEffect(() => {
    dispatch(getAllSABannersStart({
      limit: limit,
      pageNo: page,
      tenantId: selectedClient
    }))
  }, [refetch, limit,
    page, selectedClient])

  const handleCreateEdit = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }
  const handleDelete = (type, data) => {
    setType(type)
    setData(data)
    setDeleteModalShow(true)
  }
  const handleDeleteYes = () => {
    dispatch(deleteBannerSuperAdminStart({
      data: { ...data, lang },
      handleClose: () => {
        setDeleteModalShow(false)
        setRefetch(prev => !prev)
      }
    }))
  }

  const createUpdate = (item) => {
    const formData = new FormData()
    formData.append('tenantId', item?.tenantId)
    formData.append('lang', item?.languageCode)
    formData.append('bannerType', item?.bannerType)
    if (item?.file) {
      formData.append('file', item?.file)
    } else {
      formData.append('file', data?.file)
    }
    if (type === 'Update') {
      if (data?.imageKey) {
        formData.append('imageKey', data?.imageKey)
      }
    }

    type === 'Create'
      ? dispatch(uploadSABannerStart({
          data: formData,
          setShow,
          setRefetch
        }))
      : dispatch(updateSABannerStart({
        data: formData,
        setShow,
        setRefetch
      }))
  }

  return {
    loading,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    SABanners,
    handleCreateEdit,
    type,
    data,
    setShow,
    show,
    createUpdate,
    clientsFilterData,
    dispatch,
    isHidden,
    validation,
    setValidation,
    bannerTypes,
    bannerTypeLoading,
    handleDelete,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    limit,
    setLimit,
    page,
    setPage,
    t,
    lang,
    setLang,
    languages,
    totalPages,
    updateLoading,
    deleteLoading
  }
}

export default useBannerManagement
