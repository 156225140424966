import React from 'react'
import { Route } from 'react-router-dom'
import { AgentRoutes } from '../../../routes'
import PrivateRoute from '../../../components/PrivateRoute'

import CreateUser from '../../../common/CreateUser/CreateUser'
import Users from '../../Tenant/Users'
import UserDetails from '../../Tenant/UserDetails'
import CasinoReporting from '../../Tenant/CasinoReporting'
import TenantUserReporting from '../../Tenant/UserReporting'
import TenantUserHistory from '../../Tenant/UserHistory'
import BonusTransaction from '../../Tenant/BonusTransaction'
import NotFound from '../../NotFound'
import ProfilePage from '../../../components/ProfilePage'
import Dashboard from '../../Tenant/Dashboard'

const AgentPages = () => (
  <>

    <Route
      path={AgentRoutes.Users}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <Users isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.UserDetails}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <UserDetails isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.CreateUser}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <CreateUser isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.CasinoReporting}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <CasinoReporting isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.UserReporting}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <TenantUserReporting isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.UserHistory}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <TenantUserHistory isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.bonusTransactions}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <BonusTransaction isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.sportsBonusTransactions}
      element={
        <PrivateRoute isAgent module={{ Users: 'R' }}>
          <BonusTransaction isAgent isSportsBook />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.Profile}
      element={
        <PrivateRoute isAgent>
          <ProfilePage isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={AgentRoutes.Dashboard}
      element={
        <PrivateRoute isAgent>
          <Dashboard isAgent />
        </PrivateRoute>
      }
    />
    <Route
      path='*'
      element={<NotFound />}
    />

  </>
)

export default AgentPages
