import { Row, Col, Badge } from '@themesberg/react-bootstrap'
import React from 'react'
import {
  BONUSES,
  checkLabels,
  daysLabels,
  wageringRequirementType
} from './../components/CreateBonus/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
// import Parser from 'html-react-parser'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from '../../../../utils/storageUtils'

const GeneralDetails = ({ bonusDetail }) => {
  const navigate = useNavigate()
  return (
    <>
      <Row>
        <Col sm={4}>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Valid on Days</h3>
            </Col>
            <Col>
              {daysLabels?.map((day) => {
                return (
                  <Col
                    key={day}
                    className='d-flex'
                    style={{
                      verticalAlign: 'middle',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <p>{day}</p>
                    {bonusDetail?.validOnDays?.includes(day) && (
                      <div
                        className='rounded-circle mt-2 mx-2'
                        style={{
                          width: '10px',
                          height: '10px',
                          background: '#1aa509'
                        }}
                      />
                    )}
                  </Col>
                )
              })}
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Bonus Type</h3>
            </Col>
            <Col>
              <p>{bonusDetail?.bonusType === 'freespins' ? bonusDetail?.isSticky ? 'BONUS FREESPINS' : 'CASH FREESPINS' : bonusDetail?.bonusType?.toUpperCase()}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>{bonusDetail?.bonusType === 'balance' ? 'Min Balance Percentage' : 'Bonus Percentage'}</h3>
            </Col>
            <Col>
              <p>{bonusDetail?.depositBonusPercent}%</p>
            </Col>
          </Row>
          {!((bonusDetail?.bonusType === 'freespins' || bonusDetail?.bonusType === 'balance') && !bonusDetail?.isSticky) && bonusDetail?.bonusType !== 'promotion' &&
            <>
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Wagering Type</h3>
                </Col>
                <Col>
                  <p>{wageringRequirementType?.find(val => val.value === bonusDetail?.wageringRequirementType)?.label}</p>
                </Col>
              </Row>
              {bonusDetail?.bonusType === BONUSES.compensation_bonus.value && (
                <>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Daily Limit</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.daily_limit} {bonusDetail?.tenant?.primaryCurrency} </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Weekly Limit</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.weekly_limit} {bonusDetail?.tenant?.primaryCurrency}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Monthly Limit</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.monthly_limit} {bonusDetail?.tenant?.primaryCurrency}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Compensation Target</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.compensation_target} {bonusDetail?.tenant?.primaryCurrency}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Minutes for bonus given</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.minutes_for_bonus_given}</p>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Maximum Bonus Limit</h3>
                </Col>
                <Col>
                  <p>{formatNumber(bonusDetail?.maxBonusLimit) || 'N/A'}  {bonusDetail?.tenant?.primaryCurrency}</p>
                </Col>
              </Row>
              {bonusDetail?.bonusType === BONUSES.re_occurring_bonus.value && (
                <>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>Reocurring Deposit Rate</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.reoccuring_deposite_rate}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className='h6 text-nowrap'>WithDrawal Rate</h3>
                    </Col>
                    <Col>
                      <p>{bonusDetail?.withdrawl_rate}</p>
                    </Col>
                  </Row>
                </>
              )}
              {/* <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Wagering Multiplier </h3>
                </Col>
                <Col>
                  <p>{bonusDetail?.wageringMultiplier}</p>
                </Col>
              </Row> */}
            </>}

          {checkLabels(bonusDetail?.bonusType).map(({ label, value }) => {
            return (
              <Row key={label}>
                <Col>
                  <h3 className='h6 text-nowrap'>{label}</h3>
                </Col>
                <Col>
                  <Badge
                    className='mb-3'
                    bg={bonusDetail?.[value] ? 'success' : 'dark'}
                  >
                    <FontAwesomeIcon
                      icon={bonusDetail?.[value] ? faCheck : faTimes}
                    />
                  </Badge>
                </Col>
              </Row>
            )
          })}
          {/* {!['deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Show Validity</h3>
              </Col>
              <Col>
                <Badge
                  className='mb-3'
                  bg={bonusDetail?.other?.showBonusValidity ? 'success' : 'dark'}
                >
                  <FontAwesomeIcon
                    icon={bonusDetail?.other?.showBonusValidity ? faCheck : faTimes}
                  />
                </Badge>
              </Col>
            </Row>} */}
          {/* {bonusDetail?.bonusType !== 'freespins' && bonusDetail?.bonusType !== 'promotion' && bonusDetail?.bonusType !== 'balance' &&
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>isSticky</h3>
              </Col>
              <Col>
                <Badge
                  className='mb-3'
                  bg={bonusDetail?.isSticky ? 'success' : 'dark'}
                >
                  <FontAwesomeIcon
                    icon={bonusDetail?.isSticky ? faCheck : faTimes}
                  />
                </Badge>
              </Col>
            </Row>} */}
        </Col>
        <Col sm={4}>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Admin</h3>
            </Col>
            <Col>
              <p>{`${bonusDetail?.adminUser?.firstName} ${bonusDetail?.adminUser?.lastName}`}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Tenant</h3>
            </Col>
            <Col>
              <p>{`${bonusDetail?.tenant?.name}`}</p>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Code</h3>
            </Col>
            <Col>
              <p>{bonusDetail?.code}</p>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Description</h3>
            </Col>
            <Col>
              {bonusDetail?.description?.EN && Parser(bonusDetail?.description?.EN)}
            </Col>
          </Row> */}
          {(bonusDetail?.bonusType !== 'deposit' && bonusDetail?.bonusType !== 'wagering') &&
            <>
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Valid From</h3>
                </Col>
                <Col>
                  <p>{moment(bonusDetail?.validFrom).format('MM-DD-YYYY HH:mm ')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Valid To</h3>
                </Col>
                <Col>
                  <p>{moment(bonusDetail?.validTo).format('MM-DD-YYYY HH:mm')}</p>
                </Col>
              </Row>
            </>}
          {(bonusDetail?.bonusType === 'deposit' || bonusDetail?.bonusType === 'wagering') &&
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Time Period</h3>
              </Col>
              <Col>
                <p>{parseInt(bonusDetail?.other?.timePeriod) === 1 ? 'Daily' : (parseInt(bonusDetail?.other?.timePeriod) === 7 ? 'Weekly' : 'Monthly')}</p>
              </Col>
            </Row>}
          {/* <Row>
            <Col>
              <img src={bonusDetail?.imageUrl} height='200' width='300' alt='img' />
            </Col>
          </Row> */}
        </Col>

        <Col sm={12} className='px-4 py-2'>
          {(!bonusDetail?.gameIds && !bonusDetail?.user_id) &&
            <>
              <Button
                variant='warning'
                onClick={() =>
                  navigate(`/super-admin/bonus-users/${bonusDetail?.bonusId}/${bonusDetail?.tenantId}`)}
              >
                View Users
              </Button>
              <Button
                className='ms-4'
                variant='warning'
                onClick={() =>
                  navigate(`/super-admin/bonus-games/${bonusDetail?.bonusId}`)}
              >
                View Games
              </Button>

            </>}
          {bonusDetail?.user_id &&
            <>
              <Button
                variant='warning'
                onClick={() =>
                  navigate(`/super-admin/bonus-users/${bonusDetail?.bonusId}/${bonusDetail?.tenantId}`, {
                    state: {
                      bonusType: bonusDetail?.bonusType
                    }
                  })}
              >
                View Users
              </Button>

            </>}
          {bonusDetail?.gameIds &&
            <>
              <Button
                variant='warning'
                onClick={() =>
                  navigate(`/super-admin/bonus-games/${bonusDetail?.bonusId}`)}
              >
                View Games
              </Button>

            </>}

        </Col>

      </Row>
    </>
  )
}
export default GeneralDetails
