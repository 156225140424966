import { Col, Card, Button, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import useUserOverview from '../hooks/useUserOverview'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../../components/OverlayTrigger'
import Reason from './Reason'
import ConfirmationModal, { ResetUserPasswordModal } from '../../../../components/ConfirmationModal'

const Overview = ({
  setShowReasonModal,
  handleShow,
  addTag,
  getDuplicateUsers,
  user,
  isHidden,
  duplicateUsers,
  setShowModal,
  setFreespinModal,
  setIsInternalModal,
  updateUserAffiliateLoading,
  showRemoveAffiliate,
  removeAffiliateHandler,
  setEditModal,
  setPasswordEmail,
  setUserPassword,
  userCurrentStatus,
  loading,
  t,
  isAffiliate,
  isAgent
}) => {
  const {
    basicInfo,
    moreInfo,
    adminPermissions,
    showRedFlagReasonMoadl,
    setShowRedFlagReasonMoadl,
    handleFlag,
    handleRedFlagClose,
    setPasswordForm,
    passwordForm,
    handleUpdatePassword
    // handleStatus
  } = useUserOverview({
    user,
    isAffiliate,
    isAgent
  })
  return (
    <>
      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview'>
          <h6 className='h4-overview text-center mt-3'>{t('basicInfo')} <hr className='h4-hr' /></h6>
          <div className='div-overview'>
            {basicInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h7 style={{ fontSize: '14px' }} className='px-2'>{t(label)}</h7>
                    <div>
                      <span style={{ fontSize: '14px' }} className={`${subValue} px-2`}>{value || 'NA'}</span>
                      {!adminPermissions?.Users?.includes('RUI') && label === 'Email' &&
                        <Trigger message={t('notAllowedToView')}>
                          <Button
                            variant='outline-warning'
                            size='sm'
                            className='m-1'
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </Button>
                        </Trigger>}
                    </div>
                  </div>
                  )
            })}
          </div>
          <h6 className='h4-overview text-center mt-3'>{t('moreInfo')}<hr className='h4-hr' /></h6>
          <div className='div-overview'>
            {moreInfo?.map(({ label, value, subValue }) => {
              return (
                <div key={label} className='d-flex justify-content-between m-1'>
                  <h7 style={{ fontSize: '14px' }}>{t(label)}</h7>&nbsp;
                  <div>
                    <span style={{ fontSize: '14px' }} className={subValue}>{value || 'NA'}</span>
                    {!adminPermissions?.Users?.includes('RUI') && label === 'Phone Number' &&
                      <Trigger message={t('notAllowedToView')}>
                        <Button
                          variant='outline-warning'
                          size='sm'
                          className='m-1'
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Button>
                      </Trigger>}
                  </div>
                </div>
              )
            })}
          </div>
        </Card>
      </Col>

      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview'>
          <h6 className='h4-overview text-center mt-3'>{t('accountActions')}<hr className='h4-hr' /></h6>
          <div className='div-overview d-flex justify-content-center flex-wrap'>
            {!isAffiliate && !isAgent && (
              <Button
                variant={user?.isActive ? 'outline-danger' : 'outline-success'}
                hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                onClick={() => {
                  setShowReasonModal(true)
                  handleShow()
                }}
                className='actionButton'
              >
                {user?.isActive ? t('blackList') : t('whiteList')}
              </Button>
            )}

            <Button
              className='actionButton'
              variant={user?.isFlaggedRed ? 'outline-success' : 'outline-danger'}
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              disabled={!user?.isActive}
              onClick={() => { setShowRedFlagReasonMoadl(true) }}

            >
              {user && user?.isFlaggedRed ? t('greenFlag') : t('redFlag')}
            </Button>
            {showRemoveAffiliate &&
              <Button
                className='actionButton'
                variant='outline-danger'
                onClick={removeAffiliateHandler}
              >
                {t('removeAffiliate')}
                {updateUserAffiliateLoading && (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>}

            {showRedFlagReasonMoadl
              ? (!user?.isFlaggedRed)
                  ? <Reason
                      show={showRedFlagReasonMoadl}
                      handleClose={handleRedFlagClose}
                      markUserStatusInactive={handleFlag}
                      name={user?.legalName}
                      isRedFlagged={showRedFlagReasonMoadl}
                      isSuperAdmin='tenant'
                      setShow={setShowRedFlagReasonMoadl}
                    />
                  : <ConfirmationModal
                      show={showRedFlagReasonMoadl}
                      setShow={setShowRedFlagReasonMoadl}
                      handleYes={() => {
                        handleFlag({
                          role: 'Tenant'
                        })
                        setShowRedFlagReasonMoadl(false)
                      }}
                      active={!user?.isFlaggedRed}
                      name={user?.legalName}
                    />
              : null}
            <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={() => setPasswordForm(true)}
            >
              {t('changePassword')}
            </Button>
            {/* <Button
              className='actionButton'
              variant='outline-info'
              hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
              onClick={handleStatus}
            >
              {t('isAnonymous')}
            </Button> */}
          </div>
        </Card>
      </Col>
      {
        passwordForm
          ? (
            <ResetUserPasswordModal
              show={passwordForm}
              setShow={setPasswordForm}
              handleResetUserPassword={handleUpdatePassword}
            />
            )
          : (<></>)
      }
    </>
  )
}

export default Overview
