import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import { languageCode } from './constants'
import useLanguages from './hooks/useLanguages'

const Languages = () => {
  const {
    languageLoading,
    tenantDetails
  } = useLanguages()
  return (
    <>
      {languageLoading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col sm={8}>
                <h3>Languages</h3>
              </Col>
            </Row>

            <Table bordered responsive hover className='text-center mt-4'>
              <thead className='thead-dark'>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                </tr>
              </thead>

              <tbody>
                {tenantDetails?.tenantConfig?.allowedLanguages?.map((item, i) => (
                  <tr key={`tenant-language-listing ${i}`}>
                    <td>{item}</td>
                    <td>{languageCode?.[item]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {tenantDetails?.tenantConfig?.allowedLanguages?.length < 1 && (
            // If no data found
              <Form.Label className='text-danger d-flex justify-content-center'>
                No data found.
              </Form.Label>
            )}
          </>)}
    </>
  )
}

export default Languages
