
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createAndUpdateRollingStart } from '../../../../store/redux-slices/superAdminSettings'
import { getItem } from '../../../../utils/storageUtils'
import { getTenantCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { useEffect } from 'react'

const useCreateRollingCompetetion = ({ rollingData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { rollingCrudLoading } = useSelector(state => state.superAdminSettings)
  const { languages } = useSelector(state => state.languages)
  const currentLang = getItem('language')?.toLowerCase()
  const { tenantCurrencies } = useSelector(state => state.currencies)
  const handleCreateAndUpdateRolling = (values, handleClose, setRefetch, formActions) => {
    const formData = {
      ...values,
      validFrom: new Date(values?.validFrom).toISOString(),
      validTo: new Date(values?.validTo).toISOString()
    }
    const updateData = {}
    if (rollingData) {
      updateData.competitionId = rollingData?.competitionId
      updateData.rollingName = formData?.rollingName
      updateData.file = formData?.file
      updateData.languageCode = formData?.languageCode
    }
    dispatch(createAndUpdateRollingStart({
      formData: rollingData ? updateData : formData,
      isUpdate: !!rollingData,
      handleClose: () => {
        handleClose()
        setRefetch(prev => !prev)
      }
    }))
  }
  const getTenantCurrencies = (tenantId) => {
    dispatch(getTenantCurrenciesStart({ tenantId }))
  }
  useEffect(() => {
    if (rollingData?.tenantId) {
      getTenantCurrencies(rollingData?.tenantId)
    }
  }, [rollingData?.tenantId])
  return {
    getTenantCurrencies,
    tenantCurrencies,
    clientsFilterData,
    languages,
    t,
    rollingCrudLoading,
    currentLang,
    handleCreateAndUpdateRolling
  }
}

export default useCreateRollingCompetetion
