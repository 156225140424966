import React from 'react'
import { Button, Form } from '@themesberg/react-bootstrap'
import useAccountTransaction from '../hooks/useAccountTransaction'
import { ErrorMessage, Form as FormikForm, Formik } from 'formik'
import { depositSchema, depositSchemaForTenant } from './schema'
import { TransactionLimitConfirmationModal } from '../../../../components/ConfirmationModal'
import { useTranslation } from 'react-i18next' // Import the useTranslation hook
import CurrencyInput from 'react-currency-input-field'
import { formatNumber } from '../../../../utils/storageUtils'
import { WALLET_V2_TYPE_DEPOSIT_ARRAY, WALLET_V2_TYPE_WITHDRAW_ARRAY } from '../../../../utils/constants'

const AccountTransaction = ({
  buttonTitle,
  userData,
  isWithdraw = false,
  isForTenant = false,
  isDeposit,
  show,
  setShow,
  tenantData
}) => {
  const isV2User = userData?.isV2User
  const { t } = useTranslation()
  const {
    handleAccountTransaction,
    setConfirmTransactionModal,
    setFormData,
    formDataValues,
    formInitialValues,
    confirmTransactionModal
  } = useAccountTransaction(isForTenant, show, setShow, tenantData, isDeposit, isV2User)
  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={isForTenant
          ? depositSchemaForTenant(tenantData?.promotionCash || 0, !!isWithdraw, t, isV2User)
          : depositSchema((!isWithdraw
            ? userData?.['tenant.AdminUser.promotionCash']
            : userData?.['userWallet.nonCashAmount']), !!isWithdraw, t, isV2User)}
        onSubmit={(formData) => {
          setFormData(formData)
          setConfirmTransactionModal(true)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          resetForm,
          setFieldValue
        }) => {
          return (
            <FormikForm className='d-flex justify-content-center align-items-center '>
              <div className='card w-50 p-3 pt-4 ' style={{ minWidth: 500 }}>
                {isWithdraw &&
                  (
                    isForTenant
                      ? (
                        <div className='d-flex mb-3 w-100 align-items-center'>
                          <div style={{ minWidth: 120 }}>
                            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                              {t('nonCashAmount')}
                            </Form.Label>
                          </div>
                          <div className='d-flex flex-column flex-grow-1 gap-2 fw-bold'>
                            {
                              formatNumber(tenantData?.promotionCash) + ' ₩'
                            }
                          </div>
                        </div>
                        )
                      : (
                          values?.actionSource !== '' &&
                            <div className='d-flex mb-3 w-100 align-items-center'>
                              <div style={{ minWidth: 120 }}>
                                <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                                  {values?.actionSource === 'SPORTS-PROMOTION-CASH-WITHDRAW' ? t('sportsPromotionCash') : t('casinoPromotionCash')}
                                </Form.Label>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 gap-2 fw-bold'>
                                {
                              (
                                values?.actionSource === 'SPORTS-PROMOTION-CASH-WITHDRAW'
                                  ? formatNumber(Number(userData?.['userWallet.sportsPromotionCash'] || 0).toFixed(2))
                                  : formatNumber(Number(userData?.['userWallet.casinoPromotionCash'] || 0).toFixed(2))
                              ) + ' ' + userData?.currencyCode
                            }
                              </div>
                            </div>
                        )
                  )}
                {userData && !isForTenant && !isWithdraw &&
                  (
                    <div className='d-flex mb-3 w-100 align-items-center'>
                      <div style={{ minWidth: 120 }}>
                        <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                          {t('nonCashAmount')}
                        </Form.Label>
                      </div>
                      <div className='d-flex flex-column flex-grow-1 gap-2 fw-bold'>
                        {
                          formatNumber(Number(userData?.['tenant.AdminUser.promotionCash'] || 0).toFixed(2)) + ' ' + '₩'
                        }
                      </div>
                    </div>
                  )}
                <div className='d-flex mb-3 w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                      {t('currency')}
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column flex-grow-1 gap-2'>
                    <Form.Select
                      className='w-100'
                      size='sm'
                      name='currency'
                      style={{ width: '200px' }}
                      value={values?.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value=''>{t('selectCurrency')}</option>
                      <option key={1} value='KRW'>
                        ₩
                      </option>
                    </Form.Select>
                    <ErrorMessage
                      component='div'
                      name='currency'
                      className='text-danger'
                    />
                  </div>
                </div>
                {isV2User && (
                  <div className='d-flex mb-3 w-100'>
                    <div style={{ minWidth: 120 }}>
                      <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                        {t('walletType')}
                      </Form.Label>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 gap-2'>
                      <Form.Select
                        className='w-100'
                        size='sm'
                        name='actionSource'
                        style={{ width: '200px' }}
                        value={values?.actionSource}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value=''>{t('selectWalletType')}</option>
                        {(isDeposit ? WALLET_V2_TYPE_DEPOSIT_ARRAY : WALLET_V2_TYPE_WITHDRAW_ARRAY)?.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {t(item)}
                            </option>
                          )
                        })}

                      </Form.Select>
                      <ErrorMessage
                        component='div'
                        name='actionSource'
                        className='text-danger'
                      />
                    </div>
                  </div>
                )}
                <div className='d-flex w-100'>
                  <div style={{ minWidth: 120 }}>
                    <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                      {t('amount')}
                    </Form.Label>
                  </div>
                  <div className='d-flex flex-column flex-grow-1 gap-2'>

                    <CurrencyInput
                      intlConfig={{ locale: 'ko-KR' }}
                      value={values?.amount}
                      placeholder={t('enterAmount')}
                      onValueChange={(value) => {
                        setFieldValue('amount', value !== undefined ? Number(value) : '')
                      }}
                      className='w-100 form-control form-control-sm'
                      size='sm'
                      decimalScale={2}
                    />
                    {/* <Form.Control
                      className='w-100'
                      type='number'
                      name='amount'
                      placeholder={t('enterAmount')}
                      size='sm'
                      style={{ width: '200px' }}
                      value={values?.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}
                    <ErrorMessage
                      component='div'
                      name='amount'
                      className='text-danger'
                    />
                  </div>
                </div>
                {isV2User && isDeposit
                  ? (
                    <div className='d-flex mt-3 mb-3 w-100'>
                      <div style={{ minWidth: 120 }}>
                        <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                          {t('rollingPercent')}
                        </Form.Label>
                      </div>
                      <div className='d-flex flex-column flex-grow-1 gap-2'>

                        <CurrencyInput
                          intlConfig={{ locale: 'ko-KR' }}
                          value={values?.rollingPercentage}
                          placeholder={t('enterValueInPercent')}
                          onValueChange={(value) => {
                            setFieldValue('rollingPercentage', value !== undefined ? Number(value) : '')
                          }}
                          className='w-100 form-control form-control-sm'
                          size='sm'
                          decimalScale={2}
                        />
                        <ErrorMessage
                          component='div'
                          name='rollingPercentage'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    )
                  : <></>}
                <div className='mt-4'>
                  <div className='d-flex justify-content-center'>
                    <Button
                      className='depositButton'
                      variant='outline-info'
                      type='submit'
                    >
                      {buttonTitle}
                    </Button>
                  </div>
                </div>
              </div>
            </FormikForm>
          )
        }}
      </Formik>
      {confirmTransactionModal && (
        <TransactionLimitConfirmationModal
          setShow={setConfirmTransactionModal}
          show={confirmTransactionModal}
          values={formDataValues}
          handleConfirmActionRequest={handleAccountTransaction}
          isDeposit={isDeposit}
          buttonTitle={buttonTitle}
        />
      )}
    </>
  )
}

export default AccountTransaction
