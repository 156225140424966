import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  deleteTenantCasinoGameStart,
  getAllGamesStart,
  getAllSubCategoriesStart,
  tenantCasinoActionStart
} from '../../../../store/redux-slices/tenantCasino'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getCasinoThemesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'
import gameImages from '../../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'

const useCasinoGamesListing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [casinoCategoryId, setCasinoCategoryId] = useState('')
  const [gameId, setgameId] = useState(null)
  const [statusFilter, setStatusFilter] = useState('')
  const [categoryGameId, setCategoryGameId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [showGameIds, setShowGameIds] = useState(false)
  const [gameData, setGameData] = useState()
  const [orderBy, setOrderBy] = useState('categoryGameId')
  const [sort, setSort] = useState('desc')
  const [over, setOver] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [name, setName] = useState()
  const [search, setSearch] = useState('')

  const { loading, games, subCategories, allProviders } = useSelector((state) => state.tenantCasino)

  const totalPages = Math.ceil(games?.count / limit)
  const isInitialRender = useDidMountEffect()

  const getProviderName = (id) =>
    allProviders?.rows.find((val) => val.masterCasinoProviderId === id)?.name

  const selected = (h) =>
    orderBy === h.value &&
    h.label !== 'Thumbnail' &&
    h.label !== 'Status' &&
    h.label !== 'Sub Category' &&
    h.label !== 'Category'

  const handleShow = (id, subId, active, myName) => {
    setgameId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }

  useEffect(() => {
    dispatch(getCasinoThemesStart({ isTenant: true }))
    dispatch(getTenantCountriesStart())
  }, [])

  const handleDeleteYes = () => {
    dispatch(deleteTenantCasinoGameStart({
      data: { categoryGameId },
      limit,
      pageNo: page,
      casinoCategoryId,
      isActive: statusFilter,
      providerId: selectedProvider
    }))
    setDeleteModalShow(false)
  }

  const handleDeleteModal = (id, myName) => {
    setCategoryGameId(id)
    setDeleteModalShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(tenantCasinoActionStart({
      activeStatus: active,
      gameId: gameId
    }))
    setShow(false)
  }

  const handleClose = () => setShowModal(false)

  const handleShowModal = (type, data, id) => {
    setGameData(data)
    setCategoryGameId(id)
    setType(type)
    setShowModal(true)
  }

  useEffect(() => {
    dispatch(getAllSubCategoriesStart({
      limit: '',
      pageNo: '',
      categoryId: '',
      search,
      isActive: '',
      orderBy: '',
      sort: ''
    }))
  }, [getItem('language')])

  useEffect(() => {
    setPage(1)
    !isInitialRender && dispatch(
      getAllGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId,
        isActive: statusFilter,
        orderBy,
        sort,
        search,
        providerId: selectedProvider
      })
    )
  }, [limit, casinoCategoryId, statusFilter, orderBy, sort, selectedProvider, getItem('language')])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllGamesStart({
            limit,
            pageNo: page,
            casinoCategoryId,
            isActive: statusFilter,
            orderBy,
            sort,
            search,
            providerId: selectedProvider,
            languageCode: getItem('language')
          })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(
      getAllGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId,
        isActive: statusFilter,
        orderBy,
        sort,
        providerId: selectedProvider,
        languageCode: getItem('language')
      })
    )
  }, [page, getItem('language')])

  const getImageByGameCategory = (categoryName) => {
    switch (categoryName) {
      case 'baccarat':
        return gameImages.baccaratGame

      case 'slot':
        return gameImages.slotGame

      case 'video slots':
        return gameImages.liveGame

      case 'blackjack':
        return gameImages.blackjackGame

      case 'roulette':
        return gameImages.rouletteGame

      case 'gamble':
        return gameImages.gambleGame

      case 'poker':
        return gameImages.pokerGame

      case 'scratchcard':
        return gameImages.scratchCardGame

      case 'live games':
        return gameImages.gambleGame

      default:
        return gameImages.gambleGame
    }
  }
  const code = getItem('language')
  return {
    t,
    limit,
    page,
    loading,
    setLimit,
    setPage,
    totalPages,
    games,
    casinoCategoryId,
    setCasinoCategoryId,
    subCategories,
    show,
    setShow,
    handleShow,
    handleYes,
    handleShowModal,
    showModal,
    type,
    handleClose,
    active,
    gameData,
    categoryGameId,
    search,
    setSearch,
    setDeleteModalShow,
    deleteModalShow,
    handleDeleteYes,
    handleDeleteModal,
    statusFilter,
    setStatusFilter,
    setOrderBy,
    setSort,
    setOver,
    selected,
    sort,
    over,
    selectedProvider,
    setSelectedProvider,
    getProviderName,
    navigate,
    name,
    showGameIds,
    setShowGameIds,
    getImageByGameCategory,
    code
  }
}

export default useCasinoGamesListing
