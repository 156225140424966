
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { createBonusStart, getBonusReset, getBonusStart, getSelectedSeniorBonusStart, updateBonusStart } from '../../../store/redux-slices/bonus'
import { useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { getAllClientsStart } from '../../../store/redux-slices/tenants'
import { useNavigate, useParams } from 'react-router-dom'
import { objectToFormData } from '../../../utils/helpers'
import { getTenantCurrenciesStart } from '../../../store/redux-slices/currencies'
import { getTenantSportsListingStart } from '../../../store/redux-slices/tenantSportsBook'
import { getMatchListingStart, getSportsListingStart } from '../../../store/redux-slices/superAdminSportsBook'
import { BonusVal } from '../constant'
import { toast } from '../../../components/Toast'
import { getItem } from '../../../utils/storageUtils'
const initialState = {
  matchName: '',
  status: '',
  countryName: '',
  sportId: '1',
  tournamentId: ''
}

const useSportsbookCreateBonus = ({ isEdit }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const { bonusId } = useParams()
  const dispatch = useDispatch()
  const [filterData, setFilterData] = useState(initialState)
  const [matchSelectedData, setSelectedMatchData] = useState([])
  const [tempSelectedMatch, setTempSelectedMatch] = useState({})
  const [date, setDate] = useState({
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().add(1, 'day').format('YYYY-MM-DD')
  })

  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const { adminDetails } = useSelector((state) => state.admins)
  const { tenantCurrencies } = useSelector(state => state.currencies)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { bonusDropDown } = useSelector(state => state.bonus)
  const [bonusType, setBonusType] = useState('')
  const adminData = clientsFilterData?.rows
  const { matchListing, sportsListing } = useSelector(state => state.superAdminSportsBook)

  const isTenant = false
  const getTenantCurrencies = (tenantId) => {
    dispatch(getTenantCurrenciesStart({ tenantId }))
  }

  const handleCreateBonus = (values) => {
    const newFormData = {
      ...values,
      name: `${adminDetails?.firstName} ${adminDetails?.lastName}`,
      id: adminDetails?.superAdminUserId,
      bonusValidFrom: new Date(values?.bonusValidFrom).toISOString(),
      bonusValidTo: new Date(values?.bonusValidTo).toISOString(),
      bonusConfiguration: JSON.stringify({ masterMatchId: matchSelectedData?.map((item) => item?.masterMatchId) })
    }

    if (values?.bonusType === BonusVal.winningBonus) {
      delete newFormData.bonusPercent
    } else {
      delete newFormData.addOnOddBonus
    }

    if (isEdit) {
      delete newFormData.bonusType
      newFormData.bonusId = bonusDetail?.bonusId
    }

    const formData = objectToFormData(newFormData)

    if (isEdit) {
      dispatch(
        updateBonusStart({
          data: formData,
          navigate
        })
      )
    } else {
      dispatch(createBonusStart({ formData: formData, navigate }))
    }
  }

  const getSeniorBonusStart = (tenantId, bonusId) => {
    dispatch(getSelectedSeniorBonusStart({ type: 'SPORTBOOK', tenantId, bonusId }))
  }

  useEffect(() => {
    !isTenant
      ? dispatch(getSportsListingStart({
          paramsData: {
            limit: 1000,
            pageNo: 1,
            isTenant,
            isTop: true
          }
        }))
      : dispatch(getTenantSportsListingStart({
        paramsData: {
          limit: 1000,
          pageNo: 1,
          isTenant,
          isTop: true
        }
      }))
    !isEdit &&
      dispatch(getAllClientsStart({
        search: '',
        limit: '',
        orderBy: 'email',
        sort: 'desc'
      }))
  }, [])

  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ formData: { bonusId, type: 'SPORTBOOK' } }))
    }
    return () => {
      dispatch(getBonusReset())
    }
  }, [bonusId])

  useEffect(() => {
    if (bonusDetail?.tenantId) {
      if (bonusDetail?.bonusValidFrom && bonusDetail?.bonusValidTo) {
        setDate({
          fromDate: moment(bonusDetail?.bonusValidFrom).format('YYYY-MM-DD'),
          toDate: moment(bonusDetail?.bonusValidTo).format('YYYY-MM-DD')
        })
      }
      if (bonusDetail?.matchDetails?.length > 0) {
        const lang = (getItem('languages') || 'EN')?.toLowerCase()
        const matchDetails = bonusDetail?.matchDetails?.map((match) => {
          const homeTeam = match?.homeTeams?.[0]?.teamName?.en
          const awayTeam = match?.awayTeams?.[0]?.teamName?.en
          return ({
            homeTeam,
            awayTeam,
            masterMatchId: match?.matchId,
            matchDate: match?.matchDate,
            sportName: match?.sportName[lang]

          })
        })
        setSelectedMatchData(matchDetails)
      }
    }
  }, [bonusDetail?.tenantId])
  useEffect(() => {
    dispatch(getMatchListingStart({
      paramsData: {
        ...filterData,
        limit: limit,
        pageNo: page,
        isTenant: false,
        ...date
      }
    }))
  }, [filterData, date, limit, page])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])
  function handleDebounceFn (event) {
    setFilterData(prev => ({ ...prev, matchName: event.target.value }))
  }
  const isChecked = (masterMatchId) => {
    return matchSelectedData?.find(item => Number(item?.masterMatchId) === Number(masterMatchId))
  }
  const handleSelectMatches = (e, item) => {
    if (bonusType === BonusVal.bettingBonus) {
      if (matchSelectedData.length >= 1) {
        toast(t('onlyOneMatchCanBeSelect'), 'error')
        return
      }
    }
    const tempVar = { ...tempSelectedMatch }
    if (!isChecked(e.target.value)) {
      setSelectedMatchData(prev => [...prev, item])
      tempVar[page] = [...tempVar[page] || [], item]
    } else {
      setSelectedMatchData(matchSelectedData.filter(t => Number(t?.masterMatchId) !== Number(e.target.value)))
      tempVar[page] = (tempVar?.[page] || []).filter(t => Number(t?.masterMatchId) !== Number(e.target.value))
    }
    setTempSelectedMatch(tempVar)
  }
  const handleSelectAll = () => {
    const tempVar = { ...tempSelectedMatch }
    if (tempVar[page]) {
      if (matchListing?.rows?.length === tempVar[page]?.length) {
        tempVar[page] = []
      } else {
        tempVar[page] = matchListing?.rows.map(item => item)
      }
    } else {
      tempVar[page] = matchListing?.rows.map(item => item)
    }
    setTempSelectedMatch(tempVar)
    setSelectedMatchData(Object.values(tempVar)?.flat(Infinity))
  }

  return {
    loading,
    bonusDetail,
    t,
    handleCreateBonus,
    adminData,
    bonusDropDown,
    getSeniorBonusStart,
    navigate,
    getTenantCurrencies,
    tenantCurrencies,
    debounceFn,
    matchSelectedData,
    sportsListing,
    filterData,
    setFilterData,
    matchListing,
    setBonusType,
    setSelectedMatchData,
    setDate,
    bonusType,
    limit,
    page,
    setPage,
    setLimit,
    isChecked,
    handleSelectMatches,
    handleSelectAll,
    tempSelectedMatch
  }
}

export default useSportsbookCreateBonus
