
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createSeasonListStart, getTemplateListStart, updateSeasonListStart } from '../../../../store/redux-slices/superAdminSettings'
import { getItem } from '../../../../utils/storageUtils'
import { useEffect } from 'react'

const useCreateSeason = ({ seasonData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { templateList, createLoading, loading: templateListLoading } = useSelector(state => state.superAdminSettings)
  const { languages } = useSelector(state => state.languages)
  const currentLang = getItem('language')?.toLowerCase()
  const getTemplateList = (tenantId) => {
    dispatch(getTemplateListStart({ tenantId: tenantId }))
  }
  const handleCreateSeason = (values, handleClose, setRefetch, formActions) => {
    const formData = { ...values, startDate: new Date(values?.startDate).toISOString(), endDate: new Date(values?.endDate).toISOString() }
    dispatch(createSeasonListStart({
      formData: formData,
      handleClose: () => {
        handleClose()
        setRefetch(prev => !prev)
        formActions.resetForm()
      }
    }))
  }
  const handleUpdateSeason = (values, handleClose, setRefetch, formActions) => {
    const isURL = typeof values?.file === 'string'
    const formData = { ...values, seasonId: seasonData?.seasonId, startDate: new Date(values?.startDate).toISOString(), endDate: new Date(values?.endDate).toISOString() }
    if (isURL) {
      delete formData.file
    }
    dispatch(updateSeasonListStart({
      formData: formData,
      handleClose: () => {
        handleClose()
        setRefetch(prev => !prev)
        formActions.resetForm()
      }
    }))
  }
  useEffect(() => {
    if (seasonData && seasonData.tenantId) {
      getTemplateList(seasonData.tenantId)
    }
  }, [seasonData])
  return {
    clientsFilterData,
    languages,
    t,
    getTemplateList,
    templateList,
    templateListLoading,
    currentLang,
    handleCreateSeason,
    handleUpdateSeason,
    createLoading
  }
}

export default useCreateSeason
