import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { Button, Col, Row, Form as BForm, Spinner } from 'react-bootstrap'
import { BonusVal, LIMITTYPE } from '../constant'
import { updateLimitSchema } from '../schema'
import { useSelector } from 'react-redux'
import { getItem } from '../../../utils/storageUtils'
import { ADMIN_ROLES } from '../../../utils/constants'

const UpdateLimitForm = ({ bonusDetail, loading, t, handleUpdateYes, handleClose }) => {
  const { bonusDetail: mainBonusDetail } = useSelector((state) => state.bonus)
  const role = getItem('role')
  const isSuperAdminRole = role === ADMIN_ROLES.SUPERADMIN
  return (
    <Formik
      enableReinitialize
      validateOnMount={false}
      initialValues={{
        bonusType: bonusDetail?.bonusType || '',
        reoccuringDepositRate: bonusDetail?.reoccuringDepositRate || '',
        withdrawalRate: bonusDetail?.withdrawalRate || '',
        limitType: bonusDetail?.limitType || '',
        customLimit: bonusDetail?.customLimit || '',
        minsForBonusGiven: bonusDetail?.minsForBonusGiven || '',
        compensationTarget: bonusDetail?.compensationTarget || '',
        maxBonusLimit: bonusDetail?.maxBonusLimit || '',
        bonusPercent: bonusDetail?.bonusPercent || '',
        slotCatBonusPercent: bonusDetail?.slotCatBonusPercent || '',
        liveCatBonusPercent: bonusDetail?.liveCatBonusPercent || ''
      }}
      validationSchema={updateLimitSchema(t, mainBonusDetail)}
      onSubmit={(values, formActions) => { handleUpdateYes(values, formActions) }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        validateField,
        setValues
      }) => {
        const isCompensation = values?.bonusType === BonusVal.compensationBonus
        const isRecurring = values?.bonusType === BonusVal.reOccuringDepositBonus
        const isPayback = values?.bonusType === BonusVal.paybackBonus
        return (
          <Form>
            <Row>
              {!isPayback && (
                <Col sm={12} className='mb-2'>
                  <div className='d-flex flex-column gap-1'>
                    <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                      {t('bonus_percentage')}
                    </BForm.Label>
                    <BForm.Control
                      name='bonusPercent'
                      type='number'
                      min={1}
                      placeholder={t('enterValueInPercent')}
                      value={values.bonusPercent}
                      onInput={handleChange}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component='div'
                      name='bonusPercent'
                      className='text-danger mt-1'
                    />
                  </div>

                </Col>
              )}
              <Col sm={12} className='mb-2'>
                <div className='d-flex flex-column gap-1'>
                  <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                    {t('maximum_bonus_limit')}
                  </BForm.Label>
                  <BForm.Control
                    name='maxBonusLimit'
                    type='number'
                    min={1}
                    placeholder={t('enterValue')}
                    value={values.maxBonusLimit}
                    onInput={handleChange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    component='div'
                    name='maxBonusLimit'
                    className='text-danger mt-1'
                  />
                </div>

              </Col>
              {isCompensation
                ? (
                  <>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('bonus_limit')}
                        </BForm.Label>
                        <BForm.Select
                          name='limitType'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={
                                t('select_bonus_limit')
                              }
                          value={values?.limitType}
                          disabled={!isSuperAdminRole}
                        >
                          {
                                LIMITTYPE?.map((item, index) => (
                                  <option key={index} value={item.value}>{t(item.label.toLowerCase())}</option>
                                ))
                              }

                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='limitType'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                    <Col sm={12} className='mb-3'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('customLimit')}
                        </BForm.Label>

                        <BForm.Control
                          name='customLimit'
                          type='number'
                          min={1}
                          placeholder={t('enterValue')}
                          value={values.customLimit}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component='div'
                          name='customLimit'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('minutes_for_bonus_given')}
                        </BForm.Label>

                        <BForm.Control
                          name='minsForBonusGiven'
                          type='number'
                          min={1}
                          placeholder={t('enterValue')}
                          value={values.minsForBonusGiven}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component='div'
                          name='minsForBonusGiven'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('compensation_target')}
                        </BForm.Label>

                        <BForm.Control
                          name='compensationTarget'
                          type='number'
                          min={1}
                          placeholder={t('enterValue')}
                          value={values.compensationTarget}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <ErrorMessage
                          component='div'
                          name='compensationTarget'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                  </>
                  )
                : null}
              {isRecurring
                ? (
                  <>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('withdrawal_rate')}
                        </BForm.Label>

                        <BForm.Control
                          name='withdrawalRate'
                          type='number'
                          min={1}
                          placeholder={t('enterValueInPercent')}
                          value={values.withdrawalRate}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <ErrorMessage
                          component='div'
                          name='withdrawalRate'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('reoccurring_deposit_rate')}
                        </BForm.Label>

                        <BForm.Control
                          name='reoccuringDepositRate'
                          type='number'
                          min={1}
                          placeholder={t('enterValueInPercent')}
                          value={values.reoccuringDepositRate}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <ErrorMessage
                          component='div'
                          name='reoccuringDepositRate'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                  </>
                  )
                : null}
              {isPayback
                ? (
                  <>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('slotCatBonusPercent')}
                        </BForm.Label>

                        <BForm.Control
                          name='slotCatBonusPercent'
                          type='number'
                          min={1}
                          placeholder={t('enterValueInPercent')}
                          value={values.slotCatBonusPercent}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <ErrorMessage
                          component='div'
                          name='slotCatBonusPercent'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                    <Col sm={12} className='mb-2'>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Label style={{ fontSize: 16 }} className='mb-0'>
                          {t('liveCatBonusPercent')}
                        </BForm.Label>

                        <BForm.Control
                          name='liveCatBonusPercent'
                          type='number'
                          min={1}
                          placeholder={t('enterValueInPercent')}
                          value={values.liveCatBonusPercent}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <ErrorMessage
                          component='div'
                          name='liveCatBonusPercent'
                          className='text-danger mt-1'
                        />
                      </div>
                    </Col>
                  </>
                  )
                : null}

              <Col sm={12}>
                <div className='mt-4 d-flex align-align-items-center gap-4'>
                  {handleClose && (
                    <Button
                      variant='outline-warning'
                      className='ml-2'
                      onClick={handleClose}
                    >
                      {t('cancel')}
                    </Button>
                  )}
                  <Button
                    type='submit'
                    className='ml-2'
                    variant='outline-success'
                  >
                    {t('submit')} {loading && <Spinner size='sm' className='ms-1' />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateLimitForm
