import { takeLatest, put } from 'redux-saga/effects'
import {
  getAdminUsers,
  getAdminUserDetails,
  createAdminUser,
  createSuperAdminUser,
  superAdminViewToggleStatus,
  updateSuperAdminUser,
  updateTenantAdminUser,
  getAllGroups,
  updateUserNote,
  updateBetNote,
  updateKycDetails,
  getUserInspectionDetails,
  getUserGGR,
  getAgentAndAffiliateList,
  getAgents,
  getUserForNotification
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

import {
  getUserInspectionDetailsStart,
  getUserInspectionDetailsSuccess,
  getUserInspectionDetailsFailure,
  getUserGGRStart,
  getUserGGRSuccess,
  getUserGGRFailure,
  updateUserKycDetailsStart,
  updateUserKycDetailsSuccess,
  updateUserKycDetailsFailure,
  getAdminUsersStart,
  getAdminUsersSuccess,
  getAdminUsersFailure,
  createAdminUserStart,
  createAdminUserSuccess,
  createAdminUserFailure,
  updateSuperAdminUserStart,
  updateSuperAdminUserComplete,
  getAdminUserDetailsStart,
  getAdminUserDetailsSuccess,
  getAdminUserDetailsFailure,
  createSuperAdminUserStart,
  createSuperAdminUserComplete,
  updateSuperAdminStatusStart,
  updateSuperAdminStatusComplete,
  updateTenantAdminUserStart,
  updateTenantAdminUserComplete,
  getAllGroupsStart,
  getAllGroupsSuccess,
  getAllGroupsFailure,
  updateSuperAdminUserNoteStart,
  updateSuperAdminUserNoteSuccess,
  updateSuperAdminUserNoteFailure,
  updateSuperAdminBetNoteStart,
  updateSuperAdminBetNoteSuccess,
  updateSuperAdminBetNoteFailure,
  getAffiliateAndAgentsListStart,
  getAffiliateAndAgentsListSuccess,
  getAffiliateAndAgentsListFailure,
  getAgentFilterListStart,
  getUserForNotificationsStart,
  getUserForNotificationsSuccess,
  getUserForNotificationsFailure
} from '../redux-slices/adminUser'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { getAllAdminsStart } from '../redux-slices/admins'
import { getUserStart } from '../redux-slices/fetchData'
import { getSuperAdminCasinoTransactionsStart } from '../redux-slices/superAdminTransactions'

export default function * adminUserWatcher () {
  yield takeLatest(getAdminUsersStart.type, getAdminUsersWorker)
  yield takeLatest(getAffiliateAndAgentsListStart.type, getAffiliateAndAgentsListWorker)
  yield takeLatest(getUserInspectionDetailsStart.type, getUserInspectionDetailsWorker)
  yield takeLatest(getAdminUserDetailsStart.type, getAdminUserDetailsWorker)
  yield takeLatest(createAdminUserStart.type, createAdminUserWorker)
  yield takeLatest(createSuperAdminUserStart.type, createSuperAdminUserWorker)
  yield takeLatest(updateSuperAdminUserStart.type, updateSuperAdminUserWorker)
  yield takeLatest(updateSuperAdminStatusStart.type, updateSuperAdminStatusWorker)
  yield takeLatest(updateTenantAdminUserStart.type, updateTenantAdminUserWorker)
  yield takeLatest(getAllGroupsStart.type, getAllGroupsWorker)
  yield takeLatest(updateSuperAdminUserNoteStart.type, updateSuperAdminUserNoteWorker)
  yield takeLatest(updateSuperAdminBetNoteStart.type, updateSuperAdminBetNoteWorker)
  yield takeLatest(updateUserKycDetailsStart.type, updateUserKycDetailsWorker)
  yield takeLatest(getUserGGRStart.type, getUserGGRWorker)
  yield takeLatest(getAgentFilterListStart.type, getAgentFilterListWorker)
  yield takeLatest(getUserForNotificationsStart.type, getUserForNotificationsWorker)
}

function * getAffiliateAndAgentsListWorker (action) {
  try {
    const { data } = yield getAgentAndAffiliateList(action.payload)
    yield put(getAffiliateAndAgentsListSuccess(data?.data?.roles))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAffiliateAndAgentsListFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserForNotificationsWorker (action) {
  try {
    const { data } = yield getUserForNotification(action.payload)
    yield put(getUserForNotificationsSuccess(data?.data?.getUsers))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserForNotificationsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAgentFilterListWorker (action) {
  try {
    const { data } = yield getAgents(action.payload)
    yield put(getAffiliateAndAgentsListSuccess(data?.data?.agentDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAffiliateAndAgentsListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getUserGGRWorker (action) {
  try {
    const {
      startDate,
      endDate,
      tenantId,
      userId,
      contentFilter
    } = action && action.payload
    const { data } = yield getUserGGR({
      startDate,
      endDate,
      tenantId,
      userId,
      ...contentFilter
    })
    yield put(getUserGGRSuccess(data?.data?.totalGgrAmount))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserGGRFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateUserKycDetailsWorker (action) {
  try {
    const {
      userId,
      legalName,
      phone,
      phoneCode,
      accountNumber,
      bankName,
      firstName
    } = action && action.payload
    const { data } = yield updateKycDetails({
      userId,
      legalName,
      phone,
      phoneCode,
      accountNumber,
      bankName,
      firstName
    })
    yield put(updateUserKycDetailsSuccess(data?.data?.message))
    yield toast(data?.data?.message, 'success')
    yield put(getUserStart({
      userId
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateUserKycDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateSuperAdminUserNoteWorker (action) {
  try {
    const {
      userId,
      note,
      fetchData
    } = action && action.payload
    const { data } = yield updateUserNote({
      userId,
      note
    })
    yield put(updateSuperAdminUserNoteSuccess(data?.data?.message))
    fetchData()
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSuperAdminUserNoteFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateSuperAdminBetNoteWorker (action) {
  try {
    const {
      transactionId,
      note,
      userId
    } = action && action.payload
    const { data } = yield updateBetNote({
      transactionId,
      note
    })
    yield put(updateSuperAdminBetNoteSuccess(data?.data?.message))
    yield put(getSuperAdminCasinoTransactionsStart({
      limit: 15,
      pageNo: 1,
      userId
    }))
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSuperAdminBetNoteFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getUserInspectionDetailsWorker (action) {
  try {
    const { formData } = action && action.payload
    const { data } = yield getUserInspectionDetails({ formData })

    yield put(getUserInspectionDetailsSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getUserInspectionDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAdminUserDetailsWorker (action) {
  try {
    const { adminUserId, isTenant = false } = action && action.payload
    const { data } = yield getAdminUserDetails({ adminUserId, isTenant })

    yield put(getAdminUserDetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminUserDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createAdminUserWorker (action) {
  try {
    const { data, navigate } = action && action.payload

    yield createAdminUser(data)

    yield put(createAdminUserSuccess())

    yield toast('Manager Created Successfully', 'success')

    navigate(TenantRoutes.Admins)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createAdminUserFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminUsersWorker (action) {
  const { limit, pageNo, sort, roleId } = action && action.payload
  try {
    const { data } = yield getAdminUsers(limit, pageNo, sort, roleId)

    yield put(getAdminUsersSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminUsersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createSuperAdminUserWorker (action) {
  try {
    const { data, navigate } = action && action.payload

    yield createSuperAdminUser(data)

    yield put(createSuperAdminUserComplete())

    yield toast(`${data?.role} Created Successfully`, 'success')

    navigate(SuperAdminRoutes.Admins)
  } catch (e) {
    yield put(createSuperAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSuperAdminUserWorker (action) {
  try {
    const { data, navigate, profile } = action && action.payload

    yield updateSuperAdminUser(data)

    profile
      ? (setTimeout(() => {
          navigate(SuperAdminRoutes.Profile)
        }
        , 7000))
      : navigate(SuperAdminRoutes.Admins)

    yield put(updateSuperAdminUserComplete())

    yield toast(`${data?.role} Updated Successfully`, 'success')
  } catch (e) {
    yield put(updateSuperAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSuperAdminStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      orderBy,
      sort,
      search,
      superAdminId,
      superRoleId,
      status
    } = action && action.payload

    yield superAdminViewToggleStatus(data)

    yield put(updateSuperAdminStatusComplete())

    yield put(
      getAllAdminsStart({
        limit,
        pageNo,
        orderBy,
        sort,
        search,
        superAdminId,
        superRoleId,
        status
      })
    )

    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateSuperAdminStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateTenantAdminUserWorker (action) {
  try {
    const { data, navigate, profile } = action && action.payload

    yield updateTenantAdminUser(data)

    yield put(updateTenantAdminUserComplete())

    yield toast(`${data?.role} Updated Successfully`, 'success')
    profile ? navigate(TenantRoutes.Profile) : navigate(TenantRoutes.Admins)
  } catch (e) {
    yield put(updateTenantAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getAllGroupsWorker (action) {
  try {
    const { isTenant } = action && action.payload
    const { data } = yield getAllGroups(isTenant)

    yield put(getAllGroupsSuccess(data?.data?.groupNames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllGroupsFailure(e?.response?.data?.errors[0]?.description))
  }
}
