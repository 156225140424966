export const tableHeaders = [
  { label: 'id', value: 'superAdminUserId' },
  { label: 'ownerName', value: 'ownerName' },
  { label: 'portalName', value: 'tenantName' },
  { label: 'bonusType', value: 'bonusType' },
  { label: 'validTill', value: 'validTo' },
  { label: 'isExpired', value: 'isExpired' },
  { label: 'status', value: 'isActive' },
  { label: 'action', value: 'superAdminUserId' }
]
