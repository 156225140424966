import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getSeniorWalletListStart, updateSeniorTransactionActionStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getLoginToken } from '../../../../utils/storageUtils'
import { downloadFile } from '../../../../utils/fileDownloader'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const initalWalletState = {
  actionType: '',
  search: '',
  status: '',
  senior: '',
  transactionType: ''
}
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useSeniorWallet = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  let intervalId = null
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const { countries } = useSelector((state) => state.fetch)
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { seniorWalletTransaction, loading } = useSelector((state) => state.superAdminTransactions)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const [transactionId, setTransactionId] = useState(null)
  const [seniorId, setseniorId] = useState(null)
  const [seniorWalletRequestId, setSeniorWalletRequestId] = useState(null)
  const [status, setStatus] = useState(false)
  const [showWalletRequestAction, setShowWalletRequestAction] = useState(false)
  const totalPages = Math.ceil(seniorWalletTransaction?.seniorWalletRequests?.count / limit)
  const [state, setState] = useState(initialDate)

  const handleShowModal = (transactionId, seniorId, status, seniorWalletRequestId) => {
    setseniorId(seniorId)
    setTransactionId(transactionId)
    setShowWalletRequestAction(true)
    setStatus(status)
    setSeniorWalletRequestId(seniorWalletRequestId)
  }

  const handleConfirmActionRequest = () => {
    dispatch(updateSeniorTransactionActionStart({
      status,
      transactionId,
      seniorId,
      seniorWalletRequestId,
      form: { ...formData, limit, pageNo: page, selectedClient }
    }))
    setShowWalletRequestAction(false)
    setFormData(initalWalletState)
  }

  useEffect(() => {
    dispatch(getSeniorWalletListStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        tenantId: selectedClient,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || ''
      }
    }))
  }, [formData, limit, page, selectedClient, state])

  useEffect(() => {
    intervalId = setInterval(() => {
      return dispatch(getSeniorWalletListStart({
        form: {
          ...formData,
          limit,
          pageNo: page,
          tenantId: selectedClient,
          from: moment(state[0].startDate).toISOString() || '',
          to: moment(state[0].endDate).toISOString() || ''
        }
      }))
    }, 30000)
    return () => clearInterval(intervalId)
  }, [formData, limit, page, selectedClient, state])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }
  useEffect(() => {
    dispatch(getAllCurrenciesStart({
      limit: 500,
      pageNo: 1
    }))
    dispatch(getCountriesStart({
      limit: '',
      name: '',
      pageNo: '',
      isActive: '',
      kycMethod: ''
    }))
  }, [])

  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/senior-wallet-transactions?status=${formData.status || ''}&limit=${limit || 15}&actionSource=${formData.actionSource || ''}&search=${formData.search || ''}&actionType=${formData.actionType || ''}&transactionId=${transactionId || ''}&pageNo=${formData.pageNo || 1}&tenantId=${selectedClient || ''}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}`)
  }
  return {
    state,
    setState,
    formData,
    setFormData,
    allCurrencies,
    handleShowModal,
    setShowWalletRequestAction,
    showWalletRequestAction,
    countries,
    seniorWalletTransaction,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    handleConfirmActionRequest,
    selectedClient,
    setSelectedClient,
    handleCsvDownload,
    inputRef,
    initialDate,
    loading,
    t,
    status,
    setStatus,
    seniorWalletRequestId,
    setSeniorWalletRequestId
  }
}

export default useSeniorWallet
