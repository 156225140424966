import { takeLatest, put } from 'redux-saga/effects'

import { toast } from '../../components/Toast'
// import { serialize } from 'object-to-formdata'
import { createAgent, getAgentDetails, getAgents, getBonusAgents, updateAgent, updateAgentStatus } from '../../utils/apiCalls'
import {
  createAgentStart,
  createAgentSuccess,
  createAgentFailure,
  getAgentStart,
  getAgentSuccess,
  getAgentFailure,
  getAgentDetailStart,
  getAgentDetailSuccess,
  getAgentDetailFailure,
  updateAgentStatusStart,
  updateAgentStatusSuccess,
  updateAgentStatusFailure,
  updateAgentStart,
  updateAgentSuccess,
  updateAgentFailure,
  getBonusDetailAgentStart,
  getBonusDetailAgentSuccess,
  getBonusDetailAgentFailure
} from '../redux-slices/agent'

export default function * agentWatcher () {
  yield takeLatest(createAgentStart.type, createAgentWorker)
  yield takeLatest(getBonusDetailAgentStart.type, getBonusDetailAgentWorker)
  yield takeLatest(getAgentStart.type, getAgentWorker)
  yield takeLatest(getAgentDetailStart.type, getAgentDetailWorker)
  yield takeLatest(updateAgentStatusStart.type, updateAgentStatusWorker)
  yield takeLatest(updateAgentStart.type, updateAgentWorker)
}

function * getBonusDetailAgentWorker (action) {
  try {
    const { data } = yield getBonusAgents(action.payload)
    yield put(getBonusDetailAgentSuccess(data?.data?.agentsDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusDetailAgentFailure())
  }
}
function * createAgentWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    const encryptedPass = btoa(data.password)

    yield createAgent({ ...data, password: encryptedPass })
    yield toast('Agent Created successfully', 'success')
    navigate(-1)
    yield put(createAgentSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createAgentFailure())
  }
}

function * updateAgentStatusWorker (action) {
  try {
    const {
      formData,
      isTenant,
      isAffiliate,
      handleClose
    } = action && action.payload
    const res = yield updateAgentStatus({
      data: formData,
      isTenant,
      isAffiliate
    })
    if (res.status === 200) {
      yield put(updateAgentStatusSuccess())
      handleClose()
      yield toast('Agent Updated successfully', 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAgentStatusFailure())
  }
}

function * updateAgentWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    // data = serialize(data)
    yield updateAgent(data)
    yield toast('Agent Updated successfully', 'success')
    navigate(-1)
    yield put(updateAgentSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAgentFailure())
  }
}

function * getAgentWorker (action) {
  try {
    const { data } = yield getAgents(action.payload)
    yield put(getAgentSuccess(data?.data?.agentDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAgentFailure())
  }
}

function * getAgentDetailWorker (action) {
  try {
    // data = serialize(data)
    const { data } = yield getAgentDetails(action.payload)
    yield put(getAgentDetailSuccess(data?.data?.agentDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAgentDetailFailure())
  }
}
