import { createSlice } from '@reduxjs/toolkit'

const bonusSlice = createSlice({
  name: 'bonus',
  initialState: {
    loading: false,
    convertedAmount: null,
    bonusDetail: null,
    userBonus: null,
    paymentMethod: null,
    wageringTemplateList: null,
    wageringTemplateDetail: null,
    wageringTemplates: null,
    tenantCasinoGames: null,
    languages: [],
    langLoading: false,
    segments: [],
    bonusDropDown: [],
    bonusUsers: {},
    bonusGames: {},
    uniqueNumberBonus: {},
    userLotteryTickets: {},
    deleteLoading: false,
    updateLoading: false,
    isNewNotification: false,
    newNotificationData: [],
    adminAgentsAndAffiliatesListing: {},
    entityBonusDetails: null,
    entityLoading: false
  },
  reducers: {
    resetEntityBonusDetails: (state) => ({
      ...state,
      entityBonusDetails: null
    }),
    getEntityBonusDetailsStart: (state) => ({
      ...state,
      entityLoading: true
    }),
    getEntityBonusDetailsSuccess: (state, { payload }) => ({
      ...state,
      entityBonusDetails: payload,
      entityLoading: false
    }),
    getEntityBonusDetailsFailure: (state) => ({
      ...state,
      entityLoading: false,
      entityBonusDetails: null
    }),
    getAdminAffiliateAndAgentsListingStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminAffiliateAndAgentsListingSuccess: (state, { payload }) => ({
      ...state,
      adminAgentsAndAffiliatesListing: payload,
      loading: false
    }),
    getAdminAffiliateAndAgentsListingFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateAdminBonusLimitStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateAdminBonusLimitComplete: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    removeAffiliateAndAgentStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    removeAffiliateAndAgentComplete: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    setIsNewNotification: (state, { payload }) => ({
      ...state,
      isNewNotification: payload
    }),
    updateNewNotification: (state, { payload }) => ({
      ...state,
      newNotificationData: payload.type === 'incorrect password'
        ? [payload, ...state.newNotificationData]
        : [payload, ...state.newNotificationData]
    }),

    getSelectedSeniorBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getSelectedSeniorBonusSuccess: (state, { payload }) => ({
      ...state,
      bonusDropDown: payload,
      loading: false
    }),
    getSelectedSeniorBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateAffiliateAndAgentBonusLimitStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateAffiliateAndAgentBonusLimitComplete: (state) => ({
      ...state,
      updateLoading: false
    }),

    getBonusGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getBonusGamesSuccess: (state, { payload }) => ({
      ...state,
      bonusGames: payload,
      loading: false
    }),
    getBonusGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getUniqueNumberBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getUniqueNumberBonusSuccess: (state, { payload }) => ({
      ...state,
      uniqueNumberBonus: payload,
      loading: false
    }),
    getUniqueNumberBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBonusUsersStart: (state) => ({
      ...state,
      loading: true
    }),
    getBonusUsersSuccess: (state, { payload }) => ({
      ...state,
      bonusUsers: payload,
      loading: false
    }),
    getBonusUsersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getUserLotteryTicketsStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserLotteryTicketsSuccess: (state, { payload }) => ({
      ...state,
      userLotteryTickets: payload,
      loading: false
    }),
    getUserLotteryTicketsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSAConvertAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    getSAConvertAmountSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      convertedAmount: payload
    }),
    getSAConvertAmountFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    createBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getBonusStart: (state) => ({
      ...state,
      loading: true,
      bonusDetail: null
    }),
    getBonusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusDetail: payload
    }),
    getBonusFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getBonusReset: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusDetail: {}
    }),
    issueBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    issueBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getUserBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserBonusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userBonus: payload
    }),
    getUserBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    cancelBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    cancelBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSABonusStatusStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateSABonusStatusComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    getSAPaymentMethodStart: (state) => ({
      ...state,
      loading: true
    }),
    getSAPaymentMethodSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentMethod: payload
    }),
    getSAPaymentMethodFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getWageringTemplateStart: (state) => ({
      ...state,
      loading: true,
      wageringTemplateDetail: null
    }),
    getWageringTemplateComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      wageringTemplateList: payload
    }),
    getWageringTemplateFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getWageringTemplateDetailsStart: (state) => ({
      ...state,
      loading: true,
      wageringTemplateDetail: null
    }),
    getWageringTemplateDetailsComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      wageringTemplateDetail: payload
    }),
    getWageringTemplateDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createWageringTemplateStart: (state) => ({
      ...state,
      loading: true
    }),
    createWageringTemplateComplete: (state) => ({
      ...state,
      loading: false
    }),
    createWageringTemplateFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateWageringTemplateStart: (state) => ({
      ...state,
      loading: true
    }),
    updateWageringTemplateComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateWageringTemplateFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTAConvertAmountStart: (state) => ({
      ...state,
      loading: true
    }),
    getTAConvertAmountSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      convertedAmount: payload
    }),
    getTAConvertAmountFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createTABonusStart: (state) => ({
      ...state,
      loading: true
    }),
    createTABonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateTABonusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTABonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getTABonusStart: (state) => ({
      ...state,
      loading: true,
      bonusDetail: null
    }),
    getTABonusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusDetail: payload
    }),
    getTABonusFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateTABonusStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTABonusStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getTAPaymentMethodStart: (state) => ({
      ...state,
      loading: true
    }),
    getTAPaymentMethodSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentMethod: payload
    }),
    getTAPaymentMethodFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getAllTABonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTABonusComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusList: payload
    }),
    getAllTABonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getWageringTemplatePaginationStart: (state) => ({
      ...state,
      loading: true
    }),
    getWageringTemplatePaginationComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      wageringTemplates: payload
    }),
    getWageringTemplatePaginationFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getTenantAllCasinoGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantAllCasinoGamesComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantCasinoGames: payload
    }),
    getTenantAllCasinoGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantLanguagesStart: (state) => ({
      ...state,
      langLoading: true
    }),
    getTenantLanguagesSuccess: (state, { payload }) => ({
      ...state,
      langLoading: false,
      languages: payload
    }),
    getTenantLanguagesFailure: (state) => ({
      ...state,
      langLoading: false
    }),
    getSegmentsStart: (state) => ({
      ...state,
      loading: true
    }),
    getSegmentsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      segments: payload
    }),
    getSegmentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    deleteBonusStart: (state) => ({
      ...state,
      deleteLoading: true
    }),
    deleteBonusComplete: (state) => ({
      ...state,
      deleteLoading: false
    })
  }
})

export default bonusSlice.reducer

export const {
  getSelectedSeniorBonusStart,
  getSelectedSeniorBonusSuccess,
  getSelectedSeniorBonusFailure,
  getSAConvertAmountStart,
  getSAConvertAmountSuccess,
  getSAConvertAmountFailure,
  createBonusStart,
  createBonusComplete,
  updateBonusStart,
  updateBonusComplete,
  getBonusStart,
  getBonusSuccess,
  getBonusFailure,
  issueBonusStart,
  issueBonusComplete,
  getUserBonusStart,
  getUserBonusSuccess,
  getUserBonusFailure,
  cancelBonusStart,
  cancelBonusComplete,
  updateSABonusStatusStart,
  updateSABonusStatusComplete,
  getSAPaymentMethodStart,
  getSAPaymentMethodSuccess,
  getSAPaymentMethodFailure,
  getWageringTemplateFailure,
  getWageringTemplateComplete,
  getWageringTemplateStart,
  getWageringTemplateDetailsStart,
  getWageringTemplateDetailsComplete,
  getWageringTemplateDetailsFailure,
  createWageringTemplateStart,
  createWageringTemplateComplete,
  createWageringTemplateFailure,
  updateWageringTemplateStart,
  updateWageringTemplateComplete,
  updateWageringTemplateFailure,
  getTABonusStart,
  getTABonusSuccess,
  getTABonusFailure,
  createTABonusStart,
  createTABonusComplete,
  updateTABonusStart,
  updateTABonusComplete,
  updateTABonusStatusStart,
  updateTABonusStatusComplete,
  getTAConvertAmountStart,
  getTAConvertAmountSuccess,
  getTAConvertAmountFailure,
  getAllTABonusStart,
  getAllTABonusComplete,
  getAllTABonusFailure,
  getTAPaymentMethodStart,
  getTAPaymentMethodSuccess,
  getTAPaymentMethodFailure,
  getWageringTemplatePaginationFailure,
  getWageringTemplatePaginationComplete,
  getWageringTemplatePaginationStart,
  getTenantAllCasinoGamesStart,
  getTenantAllCasinoGamesComplete,
  getTenantAllCasinoGamesFailure,
  getTenantLanguagesStart,
  getTenantLanguagesSuccess,
  getTenantLanguagesFailure,
  getSegmentsStart,
  getSegmentsSuccess,
  getSegmentsFailure,
  deleteBonusStart,
  deleteBonusComplete,
  getBonusUsersStart,
  getBonusUsersSuccess,
  getBonusUsersFailure,
  getUserLotteryTicketsStart,
  getUserLotteryTicketsSuccess,
  getUserLotteryTicketsFailure,
  getBonusGamesStart,
  getBonusGamesSuccess,
  getBonusGamesFailure,
  getUniqueNumberBonusStart,
  getUniqueNumberBonusSuccess,
  getUniqueNumberBonusFailure,
  setIsNewNotification,
  updateNewNotification,
  updateAffiliateAndAgentBonusLimitStart,
  updateAffiliateAndAgentBonusLimitComplete,
  getAdminAffiliateAndAgentsListingStart,
  getAdminAffiliateAndAgentsListingSuccess,
  getAdminAffiliateAndAgentsListingFailure,
  updateAdminBonusLimitStart,
  updateAdminBonusLimitComplete,
  removeAffiliateAndAgentStart,
  removeAffiliateAndAgentComplete,
  getEntityBonusDetailsStart,
  getEntityBonusDetailsSuccess,
  getEntityBonusDetailsFailure,
  resetEntityBonusDetails,
  getBonusReset
} = bonusSlice.actions
