import {
  faCheckSquare,
  faEdit,
  faEye,
  faGamepad,
  faUsers,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import Trigger from '../../../../components/OverlayTrigger'
import PaginationComponent from '../../../../components/Pagination'
import useCheckPermission from '../../../../utils/checkPermission'
import moment from 'moment'
import useIsGamma from '../../../../common/hook/useIsGamma'
import { formatNumber } from '../../../../utils/storageUtils'

export default ({
  voucherData,
  limit,
  setLimit,
  page,
  setPage,
  totalPages,
  tableHeaders,
  show,
  setShow,
  handleYes,
  active,
  isTenant,
  handleShow,
  navigate,
  name,
  loading,
  setShowDeleteModal,
  t,
  isUniqueCodeBonus
}) => {
  const { isHidden } = useCheckPermission()
  const {
    isGamma
  } = useIsGamma()
  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(isUniqueCodeBonus).map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>{t(h.label)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {voucherData && !loading &&
            voucherData?.rows?.map(
              ({
                voucherId,
                promotionTitle,
                tenant,
                tenantId,
                isActive,
                targetBet,
                bonusType,
                adminUser,
                voucherValidFrom,
                voucherValidTo,
                voucherBenefits,
                createdAt,
                isSticky,
                voucher,
                benefitType,
                voucherUseLimit,
                currencyCode,
                isGiftCard,
                rollingPercentage
              }, index) => {
                return (
                  <tr key={`voucher-list ${voucherId}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>{voucher}</td>
                    <td>{moment(voucherValidFrom).format('MM-DD-YYYY HH:mm')}</td>
                    <td>{moment(voucherValidTo).format('MM-DD-YYYY HH:mm')}</td>
                    <td>{formatNumber(targetBet) + ' ₩'}</td>
                    <td>{t(benefitType)}</td>
                    <td>{voucherUseLimit}</td>
                    <td>{formatNumber(voucherBenefits) + ' ₩'}</td>
                    <td>{moment(createdAt).format('MM-DD-YYYY HH:mm')}</td>

                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inActive')}
                    </td>
                    {
                      isUniqueCodeBonus
                        ? (
                          <>
                            <td>
                              {moment(voucherValidTo).isBefore(moment()) ? 'yes' : 'no'}
                            </td>
                            <td>
                              {rollingPercentage || '-'}
                            </td>
                          </>
                          )
                        : null
                    }
                    <td className='text-left'>
                      <ButtonGroup>
                        <Trigger message={t('view')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/${isUniqueCodeBonus ? 'unique-code-bonus' : isGiftCard ? 'giftcard' : 'voucher'}/details/${voucherId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      {!isTenant && (
                        <ButtonGroup>
                          {
                          !isGamma &&
                          (
                            <Trigger message={t('edit')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                                onClick={() =>
                                  navigate(`/${isTenant ? 'tenant' : 'super-admin'}/${isUniqueCodeBonus ? 'unique-code-bonus' : isGiftCard ? 'giftcard' : 'voucher'}/edit/${voucherId}`)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>
                          )
                         }
                        </ButtonGroup>
                      )}
                      {!isTenant
                        ? !isActive
                            ? (
                              <Trigger message={t('setStatusActive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                  onClick={() => handleShow(voucherId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>
                              )
                            : (
                              <Trigger message={t('setStatusInactive')}>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                  onClick={() => handleShow(voucherId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                              )
                        : null}
                      {!isUniqueCodeBonus && (
                        <>
                          <ButtonGroup>
                            <Trigger message={t('viewUser')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                                onClick={() =>
                                  navigate(`/${isTenant ? 'tenant' : 'super-admin'}/${isGiftCard ? 'giftcard' : 'voucher'}-users/${voucherId}/${tenantId}`)}
                              >
                                <FontAwesomeIcon icon={faUsers} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                          <ButtonGroup>
                            <Trigger message={t('viewGames')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                disabled={isGiftCard === true}
                                hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                                onClick={() =>
                                  navigate(`/${isTenant ? 'tenant' : 'super-admin'}/voucher-games/${voucherId}`)}
                              >
                                <FontAwesomeIcon icon={faGamepad} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                        </>
                      )}
                    </td>
                  </tr>
                )
              }
            )}

          {voucherData?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {voucherData?.count > 0 && !loading && (
        <PaginationComponent
          page={voucherData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={voucherData?.count}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}
    </>
  )
}
