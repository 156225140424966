import React from 'react'
import {
  Row,
  Col,
  Table,
  Form,
  Button
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useSubCategoryListing from './useSubCategoryListing'
import { tableHeaders } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    totalPages,
    gameSubCategory,
    search,
    setSearch,
    setActive,
    gameCategory,
    categoryId,
    setCategoryId
  } = useSubCategoryListing()

  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{t('casinoSubCategories')}</h3>
        </Col>
      </Row>

      <Row className='mt-2 mb-3'>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('category')}
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setCategoryId(e.target.value)
                setPage(1)
              }}
              value={categoryId}
              size='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              {gameCategory && gameCategory?.rows?.map(({ mastergamecategoryid, name }) => {
                return <option key={mastergamecategoryid} value={mastergamecategoryid}>{name?.EN}</option>
              })}
            </Form.Select>
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder={t('searchSubCategory')}
              size='sm'
              style={{
                width: 'fit-content',
                minWidth: '220px',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
              onChange={(event) => {
                setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))
                setPage(1)
              }}
            />
          </div>
        </Col>

        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setActive('')
                setCategoryId('')
                setLimit(15)
                setPage(1)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, index) => (
              <th
                key={index}
              >
                {t(h.label)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {gameSubCategory && !loading &&
                      gameSubCategory?.rows?.map(
                        (
                          item, index
                        ) => {
                          return (
                            <tr key={index}>

                              <td>{(index + 1) + (limit * (page - 1))}</td>

                              <td>
                                <Trigger message={item?.name?.EN}>
                                  <span
                                    style={{
                                      width: '100px',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block text-truncate'
                                  >
                                    {item?.name?.EN}
                                  </span>
                                </Trigger>
                              </td>

                              <td>{item?.['MasterGameCategory.name']?.EN}</td>

                              <td className={item?.isActive ? 'table-success' : 'table-danger'}>
                                {item?.isActive ? t('active') : t('inactive')}
                              </td>

                            </tr>
                          )
                        }
                      )}

          {gameSubCategory?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={5}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>
      {gameSubCategory?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={gameSubCategory?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalCount={gameSubCategory?.count}
                />
              )}

    </div>
  )
}
