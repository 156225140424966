import { Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UpdateLimitForm from './UpdateLimitForm'

const UpdateLimitModal = ({
  show,
  handleUpdateYes,
  handleClose,
  loading,
  bonusDetail
}) => {
  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={handleClose} centered>

      <Modal.Body>
        <UpdateLimitForm
          bonusDetail={bonusDetail}
          t={t}
          handleUpdateYes={handleUpdateYes}
          handleClose={handleClose}
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  )
}

export default UpdateLimitModal
