export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'gameName', value: 'name' },
  { label: 'status', value: 'status' },
  { label: 'thumbnail', value: 'Thumbnail' },
  { label: 'provider', value: 'masterCasinoProviderId' },
  { label: 'returnToPlayer', value: 'returnToPlayer' },
  { label: 'masterId', value: 'masterCasinoGameId' },
  { label: 'subCategory', value: 'subcategory' },
  { label: 'action', value: 'action' }

]

export const allowedKeysforOrder = [
  'categoryGameId',
  'name'
]
