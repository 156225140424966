// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  //  Button, ButtonGroup,
  Table
} from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../../components/Pagination'
import { Button, ButtonGroup } from 'react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
const tableHeaders = [
  'S.No',
  'Username',
  'Domain',
  'AdminName',
  'Action'
]

const BonusUsersListing = ({
  userList,
  limit,
  setLimit,
  page,
  setPage,
  totalPages,
  navigate,
  loading,
  count,
  isTenant
}) => {
  const { state } = useLocation()
  return (
    <div>
      <>
        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center '
        >
          <thead className='thead-dark'>
            <tr>

              {tableHeaders.map((h, idx) => (
                <th key={`T-table_heading ${idx}`}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {count > 0 && !loading &&
            userList?.map(
              (item, index) => {
                return (
                  <tr key={`bonus-list ${index}`}>
                    <td>{(index + 1) + (limit * (page - 1))}</td>
                    <td>{item?.username}</td>
                    <td>{item?.domain}</td>
                    <td>{item?.adminName}</td>
                    <td>
                      <ButtonGroup>
                        <Trigger message='View Tickets'>
                          <Button
                            className='m-1'
                            disabled={state?.bonusType !== 'unique_number_bonus'}
                            size='sm'
                            variant='info'
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/lottery-tickets/${item?.userId}`)}
                          >
                            <FontAwesomeIcon icon={faTicket} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>

                  </tr>
                )
              }
            )}

            {count < 1 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {count > 0 && !loading && (
          <PaginationComponent
            page={count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}

      </>
    </div>
  )
}

export default BonusUsersListing
