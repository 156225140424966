import { faCheckSquare, faEdit, faEye, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../OverlayTrigger'
import useCheckPermission from '../../utils/checkPermission'
import { providerCategory } from '../../pages/Super-Admin/CustomPayment/components/constants'

export default ({
  data,
  handleShowModal,
  tenant = false,
  navigate,
  selectedTab,
  createTenantData,
  setCreateTenantData
}) => {
  const { isHidden } = useCheckPermission()

  return (
    <>
      {data && Object.keys(data)?.length
        ? (
          <Accordion defaultActiveKey={0}>
            {Object.keys(data)?.map((item, i) => {
              return (
                <Accordion.Item
                  key={`pay-aggregators-${i}`}
                  eventKey={i}
                >
                  <Accordion.Header>{item.toUpperCase()}</Accordion.Header>
                  <Accordion.Body>
                    <Accordion defaultActiveKey={0}>
                      {Object.keys(data[item]).map((agg, index) => {
                        return (
                          <Accordion.Item
                            key={`pay-aggregators-${agg}`}
                            eventKey={index}
                          >
                            <Accordion.Header>{agg}</Accordion.Header>
                            <Accordion.Body>
                              <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                                <Table
                                  bordered
                                  striped
                                  hover
                                  size='sm'
                                  className='text-center'
                                >
                                  <thead className='thead-dark'>
                                    <tr>
                                      <th>Provider</th>
                                      <th>Display Name</th>
                                      {!tenant && <th>Category</th>}
                                      {!tenant && <th>Action</th>}
                                      {tenant && <th>Allow</th>}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data[item][agg]?.map(({ paymentProviderId, name, isActive, aggregator, group, category, displayName }) => {
                                      return (
                                        <tr key={paymentProviderId}>
                                          <td>{name}</td>
                                          <td>{displayName || 'NA'}</td>
                                          {!tenant && <td>{providerCategory[category || 'OTHER']}</td>}
                                          {!tenant &&
                                            <td>
                                              <ButtonGroup>
                                                <Trigger message='Edit'>
                                                  <Button
                                                    variant='warning'
                                                    size='sm'
                                                    className='m-1'
                                                    hidden={isHidden({ module: { key: 'CashierManagement', value: 'U' } })}
                                                    onClick={() => navigate && navigate(`/super-admin/cashier-management/edit-provider/${paymentProviderId}`)}
                                                  >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                  </Button>
                                                </Trigger>
                                                <Trigger message='View'>
                                                  <Button
                                                    variant='info'
                                                    size='sm'
                                                    className='m-1'
                                                    onClick={() => navigate(`/super-admin/cashier-management/view-provider/${paymentProviderId}`)}
                                                  >
                                                    <FontAwesomeIcon icon={faEye} />
                                                  </Button>
                                                </Trigger>

                                                {!isActive
                                                  ? (
                                                    <Trigger message='Set Status Active'>
                                                      <Button
                                                        className='m-1'
                                                        size='sm'
                                                        variant='success'
                                                        hidden={isHidden({ module: { key: 'CashierManagement', value: 'T' } })}
                                                        onClick={() =>
                                                          handleShowModal && handleShowModal(paymentProviderId, isActive, `${name} (${item}) (${agg})`)}
                                                      >
                                                        <FontAwesomeIcon icon={faCheckSquare} />
                                                      </Button>
                                                    </Trigger>
                                                    )
                                                  : (
                                                    <Trigger message='Set Status In-Active'>
                                                      <Button
                                                        className='m-1'
                                                        size='sm'
                                                        variant='danger'
                                                        hidden={isHidden({ module: { key: 'CashierManagement', value: 'T' } })}
                                                        onClick={() =>
                                                          handleShowModal && handleShowModal(paymentProviderId, isActive, `${name} (${item}) (${agg})`)}
                                                      >
                                                        <FontAwesomeIcon icon={faWindowClose} />
                                                      </Button>
                                                    </Trigger>
                                                    )}
                                              </ButtonGroup>
                                            </td>}

                                          {tenant &&
                                            <td>
                                              <input
                                                name={paymentProviderId}
                                                type='checkbox'
                                                checked={
                                              selectedTab === 'deposit'
                                                ? typeof (createTenantData.depositMethods) === 'object' && createTenantData.depositMethods.filter(item => item.paymentProviderId === paymentProviderId).length
                                                : typeof (createTenantData.withdrawMethods) === 'object' && createTenantData.withdrawMethods.filter(item => item.paymentProviderId === paymentProviderId).length
                                            }
                                                value={paymentProviderId}
                                                onChange={(e) => {
                                                  const data =
                                                  selectedTab === 'deposit'
                                                    ? createTenantData.depositMethods
                                                    : createTenantData.withdrawMethods
                                                  const methods = selectedTab === 'deposit' ? 'depositMethods' : 'withdrawMethods'
                                                  if (e.target.checked) {
                                                    setCreateTenantData({
                                                      ...createTenantData,
                                                      [methods]: [
                                                        ...data,
                                                        { paymentProviderId, name, isActive, aggregator, group, category }
                                                      ]
                                                    })
                                                  } else {
                                                    setCreateTenantData({
                                                      ...createTenantData,
                                                      [methods]: data.filter(item => item.paymentProviderId !== paymentProviderId)
                                                    })
                                                  }
                                                }}
                                              />
                                            </td>}
                                        </tr>
                                      )
                                    })}

                                    {data[item][agg]?.length === 0 && (
                                      <tr>
                                        <td colSpan={2} className='text-danger text-center'>
                                          No data found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>)
        : <p className='text-center text-danger'>No Data Found</p>}
    </>
  )
}
