import { createSlice } from '@reduxjs/toolkit'
const wageringTemplateSlice = createSlice({
  name: 'wageringTemplate',
  initialState: {
    loading: false,
    wageringTemplateDetail: null
  },
  reducers: {
    getWageringTemplateDetailStart: (state) => ({
      ...state,
      loading: true,
      wageringTemplateDetail: null
    }),
    getWageringTemplateDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      wageringTemplateDetail: payload
    }),
    getWageringTemplateDetailFailure: (state, { payload }) => ({
      ...state,
      loading: false
    })
  }
})

export default wageringTemplateSlice.reducer

export const {
  getWageringTemplateDetailStart,
  getWageringTemplateDetailSuccess,
  getWageringTemplateDetailFailure
} = wageringTemplateSlice.actions
