import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteHotComboStart, getHotComboStart } from '../../../store/redux-slices/superAdminSportsBook'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../Toast'

const useHotCombo = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isConfirmationDeleteOpen, setIsConfirmationDeleteOpen] = useState(false)
  const [comboId, setComboId] = useState(null)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { hotCombos, loading, updateLoading } = useSelector((state) => state.superAdminSportsBook)
  const totalPages = Math.ceil(hotCombos?.count / limit)
  const isTenant = !window.location.href.match(/super/g)
  const fetchData = () => {
    dispatch(getHotComboStart(isTenant))
  }
  const handleDeleteCombo = (comboId) => {
    setIsConfirmationDeleteOpen(true)
    setComboId(comboId)
  }
  const handleYesDeleteCombo = () => {
    dispatch(deleteHotComboStart({
      formData: {
        hotComboId: comboId
      },
      onSuccess: () => {
        setIsConfirmationDeleteOpen(false)
        setComboId(null)
        fetchData()
        toast(t('deleteSuccess', 'success'))
      }
    }))
  }
  const handleNo = () => {
    setIsConfirmationDeleteOpen(false)
    setComboId(null)
  }
  useEffect(() => {
    fetchData()
  }, [])

  return {
    handleNo,
    isConfirmationDeleteOpen,
    handleDeleteCombo,
    handleYesDeleteCombo,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    loading,
    t,
    navigate,
    hotCombos,
    isTenant,
    updateLoading,
    setIsConfirmationDeleteOpen
  }
}

export default useHotCombo
