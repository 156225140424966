import React from 'react'
import { Button, ButtonGroup, Col, Form, Table, Spinner } from 'react-bootstrap'
import { disableAction, matchListingTableHeader } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faRedoAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useMatches from './hooks/useMatches'
import Trigger from '../OverlayTrigger'
import PaginationComponent from '../Pagination'
import ConfirmationModal from '../ConfirmationModal'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const MatchListing = ({
  title,
  isTenant
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    filterData,
    setFilterData,
    initialState,
    matchListing,
    loading,
    totalPages,
    debounceFn,
    inputRef,
    isStatusModalOpen,
    setIsStatusModalOpen,
    isDisable,
    setIsDisable,
    setUpdateData,
    updateData,
    handleUpdateSportAction,
    sportsCountries,
    sportsListing,
    addTopLoading,
    handleTopMatch,
    tournamentsListing,
    handleResetFilterData
  } = useMatches(isTenant)
  const { t } = useTranslation()

  return (
    <div>
      <h3 className='px-3'>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className='cursor-pointer px-2'
          onClick={() => navigate(-1)}
        />
        {t(title.toLowerCase())}
      </h3>

      <div className='p-3'>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='d-flex align-items-center px-2'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('search')}
            </Form.Label>&nbsp;
            <Form.Control
              ref={inputRef}
              name='search'
              type='search'
              placeholder={t('search')}
              size='sm'
              style={{
                minWidth: '200px',
                maxWidth: '240px',
                width: 'fit-content'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPage(1)
              }}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('sportsStatus')}
            </Form.Label>
            <Form.Select
              value={filterData?.status}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  status: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {disableAction?.map((actionRequest) =>
                (
                  <option
                    key={actionRequest.value}
                    value={actionRequest.label}
                  >
                    {t(actionRequest?.value)}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          {/* <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('matchStatus')}
            </Form.Label>
            <Form.Select
              value={filterData?.liveStatus}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  liveStatus: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {liveAction?.map((actionRequest) =>
                (
                  <option
                    key={actionRequest.value}
                    value={actionRequest.label}
                  >
                    {t(actionRequest?.value)}
                  </option>
                )
              )}
            </Form.Select>
          </div> */}
          <div className='d-flex align-items-center'>
            <Form.Label
              column='sm' style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('sports')}
            </Form.Label>
            <Form.Select
              value={filterData?.sportId}
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  sportId: e.target.value
                })
                setPage(1)
              }}
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              size='sm'
            >
              <option value=''>{t('all')}</option>

              {sportsListing?.rows?.map(({
                providerSportId,
                sportName
              }) =>
                (
                  <option
                    key={providerSportId}
                    value={providerSportId}
                  >
                    {sportName}
                  </option>
                )
              )}
            </Form.Select>
          </div>
          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('country')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '150px', width: 'auto' }}
              size='sm'
              value={filterData?.countryName}
              onChange={(e) => {
                setFilterData({ ...filterData, countryName: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {sportsCountries && sportsCountries?.map((country) => (
                <option
                  key={country?.countryName}
                  value={country?.countryName}
                >
                  {country?.countryName}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className='d-flex align-items-center'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              {t('tournaments')}
            </Form.Label>
            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '150px', width: 'auto' }}
              size='sm'
              value={filterData?.tournamentId}
              onChange={(e) => {
                setFilterData({ ...filterData, tournamentId: e.target.value })
                setPage(1)
              }}
            >
              <option value=''>{t('all')}</option>
              {tournamentsListing && tournamentsListing?.rows?.map((tournament) => (
                <option
                  key={tournament?.providerTournamentId}
                  value={tournament?.providerTournamentId}
                >
                  {tournament?.tournamentName}
                </option>
              ))}
            </Form.Select>
          </div>

          <Col className='d-flex p-3 float-end'>
            <Trigger message='Reset Filters'>
              <Button
                variant='outline-success'
                className='py-1'
                size='sm'
                onClick={() => {
                  setFilterData(initialState)
                  inputRef.current.value = ''
                  setPage(1)
                  handleResetFilterData()
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </div>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {matchListingTableHeader?.map((h, idx) => (
                <th key={h.label}>
                  {t(h.label)} &nbsp;
                </th>
              ))}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {
              !loading && matchListing &&
              matchListing?.rows?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {(index + 1) + (limit * (page - 1))}</td>
                    <td>
                      {item?.sportname || item?.sportName || '-'}
                    </td>
                    <td>
                      {item?.tournamentname || item?.tournamentName || '-'}
                    </td>
                    <td>
                      {item?.countryname || item?.countryName || '-'}
                    </td>
                    <td>
                      {item?.matchDate ? moment(item?.matchDate).format('MM-DD-YYYY HH:mm') : '-'}
                    </td>
                    <td>
                      {item?.hometeam || item?.homeTeam || '-'}
                    </td>
                    <td>
                      {item?.awayteam || item?.awayTeam || '-'}
                    </td>
                    {/* <td>
                      {(item?.islive || item?.isLive) ? <span style={{ color: 'green', fontSize: '25px' }}>●</span> : <span style={{ color: 'red', fontSize: '25px' }}>●</span> || ''}
                    </td> */}
                    <td className={(item?.systemstatus || item?.systemStatus) ? 'table-success' : 'table-danger'}>
                      {(item?.systemstatus || item?.systemStatus) ? t('enabled') : t('disabled') || ''}
                    </td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={!(item?.systemstatus || item?.systemStatus) ? t('setEnable') : t('setDisable')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant={!(item?.systemstatus || item?.systemStatus) ? 'success' : 'danger'}
                            onClick={() => {
                              setIsStatusModalOpen(true)
                              setIsDisable((item?.systemstatus || item?.systemStatus))
                              setUpdateData({
                                entityType: 'match',
                                status: !(item?.systemstatus || item?.systemStatus),
                                entityId: Number(item?.masterMatchId)
                              })
                            }}
                          >
                            <FontAwesomeIcon icon={!(item?.systemstatus || item?.systemStatus) ? faArrowCircleLeft : faWindowClose} />
                          </Button>
                        </Trigger>
                        <Trigger message={!item?.isTop ? t('setTop') : t('removeTop')}>
                          <Button
                            className='m-1'
                            size='sm'
                            disabled={addTopLoading === item?.masterMatchId}
                            variant={!item?.isTop ? 'success' : 'danger'}
                            onClick={() => {
                              handleTopMatch(item?.masterMatchId, !item?.isTop)
                            }}
                          >
                            {
                              addTopLoading === item?.masterMatchId
                                ? <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                  />
                                : <FontAwesomeIcon icon={!item?.isTop ? faArrowCircleLeft : faWindowClose} />
                            }

                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
              )
            }
            {matchListing?.count === 0
              ? (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >
                    {!loading && t('noDataFound')}
                  </td>
                </tr>
                )
              : <></>}
          </tbody>
        </Table>
        {!loading && matchListing?.count !== 0 &&
          (
            <PaginationComponent
              page={matchListing?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalCount={matchListing.count}
            />
          )}
      </div>
      {isStatusModalOpen &&
        <ConfirmationModal
          setShow={setIsStatusModalOpen}
          show={isStatusModalOpen}
          handleYes={() => handleUpdateSportAction(updateData)}
          active={isDisable}
          isEnable
        />}
    </div>
  )
}
export default MatchListing
