import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItem } from '../../../../utils/storageUtils'
import useCheckPermission from '../../../../utils/checkPermission'
import { getPaymentProviderStart, reOrderPaymentProvidersStart } from '../../../../store/redux-slices/payment'

const usePayProviderList = () => {
  const dispatch = useDispatch()
  const [type, setType] = useState('depositProviders')
  const { paymentProviders } = useSelector(state => state.payment)
  const [state, setState] = useState([])
  const [reorderPayment, setReorderPayment] = useState(false)
  const { isHidden } = useCheckPermission()

  useEffect(() => {
    fetchProviders()
  }, [])

  const fetchProviders = () => {
    dispatch(getPaymentProviderStart({
      isTenant: true,
      paymentType: '',
      aggregator: '',
      tenant: '',
      group: '',
      status: '',
      tenantIds: []
    }))
  }

  useEffect(() => {
    if (paymentProviders?.[type]?.length) {
      setState(paymentProviders[type])
    }
  }, [paymentProviders, type])

  const reorder = (providers, startIndex, endIndex) => {
    const result = Array.from(providers)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      state,
      result.source.index,
      result.destination.index
    )
    setState(rows)
  }

  const handleSave = () => {
    const row = []
    state.map(list => row.push(list))
    dispatch(reOrderPaymentProvidersStart({
      data: {
        order: row,
        paymentType: type === 'depositProviders' ? 'deposit' : 'withdraw',
        tenantId: getItem('tenant-id')
      }
    }))
    setReorderPayment(false)
  }

  return {
    setType,
    type,
    state,
    onDragEnd,
    handleSave,
    isHidden,
    reorderPayment,
    setReorderPayment
  }
}

export default usePayProviderList
