import React from 'react'
import { Button, Card, Col, Form as BForm, Row } from '@themesberg/react-bootstrap'
import { KycActionConfirmModal } from '../../../../components/ConfirmationModal'
import useSetting from '../hooks/useSetting'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { countryFilter } from '../../../../utils/countryFilter'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { ErrorMessage, Form, Formik } from 'formik'
import { editKycSchema } from './schema'
import { updateUserKycDetailsStart } from '../../../../store/redux-slices/adminUser'
import { getItem } from '../../../../utils/storageUtils'
import PhoneInput from 'react-phone-input-2'

const Settings = ({
  userDetails
}) => {
  const { t } = useTranslation()

  const {
    handleShowModal,
    setShowKycRequestAction,
    showKycRequestAction,
    handleConfirmActionRequest,
    KycRequestAction,
    kycEditForm,
    setKycEditForm,
    dispatch,
    banksArr
  } = useSetting()
  const lang = getItem('language').toUpperCase()
  const { countryImage, countryName } = countryFilter(userDetails?.countryCode)
  const showStar = (fieldName) =>
    <span className='text-danger mt-1'>&nbsp;*&nbsp;</span>
  return (
    <>
      <Card className='card-overview'>
        <h6 className='h4-overview text-center mt-3'>
          {kycEditForm ? t('kycEdit') : t('kycDetails')}
          <Trigger message={t('editUserKYC')}>
            <Button
              className='d-flex float-end'
              size='m'
              variant='info'
              onClick={() => {
                setKycEditForm(prevState => !prevState)
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Trigger>
        </h6>
        {
        !kycEditForm
          ? (
            <div className='d-flex justify-content-center py-4 '>
              <div className='card' style={{ minWidth: 500 }}>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('userName')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.username}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('nickNameLabel')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.firstName}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('fullName')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.legalName}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('country')}</span>
                  <span style={{ fontSize: '14px' }}>
                    {
                        !countryName
                          ? <span>{t('other')}</span>
                          : (
                            <Trigger message={countryName}>
                              <img width='25px' src={countryImage} alt={countryImage} />
                            </Trigger>
                            )
                      }
                  </span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('phoneNumber')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.phoneCode}-{userDetails?.phone}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('accountNumber')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.accountNumber}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('bankName')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.bankName}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('dateOfBirth')}</span>
                  <span style={{ fontSize: '14px' }}> {moment(userDetails?.dateOfBirth).format('MM-DD-YYYY')}</span>
                </div>
                <div className='d-flex p-4 justify-content-between gap-4 shadow-sm'>
                  <span style={{ fontSize: '14px' }}>{t('kycStatus')}</span>
                  <span style={{ fontSize: '14px' }}>{userDetails?.kycStatus}</span>
                </div>
                {
              userDetails?.kycStatus === 'PENDING'
                ? (
                  <div className='d-flex justify-content-between'>
                    <Button
                      className='actionButton'
                      variant='outline-success'
                      onClick={() => handleShowModal(
                        userDetails?.userId,
                        true
                      )}
                    >
                      {t('accept')}
                    </Button>
                    <Button
                      className='actionButton'
                      variant='outline-danger'
                      onClick={() => handleShowModal(
                        userDetails?.userId,
                        false
                      )}
                    >
                      {t('reject')}
                    </Button>
                  </div>
                  )
                : (
                  <></>
                  )
            }
              </div>

            </div>
            )
          : (
            <div>
              {

        userDetails &&
          <Formik
            enableReinitializeprop
            initialValues={{
              legalName: userDetails?.legalName,
              phoneCode: userDetails?.phoneCode,
              phone: userDetails?.phone,
              accountNumber: userDetails?.accountNumber,
              bankName: userDetails?.bankName,
              firstName: userDetails?.firstName
            }}
            validationSchema={editKycSchema(t)}
            onSubmit={(formValues) => {
              dispatch(updateUserKycDetailsStart({
                userId: userDetails.userId,
                ...formValues
              }))
              setKycEditForm(prevState => !prevState)
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              setFieldTouched
            }) => {
              return (
                <Form>
                  <Card className='p-4 mx-auto' style={{ maxWidth: '700px' }}>
                    <Row className='mb-3'>
                      <Col xs={12}>
                        <BForm.Label>{t('fullName')}</BForm.Label>
                        <BForm.Control
                          type='text'
                          name='legalName'
                          placeholder={t('enterLegalName')}
                          value={values.legalName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component='div'
                          name='legalName'
                          className='text-danger'
                        />
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col xs={12}>

                        <label htmlFor='firstName'>
                          {t('nickNameLabel')}
                          {showStar()}
                        </label>
                        <BForm.Control
                          name='firstName'
                          type='text'
                          className='form-control'
                          id='firstName'
                          placeholder={t('nickNamePlaceholder')}
                          aria-describedby='emailHelp'
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={50}
                        />
                        <ErrorMessage
                          component='div'
                          name='firstName'
                          key='firstName'
                          className='text-danger mt-1'
                        />

                      </Col>
                    </Row>
                    <Col sm={6} className='mb-2'>
                      <div className=' d-flex  flex-column input-label'>

                        <div className='form-group '>
                          <label htmlFor='phone'>
                            {t('phoneLabel')}
                            {showStar()}
                          </label>

                          <PhoneInput
                            maxLength={20}
                            name='phone'
                            type='text'
                            value={values.phoneCode + values.phone}
                            countryCodeEditable={false}
                            aria-describedby='emailHelp'
                            alwaysDefaultMask={false}
                            enableSearch
                            placeholder={t('phonePlaceholder')}
                            enableLongNumbers
                            country='kr'
                            onChange={(phone, code) => {
                              code = String(code?.dialCode)
                              const newPhone = phone.substring(code.length)
                              const newCode = '+' + code
                              setFieldValue('phone', newPhone)
                              setFieldValue('phoneCode', newCode)
                            }}
                            searchPlaceholder={t('searchPhonePlaceholder')}
                            inputProps={{
                              maxLength: 20
                            }}
                            onBlur={() => setFieldTouched('phone', true)}
                            buttonStyle={{ backgroundColor: '#22214b' }}
                            inputStyle={{ width: '100%' }}
                          />
                          <ErrorMessage
                            component='div'
                            name='phone'
                            className='text-danger mt-1'
                          />
                        </div>

                      </div>
                    </Col>
                    <Row className='mb-3'>
                      <Col xs={12}>
                        <BForm.Label>{t('bankName')}</BForm.Label>
                        <BForm.Select
                          className='w-100 select-placeholder'
                          id='bankName'
                          name='bankName'
                          value={values.bankName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value='' disabled>
                            {t('bankName')}
                          </option>
                          {banksArr[lang].map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='bankName'
                          className='text-danger'
                        />
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col xs={12}>
                        <BForm.Label>{t('accountNumber')}</BForm.Label>
                        {/* <BForm.Select
                          className='w-100 select-placeholder'
                          id='bankName'
                          name='bankName'
                          value={values.bankName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value='' disabled>
                            {t('bankName')}
                          </option>
                          {banksArr[lang].map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </BForm.Select> */}
                        <BForm.Control
                          type='text'
                          name='accountNumber'
                          placeholder={t('enterAccountNumber')}
                          value={values.accountNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component='div'
                          name='accountNumber'
                          className='text-danger'
                        />
                      </Col>
                    </Row>
                    <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                      <Button
                        className='actionButton'
                        variant='outline-success'
                        onClick={handleSubmit}
                      >
                        {t('submit')}
                      </Button>
                      <Button
                        className='actionButton'
                        variant='outline-danger'
                        onClick={() =>
                          setKycEditForm(prevState => !prevState)}
                      >
                        {t('cancel')}
                      </Button>
                    </div>
                  </Card>

                </Form>
              )
            }}
          </Formik>

        }
            </div>
            )
       }
      </Card>
      {setShowKycRequestAction && (
        <KycActionConfirmModal
          action={KycRequestAction}
          setShow={setShowKycRequestAction}
          show={showKycRequestAction}
          handleConfirmActionRequest={handleConfirmActionRequest}
        />
      )}
    </>
  )
}

export default Settings
