import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { createCmsNewSchema } from '../schema'
import useCreateCms from '../hooks/useCreateCms'
import ClientFilter from '../../../../components/ClientFilter'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GalleryModal } from '../../../../components/ConfirmationModal'
import EditCMSTemplate from '../../../../components/EditCMSTemplate'
import { languageCode } from '../../../../pages/Tenant/TenantConfigurations/Languages/constants'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const CreateCms = ({ cmsData, details }) => {
  const {
    navigate,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    editCms,
    cmsPageId,
    setTemplate,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages,
    deleteCms,
    t
  } = useCreateCms(cmsData)
  const { isHidden } = useCheckPermission()
  return (
    <div className='p-3'>
      <Row>
        <Col sm={8}>
          <h3>{!details ? t('edit') : ''} {t('cms')}: {cmsData?.title?.EN}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Trigger message={t('gallery')}>
            <Button
              hidden={details ||
                isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
              onClick={() => {
                getGalleryData()
                setGalleryModal(true)
              }}
              variant='outline-secondary'
            >
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Formik
        initialValues={{
          title: cmsData?.title?.EN,
          slug: cmsData?.slug,
          content: cmsData?.content?.EN,
          category: cmsData?.category,
          isActive: (!!cmsData?.isActive),
          language: selectedTab,
          selectedClient: cmsData?.adminUserId
        }}
        validationSchema={createCmsNewSchema(t)}
        onSubmit={(formValues) => {
          editCms({
            cmsData: {
              ...formValues,
              tenantId: selectedPortal,
              adminUserId: selectedClient,
              cmsPageId: parseInt(cmsPageId)
            }
          })
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Form>
              <Row className='mb-3 align-items-center'>
                <Col xs={6}>
                  <Col>
                    <BForm.Label>
                      {t('enterSlug')} <span className='text-danger'>*</span>
                    </BForm.Label>
                  </Col>
                  <Col>
                    <BForm.Control
                      type='text'
                      name='slug'
                      placeholder={t('enterSlug')}
                      value={values?.slug}
                      disabled={details}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='slug'
                      className='text-danger'
                    />
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3 d-flex'>

                <Col>
                  <ClientFilter
                    setSelectedClient={setSelectedClient}
                    setSelectedPortal={setSelectedPortal}
                    selectedClient={selectedClient}
                    cmsData
                    cms={cmsData}
                    details
                  />
                </Col>
              </Row>
              <Tabs
                activeKey={selectedTab}
                onSelect={(tab) => setSelectedTab(tab)}
                className='nav-light mt-3'
              >
                <Tab
                  eventKey='EN'
                  title={
                    <Trigger message={t('english')}>
                      <span>EN</span>
                    </Trigger>
                }
                  mountOnEnter
                  tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                >
                  <div className='mt-5'>
                    <EditCMSTemplate
                      values={cmsData}
                      cmsKeys={cmsKeys}
                      setFieldValue={setFieldValue}
                      isHidden={isHidden}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setTemp={setTemplate}
                      handleSubmit={handleSubmit}
                      navigate={navigate}
                      deleteCms={deleteCms}
                      details={details}
                      initValues={values}
                      errors={errors}
                    />

                  </div>
                </Tab>
                {details
                  ? cmsData && languages?.length && languages?.map((code) => {
                      return cmsData?.content?.[code] !== undefined && code !== 'EN' && (
                        <Tab
                          eventKey={code}
                          title={
                            <Trigger message={languageCode[code]}>
                              <span>{code}</span>
                            </Trigger>
                      }
                          key={code}
                          mountOnEnter
                          disabled={(!cmsData && cmsData?.content.EN === undefined)}
                          tabClassName={selectedTab !== code ? cmsData?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                        >
                          <div className='mt-5'>
                            <EditCMSTemplate
                              values={cmsData}
                              cmsKeys={cmsKeys}
                              setFieldValue={setFieldValue}
                              isHidden={isHidden}
                              selectedTab={selectedTab}
                              setSelectedTab={setSelectedTab}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setTemp={setTemplate}
                              navigate={navigate}
                              handleSubmit={handleSubmit}
                              deleteCms={deleteCms}
                              details={details}
                              initValues={values}
                              errors={errors}
                            />
                          </div>
                        </Tab>
                      )
                    })
                  : cmsData && languages?.length && languages?.map((code) => {
                    return code !== 'EN' && (
                      <Tab
                        eventKey={code}
                        title={
                          <Trigger message={languageCode[code]}>
                            <span>{code}</span>
                          </Trigger>
                      }
                        key={code}
                        mountOnEnter
                        disabled={(!cmsData && cmsData?.content.EN === undefined)}
                        tabClassName={selectedTab !== code ? cmsData?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                      >
                        <div className='mt-5'>
                          <EditCMSTemplate
                            values={cmsData}
                            cmsKeys={cmsKeys}
                            setFieldValue={setFieldValue}
                            isHidden={isHidden}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setTemp={setTemplate}
                            navigate={navigate}
                            handleSubmit={handleSubmit}
                            deleteCms={deleteCms}
                            details={details}
                            initValues={values}
                            errors={errors}
                          />
                        </div>
                      </Tab>
                    )
                  })}
              </Tabs>

            </Form>
          )
        }}
      </Formik>
      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
        />}
    </div>
  )
}

export default CreateCms
