import React from 'react'
import useNotification from './hooks/useNotification'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBell, faPlus } from '@fortawesome/free-solid-svg-icons'
import NotificationTable from '../../../components/NotificationTable/NotificationTable'
import ReadReceiptTable from '../../../components/NotificationTable/ReadReceiptTable'
import Trigger from '../../../components/OverlayTrigger'
import ConfirmationModal, { CreateNotificationModal } from '../../../components/ConfirmationModal'
// import { Form } from 'react-bootstrap'

const TenantNotification = () => {
  const {
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    setShowNotificationForm,
    showNotificationForm,
    isReadReceiptTable,
    setIsReadReceiptTable,
    handleViewReadReciept,
    tenantNotificationList,
    totalPagesReadReciept,
    tenantReadRecieptList,
    handleCreateNotification,
    showConfirmActiveNotification,
    setShowConfirmActiveNotificationModal,
    setNotificatonId,
    setStatus,
    handleActiveNotification,
    filterData,
    setFilterData,
    initialState,
    t,
    navigate,
    loading,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = useNotification()
  return (
    <div className='p-3'>
      <Row>
        <Col>
          <Row>
            <div className='d-flex gap-2 flex-column '>
              <div
                className='d-flex gap-1 align-items-center cursor-pointer' onClick={() => {
                  setIsReadReceiptTable(!isReadReceiptTable)
                  setPage(1)
                }}
              >
                {isReadReceiptTable && (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className='cursor-pointer me-1'
                    />
                    {t('goBack')}
                  </>
                )}
              </div>

              <h3 className='mb-2'><FontAwesomeIcon icon={faBell} />&nbsp; {t('notification')}</h3>
            </div>
          </Row>
        </Col>
        <Col>
          <Trigger message='create notification'>
            <FontAwesomeIcon
              icon={faPlus}
              data-tip='Create Notification'
              className='cursor-pointer float-end'
              onClick={() => {
                navigate('/tenant/create-notification')
              }}
              size='xl'
            />
          </Trigger>
        </Col>
      </Row>
      {
      !isReadReceiptTable
        ? (<NotificationTable
            tableData={tenantNotificationList}
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            totalPages={totalPages}
            handleViewReadReciept={handleViewReadReciept}
            setShowConfirmActiveNotificationModal={setShowConfirmActiveNotificationModal}
            setNotificatonId={setNotificatonId}
            setStatus={setStatus}
            filterData={filterData}
            setFilterData={setFilterData}
            initialState={initialState}
            isTenant
            t={t}
            loading={loading}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
           />)
        : (<ReadReceiptTable
            tableData={tenantReadRecieptList}
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            totalPages={totalPagesReadReciept}
            setIsReadReceiptTable={setIsReadReceiptTable}
            isReadReceiptTable={isReadReceiptTable}
            filterData={filterData}
            setFilterData={setFilterData}
            initialState={initialState}
            t={t}
           />)
     }
      {
      showNotificationForm
        ? <CreateNotificationModal
            setShow={setShowNotificationForm}
            show={showNotificationForm}
            handleCreateNotification={handleCreateNotification}
            isTenant
            t={t}
          />
        : <></>
     }
      {
       showConfirmActiveNotification
         ? <ConfirmationModal
             show={showConfirmActiveNotification}
             setShow={setShowConfirmActiveNotificationModal}
             handleYes={handleActiveNotification}
             isNotification
             t={t}
           />
         : <></>

     }
    </div>
  )
}

export default TenantNotification
