import React from 'react'
import useBetslipDetails from './hooks/useBetslipDetails'
import BetslipListing from './BetslipListing'
import { Breadcrumb } from 'react-bootstrap'

const BetslipDetails = () => {
  const {
    betslipDetails,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    t,
    loading,
    navigate
  } = useBetslipDetails()

  return (

    <div className='p-3'>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate('/super-admin/bet-reporting')}>{t('betTransactions')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('betDetails')}</Breadcrumb.Item>
      </Breadcrumb>
      <BetslipListing
        tableData={betslipDetails}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        t={t}
        loading={loading}
      />
    </div>
  )
}
export default BetslipDetails
