export const fieldNames = [
  'APP_TWILIO_ACCOUNT_SID',
  'APP_TWILIO_SERVICE_ID',
  'APP_TWILIO_AUTH_TOKEN',
  'APP_SENDGRID_RELAY_KEY',
  'APP_SENDGRID_HOST',
  'APP_SENDGRID_PORT',
  'APP_SENDGRID_USERNAME',
  'APP_SENDGRID_EMAIL',
  'OPERATOR_DEBIT_API',
  'OPERATOR_CREDIT_API',
  'OPERATOR_GET_BALANCE_API'
]

export const timeUnits = [
  { label: 'Hour', value: 'h' },
  { label: 'Minute', value: 'm' }
]
