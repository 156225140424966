import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoyaltyLimitStart } from '../../../store/redux-slices/tenantsFetchData'

const useConfigurations = () => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  const { loading } = useSelector((state) => state.tenantsFetch)
  const [pointsObject, setPointsObject] = useState([])
  const [enableEdit, setEnableEdit] = useState(false)

  const updateLoyaltyLimit = (loyaltyLimit) => {
    dispatch(updateLoyaltyLimitStart({ loyaltyLimit }))
    setEnableEdit(false)
  }

  useEffect(() => {
    const pointObject = tenantDetails?.tenantConfig?.loyaltyPoint
    const newData = Object.keys(pointObject)
      .filter((key) => {
        return tenantDetails?.tenantConfig?.allowedCurrencies?.includes(key)
      })
      .map((key) => ({ code: key, loyaltyPoint: pointObject[key] }))

    setPointsObject(newData)
  }, [tenantDetails])

  return {
    loading,
    pointsObject,
    updateLoyaltyLimit,
    tenantDetails,
    enableEdit,
    setEnableEdit
  }
}

export default useConfigurations
