import * as Yup from 'yup'
import { getItem } from '../../../utils/storageUtils'

export const createTenantAdminSchema = (tenantData, t) => Yup.object().shape({
  email: Yup
    .string()
    .email(t('invalidEmail'))
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      t('invalidEmail')
    )
    .required(t('emailRequired')),
  password: tenantData
    ? Yup
        .string()
        .matches(/^\S*$/, t('spaceNotAllowed'))
        .matches(/^(().{4,})$/, t('minCharacters', { label: t('password'), minNumber: 4 }))
        .matches(/[a-z]/, t('minLowerCase'))
        .matches(/[A-Z]/, t('minUpperCase'))
        .matches(/[0-9]/, t('minNumber'))
        .matches(/[^A-Za-z0-9\s]/, t('minSpecialCharacters'))
        .max(16, t('maxCharacters', { label: t('password'), maxNumber: 16 }))
    : Yup
      .string()
      .matches(/^\S*$/, t('spaceNotAllowed'))
      .matches(/^(().{4,})$/, t('minCharacters', { label: t('password'), minNumber: 4 }))
      .matches(/[a-z]/, t('minLowerCase'))
      .matches(/[A-Z]/, t('minUpperCase'))
      .matches(/[0-9]/, t('minNumber'))
      .matches(/[^A-Za-z0-9\s]/, t('minSpecialCharacters'))
      .max(16, t('maxCharacters', { label: t('password'), maxNumber: 16 }))
      .required(t('passwordRequired')),
  firstName: getItem('languages') === 'EN'
    ? Yup
        .string()
        .min(3, t('minCharacters', { label: t('firstName'), minNumber: 3 }))
        .max(25, t('maxCharacters', { label: t('firstName'), maxNumber: 25 }))
        .matches(/^[A-Za-z]+$/, t('onlyAlphabets'))
        .required(t('required', { label: t('firstName') }))
    : Yup
      .string()
      .min(1, t('minCharacters', { label: t('firstName'), minNumber: 3 }))
      .max(25, t('maxCharacters', { label: t('firstName'), maxNumber: 25 }))
      .required(t('required', { label: t('firstName') })),
  lastName: getItem('languages') === 'EN'
    ? Yup
        .string()
        .min(3, t('minCharacters', { label: t('lastName'), minNumber: 3 }))
        .max(25, t('maxCharacters', { label: t('lastName'), maxNumber: 25 }))
        .matches(/^[A-Za-z]+$/, t('onlyAlphabets'))
        .required(t('required', { label: t('lastName') }))
    : Yup
      .string()
      .min(1, t('minCharacters', { label: t('lastName'), minNumber: 3 }))
      .max(25, t('maxCharacters', { label: t('lastName'), maxNumber: 25 }))
      .required(t('required', { label: t('lastName') })),
  agentName: getItem('languages') === 'EN'
    ? Yup
        .string()
        .min(3, t('minCharacters', { label: t('username'), minNumber: 3 }))
        .max(8, t('maxCharacters', { label: t('username'), maxNumber: 8 }))
        .matches(/^\S*$/, t('spaceNotAllowed'))
        .matches(/^[a-zA-Z0-9._]+$/, t('validUsername'))
        .required(t('required', { label: t('username') }))
    : Yup
      .string()
      .min(1, t('minCharacters', { label: t('username'), minNumber: 3 }))
      .max(8, t('maxCharacters', { label: t('username'), maxNumber: 8 }))
      .required(t('required', { label: t('username') })),
  phone: Yup
    .string()
    .min(5, t('minDigits', { label: t('phone'), minNumber: 5 }))
    .max(13, t('maxDigits', { label: t('phone'), maxNumber: 13 }))
    .matches(
      /^\+[1-9]{1}[0-9]{3,14}$/,
      t('validPhoneNumber')
    )
    .required(t('required', { label: t('phoneNumber') })),
  adminAccountNumber:
      Yup.string()
        .min(8, t('minNumber', { label: t('accountNumber'), minNumber: 8 }))
        .max(20, t('maxNumber', { label: t('accountNumber'), maxNumber: 20 }))
        .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, t('invalidAccountNumber'))
        .required(t('required', { label: t('accountNumber') })),
  adminBankName:
    Yup.string()
      .trim()
      .required(t('required', { label: t('bankName') }))
})
