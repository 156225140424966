import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import TenantPages from './components/TenantPages'
import SuperAdminPages from './components/SuperAdminPages'

import { SuperAdminRoutes, CommonRoutes, TenantRoutes, AffiliateRoutes, AgentRoutes } from '../../routes'
import NotFound from '../NotFound'
import SuperAdminSignIn from '../Super-Admin/SuperAdminSignin'
import TenantSignIn from '../Tenant/TenantSignin'
import { getItem, removeLoginToken } from '../../utils/storageUtils'
import TenantNotFound from '../TenantNotFound'
import ForgotPassword from './ForgotPassword'
import { IdleTimer } from '../../utils/idealBot'
import { resetState } from '../../store/redux-slices'
import RouteHandle from '../RouteHandle'
import AffiliatePages from './components/AffiliatePages'
import AgentPages from './components/AgentPages'
import { ADMIN_ROLES } from '../../utils/constants'

export default () => {
  let { role } = useSelector((state) => state.login)
  const token = getItem('access-token')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (!role) {
    role = getItem('role')
  }
  const isSuperAdmin = role === ADMIN_ROLES.SUPERADMIN
  const isTenant = role === ADMIN_ROLES.ADMIN

  const logout = () => {
    removeLoginToken()
    dispatch(resetState())
    if (role === ADMIN_ROLES.ADMIN) {
      navigate(TenantRoutes.TenantSignin)
    } else if (role === ADMIN_ROLES.SUPERADMIN) {
      navigate(SuperAdminRoutes.SuperAdminSignin)
    }
  }

  const idletimer = new IdleTimer(logout, 3600000)

  useEffect(() => {
    if (token) {
      idletimer.deactivate()
      setTimeout(() => {
        idletimer.activate()
      }, 1000)
    }
  }, [token])

  return (
    <Routes>
      {/* Public Routes without sidebar */}

      <Route
        path={CommonRoutes.Home}
        element={<RouteHandle
          isSuperAdmin={isSuperAdmin}
          isTenant={isTenant}
          role={role}
          token={token}
                 />}
      />
      <Route
        path={SuperAdminRoutes.SuperAdminSignin}
        element={<SuperAdminSignIn />}
      />
      <Route path={TenantRoutes.TenantSignin} element={<TenantSignIn />} />
      <Route path={AffiliateRoutes.AffiliateSignin} element={<TenantSignIn isAffiliate />} />
      <Route path={AgentRoutes.AgentSignin} element={<TenantSignIn isAgent />} />

      <Route path={CommonRoutes.TenantNotFound} element={<TenantNotFound />} />

      <Route path={CommonRoutes.NotFound} element={<NotFound />} />

      <Route path={CommonRoutes?.ForgotPassword} element={<ForgotPassword />} />

      <Route path={CommonRoutes?.ResetPassword} element={<ForgotPassword />} />

      {role === ADMIN_ROLES.SUPERADMIN && SuperAdminPages()}

      {role === ADMIN_ROLES.ADMIN && TenantPages()}
      {role === ADMIN_ROLES.AFFILIATE && AffiliatePages()}
      {role === ADMIN_ROLES.AGENT && AgentPages()}

      <Route
        path='*'
        element={<Navigate replace to={CommonRoutes.Home} />}
      />

    </Routes>
  )
}
