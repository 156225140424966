
export const tableHeaders = (isSuperAdminRole) => {
  if (isSuperAdminRole) {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'username' },
      { label: 'joinDate', value: 'regDate' },
      { label: 'affiliateToken', value: 'country' },
      { label: 'domain', value: 'kycStatus' },
      { label: 'revenuePercentage', value: 'revenuePercentage' },
      { label: 'status', value: 'status' },
      { label: 'action', value: 'action' }
    ]
  } else {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'username' },
      { label: 'joinDate', value: 'regDate' },
      { label: 'affiliateToken', value: 'country' },
      { label: 'domain', value: 'kycStatus' },
      { label: 'status', value: 'status' },
      { label: 'action', value: 'action' }
    ]
  }
}

export const affiliateStatus = [
  { label: 'all', value: '' },
  { label: 'active', value: true },
  { label: 'inactive', value: false }
]
