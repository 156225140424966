import React from 'react'
import CreateTenantUser from './CreateTenantUser'
import Preloader from '../../../../components/Preloader'
import useEditTenantUser from '../hooks/useEditTenantUser'

const EditTenantUser = () => {
  const { userId, user, loading } = useEditTenantUser()

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>{user && user.userId === userId && <CreateTenantUser user={user} />}</>
          )}
    </>
  )
}

export default EditTenantUser
