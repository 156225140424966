export const BonusVal = {
  winningBonus: 'WINNING-BONUS',
  bettingBonus: 'BETTING-BONUS',
  mixedMatchBonus: 'MIXED-MATCH',
  betCompenSationBonus: 'BET-COMPENSATION'
}
export const BONUSES = Object.keys(BonusVal).map((key) => ({
  label: key, value: BonusVal[key]
}))
export const LIMITTYPE = [
  { label: 'DAILY', value: 'DAILY' },
  { label: 'WEEKLY', value: 'WEEKLY' },
  { label: 'MONTHLY', value: 'MONTHLY' }

]

export const tableHeaders = [
  { label: 'id', value: 'id' },
  { label: 'ownerName', value: 'ownerName' },
  { label: 'senior', value: 'senior' },
  { label: 'bonusType', value: 'bonusType' },
  { label: 'validTill', value: 'validTo' },
  { label: 'isExpired', value: 'isExpired' },
  { label: 'status', value: 'isActive' },
  { label: 'action', value: 'action' }
]

export const matchListingTableHeader = [
  { label: 'sno', value: 'sno' },
  { label: 'sportName', value: 'sportname' },
  { label: 'homeTeam', value: 'hometeam' },
  { label: 'awayTeam', value: 'awayteam' },
  { label: 'matchTime', value: 'matchtime' }
]
