import { createSlice } from '@reduxjs/toolkit'

const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState: {
    loading: false,
    affiliateData: [],
    languages: {},
    countries: {},
    bonusAffiliate: {},
    bonusAffiliateLoading: false
  },
  reducers: {
    createAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    createAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createAffiliateFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAffiliateCountryStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateCountrySuccess: (state, { payload }) => ({
      ...state,
      countries: payload,
      loading: false
    }),
    getAffiliateCountryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      affiliateData: payload
    }),
    getAffiliateFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAffiliateDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      affiliateData: payload
    }),
    getAffiliateDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateAffiliateStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAffiliateStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateAffiliateStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateAffiliateFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBonusDetailAffiliateStart: (state) => ({
      ...state,
      bonusAffiliateLoading: true
    }),
    getBonusDetailAffiliateSuccess: (state, { payload }) => ({
      ...state,
      bonusAffiliateLoading: false,
      bonusAffiliate: payload
    }),
    getBonusDetailAffiliateFailure: (state) => ({
      ...state,
      bonusAffiliateLoading: false
    })
  }
})

export default affiliateSlice.reducer

export const {
  createAffiliateStart,
  createAffiliateSuccess,
  createAffiliateFailure,
  getAffiliateStart,
  getAffiliateSuccess,
  getAffiliateFailure,
  getAffiliateDetailStart,
  getAffiliateDetailSuccess,
  getAffiliateDetailFailure,
  updateAffiliateStatusStart,
  updateAffiliateStatusSuccess,
  updateAffiliateStatusFailure,
  updateAffiliateStart,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  getAffiliateCountryStart,
  getAffiliateCountrySuccess,
  getAffiliateCountryFailure,
  getBonusDetailAffiliateStart,
  getBonusDetailAffiliateSuccess,
  getBonusDetailAffiliateFailure
} = affiliateSlice.actions
