import React from 'react'
import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import useCategoryListing from './useCategoryListing'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    gameCategory,
    totalPages,
    search,
    setSearch,
    t
  } = useCategoryListing()

  return (
    <div className='p-3'>
      <Row>
        <Col sm={7}>
          <h3>{t('masterCasinoCategories')}</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center w-100'>
            <Form.Label style={{
              marginBottom: '0',
              marginTop: '5px',
              minWidth: '70px',
              fontSize: '13px'
            }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder={t('searchName')}
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {[
              's.no',
              'name'
            ].map((header, index) => (
              <th key={index}>{t(header)}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && gameCategory &&
                      gameCategory?.rows?.map(
                        (
                          {
                            masterGameCategoryId,
                            name
                          },
                          index
                        ) => {
                          return (
                            <tr key={masterGameCategoryId}>
                              <td>{(index + 1) + (limit * (page - 1))}</td>
                              <td>
                                {name?.EN}
                              </td>
                            </tr>
                          )
                        }
                      )}

          {
                   gameCategory?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={4}
                            className='text-danger text-center'
                          >
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )
          }
        </tbody>
      </Table>

      {gameCategory?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={gameCategory?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalCount={gameCategory?.count}
                />
              )}
    </div>
  )
}
