import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { affiliateLoginStart, agentLoginStart, tenantLoginStart } from '../../../store/redux-slices/login'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { AffiliateRoutes, AgentRoutes, SuperAdminRoutes, TenantRoutes } from '../../../routes'
import { useTranslation } from 'react-i18next'

const useTenantSignin = ({ isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, twoStepsEnabled } = useSelector((state) => state.login)
  const [showPassword, setShowPassword] = useState(false)
  const [loginData, setLoginData] = useState({ user: '', password: '' })

  useEffect(() => {
    if (getLoginToken() && getItem('role') === 'Admin') {
      navigate(TenantRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Affiliate') {
      navigate(AffiliateRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Agent') {
      navigate(AgentRoutes.Dashboard)
    } else if (getLoginToken() && getItem('role') === 'Super Admin') {
      navigate(SuperAdminRoutes.Dashboard)
    }
  }, [])

  const handleSignIn = (data) => {
    setLoginData({ ...data })
    if (isAffiliate) {
      dispatch(affiliateLoginStart({ ...data, navigate }))
    } else if (isAgent) {
      dispatch(agentLoginStart({ ...data, navigate }))
    } else {
      dispatch(tenantLoginStart({ ...data, navigate }))
    }
  }

  return {
    loading,
    showPassword,
    setShowPassword,
    navigate,
    twoStepsEnabled,
    loginData,
    handleSignIn,
    t
  }
}

export default useTenantSignin
