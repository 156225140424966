import {
  Modal,
  Table,
  Form as BForm
  // Col
} from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../Pagination'
import { statusType, tableHeaders, transStatus, transactionType, walletType } from '../../pages/Super-Admin/TransactionsBanking/constants'
import {
  // formatDateYMD,
  getDateTime
} from '../../utils/dateFormatter'
import { Button, Card } from 'react-bootstrap'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import {
  // useDispatch,
  useSelector
} from 'react-redux'
// import { updateSuperadminTransactionStatusStart } from '../../store/redux-slices/superAdminTransactions'
import Trigger from '../OverlayTrigger'
import useCheckPermission from '../../utils/checkPermission'

const TransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  loading = false,
  selectedCurrency,
  selectedAction,
  userDetail = false,
  countries = false,
  adminId,
  tenantId,
  paymentProvider,
  state,
  countryCode,
  selectedStatus,
  actioneeType,
  playerId = '',
  myTransactionId = '',
  isTenant = false,
  createdAt,
  paymentMethod,
  check
}) => {
  const { isHidden } = useCheckPermission()
  const type = transactionType
  const currencyCode = selectedCurrency || 'EUR'
  // const [show, setShow] = useState(false)
  // const [showPaymentDetailDialog, setShowPaymentDetailDialog] = useState(false) // temp-comment
  // const [paymentProviderId, setPaymentProviderId] = useState(null)
  const [showCommentDialog, setShowCommentDialog] = useState(null)
  // const [transactionComment, setTransactionComment] = useState(null)
  // const dispatch = useDispatch()
  const [userTransactionData, setUserTransactionData] = useState({})
  // temp -comment
  // const [transactionStatus, setTransactionStatus] = useState('')
  const totalAmount = selectedCurrency ? transactions?.currencyWiseAmount : transactions?.totalAmount
  const primaryCurrAmount = selectedCurrency && selectedCurrency !== 'EUR' && selectedAction
    ? (transactions?.totalAmount < 0 ? (transactions?.totalAmount * (-1)) : transactions?.totalAmount)?.toFixed(2)
    : 0
  const addedMoney = !['removeMoney', 'removeMoneyInternal', 'withdraw', 'bonusForfeit', 'bonusExpired', 'bonusZeroedOut', 'withdrawInternal'].includes(selectedAction)
  const { adminPermissions } = useSelector(state => state.admins)
  // const { tenantDetails } = useSelector(state => state.login)
  const isUPermission = !isHidden({ module: { key: 'Transactions', value: 'U' } })

  const commentsTableHeaders = [
    { label: 'Id' },
    { label: 'Actionee Email' },
    { label: 'Actionee Name' },
    { label: 'Comment' },
    { label: 'Before Status' },
    { label: 'After Status' },
    { label: 'Updated At' }
  ]

  const transactionOptions = (status, transactionType) => {
    return Array.isArray(transStatus[status])
      ? transStatus[status]
      : (transactionType === 'deposit' || transactionType === 'depositInternal')
          ? transStatus[status]?.deposit
          : transStatus[status]?.withdraw
  }

  const handleWalletType = ({ transactionType, amountType }, bonusId) => {
    if (['addMoney', 'removeMoney', 'addMoneyInternal', 'removeMoneyInternal']?.includes(transactionType)) {
      return <>({walletType[amountType]})</>
    } else if (bonusId && transactionType === 'bonusToCash') {
      return <>({bonusId})</>
    }
  }

  // const updateTransactionStatus = () => {
  //   const {
  //     userId,
  //     transactionId,
  //     paymentTransactionId,
  //     transactionType,
  //     selectedStatus: status
  //   } = userTransactionData
  //   dispatch(updateSuperadminTransactionStatusStart({
  //     adminId: adminId || '',
  //     search: actioneeType || '',
  //     userId,
  //     comment: transactionComment,
  //     transactionId,
  //     paymentTransactionId: paymentTransactionId || paymentProviderId,
  //     paymentProviderId: paymentProviderId || '',
  //     // statusAction: status === 'chargeback' ? 9 : status === 'refund' ? 8 : status === 'complete' ? 1 : 2,
  //     statusAction: Number(status),
  //     statusTransactionType: transactionType,
  //     limit,
  //     pageNo: page,
  //     status: selectedStatus,
  //     transactionType: selectedAction,
  //     startDate: formatDateYMD(state.map(a => a.startDate)) || '',
  //     endDate: formatDateYMD(state.map(a => a.endDate)) || '',
  //     currencyId: selectedCurrency || '',
  //     tenantId: tenantId || '',
  //     paymentProvider,
  //     countryCode: countryCode || '',
  //     playerId,
  //     isTenant: !!tenantDetails,
  //     myTransactionId,
  //     createdAtStartDate: formatDateYMD(createdAt.map(a => a.startDate)),
  //     createdAtEndDate: formatDateYMD(createdAt.map(a => a.endDate)),
  //     paymentMethod,
  //     check
  //   }))
  //   setShow(false)
  //   setTransactionComment(null)
  // }

  const getActionSelector = (status, transactionType) => {
    if (status === 0 && (transactionType === 'withdrawInternal' || transactionType === 'withdraw')) return false
    return (!!(isUPermission && Array.isArray(transactionOptions(status, transactionType))))
  }

  const getViewAction = (moreDetails) => {
    return !!((isUPermission && Array.isArray(moreDetails?.transactionHistory)))
  }

  // const getMyStatus = () => statusType?.find((type) => type.value === parseInt(userTransactionData?.selectedStatus))?.label

  return (
    <>
      <div className='d-flex justify-content-center'>
        {/* Table with tenants info */}
        <Card body className='w-100 text-center' style={{ maxWidth: '400px', margin: userDetail ? 'auto' : 'auto 1rem auto auto' }}>
          <div>
            <small>
              TOTAL TRANSACTION AMOUNT
            </small>
          </div>
          <div className='header-font-weight'>
            {transactions?.count !== 0 && transactions && !loading
              ? (
                <div className={(totalAmount >= 0 && addedMoney) ? 'text-success' : 'text-danger'}>
                  {totalAmount ? (`${(totalAmount < 0 ? (totalAmount * (-1)) : totalAmount)?.toFixed(2)} ${currencyCode}`) : 'Please Select Action Type'}
                  {primaryCurrAmount !== 0 && ` (${primaryCurrAmount} EUR)`}
                </div>)
              : (
                <small className='text-danger text-center'>
                  No data found
                </small>
                )}
          </div>
        </Card>

        <Card body className='w-100 text-center' style={{ maxWidth: '400px', margin: 'auto auto auto 1rem' }}>
          <div>
            <Trigger message='DEPOSIT ACCEPTANCE RATE' placement='top'>
              <small className='cursor-pointer'>
                ACCEPTANCE RATE
              </small>
            </Trigger>
          </div>
          <div className='header-font-weight'>
            {transactions?.acceptanceRate ? <span className='text-success'>{transactions?.acceptanceRate}%</span> : <span className='text-danger'>0%</span>}
          </div>
        </Card>
      </div>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              (userDetail ? h.label !== 'Country' && h.value !== 'kycStatus' : true) && (
                <th
                  key={`T-table_heading ${idx}`}
                  className={`${['Amount', 'Amount in EUR'].includes(h.label) && transactions?.count > 0 ? 'text-right' : ''}`}
                >
                  {h.label}
                </th>)
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions && !loading &&
            transactions?.rows?.map(
              ({
                transactionBankingId,
                paymentTransactionId,
                createdAt,
                actioneeEmail,
                paymentProvider,
                amount,
                transactionType,
                amountType,
                actioneeType,
                status,
                currencyCode,
                countryCode,
                primaryCurrencyAmount,
                updatedAt,
                moreDetails,
                targetId,
                transactionId,
                kycStatus,
                paymentMethod
              }) => (
                <tr key={`transaction-list ${transactionBankingId}`}>
                  <td>{transactionBankingId}</td>
                  <td>{moreDetails?.withdrawId || paymentTransactionId || 'NA'}</td>
                  <td>{(userDetail && !adminPermissions?.Users?.includes('RUI'))
                    ? <span className='blur-contant'>test@yopmail.com</span>
                    : actioneeEmail || 'NA'}
                  </td>
                  <td onClick={() => window.open(`/${isTenant ? 'tenant' : 'super-admin'}/users/details/${targetId}`, '_blank')} className='text-link cursor-pointer'>{targetId || 'NA'}</td>
                  {!userDetail && <td>{kycStatus || 'NA'}</td>}
                  {!userDetail && <td>{Array.isArray(countries) && countries?.find(({ code }) => code === countryCode)?.name}</td>}
                  <td>{paymentProvider || 'NA'}</td>
                  <td>{paymentMethod || 'NA'}</td>
                  <td className='text-right'>
                    {`${amount < 0 ? amount * (-1) : amount} ${currencyCode}`}
                  </td>
                  <td className='text-right'>
                    {`${primaryCurrencyAmount < 0 ? primaryCurrencyAmount * (-1) : primaryCurrencyAmount} EUR`}
                  </td>
                  <td>{type?.find((type) => type.value === transactionType)?.label} {handleWalletType({ transactionType, amountType }, moreDetails?.bonusId)} </td>
                  <td>{moreDetails?.updatedBy || actioneeType || 'NA'}</td>
                  <td>
                    {statusType?.find((type) => type.value === status)?.label}
                  </td>
                  <td>{createdAt ? getDateTime(createdAt) : 'NA'}</td>
                  <td>{updatedAt ? getDateTime(updatedAt) : 'NA'}</td>
                  <td>
                    {
                      (transactionType === 'deposit' || transactionType === 'withdrawInternal' ||
                        transactionType === 'depositInternal' || transactionType === 'withdraw')
                        ? (
                          <span className='d-flex justify-content-center'>
                            {getActionSelector(status, transactionType) && (
                              <BForm.Select
                                size='sm'
                                style={{ maxWidth: '230px' }}
                                onChange={(e) => {
                                  // setShow(true)
                                  setUserTransactionData({
                                    completed: false,
                                    userId: targetId,
                                    transactionId,
                                    paymentTransactionId,
                                    transactionType,
                                    aggregator: moreDetails?.aggregator ? 'praxis' : '',
                                    selectedStatus: e.target.value
                                  })
                                }}
                                // value={transactionStatus}
                                value={null}
                              >
                                <option value=''>None</option>
                                {transactionOptions(status, transactionType)?.map(({ label, value }) => (
                                  <option key={value} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </BForm.Select>)}

                            {getViewAction(moreDetails) &&
                              <Trigger message='View Comments'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='info'
                                  onClick={() => {
                                    setShowCommentDialog(true)
                                    setUserTransactionData({
                                      moreDetails,
                                      transactionBankingId
                                    })
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </Button>
                              </Trigger>}
                            {!getActionSelector(status, transactionType) && !getViewAction(moreDetails) && 'NA'}
                          </span>)
                        : 'NA'
                    }
                  </td>
                </tr>
              ))}

          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={20} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        show={showCommentDialog}
        onHide={() => setShowCommentDialog(false)}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: 'auto', maxHeight: '640px' }}>
            <Table
              bordered
              size='sm'
              className='text-center fixTableHead'
            >
              <thead>
                <tr className='thead-dark fixTableHead-th'>
                  {commentsTableHeaders.map((h, idx) => (
                    <th key={`T-table_heading ${idx}`}>
                      <span>{h.label}</span><br />
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {userTransactionData?.moreDetails?.transactionHistory
                  ? (
                      userTransactionData.moreDetails.transactionHistory?.map(
                        ({
                          actioneeEmail,
                          actioneeName,
                          comment,
                          from,
                          to,
                          updatedAt
                        }, index) => {
                          return (
                            <tr key={index}>
                              <td>{userTransactionData.transactionBankingId || 'NA'}</td>
                              <td>{actioneeEmail || 'NA'}</td>
                              <td>{actioneeName || 'NA'}</td>
                              <td>{comment || 'NA'}</td>
                              <td>{statusType?.find((type) => type.value === from)?.label}</td>
                              <td>{statusType?.find((type) => type.value === to)?.label}</td>
                              <td>{updatedAt ? getDateTime(updatedAt) : 'NA'}</td>
                            </tr>
                          )
                        }
                      ))
                  : (
                    <tr>
                      <td colSpan={20} className='text-danger text-center pt-3'>
                        No data found
                      </td>
                    </tr>
                    )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      {
        // temp -comment
        // !showPaymentDetailDialog && (
        //   <Modal
        //     show={show}
        //     onHide={() => {
        //       setShow(false)
        //       setUserTransactionData(null)
        //       setTransactionComment(null)
        //     }}
        //     backdrop='static'
        //     keyboard={false}
        //   >
        //     <Modal.Header>
        //       <Modal.Title>
        //         Are you sure you want to {getMyStatus() === 'Cancelled' ? 'Cancel' : getMyStatus() === 'Refunded' ? 'Refund' : getMyStatus()} this Transaction Request ?
        //       </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       <BForm.Label>
        //         Add Comment
        //       </BForm.Label>
        //       <BForm.Control
        //         type='text'
        //         name='comment'
        //         placeholder='Enter comment'
        //         value={transactionComment}
        //         onChange={(e) => setTransactionComment(e.target.value)}
        //       />
        //     </Modal.Body>
        //     {userTransactionData.aggregator === 'praxis' && !userTransactionData.paymentTransactionId &&
        //       <Modal.Body>
        //         <BForm.Label>
        //           Payment Provider Id
        //         </BForm.Label>
        //         <BForm.Control
        //           type='number'
        //           name='paymentProviderId'
        //           placeholder='Enter Payment Provider Id'
        //           value={paymentProviderId}
        //           onChange={(e) => setPaymentProviderId(e.target.value)}
        //         />
        //       </Modal.Body>}
        //     <Modal.Footer className='d-flex justify-content-between align-items-center'>
        //       <Button
        //         variant='outline-primary'
        //         onClick={() => setShow(false)}
        //         className='btn-outline-warning'
        //       >
        //         Cancel
        //       </Button>
        //       <Button
        //         variant='btn-outline-secondary'
        //         onClick={() => updateTransactionStatus()}
        //         className='btn btn-outline-success ml-2'
        //       >
        //         Confirm
        //       </Button>
        //     </Modal.Footer>
        //   </Modal>
        // )
      }
      {
        transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={transactions?.count}
          />
        )
      }
    </>
  )
}

export default TransactionsList
