import * as Yup from 'yup'
import { formatDateYMD } from '../../utils/dateFormatter'

export const giveBonusSchema = (t) => {
  Yup.object().shape({
    includeUserIds: Yup.array().min(1, t('segmentIdRequired!')).required(t('segmentIdRequired!'))
  })
}

export const giveFreespinSchema = (t) => {
  Yup.object().shape({
    includeUserIds: Yup.array().min(1, t('segmentIdRequired!')).required(t('segmentIdRequired!')),
    quantity: Yup.number()
      .min(1, t('minimum1SpinRequired'))
      .positive(t('spinsMustBeAPositiveNumber'))
      .integer(t('spinsMustBeAnIntegerValue'))
      .required(t('spinsRequired')),
    betLevel: Yup.number()
      .min(1, t('minimum1BetLevelRequired'))
      .positive(t('betLevelMustBeAPositiveNumber'))
      .integer(t('betLevelMustBeAnIntegerValue'))
      .required(t('betLevelRequired')),
    bonusId: Yup.string().required(t('bonusRequired')),
    validFrom: Yup.date()
      .min(formatDateYMD(new Date()), t('validFromMustBeGreaterThanOrEqualToTodaysDate'))
      .nullable(),
    validTo: Yup.date()
      .test('isSmall',
        t('validToMustBeGreaterThanValidFromDate'),
        (value, context) => {
          if (value.getTime() <= context.parent.validFrom.getTime()) {
            return false
          } else {
            return true
          }
        })
      .test('isLess',
        t('validToMustBeInRangeOfOneMonthFromValidFromDate'),
        (value, context) => {
          const validFromDate = context.parent.validFrom
          validFromDate.setDate(validFromDate.getDate() + 30)
          if (value.getTime() <= validFromDate.getTime()) {
            return true
          } else {
            return false
          }
        })
  })
}
