import { takeLatest, put } from 'redux-saga/effects'
import { serialize } from 'object-to-formdata'

import {
  createBonus,
  updateBonus,
  getSAConvertAmount,
  getBonus,
  // superAdminViewToggleStatus,
  getPaymentMethod,
  getSuperAdminAllWageringTemplate,
  getTenantAllWageringTemplate,
  getSuperAdminWageringTemplateDetail,
  updateWageringTemplate,
  createWageringTemplate,
  tenantViewToggleStatus,
  getTAConvertAmount,
  getAllTABonus,
  getTenantWageringTemplate,
  getSuperAdminWageringTemplate,
  getTenantWageringTemplateDetail,
  getAllWageringCasinoGames,
  issueBonus,
  getUserBonus,
  cancelBonus,
  getTenantLanguages,
  getSegments,
  deleteBonus,
  getSelectedSeniorBonus,
  getBonusUsers,
  getUserLotteryTickets,
  getBonusGames,
  getUniqueNumberBonus,
  updateAffiliateAndAgentBonusLimit,
  getAdminAffiliateAndAgentsListing,
  updateAdminBonusLimit,
  removeAffiliateAndAgent,
  updateBonusStatus,
  getEntityBonusDetails
} from '../../utils/apiCalls'
import {
  removeAffiliateAndAgentStart,
  removeAffiliateAndAgentComplete,
  getSelectedSeniorBonusStart,
  getSelectedSeniorBonusSuccess,
  getSelectedSeniorBonusFailure,
  getSAConvertAmountStart,
  getSAConvertAmountSuccess,
  getSAConvertAmountFailure,
  createBonusStart,
  createBonusComplete,
  updateBonusStart,
  updateBonusComplete,
  getBonusStart,
  getBonusSuccess,
  getBonusFailure,
  issueBonusStart,
  issueBonusComplete,
  getUserBonusStart,
  getUserBonusSuccess,
  getUserBonusFailure,
  cancelBonusStart,
  cancelBonusComplete,
  updateSABonusStatusStart,
  updateSABonusStatusComplete,
  getSAPaymentMethodStart,
  getSAPaymentMethodSuccess,
  getSAPaymentMethodFailure,
  getWageringTemplateFailure,
  getWageringTemplateComplete,
  getWageringTemplateStart,
  getWageringTemplateDetailsStart,
  getWageringTemplateDetailsComplete,
  getWageringTemplateDetailsFailure,
  createWageringTemplateStart,
  createWageringTemplateComplete,
  createWageringTemplateFailure,
  updateWageringTemplateStart,
  updateWageringTemplateComplete,
  updateWageringTemplateFailure,
  getTABonusStart,
  getTABonusSuccess,
  getTABonusFailure,
  createTABonusStart,
  createTABonusComplete,
  updateTABonusStart,
  updateTABonusComplete,
  updateTABonusStatusStart,
  updateTABonusStatusComplete,
  getTAConvertAmountStart,
  getTAConvertAmountSuccess,
  getTAConvertAmountFailure,
  getAllTABonusStart,
  getAllTABonusComplete,
  getAllTABonusFailure,
  getTAPaymentMethodStart,
  getTAPaymentMethodSuccess,
  getTAPaymentMethodFailure,
  getWageringTemplatePaginationFailure,
  getWageringTemplatePaginationComplete,
  getWageringTemplatePaginationStart,
  getTenantAllCasinoGamesStart,
  getTenantAllCasinoGamesComplete,
  getTenantAllCasinoGamesFailure,
  getTenantLanguagesStart,
  getTenantLanguagesSuccess,
  getTenantLanguagesFailure,
  getSegmentsStart,
  getSegmentsSuccess,
  getSegmentsFailure,
  deleteBonusStart,
  deleteBonusComplete,
  getBonusUsersStart,
  getBonusUsersSuccess,
  getBonusUsersFailure,
  getUserLotteryTicketsStart,
  getUserLotteryTicketsSuccess,
  getUserLotteryTicketsFailure,
  getBonusGamesStart,
  getBonusGamesSuccess,
  getBonusGamesFailure,
  getUniqueNumberBonusStart,
  getUniqueNumberBonusSuccess,
  getUniqueNumberBonusFailure,
  updateAffiliateAndAgentBonusLimitStart,
  updateAffiliateAndAgentBonusLimitComplete,
  getAdminAffiliateAndAgentsListingStart,
  getAdminAffiliateAndAgentsListingSuccess,
  getAdminAffiliateAndAgentsListingFailure,
  updateAdminBonusLimitStart,
  updateAdminBonusLimitComplete,
  getEntityBonusDetailsStart,
  getEntityBonusDetailsSuccess,
  getEntityBonusDetailsFailure
} from '../redux-slices/bonus'
import { toast } from '../../components/Toast'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { t } from 'i18next'
import { BonusVal, LIMITTYPE } from '../../common/NewBonus/constant'

export default function * bonusWatcher () {
  yield takeLatest(getBonusUsersStart.type, getBonusUsersWorker)
  yield takeLatest(getEntityBonusDetailsStart.type, getEntityBonusDetailsWorker)
  yield takeLatest(getAdminAffiliateAndAgentsListingStart.type, getAdminAffiliateAndAgentsListingWorker)
  yield takeLatest(removeAffiliateAndAgentStart.type, removeAffiliateAndAgentWorker)
  yield takeLatest(updateAdminBonusLimitStart.type, updateAdminBonusLimitWorker)
  yield takeLatest(updateAffiliateAndAgentBonusLimitStart.type, updateAffiliateAndAgentBonusLimitWorker)
  yield takeLatest(getUserLotteryTicketsStart.type, getUserLotteryTicketsWorker)
  yield takeLatest(getSAConvertAmountStart.type, getSAConvertAmountWorker)
  yield takeLatest(createBonusStart.type, createBonusStartWorker)
  yield takeLatest(updateBonusStart.type, updateBonusStartWorker)
  yield takeLatest(getBonusStart.type, getBonusStartWorker)
  yield takeLatest(issueBonusStart.type, issueBonusWorker)
  yield takeLatest(getUserBonusStart.type, getUserBonusWorker)
  yield takeLatest(cancelBonusStart.type, cancelBonusWorker)
  yield takeLatest(updateSABonusStatusStart.type, updateSABonusStatusWorker)
  yield takeLatest(getSAPaymentMethodStart.type, getSAPaymentMethodWorker)
  yield takeLatest(getWageringTemplateStart.type, getWageringTemplateWorker)
  yield takeLatest(getWageringTemplateDetailsStart.type, getWageringTemplateDetailWorker)
  yield takeLatest(createWageringTemplateStart.type, createWageringTemplateWorker)
  yield takeLatest(updateWageringTemplateStart.type, updateWageringTemplateWorker)
  yield takeLatest(createTABonusStart.type, createTABonusWorker)
  yield takeLatest(updateTABonusStart.type, updateTABonusWorker)
  yield takeLatest(updateTABonusStatusStart.type, updateTABonusStatusWorker)
  yield takeLatest(getTABonusStart.type, getTABonusWorker)
  yield takeLatest(getTAConvertAmountStart.type, getTAConvertAmountWorker)
  yield takeLatest(getAllTABonusStart.type, getAllTABonusWorker)
  yield takeLatest(getTAPaymentMethodStart.type, getTAPaymentMethodWorker)
  yield takeLatest(getWageringTemplatePaginationStart.type, getWageringTemplatePaginationWorker)
  yield takeLatest(getTenantAllCasinoGamesStart.type, getTenantAllCasinoGamesWorker)
  yield takeLatest(getTenantLanguagesStart.type, getTenantLanguagesWorker)
  yield takeLatest(getSegmentsStart.type, getSegmentsWorker)
  yield takeLatest(deleteBonusStart.type, deleteBonusWorker)
  yield takeLatest(getSelectedSeniorBonusStart.type, getSelectedSeniorBonusWorker)
  yield takeLatest(getBonusGamesStart.type, getBonusGamesWorker)
  yield takeLatest(getUniqueNumberBonusStart.type, getUniqueNumberBonusWorker)
}
function * removeAffiliateAndAgentWorker (action) {
  const { handleClose } = action?.payload
  try {
    const res = yield removeAffiliateAndAgent(action?.payload)
    if (res.status === 200) {
      yield put(removeAffiliateAndAgentComplete())
      yield toast(`${t('removeSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(removeAffiliateAndAgentComplete())
  }
}
function * getEntityBonusDetailsWorker (action) {
  try {
    const { bonusType } = action.payload
    const { data } = yield getEntityBonusDetails(action?.payload)
    let tempData = null
    if (data?.data && data?.data?.entityBonusDetails?.length > 0) {
      const typeArr = LIMITTYPE?.map((item) => item?.value)
      const limitTypeData = data?.data?.entityBonusDetails?.find(item => typeArr.includes(item?.limitType))
      const maxData = data?.data?.entityBonusDetails?.find(item => item?.limitType === 'MAX')
      const isCompensation = bonusType === BonusVal.compensationBonus
      const isRecurring = bonusType === BonusVal.reOccuringDepositBonus
      const isPayback = bonusType === BonusVal.paybackBonus
      if (isCompensation) {
        tempData = {
          isCompensation,
          isRecurring,
          isPayback,
          bonusType,
          ...data?.data?.entityBonusDetails?.[0],
          ...limitTypeData,
          maxBonusLimit: maxData?.limit,
          customLimit: limitTypeData?.limit
        }
      } else {
        tempData = {
          bonusType,
          isCompensation,
          isRecurring,
          isPayback,
          ...data?.data?.entityBonusDetails?.[0],
          ...maxData,
          customLimit: limitTypeData?.limit,
          maxBonusLimit: maxData?.limit
        }
      }
    }
    yield put(getEntityBonusDetailsSuccess(tempData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getEntityBonusDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAdminAffiliateAndAgentsListingWorker (action) {
  try {
    const { data } = yield getAdminAffiliateAndAgentsListing(action?.payload)
    yield put(getAdminAffiliateAndAgentsListingSuccess(data?.data?.entityDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminAffiliateAndAgentsListingFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateAdminBonusLimitWorker (action) {
  const { handleClose } = action.payload
  try {
    const res = yield updateAdminBonusLimit(action?.payload)
    if (res.status === 200) {
      yield put(updateAdminBonusLimitComplete())
      yield toast(`${t('updateSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateAdminBonusLimitComplete())
  }
}

function * getBonusStartWorker (action) {
  try {
    const { isAffiliate } = action.payload
    const { data } = yield getBonus(action.payload)
    let tempData
    let limitTypeData
    let maxData
    if (!isAffiliate) {
      tempData = data?.data?.bonusDetail
    } else {
      const typeArr = LIMITTYPE?.map((item) => item?.value)
      limitTypeData = data?.data?.bonusDetails?.bonusEntity?.find(item => typeArr.includes(item?.limitType))
      maxData = data?.data?.bonusDetails?.bonusEntity?.find(item => item?.limitType === 'MAX')
      if (data?.data?.bonusDetails?.bonusType === BonusVal.compensationBonus) {
        tempData = {
          ...data?.data?.bonusDetails,
          ...limitTypeData,
          maxBonusLimit: maxData?.limit,
          customLimit: limitTypeData?.limit
        }
      } else {
        tempData = {
          ...data?.data?.bonusDetails,
          ...maxData,
          maxBonusLimit: maxData?.limit
        }
      }
    }
    yield put(getBonusSuccess(tempData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusFailure())
  }
}
function * updateAffiliateAndAgentBonusLimitWorker (action) {
  try {
    const { handleClose } = action.payload
    const res = yield updateAffiliateAndAgentBonusLimit(action.payload)
    if (res.status === 200) {
      yield put(updateAffiliateAndAgentBonusLimitComplete())
      yield toast(`${t('updateSuccess')}`, 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateAffiliateAndAgentBonusLimitComplete())
  }
}
function * getUniqueNumberBonusWorker (action) {
  try {
    const { data } = yield getUniqueNumberBonus(action.payload)
    yield put(getUniqueNumberBonusSuccess(data?.data?.tenantBonuses))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getUniqueNumberBonusFailure())
  }
}
function * getBonusUsersWorker (action) {
  try {
    // const { pageNo } = action && action.payload
    const { data } = yield getBonusUsers(action.payload)
    yield put(getBonusUsersSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusUsersFailure())
  }
}
function * getUserLotteryTicketsWorker (action) {
  try {
    // const { pageNo } = action && action.payload
    const { data } = yield getUserLotteryTickets(action.payload)
    yield put(getUserLotteryTicketsSuccess(data?.data?.userDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getUserLotteryTicketsFailure())
  }
}
function * getBonusGamesWorker (action) {
  try {
    // const { pageNo } = action && action.payload
    const { data } = yield getBonusGames(action.payload)
    yield put(getBonusGamesSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusGamesFailure())
  }
}
function * createBonusStartWorker (action) {
  try {
    const { formData, navigate } = action && action.payload
    const res = yield createBonus(formData)
    if (res.status === 200) {
      yield toast(t('bonusCreatedSuccessfully'), 'success')
      if (formData.status) {
        navigate(0)
      } else {
        navigate(-1)
      }
      yield put(createBonusComplete())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createBonusComplete())
  }
}

function * updateBonusStartWorker (action) {
  try {
    const { data, navigate } = action && action.payload

    const response = yield updateBonus(data)
    if (response.status === 200) {
      yield toast(t('bonusUpdatedSuccessfully'), 'success')
      navigate(-1)
    }
    yield put(updateBonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateBonusComplete())
  }
}
function * issueBonusWorker (action) {
  try {
    const { data, isTenant, handleIssue } = action && action.payload

    yield issueBonus({ data, isTenant })

    yield put(issueBonusComplete())

    if (handleIssue) {
      handleIssue()
    } else {
      yield put(getUserBonusStart({ limit: 10, pageNo: 1, bonusType: 'all', status: 'all', userId: data?.userId, isTenant }))
    }

    yield toast('Bonus issued successfully', 'success')
  } catch (e) {
    yield put(issueBonusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getUserBonusWorker (action) {
  try {
    const { limit, pageNo, bonusType, status, userId, isTenant, bonusId = '' } = action && action.payload

    const { data } = yield getUserBonus({ limit, pageNo, bonusType, status, userId, isTenant, bonusId })

    yield put(getUserBonusSuccess(data?.data?.userBonus))
  } catch (e) {
    yield put(getUserBonusFailure())
  }
}

function * cancelBonusWorker (action) {
  try {
    const { data, limit, pageNo, bonusType, status, userId, isTenant } = action && action.payload

    yield cancelBonus({ data, isTenant })

    yield put(getUserBonusStart({ limit, pageNo, bonusType, status, userId, isTenant }))
    yield put(cancelBonusComplete())

    yield toast('Bonus cancelled successfully', 'success')
  } catch (e) {
    yield put(cancelBonusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getSAConvertAmountWorker (action) {
  try {
    const { currencyCode, maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold, tenantIds } = action && action.payload
    const { res } = yield getSAConvertAmount({ currencyFields: { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold }, currencyCode, tenantIds })

    yield put(getSAConvertAmountSuccess(res?.data?.wallet))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSAConvertAmountFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateSABonusStatusWorker (action) {
  try {
    const { data: formData, handleClose } = action && action.payload
    const res = yield updateBonusStatus(formData)
    if (res.status === 200) {
      yield put(updateSABonusStatusComplete())
      yield toast('Bonus Status Updated Successfully', 'success')
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSABonusStatusComplete())
  }
}

function * getSAPaymentMethodWorker () {
  try {
    const { data } = yield getPaymentMethod({ flag: 'superadmin' })
    yield put(getSAPaymentMethodSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSAPaymentMethodFailure())
  }
}

function * getWageringTemplateWorker (action) {
  try {
    const { isTenant, adminId } = action && action.payload
    const { data } = isTenant ? yield getTenantAllWageringTemplate() : yield getSuperAdminAllWageringTemplate({ adminId: adminId || '' })

    yield put(getWageringTemplateComplete(data?.data?.getTemplates))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplateFailure())
  }
}

function * getWageringTemplateDetailWorker (action) {
  try {
    const { isTenant, wageringTemplateId, limit, pageNo, providerId, search } = action && action.payload
    const { data } = isTenant ? yield getTenantWageringTemplateDetail({ wageringTemplateId, limit, pageNo, providerId, search: search || '' }) : yield getSuperAdminWageringTemplateDetail({ wageringTemplateId, limit, pageNo, providerId, search: search || '' })
    yield put(getWageringTemplateDetailsComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplateDetailsFailure())
  }
}

function * createWageringTemplateWorker (action) {
  try {
    const { isTenant, templateData, navigate } = action && action.payload
    const { data } = yield createWageringTemplate(isTenant, templateData)
    yield toast(data.message, 'success')
    yield put(createWageringTemplateComplete())

    navigate(isTenant ? TenantRoutes.WageringTemplate : SuperAdminRoutes.WageringTemplate)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createWageringTemplateFailure())
  }
}

function * updateWageringTemplateWorker (action) {
  try {
    const { isTenant, templateData, navigate } = action && action.payload
    const { data } = yield updateWageringTemplate(isTenant, templateData)
    yield toast(data.message, 'success')
    yield put(updateWageringTemplateComplete())

    navigate(isTenant ? TenantRoutes.WageringTemplate : SuperAdminRoutes.WageringTemplate)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateWageringTemplateFailure())
  }
}

function * createTABonusWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(data)
    const isTenant = true
    yield createBonus(data, isTenant)
    yield toast('Bonus Created successfully', 'success')
    navigate('/tenant/bonus')
    yield put(createTABonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createTABonusComplete())
  }
}
function * updateTABonusWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(
      data
    )
    const isTenant = true
    yield updateBonus(data, isTenant)
    yield toast('Bonus Updated successfully', 'success')
    navigate('/tenant/bonus')
    yield put(updateTABonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTABonusComplete())
  }
}

function * updateTABonusStatusWorker (action) {
  try {
    const { limit, pageNo, bonusType, isActive, search, data } = action && action.payload

    yield tenantViewToggleStatus(data)
    yield put(updateTABonusStatusComplete())
    yield toast('Bonus Status Updated Successfully', 'success')

    yield put(getAllTABonusStart({ limit, pageNo, bonusType, isActive, search }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTABonusStatusComplete())
  }
}

function * getTABonusWorker (action) {
  try {
    const { bonusId, userBonusId } = action && action.payload
    const { data } = yield getBonus({ bonusId, isTenant: true, userBonusId })
    yield put(getTABonusSuccess(data?.data?.bonusDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTABonusFailure())
  }
}

function * getTAConvertAmountWorker (action) {
  try {
    const { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold, currencyCode, tenantIds } = action && action.payload
    const { res } = yield getTAConvertAmount({ currencyFields: { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold }, currencyCode, tenantIds })

    yield put(getTAConvertAmountSuccess(res?.data?.wallet))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTAConvertAmountFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllTABonusWorker (action) {
  try {
    const { tenantId, limit, pageNo, bonusType, isActive, search, userId = '', reorder = false, bonusId = '' } = action && action.payload

    const { data } = yield getAllTABonus({ userId, tenantId, limit, pageNo, bonusType, isActive, search, reorder, bonusId })

    yield put(getAllTABonusComplete(data?.data?.bonus))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTABonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTAPaymentMethodWorker () {
  try {
    const { data } = yield getPaymentMethod({ flag: 'tenant' })
    yield put(getTAPaymentMethodSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTAPaymentMethodFailure())
  }
}

function * getWageringTemplatePaginationWorker (action) {
  try {
    const { isTenant, adminId, search, limit, pageNo } = action && action.payload
    const { data } = isTenant ? yield getTenantWageringTemplate({ search, limit, pageNo }) : yield getSuperAdminWageringTemplate({ adminId, search, limit, pageNo })

    yield put(getWageringTemplatePaginationComplete(data?.data?.wageringTemplates))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplatePaginationFailure())
  }
}

function * getTenantAllCasinoGamesWorker (action) {
  try {
    const { isTenant, providerId, search } = action && action.payload
    const { data } = yield getAllWageringCasinoGames({ isTenant, providerId, search: search || '' })
    yield put(getTenantAllCasinoGamesComplete(data?.data?.games))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantAllCasinoGamesFailure())
  }
}

function * getTenantLanguagesWorker (action) {
  try {
    const { tenantId, isTenant } = action && action.payload
    const { data } = yield getTenantLanguages({ tenantId, isTenant })
    yield put(getTenantLanguagesSuccess(data?.data?.languages?.filter(lang => lang !== 'EN')))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantLanguagesFailure())
  }
}

function * getSegmentsWorker (action) {
  try {
    const { isTenant } = action && action.payload
    const { data } = yield getSegments({ isTenant })
    yield put(getSegmentsSuccess(data?.data?.segments))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSegmentsFailure())
  }
}

function * deleteBonusWorker (action) {
  try {
    const { data, handleClose } = action && action.payload
    const res = yield deleteBonus(data)
    if (res.status === 200) {
      yield put(deleteBonusComplete())
      toast(t('deleteSuccess'), 'success')
      handleClose()
    }

    if (handleClose) {
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteBonusComplete())
  }
}

function * getSelectedSeniorBonusWorker (action) {
  const { type, tenantId } = action && action.payload
  try {
    const { data } = yield getSelectedSeniorBonus(type, tenantId)
    yield put(getSelectedSeniorBonusSuccess(data?.data?.dropdownBonusOptions))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSelectedSeniorBonusFailure())
  }
}
