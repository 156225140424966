import * as Yup from 'yup'

export const createVoucherSchema = (isTenant, t) =>
  Yup.object().shape({
    tenantId: isTenant ? Yup.number().nullable() : Yup.number().required(t('seniorRequired')),
    voucher:
      Yup.string()
        .trim()
        .min(3, t('min3Characters'))
        .required(t('fieldRequired')),
    targetBet: Yup
      .number()
      .min(1, t('minValue', { min: 1 }))
      .max(100000, t('maximumValue100000', { max: 100000 }))
      .integer(t('integerValueError'))
      .required(t('fieldRequired')),
    voucherUseLimit: Yup
      .number()
      .min(1, t('minValue', { min: 1 }))
      .max(9, t('maximumValue9', { max: 9 }))
      .integer(t('integerValueError'))
      .required(t('fieldRequired')),
    benefitType: Yup
      .string()
      .required(t('fieldRequired')),
    currencyCode: Yup
      .string()
      .required(t('fieldRequired')),
    voucherBenefits: Yup
      .number()
      .min(1, t('minValue', { min: 1 }))
      .max(100000, t('maximumValue100000', { max: 100000 }))
      .integer(t('integerValueError'))
      .required(t('fieldRequired'))
      .nullable(),
    voucherValidFrom: Yup.date()
      .required(t('validFromDateRequired'))
      .nullable(),
    voucherValidTo: Yup.date()
      .required(t('validToDateRequired'))
      .test('isSmall',
        t('validToMustBeGreaterThanValidFromDate'),
        (value, context) => {
          if (value?.getTime() <= context.parent.voucherValidFrom?.getTime()) {
            return false
          } else {
            return true
          }
        }),
    rollingPercentage: Yup.number().when(['voucherType', 'benefitType'], {
      is: (voucherType, benefitType) =>
        voucherType === 'UniqueCode' || benefitType === 'bonus',
      then: Yup.number()
        .required(t('rollingPercentageRequired'))
        .integer(t('integerValueError')) // Error if not a number
        .min(1, t('minValue', { min: 1 })) // Minimum value 1
        .max(999, t('maximumValue999', { max: 999 })),
      otherwise: Yup.number().nullable()
    })
  })
