import React from 'react'
import { notificationCategoryOptions, notificationTable, statusFilter } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import PaginationComponent from '../Pagination'
import { Button, Form } from 'react-bootstrap'
import moment from 'moment'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faPaperPlane, faEdit, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import ClientFilter from '../ClientFilter'
import AffiliateAgentFilter from '../../common/AffiliateAgentFilter'
import { getItem } from '../../utils/storageUtils'

const NotificationTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  handleViewReadReciept,
  setShowConfirmActiveNotificationModal,
  notificationId,
  setNotificatonId,
  setStatus,
  setShowTenant,
  setDataToShowAtTenantTable,
  tableDataToShow,
  isTenant,
  filterData,
  setFilterData,
  initialState,
  loading,
  t,
  selectedClient,
  setSelectedClient,
  agent,
  setAgent,
  affiliate,
  setAffiliate
}) => {
  const currentLang = getItem('language').toLowerCase()
  const navigate = useNavigate()
  return (
    <div>
      <div className='d-flex flex-wrap align-items-center'>
        <div className='d-flex align-items-center gap-2'>
          {!isTenant &&
            <>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
              />

            </>}
          <AffiliateAgentFilter
            selectedClient={selectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
          />

        </div>
        <div className='d-flex align-items-center'>
          <Form.Label
            column='sm' style={{
              marginBottom: '0',
              marginRight: '15px',
              minWidth: 'fit-content'
            }}
          >
            {t('category')}
          </Form.Label>
          <Form.Select
            value={filterData?.referenceType}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                referenceType: e.target.value
              })
              setPage(1)
            }}
            style={{
              marginBottom: '0',
              marginRight: '15px',
              maxWidth: '230px',
              width: 'auto'
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>
            {notificationCategoryOptions?.map((requestAction) =>
              (
                <option
                  key={requestAction.value}
                  value={requestAction.value}
                >
                  {t(requestAction?.label)}
                </option>
              )
            )}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center'>
          <Form.Label
            column='sm' style={{
              marginBottom: '0',
              marginRight: '15px',
              minWidth: 'fit-content'
            }}
          >
            {t('status')}
          </Form.Label>
          <Form.Select
            value={filterData?.status}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                status: e.target.value
              })
              setPage(1)
            }}
            style={{
              marginBottom: '0',
              marginRight: '15px',
              maxWidth: '230px',
              width: 'auto'
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {statusFilter?.map((requestAction) =>
              (
                <option
                  key={requestAction.value}
                  value={requestAction.value}
                >
                  {t(requestAction?.label)}
                </option>
              )
            )}
          </Form.Select>
        </div>
        <Trigger message={t('resetFilters')}>
          <Button
            variant='outline-success'
            className='py-1'
            size='sm'
            onClick={() => {
              setFilterData(initialState)
              setAgent('')
              setAffiliate('')
              setSelectedClient && setSelectedClient('')
            }}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
        </Trigger>
      </div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {notificationTable(isTenant)?.map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && tableData &&
      tableData?.rows?.map((item, index) => {
        return (
          <tr key={index}>
            <td>{(page - 1) * limit + index + 1}</td>
            <td>
              {item?.senderType}
            </td>
            {!isTenant && (
              <td>
                {item?.referenceName}
              </td>
            )}
            <td>
              {item?.referenceType}
            </td>
            <td>
              {item?.translations?.[currentLang] || item?.notificationMessage}
            </td>
            <td className={item?.isActive ? 'table-success' : 'table-danger'}>
              {item?.isActive ? t('sent') : t('unsent')}
            </td>
            <td>
              {moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}
            </td>
            <td>
              {moment(item?.updatedAt).format('DD-MM-YYYY HH:mm')}
            </td>
            <td>
              <Trigger message={t('readership')}>
                <Button
                  className='m-2'
                  size='sm'
                  onClick={() => {
                    handleViewReadReciept(item?.notificationId)
                    setFilterData({
                      referenceType: '',
                      status: ''
                    })
                    setPage(1)
                  }}
                >
                  <FontAwesomeIcon icon={faMessage} />
                </Button>
              </Trigger>
              {!isTenant
                ? (
                  <Trigger message={t('recipients')}>
                    <Button
                      className='m-2'
                      size='sm'
                      disabled={item?.referenceId === null || isTenant}
                      onClick={() => {
                        setShowTenant(true)
                        setDataToShowAtTenantTable([{ id: item?.referenceId, name: item?.referenceName }])
                      }}
                    >
                      <FontAwesomeIcon icon={faBell} />
                    </Button>
                  </Trigger>
                  )
                : <></>}
              <Trigger message={t('sendNotification')}>
                <Button
                  disabled={item?.isActive}
                  className='m-2'
                  size='sm'
                  variant={!item?.isActive ? 'success' : 'danger'}
                  onClick={() => {
                    setShowConfirmActiveNotificationModal(true)
                    setNotificatonId(item?.notificationId)
                    !item?.isActive ? setStatus(true) : setStatus(false)
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </Trigger>
              <Trigger message={t('editNotification')}>
                <Button
                  disabled={item?.isActive}
                  className='m-2'
                  size='sm'
                  onClick={() => navigate(`/${isTenant ? 'tenant' : 'super-admin'}/edit-notification/${item?.notificationId}`, { state: { data: item } })}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </Trigger>
            </td>
          </tr>
        )
      }
      )
          }
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
    </div>
  )
}

export default NotificationTable
