import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getTenantUserDetailsStart, resetUserPasswordStart, updateTenantUserStatusStart } from '../../../../store/redux-slices/tenantUsers'
import { getAllTABonusStart, getBonusStart } from '../../../../store/redux-slices/bonus'
import { safeStringify } from '../../../../utils/helpers'
import { useTranslation } from 'react-i18next'

const useUserDetailsListing = ({ isAffiliate, isAgent }) => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState('overview')
  const navigate = useNavigate()
  const { user, loading } = useSelector((state) => state.tenantUsers)
  const { updateUserAffiliateLoading } = useSelector((state) => state.fetch)
  const [show, setShow] = useState(false)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [showTagModal, setShowTagModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { userId } = useParams()
  const dispatch = useDispatch()
  const [tagOptions, setTagOptions] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const { userDocuments } = useSelector(state => state.tenantsFetch)
  const { duplicateUsers } = useSelector(state => state.tenantUsers)
  const { bonusDetail } = useSelector((state) => state.bonus)
  const { bonusList } = useSelector((state) => state.bonus)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [imagePreviewModalShow, setImagePreviewModalShow] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState()
  const [bonusAmount, setBonusAmount] = useState()
  const [freespinModal, setFreespinModal] = useState()
  const [isInternalModal, setIsInternalModal] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [userPasswordModal, setUserPasswordModal] = useState(false)
  const [passwordEmailModal, setPasswordEmailModal] = useState(false)
  const { userCurrentStatus, userStatusLoading } = useSelector((state) => state.adminUser)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    dispatch(getTenantUserDetailsStart({ userId, isAffiliate, isAgent }))
  }, [userId])

  const updateTenantUserStatus = (data) =>
    dispatch(updateTenantUserStatusStart({
      ...data,
      code: 'USER',
      userId,
      status: !user.isActive,
      isUserPage: true
    }))

  useEffect(() => {
    if (user?.tags?.length > 0) {
      const options = []
      for (const i in user?.tags) {
        if (user?.tags[i] !== '' && user?.tags[i] !== null) {
          options.push({ label: user?.tags[i], value: user?.tags[i] })
        }
      }
      setTagOptions(options)
    }
  }, [user?.tags])

  useEffect(() => {
    if (!selectedBonus && bonusList?.length > 0) {
      setSelectedBonus(bonusList?.[0])
    }
    if (selectedBonus) {
      dispatch(getBonusStart({ userBonusId: '', bonusId: selectedBonus?.bonusId, isTenant: true }))
    }
  }, [bonusList, selectedBonus?.bonusId])

  useEffect(() => {
    if (showModal) {
      dispatch(getAllTABonusStart({
        limit: '',
        pageNo: '',
        search: '',
        isActive: '',
        bonusType: safeStringify(['match', 'balance', 'wagering'])
      }))
    }
  }, [showModal])

  const handleResetUserPassword = (data) => {
    dispatch(resetUserPasswordStart({
      isTenant: true,
      ...data
    }))
    setUserPasswordModal(false)
  }

  return {
    t,
    selectedTab,
    user,
    loading,
    show,
    showModal,
    showReasonModal,
    showDuplicateModal,
    limit,
    pageNo,
    tagOptions,
    selectedTag,
    duplicateUsers,
    imageUrl,
    imagePreviewModalShow,
    userDocuments,
    showTagModal,
    bonusDetail,
    bonusList,
    selectedBonus,
    bonusAmount,
    updateUserAffiliateLoading,
    setBonusAmount,
    setSelectedBonus,
    setShowModal,
    updateTenantUserStatus,
    setShow,
    setShowReasonModal,
    handleShow,
    handleClose,
    setSelectedTab,
    setShowTagModal,
    setTagOptions,
    setSelectedTag,
    setShowDuplicateModal,
    setLimit,
    setPageNo,
    setImagePreviewModalShow,
    setImageUrl,
    freespinModal,
    setFreespinModal,
    setIsInternalModal,
    isInternalModal,
    navigate,
    editUserModal,
    setEditUserModal,
    userPasswordModal,
    setUserPasswordModal,
    handleResetUserPassword,
    setPasswordEmailModal,
    passwordEmailModal,
    userCurrentStatus,
    userStatusLoading
  }
}

export default useUserDetailsListing
