import React from 'react'

import Preloader from '../../../../components/Preloader'
import useEditCms from '../hooks/useEditCms'
import CreateCms from './CreateCms'

const EditCms = () => {
  const {
    loading,
    cmsDetails,
    cmsId
  } = useEditCms()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            {cmsDetails && cmsDetails.cmsPageId === JSON.parse(cmsId) && (
              <CreateCms
                cmsDetails={cmsDetails}
              />
            )}
          </>
          )}
    </>
  )
}

export default EditCms
