import { toast as toastify } from 'react-toastify'

export const toast = (message, type) => {
  switch (type) {
    case 'success':
      return toastify.success(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })

    case 'error':
      return toastify.error(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })

    case 'warning':
      toastify.warning(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
  }
}

// const Toast = ({ message, title }) => (
//   <>
//     <span className='d-block'>{title}</span>

//     <span>{message}</span>
//   </>
// )
