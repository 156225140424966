import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import './voucherTransaction.scss'
import PaginationComponent from '../../../Pagination'
import moment from 'moment'
import { formatNumber } from '../../../../utils/storageUtils'
const tableHeaders = ['sNo', 'voucherName', 'voucherBenefits', 'username', 'redemptionDate', 'transactionId']
const giftTableHeader = ['sNo', 'giftCardName', 'giftCardBenefits', 'username', 'redemptionDate', 'transactionId']
const VoucherTransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  loading,
  t,
  isGiftCard
}) => {
  const renderHeader = isGiftCard
    ? giftTableHeader
    : tableHeaders
  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {renderHeader.map((c) => (
              <th key={c}>
                {t(c)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions && !loading &&
            transactions?.rows?.map(
              (item, index) => {
                return (
                  <tr key={`casino-transaction-list ${index}`}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>{item?.Voucher?.voucher}</td>
                    <td>{formatNumber(item?.amount)}</td>
                    <td>{item?.User?.username}</td>
                    <td>{moment(item?.createdAt).format('MM-DD-YYYY HH:mm')} </td>
                    <td>{item?.transactionId}</td>
                  </tr>
                )
              })}
          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {
        transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={transactions?.count}
          />
        )
      }
    </>
  )
}

export default VoucherTransactionsList
