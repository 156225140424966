import { createSlice } from '@reduxjs/toolkit'

const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    loading: false,
    referralData: [],
    ReferredUserData: []
  },
  reducers: {
    getAllReferalStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllReferalSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      referralData: payload
    }),
    getAllReferalFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllReferredUserlStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllReferredUserlSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      ReferredUserData: payload
    }),
    getAllReferredUserlFailure: (state) => ({
      ...state,
      loading: false
    })

  }
})

export default referralSlice.reducer

export const {
  getAllReferalStart,
  getAllReferalSuccess,
  getAllReferalFailure,
  getAllReferredUserlStart,
  getAllReferredUserlSuccess,
  getAllReferredUserlFailure
} = referralSlice.actions
