import React, { useState } from 'react'
import { betBySportsLogTable } from './constant'
import { Table, Button } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import { PrettyJsonPrinter } from '../ConfirmationModal'
// import { formatNumber } from '../../utils/storageUtils'

const BetBySportsLogTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t // Assuming t is the translation function
}) => {
  const [show, setShow] = useState(false)
  const [jsonData, setJsonData] = useState({})
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {betBySportsLogTable.map((h, idx) => (
              <th key={h.label}>
                {t(`${h.value}`)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && tableData &&
            tableData?.rows?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      <span
                        style={{
                          width: '130px',
                          cursor: 'pointer'
                        }}
                        className='d-inline-block text-truncate'
                      >
                        {t(`${item?.user?.username || 'NA'}`)}
                      </span>
                    </td>
                    <td>
                      {t(`${item?.match || 'NA'}`)}
                    </td>
                    <td>
                      {t(`${item?.logType || 'NA'}`)}
                    </td>
                    <td>
                      <Button
                        className='actionButton'
                        onClick={() => {
                          setShow(true)
                          setJsonData({ Body: item?.previousData?.request })
                        }}
                      >
                        {t('view')}
                      </Button>
                    </td>
                    <td>
                      <span title={item?.description || 'NA'}>{t(`${item?.description || 'NA'}`)}</span>
                    </td>
                    <td>
                      {t(`${item?.transactionId || 'NA'}`)}
                    </td>
                    <td>
                      {t(`${moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || 'NA'}`)}

                    </td>
                  </tr>
                )
              }
            )}
          {!loading && tableData?.count === 0
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0
        ? (
          <PaginationComponent
            page={tableData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={tableData?.count}
          />
          )
        : <></>}

      {show &&
        <PrettyJsonPrinter
          setShow={setShow}
          show={show}
          data={jsonData}
        />}
    </div>
  )
}
export default BetBySportsLogTable
