import { Modal, Form, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik, Form as FormikForm } from 'formik'
import React from 'react'
import { userDisableReasons } from '../../../Super-Admin/UserDetails/constants'
import { reasonSchema } from '../../Users/schemas'
import { useTranslation } from 'react-i18next'

const Reason = ({
  show,
  setShow,
  handleClose,
  markUserStatusInactive,
  name,
  isSuperAdmin,
  isRedFlagged

}) => {
  const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          reason: '',
          description: ''
        }}
        validationSchema={reasonSchema(t)}
        onSubmit={(formValues, { resetForm }) => {
          isSuperAdmin === 'tenant'
            ? markUserStatusInactive({ ...formValues, role: 'tenant' })
            : markUserStatusInactive({ ...formValues, role: 'superAdmin' })
          resetForm({ formValues: '' })
          handleClose()
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <FormikForm>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              backdrop='static'
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <h5>{t('mark')} {t(name || 'player')} {isRedFlagged ? t('redFlag') : t('blackList')}</h5>
              </Modal.Header>
              <Modal.Body>
                <div className='form-group'>
                  <label
                    htmlFor='reason'
                    className={touched.reason && errors.reason ? 'text-danger' : ''}
                  >
                    {t('reason')}<span className='text-danger'> *</span>
                  </label>
                  <Form.Select
                    name='reason'
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.reason && errors.reason ? 'border border-danger' : ''}
                  >
                    <option value=''>{t('selectReason')}</option>
                    {userDisableReasons.map(reason => {
                      return (
                        <option value={reason.value} key={reason.value}>
                          {t(reason.label)}
                        </option>
                      )
                    }
                    )}
                  </Form.Select>
                  <ErrorMessage
                    component='div'
                    name='reason'
                    className='text-danger'
                  />
                </div>
                <div className='form-group mt-3'>
                  <label
                    htmlFor='description'
                  >
                    {t('description')}
                  </label>
                  <Form.Control
                    name='description'
                    type='text'
                    placeholder={t('enterDescription')}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Modal.Body>
              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    {t('submit')}
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </FormikForm>

        )}

      </Formik>
    </>
  )
}

export default Reason
